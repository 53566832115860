import { useRef, useState } from "react";
import { QueryRenderer, createFragmentContainer, graphql } from "react-relay";
import Environment from "app/lib/relay/environment";
import Panel from "app/components/shared/Panel";
import Dropdown from "app/components/shared/Dropdown";
import SectionLoader from "app/components/shared/SectionLoader";
import ExampleSection from "./ExampleSection";
import EXAMPLES from "./Examples";
import GraphqlExplorerLayout from "../Layout";

type Organization = {
  id: string;
  name: string;
};

type OrganizationSwitcherProps = {
  currentOrganization: Organization;
  organizations: Array<{
    node: Organization;
  }>;
  onOrgChange: (org: Organization) => void;
};

const OrganizationSwitcher = ({
  currentOrganization,
  organizations,
  onOrgChange,
}: OrganizationSwitcherProps) => {
  const dropdownRef: any = useRef();

  const onOrgClick = (event: any, organization: Organization) => {
    event.preventDefault();

    if (dropdownRef?.current) {
      dropdownRef.current.setShowing(false);
    }
    onOrgChange(organization);
  };

  return (
    organizations && (
      <div className="mb3 flex">
        <span className="semi-bold mr1">Change Organization:</span>

        <Dropdown width={150} ref={(ref: any) => (dropdownRef.current = ref)}>
          <div className="underline-dotted cursor-pointer inline-block">
            {currentOrganization.name}
          </div>
          {organizations.map((edge) => {
            return (
              <div
                key={edge.node.id}
                className="btn block hover-bg-silver"
                onClick={(event) => onOrgClick(event, edge.node)}
              >
                <span className="block">{edge.node.name}</span>
              </div>
            );
          })}
        </Dropdown>
      </div>
    )
  );
};

type Props = {
  viewer: {
    organizations: {
      edges: Array<{
        node: Organization;
      }>;
    };
  };
};

const Examples = ({ viewer }: Props) => {
  const [organization, setOrganization] = useState<Organization>(
    viewer.organizations.edges?.[0]?.node,
  );

  return (
    <div>
      <p>
        Here are some example GraphQL Query and Mutations to get you started. If
        you want more detailed examples for common tasks, see the{" "}
        <a href="https://buildkite.com/docs/apis/graphql/graphql-cookbook">
          GraphQL API cookbook
        </a>
        .
      </p>

      <OrganizationSwitcher
        currentOrganization={organization}
        organizations={viewer.organizations.edges}
        onOrgChange={(org) => {
          setOrganization(org);
        }}
      />

      {EXAMPLES.map((example) => {
        return (
          // @ts-expect-error - TS2769 - No overload matches this call.
          <Panel key={example} style={{ borderLeftWidth: 4 }} className="mb4">
            <Panel.Section>
              <ExampleSection query={example} organization={organization} />
            </Panel.Section>
          </Panel>
        );
      })}
    </div>
  );
};

const ExamplesContainer = createFragmentContainer(Examples, {
  viewer: graphql`
    fragment Examples_viewer on Viewer {
      organizations(first: 100) {
        edges {
          node {
            id
            name
            slug
            permissions {
              pipelineView {
                allowed
                code
              }
            }
          }
        }
      }
    }
  `,
});

const ExamplesQuery = graphql`
  query ExamplesQuery {
    viewer {
      ...Examples_viewer
    }
  }
`;

const ExamplesQueryContainer = () => {
  return (
    <GraphqlExplorerLayout>
      <QueryRenderer
        environment={Environment.get()}
        query={ExamplesQuery}
        render={({ props }: { props: any }) =>
          props ? <ExamplesContainer {...props} /> : <SectionLoader />
        }
      />
    </GraphqlExplorerLayout>
  );
};

export default ExamplesQueryContainer;

import { useCallback, useContext } from "react";
import { OrganizationContext } from "./OrganizationContext";
import { Product, useProduct } from "./Steps/Complete";
import { useFetch } from "use-http";
import { useFormContext } from "react-hook-form";
import { Form } from "./form";

export default function SkipLink() {
  const { organization } = useContext(OrganizationContext);
  const { post, error } = useFetch({
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": window._csrf.token,
    },
  });
  const { handleSubmit, getValues } = useFormContext<Form>();
  const product = getValues("product");
  const { url } = useProduct(product as Product);

  const handleClick = useCallback(async function handleClick() {
    if (!organization) {
      throw new Error("Organization is required");
    }

    handleSubmit(async (data) => {
      if (window.analytics) {
        window.analytics.track("Form Skipped", {
          name: "Welcome",
          organization_uuid: organization.uuid,
          ...data,
        });
      }

      await post(`/organizations/${organization.slug}/welcome`, data);
      if (!error) {
        window.location.href = url(organization.slug);
      }
    })();
  }, []);

  if (!organization || !product) {
    return null;
  }

  return (
    <a
      className="text-charcoal-300 hover:text-purple transition-colors cursor-pointer"
      onClick={handleClick}
    >
      Skip
    </a>
  );
}

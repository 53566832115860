import { createFragmentContainer, graphql } from "react-relay";
import JobEvent from "../JobEvent";

type Props = {
  event: any;
  previousTimestamp: string | null | undefined;
};

function JobEventAssignedExpired({ event, previousTimestamp }: Props) {
  return (
    <JobEvent timestamp={event.timestamp} previousTimestamp={previousTimestamp}>
      <JobEvent.Label>Assigned Expired</JobEvent.Label>
      <JobEvent.Description>
        The agent took too long to accept the job, and so the job was
        re-scheduled.
      </JobEvent.Description>
    </JobEvent>
  );
}

export default createFragmentContainer(JobEventAssignedExpired, {
  event: graphql`
    fragment JobEventAssignedExpired_event on JobEvent {
      timestamp
    }
  `,
});

import React from "react";
import moment from "moment";
import classNames from "classnames";

import Duration from "app/components/shared/Duration";
import LegacySpinner from "app/components/shared/LegacySpinner";
import parseEmoji from "app/lib/parseEmoji";
import {
  DateLike,
  getComputerDateString,
  getLocalTimezoneAbbr,
} from "app/lib/date";

export function Log({
  className,
  theme,
  children,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & {
  theme?: string;
}) {
  return (
    <div
      className={classNames("JobLogOutputComponent", className)}
      data-theme={theme}
      {...props}
    >
      {children}
    </div>
  );
}

export function LogHeader({
  className,
  children,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={classNames("JobLogOutputComponent__Controls", className)}
      {...props}
    >
      {children}
    </div>
  );
}

export function LogControl({
  children,
  active,
  ...props
}: (
  | React.ButtonHTMLAttributes<HTMLButtonElement>
  | ({ href: string } & React.AnchorHTMLAttributes<HTMLAnchorElement>)
) & {
  active?: boolean;
}) {
  const className = classNames(
    active
      ? "JobLogOutputComponent__Button--Active"
      : "JobLogOutputComponent__Button",

    props.className,
  );

  if ("href" in props) {
    return (
      <a {...props} className={className}>
        {children}
      </a>
    );
  }

  return (
    <button {...props} className={className}>
      {children}
    </button>
  );
}

export function LogBody({
  className,
  children,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={classNames("JobLogOutputComponent__Body", className)}
      {...props}
    >
      {children}
    </div>
  );
}

export function LogLoading({
  className,
  children,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={classNames("JobLogOutputComponent__Loader", className)}
      {...props}
    >
      {children}
    </div>
  );
}

export function LogMessage({
  className,
  children,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={classNames("p3", className)} {...props}>
      {children}
    </div>
  );
}

export function LogContent({
  className,
  children,
  totalLines,
  ...props
}: React.HTMLAttributes<HTMLTableElement> & {
  totalLines?: number;
}) {
  return (
    <table
      className={classNames(
        `JobLogOutputComponent__Table--${totalLines?.toString().length}Column`,
        className,
      )}
      {...props}
    >
      {children}
    </table>
  );
}

export function LogGroup({
  className,
  children,
  ...props
}: React.HTMLAttributes<HTMLTableSectionElement>) {
  return (
    <tbody className={className} {...props}>
      {children}
    </tbody>
  );
}

export const LogGroupHeader = React.forwardRef<
  HTMLTableRowElement,
  React.HTMLAttributes<HTMLTableRowElement> & {
    highlighted?: boolean;
    deemphasized?: boolean;
  }
>(({ className, highlighted, deemphasized, children, ...props }, ref) => {
  let headerClassName = "JobLogOutputComponent__Header";
  if (highlighted) {
    headerClassName = "JobLogOutputComponent__Header--Highlighted";
  } else if (deemphasized) {
    headerClassName = "JobLogOutputComponent__Header--Deemphasized";
  }

  return (
    <tr ref={ref} className={classNames(headerClassName, className)} {...props}>
      {children}
    </tr>
  );
});

export function LogGroupName({
  className,
  name,
  children,
  ...props
}: React.TdHTMLAttributes<HTMLTableCellElement> & {
  name: string;
}) {
  return (
    <td
      className={classNames("JobLogOutputComponent__Header__Name", className)}
      {...props}
    >
      {children}

      <span
        dangerouslySetInnerHTML={{
          __html: parseEmoji(name, { escape: false }),
        }}
      />
    </td>
  );
}

export const LogLine = React.forwardRef<
  HTMLTableRowElement,
  React.HTMLAttributes<HTMLTableRowElement> & { highlighted?: boolean }
>(({ className, highlighted, children, ...props }, ref) => {
  return (
    <tr
      ref={ref}
      className={classNames(
        highlighted
          ? "JobLogOutputComponent__Line--Highlighted"
          : "JobLogOutputComponent__Line",
        className,
      )}
      {...props}
    >
      {children}
    </tr>
  );
});

export function LogLineContent({
  className,
  children,
  ...props
}: React.TdHTMLAttributes<HTMLTableCellElement>) {
  return (
    <td
      className={classNames("JobLogOutputComponent__Line__Content", className)}
      {...props}
    >
      {children}
    </td>
  );
}

export function LogLineNumber({
  className,
  number,
  children,
  ...props
}: React.TdHTMLAttributes<HTMLTableCellElement> & {
  number: number;
}) {
  return (
    <td
      className={classNames("JobLogOutputComponent__Number", className)}
      data-line-number={number}
      {...props}
    >
      {children}
    </td>
  );
}

export function LogTimestamp({
  className,
  time,
  utc,
  ...props
}: React.TdHTMLAttributes<HTMLTableCellElement> & {
  time?: DateLike | null;
  utc?: boolean | null;
}) {
  let timestamp: string | null = null;

  if (time) {
    timestamp = utc
      ? `${getComputerDateString(moment.utc(time))} UTC`
      : `${getComputerDateString(moment(time))} ${getLocalTimezoneAbbr()}`;
  }
  return (
    <td
      className={classNames(
        "JobLogOutputComponent__Timestamp",
        { "JobLogOutputComponent__Timestamp--UTC": utc },
        className,
      )}
      {...props}
    >
      {timestamp}
    </td>
  );
}

export function LogGroupTime({
  startedAt,
  finishedAt,
  finished,
  durationPercentage,
  className,
  ...props
}: React.TdHTMLAttributes<HTMLTableCellElement> & {
  startedAt?: DateLike | null;
  finishedAt?: DateLike | null;
  finished: boolean;
  durationPercentage?: number | null;
}) {
  // Set the width of the badge to it's percentage of time taken to run
  // the group. We only bother doing this if the percentage is more than
  // 20% (anything lower is kinda insignificant)
  let badgeStyle = {};

  if (durationPercentage && durationPercentage > 20) {
    badgeStyle = { width: `${durationPercentage}%` };
  } else if (startedAt && !finishedAt) {
    badgeStyle = { width: "20px" };
  }

  // Only show the timing node if we have the full timing information. We
  // could do live countdowns, but because we only get new data every 5
  // seconds, the counter would go up to 6 seconds, we get new data, then it
  // drops to 2 seconds. So it looks funky town.
  return (
    <td
      className={classNames("JobLogOutputComponent__Time", className)}
      {...props}
    >
      {startedAt && (
        <span className="JobLogOutputComponent__Time__Badge" style={badgeStyle}>
          {finishedAt ? (
            <Duration.Short from={startedAt} to={finishedAt} />
          ) : finished ? null : (
            <LegacySpinner />
          )}
        </span>
      )}
    </td>
  );
}

import { PureComponent } from "react";
import Badge from "app/components/shared/Badge";

type Props = {
  forumTopicUrl: string;
};

export default class BetaBadge extends PureComponent<Props> {
  render() {
    return (
      <a title="Community Forum Topic" href={this.props.forumTopicUrl}>
        <Badge
          outline={true}
          className="btn-outline bg-white regular very-dark-gray"
        >
          Beta
        </Badge>
      </a>
    );
  }
}

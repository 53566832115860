import { twMerge } from "tailwind-merge";

interface Props {
  className?: string;
  project: {
    provider: {
      id?: string;
    };
  };
  pullRequest?: {
    url: string;
    id: string;
  };
}

export function PullRequest({ pullRequest, project, className }: Props) {
  if (!pullRequest) {
    return null;
  }

  return (
    <a
      className={twMerge(
        "color-inherit hover-color-inherit text-xs",
        className,
      )}
      href={pullRequest.url}
    >
      {project.provider.id === "gitlab" ? "Merge Request" : "Pull Request"} #
      {pullRequest.id}
    </a>
  );
}

/**
 * @generated SignedSource<<f2417be3307130a64400d0b4336c0757>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type JobStates = "ACCEPTED" | "ASSIGNED" | "BLOCKED" | "BLOCKED_FAILED" | "BROKEN" | "CANCELED" | "CANCELING" | "EXPIRED" | "FINISHED" | "LIMITED" | "LIMITING" | "PENDING" | "RUNNING" | "SCHEDULED" | "SKIPPED" | "TIMED_OUT" | "TIMING_OUT" | "UNBLOCKED" | "UNBLOCKED_FAILED" | "WAITING" | "WAITING_FAILED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AgentJobRow_job$data = {
  readonly passed: boolean;
  readonly scheduledAt: any | null;
  readonly softFailed: boolean;
  readonly startedAt: any | null;
  readonly state: JobStates;
  readonly url: string;
  readonly uuid: string;
  readonly " $fragmentSpreads": FragmentRefs<"JobTitle_job">;
  readonly " $fragmentType": "AgentJobRow_job";
};
export type AgentJobRow_job$key = {
  readonly " $data"?: AgentJobRow_job$data;
  readonly " $fragmentSpreads": FragmentRefs<"AgentJobRow_job">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AgentJobRow_job",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "JobTitle_job"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "uuid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "passed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "scheduledAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "softFailed",
      "storageKey": null
    }
  ],
  "type": "JobTypeCommand",
  "abstractKey": null
};

(node as any).hash = "b5ec618a1082f3560d33c970279fc426";

export default node;

import EventEmitter from "eventemitter3";
import LegacyDispatcher from "app/lib/legacyDispatcher";
import Database from "app/lib/Database";
import string from "app/lib/string";

type jQueryAjax = Record<any, any>;

type jQueryPromise = {
  done: (callback: (result: Array<any>) => unknown) => jQueryPromise;
};

declare let jQuery: {
  ajax: (options: {
    url: string;
    dataType?: string;
    method: string;
    data: unknown;
    success: () => unknown;
    error: () => unknown;
  }) => jQueryAjax;
  when: (ajax: jQueryAjax) => jQueryPromise;
};

type Project = {
  id: string;
};

type Settings = Record<any, any>;

class UserStore {
  pipelineSettings: Settings;
  eventEmitter: EventEmitter;

  constructor() {
    this.pipelineSettings = {};
    this.eventEmitter = new EventEmitter();

    this.registerWithDispatcher();
  }

  emitChange() {
    return this.eventEmitter.emit("change");
  }

  updateProjectSettings(project: Project, settings: Settings) {
    const data = { _method: "put" } as const;

    // Convert the keys to underscore case
    Object.entries(settings).forEach(([key, value]: [any, any]) => {
      // Uber hack so jQuery will send an empty array. We need these keys to be
      // present in the JSON payload that we send, and if we send an empty array
      // to jQuery, it'll drop it from post.
      if (Array.isArray(value) && value.length === 0) {
        value = [""];
      }

      data["pipeline_settings[" + string.underscore(key) + "]"] = value;
    });

    const ajax = jQuery.ajax({
      url: "/user/pipeline_settings/" + project.id.toString(),
      dataType: "json",
      method: "POST",
      // @ts-expect-error - TS2345 - Argument of type '{ url: string; dataType: string; method: string; headers: { "X-Buildkite-Frontend-Version": string; }; data: { readonly _method: "put"; }; success: () => void; error: () => void; }' is not assignable to parameter of type '{ url: string; dataType?: string | undefined; method: string; data: unknown; success: () => unknown; error: () => unknown; }'.
      headers: { "X-Buildkite-Frontend-Version": BUILDKITE_FRONTEND_VERSION },
      data: data,
      success: function () {
        // @ts-expect-error - TS2554 - Expected 6 arguments, but got 2.
        LegacyDispatcher.emit("pipeline_settings:update:success", {
          project: { id: project.id },
          settings: settings,
        });
      },
      error: function () {
        // @ts-expect-error - TS2554 - Expected 6 arguments, but got 2.
        LegacyDispatcher.emit("pipeline_settings:update:error", {
          project: { id: project.id },
          settings: settings,
        });
      },
    });

    // Return a promise
    return jQuery.when(ajax);
  }

  starProjectBranch(project: Project, branch: string) {
    const ajax = jQuery.ajax({
      url: "/user/pipeline_settings/" + project.id.toString() + "/star",
      dataType: "json",
      method: "POST",
      // @ts-expect-error - TS2345 - Argument of type '{ url: string; dataType: string; method: string; headers: { "X-Buildkite-Frontend-Version": string; }; data: { branch: string; }; success: () => void; error: () => void; }' is not assignable to parameter of type '{ url: string; dataType?: string | undefined; method: string; data: unknown; success: () => unknown; error: () => unknown; }'.
      headers: { "X-Buildkite-Frontend-Version": BUILDKITE_FRONTEND_VERSION },
      data: { branch: branch },
      success: function () {
        // @ts-expect-error - TS2554 - Expected 6 arguments, but got 2.
        LegacyDispatcher.emit("pipeline_settings:update:success", {
          project: { id: project.id },
        });
      },
      error: function () {
        // @ts-expect-error - TS2554 - Expected 6 arguments, but got 2.
        LegacyDispatcher.emit("pipeline_settings:update:error", {
          project: { id: project.id },
        });
      },
    });

    // Return a promise
    return jQuery.when(ajax);
  }

  unstarProjectBranch(project: Project, branch: string) {
    const ajax = jQuery.ajax({
      url: "/user/pipeline_settings/" + project.id.toString() + "/unstar",
      dataType: "json",
      method: "POST",
      // @ts-expect-error - TS2345 - Argument of type '{ url: string; dataType: string; method: string; headers: { "X-Buildkite-Frontend-Version": string; }; data: { branch: string; }; success: () => void; error: () => void; }' is not assignable to parameter of type '{ url: string; dataType?: string | undefined; method: string; data: unknown; success: () => unknown; error: () => unknown; }'.
      headers: { "X-Buildkite-Frontend-Version": BUILDKITE_FRONTEND_VERSION },
      data: { branch: branch },
      success: function () {
        // @ts-expect-error - TS2554 - Expected 6 arguments, but got 2.
        LegacyDispatcher.emit("pipeline_settings:update:success", {
          project: { id: project.id },
        });
      },
      error: function () {
        // @ts-expect-error - TS2554 - Expected 6 arguments, but got 2.
        LegacyDispatcher.emit("pipeline_settings:update:error", {
          project: { id: project.id },
        });
      },
    });

    // Return a promise
    return jQuery.when(ajax);
  }

  registerWithDispatcher() {
    let existingSettings;
    LegacyDispatcher.on("pipeline_settings:update", ({ project, settings }) => {
      existingSettings = this.pipelineSettings[project.id];
      const updatedSettings = (this.pipelineSettings[project.id] =
        Object.assign(existingSettings, settings));

      this.updateProjectSettings(project, updatedSettings);

      this.emitChange();
    });

    LegacyDispatcher.on("pipeline_settings:update:success", () =>
      this.emitChange(),
    );

    LegacyDispatcher.on("pipeline_settings:update:error", () =>
      this.emitChange(),
    );

    LegacyDispatcher.on("project_branch:star", ({ project, branch }) => {
      existingSettings = this.pipelineSettings[project.id];
      existingSettings.starredBranches.push(branch);
      existingSettings.unstarredBranches =
        existingSettings.unstarredBranches.filter(
          (unstarredBranch) => unstarredBranch !== branch,
        );

      this.starProjectBranch(project, branch);

      this.emitChange();
    });

    LegacyDispatcher.on("project_branch:unstar", ({ project, branch }) => {
      existingSettings = this.pipelineSettings[project.id];
      existingSettings.starredBranches =
        existingSettings.starredBranches.filter(
          (starredBranch) => starredBranch !== branch,
        );
      existingSettings.unstarredBranches.push(branch);

      this.unstarProjectBranch(project, branch);

      this.emitChange();
    });
  }

  bootstrap(project: Project, settings: Settings) {
    return (this.pipelineSettings[project.id] = Database.parse(settings));
  }

  addChangeListener(callback: any) {
    return this.eventEmitter.addListener("change", callback);
  }

  removeChangeListener(callback: any) {
    return this.eventEmitter.removeListener("change", callback);
  }

  getEmailSettings(project: Project) {
    return this.pipelineSettings[project.id];
  }

  getStarredBranches(project: Project) {
    let settings;

    if ((settings = this.pipelineSettings[project.id])) {
      return settings.starredBranches;
    }

    return [];
  }
}

export default new UserStore();

import CodeBlock from "app/components/docs/shared/code-block";
import Heading from "app/components/docs/shared/heading";
import Link from "app/components/docs/shared/link";
import awsLaunchStackButtonImage from "app/images/aws-launch-stack-button.svg";

import "highlight.js/styles/atom-one-light.css";

type Props = {
  token?: string;
  className?: string;
  style?: any;
};

AWSQuickStart.title = "AWS";

function AWSQuickStart(props: Props) {
  const { className, style } = props;

  return (
    <div className={className} style={style}>
      <Heading>Use our pre-built Elastic CI Stack for AWS</Heading>
      <p>
        The{" "}
        <Link href="https://github.com/buildkite/elastic-ci-stack-for-aws">
          Elastic CI Stack for AWS
        </Link>{" "}
        is a pre–built CloudFormation template that gives you an autoscaling
        Agent cluster including Docker, S3 and Cloudwatch integration.{" "}
        <Link href="https://buildkite.com/docs/tutorials/elastic-ci-stack-aws">
          Follow the tutorial
        </Link>{" "}
        and launch the stack using the details below:
      </p>
      <p>
        <Link
          href="https://console.aws.amazon.com/cloudformation/home#/stacks/new?stackName=buildkite&templateURL=https://s3.amazonaws.com/buildkite-aws-stack/latest/aws-stack.yml"
          // @ts-expect-error - TS2322 - Type '{ children: Element; href: string; target: string; rel: string; }' is not assignable to type 'IntrinsicAttributes & Props'.
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={awsLaunchStackButtonImage}
            alt="Launch Buildkite Elastic CI Stack"
            width="144"
            height="27"
          />
        </Link>
      </p>

      <p>
        You will need your <strong>BuildkiteAgentToken</strong> when launching
        the stack:
      </p>
      <CodeBlock>
        <span className="hljs-attribute">
          {props.token || "INSERT-YOUR-AGENT-TOKEN-HERE"}
        </span>
        <br />
      </CodeBlock>

      <p className="rounded bg-silver p2">
        We recommend following AWS best practice by setting up a separate
        development AWS account and using role switching and consolidated
        billing. Please see the AWS tutorial on{" "}
        <Link href="https://docs.aws.amazon.com/IAM/latest/UserGuide/tutorial_cross-account-with-roles.html">
          Delegate access across AWS accounts using IAM roles
        </Link>{" "}
        and{" "}
        <Link href="https://github.com/buildkite/elastic-ci-stack-for-aws">
          Elastic CI Stack repository on GitHub
        </Link>{" "}
        for full documentation.
      </p>

      <Heading>Install the Agent on your own AWS instance</Heading>
      <p className="rounded bg-silver p2">
        For Amazon Linux, see the{" "}
        <Link href="/docs/agent/redhat">Red Hat/CentOS agent docs</Link> for
        more details. For other operating systems, please see the tab
        accordingly.
      </p>
    </div>
  );
}

export default AWSQuickStart;

import { graphql, QueryRenderer } from "react-relay";
import Environment from "app/lib/relay/environment";
import Section from "./Section";
import List from "./List";

const AUDIT_LOG_EVENTS_QUERY = graphql`
  query IndexQuery($organization: ID!) {
    organization(slug: $organization) {
      name
      slug
      ...List_organization
    }
  }
`;

const AuditLogsPage = ({ props }: any) => (
  <Section name={props.organization.name} slug={props.organization.slug}>
    <List {...props} />
  </Section>
);

const AuditLogsPageRenderer = ({ slug }: { slug: string }) => (
  <QueryRenderer
    fetchPolicy="store-and-network"
    environment={Environment.get()}
    query={AUDIT_LOG_EVENTS_QUERY}
    variables={{ organization: slug }}
    render={({ props, error }) => {
      if (error || !props) {
        return null;
      }
      return <AuditLogsPage props={props} />;
    }}
  />
);

export default AuditLogsPageRenderer;

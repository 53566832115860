import RunTable from "app/components/analytics/execution/RunTable";

type Props = {
  job: {
    testAnalytics: {
      failedExecutionsUrl?: string;
    };
  };
};

export default function TestAnalytics({ job }: Props) {
  const { failedExecutionsUrl } = job.testAnalytics;
  const durationTooltip =
    "Duration represents the total time taken for the test to execute, including setup and teardown time.";

  return (
    <div className="p-1">
      {failedExecutionsUrl ? (
        <RunTable
          endpoint={failedExecutionsUrl}
          emptyState="failed"
          tooltip={durationTooltip}
        />
      ) : (
        "Test Analytics is not available on this job"
      )}
    </div>
  );
}

/**
 * @generated SignedSource<<f719966ed48b984c31571c9e555c148f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JobEventAccepted_event$data = {
  readonly actor: {
    readonly node: {
      readonly hostname?: string | null;
      readonly isDeprecated?: boolean;
      readonly operatingSystem?: {
        readonly name: string;
      } | null;
      readonly version?: string | null;
      readonly versionHasKnownIssues?: boolean;
      readonly " $fragmentSpreads": FragmentRefs<"AgentBadge_agent" | "AgentUpgradeBanner_agent">;
    } | null;
  };
  readonly timestamp: any;
  readonly " $fragmentType": "JobEventAccepted_event";
};
export type JobEventAccepted_event$key = {
  readonly " $data"?: JobEventAccepted_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"JobEventAccepted_event">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "JobEventAccepted_event",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timestamp",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "JobEventActor",
      "kind": "LinkedField",
      "name": "actor",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "AgentBadge_agent"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "AgentUpgradeBanner_agent"
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hostname",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "version",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "versionHasKnownIssues",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isDeprecated",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "OperatingSystem",
                  "kind": "LinkedField",
                  "name": "operatingSystem",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "Agent",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "JobEvent",
  "abstractKey": "__isJobEvent"
};

(node as any).hash = "7a5afe0c18fb9948b31d6bc9cc203ed6";

export default node;

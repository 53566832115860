import { useState, useEffect } from "react";
import useFetch from "use-http";

export type PaginationProps = {
  currentData: Array<any>;
  metadata?: any;
  pageCount: number;
  handleOnChange: (page: PageProps) => void;
  loading: boolean;
  error: boolean;
};

type Props = {
  endpoint?: string;
  data?: Array<any>;
  refetch?: boolean;
};

type PageProps = {
  selected: number;
};

function usePagination({ data = [], endpoint, refetch }: Props) {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState(data);
  const [metadata, setMetadata] = useState({});
  const [pageCount, setPageCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleOnChange = (page: PageProps) => {
    const newPage = page.selected + 1; // ReactPaginate has an initial page of 0 rather than 1
    setCurrentPage(newPage);
  };

  const loadData = async () => {
    if (!endpoint) {
      return;
    }

    try {
      const newData = await get();

      if (response.ok && newData) {
        const { data, metadata, total_pages } = newData;

        if (data) {
          setCurrentData(data);
        }

        if (metadata) {
          setMetadata(metadata);
        }

        if (total_pages && currentPage === 1) {
          setPageCount(total_pages);
        }
      } else {
        setError(true);
      }
    } catch (error) {
      setError(true);
    }

    setLoading(false);
  };

  if (!endpoint) {
    useEffect(() => {
      setLoading(false);
    }, []);

    return {
      currentData: data,
      metadata,
      pageCount,
      currentPage,
      handleOnChange,
      loading,
      error,
    };
  }

  const url = new URL(endpoint);
  url.searchParams.set("page", `${currentPage}`);

  const { get, response, cache } = useFetch(url.href);

  useEffect(() => {
    setLoading(true);
    loadData();
  }, [currentPage]);

  useEffect(() => {
    if (refetch) {
      // Clear useFetch cache so we get the correct
      // data on previously loaded pages
      cache.clear();
      loadData();
    }
  }, [refetch]);

  return {
    currentData,
    metadata,
    pageCount,
    currentPage,
    handleOnChange,
    loading,
    error,
  };
}

export default usePagination;

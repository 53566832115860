/**
 * @generated SignedSource<<3a770a0b7bc12fe4712f154f2b5c0f5f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MyBuildsRefetchQuery$variables = {
  includeBuildCounts: boolean;
  includeBuilds: boolean;
};
export type MyBuildsRefetchQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"MyBuilds_viewer">;
  } | null;
};
export type MyBuildsRefetchQuery = {
  response: MyBuildsRefetchQuery$data;
  variables: MyBuildsRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includeBuildCounts"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includeBuilds"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "count",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "MyBuildsRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "includeBuildCounts",
                "variableName": "includeBuildCounts"
              },
              {
                "kind": "Variable",
                "name": "includeBuilds",
                "variableName": "includeBuilds"
              }
            ],
            "kind": "FragmentSpread",
            "name": "MyBuilds_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "MyBuildsRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "condition": "includeBuilds",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "first",
                        "value": 5
                      }
                    ],
                    "concreteType": "BuildConnection",
                    "kind": "LinkedField",
                    "name": "builds",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BuildEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Build",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "uuid",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "message",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "state",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "blockedState",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "createdAt",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "canceledAt",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "finishedAt",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "url",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "commit",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Pipeline",
                                "kind": "LinkedField",
                                "name": "pipeline",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "name",
                                    "storageKey": null
                                  },
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "builds(first:5)"
                  }
                ]
              },
              {
                "condition": "includeBuildCounts",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "alias": "runningBuilds",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "state",
                        "value": [
                          "RUNNING",
                          "FAILING"
                        ]
                      }
                    ],
                    "concreteType": "BuildConnection",
                    "kind": "LinkedField",
                    "name": "builds",
                    "plural": false,
                    "selections": (v3/*: any*/),
                    "storageKey": "builds(state:[\"RUNNING\",\"FAILING\"])"
                  },
                  {
                    "alias": "scheduledBuilds",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "state",
                        "value": "SCHEDULED"
                      }
                    ],
                    "concreteType": "BuildConnection",
                    "kind": "LinkedField",
                    "name": "builds",
                    "plural": false,
                    "selections": (v3/*: any*/),
                    "storageKey": "builds(state:\"SCHEDULED\")"
                  }
                ]
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ad28d734b8256375680cb951b09d2e64",
    "id": null,
    "metadata": {},
    "name": "MyBuildsRefetchQuery",
    "operationKind": "query",
    "text": "query MyBuildsRefetchQuery(\n  $includeBuilds: Boolean!\n  $includeBuildCounts: Boolean!\n) {\n  viewer {\n    ...MyBuilds_viewer_4wmA7A\n    id\n  }\n}\n\nfragment MyBuilds_viewer_4wmA7A on Viewer {\n  user {\n    id\n    builds(first: 5) @include(if: $includeBuilds) {\n      edges {\n        node {\n          id\n          ...build_build\n        }\n      }\n    }\n    runningBuilds: builds(state: [RUNNING, FAILING]) @include(if: $includeBuildCounts) {\n      count\n    }\n    scheduledBuilds: builds(state: SCHEDULED) @include(if: $includeBuildCounts) {\n      count\n    }\n  }\n}\n\nfragment build_build on Build {\n  id\n  uuid\n  message\n  state\n  blockedState\n  createdAt\n  canceledAt\n  finishedAt\n  url\n  commit\n  pipeline {\n    name\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "f78e04166ba11d11bfe5ad50a9e0e51a";

export default node;

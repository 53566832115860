/**
 * @generated SignedSource<<7cb57e829d1d28aa4a99cd8e1e0a1963>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AuditActorType = "AGENT" | "USER" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type actorSection_auditEvent$data = {
  readonly actor: {
    readonly name: string | null;
    readonly node: {
      readonly avatar?: {
        readonly url: string;
      };
      readonly name?: string;
    } | null;
    readonly type: AuditActorType | null;
    readonly uuid: string;
  } | null;
  readonly " $fragmentType": "actorSection_auditEvent";
};
export type actorSection_auditEvent$key = {
  readonly " $data"?: actorSection_auditEvent$data;
  readonly " $fragmentSpreads": FragmentRefs<"actorSection_auditEvent">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "actorSection_auditEvent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AuditActor",
      "kind": "LinkedField",
      "name": "actor",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "uuid",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Avatar",
                  "kind": "LinkedField",
                  "name": "avatar",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "url",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "User",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                (v0/*: any*/)
              ],
              "type": "Agent",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AuditEvent",
  "abstractKey": null
};
})();

(node as any).hash = "a39f5a31426b7e9fc84dc618d596ea73";

export default node;

import { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import RevealableDownChevron from "./Icon/RevealableDownChevron";

export default class CollapsableArea extends Component {
  static propTypes = {
    className: PropTypes.string,
    initialOpen: PropTypes.bool,
    children: PropTypes.node.isRequired,
    label: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = { open: this.props.initialOpen };
  }

  render() {
    return (
      <details
        className={classNames(this.props.className, "details-reset")}
        open={this.state.open}
        onToggle={this.handleToggle}
      >
        <summary className="unstyled-button bold lime hover-dark-lime outline-none">
          {this.props.label}
          <RevealableDownChevron
            revealed={this.state.isOpen}
            style={{ marginLeft: 6, marginTop: -1 }}
          />
        </summary>
        <div className="mt1">{this.props.children}</div>
      </details>
    );
  }

  handleToggle = (event) => {
    this.setState({ isOpen: event.target.open });
  };
}

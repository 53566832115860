import { useCallback } from "react";
import styled from "styled-components";
import classNames from "classnames";

import useCopyToClipboard from "app/components/analytics/shared/hooks/useCopyToClipboard";
import Icon from "app/components/shared/Icon";

type CopyButtonProps = {
  text: string;
  className?: string;
};

const Button = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: normal;
  position: relative;
  z-index: 1;
  transition: color 0.1s ease-in-out;
  padding: 0;
  margin: 0;
  overflow: hidden;
  max-width: 100%;
  color: var(--charcoal-600);

  .text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }

  .copy-icon {
    transition: opacity 0.2s;
    color: inherit;
  }

  &:hover {
    text-decoration: underline;
  }

  svg {
    min-width: 14px;
    width: 14px;
    margin-left: 5px;
    stroke-width: 1.2;
    position: relative;
    z-index: 1;
    color: inherit;
  }
`;

const CopyTextButton = ({ text, className }: CopyButtonProps) => {
  const { copied, handleCopy } = useCopyToClipboard();

  const handleCopyClick = useCallback((event) => {
    event.currentTarget.blur();
    handleCopy(text);
  }, []);

  return (
    <Button
      className={classNames("btn", className)}
      onClick={handleCopyClick}
      title={`Copy ${text}`}
    >
      <span className="relative text">{text}</span>
      <span className="copy-icon">
        <Icon
          icon={
            copied
              ? "heroicons/outline/check"
              : "heroicons/outline/document-duplicate"
          }
        />
      </span>
    </Button>
  );
};

export default CopyTextButton;

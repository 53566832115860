/* eslint-disable react/display-name */

import PropTypes from "prop-types";

import Chooser from "app/components/shared/Chooser";
import Dropdown from "app/components/shared/Dropdown";
import PermissionDescription from "app/components/shared/PermissionDescription";
import PermissionSelectOptionDescriptions from "app/components/shared/PermissionSelectOptionDescriptions";

const MANAGE_BUILD_AND_READ = "MANAGE_BUILD_AND_READ";
const BUILD_AND_READ = "BUILD_AND_READ";
const READ_ONLY = "READ_ONLY";

const getLabel = (value) => {
  switch (value) {
    case MANAGE_BUILD_AND_READ:
      return "Full Access";
    case BUILD_AND_READ:
      return "Build & Read";
    case READ_ONLY:
      return "Read Only";
  }
};

const DropdownWithChooser = ({ saving, selected, onAccessLevelChange }) => (
  <Dropdown width={270}>
    <div className="underline-dotted cursor-pointer inline-block regular">
      {getLabel(selected)}
    </div>

    <Chooser selected={selected} onSelect={onAccessLevelChange}>
      <Chooser.SelectOption
        value={MANAGE_BUILD_AND_READ}
        saving={saving === MANAGE_BUILD_AND_READ}
        selected={selected === MANAGE_BUILD_AND_READ}
        label={getLabel(MANAGE_BUILD_AND_READ)}
        description={
          <PermissionSelectOptionDescriptions>
            <PermissionDescription
              allowed={true}
              permission="view and create builds"
            />
            <PermissionDescription
              allowed={true}
              permission="edit pipeline settings"
            />
          </PermissionSelectOptionDescriptions>
        }
      />
      <Chooser.SelectOption
        value={BUILD_AND_READ}
        saving={saving === BUILD_AND_READ}
        selected={selected === BUILD_AND_READ}
        label={getLabel(BUILD_AND_READ)}
        description={
          <PermissionSelectOptionDescriptions>
            <PermissionDescription
              allowed={true}
              permission="view and create builds"
            />
            <PermissionDescription
              allowed={false}
              permission="edit pipeline settings"
            />
          </PermissionSelectOptionDescriptions>
        }
      />
      <Chooser.SelectOption
        value={READ_ONLY}
        label={getLabel(READ_ONLY)}
        saving={saving === READ_ONLY}
        selected={selected === READ_ONLY}
        description={
          <PermissionSelectOptionDescriptions>
            <PermissionDescription allowed={true} permission="view builds" />
            <PermissionDescription allowed={false} permission="create builds" />
            <PermissionDescription
              allowed={false}
              permission="edit pipeline settings"
            />
          </PermissionSelectOptionDescriptions>
        }
      />
    </Chooser>
  </Dropdown>
);

const AccessLevel = ({ saving, teamPipeline, onAccessLevelChange }) => {
  if (teamPipeline.permissions.teamPipelineUpdate.allowed) {
    return (
      <DropdownWithChooser
        saving={saving}
        selected={teamPipeline.accessLevel}
        onAccessLevelChange={onAccessLevelChange}
      />
    );
  }

  return (
    <span className="dark-gray">{getLabel(teamPipeline.accessLevel)}</span>
  );
};

AccessLevel.propTypes = {
  teamPipeline: PropTypes.shape({
    accessLevel: PropTypes.string.isRequired,
    permissions: PropTypes.shape({
      teamPipelineUpdate: PropTypes.shape({
        allowed: PropTypes.bool.isRequired,
      }).isRequired,
    }),
  }).isRequired,
  onAccessLevelChange: PropTypes.func.isRequired,
  saving: PropTypes.string,
};

export default AccessLevel;

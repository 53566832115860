import * as React from "react";
import moment from "moment";

import { createFragmentContainer, commitMutation, graphql } from "react-relay";
import Environment from "app/lib/relay/environment";

import Emojify from "../shared/Emojify";
import Icon from "../shared/Icon";

import FlashesStore from "../../stores/FlashesStore";

const CONFERENCE_START = moment.parseZone("2022-12-05T12:00:00-08:00");
const CONFERENCE_START_LOCAL = CONFERENCE_START.clone().local();
const CONFERENCE_END = moment.parseZone("2022-12-06T17:30:00-08:00");
const CONFERENCE_END_LOCAL = CONFERENCE_END.clone().local();

type Props = {
  viewer: any | null | undefined;
};

type State = {
  dismissing: boolean;
};

class ConferenceAnnouncementNotice extends React.PureComponent<Props, State> {
  state = {
    dismissing: false,
  };

  handleDismissClick = (event: any) => {
    event.preventDefault();

    if (!this.props.viewer) {
      return;
    }

    this.setState({ dismissing: true }, () => {
      const environment = Environment.get();

      if (!this.props.viewer) {
        return;
      }

      const input = { id: this.props.viewer.notice.id } as const;

      commitMutation(environment, {
        mutation: graphql`
          mutation ConferenceAnnouncementNoticeNoticeDismissMutation(
            $input: NoticeDismissInput!
          ) {
            noticeDismiss(input: $input) {
              notice {
                id
                dismissedAt
              }
            }
          }
        `,
        variables: { input },
        onCompleted: this.handleMutationComplete,
        onError: this.handleMutationError,
      });
    });
  };

  handleMutationComplete = () => {
    this.setState({ dismissing: false });
  };

  handleMutationError = (error: any) => {
    if (error) {
      // @ts-expect-error - TS2339 - Property 'ERROR' does not exist on type 'FlashesStore'.
      FlashesStore.flash(FlashesStore.ERROR, error);
    }

    this.setState({ dismissing: false });
  };

  renderDates() {
    return `${CONFERENCE_START_LOCAL.format(
      "MMMM D",
    )}-${CONFERENCE_END_LOCAL.format("D")}`;
  }

  render() {
    if (
      !this.props.viewer ||
      this.props.viewer.notice.dismissedAt ||
      moment().isAfter(CONFERENCE_END)
    ) {
      return null;
    }

    return (
      <div
        className="mb4 border border-lime rounded"
        style={{
          backgroundColor: "#E8FAF2",
          boxShadow: "0px 2px 8px #14CC8033",
        }}
      >
        <div className="flex items-center my1 mx3">
          <div className="flex flex-auto flex-wrap items-top justify-end">
            <div
              className="flex flex-auto items-top"
              style={{ flexBasis: 300 }}
            >
              <Emojify
                text="📣"
                className="flex-none ml1 mr3 line-height-2"
                style={{ fontSize: 40 }}
              />
              <div className="flex-auto mr3 py1">
                <strong>The agenda for Unblock 2022 is now live!</strong>{" "}
                Experience the 2-day virtual conference on {this.renderDates()},
                and hear from engineering and infrastructure leaders solving
                some of the most interesting challenges in software.
              </div>
            </div>
            <a
              className="btn btn-primary self-center my1"
              href="https://buildkite.com/unblock/schedule?utm_campaign=UnblockConf%202022&utm_source=Buildkite-In-App&utm_medium=In-App&utm_term=banner&utm_content=unblock22"
              target="_blank"
              rel="noopener noreferrer"
            >
              Check out the agenda
            </a>
          </div>
          <button
            className="btn py4 px3"
            style={{ marginRight: -15 }}
            onClick={this.handleDismissClick}
            disabled={this.state.dismissing}
          >
            <Icon className="mx-auto" icon="close" title="Close" />
          </button>
        </div>
      </div>
    );
  }
}

export default createFragmentContainer(ConferenceAnnouncementNotice, {
  viewer: graphql`
    fragment ConferenceAnnouncementNotice_viewer on Viewer {
      notice(namespace: EVENT, scope: "unblockconf-2022-schedule") {
        id
        dismissedAt
      }
    }
  `,
});

import { useCallback, useState } from "react";
import FlakyInstances from "../FlakyInstances";
import TestTable from "../../execution/TestTable";
import TabControl, { TabButton } from "app/components/analytics/shared/Tabs";
import TestEvents from "../TestEvents";

type TabsProps = {
  flaky_instances_endpoint: string;
  flaky_instances_executions_endpoint?: string;
  failed_executions_endpoint: string;
  tooltip: string;
  show_test_events_ui: boolean;
  show_flaky_tests_ui: boolean;
  test_events_endpoint: string;
};

const TABS = {
  FLAKY: "flaky",
  FAILED: "failed",
  EVENTS: "events",
};

const Tabs = ({
  flaky_instances_endpoint,
  flaky_instances_executions_endpoint,
  failed_executions_endpoint,
  tooltip,
  show_test_events_ui,
  show_flaky_tests_ui,
  test_events_endpoint,
}: TabsProps) => {
  const getDefaultAnchor = () => {
    const initialAnchor = window.location.hash.replace("#", "");

    if (
      initialAnchor === TABS.FAILED ||
      (initialAnchor === TABS.FLAKY && show_flaky_tests_ui)
    ) {
      return initialAnchor;
    }

    return show_flaky_tests_ui ? TABS.FLAKY : TABS.FAILED;
  };

  const anchor = getDefaultAnchor();

  const [activeTab, setActiveTab] = useState(anchor);
  const handleFlakyTabClick = useCallback(() => setActiveTab(TABS.FLAKY), []);
  const handleFailedTestExecutionsTabClick = useCallback(() => {
    setActiveTab(TABS.FAILED);
  }, []);
  const handleEventsTabClick = useCallback(() => setActiveTab(TABS.EVENTS), []);

  return (
    <div style={{ marginTop: "60px" }}>
      <TabControl className="justify-center">
        {show_flaky_tests_ui && (
          <TabButton
            active={activeTab === TABS.FLAKY}
            href={`#${TABS.FLAKY}`}
            onClick={handleFlakyTabClick}
            icon="fire"
          >
            Flaky instances
          </TabButton>
        )}
        <TabButton
          active={activeTab === TABS.FAILED}
          href={`#${TABS.FAILED}`}
          onClick={handleFailedTestExecutionsTabClick}
          icon="cross-clipboard"
        >
          Failed test executions
        </TabButton>
        {show_test_events_ui && (
          <TabButton
            active={activeTab === TABS.EVENTS}
            href={`#${TABS.EVENTS}`}
            onClick={handleEventsTabClick}
            icon="auditLog"
          >
            Test Events
          </TabButton>
        )}
      </TabControl>
      {activeTab === TABS.FLAKY && show_flaky_tests_ui && (
        <FlakyInstances
          endpoint={flaky_instances_endpoint}
          executionsEndpoint={flaky_instances_executions_endpoint}
        />
      )}
      {activeTab === TABS.FAILED && (
        <TestTable endpoint={failed_executions_endpoint} tooltip={tooltip} />
      )}
      {activeTab === TABS.EVENTS && show_test_events_ui && (
        <TestEvents endpoint={test_events_endpoint} />
      )}
    </div>
  );
};

export default Tabs;

import * as React from "react";
import Emojify from "app/components/shared/Emojify";
import classNames from "classnames";

type Variant = "default" | "blue-gradient" | "orange-gradient";

type Props = {
  heading: string;
  subheading?: React.ReactNode;
  emoji?: string;
  fullscreen?: boolean;
  variant?: Variant;
  link?: React.ReactNode;
};

export default function EmptyState(props: Props) {
  const { variant = "default" } = props;

  const variantStyles: Record<Variant, string> = {
    default: "bg-slate-100",
    "blue-gradient": "bg-empty-state-blue-gradient",
    "orange-gradient": "bg-empty-state-orange-gradient",
  };

  return (
    <div
      className={classNames(
        "flex flex-column gap-5 relative min-h-[320px] justify-center items-center p-5 rounded-3 antialiased",
        variantStyles[variant],
        props.fullscreen ? "h-full" : "h-auto",
      )}
    >
      {props.emoji && (
        <span className="ui-emoji">
          <Emojify
            text={props.emoji}
            style={{ fontSize: 38, lineHeight: 1 }}
            title={props.heading}
          />
        </span>
      )}

      <div className="flex justify-center items-center flex-column gap-2.5">
        <h3 className="m-0 text-center text-lg">{props.heading}</h3>
        {props.subheading && (
          <p className="m-0 text-center max-w-[360px]">{props.subheading}</p>
        )}
      </div>
      {props.link}
    </div>
  );
}

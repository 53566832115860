import { useEffect, useState } from "react";
import useFetch from "use-http";

import { FlakyTest, Team } from "../../shared/type";
import EmptyState from "app/components/shared/EmptyState";
import { LoadingPanels } from "../../shared/LoadingStates";
import ErrorState from "../../shared/ErrorState";
import FlakyTestRow from "../FlakyTestRow";
import FeedbackMessage from "../../shared/FeedbackMessage";

type TestAssignmentProps = {
  teams: Team[];
  currentAccountMember: boolean;
  endpoint: string;
  isFlaky: boolean;
};

const TestAssignments = ({
  teams,
  currentAccountMember,
  endpoint,
  isFlaky,
}: TestAssignmentProps) => {
  const [myAssignments, setMyAssignments] = useState<FlakyTest[]>([]);
  const [loading, setLoading] = useState(true);

  const manageAssignments = useFetch();

  const loadAssignments = async () => {
    manageAssignments.cache.clear();

    await manageAssignments.get(endpoint);

    setLoading(false);

    if (manageAssignments.response.ok) {
      setMyAssignments(manageAssignments.response.data);
    }
  };

  useEffect(() => {
    loadAssignments();
  }, []);

  if (manageAssignments.error) {
    return (
      <ErrorState
        emoji="🕳️"
        heading="There was an error loading your assignments"
        subheading="Try refreshing the page; if the problem persists"
        urlText="let us know"
        url="mailto:support@buildkite.com"
      />
    );
  }

  if (!loading && myAssignments.length === 0) {
    return (
      <EmptyState
        emoji="🤖"
        heading={`You have no ${
          isFlaky ? "recent" : "outdated"
        } flaky test assignments`}
        subheading={
          isFlaky
            ? "Create new assignments from your flaky test list"
            : "Tests that have not flaked in the last 7 days will appear here"
        }
      />
    );
  }

  return (
    <div>
      {loading ? (
        <LoadingPanels rows={5} columns={1} />
      ) : (
        <>
          {!isFlaky && (
            <FeedbackMessage message="These tests have not flaked in the last 7 days, and may no longer require assignment." />
          )}
          <ul className="ta-panel">
            <li
              aria-hidden={true}
              className="ta-panel--heading grid-cols-[auto_7rem_8rem_8rem] lg:grid-cols-[auto_7rem_14rem_17rem]"
            >
              <div className="main-section">Description</div>
              {isFlaky ? (
                <>
                  <div className="text-center">Instances</div>
                  <div>First occurrence</div>
                </>
              ) : (
                <>
                  <div /> {/* Empty column to help alignment */}
                  <div>Assignment date</div>
                </>
              )}
              <div>Latest occurrence</div>
            </li>
            {myAssignments.map((assignment) => (
              <FlakyTestRow
                key={assignment.test_id}
                flakyTest={assignment}
                teams={teams}
                currentAccountMember={currentAccountMember}
                flakyTestAssignmentFeatureAvailable={true}
                refreshAssignments={loadAssignments}
                isFlaky={isFlaky}
              />
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

export default TestAssignments;

/**
 * @generated SignedSource<<ece8930e873afcf9d9a1ec43e7fdc559>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AgentConnectedDialog_organization$data = {
  readonly agents?: {
    readonly count: number;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly name: string;
      } | null;
    } | null> | null;
  } | null;
  readonly slug: string;
  readonly " $fragmentType": "AgentConnectedDialog_organization";
};
export type AgentConnectedDialog_organization$key = {
  readonly " $data"?: AgentConnectedDialog_organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"AgentConnectedDialog_organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "isMounted"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "AgentConnectedDialog_organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "condition": "isMounted",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "last",
              "value": 1
            }
          ],
          "concreteType": "AgentConnection",
          "kind": "LinkedField",
          "name": "agents",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "count",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "AgentEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Agent",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "agents(last:1)"
        }
      ]
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "27694a433c3d7a43475aeb21bf94af2d";

export default node;

import * as React from "react";
import classNames from "classnames";

type Props = {
  label: React.ReactNode | null | undefined;
  children?: React.ReactNode;
  errors?: boolean;
  required?: boolean;
  htmlFor?: string;
};

class FormInputLabel extends React.PureComponent<Props> {
  render() {
    return (
      <label htmlFor={this.props.htmlFor}>
        <div className={classNames("bold mb1", { red: this.props.errors })}>
          {this.props.label}
          {this.props.required && (
            <span className="dark-gray h6 semi-bold"> — Required</span>
          )}
        </div>
        {this.props.children}
      </label>
    );
  }
}

export default FormInputLabel;

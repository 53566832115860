import * as React from "react";
import classNames from "classnames";

export type AnnotationStyles =
  | "DEFAULT"
  | "SUCCESS"
  | "ERROR"
  | "INFO"
  | "WARNING";

const ANNOTATION_STYLES: Partial<
  Record<
    AnnotationStyles,
    {
      annotationClass: string;
      icon: string;
    }
  >
> = {
  DEFAULT: {
    annotationClass: "build-annotation-default",
    icon: "fa-pencil",
  },
  SUCCESS: {
    annotationClass: "build-annotation-success",
    icon: "fa-check",
  },
  ERROR: {
    annotationClass: "build-annotation-error",
    icon: "fa-times",
  },
  INFO: {
    annotationClass: "build-annotation-info",
    icon: "fa-info",
  },
  WARNING: {
    annotationClass: "build-annotation-warning",
    icon: "fa-warning",
  },
};

type Props = {
  annotation: {
    context: string;
    style: AnnotationStyles;
    body: {
      html: string | null | undefined;
    };
  };
};

export default class Annnotation extends React.PureComponent<Props> {
  render() {
    const { annotation } = this.props;

    const annotationId = `annotation-${annotation.context}`;
    const styles = ANNOTATION_STYLES[annotation.style];

    return (
      <div
        id={annotationId}
        className={classNames(
          `rounded flex items-stretch border mb2 build-annotation`,
          // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
          styles.annotationClass,
        )}
      >
        <a
          href={`#${annotationId}`}
          className="flex-none flex pt3 border-right annotation-link"
        >
          <div className="center flex-auto">
            {/* @ts-expect-error - TS2532 - Object is possibly 'undefined'. */}
            <i className={classNames("fa", styles.icon, "annotation-icon")} />
          </div>
        </a>
        <div className="flex-auto">
          <div
            className="m3 annotation-body"
            dangerouslySetInnerHTML={{
              __html:
                annotation.body && annotation.body.html
                  ? annotation.body.html
                  : "",
            }}
          />
        </div>
      </div>
    );
  }
}

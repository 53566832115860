import { PureComponent } from "react";

type Props = {
  name: string;
  className?: string;
};

export default class Scalar extends PureComponent<Props> {
  render() {
    return (
      <span className={this.props.className}>
        <a
          className="cm-atom"
          href={`/user/graphql/documentation/type/${this.props.name}`}
        >
          {this.props.name}
        </a>
      </span>
    );
  }
}

import * as React from "react";
import { createRefetchContainer, graphql, RelayRefetchProp } from "react-relay";
import cable from "app/lib/cable";
import {
  PROCESS_KILLED_SIGNAL_REASONS,
  NEVER_STARTED_SIGNAL_REASONS,
} from "app/constants/SignalReasons";

type Props = {
  relay: RelayRefetchProp;
  job: any | null | undefined;
};

// Explanations for each possible "signal reason," should be kept in sync with `job_event_signal_reason_enum.rb`
const SIGNAL_REASON_EXPLANATIONS: Partial<Record<any, React.ReactNode>> = {
  AGENT_STOP: "because the agent was stopped",
  CANCEL: "because the job was canceled",
  PROCESS_RUN_ERROR: "because the agent was unable to start the process",
  AGENT_REFUSED: "because the agent was configured to refuse this job",
  SIGNATURE_REJECTED: "because the job's signature could not be verified",
};

function JobLogExitInformation({ job, relay, ...props }: Props) {
  if (!job) {
    return;
  }

  React.useEffect(() => {
    const subscription = cable.subscriptions.create(
      { channel: "JobEventsChannel", uuid: job.uuid },
      {
        received: (data: any) => {
          if (data.changed) {
            relay.refetch(null, null, null, { force: true });
          }
        },
      },
    );

    return () => {
      subscription.unsubscribe();
    };
  }, [job ? job.uuid : null]);

  let lastExitEvent;

  // Find the last event with an exit status or signal information
  if (job.events && job.events.edges) {
    for (let idx = job.events.edges.length - 1; idx >= 0; idx--) {
      const edge = job.events.edges[idx];

      if (!edge || !edge.node) {
        continue;
      }

      const { node } = edge;

      if (node.exitStatus !== undefined || node.signal || node.signalReason) {
        lastExitEvent = node;
        break;
      }
    }
  }

  let value = "";
  let exitStatus = job.exitStatus;
  let signal, signalReason, actorType;
  const { softFailed } = job;

  // Some minor type massaging here, please forgive;
  // we want to be able to do just one strict check later
  if (exitStatus === null) {
    exitStatus = undefined;
  } else if (exitStatus !== undefined) {
    exitStatus = parseInt(exitStatus, 10);
  }

  if (lastExitEvent) {
    exitStatus = lastExitEvent.exitStatus;
    signal = lastExitEvent.signal;
    signalReason = lastExitEvent.signalReason;
    actorType = lastExitEvent.actor.type;
  }

  if (exitStatus !== undefined || signal || signalReason) {
    if (!signal) {
      let maybeExitedWith = (
        <>
          Exited with status <code className="monospace">{exitStatus}</code>
        </>
      );
      let signalExplanation;

      if (signalReason) {
        if (PROCESS_KILLED_SIGNAL_REASONS.includes(signalReason)) {
          signalExplanation = (
            <>
              {" "}
              (after intercepting the agent’s termination signal, sent{" "}
              {SIGNAL_REASON_EXPLANATIONS[signalReason]})
            </>
          );
        } else if (NEVER_STARTED_SIGNAL_REASONS.includes(signalReason)) {
          maybeExitedWith = <>Job never started</>;
          signalExplanation = (
            <> ({SIGNAL_REASON_EXPLANATIONS[signalReason]})</>
          );
        }
      }

      // @ts-expect-error - TS2322 - Type 'Element' is not assignable to type 'string'.
      value = (
        <>
          {maybeExitedWith}
          {actorType !== "AGENT" && exitStatus === -1 && " (agent lost)"}
          {signalReason && signalExplanation}
        </>
      );
    } else {
      // @ts-expect-error - TS2322 - Type 'Element' is not assignable to type 'string'.
      value = (
        <>
          Terminated
          {signal && (
            <>
              {" "}
              with signal <code className="monospace">{signal}</code>
            </>
          )}
          {signalReason ? (
            <> (from the agent {SIGNAL_REASON_EXPLANATIONS[signalReason]})</>
          ) : (
            " (from the operating system or another process)"
          )}
          {exitStatus !== undefined && (
            <>
              . Exit status <code className="monospace">{exitStatus}</code>
            </>
          )}
        </>
      );
    }

    if (softFailed) {
      // @ts-expect-error - TS2322 - Type 'Element' is not assignable to type 'string'.
      value = <>{value} (soft failed)</>;
    }
  }

  return (
    <div {...props}>
      <span>{value}</span>
    </div>
  );
}

export default createRefetchContainer(
  JobLogExitInformation,
  {
    job: graphql`
      fragment JobLogExitInformation_job on Job {
        ... on JobTypeCommand {
          uuid
          exitStatus
          softFailed

          events(first: 60) {
            edges {
              node {
                actor {
                  type
                }
                ... on JobEventFinished {
                  exitStatus
                  signal
                  signalReason
                }
                ... on JobEventCanceled {
                  exitStatus
                  signal
                  signalReason
                }
                ... on JobEventTimedOut {
                  exitStatus
                  signal
                  signalReason
                }
              }
            }
          }
        }
      }
    `,
  },
  graphql`
    query JobLogExitInformationRefetchQuery($id: ID!) {
      job(uuid: $id) {
        ...JobLogExitInformation_job
      }
    }
  `,
);

import * as React from "react";
import { createFragmentContainer, graphql } from "react-relay";

import Badge from "app/components/shared/Badge";
import Emojify from "app/components/shared/Emojify";
import Panel from "app/components/shared/Panel";
import JobLink from "app/components/shared/JobLink";

import AgentStateIcon from "./AgentStateIcon";

type AgentLinkProps = {
  children?: React.ReactNode;
  className?: string;
  path: string;
  title?: string;
};

function AgentLink({ path, className, children, title }: AgentLinkProps) {
  return (
    <a className={className} title={title} href={path}>
      {children}
    </a>
  );
}

type Props = {
  agent: {
    id: string;
    hostname: string;
    job?: {
      state: string;
    };
    isRunningJob: boolean;
    metaData: Array<string>;
    name: string;
    organization: {
      slug: string;
    };
    clusterQueue?: {
      key: string;
      cluster: {
        uuid: string;
        name: string;
      };
      uuid: string;
    };
    public: boolean;
    uuid: string;
    version: string;
    versionHasKnownIssues: boolean;
  };
  showClusterBadge: boolean;
};

class AgentRow extends React.PureComponent<Props> {
  static defaultProps = {
    showClusterBadge: true,
  };

  renderJob() {
    const { agent } = this.props;

    if (agent.isRunningJob) {
      const job = agent.job ? (
        <JobLink job={agent.job} />
      ) : (
        "a job owned by another team"
      );

      return (
        <small
          className="block mt1 pt1 border border-gray"
          style={{
            borderLeft: "none",
            borderRight: "none",
            borderBottom: "none",
          }}
        >
          Running {job}
        </small>
      );
    }
  }

  render() {
    const { agent } = this.props;

    let metaDataContent = "No metadata";

    if (agent.metaData.length > 0) {
      let metaDataList = [...agent.metaData].sort();

      if (agent.clusterQueue) {
        const clusterQueueName = `queue=${agent.clusterQueue.key}`;
        metaDataList = metaDataList.filter(
          (metadata) => metadata !== clusterQueueName,
        );
      }

      if (metaDataList.length) {
        metaDataContent = metaDataList.join(" ");
      }
    }

    return (
      <Panel.Row>
        <div className="flex">
          <AgentStateIcon agent={agent} className="pr3 pt1" />
          <div className="flex flex-auto flex-column">
            <div className="flex flex-auto">
              <div className="flex-auto col-8">
                <div className="flex-auto flex">
                  <AgentLink
                    className="semi-bold black hover-lime text-decoration-none truncate"
                    path={this.agentPath()}
                  >
                    {agent.name}
                  </AgentLink>
                  {this.renderPublicBadge()}
                </div>
                {this.props.showClusterBadge && agent.clusterQueue && (
                  <Badge outline={true} style={{ margin: "0" }}>
                    <span className="monospace">{agent.clusterQueue.key}</span>
                    {" in "}
                    <Emojify text={agent.clusterQueue.cluster.name} />
                  </Badge>
                )}
                <div className="small dark-gray wrap-break-word">
                  {metaDataContent}
                </div>
              </div>
              <div className="flex-none right-align col-4 pl3">
                <div className="black">
                  {agent.version ? `v${agent.version}` : "Unknown Version"}
                  {this.renderVersionWarning()}
                </div>
                <div className="small dark-gray truncate">{agent.hostname}</div>
              </div>
            </div>
            {this.renderJob()}
          </div>
        </div>
      </Panel.Row>
    );
  }

  clusterPath() {
    const {
      agent: { clusterQueue },
    } = this.props;
    if (!clusterQueue) {
      return "/unclustered";
    }

    return `/clusters/${clusterQueue.cluster.uuid}/queues/${clusterQueue.uuid}`;
  }

  agentPath() {
    const { agent } = this.props;
    const orgPath = `/organizations/${agent.organization.slug}`;
    const clusterPath = this.clusterPath();

    return `${orgPath}${clusterPath}/agents/${agent.uuid}`;
  }

  renderVersionWarning() {
    const {
      agent: { versionHasKnownIssues },
    } = this.props;

    if (versionHasKnownIssues) {
      return (
        <AgentLink
          className="ml1 text-decoration-none"
          path={this.agentPath()}
          title="This version has known issues"
        >
          ⚠️
        </AgentLink>
      );
    }
  }

  renderPublicBadge() {
    if (this.props.agent.public) {
      return (
        <Badge
          outline={true}
          className="regular very-dark-gray"
          // @ts-expect-error - TS2769 - No overload matches this call.
          title="Visible to everyone, including people outside this organization"
        >
          Public
        </Badge>
      );
    }
  }
}

export default createFragmentContainer(AgentRow, {
  agent: graphql`
    fragment AgentRow_agent on Agent {
      ...AgentStateIcon_agent
      id
      hostname
      metaData
      name
      organization {
        slug
      }
      clusterQueue {
        key
        cluster {
          uuid
          name
        }
        uuid
      }
      public
      isRunningJob
      job {
        ... on JobTypeCommand {
          state
        }
        ...JobLink_job
      }
      uuid
      version
      versionHasKnownIssues
    }
  `,
});

/**
 * @generated SignedSource<<0625aa8a2b6a476723bec9c9e6f04df1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JobEventBuildStepUploadCreated_event$data = {
  readonly actor: {
    readonly node: {
      readonly " $fragmentSpreads": FragmentRefs<"AgentBadge_agent">;
    } | null;
  };
  readonly buildStepUpload?: {
    readonly definition: {
      readonly yaml: string;
    };
  };
  readonly timestamp: any;
  readonly " $fragmentType": "JobEventBuildStepUploadCreated_event";
};
export type JobEventBuildStepUploadCreated_event$key = {
  readonly " $data"?: JobEventBuildStepUploadCreated_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"JobEventBuildStepUploadCreated_event">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "JobEventBuildStepUploadCreated_event",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timestamp",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "JobEventActor",
      "kind": "LinkedField",
      "name": "actor",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "AgentBadge_agent"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BuildStepUpload",
          "kind": "LinkedField",
          "name": "buildStepUpload",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "BuildStepUploadDefinition",
              "kind": "LinkedField",
              "name": "definition",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "yaml",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "JobEventBuildStepUploadCreated",
      "abstractKey": null
    }
  ],
  "type": "JobEvent",
  "abstractKey": "__isJobEvent"
};

(node as any).hash = "04b4d3825985d44468e22114ea9276c1";

export default node;

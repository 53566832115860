class String {
  _camelCaseLookup = {};
  _underscoreLookup = {};

  // camelCase a string. Uses an internal hash to store values so it doesn't have to compute
  // them again and again.
  camelCase(str: string) {
    return (
      this._camelCaseLookup[str] ||
      (this._camelCaseLookup[str] = str.replace(
        /[-_\s]+(.)?/g,
        (match, character) => {
          if (character) {
            return character.toUpperCase();
          }

          return "";
        },
      ))
    );
  }

  underscore(str: string) {
    return (
      this._underscoreLookup[str] ||
      (this._underscoreLookup[str] = str
        .replace(/([a-z])([A-Z0-9])/g, "$1_$2")
        .toLowerCase())
    );
  }
}

export default new String();

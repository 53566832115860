import { createElement } from "react";
import reactRender from "app/lib/reactRenderer";
import ReactDOM from "react-dom";

import isEqual from "lodash/isEqual";

export default class TurboReactComponentElement extends HTMLElement {
  constructor() {
    super();
  }

  get component() {
    return window.require(this.getAttribute("component") as string);
  }

  get props() {
    return JSON.parse(this.getAttribute("props") as string);
  }

  morph(
    event: Event & {
      detail: {
        newElement: Element | null;
      };
    },
  ) {
    if (!event.detail.newElement) {
      return;
    }

    // Prevent the default morphing behavior
    event.preventDefault();

    const newProps = JSON.parse(
      event.detail.newElement.getAttribute("props") as string,
    );

    // Only re-render if the props have changed
    if (!isEqual(this.props, newProps)) {
      reactRender(createElement(this.component, newProps), this);
    }
  }

  connectedCallback() {
    reactRender(createElement(this.component, this.props), this);

    this.addEventListener<any>("turbo:before-morph-element", this.morph);
  }

  disconnectedCallback() {
    ReactDOM.unmountComponentAtNode(this);

    this.removeEventListener<any>("turbo:before-morph-element", this.morph);
  }
}

declare global {
  interface Window {
    TurboReactComponentElement: typeof TurboReactComponentElement;
  }
}

if (!window.customElements.get("turbo-react-component")) {
  window.TurboReactComponentElement = TurboReactComponentElement;

  window.customElements.define(
    "turbo-react-component",
    TurboReactComponentElement,
  );
}

import { Component } from "react";
import PropTypes from "prop-types";
import { createRefetchContainer, graphql } from "react-relay";
import moment from "moment";
import styled from "styled-components";
import DocumentTitle from "react-document-title";
import { seconds } from "metrick/duration";

import AgentJobRow from "./AgentJobRow";
import agentStopMutation from "app/mutations/AgentStop";
import Badge from "app/components/shared/Badge";
import Button from "app/components/shared/Button";
import Dialog from "app/components/shared/Dialog";
import Emojify from "app/components/shared/Emojify";
import FlashesStore from "app/stores/FlashesStore";
import FriendlyTime from "app/components/shared/FriendlyTime";
import Icon from "app/components/shared/Icon";
import PageWithContainer from "app/components/shared/PageWithContainer";
import Panel from "app/components/shared/Panel";
import PausedClusterQueuesNotice from "app/components/shared/PausedClusterQueuesNotice";
import permissions from "app/lib/permissions";

const ExtrasTable = styled.table`
  @media (max-width: 720px) {
    &,
    tbody {
      display: block;
    }

    tr {
      display: flex;
    }

    th {
      padding-bottom: 0;
    }
  }
`;

// eslint-disable-next-line react/require-optimization
class AgentShow extends Component {
  static propTypes = {
    agent: PropTypes.shape({
      id: PropTypes.string,
      uuid: PropTypes.string,
      name: PropTypes.string,
      connectionState: PropTypes.string,
      disconnectedAt: PropTypes.string,
      stoppedAt: PropTypes.string,
      job: PropTypes.object,
      permissions: PropTypes.shape({
        agentStop: PropTypes.shape({
          allowed: PropTypes.bool,
        }),
      }),
      organization: PropTypes.shape({
        name: PropTypes.string,
        slug: PropTypes.string,
      }),
      clusterQueue: PropTypes.shape({
        key: PropTypes.string.isRequired,
        cluster: PropTypes.shape({
          uuid: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
        }).isRequired,
        uuid: PropTypes.string.isRequired,
        dispatchPaused: PropTypes.bool.isRequired,
        dispatchPausedAt: PropTypes.string,
        dispatchPausedBy: PropTypes.shape({
          name: PropTypes.string.isRequired,
        }),
        dispatchPausedNote: PropTypes.string,
      }),
      public: PropTypes.bool.isRequired,
    }),
    relay: PropTypes.object.isRequired,
  };

  state = {
    stopping: false,
    forceStopping: false,
  };

  componentDidMount() {
    // Only bother setting up the delayed load and refresher if we've got an
    // actual agent to play with.
    if (this.props.agent && this.props.agent.uuid) {
      // This will cause a full refresh of the data every 3 seconds. This seems
      // very low, but chances are people aren't really looking at this page
      // for long periods of time.
      this.startTimeout();
    }
  }

  componentWillUnmount() {
    clearTimeout(this._agentRefreshTimeout);
  }

  refetchVariables = () => {
    return {
      agentId: this.props.agent.id,
    };
  };

  startTimeout = () => {
    this._agentRefreshTimeout = setTimeout(
      this.fetchUpdatedData,
      seconds.bind(3),
    );
  };

  fetchUpdatedData = () => {
    this.props.relay.refetch(
      this.refetchVariables(),
      null,
      (error) => {
        if (!error) {
          this.startTimeout();
        }
      },
      { force: true },
    );
  };

  renderExtraItem(
    title,
    content,
    { borderBottom = true, fullWidth = false } = {},
  ) {
    return (
      <tr
        key={title}
        className={`${borderBottom && "border-gray border-bottom"} flex`}
      >
        <th
          className="h4 p3 semi-bold left-align align-top flex-none"
          width={120}
        >
          {title}
        </th>
        <td
          className={
            fullWidth ? "h4 p3 flex-auto" : "h4 p3 flex-auto max-width-1"
          }
        >
          {content}
        </td>
      </tr>
    );
  }

  renderExtras(agent) {
    const extras = [];

    if (agent.clusterQueue) {
      extras.push(
        this.renderExtraItem(
          "Cluster",
          <a
            className="color-inherit"
            href={`/organizations/${this.props.agent.organization.slug}/clusters/${agent.clusterQueue.cluster.uuid}/queues`}
          >
            <Emojify text={agent.clusterQueue.cluster.name} />
          </a>,
        ),
      );

      extras.push(
        this.renderExtraItem(
          "Queue",
          <div>
            <a
              className="color-inherit"
              href={`/organizations/${this.props.agent.organization.slug}/clusters/${agent.clusterQueue.cluster.uuid}/queues/${agent.clusterQueue.uuid}`}
            >
              {agent.clusterQueue.key}
            </a>

            {agent.clusterQueue.dispatchPaused && (
              <span data-icon="paused-large">
                <Icon icon="paused-large" className="ml1" />
              </span>
            )}
          </div>,
        ),
      );
    }

    if (this.props.params.deletedClusterQueue) {
      {
        this.props.params.deletedClusterQueue.cluster.deleted
          ? extras.push(
              this.renderExtraItem(
                "Cluster",
                <div>
                  <Emojify
                    text={this.props.params.deletedClusterQueue.cluster.name}
                  />
                  <Badge
                    outline={true}
                    className="badge--gray"
                    // @ts-expect-error - TS2769 - No overload matches this call.
                    title="This cluster no longer exists."
                  >
                    Deleted
                  </Badge>
                </div>,
              ),
            )
          : extras.push(
              this.renderExtraItem(
                "Cluster",
                <a
                  className="color-inherit"
                  href={`/organizations/${this.props.agent.organization.slug}/clusters/${this.props.params.deletedClusterQueue.cluster.uuid}/queues`}
                >
                  <Emojify
                    text={this.props.params.deletedClusterQueue.cluster.name}
                  />
                </a>,
              ),
            );
      }

      extras.push(
        this.renderExtraItem(
          "Queue",
          <div>
            {this.props.params.deletedClusterQueue.key}
            <Badge
              outline={true}
              className="badge--gray"
              // @ts-expect-error - TS2769 - No overload matches this call.
              title="This cluster queue no longer exists."
            >
              Deleted
            </Badge>
          </div>,
        ),
      );
    }

    extras.push(
      this.renderExtraItem(
        "Agent State",
        <div className="flex">
          <div
            className={`bold letter-spacing-3 caps state-pill ${this.getClassNameForStatePill(
              agent.connectionState,
            )}`}
          >
            {this.getLabelForStatePill(agent.connectionState)}
          </div>
        </div>,
      ),
    );

    extras.push(this.renderExtraItem("Version", agent.version || "Unknown"));

    if (agent.version && agent.versionHasKnownIssues) {
      extras.push(
        this.renderExtraItem(
          "Upgrade Required ⚠️",
          <>
            buildkite-agent v{agent.version} has known issues and should be
            upgraded to a later version. See the{" "}
            <a
              href={`https://github.com/buildkite/agent/releases/tag/v${agent.version}`}
              className="text-decoration-none lime text-decoration-none hover-underline"
            >
              release notes on GitHub
            </a>{" "}
            for more information.
          </>,
        ),
      );
    }

    if (agent.hostname) {
      extras.push(
        this.renderExtraItem(
          "Hostname",
          <div className="truncate">{agent.hostname}</div>,
        ),
      );
    }

    if (agent.pid) {
      extras.push(this.renderExtraItem("PID", agent.pid));
    }

    if (agent.ipAddress) {
      extras.push(this.renderExtraItem("IP Address", agent.ipAddress));
    }

    if (agent.userAgent) {
      extras.push(this.renderExtraItem("User Agent", agent.userAgent));
    }

    if (agent.operatingSystem) {
      extras.push(this.renderExtraItem("OS", agent.operatingSystem.name));
    }

    if (agent.priority) {
      extras.push(this.renderExtraItem("Priority", agent.priority));
    }

    extras.push(
      this.renderExtraItem(
        this.renderAgentJobsLink("Jobs"),
        this.renderExtraJobs(agent),
        { fullWidth: true },
      ),
    );

    if (agent.connectedAt) {
      extras.push(
        this.renderExtraItem(
          "Connected",
          <FriendlyTime value={agent.connectedAt} />,
        ),
      );
    }

    if (agent.connectionState === "connected") {
      if (agent.pingedAt) {
        extras.push(
          this.renderExtraItem(
            "Last Ping",
            <FriendlyTime value={agent.pingedAt} />,
          ),
        );
      }
    }

    if (agent.connectionState === "disconnected") {
      extras.push(
        this.renderExtraItem(
          "Disconnected",
          <FriendlyTime value={agent.disconnectedAt} />,
        ),
      );
    } else if (agent.connectionState === "lost") {
      extras.push(
        this.renderExtraItem(
          "Last Ping",
          <FriendlyTime value={agent.lostAt} />,
        ),
      );
      if (agent.disconnectedAt) {
        extras.push(
          this.renderExtraItem(
            "Marked Lost",
            <FriendlyTime value={agent.disconnectedAt} />,
          ),
        );
      }
    } else if (
      agent.connectionState === "stopped" ||
      agent.connectionState === "stopping"
    ) {
      extras.push(
        this.renderExtraItem(
          "Stopped By",
          <span>
            {agent.stoppedBy.name}{" "}
            <FriendlyTime value={agent.stoppedAt} capitalized={false} />
          </span>,
        ),
      );

      if (agent.disconnectedAt) {
        extras.push(
          this.renderExtraItem(
            "Disconnected",
            <FriendlyTime value={agent.disconnectedAt} />,
          ),
        );
      }
    }

    let metaDataContent = "None";

    if (agent.metaData.length > 0) {
      let metaDataList = agent.metaData.sort();

      if (agent.clusterQueue) {
        const clusterQueueName = `queue=${agent.clusterQueue.key}`;
        metaDataList = metaDataList.filter(
          (metadata) => metadata !== clusterQueueName,
        );
      }

      if (metaDataList.length) {
        metaDataContent = metaDataList.map((metaData) => {
          return <div key={metaData}>{metaData}</div>;
        });
      }
    }

    extras.push(
      this.renderExtraItem(
        "Tags",
        <pre className="code">{metaDataContent}</pre>,
        { borderBottom: false },
      ),
    );

    return (
      <ExtrasTable className="col-12">
        <tbody>{extras}</tbody>
      </ExtrasTable>
    );
  }

  clusterPath() {
    const {
      agent: { clusterQueue },
    } = this.props;
    if (!clusterQueue) {
      return "/unclustered";
    }

    return `/clusters/${clusterQueue.cluster.uuid}/queues/${clusterQueue.uuid}`;
  }

  jobsPath() {
    const { agent } = this.props;
    const orgPath = `/organizations/${agent.organization.slug}`;
    const clusterPath = this.clusterPath();

    if (this.props.params.deletedClusterQueue) {
      return `${orgPath}/agents/${agent.uuid}/jobs`;
    }

    return `${orgPath}${clusterPath}/agents/${agent.uuid}/jobs`;
  }

  renderAgentJobsButton(linkText) {
    const className = "btn btn-default mt2";

    return (
      <Button href={this.jobsPath()} className={className}>
        {linkText}
      </Button>
    );
  }

  renderAgentJobsLink(linkText) {
    const className = "semi-bold black hover-lime text-decoration-none";

    return (
      <a href={this.jobsPath()} className={className}>
        {linkText}
      </a>
    );
  }

  renderExtraJobs(agent) {
    if (agent.jobs.edges.length < 1) {
      return "This agent has not run any jobs";
    }

    return (
      <div>
        <Panel>
          {agent.jobs.edges.map(({ node: job }) => (
            <AgentJobRow key={job.uuid} job={job} jobType="command" />
          ))}
        </Panel>
        {this.renderAgentJobsButton("Show all jobs")}
      </div>
    );
  }

  handleStopButtonClick = (evt) => {
    evt.preventDefault();

    this.setState({ stopping: true }, () => {
      // We add a delay in case it executes so quickly that the user can't
      // understand what just flashed past their face.
      setTimeout(() => {
        this.sendAgentStopMutation(
          true,
          this.handleStopMutationSuccess,
          this.handleStopMutationError,
        );
      }, 1250);
    });
    this.handleAgentStopDialogClose();
  };

  handleForceStopButtonClick = (evt) => {
    evt.preventDefault();

    this.setState({ forceStopping: true }, () => {
      this.sendAgentStopMutation(
        false,
        this.handleForceStopMutationSuccess,
        this.handleForceStopMutationError,
      );
    });
  };

  sendAgentStopMutation = (graceful, onCompleted, onError) => {
    return agentStopMutation({
      id: this.props.agent.id,
      graceful,
      onCompleted,
      onError,
    });
  };

  handleStopMutationSuccess = () => {
    this.setState({ stopping: false });
  };

  handleStopMutationError = (response) => {
    const error = response.source.errors[0].message;
    FlashesStore.flash(FlashesStore.ERROR, error);

    this.setState({ stopping: false });
  };

  handleForceStopMutationSuccess = () => {
    // Force stopping state stays on, because it doesn't change the connectionState
  };

  handleForceStopMutationError = (response) => {
    const error = response.source.errors[0].message;
    FlashesStore.flash(FlashesStore.ERROR, error);

    this.setState({ forceStopping: false });
  };

  handleAgentStopDialogClose = () => {
    this.setState({ agentStopDialogOpen: false });
  };

  handleAgentStopDialogOpen = () => {
    this.setState({ agentStopDialogOpen: true });
  };

  getClassNameForStatePill = (agentState) => {
    if (agentState === "connected") {
      return "state-pill-green";
    }
    if (agentState === "lost" || agentState === "stopping") {
      return "state-pill-pink";
    }
    return "state-pill-gray";
  };

  getLabelForStatePill = (agentState) => {
    return agentState.replace("_", " ");
  };

  render() {
    // If we don't have an agent object, or we do but it doesn't have an id
    // (perhaps Relay gave us an object but it's empty) then we can safely
    // assume that it's a 404.
    if (!this.props.agent || !this.props.agent.uuid) {
      return (
        <DocumentTitle title="Agents / No Agent Found">
          <PageWithContainer>
            <p>No agent could be found!</p>
          </PageWithContainer>
        </DocumentTitle>
      );
    }

    const agent = this.props.agent;

    return (
      <DocumentTitle
        title={`Agents / ${this.props.agent.name} · ${this.props.agent.organization.name}`}
      >
        <>
          {agent.clusterQueue?.dispatchPaused && (
            <PausedClusterQueuesNotice pausedQueues={[agent.clusterQueue]} />
          )}

          <Panel className="mx-auto">
            <Panel.Header className="flex p3">
              <div className="semi-bold" style={{ width: "120px" }}>
                Name
              </div>
              <div className="truncate regular">{this.props.agent.name}</div>
              {this.renderPublicBadge()}
            </Panel.Header>

            <div key="info">
              {this.renderExtras(agent)}
              {(this.props.agent.connectionState === "connected" ||
                this.props.agent.connectionState === "stopping") && (
                <div className="px3 pb3">
                  <p className="m0" style={{ marginLeft: "120px" }}>
                    You can use the agent’s tags to target the agent in your
                    pipeline’s step configuration, or to set the agent’s queue.
                    See the{" "}
                    <a
                      className="lime hover-lime text-decoration-none hover-underline"
                      href="/docs/agent/v3/cli-start"
                    >
                      Agent Tags and Queue Documentation
                    </a>{" "}
                    for more details.
                  </p>
                </div>
              )}
            </div>

            {this.renderStopRow()}
            {this.renderForceStopRow()}
          </Panel>
        </>
      </DocumentTitle>
    );
  }

  renderStopRow() {
    if (this.props.agent.disconnectedAt !== null) {
      return null;
    }
    if (this.props.agent.connectionState === "stopped") {
      return null;
    }
    if (
      !permissions(this.props.agent.permissions).isPermissionAllowed(
        "agentStop",
      )
    ) {
      return null;
    }

    const stopping =
      this.state.stopping || this.props.agent.connectionState === "stopping";

    return (
      <div className="p3">
        <div className="flex items-center" style={{ marginLeft: "120px" }}>
          <Button
            loading={stopping ? "Stopping…" : false}
            onClick={this.handleAgentStopDialogOpen}
            className="mr3 flex-none"
          >
            Stop Agent
          </Button>
          <Dialog
            isOpen={this.state.agentStopDialogOpen}
            onRequestClose={this.handleAgentStopDialogClose}
            width={400}
          >
            <div className="px4 pb4">
              <h2 className="h2">Are you sure?</h2>
              <p className="mb3">
                This will send a signal to the agent that it should disconnect
                and will prevent it from running jobs.
              </p>
              <Button
                loading={stopping ? "Stopping…" : false}
                onClick={this.handleStopButtonClick}
                className="mr3 btn-danger"
                style={{ width: "100%" }}
              >
                <span>Yes, stop this agent</span>
              </Button>
            </div>
          </Dialog>
          <p className="dark-gray m0 flex-1">
            {!this.props.agent.job &&
              !this.props.agent.stoppedAt &&
              "Send a signal to the agent that it should disconnect."}
            {this.props.agent.job &&
              !this.props.agent.stoppedAt &&
              "Send a signal to the agent that it should disconnect once its current job has completed."}
            {this.props.agent.job &&
              this.props.agent.stoppedAt &&
              "Waiting for the agent to complete its current job and disconnect."}
            {!this.props.agent.job &&
              this.props.agent.stoppedAt &&
              "Waiting for the agent to disconnect."}
            {!this.props.agent.job &&
              this.props.agent.stoppedAt &&
              moment().diff(this.props.agent.stoppedAt, "seconds") > 5 &&
              " If the agent doesn’t respond within a few minutes, it will be forcefully removed from your agent pool."}
          </p>
        </div>
      </div>
    );
  }

  renderForceStopRow() {
    if (
      this.state.stopping ||
      this.props.agent.connectionState !== "stopping"
    ) {
      return null;
    }

    if (
      this.props.agent.stoppedAt &&
      moment().diff(this.props.agent.stoppedAt, "seconds") < 5
    ) {
      return null;
    }

    // if the user can't stop an agent, abort
    if (
      !permissions(this.props.agent.permissions).isPermissionAllowed(
        "agentStop",
      )
    ) {
      return null;
    }

    return (
      <Panel.Row>
        <div className="flex items-center">
          <Button
            loading={this.state.forceStopping ? "Force Stopping…" : false}
            onClick={this.handleForceStopButtonClick}
            className="mb1 mr3 flex-none"
          >
            Force Stop Agent
          </Button>
          <p className="dark-gray m0 flex-1">
            {!this.props.agent.job &&
              !this.state.forceStopping &&
              "Forcefully remove this agent from your agent pool."}
            {this.props.agent.job &&
              "Cancel the running job, and forcefully remove this agent from your agent pool."}
          </p>
        </div>
      </Panel.Row>
    );
  }

  renderPublicBadge() {
    if (this.props.agent.public) {
      return (
        <Badge
          outline={true}
          className="regular very-dark-gray"
          title="Visible to everyone, including people outside this organization"
        >
          Public
        </Badge>
      );
    }
  }
}

/*
Disabling @graphql-eslint/no-deprecated here as I think there is some changes probably
required in the graph implementation so that we can actually use the alternatives to
`pingedAt`, `stoppedAt`, & `stoppedBy` (see schema.graphql - all of these fields have been
deprecated).
*/
export default createRefetchContainer(
  AgentShow,
  {
    agent: graphql`
      # eslint-disable @graphql-eslint/no-deprecated
      fragment AgentShow_agent on Agent {
        ...AgentStateIcon_agent
        id
        uuid
        name
        organization {
          name
          slug
        }
        clusterQueue {
          key
          cluster {
            uuid
            name
          }
          uuid
          dispatchPaused
          dispatchPausedAt
          dispatchPausedBy {
            name
            avatar {
              url
            }
          }
          dispatchPausedNote
        }
        connectedAt
        connectionState
        disconnectedAt
        hostname
        ipAddress
        job {
          ... on JobTypeCommand {
            state
            passed
          }
          ...AgentJobRow_job
        }
        jobs(first: 10) {
          edges {
            node {
              ... on JobTypeCommand {
                uuid
                state
                passed
              }
              ...AgentJobRow_job
            }
          }
          count
        }
        isRunningJob
        lostAt
        metaData
        operatingSystem {
          name
        }
        priority
        permissions {
          agentStop {
            allowed
            code
            message
          }
        }
        pid
        pingedAt
        public
        stoppedAt
        stoppedBy {
          name
        }
        userAgent
        version
        versionHasKnownIssues
      }
    `,
  },
  graphql`
    query AgentShowRefetchQuery($agentId: ID!) {
      agent: node(id: $agentId) {
        ...AgentShow_agent
      }
    }
  `,
);

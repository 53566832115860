/**
 * @generated SignedSource<<29d09bc733e8208dfb33e0c34884f379>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TwoFactorConfigure_viewer$data = {
  readonly id: string;
  readonly totp: {
    readonly id: string;
    readonly recoveryCodes: {
      readonly " $fragmentSpreads": FragmentRefs<"TwoFactorConfigureRecoveryCodes_recoveryCodes">;
    };
  } | null;
  readonly " $fragmentType": "TwoFactorConfigure_viewer";
};
export type TwoFactorConfigure_viewer$key = {
  readonly " $data"?: TwoFactorConfigure_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"TwoFactorConfigure_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TwoFactorConfigure_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "TOTP",
      "kind": "LinkedField",
      "name": "totp",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "RecoveryCodeBatch",
          "kind": "LinkedField",
          "name": "recoveryCodes",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "TwoFactorConfigureRecoveryCodes_recoveryCodes"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "be42258de8ffe29f28c66ecbf37b4a23";

export default node;

/**
 * @generated SignedSource<<785d21248f7e356e281e520edf6f0eb7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BuildsIndicator_pipeline$data = {
  readonly id: string;
  readonly runningBuilds: {
    readonly count: number;
  } | null;
  readonly scheduledBuilds: {
    readonly count: number;
  } | null;
  readonly uuid: string;
  readonly " $fragmentType": "BuildsIndicator_pipeline";
};
export type BuildsIndicator_pipeline$key = {
  readonly " $data"?: BuildsIndicator_pipeline$data;
  readonly " $fragmentSpreads": FragmentRefs<"BuildsIndicator_pipeline">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "count",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BuildsIndicator_pipeline",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "uuid",
      "storageKey": null
    },
    {
      "alias": "scheduledBuilds",
      "args": [
        {
          "kind": "Literal",
          "name": "state",
          "value": "SCHEDULED"
        }
      ],
      "concreteType": "BuildConnection",
      "kind": "LinkedField",
      "name": "builds",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": "builds(state:\"SCHEDULED\")"
    },
    {
      "alias": "runningBuilds",
      "args": [
        {
          "kind": "Literal",
          "name": "state",
          "value": [
            "RUNNING",
            "FAILING"
          ]
        }
      ],
      "concreteType": "BuildConnection",
      "kind": "LinkedField",
      "name": "builds",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": "builds(state:[\"RUNNING\",\"FAILING\"])"
    }
  ],
  "type": "Pipeline",
  "abstractKey": null
};
})();

(node as any).hash = "dd4daba76fd0e9f9bb77feecab62d31b";

export default node;

/**
 * @generated SignedSource<<a55d8c14c89043c6bb43f5793a386ef6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TwoFactorDelete_viewer$data = {
  readonly organizations: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly membersRequireTwoFactorAuthentication: boolean;
        readonly name: string;
        readonly slug: string;
      } | null;
    } | null> | null;
  } | null;
  readonly totp: {
    readonly id: string;
  } | null;
  readonly " $fragmentType": "TwoFactorDelete_viewer";
};
export type TwoFactorDelete_viewer$key = {
  readonly " $data"?: TwoFactorDelete_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"TwoFactorDelete_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TwoFactorDelete_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "TOTP",
      "kind": "LinkedField",
      "name": "totp",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 50
        }
      ],
      "concreteType": "OrganizationConnection",
      "kind": "LinkedField",
      "name": "organizations",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OrganizationEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Organization",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "slug",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "membersRequireTwoFactorAuthentication",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "organizations(first:50)"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "51685c4c3e1cb62033f388e622cc69d6";

export default node;

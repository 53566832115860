/**
 * @generated SignedSource<<ada579f3598cb27c58ceaa7b1e951317>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PipelineVisibility = "PRIVATE" | "PUBLIC" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type Header_pipeline$data = {
  readonly archived: boolean;
  readonly color: string | null;
  readonly description: string | null;
  readonly emoji: string | null;
  readonly id: string;
  readonly name: string;
  readonly organization: {
    readonly iconUrl: string | null;
    readonly name: string;
    readonly slug: string;
  };
  readonly permissions: {
    readonly buildCreate: {
      readonly allowed: boolean;
      readonly code: string | null;
      readonly message: string | null;
    };
    readonly pipelineUpdate: {
      readonly allowed: boolean;
      readonly code: string | null;
      readonly message: string | null;
    };
  };
  readonly repository: {
    readonly provider: {
      readonly __typename: string;
      readonly url: string | null;
    } | null;
    readonly url: string;
  } | null;
  readonly url: string;
  readonly visibility: PipelineVisibility;
  readonly " $fragmentSpreads": FragmentRefs<"BuildsIndicator_pipeline" | "CreateBuildDialog_pipeline">;
  readonly " $fragmentType": "Header_pipeline";
};
export type Header_pipeline$key = {
  readonly " $data"?: Header_pipeline$data;
  readonly " $fragmentSpreads": FragmentRefs<"Header_pipeline">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "allowed",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "code",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "message",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Header_pipeline",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreateBuildDialog_pipeline"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BuildsIndicator_pipeline"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "visibility",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "archived",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "emoji",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "color",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "iconUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Repository",
      "kind": "LinkedField",
      "name": "repository",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "provider",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__typename",
              "storageKey": null
            },
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PipelinePermissions",
      "kind": "LinkedField",
      "name": "permissions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Permission",
          "kind": "LinkedField",
          "name": "pipelineUpdate",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Permission",
          "kind": "LinkedField",
          "name": "buildCreate",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Pipeline",
  "abstractKey": null
};
})();

(node as any).hash = "30f11d13dc3c8aef72c40cafe18f0625";

export default node;

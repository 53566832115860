/* eslint-disable react/require-optimization */
import * as React from "react";
import { QueryRenderer, graphql } from "react-relay";
import Environment from "app/lib/relay/environment";

import Panel from "app/components/shared/Panel";
import Button from "app/components/shared/Button";
import SectionLoader from "app/components/shared/SectionLoader";
import CollapsableArea from "app/components/shared/CollapsableArea";

import withHistory, { LocationHistoryContext } from "app/lib/withHistory";

import Jobs from "./Jobs";
import SearchInput from "./search-input";

type Props = {
  clusterQueueId?: string;
  organizationSlug: string;
  location: {
    query: {
      q?: string;
    };
  };
};

type State = {
  query: string | null | undefined;
  searchInputValue: string;
};

class JobIndex extends React.Component<Props, State> {
  environment = Environment.get();

  static query = graphql`
    query IndexJobIndexQuery($organizationSlug: ID!) {
      organization(slug: $organizationSlug) {
        name
        slug
        ...Jobs_organization
      }
    }
  `;

  static contextType = LocationHistoryContext;

  constructor(initialProps: any) {
    super(initialProps);

    // Figure out if the default query
    const query =
      this.props.location.query.q !== undefined
        ? this.props.location.query.q
        : "state:scheduled";

    this.state = {
      query: query,
      searchInputValue: query,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    // When the `q` param in the URL changes, do a new search
    const query = nextProps.location.query.q;
    if (query !== undefined && this.state.query !== query) {
      this.setState({ query: query, searchInputValue: query });
    }
  }

  get queryVariables() {
    return {
      organizationSlug: this.props.organizationSlug,
    };
  }

  render() {
    return (
      <QueryRenderer
        fetchPolicy="store-and-network"
        cacheConfig={{ force: true }}
        environment={this.environment}
        query={JobIndex.query}
        variables={this.queryVariables}
        render={this.renderQuery()}
      />
    );
  }

  renderQuery =
    () =>
    ({ error, props }) => {
      if (error) {
        return;
      }

      if (!props || !props.organization) {
        return <SectionLoader />;
      }

      return (
        <Panel>
          <Panel.Section className="my-2.5">
            <form
              onSubmit={this.handleFormSubmit}
              className="flex items-stretch"
            >
              <SearchInput
                value={this.state.searchInputValue}
                onChange={this.handleSearchInputChange}
              />
              <Button theme="small" className="ml-3">
                Search
              </Button>
            </form>

            <details className="dark-gray mt-1.5 text-sm">
              <summary className="cursor-pointer">
                Search for jobs{" "}
                {this.props.clusterQueueId ? "on this queue " : ""} by state,
                concurrency group or passed status.
              </summary>

              <div className="dark-gray mt-1.5 text-sm">
                Narrow down your search further by using one or more of the
                available search keys:
                <table className="frontend-table mt-2">
                  <tr>
                    <td valign="top" className="w-1/4 p-2">
                      <code className="code text-xs">state</code>
                    </td>
                    <td valign="top" className="w-3/4 p-2">
                      Filter jobs by their current state. Learn more about{" "}
                      <a
                        href="https://buildkite.com/docs/pipelines/defining-steps#job-states"
                        className="color-inherit text-purple-600 hover:underline"
                      >
                        job states.
                      </a>
                      <br />
                      <em>Example:</em>{" "}
                      <code className="code text-xs">
                        state:scheduled,waiting
                      </code>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top" className="w-1/4 p-2">
                      <code className="code text-xs">concurrency-group</code>
                    </td>
                    <td valign="top" className="w-3/4 p-2">
                      Filter jobs by the concurrency group they belong to.
                      <br />
                      <em>Example:</em>{" "}
                      <code className="code text-xs">
                        concurrency-group:concurrency-group-key
                      </code>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top" className="w-1/4 p-2">
                      <code className="code text-xs">passed</code>
                    </td>
                    <td valign="top" className="w-3/4 p-2">
                      Filter jobs based on whether they have passed or failed.
                      <br />
                      <em>Example:</em>{" "}
                      <code className="code text-xs">passed:true/false</code>
                    </td>
                  </tr>
                </table>
              </div>
            </details>
          </Panel.Section>

          <Jobs
            query={this.state.query}
            clusterQueueId={this.props.clusterQueueId}
            organization={props.organization}
            onSuggestionClick={this.handleSuggestionClick}
          />
        </Panel>
      );
    };

  handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchInputValue: event.target.value });
  };

  searchPath = (query: string) => {
    const url = new URL(window.location.href);
    url.searchParams.set("q", query);

    return url.pathname + url.search;
  };

  handleFormSubmit = (event: any) => {
    event.preventDefault();

    this.context.history.push(this.searchPath(this.state.searchInputValue));
  };

  handleSuggestionClick = (suggestion: any) => {
    if (this.state.searchInputValue.includes(suggestion)) {
      return this.searchPath(this.state.searchInputValue);
    }

    const query = `${this.state.searchInputValue} ${suggestion}`;

    this.context.history.push(this.searchPath(query));
  };
}

export default withHistory(JobIndex);

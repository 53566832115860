const Colors = {
  PALE_BLUE: "#EFF9FF",
  BLUE: "#95CDF5",
  DARK_BLUE: "#0A568D",
  EMERALD: "#0E7868",
  PALE_AQUA: "#DDFFFA",
  AQUA: "#6AF0DB",
  DARK_AQUA: "#238072",
  PALE_PURPLE: "#F1EFFF",
  PURPLE: "#A195EF",
  DARK_PURPLE: "#4B19D5",
  PALE_PINK: "#FFEDF9",
  PINK: "#F198D3",
  DARK_PINK: "#921F6B",
  FAIL_RED: "#EB130F",
  PASS_GREEN: "#2ECC40",
  STORM_GRAY: "#AAAAAA",
  PALE_GRAY: "#F8F8F8",
  LIGHT_GRAY: "#E5E5E5",
  DARK_GRAY: "#333333",
  GRAY_BORDER: "#DDDDDD",
  MID_GRAY: "#8E8E8E",
  BASE_GRAY: "#4F4F4F",
  WHITE: "#FFFFFF",
} as const;

export default Colors;

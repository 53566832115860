import { useEffect, useState } from "react";
import classNames from "classnames";
import useFetch from "use-http";

import { parse } from "app/lib/job-log";
import Emojify from "app/components/shared/Emojify";
import Icon from "app/components/shared/Icon";
import LegacyLoading from "app/components/shared/LegacyLoading";
import {
  Log,
  LogBody,
  LogContent,
  LogControl,
  LogGroup,
  LogGroupHeader,
  LogGroupName,
  LogGroupTime,
  LogHeader,
  LogLine,
  LogLineContent,
  LogLineNumber,
  LogLoading,
  LogMessage,
  LogTimestamp,
} from "app/components/shared/Log";
import { useUserSession } from "app/lib/useUserSession";

type Log = {
  linesHaveTimestamps: boolean;
  totalLines: number;
  groups: Array<{
    id: string;
    number: number;
    name: string;
    startedAt: number | null;
    finishedAt: number | null;
    finished: boolean;
    durationPercentage: number;
    lines: Array<{
      number: number;
      startedAt: number | null;
      line: string;
    }>;
  }>;
};

export default function LogViewer({
  logUrl,
  job,
}: {
  logUrl: string;
  job: {
    id: string;
    state: string;
    base_path: string;
    log_deleted_message: string;
    started_at_from_agent: number | null;
    finished_at_from_agent: number | null;
    permissions: {
      delete_job_log: {
        allowed: boolean;
      };
    };
  };
}) {
  const isDeleted = Boolean(job.log_deleted_message);
  const canDeleteLog =
    ["finished", "canceled", "timed_out"].includes(job.state) &&
    job?.permissions?.delete_job_log?.allowed;

  const [log, setLog] = useState<Log | null>(null);
  const [theme, setTheme] = useUserSession("code-theme", "default");
  const [timestamps, setTimestamps] = useUserSession(
    "job-log-timestamps-on",
    false,
  );
  const [utcTimestamps, setUtcTimestamps] = useUserSession(
    "timestamps-utc",
    false,
  );

  const { get, response, loading, error } = useFetch(logUrl);

  useEffect(() => {
    async function load() {
      const data = await get();

      if (response.ok) {
        if (!data) {
          return setLog({
            groups: [],
            linesHaveTimestamps: false,
            totalLines: 0,
          });
        }

        const parsedLog = parse(
          {
            id: job.id,
            url: logUrl,
            truncated: false,
            startedAtFromAgent: job.started_at_from_agent,
            finishedAtFromAgent: job.finished_at_from_agent,
          },
          data,
        );

        setLog(parsedLog);
      }
    }

    if (!isDeleted) {
      load();
    }
  }, [logUrl]);

  function toggleTheme() {
    if (theme === "default") {
      setTheme("solarized");
    } else {
      setTheme("default");
    }
  }

  function scrollToEnd() {
    window.scrollTo(0, document.body.scrollHeight);
  }

  function toggleTimestamps() {
    setTimestamps(!timestamps);
  }

  function toggleUtcTimestamps() {
    setUtcTimestamps(!utcTimestamps);
  }

  return (
    <Log
      theme={theme}
      className={classNames({ "min-h-0 p-0": error || isDeleted })}
    >
      {!error && !isDeleted && (
        <LogHeader>
          {log?.linesHaveTimestamps && (
            <LogControl onClick={toggleTimestamps}>
              <i className="fa fa-calendar-o" />
              {timestamps ? "Hide timestamps" : "Show timestamps"}
            </LogControl>
          )}

          <LogControl className="md:ml-auto" onClick={toggleTheme}>
            <Icon
              icon={
                theme === "default" ? "theme-toggle-off" : "theme-toggle-on"
              }
              className="h-3 w-3"
            />
            Theme
          </LogControl>

          {canDeleteLog && (
            <LogControl
              href={`${job.base_path}/delete_log`}
              data-method="delete"
              data-confirm="Permanently delete this job’s log?"
            >
              <i className="fa fa-trash" /> Delete
            </LogControl>
          )}

          <LogControl href={`${job.base_path}/download.txt`}>
            <i className="fa fa-download" /> Download
          </LogControl>

          <LogControl onClick={scrollToEnd}>
            <i className="fa fa-arrow-down" /> Jump to end
          </LogControl>
        </LogHeader>
      )}

      <LogBody>
        {loading && (
          <LogLoading>
            <LegacyLoading />
          </LogLoading>
        )}

        {isDeleted && (
          <LogMessage>
            <Emojify text={job.log_deleted_message} />
          </LogMessage>
        )}

        {error && <LogMessage>There was an error loading the log</LogMessage>}

        {log && (
          <LogContent totalLines={log.totalLines}>
            {log.groups.map((group) => (
              <LogGroup key={group.id}>
                <LogGroupHeader>
                  <LogLineNumber number={group.number} />

                  {timestamps && (
                    <LogTimestamp
                      utc={utcTimestamps}
                      time={group.startedAt}
                      onClick={toggleUtcTimestamps}
                    />
                  )}

                  <LogGroupName name={group.name} />

                  <LogGroupTime
                    startedAt={group.startedAt}
                    finishedAt={group.finishedAt}
                    finished={group.finished}
                    durationPercentage={group.durationPercentage}
                  />
                </LogGroupHeader>

                {group.lines.map((line) => (
                  <LogLine key={line.number}>
                    <LogLineNumber number={line.number} />

                    {timestamps && (
                      <LogTimestamp
                        utc={utcTimestamps}
                        time={line.startedAt}
                        onClick={toggleUtcTimestamps}
                      />
                    )}

                    <LogLineContent colSpan={2}>
                      <span dangerouslySetInnerHTML={{ __html: line.line }} />
                    </LogLineContent>
                  </LogLine>
                ))}
              </LogGroup>
            ))}
          </LogContent>
        )}
      </LogBody>
    </Log>
  );
}

/**
 * @generated SignedSource<<00e536c848c95ee4b5df8c303ff9c2f7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TeamSuggestion_team$data = {
  readonly description: string | null;
  readonly name: string;
  readonly slug: string;
  readonly " $fragmentSpreads": FragmentRefs<"Labels_team">;
  readonly " $fragmentType": "TeamSuggestion_team";
};
export type TeamSuggestion_team$key = {
  readonly " $data"?: TeamSuggestion_team$data;
  readonly " $fragmentSpreads": FragmentRefs<"TeamSuggestion_team">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TeamSuggestion_team",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Labels_team"
    }
  ],
  "type": "Team",
  "abstractKey": null
};

(node as any).hash = "5678ea8d9a7539c2884290bff674d92c";

export default node;

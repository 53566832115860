import Environment from "app/lib/relay/environment";
import debounce from "lodash/debounce";

import SearchField from "app/components/shared/SearchField";
import ShowMoreFooter from "app/components/shared/ShowMoreFooter";
import Spinner from "app/components/shared/Spinner";
import { formatNumber } from "app/lib/number";
import { useState } from "react";
import {
  QueryRenderer,
  commitMutation,
  createRefetchContainer,
  graphql,
} from "react-relay";

import Panel from "app/components/shared/Panel";

import SectionLoader from "app/components/shared/SectionLoader";
import Chooser from "./MembersChooser";
import Row from "./row";

const PAGE_SIZE = 10;

const MembersPage = ({ team, className, relay }) => {
  const [loading, setLoading] = useState(false);
  const [searchingMembers, setSearchingMembers] = useState(false);
  const [memberSearch, setMemberSearch] = useState(null);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);

  const renderMemberSearch = () => {
    if (
      (team.members && team.members.edges.length > 0) ||
      memberSearch != null
    ) {
      return (
        <div className="py2 px3">
          <SearchField
            placeholder="Search members…"
            searching={searchingMembers}
            onChange={handleMemberSearch}
          />
        </div>
      );
    }

    return null;
  };

  const renderMemberSearchCount = () => {
    if (memberSearch && team.members && !searchingMembers) {
      return (
        <div className="bg-silver semi-bold py2 px3">
          <small className="dark-gray">
            {formatNumber(team.members.count)} matching member
            {team.members.count !== 1 && "s"}
          </small>
        </div>
      );
    }

    return null;
  };

  const handleTeamMemberRemove = (teamMember, callback) => {
    const environment = Environment.get();
    const input = { id: teamMember.id };
    commitMutation(environment, {
      mutation: graphql`
        mutation MembersTeamDeleteMutation($input: TeamMemberDeleteInput!) {
          teamMemberDelete(input: $input) {
            deletedTeamMemberID
            team {
              members {
                count
              }
            }
          }
        }
      `,
      variables: { input },
      onCompleted: () => {
        callback(null);
        reloadTable();
      },
      onError: (error) => callback(error),
    });
  };

  const handleMemberSearch = debounce(
    (search) => {
      setSearchingMembers(true);
      setMemberSearch(search || "");
      relay.refetch(
        {
          pageSize: pageSize,
          search,
          includeSensitiveInfo:
            team.organization.permissions.organizationMemberViewSensitive
              .allowed,
        },
        null,
        () => {
          setSearchingMembers(false);
        },
        { force: true },
      );
    },
    500,
    { leading: false },
  );

  const handleRoleChange = (teamMember, role, callback) => {
    const environment = Environment.get();
    const input = { id: teamMember.id, role: role };

    commitMutation(environment, {
      mutation: graphql`
        mutation MembersTeamUpdateMutation($input: TeamMemberUpdateInput!) {
          teamMemberUpdate(input: $input) {
            teamMember {
              id
              role
            }
          }
        }
      `,
      variables: { input },
      onCompleted: () => {
        callback(null);
      },
      onError: (error) => callback(error),
    });
  };

  const handleShowMoreMembers = () => {
    setLoading(true);
    const nextPageSize = pageSize + PAGE_SIZE;

    setPageSize(nextPageSize);
    reloadTable({ pageSize: nextPageSize });
  };

  const renderMembers = () => {
    if (!team.members) {
      return (
        <Panel.Section className="center">
          <Spinner />
        </Panel.Section>
      );
    }

    if (team.members.edges.length > 0) {
      return team.members.edges.map((edge) => {
        return (
          <Row
            key={edge.node.id}
            teamMember={edge.node}
            onRemoveClick={handleTeamMemberRemove}
            onRoleChange={handleRoleChange}
            includeSensitiveInfo={false}
          />
        );
      });
    }

    if (memberSearch) {
      return null;
    }

    return (
      <Panel.Section className="dark-gray">
        There are no users assigned to this team
      </Panel.Section>
    );
  };

  const reloadTable = (props) => {
    setLoading(true);
    relay.refetch(
      {
        pageSize: pageSize,
        search: memberSearch,
        includeSensitiveInfo:
          team.organization.permissions.organizationMemberViewSensitive.allowed,
        ...props,
      },
      null,
      () => {
        setLoading(false);
      },
      { force: true },
    );
  };

  const handleTeamMemberChoose = () => {
    reloadTable();
  };

  return (
    <div>
      <Chooser
        team={team}
        onChoose={handleTeamMemberChoose}
        disabled={loading}
      />
      <Panel className={className}>
        {renderMemberSearch()}
        {renderMemberSearchCount()}
        {renderMembers()}
        <ShowMoreFooter
          connection={team.members}
          label="members"
          loading={loading}
          searching={searchingMembers}
          onShowMore={handleShowMoreMembers}
        />
      </Panel>
    </div>
  );
};

const TeamsMembersPageContainer = createRefetchContainer(
  MembersPage,
  {
    team: graphql`
      fragment Members_team on Team
      @argumentDefinitions(
        pageSize: { type: "Int", defaultValue: 10 }
        search: { type: "String" }
        includeSensitiveInfo: { type: "Boolean", defaultValue: false }
      ) {
        id
        ...MembersChooser_team
        members(first: $pageSize, search: $search, order: NAME) {
          ...ShowMoreFooter_connection
          count
          edges {
            node {
              id
              ...row_teamMember
                @arguments(includeSensitiveInfo: $includeSensitiveInfo)
            }
          }
        }
        organization {
          permissions {
            organizationMemberViewSensitive {
              allowed
            }
          }
        }
      }
    `,
  },
  graphql`
    query Members_organizationQuery(
      $team: ID!
      $pageSize: Int!
      $search: String
    ) {
      team(slug: $team) {
        ...Members_team @arguments(pageSize: $pageSize, search: $search)
      }
    }
  `,
);

const TEAMS_MEMBERS_QUERY = graphql`
  query Members_Query($team: ID!) {
    team(slug: $team) {
      ...Members_team
    }
  }
`;

const TeamsMembersRenderer = ({ team }) => (
  <QueryRenderer
    fetchPolicy="store-and-network"
    environment={Environment.get()}
    query={TEAMS_MEMBERS_QUERY}
    variables={{ team }}
    render={({ props, error }) => {
      if (error || !props) {
        return <SectionLoader />;
      }
      return <TeamsMembersPageContainer {...props} />;
    }}
  />
);

export default TeamsMembersRenderer;

/**
 * @generated SignedSource<<a2754ae52c427e90ccd8126b96fa5ef6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TeamChooser_pipeline$data = {
  readonly archived: boolean;
  readonly id: string;
  readonly organization: {
    readonly slug: string;
    readonly teams?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly permissions: {
            readonly teamPipelineCreate: {
              readonly allowed: boolean;
            } | null;
          };
          readonly " $fragmentSpreads": FragmentRefs<"TeamSuggestion_team">;
        } | null;
      } | null> | null;
    } | null;
  };
  readonly slug: string;
  readonly " $fragmentType": "TeamChooser_pipeline";
};
export type TeamChooser_pipeline$key = {
  readonly " $data"?: TeamChooser_pipeline$data;
  readonly " $fragmentSpreads": FragmentRefs<"TeamChooser_pipeline">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "includeSearchResults"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "pipelineSelector"
    },
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "teamAddSearch"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "TeamChooser_pipeline",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "archived",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "condition": "includeSearchResults",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "first",
                  "value": 10
                },
                {
                  "kind": "Literal",
                  "name": "order",
                  "value": "RELEVANCE"
                },
                {
                  "kind": "Variable",
                  "name": "pipeline",
                  "variableName": "pipelineSelector"
                },
                {
                  "kind": "Variable",
                  "name": "search",
                  "variableName": "teamAddSearch"
                }
              ],
              "concreteType": "TeamConnection",
              "kind": "LinkedField",
              "name": "teams",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "TeamEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Team",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "TeamPermissions",
                          "kind": "LinkedField",
                          "name": "permissions",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Permission",
                              "kind": "LinkedField",
                              "name": "teamPipelineCreate",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "allowed",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "TeamSuggestion_team"
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Pipeline",
  "abstractKey": null
};
})();

(node as any).hash = "8dfe2be6d9e592b75f9000f0c052ec28";

export default node;

import { useBuild } from "app/components/Playground/BuildContext";
import { CancelButton } from "../../Header/CancelButton";
import { RebuildButton } from "../../Header/RebuildButton";
import RetryFailedJobsButton from "../../Header/RetryFailedJobsButton";

export const BuildActions = () => {
  const { build, store } = useBuild();
  if (!build || !store) {
    throw new Error("Missing build context");
  }

  return (
    <>
      <CancelButton build={build} store={store} className="btn-small" />
      <RetryFailedJobsButton
        build={build}
        store={store}
        className="btn-small"
      />
      <RebuildButton build={build} className="btn-small" />
    </>
  );
};

import { createRefetchContainer, graphql } from "react-relay";

import DocumentTitle from "react-document-title";

import Panel from "app/components/shared/Panel";
import PageHeader from "app/components/shared/PageHeader";
import Icon from "app/components/shared/Icon";

import TeamChooser from "./TeamChooser";
import TeamRow from "./TeamRow";

type TeamEdges = Array<{
  node: {
    id: string;
  } | null;
}>;

type Props = {
  pipeline: {
    name: string;
    organization: {
      id: string;
      slug: string;
    };
    teams: {
      edges: TeamEdges;
    };
  };
};

const Rows = ({
  edges,
  organization,
}: {
  edges: TeamEdges;
  organization: {
    id: string;
    slug: string;
  };
}) => {
  if (!edges?.length) {
    return (
      <Panel.Section>
        <p className="dark-gray">
          This Pipeline has not been added to any teams yet.
        </p>
      </Panel.Section>
    );
  }

  return edges.map((edge, idx) => (
    <TeamRow
      key={edge.node?.id || idx}
      teamPipeline={edge.node}
      organization={organization}
    />
  ));
};

const Page = ({ pipeline }: Props) => (
  <DocumentTitle title={`Teams · ${pipeline.name}`}>
    <div>
      <PageHeader>
        <PageHeader.Icon>
          <Icon
            icon="teams"
            style={{ width: 34, height: 34, marginTop: 3, marginLeft: 3 }}
          />
        </PageHeader.Icon>
        <PageHeader.Title>Teams</PageHeader.Title>
        <PageHeader.Description>
          Control which teams have access to this pipeline.
        </PageHeader.Description>
      </PageHeader>

      <div className="mb3">
        <TeamChooser pipeline={pipeline} />
      </div>

      <Panel>
        {/* @ts-expect-error - TS2786 - 'Rows' cannot be used as a JSX component. */}
        <Rows
          edges={pipeline.teams.edges}
          organization={pipeline.organization}
        />
      </Panel>
    </div>
  </DocumentTitle>
);

export default createRefetchContainer(
  Page,
  {
    pipeline: graphql`
      fragment Page_pipeline on Pipeline {
        name
        ...TeamChooser_pipeline
        organization {
          id
          ...TeamRow_organization
        }
        teams(first: 500) @connection(key: "Page_pipeline_teams") {
          edges {
            node {
              id
              ...TeamRow_teamPipeline
            }
          }
        }
      }
    `,
  },
  graphql`
    query Page_Query($pipelineSlug: ID!) {
      pipeline(slug: $pipelineSlug) {
        ...Page_pipeline
      }
    }
  `,
);

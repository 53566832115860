/**
 * @generated SignedSource<<2d84081dd52bb2556cc98d526eb83561>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JobEventCancelation_event$data = {
  readonly actor: {
    readonly node: {
      readonly name?: string;
      readonly " $fragmentSpreads": FragmentRefs<"UserAvatar_user">;
    } | null;
  };
  readonly timestamp: any;
  readonly " $fragmentType": "JobEventCancelation_event";
};
export type JobEventCancelation_event$key = {
  readonly " $data"?: JobEventCancelation_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"JobEventCancelation_event">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "JobEventCancelation_event",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timestamp",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "JobEventActor",
      "kind": "LinkedField",
      "name": "actor",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "UserAvatar_user"
                }
              ],
              "type": "User",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "JobEvent",
  "abstractKey": "__isJobEvent"
};

(node as any).hash = "b56768b3fc42735453b1f8612f5b5ee3";

export default node;

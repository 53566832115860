import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";

import Button from "app/components/shared/Button";
import FormInputLabel from "app/components/shared/FormInputLabel";
import FormTextField from "app/components/shared/FormTextField";

type GithubSuiteProps = {
  url: string;
  repositories: Array<{
    id: number;
    name: string;
    default_branch: string;
  }>;
};

interface GithubFormFields {
  analytics_suite: {
    name: string;
    repo: string;
    default_branch: string;
  };
}

const Wrapper = styled.div`
  padding: 75px 0;

  h1 {
    font-size: 24px !important;
    margin: 0 0 50px 0;
  }
`;

const GithubSuite = ({ url, repositories }: GithubSuiteProps) => {
  const defaultValues = {
    analytics_suite: {
      repo: repositories[0].name,
      default_branch: repositories[0].default_branch,
    },
  };
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm<GithubFormFields>({ defaultValues });
  const branch = watch("analytics_suite.default_branch");
  const [repoSelectionEnabled, setRepoSelectionEnabled] = useState(true);

  const handleSelectRepo = useCallback((event) => {
    const selectedRepo = repositories.find(
      (repo) => repo.name === event.target.value,
    );

    if (selectedRepo) {
      setValue("analytics_suite.default_branch", selectedRepo.default_branch);
    }
  }, []);

  const toggleRepoSelectionEnabled = useCallback((event) => {
    event.preventDefault();
    setRepoSelectionEnabled((previouslyEnabled) => {
      // reset form to empy values when switching to manual mode, and
      // reset form to default values when switching back to repo selection mode
      reset(previouslyEnabled ? { analytics_suite: {} } : defaultValues);
      return !previouslyEnabled;
    });
  }, []);

  const onSubmit = useCallback((_data, event) => {
    event.target.submit();
  }, []);

  return (
    <Wrapper className="container">
      <div className="md-col-5 mx-auto">
        <h1 className="center">Create a test suite</h1>

        <form
          name="create-suite"
          action={url}
          method="post"
          onSubmit={handleSubmit(onSubmit)}
          noValidate={true}
        >
          <input
            type="hidden"
            name={window._csrf.param}
            value={window._csrf.token}
          />
          {repoSelectionEnabled ? (
            <>
              <input
                readOnly={true}
                hidden={true}
                {...register("analytics_suite.default_branch")}
                name="analytics_suite[default_branch]"
              />
              <div className="mb2">
                <FormInputLabel label="Choose your repository" required={true}>
                  <select
                    className="select"
                    {...register("analytics_suite.repo", {
                      onChange: handleSelectRepo,
                    })}
                    name="analytics_suite[repo]"
                  >
                    {repositories.map((repo) => (
                      <option key={repo.id} value={repo.name}>
                        {repo.name}
                      </option>
                    ))}
                  </select>
                </FormInputLabel>
              </div>
              <p className="p0 m0 charcoal-300">
                Your test suite will default to showing trends for `{branch}`
                branch. You can change your default branch later in the suite
                settings.
              </p>
            </>
          ) : (
            <>
              <FormTextField
                required={true}
                label="Test suite name"
                placeholder="Acme iOS App"
                errors={
                  errors.analytics_suite?.name
                    ? [errors.analytics_suite.name.message]
                    : undefined
                }
                {...register("analytics_suite.name", {
                  required: "This field is required",
                })}
                name="analytics_suite[name]"
              />
              <FormTextField
                required={true}
                label="Default branch name"
                placeholder="main"
                errors={
                  errors.analytics_suite?.default_branch
                    ? [errors.analytics_suite.default_branch.message]
                    : undefined
                }
                {...register("analytics_suite.default_branch", {
                  required: "This field is required",
                })}
                name="analytics_suite[default_branch]"
              />
              <p className="p0 m0 charcoal-300">
                Your test suite will default to showing trends for this default
                branch, but collect data for all test runs. The default branch
                can be changed at any time. You can switch to “all branches” to
                see trends for all test runs on all branches.
              </p>
            </>
          )}
          <div className="pt4">
            <Button type="submit" theme="primary">
              Create test suite
            </Button>
            <span className="charcoal-300 ml2">or </span>
            <a
              className="charcoal-300"
              href="#"
              onClick={toggleRepoSelectionEnabled}
            >
              {repoSelectionEnabled ? "set up manually" : "choose a repository"}
            </a>
          </div>
        </form>
      </div>
    </Wrapper>
  );
};

export default GithubSuite;

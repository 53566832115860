/**
 * @generated SignedSource<<c85fcbe9104c8897de948e6e7cfe2401>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Drawer_auditEvent$data = {
  readonly " $fragmentSpreads": FragmentRefs<"actorSection_auditEvent" | "contextSection_auditEvent" | "eventSection_auditEvent" | "subjectSection_auditEvent">;
  readonly " $fragmentType": "Drawer_auditEvent";
};
export type Drawer_auditEvent$key = {
  readonly " $data"?: Drawer_auditEvent$data;
  readonly " $fragmentSpreads": FragmentRefs<"Drawer_auditEvent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Drawer_auditEvent",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "eventSection_auditEvent"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "subjectSection_auditEvent"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "actorSection_auditEvent"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "contextSection_auditEvent"
    }
  ],
  "type": "AuditEvent",
  "abstractKey": null
};

(node as any).hash = "bab052397a97bb5d7b6a24b6bebe5398";

export default node;

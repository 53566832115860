import * as React from "react";

type Props = {
  children: React.ReactNode;
  language?: string;
};

export default function Code(props: Props) {
  return (
    <code
      style={{
        fontFamily:
          '"SFMono-Regular", Monaco, Menlo, Consolas, "Liberation Mono", Courier, monospace',
        fontSize: ".9em",
      }}
      className={props.language && `language-${props.language}`}
    >
      {props.children}
    </code>
  );
}

/**
 * @generated SignedSource<<d16fa51ad5415c0bf09026e30ffcd0de>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AnnotationStyle = "DEFAULT" | "ERROR" | "INFO" | "SUCCESS" | "WARNING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AnnotationsList_build$data = {
  readonly annotations: {
    readonly edges: ReadonlyArray<{
      readonly cursor: string;
      readonly node: {
        readonly body: {
          readonly html: string | null;
        } | null;
        readonly context: string;
        readonly createdAt: any;
        readonly id: string;
        readonly priority: number;
        readonly style: AnnotationStyle | null;
      } | null;
    } | null> | null;
    readonly " $fragmentSpreads": FragmentRefs<"ShowMoreFooter_connection">;
  } | null;
  readonly id: string;
  readonly uuid: string;
  readonly " $fragmentType": "AnnotationsList_build";
};
export type AnnotationsList_build$key = {
  readonly " $data"?: AnnotationsList_build$data;
  readonly " $fragmentSpreads": FragmentRefs<"AnnotationsList_build">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": "RECENTLY_CREATED",
      "kind": "LocalArgument",
      "name": "order"
    },
    {
      "defaultValue": 10,
      "kind": "LocalArgument",
      "name": "pageSize"
    },
    {
      "defaultValue": [],
      "kind": "LocalArgument",
      "name": "style"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "AnnotationsList_build",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "uuid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "pageSize"
        },
        {
          "kind": "Variable",
          "name": "order",
          "variableName": "order"
        },
        {
          "kind": "Variable",
          "name": "style",
          "variableName": "style"
        }
      ],
      "concreteType": "AnnotationConnection",
      "kind": "LinkedField",
      "name": "annotations",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AnnotationEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Annotation",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "context",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "style",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "createdAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "priority",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AnnotationBody",
                  "kind": "LinkedField",
                  "name": "body",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "html",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ShowMoreFooter_connection"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Build",
  "abstractKey": null
};
})();

(node as any).hash = "a246b225cf18b10e4e88d820c6f38035";

export default node;

import * as React from "react";
import Icon from "app/components/shared/Icon";
import Badge from "app/components/shared/Badge";
import Colors from "app/constants/analytics/Colors";

type Props = {
  result: string;
};

const ResultBadge: React.ComponentType<Props> = (props: Props) => {
  const statusStyle = (result: string) => {
    switch (result) {
      case "passed":
        return {
          backgroundColor: "#028566",
          color: Colors.WHITE,
          border: "1px solid transparent",
          padding: "4px 9px 4px 23px",
        };
      case "failed":
        return {
          backgroundColor: "#D72A10",
          color: Colors.WHITE,
          border: "1px solid transparent",
          padding: "4px 9px 4px 23px",
        };
      case "pending":
        return {
          backgroundColor: Colors.BASE_GRAY,
          color: Colors.WHITE,
          border: "1px solid transparent",
          padding: "4px 13px 4px 14px",
        };
      case "skipped":
        return {
          backgroundColor: "inherit",
          color: Colors.BASE_GRAY,
          border: `1px solid ${Colors.GRAY_BORDER}`,
          padding: "4px 7px 4px 8px",
        };
      case "unknown":
        return {
          backgroundColor: "inherit",
          color: Colors.BASE_GRAY,
          border: `1px solid ${Colors.GRAY_BORDER}`,
          padding: "4px 8px 4px 9px",
        };
      case "stale":
        return {
          backgroundColor: "inherit",
          color: Colors.BASE_GRAY,
          border: `1px solid ${Colors.GRAY_BORDER}`,
          padding: "4px 10px 4px 11px",
        };
      default:
        return {};
    }
  };

  const iconType = (result) => {
    switch (result) {
      case "passed":
        return "permission-small-tick";
      case "failed":
        return "permission-small-cross";
      default:
        return "";
    }
  };

  const badgeStyle = {
    fontFamily:
      "SF Pro Text, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, sans-serif",
    fontStyle: "normal",
    fontSize: "11px",
    lineHeight: "13px",
    letterSpacing: "0.1em",
    textAlign: "center",
    textTransform: "uppercase",
    position: "relative",
  } as const;

  const ResultIcon = ({ result }) => {
    if (result === "passed" || result === "failed") {
      return (
        <Icon
          title={`Result: ${result}`}
          icon={iconType(result)}
          style={{
            strokeLinecap: "round",
            strokeLinejoin: "round",
            position: "absolute",
            height: 16,
            width: 16,
            top: "3px",
            left: "6px",
          }}
        />
      );
    }
    return null;
  };

  return (
    <div>
      <Badge
        // @ts-expect-error - TS2769 - No overload matches this call.
        role="status"
        className="bold"
        style={{ ...badgeStyle, ...statusStyle(props.result) }}
      >
        <ResultIcon result={props.result} />
        {props.result}
      </Badge>
    </div>
  );
};

export default ResultBadge;

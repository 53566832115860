import * as React from "react";
import classNames from "classnames";

import FormInputLabel from "./FormInputLabel";
import FormInputHelp from "./FormInputHelp";
import FormInputErrors from "./FormInputErrors";

type Option = {
  label: React.ReactNode;
  value: string;
};
type Options = Array<Option>;
type Props = {
  options: Options;
  defaultValue?: string;
  value?: string;
  label?: React.ReactNode;
  name?: string;
  help?: React.ReactNode;
  className?: string;
  onChange?: any;
  errors?: Array<React.ReactNode>;
  required?: boolean;
  disabled?: boolean;
};

export default class FormSelect extends React.Component<Props> {
  render() {
    return (
      <div className={classNames("mb2", this.props.className)}>
        <FormInputLabel
          label={this.props.label || null}
          errors={this._hasErrors()}
          required={this.props.required}
        >
          {this._renderSelect()}
        </FormInputLabel>
        <FormInputErrors errors={this.props.errors} />
        <FormInputHelp>{this.props.help}</FormInputHelp>
      </div>
    );
  }

  _hasErrors() {
    return this.props.errors && this.props.errors.length > 0;
  }

  _renderSelect() {
    return (
      <select
        name={this.props.name}
        className="select"
        value={this.props.value}
        defaultValue={this.props.defaultValue}
        onChange={this.props.onChange}
        disabled={this.props.disabled}
      >
        {!this.props.required && <option />}
        {this.props.options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    );
  }
}

import * as React from "react";
import classNames from "classnames";

type Props = {
  className?: string;
  children?: React.ReactNode;
};

const FormInputHelp = ({ className, children, ...props }: Props) => {
  if (!children) {
    return null;
  }

  return (
    <p className={classNames("mt1 mb0 p0 dark-gray", className)} {...props}>
      {children}
    </p>
  );
};

export default FormInputHelp;

import { PureComponent } from "react";
import classNames from "classnames";
import FlashesStore, { FlashItem } from "app/stores/FlashesStore";
import CableStore from "app/stores/CableStore";
import Flash from "./flash";

const FLASH_CONN_ERROR_ID = "FLASH_CONN_ERROR";

type State = {
  flashes: Array<FlashItem>;
  lastConnected: boolean;
};

type Props = {
  className: string;
};

class Flashes extends PureComponent<Props, State> {
  state = {
    flashes: [],
    lastConnected: true, // assume we were connected when the page loaded
  };

  UNSAFE_componentWillMount() {
    if (FlashesStore.preloaded) {
      this.setState({ flashes: FlashesStore.preloaded });
    }
  }

  componentDidMount() {
    FlashesStore.on("flash", this.handleStoreChange);
    FlashesStore.on("reset", this.handleStoreReset);
    CableStore.on("offline", this.handleConnectionError);
    CableStore.on("online", this.handleConnectionSuccess);
  }

  componentWillUnmount() {
    FlashesStore.off("flash", this.handleStoreChange);
    FlashesStore.off("reset", this.handleStoreReset);
    CableStore.off("offline", this.handleConnectionError);
    CableStore.off("online", this.handleConnectionSuccess);
  }

  handleStoreChange = (payload: FlashItem) => {
    // @ts-expect-error - TS2769 - No overload matches this call.
    this.setState({ flashes: this.state.flashes.concat(payload) });
  };

  hasConnectionErrorFlash() {
    return (
      this.state.flashes.length > 0 &&
      // @ts-expect-error - TS2339 - Property 'id' does not exist on type 'never'.
      this.state.flashes[0].id === FLASH_CONN_ERROR_ID
    );
  }

  handleStoreReset = () => {
    this.setState({
      flashes: this.hasConnectionErrorFlash()
        ? this.state.flashes.slice(0, 1)
        : [],
    });
  };

  // show a flash when there's a push connection issue
  //
  // NOTE: CableStore only sends this event after we've been disconnected from
  // the Action Cable server for 20 seconds, which only gets detected after two
  // pings fail to arrive (6 seconds). Thus this can only happen once in a given
  // 30 second period.
  handleConnectionError = () => {
    if (this.hasConnectionErrorFlash() || !this.state.lastConnected) {
      // try not to be irritating; don't add a new flash when
      // we're showing one, or when we haven't reconnected yet.
      //
      // this means we don't add a new flash if the user
      // dismissed one before a connection was restored
      // (kinder for those with flaky connections! <3)
      return;
    }

    const connectionFlash = {
      id: FLASH_CONN_ERROR_ID,
      // @ts-expect-error - TS2339 - Property 'ERROR' does not exist on type 'FlashesStore'.
      type: FlashesStore.ERROR,
      message: (
        <span>
          <span className="semi-bold">
            Couldn’t connect to Buildkite for push updates.
          </span>{" "}
          We’ll try to reconnect! Information won’t update automatically for
          now.
        </span>
      ),
    } as const;

    // prepend the flash
    this.setState({
      flashes: [connectionFlash].concat(this.state.flashes),
      lastConnected: false,
    });
  };

  // hide connection error flash (if it exists!) when reconnected
  handleConnectionSuccess = () => {
    const newState: {
      flashes?: Array<FlashItem>;
      lastConnected: boolean;
    } = {
      // make it known that we got a good connection!
      lastConnected: true,
    };

    // as the flash is always prepended (and no other flash is),
    // we can slice from the front rather than filter or find index
    if (this.hasConnectionErrorFlash()) {
      newState.flashes = this.state.flashes.slice(1);
    }

    // @ts-expect-error - TS2345 - Argument of type '{ flashes?: FlashItem[] | undefined; lastConnected: boolean; }' is not assignable to parameter of type 'State | ((prevState: Readonly<State>, props: Readonly<Props>) => State | Pick<State, "flashes" | "lastConnected"> | null) | Pick<...> | null'.
    this.setState(newState);
  };

  render() {
    if (this.state.flashes.length > 0) {
      return (
        <div className={classNames("mb4", this.props.className)}>
          {this.state.flashes.map((flash) => (
            <Flash
              // @ts-expect-error - TS2339 - Property 'id' does not exist on type 'never'.
              key={flash.id}
              flash={flash}
              onRemoveClick={this.handleFlashRemove}
            />
          ))}
        </div>
      );
    }

    return null;
  }

  handleFlashRemove = (flash: FlashItem) => {
    this.setState({
      flashes: this.state.flashes.filter(
        // @ts-expect-error - TS2339 - Property 'id' does not exist on type 'never'.
        (nextFlash) => flash.id !== nextFlash.id,
      ),
    });
  };
}

export default Flashes;

/**
 * @generated SignedSource<<d083af88bbd62985bf39bd05f1a89c9e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BuildStates = "BLOCKED" | "CANCELED" | "CANCELING" | "CREATING" | "FAILED" | "FAILING" | "NOT_RUN" | "PASSED" | "RUNNING" | "SCHEDULED" | "SKIPPED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type BuildTooltip_build$data = {
  readonly branch: string;
  readonly canceledAt: any | null;
  readonly commit: string;
  readonly createdAt: any | null;
  readonly createdBy: {
    readonly avatar?: {
      readonly url: string;
    };
    readonly maybeName?: string | null;
    readonly name?: string;
  } | null;
  readonly finishedAt: any | null;
  readonly message: string | null;
  readonly pipeline: {
    readonly commitShortLength: number;
    readonly defaultBranch: string | null;
  };
  readonly scheduledAt: any | null;
  readonly startedAt: any | null;
  readonly state: BuildStates;
  readonly url: string;
  readonly " $fragmentType": "BuildTooltip_build";
};
export type BuildTooltip_build$key = {
  readonly " $data"?: BuildTooltip_build$data;
  readonly " $fragmentSpreads": FragmentRefs<"BuildTooltip_build">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "Avatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    (v0/*: any*/)
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BuildTooltip_build",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "branch",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "commit",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "finishedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canceledAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "scheduledAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Pipeline",
      "kind": "LinkedField",
      "name": "pipeline",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "commitShortLength",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "defaultBranch",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            (v1/*: any*/)
          ],
          "type": "User",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": "maybeName",
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            (v1/*: any*/)
          ],
          "type": "UnregisteredUser",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Build",
  "abstractKey": null
};
})();

(node as any).hash = "f620d0d86a867c4ea442c45c7ccd8dd9";

export default node;

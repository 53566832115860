import * as React from "react";
import Loadable from "react-loadable";

type CodeMirrorInstance = {
  showHint: (arg1: Record<any, any>) => void;
  on: (arg1: string, arg2: (arg1?: any) => void) => unknown;
  off: (arg1: string, arg2: (arg1?: any) => void) => unknown;
  getValue: () => string;
  setValue: (arg1?: string | null | undefined) => void;
  execCommand: (arg1: string) => void;
};

type Props = {
  results?: string;
  className?: string;
  style?: Record<any, any>;
};

type LoadedProps = {
  CodeMirror: (
    arg1: HTMLDivElement,
    arg2: Record<any, any>,
  ) => CodeMirrorInstance;
};

type ReactLoadableLoadingProps = {
  error?: string;
};

class GraphQLExplorerConsoleResultsViewer extends React.PureComponent<
  Props & LoadedProps
> {
  codeMirrorInstance: CodeMirrorInstance | null | undefined;
  resultsElement: HTMLDivElement | null | undefined;

  componentDidMount() {
    if (this.resultsElement) {
      this.codeMirrorInstance = this.props.CodeMirror(this.resultsElement, {
        value: this.props.results || "",
        theme: "graphql",
        mode: "graphql-results",
        readOnly: true,
      });
    }
  }

  componentWillUnmount() {
    if (this.codeMirrorInstance) {
      this.codeMirrorInstance = null;
    }
  }

  componentDidUpdate(prevProps: Props & LoadedProps) {
    if (this.codeMirrorInstance && this.props.results !== prevProps.results) {
      this.codeMirrorInstance.setValue(this.props.results);
    }
  }

  render() {
    return (
      <div
        ref={(el) => (this.resultsElement = el)}
        className={this.props.className}
        style={this.props.style}
      />
    );
  }
}

// Instead of exporting the viewer directly, we'll export a `Loadable`
// Component that will allow us to load in dependencies and render the editor
// until then.
/* eslint-disable react/prop-types */
export default Loadable.Map({
  loader: {
    CodeMirror: () =>
      import("./codemirror").then(
        (module) =>
          // Add a "zero" delay after the module has loaded, to allow their
          // styles to take effect.
          new Promise((resolve: (result: Promise<never>) => void) => {
            setTimeout(() => resolve(module.default), 0);
          }),
      ),
  },

  loading(props: ReactLoadableLoadingProps) {
    if (props.error) {
      return <div>{props.error}</div>;
    }

    return null;
  },

  render(loaded: LoadedProps, props: Props) {
    return (
      <GraphQLExplorerConsoleResultsViewer
        CodeMirror={loaded.CodeMirror}
        results={props.results}
        className={props.className}
        style={props.style}
      />
    );
  },
});

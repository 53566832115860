/* eslint-disable react/display-name */

import { useState } from "react";
import { commitMutation, createRefetchContainer, graphql } from "react-relay";

import AutocompleteDialog from "app/components/shared/Autocomplete/Dialog";
import Button from "app/components/shared/Button";
import User from "app/components/shared/User";
import permissions from "app/lib/permissions";
import Environment from "app/lib/relay/environment";
import FlashesStore from "app/stores/FlashesStore";

const Chooser = ({ team, relay, onChoose, disabled }) => {
  const [loading, setLoading] = useState(false);
  const [showingDialog, setShowingDialog] = useState(false);
  const [search, setSearch] = useState("");

  const handleDialogOpen = () => {
    setLoading(true);
    setSearch("");

    relay.refetch(
      { teamSelector: `!${team.slug}`, memberAddSearch: "" },
      null,
      () => {
        setLoading(false);
      },
      { force: true },
    );

    setShowingDialog(true);
  };

  const handleDialogClose = () => {
    setShowingDialog(false);
    setSearch("");
  };

  const handleUserSearch = (memberAddSearch) => {
    setSearch(memberAddSearch);

    relay.refetch(
      {
        teamID: team.id,
        memberAddSearch,
        teamSelector: `!${team.slug}`,
      },
      null,
      null,
      { force: true },
    );
  };

  const handleUserSelect = (user) => {
    setShowingDialog(false);
    const environment = Environment.get();

    const input = { teamID: team.id, userID: user.id };

    commitMutation(environment, {
      mutation: graphql`
        mutation MembersChooserMutation($input: TeamMemberCreateInput!) {
          teamMemberCreate(input: $input) {
            clientMutationId
            team {
              id
              members {
                count
              }
            }
          }
        }
      `,
      variables: { input },
      onCompleted: onChoose,
      onError: (error) => {
        FlashesStore.flash(FlashesStore.ERROR, error.message);
      },
    });
  };

  const renderAutoCompleteSuggestions = () => {
    // If members has no edge node, we're still loading.
    if (!team.organization?.members || loading) {
      return [<AutocompleteDialog.Loader key="loading" />];
    }

    if (team.organization.members.edges.length > 0) {
      return team.organization.members.edges.map(({ node }) => {
        return [<User key={node.user.id} user={node.user} />, node.user];
      });
    } else if (search !== "") {
      // If search field isn't blank and we don't have members
      return [
        <AutocompleteDialog.ErrorMessage key="error">
          Could not find a user with name <em>{search}</em>
        </AutocompleteDialog.ErrorMessage>,
      ];
    }

    // It's not loading, searching and query has no users
    return [
      <AutocompleteDialog.ErrorMessage key="error">
        Could not find any more users to add
      </AutocompleteDialog.ErrorMessage>,
    ];
  };

  return permissions(team.permissions).check({
    allowed: "teamMemberCreate",
    render: () => (
      <div>
        <Button
          className="mb3"
          theme="primary"
          onClick={handleDialogOpen}
          disabled={disabled}
        >
          Add Member
        </Button>
        <AutocompleteDialog
          isOpen={showingDialog}
          onRequestClose={handleDialogClose}
          onSearch={handleUserSearch}
          onSelect={handleUserSelect}
          items={renderAutoCompleteSuggestions()}
          placeholder="Search all users…"
          selectLabel="Add"
          popover={false}
        />
      </div>
    ),
  });
};

const TeamsMembersChooserContainer = createRefetchContainer(
  Chooser,
  {
    team: graphql`
      fragment MembersChooser_team on Team
      @argumentDefinitions(
        memberAddSearch: { type: "String", defaultValue: "" }
        teamSelector: { type: "TeamSelector" }
      ) {
        id
        slug
        organization {
          members(
            search: $memberAddSearch
            first: 10
            order: RELEVANCE
            team: $teamSelector
          ) {
            edges {
              node {
                user {
                  id
                  ...User_user
                }
              }
            }
          }
        }

        permissions {
          teamMemberCreate {
            allowed
          }
        }
      }
    `,
  },
  graphql`
    query MembersChooserQuery(
      $team: ID!
      $memberAddSearch: String
      $teamSelector: TeamSelector
    ) {
      team(slug: $team) {
        ...MembersChooser_team
          @arguments(
            memberAddSearch: $memberAddSearch
            teamSelector: $teamSelector
          )
      }
    }
  `,
);

export default TeamsMembersChooserContainer;

/**
 * @generated SignedSource<<b93a4e2884b614256c443f17dfd2bf4c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AgentsCountRendererQuery$variables = {
  organizationID: string;
};
export type AgentsCountRendererQuery$data = {
  readonly organization: {
    readonly " $fragmentSpreads": FragmentRefs<"AgentsCount_organization">;
  } | null;
};
export type AgentsCountRendererQuery = {
  response: AgentsCountRendererQuery$data;
  variables: AgentsCountRendererQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationID"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationID"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AgentsCountRendererQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AgentsCount_organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AgentsCountRendererQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "uuid",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AgentConnection",
                "kind": "LinkedField",
                "name": "agents",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "count",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f5e6f5e18d772d398d18723d214225bb",
    "id": null,
    "metadata": {},
    "name": "AgentsCountRendererQuery",
    "operationKind": "query",
    "text": "query AgentsCountRendererQuery(\n  $organizationID: ID!\n) {\n  organization: node(id: $organizationID) {\n    __typename\n    ...AgentsCount_organization\n    id\n  }\n}\n\nfragment AgentsCount_organization on Organization {\n  id\n  uuid\n  agents {\n    count\n  }\n}\n"
  }
};
})();

(node as any).hash = "276235c9a4a40873cc6ed8f71c0058e3";

export default node;

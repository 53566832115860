/**
 * @generated SignedSource<<8c8048f83439ae5a954e94b6d9473998>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateBuildDialog_pipeline$data = {
  readonly defaultBranch: string | null;
  readonly organization: {
    readonly slug: string;
  };
  readonly pipelineTemplate: {
    readonly description: string | null;
    readonly name: string;
    readonly uuid: string;
  } | null;
  readonly slug: string;
  readonly " $fragmentType": "CreateBuildDialog_pipeline";
};
export type CreateBuildDialog_pipeline$key = {
  readonly " $data"?: CreateBuildDialog_pipeline$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreateBuildDialog_pipeline">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreateBuildDialog_pipeline",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "defaultBranch",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PipelineTemplate",
      "kind": "LinkedField",
      "name": "pipelineTemplate",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "uuid",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Pipeline",
  "abstractKey": null
};
})();

(node as any).hash = "c34f403a50ea086feb33f03d7f16e80d";

export default node;

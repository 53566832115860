/**
 * @generated SignedSource<<02cd3cec8a283ab92db9259f1ec86d4a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type PipelineAccessLevels = "BUILD_AND_READ" | "MANAGE_BUILD_AND_READ" | "READ_ONLY" | "%future added value";
export type TeamPipelineUpdateInput = {
  accessLevel: PipelineAccessLevels;
  clientMutationId?: string | null;
  id: string;
};
export type TeamRowUpdate_Mutation$variables = {
  input: TeamPipelineUpdateInput;
};
export type TeamRowUpdate_Mutation$data = {
  readonly teamPipelineUpdate: {
    readonly teamPipeline: {
      readonly accessLevel: PipelineAccessLevels;
    };
  } | null;
};
export type TeamRowUpdate_Mutation = {
  response: TeamRowUpdate_Mutation$data;
  variables: TeamRowUpdate_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accessLevel",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TeamRowUpdate_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "TeamPipelineUpdatePayload",
        "kind": "LinkedField",
        "name": "teamPipelineUpdate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TeamPipeline",
            "kind": "LinkedField",
            "name": "teamPipeline",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TeamRowUpdate_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "TeamPipelineUpdatePayload",
        "kind": "LinkedField",
        "name": "teamPipelineUpdate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TeamPipeline",
            "kind": "LinkedField",
            "name": "teamPipeline",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "be67f1ee9c89174d4861f5304f993d8d",
    "id": null,
    "metadata": {},
    "name": "TeamRowUpdate_Mutation",
    "operationKind": "mutation",
    "text": "mutation TeamRowUpdate_Mutation(\n  $input: TeamPipelineUpdateInput!\n) {\n  teamPipelineUpdate(input: $input) {\n    teamPipeline {\n      accessLevel\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a82ddd878d7232c172a2c88110bf74d9";

export default node;

/**
 * @generated SignedSource<<76671c9872651eda45f44754ef83db9a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Header_build$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CreateBuildDialog_build">;
  readonly " $fragmentType": "Header_build";
};
export type Header_build$key = {
  readonly " $data"?: Header_build$data;
  readonly " $fragmentSpreads": FragmentRefs<"Header_build">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Header_build",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreateBuildDialog_build"
    }
  ],
  "type": "Build",
  "abstractKey": null
};

(node as any).hash = "47d098fc765799e51f4341c06e768746";

export default node;

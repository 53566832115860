import { useEffect, useRef, useState, type FunctionComponent } from "react";
import moment from "moment";
import useFetch from "use-http";
import merge from "lodash/merge";
import ChartJS, { type ChartConfiguration } from "chart.js/auto";

import Spinner from "app/components/shared/Spinner";
import { DateFilter } from "app/components/analytics/shared/DateFilters";
import { LoadingChart } from "app/components/analytics/shared/LoadingStates";
import { Metrics } from "app/components/analytics/shared/type";

type ChartMetrics = Pick<Metrics, "chartType" | "metric">;

type Props = ChartMetrics & {
  height: string | number;
  width?: string | number;
  filter: DateFilter;
  branch: string | null | undefined;
  label?: string | null | undefined;
  endpoint: string;
  options?: object | null | undefined;
};

type ChartType = { destroy: () => void };
type ChartDataType = { x: Array<string>; y: Array<number> };

export const chartOptions = (
  chartType: Props["chartType"],
  chartData: ChartDataType,
  metric: Props["metric"],
  label: Props["label"],
  options: Props["options"],
) => {
  const chartColor = "#BFEDFF";

  const chartOptions = {
    type: chartType || "bar",
    data: {
      labels: chartData?.x || null,
      datasets: [
        {
          label: label || metric,
          fill: false,
          backgroundColor: chartColor,
          borderRadius: 5,
          data: chartData?.y || null,
          borderColor: chartColor,
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      interaction: {
        intersect: false,
        mode: "index",
      },
      elements: {
        bar: { hoverBackgroundColor: "#7FDBFF" },
        line: { spanGaps: true },
      },
      scales: {
        yIndex: {
          grid: { drawBorder: false },
          beginAtZero: true,
        },
        xIndex: {
          grid: { display: false },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
      },
    },
  };

  merge(chartOptions, options);

  return chartOptions;
};

const Chart = (props: Props) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  let chart: ChartType;

  ChartJS.defaults.font.family =
    "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, sans-serif";

  const [chartData, setChartData] = useState<ChartDataType | null>(null);
  const url = new URL("chart_data", props.endpoint);
  url.searchParams.append(
    "from",
    moment(props.filter.from).format("YYYY-MM-DD"),
  );
  url.searchParams.append("metric", props.metric);
  if (props.branch != null) {
    url.searchParams.append("branch", props.branch);
  }

  const { get, response, loading, error } = useFetch(url.href);

  useEffect(() => {
    loadChartData();
  }, [props.filter, props.branch]);

  const loadChartData = async () => {
    const data = await get();
    if (response.ok) {
      setChartData(data);
    }
  };

  useEffect(() => {
    if (chartData && canvasRef.current) {
      chart = new ChartJS(
        canvasRef.current,
        chartOptions(
          props.chartType,
          chartData,
          props.metric,
          props.label,
          props.options,
        ) as ChartConfiguration,
      );
    }

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [chartData]);

  if (error) {
    return <div>There was an error loading the chart</div>;
  }

  return (
    <div data-testid="chart-container" style={{ height: props.height }}>
      {/* use the LoadingChart for MiniChart and Spinner otherwise */}
      {loading && props.height === 50 && <LoadingChart barWidth="25px" />}
      {loading && props.height !== 50 && <Spinner />}
      {!loading && chartData && (
        <canvas
          height={props.height}
          width={props.width}
          ref={canvasRef}
          data-testid="canvas"
        />
      )}
    </div>
  );
};

export default Chart;

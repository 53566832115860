import BuildStatusDescription from "app/components/shared/BuildStatusDescription";
import { BuildStates } from "app/constants/BuildStates";

interface TimeProps {
  // eslint-disable-next-line react/no-unused-prop-types
  state: BuildStates;
  // eslint-disable-next-line react/no-unused-prop-types
  createdAt: string;
  // eslint-disable-next-line react/no-unused-prop-types
  canceledAt?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  finishedAt?: string;
}

export function TimeAgo(props: TimeProps) {
  return (
    <small className="dark-gray build-secondary-time">
      <BuildStatusDescription build={props} />
    </small>
  );
}

interface AuthorProps {
  authorName?: string;
  creatorVerified?: boolean;
}

export function AuthorAndTime({
  authorName,
  creatorVerified,
  ...props
}: AuthorProps & TimeProps) {
  if (!authorName) {
    return (
      <div className="build-author flex items-center">
        <TimeAgo {...props} />
      </div>
    );
  }

  return (
    <div className="build-author">
      <div>
        {authorName}
        {creatorVerified && <div className="build-verified">Verified</div>}
      </div>

      <TimeAgo {...props} />
    </div>
  );
}

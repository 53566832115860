// <turbo-stream action="visit" target="<--some url-->"></turbo-stream>
// This custom turbo stream action is mainly used as a response from server side actions to
// "break out of a frame". In a controller it's used like so:
// format.turbo_stream do
//   flash[:success] = "it was a success"
//   render turbo_stream: turbo_stream.action(:visit, some_application_path)
// end
// This is a temporary solution to break out of a frame. Hopefully a framework-supported
// way will be created soon. For ongoing progress on that see:
// https://github.com/hotwired/turbo-rails/pull/367

import { Turbo } from "@hotwired/turbo-rails";

Turbo.StreamActions.visit = function () {
  // Since this action is used to break out of a frame in a non-GET action
  // the cache needs to be cleared so the visit presents up to date data.
  Turbo.cache.clear();
  Turbo.visit(this.target);
};

import EmptyState from "app/components/shared/EmptyState";

export default function NotEnoughData() {
  return (
    <div className="w-full">
      <EmptyState
        emoji="🕥"
        heading="Hang tight"
        subheading="The waterfall chart displays when there's enough data from the running build."
      />
    </div>
  );
}

/**
 * @generated SignedSource<<5c080d9ae2d5d6de114ecdd7246e31e8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type JobEventActorType = "AGENT" | "DISPATCH" | "SYSTEM" | "USER" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type JobEventRetried_event$data = {
  readonly actor: {
    readonly node: {
      readonly name?: string;
      readonly uuid?: string;
      readonly " $fragmentSpreads": FragmentRefs<"UserAvatar_user">;
    } | null;
    readonly type: JobEventActorType;
  };
  readonly automaticRule?: {
    readonly exitStatus: string | null;
    readonly limit: string | null;
    readonly signal: string | null;
    readonly signalReason: string | null;
  } | null;
  readonly retriedInJob?: {
    readonly uuid: string;
  } | null;
  readonly timestamp: any;
  readonly " $fragmentType": "JobEventRetried_event";
};
export type JobEventRetried_event$key = {
  readonly " $data"?: JobEventRetried_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"JobEventRetried_event">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "JobEventRetried_event",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timestamp",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "JobEventActor",
      "kind": "LinkedField",
      "name": "actor",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                (v0/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "UserAvatar_user"
                }
              ],
              "type": "User",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "JobRetryRuleAutomatic",
          "kind": "LinkedField",
          "name": "automaticRule",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "exitStatus",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "signal",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "signalReason",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "limit",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "JobTypeCommand",
          "kind": "LinkedField",
          "name": "retriedInJob",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "type": "JobEventRetried",
      "abstractKey": null
    }
  ],
  "type": "JobEvent",
  "abstractKey": "__isJobEvent"
};
})();

(node as any).hash = "0f31c5535e9a0bb150b93d106a0630cf";

export default node;

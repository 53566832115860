import React from "react";
import { Form } from "../form";
import { useFormContext } from "react-hook-form";
import Features from "./Features";
import Quote from "./Quote";
import Support from "./Support";
import styled from "styled-components";
import gradient from "./gradient.svg";
import SlideDownTransition from "./SlideDownTransition";
import { AnimatePresence } from "framer-motion";

const StyledFeed = styled.div`
  overflow: auto;
  background: url(${gradient});
  background-size: cover;
  border-radius: 8px;
  position: relative;
  padding: 2em;

  // Hide the scrollbar since we're using a fade out
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  @media (max-width: 768px) {
    display: none;
  }
`;

const Spacing = styled.div`
  height: 1.8em;
`;

const Feed = () => {
  const { watch } = useFormContext<Form>();
  const [role, vendors, goals] = watch(["role", "vendors", "goals"]);

  const items: [string, React.ReactNode][] = [];

  if (goals.length) {
    items.push(["features", <Features key="features" goals={goals} />]);
  }

  if (role) {
    items.push(["quote", <Quote key="quote" role={role} />]);
  }

  if (vendors.length) {
    items.push(["support", <Support key="support" vendors={vendors} />]);
  }

  return (
    <StyledFeed>
      <AnimatePresence initial={false}>
        {items.reverse().map(([key, item], idx) => (
          <SlideDownTransition
            key={key}
            style={{ zIndex: items.length - idx, position: "relative" }}
          >
            {item}
            {idx < items.length && <Spacing />}
          </SlideDownTransition>
        ))}
      </AnimatePresence>
    </StyledFeed>
  );
};

export default Feed;

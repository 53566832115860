/* eslint-disable react/jsx-no-bind */
import classNames from "classnames";
import Badge from "app/components/shared/Badge";
import { NavLink, NavLinkProps, useParams } from "react-router-dom";
import { useBuild } from "app/components/Playground/BuildContext";
import { BuildView, StepRouteParams } from "..";

const BuildNavLink = (props: NavLinkProps) => {
  return (
    <NavLink
      {...props}
      className={({ isActive }) =>
        classNames(
          "px-2 text-sm",
          "btn btn-tab text-current focus:text-current transition-colors",
          {
            "btn-tab--active": isActive,
          },
        )
      }
    />
  );
};

function BuildContentNavigation({ currentView }: { currentView: BuildView }) {
  const { store, build } = useBuild();
  const { stepOrJobId = "" } = useParams<StepRouteParams>();

  if (!store || !build) {
    throw new Error("Missing build context");
  }

  // Assemble the URLs for the different views for this build;
  const listViewHref = build.path;
  let waterfallHref = `${build.path}/waterfall`;
  let canvasHref = `${build.path}/canvas`;
  const summaryHref = `${build.path}/summary`;
  const testDigestHref = `${build.path}/test-digest`;

  const showTestDigest = build.hasTestAnalytics;

  const renderUpgradeBadge = () => {
    return store.waterfallAvailable ? null : (
      <Badge outline={false} className="white bg-purple right">
        Upgrade
      </Badge>
    );
  };

  if (Features.BuildSidebar) {
    // Keep the drawer open when navigating between the different views
    if (stepOrJobId) {
      waterfallHref = `${build.path}/waterfall/${stepOrJobId}`;
      canvasHref = `${build.path}/canvas/${stepOrJobId}`;
    }

    return (
      <nav data-testid="BuildContentNavigation" className="flex">
        <BuildNavLink to={summaryHref}>Summary</BuildNavLink>
        <BuildNavLink to={canvasHref}>Canvas</BuildNavLink>
        <BuildNavLink to={waterfallHref}>
          <span>Waterfall</span>
          {renderUpgradeBadge()}
        </BuildNavLink>
        {showTestDigest && (
          <BuildNavLink to={testDigestHref}>Test digest</BuildNavLink>
        )}
      </nav>
    );
  }

  return (
    <nav
      className="Tablist flex border-bottom border-gray mb-[-1px]"
      data-testid="buildViewTabsNav"
    >
      <NavLink
        className={classNames("btn btn-tab text-current focus:text-current", {
          "btn-tab--active": currentView === BuildView.JobList,
        })}
        to={listViewHref}
      >
        Jobs
      </NavLink>

      <NavLink
        className={({ isActive }) =>
          classNames("btn btn-tab text-current focus:text-current", {
            "btn-tab--active": isActive,
          })
        }
        to={canvasHref}
      >
        Canvas <Badge outline={true}>New</Badge>
      </NavLink>

      <NavLink
        className={({ isActive }) =>
          classNames("btn btn-tab text-current focus:text-current", {
            "btn-tab--active": isActive,
          })
        }
        to={waterfallHref}
      >
        <span>Waterfall</span>
        {renderUpgradeBadge()}
      </NavLink>

      {showTestDigest && (
        <NavLink
          className={({ isActive }) =>
            classNames("btn btn-tab text-current focus:text-current", {
              "btn-tab--active": isActive,
            })
          }
          to={testDigestHref}
        >
          <span>Test digest</span>
        </NavLink>
      )}
    </nav>
  );
}

export default BuildContentNavigation;

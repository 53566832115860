import { PureComponent } from "react";

type Props = {
  name: string;
};

export default class Argument extends PureComponent<Props> {
  render() {
    return <span className="cm-attribute">{this.props.name}</span>;
  }
}

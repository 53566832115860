interface PageContext {
  title: string;
  path: string;
  referrer: string;
  url: string;
  search: string;
}

interface EventProperties {
  [key: string]: string | number | boolean;
}

interface Options {
  keepalive?: boolean;
}

/**
 * A utility function to track events in Segment via a backend proxy to avoid adding Segment's JS library to the frontend.
 * This function is used to track events that are not related to the user's current page, e.g. when a user clicks a button.
 *
 * @param event a string that identifies the event. For naming conventions see https://guide.buildkite.net/data/guides/loading-data/segment/Conventions/#event-naming
 * @param properties any custom properties you want to attach to the event
 */
export function track(
  event: string,
  properties: EventProperties = {},
): Promise<any> {
  return _trackViaProxy({ event, properties });
}

/**
 * A helper function to attach the event tracker function to a clickable element.
 *
 * @param element the element to track click events on
 * @param event the name of the event you'd like to track
 * @param properties any custom properties to pass through to segment (e.g. { "category": "button" })
 */
export function trackClick(
  element: HTMLElement,
  event: string,
  properties: EventProperties = {},
): void {
  if (element) {
    if (!(element instanceof Element)) {
      throw new TypeError("element must be an HTML Element"); // for the benefit of non-TS environments e.g. `.erb` files
    }
    element.addEventListener("click", () => {
      _trackViaProxy({ event, properties });
    });
  }
}

function _trackViaProxy({
  event,
  properties = {},
}: {
  event: string;
  properties?: EventProperties;
  options?: Options;
}): Promise<any> {
  const requestBody = {
    event: event,
    properties: properties,
    context: {
      page: _getPageContext(),
    },
  };

  const requestOptions: RequestInit = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": window._csrf.token,
    },
    body: JSON.stringify(requestBody),
    keepalive: true,
  };

  // We don't care about the response, but if we don't use the response the browser won't see the
  // request as completed, so we'll parse it anyway.
  return fetch("/_analytics/track", requestOptions)
    .then((response) => response.text())
    .then((data) => (data ? JSON.parse(data) : {}));
}

function _getPageContext(): PageContext {
  return {
    title: document.title,
    path: window.location.pathname,
    referrer: document.referrer,
    url: window.location.href,
    search: window.location.search,
  };
}

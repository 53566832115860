import { PureComponent } from "react";
import Icon from "app/components/shared/Icon";

export type WaiterStepType = {
  type: "waiter";
  id: string;
  state: string;
  continueOnFailure: boolean;
  stepUuid: string;
};

type Props = {
  job: WaiterStepType;
};

export default class WaiterJobSummary extends PureComponent<Props> {
  render() {
    const continueOnFailureNode = this.props.job.continueOnFailure && (
      <div className="dark-gray">
        Build will continue even if previous stage fails
      </div>
    );

    return (
      <div
        className={`build-details-pipeline-waiter-job --state-${this.props.job.state}`}
      >
        <span>
          <Icon icon="custom/outline/chevron-down" />
        </span>
        {continueOnFailureNode}
      </div>
    );
  }
}

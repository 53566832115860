import { createFragmentContainer, graphql } from "react-relay";

import FriendlyTime from "app/components/shared/FriendlyTime";

import { Section, SectionHeading } from "./shared";

const EventData = ({ data }) => {
  if (data === undefined || data === "{}") {
    return null;
  }

  const prettyData = JSON.stringify(JSON.parse(data), null, "  ");

  return [
    <dt className="mt1 dark-gray" key="data-title">
      Event Data
    </dt>,
    <dd className="ml0" key="data-definition">
      <pre className="code">{prettyData}</pre>
    </dd>,
  ];
};

type Props = {
  auditEvent: {
    uuid: string;
    type: string;
    occurredAt: string;
    data?: string;
  };
};

const AuditLogEventSection = ({ auditEvent }: Props) => (
  <Section>
    <SectionHeading className="m0 mb2">Event</SectionHeading>
    <dl className="m0">
      <dt className="mt1 dark-gray">Event Timestamp</dt>
      <dd className="ml0">
        <FriendlyTime value={auditEvent.occurredAt} />
      </dd>
      <dt className="mt1 dark-gray">Event UUID</dt>
      <dd className="ml0 monospace">{auditEvent.uuid}</dd>
      <dt className="mt1 dark-gray">Event Type</dt>
      <dd className="ml0 monospace">{auditEvent.type}</dd>
      {/* @ts-expect-error - TS2786 - 'EventData' cannot be used as a JSX component. */}
      <EventData data={auditEvent.data} />
    </dl>
  </Section>
);

export default createFragmentContainer(AuditLogEventSection, {
  auditEvent: graphql`
    fragment eventSection_auditEvent on AuditEvent {
      uuid
      type
      occurredAt
      data
    }
  `,
});

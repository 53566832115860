import Emojify from "app/components/shared/Emojify";
import { TestPlanProps } from "../type";
import Icon from "app/components/shared/Icon";

const TestPlan = ({
  runName,
  orchestrationLink,
  partitions,
}: TestPlanProps) => (
  <li className="flex flex-col gap-1 p-2 border-bottom border-gray-400 last:border-b-0">
    <a
      href={orchestrationLink}
      className="!no-underline text-charcoal-800 hover:!underline hover:cursor-pointer font-medium w-fit"
    >
      {runName ? (
        <Emojify text={runName} />
      ) : (
        <div className="flex items-center gap-1">
          <Icon
            icon="heroicons/16/solid/play"
            className="text-gray-600"
            style={{ height: 14, width: 14 }}
          />
          Test Plan
        </div>
      )}
    </a>
    <div className="flex justify-between items-center px-2 w-full h-5 rounded-sm bg-purple-300">
      <span className="flex items-center gap-2 text-[11px] text-white font-mono font-semibold">
        <span className="opacity-60">Min</span>
        {partitions.min}
      </span>
      <span className="flex items-center gap-2 text-[11px] text-white font-mono font-semibold">
        <span className="opacity-60">Max</span>
        {partitions.max}
      </span>
    </div>
    <div className="flex items-center gap-1">
      <div className="flex items-center gap-1">
        <span className="font-mono">{partitions.count}</span>
        <span className="text-gray-800">Partitions</span>
      </div>
    </div>
  </li>
);

export default TestPlan;

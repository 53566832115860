import classNames from "classnames";

import Icon from "app/components/shared/Icon";
import { useBuild } from "app/components/Playground/BuildContext";

const variants = {
  warning: {
    title: "Unresolved step dependencies",
    body: "This step is waiting for the following dependencies to resolve before running, but they do not currently appear in the pipeline:",
    icon: "heroicons/16/solid/exclamation-triangle",
    borderColor: "border-orange-500",
    bgColor: "bg-orange-100",
    iconColor: "text-orange-500",
  },
  error: {
    title: "Step dependencies not found",
    body: "The following step dependencies were not found during the build:",
    icon: "heroicons/16/solid/x-mark",
    borderColor: "border-red-500",
    bgColor: "bg-red-500",
    iconColor: "text-white",
  },

  group_error: {
    title: "Group step dependencies not found",
    body: "The following group step dependencies were not found during the build:",
    icon: "heroicons/16/solid/x-mark",
    borderColor: "border-red-500",
    bgColor: "bg-red-500",
    iconColor: "text-white",
  },

  group_warning: {
    title: "Unresolved group step dependencies",
    body: "The following group step dependencies could not be found during the build:",
    icon: "heroicons/16/solid/exclamation-triangle",
    borderColor: "border-orange-500",
    bgColor: "bg-orange-100",
    iconColor: "text-orange-500",
  },
};

export function MissingStepDependencies({
  variant = "warning",
  stepUuid,
  group = false,
}: {
  variant?: keyof typeof variants;
  stepUuid: string;
  group: boolean;
}) {
  if (group) {
    variant = `group_${variant}` as keyof typeof variants;
  }

  const { store } = useBuild();

  if (!store) {
    throw new Error("Missing build context");
  }

  const step = store.steps.get(stepUuid);

  if (!step) {
    return null;
  }

  const { missingDependencies = [] } = step;

  const dependencies = missingDependencies || [];

  if (!dependencies.length) {
    return null;
  }

  const { borderColor, bgColor, icon, iconColor, title, body } =
    variants[variant];

  return (
    <div
      className={classNames(
        "border rounded flex overflow-hidden mt-4",
        borderColor,
      )}
    >
      <div
        className={classNames(
          "w-8 flex-shrink-0 flex justify-center border-r pt-4",
          borderColor,
          bgColor,
        )}
      >
        <Icon icon={icon} className={classNames("w-4 h-4", iconColor)} />
      </div>

      <div className="px-4 py-3 flex-grow">
        <h3 className="text-base mt-0 mb-2">{title}</h3>

        <p className="mt-0 mb-3">{body}</p>

        <ul className="divide-y depth rounded max-w-lg mb-3">
          {dependencies.map((dependency) => (
            <li
              key={dependency}
              className="monospace border-gray-300 text-xs p-2.5 m-0"
            >
              {dependency}
            </li>
          ))}
        </ul>

        <p className="m-0">
          Check the pipeline is configured correctly, or see the{" "}
          <a
            href="/docs/pipelines/dependencies"
            className="text-inherit underline"
          >
            documentation
          </a>
          .
        </p>
      </div>
    </div>
  );
}

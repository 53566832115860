/**
 * @generated SignedSource<<6d9d9ed1d3c3cecdb3f06abc64adedc0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Members_team$data = {
  readonly id: string;
  readonly members: {
    readonly count: number;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"row_teamMember">;
      } | null;
    } | null> | null;
    readonly " $fragmentSpreads": FragmentRefs<"ShowMoreFooter_connection">;
  } | null;
  readonly organization: {
    readonly permissions: {
      readonly organizationMemberViewSensitive: {
        readonly allowed: boolean;
      } | null;
    };
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"MembersChooser_team">;
  readonly " $fragmentType": "Members_team";
};
export type Members_team$key = {
  readonly " $data"?: Members_team$data;
  readonly " $fragmentSpreads": FragmentRefs<"Members_team">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "includeSensitiveInfo"
    },
    {
      "defaultValue": 10,
      "kind": "LocalArgument",
      "name": "pageSize"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "search"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "Members_team",
  "selections": [
    (v0/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MembersChooser_team"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "pageSize"
        },
        {
          "kind": "Literal",
          "name": "order",
          "value": "NAME"
        },
        {
          "kind": "Variable",
          "name": "search",
          "variableName": "search"
        }
      ],
      "concreteType": "TeamMemberConnection",
      "kind": "LinkedField",
      "name": "members",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ShowMoreFooter_connection"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "count",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TeamMemberEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TeamMember",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "args": [
                    {
                      "kind": "Variable",
                      "name": "includeSensitiveInfo",
                      "variableName": "includeSensitiveInfo"
                    }
                  ],
                  "kind": "FragmentSpread",
                  "name": "row_teamMember"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OrganizationPermissions",
          "kind": "LinkedField",
          "name": "permissions",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Permission",
              "kind": "LinkedField",
              "name": "organizationMemberViewSensitive",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "allowed",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Team",
  "abstractKey": null
};
})();

(node as any).hash = "8ef75f100ecde16410e4866deb7043c6";

export default node;

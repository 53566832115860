import OrganizationAvatar from "app/components/shared/OrganizationAvatar";
import NavButtonRightArrow from "app/components/shared/Icon/component/navButtonRightArrow.svg";
import { Organization, OrganizationMenu } from ".";

type OrganizationButtons = Omit<OrganizationMenu, "className">;

type PublicOrganizationBadgeProps = Omit<Organization, "slug" | "isPublic"> & {
  styles: OrganizationButtons;
};

const PublicOrganizationBadge = ({
  name,
  iconUrl,
  styles,
}: PublicOrganizationBadgeProps) => {
  const { paddingLeft = 15, borderLeft } = styles;

  return (
    <div
      className="px2"
      style={{ display: "flex", alignItems: "center", borderLeft, paddingLeft }}
    >
      {iconUrl && (
        <OrganizationAvatar
          organization={{ name, iconUrl }}
          className="flex-none mr1"
          style={{ height: 26, width: 26 }}
          suppressAltText={true}
        />
      )}
      <span className="pr3 semi-bold">{name}</span>
      <NavButtonRightArrow />
    </div>
  );
};

export default PublicOrganizationBadge;

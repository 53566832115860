import { useState, useCallback } from "react";
import Emojify from "app/components/shared/Emojify";
import Icon from "app/components/shared/Icon";

type Props = {
  message: string;
  urlParam?: string;
};

const SuccessNotice = (props: Props) => {
  const [dismissed, setDismissed] = useState<boolean>(false);

  // @ts-expect-error - TS2554 - Expected 2 arguments, but got 1.
  const handleDismiss = useCallback(() => {
    setDismissed(true);

    if (props.urlParam) {
      const url = new URL(window.location.href);
      url.searchParams.delete(props.urlParam);
      history.pushState({}, "", url.toString());
    }
  });

  if (dismissed) {
    return null;
  }

  return (
    <div
      role="banner"
      className="mb4 border border-lime rounded"
      style={{
        backgroundColor: "#E8FAF2",
        boxShadow: "0px 2px 8px #14CC8033",
      }}
    >
      <div className="flex items-center my1 mx3">
        <div className="flex flex-auto flex-wrap justify-end">
          <div
            className="flex flex-auto items-center"
            style={{ flexBasis: 300 }}
          >
            <Emojify
              text="🎉"
              className="flex-none ml1 mr3 line-height-2"
              style={{ fontSize: 40 }}
              title="Success!"
            />
            <div className="flex-auto mr3 py1">
              <strong>Success!</strong> {props.message}
            </div>
          </div>
        </div>
        <button
          className="btn py4 px3"
          style={{ marginRight: -15 }}
          onClick={handleDismiss}
          disabled={dismissed}
          aria-label="Dismiss notice"
        >
          <Icon className="mx-auto" icon="close" title="Close" />
        </button>
      </div>
    </div>
  );
};

export default SuccessNotice;

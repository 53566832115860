import { createFragmentContainer, graphql } from "react-relay";
import JobEvent from "../JobEvent";

type Props = {
  event: any;
  previousTimestamp: string | null | undefined;
};

function JobEventChanged({ event, previousTimestamp }: Props) {
  return (
    <JobEvent timestamp={event.timestamp} previousTimestamp={previousTimestamp}>
      <JobEvent.Label>Job Changed</JobEvent.Label>
      <JobEvent.Content>
        <pre>{JSON.stringify(event, null, 4)}</pre>
      </JobEvent.Content>
    </JobEvent>
  );
}

export default createFragmentContainer(JobEventChanged, {
  event: graphql`
    fragment JobEventChanged_event on JobEvent {
      ... on JobEvent {
        timestamp
      }
    }
  `,
});

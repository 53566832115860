import Icon from "app/components/shared/Icon";

const CommitSha = ({
  commitSha,
  repoUrl,
}: {
  commitSha: string;
  repoUrl: string | null | undefined;
}) => {
  if (repoUrl) {
    let icon;
    const { hostname } = new URL(repoUrl);

    if (hostname.includes("github")) {
      icon = "github";
    } else if (hostname.includes("gitlab")) {
      icon = "gitlab";
    } else if (hostname.includes("bitbucket")) {
      icon = "bitbucket";
    } else {
      icon = "git";
    }

    return (
      <a
        className="color-inherit hover-faded flex items-center"
        href={`${repoUrl}/commit/${commitSha}`}
      >
        <Icon
          data-testid={icon}
          icon={icon}
          className="flex-none mr-1"
          style={{ width: 18, height: 18 }}
        />
        {commitSha.substring(0, 10)}
      </a>
    );
  }

  return <span>{commitSha.substring(0, 10)}</span>;
};

export default CommitSha;

import { createFragmentContainer, graphql } from "react-relay";
import {
  AttributeTable,
  AttributeTableRow,
} from "app/components/shared/AttributeTable";
import JobEvent from "../JobEvent";
import AgentBadge from "app/components/AgentBadge";

type Props = {
  event: any;
  previousTimestamp: string | null | undefined;
};

function JobEventTimedOut({
  event: {
    timestamp,
    exitStatus,
    actor: { node: agent },
  },

  previousTimestamp,
}: Props) {
  // Refine types here — this sucks. Because we’re using unions and interfaces and junk for
  // all these types they’re all effectively maybe types.
  if (!agent || !agent.name || !agent.uuid || !agent.organization) {
    return null;
  }

  return (
    <JobEvent timestamp={timestamp} previousTimestamp={previousTimestamp}>
      <JobEvent.Label>
        <AgentBadge agent={agent} /> Received Timeout Signal and Stopped Running
        Job
      </JobEvent.Label>
      <JobEvent.Description>
        Job exceeded its execution timeout limit and was automatically stopped.
      </JobEvent.Description>
      <JobEvent.Content>
        <AttributeTable>
          <AttributeTableRow title="Exit Status">
            <code className="dark-gray monospace">{exitStatus}</code>
          </AttributeTableRow>
        </AttributeTable>
      </JobEvent.Content>
    </JobEvent>
  );
}

export default createFragmentContainer(JobEventTimedOut, {
  event: graphql`
    fragment JobEventTimedOut_event on JobEvent {
      ... on JobEvent {
        timestamp

        ... on JobEventTimedOut {
          exitStatus
        }

        actor {
          node {
            ...AgentBadge_agent
            ... on Agent {
              name
              uuid
              organization {
                slug
              }
            }
          }
        }
      }
    }
  `,
});

/**
 * @generated SignedSource<<6f02724135902ba45ad021cdfb5eb256>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type TeamSuiteDeleteInput = {
  clientMutationId?: string | null;
  force?: boolean | null;
  id: string;
};
export type SuitesTeamDeleteMutation$variables = {
  input: TeamSuiteDeleteInput;
};
export type SuitesTeamDeleteMutation$data = {
  readonly teamSuiteDelete: {
    readonly deletedTeamSuiteID: string;
    readonly team: {
      readonly id: string;
      readonly suites: {
        readonly count: number;
      } | null;
    } | null;
  } | null;
};
export type SuitesTeamDeleteMutation = {
  response: SuitesTeamDeleteMutation$data;
  variables: SuitesTeamDeleteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "TeamSuiteDeletePayload",
    "kind": "LinkedField",
    "name": "teamSuiteDelete",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deletedTeamSuiteID",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Team",
        "kind": "LinkedField",
        "name": "team",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TeamSuiteConnection",
            "kind": "LinkedField",
            "name": "suites",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "count",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SuitesTeamDeleteMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SuitesTeamDeleteMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "169c58f7f208c71939fe61bd51ef5c6a",
    "id": null,
    "metadata": {},
    "name": "SuitesTeamDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation SuitesTeamDeleteMutation(\n  $input: TeamSuiteDeleteInput!\n) {\n  teamSuiteDelete(input: $input) {\n    deletedTeamSuiteID\n    team {\n      id\n      suites {\n        count\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8670780a09a256494a88863ca79e8ad8";

export default node;

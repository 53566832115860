/**
 * @generated SignedSource<<c10f0603365c72a0215c64d78ae4314e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type TeamMemberRole = "MAINTAINER" | "MEMBER" | "%future added value";
export type TeamMemberUpdateInput = {
  clientMutationId?: string | null;
  id: string;
  role: TeamMemberRole;
};
export type MembersTeamUpdateMutation$variables = {
  input: TeamMemberUpdateInput;
};
export type MembersTeamUpdateMutation$data = {
  readonly teamMemberUpdate: {
    readonly teamMember: {
      readonly id: string;
      readonly role: TeamMemberRole;
    };
  } | null;
};
export type MembersTeamUpdateMutation = {
  response: MembersTeamUpdateMutation$data;
  variables: MembersTeamUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "TeamMemberUpdatePayload",
    "kind": "LinkedField",
    "name": "teamMemberUpdate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TeamMember",
        "kind": "LinkedField",
        "name": "teamMember",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "role",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MembersTeamUpdateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MembersTeamUpdateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5a9ed4b228caa236eec06487e4e89334",
    "id": null,
    "metadata": {},
    "name": "MembersTeamUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation MembersTeamUpdateMutation(\n  $input: TeamMemberUpdateInput!\n) {\n  teamMemberUpdate(input: $input) {\n    teamMember {\n      id\n      role\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1468c1d259ec99f61898a573f28273e3";

export default node;

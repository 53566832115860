import * as React from "react";
import Loadable from "react-loadable";

import Button from "app/components/shared/Button";
import { interpolateQuery } from "../Console/query";
import consoleState from "../state";

type CodeMirrorInstance = {
  showHint: (arg1: Record<any, any>) => void;
  on: (arg1: string, arg2: (arg1?: any) => void) => unknown;
  off: (arg1: string, arg2: (arg1?: any) => void) => unknown;
  getValue: () => string;
  setValue: (arg1?: string | null | undefined) => void;
  execCommand: (arg1: string) => void;
};

type Props = {
  query: string;
  organization: any | null | undefined;
};

type LoadedProps = {
  CodeMirror: (
    arg1: HTMLDivElement,
    arg2: Record<any, any>,
  ) => CodeMirrorInstance;
};

type ReactLoadableLoadingProps = {
  error?: string;
};

class GraphQLExplorerExampleSection extends React.PureComponent<
  Props & LoadedProps
> {
  codeMirrorInstance: CodeMirrorInstance | null | undefined;
  exampleQueryContainerElement: HTMLDivElement | null | undefined;

  getInterpolatedQuery() {
    return interpolateQuery(this.props.query, {
      organization: this.props.organization,
    });
  }

  componentDidMount() {
    if (this.exampleQueryContainerElement) {
      this.codeMirrorInstance = this.props.CodeMirror(
        this.exampleQueryContainerElement,
        {
          value: this.getInterpolatedQuery(),
          mode: "graphql",
          theme: "graphql",
          readOnly: true,
        },
      );
    }
  }

  componentWillUnmount() {
    if (this.codeMirrorInstance) {
      this.codeMirrorInstance = null;
    }
  }

  componentDidUpdate(prevProps: { organization: any | null | undefined }) {
    // If the organization changes, we'll need to re-interpolate the query as
    // the variables inside it will probably have changed.
    if (
      this.codeMirrorInstance &&
      this.props.organization !== prevProps.organization
    ) {
      this.codeMirrorInstance.setValue(this.getInterpolatedQuery());
    }
  }

  render() {
    return (
      <div className="relative">
        <Button
          style={{ right: 0, top: 0, zIndex: 10 }}
          className="absolute bg-white"
          onClick={this.handleCopyToConsoleClick}
        >
          Copy to Console
        </Button>

        <div ref={(el) => (this.exampleQueryContainerElement = el)} />
      </div>
    );
  }

  handleCopyToConsoleClick = (
    event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>,
  ) => {
    event.preventDefault();

    consoleState.setQuery(this.getInterpolatedQuery());

    window.location.pathname = "/user/graphql/console";
  };
}

// Instead of exporting the viewer directly, we'll export a `Loadable`
// Component that will allow us to load in dependencies and render the editor
// until then.
/* eslint-disable react/prop-types */
export default Loadable.Map({
  loader: {
    CodeMirror: () =>
      import("../Console/codemirror").then(
        (module) =>
          // Add a "zero" delay after the module has loaded, to allow their
          // styles to take effect.
          new Promise((resolve: (result: Promise<never>) => void) => {
            setTimeout(() => resolve(module.default), 0);
          }),
      ),
  },

  loading(props: ReactLoadableLoadingProps) {
    if (props.error) {
      return <div>{props.error}</div>;
    }

    return null;
  },

  render(loaded: LoadedProps, props: Props) {
    return (
      <GraphQLExplorerExampleSection
        CodeMirror={loaded.CodeMirror}
        query={props.query}
        organization={props.organization}
      />
    );
  },
});

import { BaseStep, Type } from ".";
import {
  InputStep as InputStepSchema,
  StringInputStep as StringInputStepSchema,
} from "../schema";

export interface InputStep extends BaseStep {
  type: Type.Input;
  label: string;
}

export function isInputStep(step: any): step is InputStepSchema {
  return step.type === "input" || step.input !== undefined;
}

export function isStringInputStep(step: any): step is StringInputStepSchema {
  return step === "input";
}

export function parseInputStep(config: InputStepSchema): InputStep {
  return {
    uuid: "",
    dependencies: [],
    type: Type.Input,
    label: config.label || config.input || "",
  };
}

export function parseStringInputStep(config: StringInputStepSchema): InputStep {
  return {
    uuid: "",
    dependencies: [],
    type: Type.Input,
    label: "",
  };
}

/**
 * @generated SignedSource<<1d2d0962d8475ff2a57a9ab7d3390c36>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BuildBlockedStates = "FAILED" | "PASSED" | "RUNNING" | "%future added value";
export type BuildStates = "BLOCKED" | "CANCELED" | "CANCELING" | "CREATING" | "FAILED" | "FAILING" | "NOT_RUN" | "PASSED" | "RUNNING" | "SCHEDULED" | "SKIPPED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type Bar_build$data = {
  readonly blockedState: BuildBlockedStates | null;
  readonly branch: string;
  readonly commit: string;
  readonly message: string | null;
  readonly pipeline: {
    readonly commitShortLength: number;
    readonly defaultBranch: string | null;
  };
  readonly state: BuildStates;
  readonly " $fragmentSpreads": FragmentRefs<"BuildTooltip_build">;
  readonly " $fragmentType": "Bar_build";
};
export type Bar_build$key = {
  readonly " $data"?: Bar_build$data;
  readonly " $fragmentSpreads": FragmentRefs<"Bar_build">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Bar_build",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "branch",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "commit",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "blockedState",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Pipeline",
      "kind": "LinkedField",
      "name": "pipeline",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "commitShortLength",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "defaultBranch",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BuildTooltip_build"
    }
  ],
  "type": "Build",
  "abstractKey": null
};

(node as any).hash = "a88c80e2bd3db13876ca477940b189d9";

export default node;

type Framework = {
  name: string;
  language: string;
  emoji: string;
  label: string;
};

const frameworks: Framework[] = [
  {
    name: "rspec",
    language: "Ruby",
    emoji: ":rspec:",
    label: "RSpec",
  },
  {
    name: "minitest",
    language: "Ruby",
    emoji: ":ruby:",
    label: "Minitest",
  },
  {
    name: "jest",
    language: "JavaScript",
    emoji: ":jest:",
    label: "Jest",
  },
  {
    name: "jasmine",
    language: "JavaScript",
    emoji: ":jasmine:",
    label: "Jasmine",
  },
  {
    name: "mocha",
    language: "JavaScript",
    emoji: ":mocha:",
    label: "Mocha",
  },
  {
    name: "cypress",
    language: "JavaScript",
    emoji: ":cypress:",
    label: "Cypress",
  },
  {
    name: "playwright",
    language: "JavaScript",
    emoji: ":playwright:",
    label: "Playwright",
  },
  {
    name: "pytest",
    language: "Python",
    emoji: ":python:",
    label: "Pytest",
  },
  {
    name: "swift",
    language: "Swift",
    emoji: ":swift:",
    label: "XCTest",
  },
  {
    name: "rust",
    language: "Rust",
    emoji: ":rust:",
    label: "Rust",
  },
  {
    name: "elixir",
    language: "Elixir",
    emoji: ":elixir:",
    label: "ExUnit",
  },
  {
    name: "junitJava",
    language: "Java",
    emoji: ":java:",
    label: "JUnit",
  },
];

export default frameworks;

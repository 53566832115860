/**
 * Ported from https://github.com/facebook/fbjs/blob/v0.8.17/src/performance/performanceNow.js
 *
 * Uses the same license as react-relay.
 */

const performance =
  window.performance || window.msPerformance || window.webkitPerformance;

const performanceNow =
  performance && performance.now ? () => performance.now() : () => Date.now();

export default performanceNow;

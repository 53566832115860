import { createFragmentContainer, graphql } from "react-relay";

import AuditLogEventSection from "./eventSection";
import AuditLogSubjectSection from "./subjectSection";
import AuditLogActorSection from "./actorSection";
import AuditLogContextSection from "./contextSection";

type Props = {
  auditEvent: any;
  hasExpanded: boolean;
};

const AuditLogDrawer = ({ auditEvent, hasExpanded }: Props) => (
  <div
    className="px3 pt3 pb2"
    style={{
      columnWidth: "20em",
    }}
  >
    <AuditLogEventSection auditEvent={auditEvent} hasExpanded={hasExpanded} />
    <AuditLogSubjectSection auditEvent={auditEvent} />
    <AuditLogActorSection auditEvent={auditEvent} />
    <AuditLogContextSection auditEvent={auditEvent} />
  </div>
);

export default createFragmentContainer(AuditLogDrawer, {
  auditEvent: graphql`
    fragment Drawer_auditEvent on AuditEvent {
      ...eventSection_auditEvent
      ...subjectSection_auditEvent
      ...actorSection_auditEvent
      ...contextSection_auditEvent
    }
  `,
});

import * as React from "react";
import classNames from "classnames";
import FormInputHelp from "./FormInputHelp";
import FormInputErrors from "./FormInputErrors";
import Badge from "app/components/shared/Badge";

type Value = string;
type Option = {
  className?: string;
  label: React.ReactNode;
  help?: React.ReactNode;
  value: Value;
  badge?: string;
};
type Props = {
  options: Array<Option>;
  value?: Value | Array<Value>;
  label?: React.ReactNode;
  name?: string;
  help?: React.ReactNode;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  onChange?: any;
  errors?: Array<React.ReactNode>;
};

export default class FormCheckboxGroup extends React.Component<Props> {
  render() {
    return (
      <div className={classNames("mb2", this.props.className)}>
        {this._renderLabel()}
        {this.props.help && <FormInputHelp>{this.props.help}</FormInputHelp>}
        {this._renderInputs()}
        <FormInputErrors errors={this.props.errors} />
      </div>
    );
  }

  _hasErrors() {
    return this.props.errors && this.props.errors.length > 0;
  }

  _renderLabel() {
    if (this.props.label) {
      return (
        <label className={classNames("block bold", { red: this._hasErrors() })}>
          {this.props.label}
          {this.props.required && (
            <span className="dark-gray h6 semi-bold"> — Required</span>
          )}
        </label>
      );
    }
  }

  isValueSelected(value: Value) {
    return Array.isArray(this.props.value)
      ? this.props.value.indexOf(value) !== -1
      : this.props.value === value;
  }

  _renderInputs(): Array<React.ReactElement<any>> {
    // If we use onChange event handlers, check boxes need to be "managed" inputs.
    // `onChange` never fires for subsequent changes if you use `defaultChecked`.
    return this.props.options.map((option, index) => (
      <div key={index} className="mt1">
        <label className="mt1 inline-block pl4">
          <div className="flex">
            <input
              className={classNames(
                "absolute checkbox",
                { "is-error": this._hasErrors() },
                option.className,
              )}
              style={{ marginLeft: "-20px" }}
              name={this.props.name}
              type="checkbox"
              checked={
                this.props.onChange
                  ? this.isValueSelected(option.value)
                  : undefined
              }
              defaultChecked={
                this.props.onChange
                  ? undefined
                  : this.isValueSelected(option.value)
              }
              disabled={this.props.disabled}
              value={option.value}
              onChange={this.props.onChange}
            />
            <span className="bold block" style={{ marginBottom: -5 }}>
              {" "}
              {option.label}
            </span>
            {option.badge && <Badge outline={true}>{option.badge}</Badge>}
          </div>
          <FormInputHelp>{option.help}</FormInputHelp>
        </label>
      </div>
    ));
  }
}

import { PureComponent } from "react";
import { createFragmentContainer, graphql } from "react-relay";

import cable from "app/lib/cable";

type Props = {
  pipeline: {
    id: string;
    uuid: string;
    runningBuilds: {
      count: number;
    };
    scheduledBuilds: {
      count: number;
    };
  };
  relay: {
    forceFetch(): void;
  };
};

class BuildsIndicator extends PureComponent<Props> {
  subscription: any;

  componentDidMount() {
    this.subscription = cable.subscriptions.create(
      { channel: "Pipelines::PipelineChannel", uuid: this.props.pipeline.uuid },
      {
        component: this,

        received({ event }) {
          // Uses build counts stored as pipeline stats
          if (event === "stats:changed") {
            this.component.reload();
          }
        },
      },
    );
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  get buildsText() {
    return `${this.props.pipeline.runningBuilds.count} Running, ${this.props.pipeline.scheduledBuilds.count} Scheduled`;
  }

  renderPipForState(state: "running" | "scheduled") {
    return (
      <div
        className={`ml1 inline-block circle border border-white ${
          state === "running" ? "bg-orange" : "bg-dark-gray"
        }`}
        style={{
          width: 10,
          height: 10,
          bottom: 0,
          right: 0,
          borderWidth: 2,
        }}
      />
    );
  }

  maybeRenderPip() {
    if (this.props.pipeline.runningBuilds.count > 0) {
      return this.renderPipForState("running");
    } else if (this.props.pipeline.scheduledBuilds.count > 0) {
      return this.renderPipForState("scheduled");
    }
  }

  render() {
    return (
      <span title={`All Builds: ${this.buildsText}`}>
        All Builds
        {this.maybeRenderPip()}
      </span>
    );
  }

  reload() {
    this.props.relay.forceFetch();
  }

  handleWebsocketEvent = (payload: any) => {
    if (
      payload.subevent === "project:updated" &&
      payload.graphql.id === this.props.pipeline.id
    ) {
      this.reload();
    }
  };

  handleWebsocketConnected = () => {
    this.reload();
  };
}

export default createFragmentContainer(BuildsIndicator, {
  pipeline: graphql`
    fragment BuildsIndicator_pipeline on Pipeline {
      id
      uuid
      scheduledBuilds: builds(state: SCHEDULED) {
        count
      }
      runningBuilds: builds(state: [RUNNING, FAILING]) {
        count
      }
    }
  `,
});

import { HTMLProps } from "react";
import parseEmoji from "app/lib/parseEmoji";

interface Props extends HTMLProps<HTMLSpanElement> {
  text?: string;
}

export default function Emojify({ text, ...props }: Props) {
  const html = parseEmoji(text || "");

  if (html !== text) {
    props.dangerouslySetInnerHTML = { __html: html };
  } else {
    props.children = text;
  }

  return (
    <span {...props} title={props.title !== undefined ? props.title : text} />
  );
}

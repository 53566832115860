/**
 * @generated SignedSource<<408b86d7409c052c5402303ff50cf651>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type TeamRegistryDeleteInput = {
  clientMutationId?: string | null;
  force?: boolean | null;
  id: string;
};
export type RegistriesTeamDeleteMutation$variables = {
  input: TeamRegistryDeleteInput;
};
export type RegistriesTeamDeleteMutation$data = {
  readonly teamRegistryDelete: {
    readonly deletedTeamRegistryID: string;
    readonly team: {
      readonly id: string;
      readonly registries: {
        readonly count: number;
      } | null;
    } | null;
  } | null;
};
export type RegistriesTeamDeleteMutation = {
  response: RegistriesTeamDeleteMutation$data;
  variables: RegistriesTeamDeleteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "TeamRegistryDeletePayload",
    "kind": "LinkedField",
    "name": "teamRegistryDelete",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deletedTeamRegistryID",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Team",
        "kind": "LinkedField",
        "name": "team",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TeamRegistryConnection",
            "kind": "LinkedField",
            "name": "registries",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "count",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RegistriesTeamDeleteMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RegistriesTeamDeleteMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4f9948d2d0841499160ad8bc53d7347b",
    "id": null,
    "metadata": {},
    "name": "RegistriesTeamDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation RegistriesTeamDeleteMutation(\n  $input: TeamRegistryDeleteInput!\n) {\n  teamRegistryDelete(input: $input) {\n    deletedTeamRegistryID\n    team {\n      id\n      registries {\n        count\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b09247e7090f709d800fc9e8e5b21bf6";

export default node;

import Dropdown from "app/components/shared/Dropdown";
import Icon from "app/components/shared/Icon";
import TestPlan from "./TestPlan";
import { TestPlanProps } from "../type";

type TestPlanDropdownProps = {
  testPlans: TestPlanProps[];
};

const TestPlansDropdown = ({ testPlans }: TestPlanDropdownProps) => (
  <Dropdown className="!overflow-visible">
    <button className="flex gap-2 items-center hover:underline">
      <Icon
        icon="heroicons/outline/bars-3-bottom-left"
        style={{ height: 14, width: 14 }}
      />
      {`Test Plan${testPlans.length > 1 ? "s" : ""}`} ({testPlans.length})
    </button>
    <ul>
      {testPlans.map((testPlan) => (
        <TestPlan
          key={testPlan.id}
          runName={testPlan.runName}
          id={testPlan.id}
          orchestrationLink={testPlan.orchestrationLink}
          partitions={{
            count: testPlan.partitions.count,
            min: testPlan.partitions.min,
            max: testPlan.partitions.max,
          }}
        />
      ))}
    </ul>
  </Dropdown>
);

export default TestPlansDropdown;

import { CommandJob } from "app/components/build/Header/pipeline/types/CommandJob";
import { useJobsForStep } from "app/components/Playground/BuildContext";
import {
  CommandStep,
  isDefaultCommandStep,
  isMatrixCommandStep,
} from "app/lib/pipeline";
import { PropsWithChildren } from "react";
import { StepListItem } from "./StepListItem";
import { CollapsibleStepListItem } from "./CollapsibleStepListItem";
import { StepLink } from "./StepLink";
import { JobLabel } from "./JobLabel";
import { filterJobs, useFilterStore } from "../useFilterStore";

/**
 * An command step list item.
 *
 * Rendered as a single list item unless there are multiple jobs for the step.
 */
export function CommandStepListItem({
  step,
}: PropsWithChildren<{ step: CommandStep }>) {
  const stateFilter = useFilterStore((state) => state.filterBy);

  let jobs = useJobsForStep<CommandJob>(step.uuid);
  jobs = filterJobs(jobs, stateFilter);

  // If there is only one job, and it's not a parallel or matrix command step, render a single step list item.
  if (jobs.length <= 1 && isDefaultCommandStep(step)) {
    return <StepListItem step={step} />;
  }

  // If it's a matrix command step, don't render a collapsible list item.
  if (isMatrixCommandStep(step)) {
    return (
      <>
        {jobs.map((job) => (
          <StepLink uuid={job.id} className="pl-2" key={job.id}>
            <JobLabel job={job} />
          </StepLink>
        ))}
      </>
    );
  }

  return (
    <CollapsibleStepListItem step={step}>
      {jobs?.map((job) => (
        <div key={job.id} className="flex flex-auto pl-1.5">
          <StepLink uuid={job.id} className="pl-2">
            <JobLabel job={job} />
          </StepLink>
        </div>
      ))}
    </CollapsibleStepListItem>
  );
}

/* global jQuery */

import * as React from "react";

import Bugsnag from "app/lib/Bugsnag";

import Button from "app/components/shared/Button";
import Dialog from "app/components/shared/Dialog";
import Emojify from "app/components/shared/Emojify";
import FormCheckboxGroup from "app/components/shared/FormCheckboxGroup";
import FormTextarea from "app/components/shared/FormTextarea";
import FormRadioGroup from "app/components/shared/FormRadioGroup";
import FormSelect from "app/components/shared/FormSelect";

type TextField = {
  text: string;
  key: string;
  hint: string | null | undefined;
  required?: boolean;
  format?: string;
  default?: string;
};

type SelectOption = {
  value: string;
  label: React.ReactNode;
};

type UnparsedSelectOption = string | SelectOption;

type SelectField = {
  select: string;
  options: Array<UnparsedSelectOption>;
  key: string;
  hint: string | null | undefined;
  required?: boolean;
  multiple?: boolean;
  default?: string;
};

export type Field = TextField | SelectField;

type Props = {
  buildStore: {
    loadAndEmit: (arg1?: any) => void;
    reload: () => void;
  };
  job: {
    id: string;
    unblockPath: string;
    fields: Array<Field>;
    name: string | null | undefined;
    prompt: string | null | undefined;
    submit: string | null | undefined;
  };
};

type State = {
  isOpen: boolean;
  submitting: boolean;
  errors: {
    [key: string]: string;
  };
};

export default class BlockFieldsModal extends React.Component<Props, State> {
  form: HTMLFormElement | null | undefined;

  state = {
    isOpen: false,
    submitting: false,
    errors: {},
  };

  componentDidMount() {
    // FYI, These are @rails/ujs event handlers. We apply them to `document`
    // because we don't have `this.form` on mount. Each handler must check
    // for the existence of `this.form` before ultimately running!
    document.addEventListener("ajax:before", this.handleAjaxBefore);
    document.addEventListener("ajax:success", this.handleAjaxSuccess);
    document.addEventListener("ajax:error", this.handleAjaxError);
  }

  componentWillUnmount() {
    document.removeEventListener("ajax:before", this.handleAjaxBefore);
    document.removeEventListener("ajax:success", this.handleAjaxSuccess);
    document.removeEventListener("ajax:error", this.handleAjaxError);
  }

  handleAjaxBefore = (evt: Event) => {
    if (!this.form || evt.target !== this.form) {
      return;
    }

    const errors: Record<string, any> = {};
    const broken: Array<JQuery> = [];

    // Make sure all the fields that are required have values
    for (let index = 0; index < this.props.job.fields.length; index++) {
      const field = this.props.job.fields[index];

      if (field.required) {
        // @ts-expect-error - TS2339 - Property 'multiple' does not exist on type 'Field'.
        if (field.multiple) {
          const el = jQuery(`[name^='job[fields][${field.key}]']:checked`);

          if (el.length < 1) {
            errors[field.key] = "This field is required";
            broken.push(jQuery(`[name^='job[fields][${field.key}]']`));
          }
        } else {
          const el = jQuery(`[name='job[fields][${field.key}]']`);

          // Get the value from the DOM
          let val = el?.val()?.toString() || "";

          // Get rid of whitespace for the before we validate
          val = jQuery.trim(val);

          // Add our error if the value is empty
          if (val === "") {
            errors[field.key] = "This field is required";
            broken.push(el);
          }
        }
      }

      // @ts-expect-error - TS2339 - Property 'format' does not exist on type 'Field'.
      if (field.format) {
        // @ts-expect-error - TS2339 - Property 'format' does not exist on type 'Field'.
        const format = field.format;
        const el = jQuery(`[name='job[fields][${field.key}]']`);
        const val = el.val()?.toString() || "";

        if (format && val !== "") {
          const regexp = new RegExp(`^(?:${format})$`);

          if (!regexp.test(val)) {
            errors[field.key] = `This field must match the pattern "${format}"`;
            broken.push(el);
          }
        }
      }
    }

    if (jQuery.isEmptyObject(errors)) {
      return this.setState({ errors: {}, submitting: true });
    }

    // Show the errors
    this.setState({ errors });

    // Focus on the first broken fields
    broken[0].focus();

    // Stop the form from submitting
    evt.preventDefault();
    return false;
  };

  handleAjaxSuccess = (event: Event) => {
    if (!this.form || event.target !== this.form) {
      return;
    }

    this.hide();
    this.setState({ submitting: false });

    // Flow needs to know this is a CustomEvent to have a .detail, but it
    // can't be in the function signature or addEventListener will fail
    if (event instanceof CustomEvent) {
      // https://guides.rubyonrails.org/working_with_javascript_in_rails.html#rails-ujs-event-handlers
      const [response, _status, _xhr] = event.detail;

      if (response && response.build) {
        this.props.buildStore.loadAndEmit(response.build);
      }
    }
  };

  handleAjaxError = (event: Event) => {
    if (!this.form || event.target !== this.form) {
      return;
    }
    // Feels odd to close the form here, but preserving current behaviour.
    this.hide();

    this.setState({ submitting: false });

    // This modal is sometimes in the build header, which has its own ajax
    // error handler which also alerts, but is sometimes in the job summary
    // outside a build header, which doesn't. So only alert if the default
    // hasn't been prevented [which the header does via event.preventDefault()].
    if (!event.defaultPrevented) {
      // Flow needs to know this is a CustomEvent to have a .detail, but it
      // can't be in the function signature or addEventListener will fail
      if (event instanceof CustomEvent) {
        // https://guides.rubyonrails.org/working_with_javascript_in_rails.html#rails-ujs-event-handlers
        const [response, _status, _xhr] = event.detail;

        if (response && response.message) {
          alert(response.message);
        }
      }
    }

    // If there was an error then we might have an outdated idea of the
    // build pipeline, so update our understanding by reloading.
    this.props.buildStore.reload();
  };

  handleCloseClick = () => {
    this.hide();
  };

  show() {
    this.setState({ isOpen: true }, () => {
      if (this.form) {
        // Find the first non-hidden form element, and give it focus
        const firstFormElement = this.form.querySelector(
          'input:not([type="hidden"]),select,textarea',
        );
        // @ts-expect-error - TS2339 - Property 'focus' does not exist on type 'Element'.
        firstFormElement && firstFormElement.focus();
      }
    });
  }

  hide() {
    this.setState({ isOpen: false });
  }

  render() {
    return (
      <Dialog isOpen={this.state.isOpen} onRequestClose={this.handleCloseClick}>
        <form
          action={this.props.job.unblockPath}
          acceptCharset="UTF-8"
          method="post"
          data-remote="true"
          ref={(form) => (this.form = form)}
        >
          <input name="utf8" type="hidden" value="✓" />
          <input
            type="hidden"
            name={window._csrf.param}
            value={window._csrf.token}
          />

          <div className="p4">
            <h1 className="m0 h2 semi-bold mb3">
              <Emojify text={this.props.job.name || "Continue"} />
            </h1>
            {this.props.job.prompt && (
              <p className="whitespace-pre-line break-words">
                <Emojify text={this.props.job.prompt} />
              </p>
            )}

            {this.renderFields()}

            <Button
              type="submit"
              className="col-12"
              disabled={this.state.submitting}
              // @ts-expect-error - TS2322 - Type 'false | "Loading…"' is not assignable to type 'boolean | undefined'.
              loading={this.state.submitting && "Loading…"}
            >
              <Emojify text={this.props.job.submit || "Continue"} />
            </Button>
          </div>
        </form>
      </Dialog>
    );
  }

  renderFields(): Array<React.ReactNode> {
    return this.props.job.fields.map((field, index) => {
      const id = `field-${this.props.job.id}-${index}`;
      const name = `job[fields][${field.key}]`;
      const error = this.state.errors[field.key];
      const help = field.hint && (
        <Emojify
          text={field.hint}
          className="whitespace-pre-line break-words"
        />
      );

      // @ts-expect-error - TS2339 - Property 'text' does not exist on type 'Field'.
      if (field.text) {
        return (
          <FormTextarea.Autosize
            name={name}
            id={id}
            key={id}
            // @ts-expect-error - TS2339 - Property 'text' does not exist on type 'Field'.
            label={<Emojify text={field.text} />}
            help={help}
            errors={error && [error]}
            defaultValue={field.default}
            required={field.required}
            // @ts-expect-error - TS2339 - Property 'format' does not exist on type 'Field'.
            pattern={field.format}
          />
        );
      }

      // @ts-expect-error - TS2339 - Property 'select' does not exist on type 'Field'.
      if (field.select) {
        // @ts-expect-error - TS2339 - Property 'multiple' does not exist on type 'Field'.
        if (field.multiple) {
          return (
            <FormCheckboxGroup
              name={`${name}[]`}
              // @ts-expect-error - TS2769 - No overload matches this call.
              id={id}
              key={id}
              // @ts-expect-error - TS2339 - Property 'select' does not exist on type 'Field'.
              label={<Emojify text={field.select} />}
              help={help}
              errors={error ? [error] : []}
              value={field.default}
              required={field.required}
              // @ts-expect-error - TS2339 - Property 'options' does not exist on type 'Field'.
              options={field.options.map((option) => ({
                ...this.parseOption(option, true),
              }))}
            />
          );
        }

        // required must be `true` in order for radio buttons to be rendered. This is because radio
        // buttons do not allow the user to 'de-select' options once they have been checked. Rendering
        // a dropdown allows 'de-selecting' via the blank option.
        // @ts-expect-error - TS2339 - Property 'options' does not exist on type 'Field'.
        if (field.options.length <= 6 && field.required) {
          return (
            <FormRadioGroup
              name={name}
              id={id}
              key={id}
              // @ts-expect-error - TS2339 - Property 'select' does not exist on type 'Field'.
              label={<Emojify text={field.select} />}
              help={help}
              errors={error && [error]}
              value={field.default}
              required={field.required}
              // @ts-expect-error - TS2339 - Property 'options' does not exist on type 'Field'.
              options={field.options.map((option) => ({
                ...this.parseOption(option, true),
              }))}
            />
          );
        }

        return (
          <FormSelect
            name={name}
            // @ts-expect-error - TS2769 - No overload matches this call.
            id={id}
            key={id}
            // @ts-expect-error - TS2339 - Property 'select' does not exist on type 'Field'.
            label={<Emojify text={field.select} />}
            help={help}
            errors={error ? [error] : []}
            defaultValue={field.default}
            required={field.required}
            // @ts-expect-error - TS2339 - Property 'options' does not exist on type 'Field'.
            options={field.options.map((option) => ({
              ...this.parseOption(option, false),
            }))}
          />
        );
      }

      Bugsnag.notify(
        new TypeError(
          `Unrecognised block field with keys: ${Object.keys(field).join(
            ", ",
          )}`,
        ),
      );

      return null;
    });
  }

  parseOption(
    option: UnparsedSelectOption | null | undefined,
    emojify: boolean,
  ): SelectOption {
    if (!option) {
      return { value: "", label: "" };
    }

    if (typeof option === "object") {
      return {
        ...option,
        // @ts-expect-error - TS2322 - Type 'ReactNode' is not assignable to type 'string | undefined'.
        label: emojify ? <Emojify text={option.label} /> : option.label,
      };
    }

    return {
      value: option,
      label: emojify ? <Emojify text={option} /> : option,
    };
  }
}

import * as React from "react";

import UserSessionStore, {
  UserSessionEvent,
} from "app/stores/UserSessionStore";
import JobLogStore, { Job, JobPartial } from "app/stores/JobLogStore";

import LegacyLoading from "app/components/shared/LegacyLoading";

import JobLogOutputGroup from "./Group";
import {
  Log,
  LogBody,
  LogContent,
  LogHeader,
  LogLoading,
} from "app/components/shared/Log";

type Props = {
  onChange: (changed: Job) => unknown;
  job: JobPartial;
  timestampsOn: boolean | null | undefined;
  children: React.ReactNode;
  theme: "default" | "solarized";
};

type State = {
  log: Job;
  timestampsInUTC?: boolean | null | undefined;
};

const getStateFromStore = (job: JobPartial) => ({ log: JobLogStore.load(job) });

const USER_SESSION_STORE_TIMESTAMP_UTC_KEY = "timestamps-utc";

/* eslint-disable react/require-optimization */
// TODO: Fix the architecture of these components so this one can be optimised.
// The problem is the `log` object is actually passed from the store, which is always
// the same object reference, even if mutated, so ShallowCompare functions do not work.

export default class JobLogOutput extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      ...getStateFromStore(props.job),
      timestampsInUTC:
        UserSessionStore.get(USER_SESSION_STORE_TIMESTAMP_UTC_KEY) === "true",
    };
  }

  componentDidMount() {
    JobLogStore.refresh(this.state.log.id);
    JobLogStore.addChangeListener(this.state.log.id, this.handleStoreChange);
    UserSessionStore.on("change", this.handleSessionDataChange);

    // We emit an `onChange` event here because otherwise things upstream that
    // rely on the store state this component emits would never get that state
    // information from jobs which are not in the process of changing.
    this.emitChangeEvent();
  }

  componentWillUnmount() {
    JobLogStore.removeChangeListener(this.state.log.id, this.handleStoreChange);
    UserSessionStore.off("change", this.handleSessionDataChange);
  }

  handleStoreChange = () => {
    this.setState(getStateFromStore(this.props.job), () => {
      this.emitChangeEvent();
    });
  };

  emitChangeEvent() {
    this.props.onChange(this.state.log);
  }

  handleSessionDataChange = ({ key, newValue }: UserSessionEvent) => {
    if (key === USER_SESSION_STORE_TIMESTAMP_UTC_KEY) {
      this.setState({ timestampsInUTC: newValue === "true" });
    }
  };

  handleTimestampClick = () => {
    const timestampsInUTC = !this.state.timestampsInUTC;
    this.setState({ timestampsInUTC: timestampsInUTC });

    // Store it so next time we default to that choice
    UserSessionStore.set(
      USER_SESSION_STORE_TIMESTAMP_UTC_KEY,
      timestampsInUTC.toString(),
    );
  };

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    // TODO: This is a total hack, and probably shouldnt' be done here. If the
    // state of the job has changed, then reload the job log.
    if (this.props.job.state !== nextProps.job.state) {
      JobLogStore.refresh(this.state.log.id);
    }
  }

  render() {
    const { log } = this.state;

    const contentNode = log.loaded ? (
      <LogContent totalLines={log.totalLines}>
        {log.groups.map((group) => (
          <JobLogOutputGroup
            timestampsOn={this.props.timestampsOn}
            timestampsInUTC={this.state.timestampsInUTC}
            onTimestampClick={this.handleTimestampClick}
            key={group.id}
            {...group}
          />
        ))}
      </LogContent>
    ) : !this.props.job.startedAt ? (
      <LogLoading />
    ) : (
      <LogLoading>
        <LegacyLoading />
      </LogLoading>
    );

    return (
      <Log theme={this.props.theme}>
        <LogHeader className="top-[109px] group-[.drawer]:top-[157px]">
          {this.props.children}
        </LogHeader>
        <LogBody>{contentNode}</LogBody>
      </Log>
    );
  }
}

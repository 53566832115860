/**
 * @generated SignedSource<<1dd5337ec254e6b6a82f955c0838035e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type JobStates = "ACCEPTED" | "ASSIGNED" | "BLOCKED" | "BLOCKED_FAILED" | "BROKEN" | "CANCELED" | "CANCELING" | "EXPIRED" | "FINISHED" | "LIMITED" | "LIMITING" | "PENDING" | "RUNNING" | "SCHEDULED" | "SKIPPED" | "TIMED_OUT" | "TIMING_OUT" | "UNBLOCKED" | "UNBLOCKED_FAILED" | "WAITING" | "WAITING_FAILED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type Row_job$data = {
  readonly agentQueryRules?: ReadonlyArray<string> | null;
  readonly concurrency?: {
    readonly group: string;
    readonly limit: number;
  } | null;
  readonly createdAt?: any | null;
  readonly id?: string;
  readonly passed?: boolean;
  readonly priority?: {
    readonly number: number | null;
  };
  readonly state?: JobStates;
  readonly " $fragmentSpreads": FragmentRefs<"JobLink_job">;
  readonly " $fragmentType": "Row_job";
};
export type Row_job$key = {
  readonly " $data"?: Row_job$data;
  readonly " $fragmentSpreads": FragmentRefs<"Row_job">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Row_job",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "state",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "JobPriority",
          "kind": "LinkedField",
          "name": "priority",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "number",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "agentQueryRules",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "passed",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "JobConcurrency",
          "kind": "LinkedField",
          "name": "concurrency",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "group",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "limit",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createdAt",
          "storageKey": null
        }
      ],
      "type": "JobTypeCommand",
      "abstractKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "JobLink_job"
    }
  ],
  "type": "Job",
  "abstractKey": "__isJob"
};

(node as any).hash = "e059f02d35d55e9902c5cbfa7212a64d";

export default node;

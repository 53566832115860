import CodeBlock from "app/components/docs/shared/code-block";
import Link from "app/components/docs/shared/link";

import "highlight.js/styles/atom-one-light.css";

type Props = {
  token?: string;
  className?: string;
  style?: any;
};

UbuntuQuickStart.title = "Ubuntu";

function UbuntuQuickStart(props: Props) {
  const { className, style } = props;

  return (
    <div className={className} style={style}>
      <p>First, add our signed apt repository:</p>
      <CodeBlock language="bash">
        sudo sh -c{" "}
        <span className="hljs-string">
          &apos;echo deb https://apt.buildkite.com/buildkite-agent stable main
          &gt; /etc/apt/sources.list.d/buildkite-agent.list&apos;
        </span>
        <br />
        {/*
         */}
        sudo apt-key adv --keyserver hkp://keyserver.ubuntu.com:80 --recv-keys
        32A37959C2FA5C3C99EFBC32A79206696452D198
        <br />
      </CodeBlock>

      <p>Then install the agent:</p>
      <CodeBlock language="bash">
        sudo apt-get update &amp;&amp; sudo apt-get install -y buildkite-agent
        <br />
      </CodeBlock>

      <p>Then configure your agent token:</p>
      <CodeBlock language="bash">
        sudo sed -i{" "}
        <span className="hljs-string">
          &quot;s/xxx/{props.token || "INSERT-YOUR-AGENT-TOKEN-HERE"}/g&quot;
        </span>{" "}
        /etc/buildkite-agent/buildkite-agent.cfg
        <br />
      </CodeBlock>

      <p>And then start the agent:</p>
      <CodeBlock language="bash">
        <span className="hljs-comment"># Ubuntu 15.04 and above (systemd)</span>
        <br />
        {/*
         */}
        sudo systemctl <span className="hljs-built_in">enable</span>{" "}
        buildkite-agent &amp;&amp; sudo systemctl start buildkite-agent
        <br />
        <br />
        <span className="hljs-comment"># Older Ubuntu (upstart)</span>
        <br />
        {/*
         */}
        sudo service buildkite-agent start
        <br />
      </CodeBlock>

      <p>You can view the logs at:</p>
      <CodeBlock language="bash">
        <span className="hljs-comment"># Ubuntu 15.04 and above (systemd)</span>
        <br />
        {/*
         */}
        journalctl -f -u buildkite-agent
        <br />
        <br />
        <span className="hljs-comment"># Older Ubuntu (upstart)</span>
        <br />
        {/*
         */}
        tail -f /var/<span className="hljs-built_in">log</span>
        /upstart/buildkite-agent.log
        <br />
      </CodeBlock>

      <p className="rounded bg-silver p2">
        See the <Link href="/docs/agent/ubuntu">Ubuntu agent docs</Link> for
        more details. Supported Ubuntu versions: 14.04 and above.
      </p>
    </div>
  );
}

export default UbuntuQuickStart;

import EventEmitter from "eventemitter3";
import Logger from "app/lib/Logger";

import BaseChannel from "app/channels/BaseChannel";
import PingChannel from "app/channels/PingChannel";
import LegacyUserChannel from "app/channels/LegacyUserChannel";
import LegacyPublicAccountChannel from "app/channels/LegacyPublicAccountChannel";
import LegacyPrivateAccountChannel from "app/channels/LegacyPrivateAccountChannel";

const channels = {
  PingChannel,
  LegacyUserChannel,
  LegacyPublicAccountChannel,
  LegacyPrivateAccountChannel,
} as const;

class CableStore extends EventEmitter {
  online = false;
  reconnectionTimeout: number | null | undefined;

  constructor() {
    super();
  }

  listen(channelInfo: { channel: keyof typeof channels; [key: string]: any }) {
    const channelName = channelInfo.channel;
    Logger.info(`[CableStore] Listening to channel '${channelName}'`);

    const channel: typeof BaseChannel = channels[channelName];

    const options = Object.assign({}, channelInfo);
    // @ts-expect-error - TS2790 - The operand of a 'delete' operator must be optional.
    delete options.channel;

    channel.subscribe(options, {
      connected: () => {
        Logger.info(`[${channelName}]`, "Connected");

        if (this.reconnectionTimeout) {
          clearTimeout(this.reconnectionTimeout);
          this.reconnectionTimeout = null;

          if (this.online) {
            // Note that this event is effectively the opposite of
            // "online": it fires only if we had been disconnected, but
            // have now re-established our connection, all within the
            // timeout -- so no offline/online events have fired.

            this.emit("reconnected");
          }
        }

        if (!this.online) {
          this.online = true;
          this.emit("online");
        }

        this.emit("connected");
      },
      disconnected: (data) => {
        Logger.info(`[${channelName}]`, "Disconnected");
        this.emit("disconnected", data);

        if (data.willAttemptReconnect && this.online) {
          // Unexpected disconnection, while we believe we're online

          if (this.reconnectionTimeout) {
            // We're already in the process of timing out
            return;
          }

          // For our UI "hey you're offline" notification, we want an
          // event that'll only fire when the connection's clearly MIA,
          // and not just during a connectivity blip.
          //
          // We achieve that by waiting 20s (~4 reconnect attempts) and
          // only treating it as "really" disconnected if it hasn't come
          // back in that time.
          this.reconnectionTimeout = setTimeout(() => {
            this.reconnectionTimeout = null;
            this.online = false;
            this.emit("offline");
          }, 20000);
        }
      },
      rejected: () => {
        Logger.info(`[${channelName}]`, "Rejected");
        this.emit("rejected");
      },
      received: (data: any) => {
        Logger.info(`[${channelName}]`, data.event, data);
        this.emit(data.event, data);
      },
    });
  }
}

export default new CableStore();

import React, { useState } from "react";
import styled from "styled-components";
import Chart from "../Chart";
import DateFilters, {
  type DateFilter,
} from "app/components/analytics/shared/DateFilters";
import Colors from "app/constants/analytics/Colors";
import Emojify from "app/components/shared/Emojify";
import set from "lodash/set";
import { getMicrosecondDurationFormattedString } from "app/lib/date";
import {
  EnvironmentVariables,
  Metrics,
} from "app/components/analytics/shared/type";

export type InteractiveChartProps = Metrics & {
  heading?: string;
  filter: DateFilter;
  branch: EnvironmentVariables["branch"];
  endpoint: string;
};

const TIMED_METRICS = [
  "p50_duration",
  "p95_duration",
  "duration",
  "total_duration",
  "average_execution_duration",
  "speed",
];

const UTCNote = styled.div`
  color: ${Colors.BASE_GRAY};
  margin-top: 30px;
`;

const formatReliability = (reliability) => {
  if (reliability === undefined) {
    return;
  }

  return `${Number.parseFloat(reliability).toFixed(2)}%`;
};

export const chartOptions = (props) => {
  const options = {};

  const formatTooltip = (value, format) => {
    if (value.parsed.y == null) {
      return "No data available for this day";
    }
    return format;
  };

  if (TIMED_METRICS.includes(props.metric)) {
    set(options, "options.scales.yIndex.ticks", {
      callback: (value) =>
        getMicrosecondDurationFormattedString(value * 1_000_000),
    });
    set(options, "options.plugins.tooltip.callbacks", {
      label: (value) =>
        formatTooltip(
          value,
          getMicrosecondDurationFormattedString(value.parsed.y * 1_000_000),
        ),
    });
  } else if (props.metric === "reliability") {
    set(options, "options.scales.yIndex.ticks", {
      callback: (value) => `${value}%`,
    });
    set(options, "options.plugins.tooltip.callbacks", {
      label: (value) => formatTooltip(value, formatReliability(value.parsed.y)),
    });
  } else {
    set(options, "options.plugins.tooltip.callbacks", {
      label: (value) =>
        formatTooltip(value, `${value.dataset.label}: ${value.parsed.y}`),
    });
  }

  return options;
};

const InteractiveChart = (props: InteractiveChartProps) => {
  const [filter, setFilter] = useState<DateFilter>(props.filter);

  return (
    <>
      {props.heading && (
        <h2 className="text-lg">
          <Emojify text={props.heading} />
        </h2>
      )}
      <DateFilters
        className="mb4"
        subHeading={props.label}
        filter={filter}
        setFilter={setFilter}
        tooltip={
          props.description
            ? {
                name: `${props.label} calculation`,
                description: props.description,
              }
            : undefined
        }
      />
      <Chart
        height="25vh"
        filter={filter}
        branch={props.branch}
        label={props.label}
        metric={props.metric}
        endpoint={props.endpoint}
        options={chartOptions(props)}
        chartType={props.chartType}
      />
      <UTCNote>Note: Dates in UTC</UTCNote>
    </>
  );
};

export default InteractiveChart;

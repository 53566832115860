/**
 * @generated SignedSource<<eb9be37d1592ebe0ff2f67d90bc17bc3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AnnotationOrder = "PRIORITY_RECENTLY_CREATED" | "RECENTLY_CREATED" | "%future added value";
export type AnnotationStyle = "DEFAULT" | "ERROR" | "INFO" | "SUCCESS" | "WARNING" | "%future added value";
export type AnnotationsList_buildQuery$variables = {
  order?: AnnotationOrder | null;
  pageSize: number;
  slug: string;
  style?: ReadonlyArray<AnnotationStyle> | null;
};
export type AnnotationsList_buildQuery$data = {
  readonly build: {
    readonly " $fragmentSpreads": FragmentRefs<"AnnotationsList_build">;
  } | null;
};
export type AnnotationsList_buildQuery = {
  response: AnnotationsList_buildQuery$data;
  variables: AnnotationsList_buildQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "order"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pageSize"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "slug"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "style"
},
v4 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v5 = {
  "kind": "Variable",
  "name": "order",
  "variableName": "order"
},
v6 = {
  "kind": "Variable",
  "name": "style",
  "variableName": "style"
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AnnotationsList_buildQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Build",
        "kind": "LinkedField",
        "name": "build",
        "plural": false,
        "selections": [
          {
            "args": [
              (v5/*: any*/),
              {
                "kind": "Variable",
                "name": "pageSize",
                "variableName": "pageSize"
              },
              (v6/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "AnnotationsList_build"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AnnotationsList_buildQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Build",
        "kind": "LinkedField",
        "name": "build",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "uuid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "first",
                "variableName": "pageSize"
              },
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "concreteType": "AnnotationConnection",
            "kind": "LinkedField",
            "name": "annotations",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AnnotationEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Annotation",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "context",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "style",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "createdAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "priority",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AnnotationBody",
                        "kind": "LinkedField",
                        "name": "body",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "html",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "Connection",
                "abstractKey": "__isConnection"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d8e55a2dac231339252646d6580eee90",
    "id": null,
    "metadata": {},
    "name": "AnnotationsList_buildQuery",
    "operationKind": "query",
    "text": "query AnnotationsList_buildQuery(\n  $slug: ID!\n  $pageSize: Int!\n  $style: [AnnotationStyle!]\n  $order: AnnotationOrder\n) {\n  build(slug: $slug) {\n    ...AnnotationsList_build_1ooyJv\n    id\n  }\n}\n\nfragment AnnotationsList_build_1ooyJv on Build {\n  id\n  uuid\n  annotations(first: $pageSize, style: $style, order: $order) {\n    edges {\n      node {\n        id\n        context\n        style\n        createdAt\n        priority\n        body {\n          html\n        }\n      }\n      cursor\n    }\n    ...ShowMoreFooter_connection\n  }\n}\n\nfragment ShowMoreFooter_connection on Connection {\n  __isConnection: __typename\n  pageInfo {\n    hasNextPage\n  }\n}\n"
  }
};
})();

(node as any).hash = "9be0569a25c410b0eb2962edc3debd51";

export default node;

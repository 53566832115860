import { PureComponent } from "react";
import { createFragmentContainer, graphql } from "react-relay";
import moment from "moment";
import classNames from "classnames";
import { buildTime } from "app/lib/builds";
import BuildStates from "app/constants/BuildStates";
import Bar from "./Bar";
import {
  MAXIMUM_NUMBER_OF_BUILDS,
  BAR_WIDTH_WITH_SEPARATOR,
  GRAPH_HEIGHT,
  GRAPH_WIDTH,
} from "./constants";

type Props = {
  pipeline: any;
};

type State = {
  showFullGraph: boolean;
};

class Graph extends PureComponent<Props, State> {
  // @ts-expect-error - TS2564 - Property '_shifting' has no initializer and is not definitely assigned in the constructor.
  _shifting: boolean;
  // @ts-expect-error - TS2564 - Property '_interval' has no initializer and is not definitely assigned in the constructor.
  _interval: number;
  // @ts-expect-error - TS2564 - Property '_timeout' has no initializer and is not definitely assigned in the constructor.
  _timeout: number;

  state = {
    showFullGraph: false,
  };

  _buildsEdges() {
    return this.props.pipeline.builds?.edges || [];
  }

  componentDidMount() {
    const buildsEdges = this._buildsEdges();
    if (buildsEdges.length) {
      this.toggleRenderInterval(buildsEdges);
    }

    // As soon as the graph has mounted, animate the bars growing into view.
    this._timeout = setTimeout(() => {
      this.setState({ showFullGraph: true });
    }, 0);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const { builds } = this.props.pipeline;
    const { builds: nextBuilds } = nextProps.pipeline;

    if (builds && builds.edges && nextBuilds && nextBuilds.edges) {
      const build = builds.edges[0];
      const nextBuild = nextBuilds.edges[0];

      // Set `shifting` if all the builds are being moved due to a new one coming in.
      if (
        build &&
        build.node &&
        nextBuild &&
        nextBuild.node &&
        build.node.id !== nextBuild.node.id
      ) {
        this._shifting = true;
      }
    }
  }

  componentWillUnmount() {
    if (this._timeout) {
      clearTimeout(this._timeout);
      // @ts-expect-error - TS2790 - The operand of a 'delete' operator must be optional.
      delete this._timeout;
    }
    if (this._interval) {
      clearInterval(this._interval);
      // @ts-expect-error - TS2790 - The operand of a 'delete' operator must be optional.
      delete this._interval;
    }
  }

  componentDidUpdate() {
    if (this.props.pipeline.builds && this.props.pipeline.builds.edges) {
      this.toggleRenderInterval(this.props.pipeline.builds.edges);
    }

    // @ts-expect-error - TS2790 - The operand of a 'delete' operator must be optional.
    delete this._shifting;
  }

  render() {
    const classes = classNames("relative", {
      "animation-disable": this._shifting,
    });

    return (
      <div>
        <div
          data-testid="graph"
          style={{ width: GRAPH_WIDTH, height: GRAPH_HEIGHT }}
          className={classes}
        >
          {this.renderBars()}
        </div>
      </div>
    );
  }

  renderBars() {
    // `maximumDuration` is wrapped in an object so it's passed by
    // reference, which means all bars get the final, correct value
    // despite the generating loop only occuring once
    const graphProps = {
      maximumDuration: 1, // starts as 1 to avoid a `0/0` when we calculate percentages
    } as const;

    return this._buildsEdges()
      .slice(0, MAXIMUM_NUMBER_OF_BUILDS)
      .reverse()
      .map((buildEdge, index) => {
        if (buildEdge && buildEdge.node) {
          const { node } = buildEdge;
          const { from, to } = buildTime(node);
          const duration = moment(to).diff(moment(from));

          if (duration > graphProps.maximumDuration) {
            // @ts-expect-error - TS2540 - Cannot assign to 'maximumDuration' because it is a read-only property.
            graphProps.maximumDuration = duration;
          }

          return (
            <Bar
              key={node.id}
              duration={duration}
              href={node.url}
              build={node}
              left={index * BAR_WIDTH_WITH_SEPARATOR}
              width={BAR_WIDTH_WITH_SEPARATOR}
              graph={graphProps}
              showFullGraph={this.state.showFullGraph}
            />
          );
        }
      });
  }

  toggleRenderInterval(buildEdges: any) {
    // See if there is a build running
    let running = false;
    for (const edge of buildEdges) {
      if (edge && edge.node && edge.node.state === BuildStates.RUNNING) {
        running = true;
        break;
      }
    }

    // If a build is running, ensure we have an interval setup that re-renders
    // the graph every second.
    if (running) {
      if (this._interval) {
        // no-op, interval already running
      } else {
        this._interval = setInterval(() => {
          this.forceUpdate();
        }, 1000);
      }
    } else {
      // Clear the interval now that nothing is running
      if (this._interval) {
        clearInterval(this._interval);
        // @ts-expect-error - TS2790 - The operand of a 'delete' operator must be optional.
        delete this._interval;
      }
    }
  }
}

export default createFragmentContainer(Graph, {
  pipeline: graphql`
    fragment Graph_pipeline on Pipeline
    @argumentDefinitions(includeGraphData: { type: "Boolean!" }) {
      builds(
        first: 30
        branch: "%default"
        state: [
          SCHEDULED
          RUNNING
          PASSED
          FAILED
          FAILING
          CANCELED
          CANCELING
          BLOCKED
        ]
      ) @include(if: $includeGraphData) {
        edges {
          node {
            id
            state
            url
            startedAt
            finishedAt
            canceledAt
            scheduledAt
            ...Bar_build
          }
        }
      }
    }
  `,
});

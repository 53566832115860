/**
 * @generated SignedSource<<908c126085f9803219c8c7ea616209b8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AgentJobsListRefetchQuery$variables = {
  agentId: string;
  pageSize: number;
};
export type AgentJobsListRefetchQuery$data = {
  readonly agent: {
    readonly " $fragmentSpreads": FragmentRefs<"AgentJobsList_agent">;
  } | null;
};
export type AgentJobsListRefetchQuery = {
  response: AgentJobsListRefetchQuery$data;
  variables: AgentJobsListRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "agentId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pageSize"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "agentId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AgentJobsListRefetchQuery",
    "selections": [
      {
        "alias": "agent",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "pageSize",
                "variableName": "pageSize"
              }
            ],
            "kind": "FragmentSpread",
            "name": "AgentJobsList_agent"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AgentJobsListRefetchQuery",
    "selections": [
      {
        "alias": "agent",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "slug",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "first",
                    "variableName": "pageSize"
                  }
                ],
                "concreteType": "JobConnection",
                "kind": "LinkedField",
                "name": "jobs",
                "plural": false,
                "selections": [
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasNextPage",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "type": "Connection",
                    "abstractKey": "__isConnection"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "JobEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v4/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "state",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "passed",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "label",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "command",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Build",
                                "kind": "LinkedField",
                                "name": "build",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "number",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Pipeline",
                                    "kind": "LinkedField",
                                    "name": "pipeline",
                                    "plural": false,
                                    "selections": [
                                      (v5/*: any*/),
                                      (v3/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v3/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "scheduledAt",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "startedAt",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "url",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "softFailed",
                                "storageKey": null
                              }
                            ],
                            "type": "JobTypeCommand",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v3/*: any*/)
                            ],
                            "type": "Node",
                            "abstractKey": "__isNode"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "count",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Agent",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0b1055b18ee441cd46e71fca872ad553",
    "id": null,
    "metadata": {},
    "name": "AgentJobsListRefetchQuery",
    "operationKind": "query",
    "text": "query AgentJobsListRefetchQuery(\n  $agentId: ID!\n  $pageSize: Int!\n) {\n  agent: node(id: $agentId) {\n    __typename\n    ...AgentJobsList_agent_RlXb8\n    id\n  }\n}\n\nfragment AgentJobRow_job on JobTypeCommand {\n  ...JobTitle_job\n  uuid\n  state\n  passed\n  scheduledAt\n  startedAt\n  url\n  softFailed\n}\n\nfragment AgentJobsList_agent_RlXb8 on Agent {\n  id\n  uuid\n  name\n  organization {\n    name\n    slug\n    id\n  }\n  jobs(first: $pageSize) {\n    ...ShowMoreFooter_connection\n    edges {\n      node {\n        __typename\n        ... on JobTypeCommand {\n          uuid\n          state\n          passed\n        }\n        ...AgentJobRow_job\n        ... on Node {\n          __isNode: __typename\n          id\n        }\n      }\n    }\n    count\n  }\n}\n\nfragment JobTitle_job on JobTypeCommand {\n  label\n  command\n  build {\n    number\n    pipeline {\n      name\n      id\n    }\n    id\n  }\n}\n\nfragment ShowMoreFooter_connection on Connection {\n  __isConnection: __typename\n  pageInfo {\n    hasNextPage\n  }\n}\n"
  }
};
})();

(node as any).hash = "7b344296d7be020c51ebe12b93254cff";

export default node;

import { createFragmentContainer, graphql } from "react-relay";
import AgentBadge from "app/components/AgentBadge";
import JobEvent from "../JobEvent";

type Props = {
  event: any;
  previousTimestamp: string | null | undefined;
};

function JobEventStarted({ event, previousTimestamp }: Props) {
  const {
    job: { agent },
    timestamp,
  } = event;

  return (
    <JobEvent timestamp={timestamp} previousTimestamp={previousTimestamp}>
      <JobEvent.Label>
        {agent ? <AgentBadge agent={agent} /> : "Agent"} Started Running Job
      </JobEvent.Label>
    </JobEvent>
  );
}

export default createFragmentContainer(JobEventStarted, {
  event: graphql`
    fragment JobEventStarted_event on JobEvent {
      timestamp
      job {
        agent {
          ...AgentBadge_agent
        }
      }
    }
  `,
});

import { PureComponent } from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";

import BuildDuration from "app/components/build/Duration";
import BuildMessage from "app/components/build/Message";
import PipelineStateIcon, {
  VARIANTS,
} from "app/components/shared/PipelineStateIcon";

import Pipeline from "./pipeline";
import BuildNavigator from "./BuildNavigator";
import { Branch } from "./Branch";
import { Commit } from "./Commit";
import { Avatar } from "./Avatar";
import { AuthorAndTime } from "./AuthorAndTime";
import { MetaInformation } from "./MetaInformation";
import { CancelButton } from "./CancelButton";
import RetryFailedJobsButton from "./RetryFailedJobsButton";
import { RebuildButton } from "./RebuildButton";
import { BuildView } from "../Show";
import BuildsStore from "app/stores/BuildsStore";
import BuildShowStore, { Build } from "app/stores/BuildShowStore";
import { PullRequest } from "./PullRequest";

interface BuildHeaderProps {
  currentView?: BuildView;
  store: BuildsStore | BuildShowStore;
  build: Build;
  showJobs?: boolean;
  showRebuild?: boolean;
  showProject?: boolean;
  showOrganization?: boolean;
}

interface State {
  showRebuildDialog: boolean;
}

class BuildHeaderComponent extends PureComponent<BuildHeaderProps, State> {
  static defaultProps = {
    showJobs: true,
  };

  state = {
    showRebuildDialog: false,
  };

  componentDidMount() {
    ReactDOM.findDOMNode(this)?.addEventListener(
      "ajax:error",
      this._onAjaxError,
    );
  }

  componentWillUnmount() {
    ReactDOM.findDOMNode(this)?.removeEventListener(
      "ajax:error",
      this._onAjaxError,
    );
  }

  _onAjaxError = (event) => {
    event.preventDefault();

    const [response] = event.detail;

    if (response && response.message) {
      alert(response.message);
    }
  };

  buildIsInNeutralState = () => {
    return ["canceling", "canceled", "scheduled"].includes(
      this.props.build.state,
    );
  };

  bootstrapState = () => {
    switch (this.props.build.state) {
      case "failing":
      case "failed":
        return "danger";
      case "passed":
      case "blocked":
        switch (this.props.build.blockedState) {
          case "failed":
            return "danger";
          case "running":
            return "warning";
          case "passed":
          default:
            return "success";
        }
      case "started":
        return "warning";
      default:
        return "default";
    }
  };

  render() {
    const { build, showJobs } = this.props;
    const bootstrapPanelState = this.bootstrapState();

    const panelClassName = classNames(
      `panel panel-${bootstrapPanelState} build-panel build-state-${build.state}`,
      {
        "mb-2": Features.BuildSidebar && !this.props.showProject,
        mb4: this.props.showProject,
        // This is a tad hacky but we need a variant of bootstraps `panel-default` styles
        // for "neutral" build states
        "panel-neutral": this.buildIsInNeutralState(),
      },
    );

    let buildMessage = <BuildMessage message={build.message} />;
    if (build.path !== window.location.pathname) {
      buildMessage = (
        <a href={build.path} className="color-inherit hover-color-inherit">
          {buildMessage}
        </a>
      );
    }

    const isRunning =
      build.state === "failing" ||
      build.state === "started" ||
      build.state === "canceling" ||
      (build.state === "blocked" && build.blockedState === "running");
    const showTopBar =
      bootstrapPanelState !== "default" || this.buildIsInNeutralState();

    const retryNode = (
      <RetryFailedJobsButton build={build} store={this.props.store} />
    );
    const cancelNode = <CancelButton build={build} store={this.props.store} />;
    const rebuildNode = this.props.showRebuild ? (
      <RebuildButton build={build} />
    ) : null;

    return (
      <div className="build-header build-header-tremor">
        {this._projectNode()}
        <div className={panelClassName}>
          {showTopBar ? (
            <div
              className="panel-bar build-speed-hacks"
              style={
                isRunning
                  ? {
                      backgroundImage:
                        "linear-gradient(-45deg, rgba(255,255,255,.5) 25%, transparent 25%, transparent 50%, rgba(255,255,255,.5) 50%, rgba(255,255,255,.5) 75%, transparent 75%, transparent)",
                      backgroundSize: "12px 12px",
                    }
                  : undefined
              }
            />
          ) : null}
          <div className="panel-heading clearfix">
            <div className="flex items-center">
              <div className="flex-auto flex items-center flex-wrap">
                <div
                  className="flex-auto mr2 build-commit"
                  style={{
                    flexBasis: 300,
                  }}
                >
                  <h3 className="panel-title">{buildMessage}</h3>
                  <div className="flex mt1 gap-3">
                    <BuildNavigator
                      {...build}
                      currentView={this.props.currentView}
                    />

                    {build.branchName && (
                      <>
                        <span className="text-gray-600">/</span>
                        <Branch {...build} />
                      </>
                    )}

                    {build.commitId && (
                      <>
                        <span className="text-gray-600">/</span>
                        <Commit {...build} />
                      </>
                    )}

                    {build.pullRequest && (
                      <span>
                        (<PullRequest {...build} />)
                      </span>
                    )}
                  </div>
                </div>
                <div className="flex-none build-duration mr3">
                  <BuildDuration build={build} />
                </div>
              </div>
              <div className="flex-none build-status-icon flex items-center justify-center build-speed-hacks-child">
                <PipelineStateIcon
                  build={build}
                  style={{ width: "44px", height: "44px" }}
                  variant={VARIANTS.large}
                />
              </div>
            </div>
          </div>

          {showJobs && <Pipeline build={build} store={this.props.store} />}

          <div className="panel-footer flex flex-wrap items-center px2 py0">
            <div
              className="flex flex-wrap items-stretch overflow-hidden"
              style={{ flexGrow: 100 }}
            >
              <div className="flex items-center p2 mln1">
                <Avatar {...build} />
                <AuthorAndTime {...build} />
              </div>

              <div
                className="border-top border-gray flex-auto flex items-center py1"
                style={{
                  marginTop: -1,
                  marginLeft: -1,
                }}
              >
                <div
                  className={classNames(
                    "border-left border-mid-gray flex-auto flex flex-column px2",
                    {
                      truncate: build.triggeredFrom && build.triggeredFrom.url,
                    },
                  )}
                >
                  <MetaInformation {...build} />
                </div>
              </div>
            </div>
            {(retryNode || rebuildNode || cancelNode) && (
              <div className="flex p2 mxn1 space-x-1">
                {retryNode}
                {rebuildNode}
                {cancelNode}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  _projectNode = () => {
    if (this.props.showProject) {
      return (
        <div style={{ marginBottom: 10 }}>
          <a href={this.props.build.project.url} className="dark-gray">
            {this.props.showOrganization &&
              `${this.props.build.account.name} / `}
            {this.props.build.project.name}
          </a>
        </div>
      );
    }
  };
}

export default BuildHeaderComponent;

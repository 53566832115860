import warn from "app/lib/warn";
import { createOperationDescriptor } from "relay-runtime";

type Payload = {
  payload: any;
  variables: any;
};

let PRELOADED_QUERY_PAYLOADS: {
  [key: string]: Payload;
} = {};

function getPreloadPayload(requestId: string): Payload {
  warn(
    requestId in PRELOADED_QUERY_PAYLOADS,
    `The payload ID \`%s\` does not exist in the preloaded payloads IDs. Current preloaded IDs are: %s`,
    requestId,
    JSON.stringify(Object.keys(PRELOADED_QUERY_PAYLOADS)),
  );

  return PRELOADED_QUERY_PAYLOADS[requestId];
}

function sortObject<TObject extends Record<any, any>>(
  object: TObject,
): Partial<TObject> {
  // @ts-expect-error - TS2322 - Type 'Record<string, any>' is not assignable to type 'Partial<TObject>'.
  return Object.keys(object)
    .sort()
    .reduce<Record<string, any>>(
      (memo, key) => ({ ...memo, [key]: object[key] }),
      {},
    );
}

export default class RelayModernPreloader {
  static registerRequest(id: string, payload: any, variables: any) {
    PRELOADED_QUERY_PAYLOADS = {
      ...PRELOADED_QUERY_PAYLOADS,
      [id]: { payload, variables },
    };
  }

  static preload(config: { query: any; variables?: any; environment: any }) {
    const modernRuntimeQuery = config.query;
    const preloadPayload = getPreloadPayload(modernRuntimeQuery.fragment.name);

    if (preloadPayload) {
      if (process.env.NODE_ENV !== "production") {
        const diff = require("lodash/difference");
        const preloadVars = preloadPayload.variables || {};
        const runtimeVars = config.variables || {};
        const differences = [Object.keys, Object.values].reduce<Array<any>>(
          (memo, fn) => memo.concat(diff(fn(preloadVars), fn(runtimeVars))),
          [],
        );

        warn(
          differences.length === 0,
          `Your preload and runtime variables do not match - this will almost certainly result in a mismatch of preloaded data for \`%s\`!\n\n` +
            `Preload variables: \n%s\n\n` +
            `Runtime variables: \n%s\n\n`,
          modernRuntimeQuery.name,
          JSON.stringify(sortObject(preloadVars) as any, null, 4),
          JSON.stringify(sortObject(runtimeVars) as any, null, 4),
        );
      }

      const operationDescriptor = createOperationDescriptor(
        modernRuntimeQuery,
        config.variables,
      );
      config.environment.commitPayload(
        operationDescriptor,
        preloadPayload.payload,
      );
      const check = config.environment.check(operationDescriptor);

      warn(
        check,
        `The preload payload for \`%s\` did not satisfy initial render data requrirements! Check your preload query!`,
        modernRuntimeQuery.name,
      );
    }
  }
}

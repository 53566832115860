import { useEffect } from "react";

/**
 * Use a hotkey to trigger a callback function.
 *
 * Supports the following:
 * - Single key presses (e.g. "a", "1", "Enter").
 * - Meta key combinations (e.g. "Meta+s", "Meta+Enter").
 * - Shift key combinations (e.g. "Shift+s", "Shift+Enter").
 * - Control key combinations (e.g. "Control+s", "Control+Enter").
 * - Alt key combinations (e.g. "Alt+s", "Alt+Enter").
 * - Meta+Shift+Control+Alt key combinations (e.g. "Meta+Shift+Control+Alt+s", "Meta+Shift+Control+Alt+Enter").
 * - Meta+Shift key combinations (e.g. "Meta+Shift+s", "Meta+Shift+Enter").
 * - Meta+Control key combinations (e.g. "Meta+Control+s", "Meta+Control+Enter").
 *
 * @param key - The key to listen for.
 * @param callback - The function to call when the key is pressed.
 */
export const useHotKey = (key: string, callback: () => void) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.metaKey && !key.match(/Meta\+/)) {
        return;
      }

      if (event.shiftKey && !key.match(/Shift\+/)) {
        return;
      }

      if (event.ctrlKey && !key.match(/Control\+/)) {
        return;
      }

      if (event.altKey && !key.match(/Alt\+/)) {
        return;
      }

      const keyParts = key.split("+");
      const lastKey = keyParts[keyParts.length - 1];

      if (event.key === lastKey) {
        callback();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [key, callback]);
};

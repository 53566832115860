import * as React from "react";
import classNames from "classnames";
import styled from "styled-components";

import AssetUploader, { AssetUploaderFile } from "app/lib/AssetUploader";
import Spinner from "app/components/shared/Spinner";

const DropArea = styled.div<{ rounded: boolean }>`
  border-radius: ${({ rounded }) => (rounded ? "8px" : "60px")};
  transition: border-color 150ms ease-in-out;
`;

const PreviewButton = styled.button.attrs({
  type: "button",
})`
  height: 52px;
  width: 52px;
  overflow: hidden;
  cursor: pointer;
  background-color: transparent;

  &:disabled {
    cursor: wait;
  }
`;

const PreviewButtonLabel = styled.div`
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(20, 204, 128, 0.76);
  text-shadow: 0px 0px 4px black;
  opacity: 0;
  transition: opacity 150ms ease-in-out;

  ${PreviewButton}:hover > &,
  ${PreviewButton}:focus > &,
  ${PreviewButton}:active > & {
    opacity: 1;
  }

  ${PreviewButton}:disabled:hover > &,
  ${PreviewButton}:disabled:focus > &,
  ${PreviewButton}:disabled:active > & {
    opacity: 0;
  }
`;

type Props = {
  imageUrl: string;
  rounded: boolean;
  onChange?: any;
  onUpload?: any;
  onError?: any;
};

type State = {
  allowFileInput: boolean;
  documentHover: boolean;
  dropAreaHover: boolean;
  uploading: boolean;
  uploaded: boolean;
  error: Error | null | undefined;
  currentImageUrl: string | null | undefined;
  lastImageUrl: string | null | undefined;
};

export default class ImageUploadField extends React.PureComponent<
  Props,
  State
> {
  state = {
    allowFileInput: false,
    documentHover: false,
    dropAreaHover: false,
    uploading: false,
    uploaded: false,
    error: null,
    currentImageUrl: null,
    lastImageUrl: null,
  };

  // @ts-expect-error - TS2564 - Property 'assetUploader' has no initializer and is not definitely assigned in the constructor.
  assetUploader: AssetUploader;
  // @ts-expect-error - TS2564 - Property 'dragTimeout' has no initializer and is not definitely assigned in the constructor.
  dragTimeout: number;

  _iconInput: HTMLInputElement | null | undefined;
  iconInputRef = (ref?: HTMLInputElement | null) => (this._iconInput = ref);

  _dragArea: HTMLDivElement | null | undefined;
  dragAreaRef = (ref?: HTMLDivElement | null) => (this._dragArea = ref);

  _loadImage:
    | Promise<{
        default: any;
      }>
    | null
    | undefined;

  componentDidMount() {
    this.assetUploader = new AssetUploader({
      onAssetUploaded: this.handleAssetUploaded,
      onError: this.handleAssetUploadError,
    });

    document.addEventListener("dragover", this.handleDocumentDragOver);
  }

  componentWillUnmount() {
    // @ts-expect-error - TS2790 - The operand of a 'delete' operator must be optional.
    delete this.assetUploader;

    document.removeEventListener("dragover", this.handleDocumentDragOver);
  }

  handleAssetUploaded = (file: AssetUploaderFile, asset: any) => {
    if (this.props.onUpload) {
      this.props.onUpload(asset);
    }

    this.setState({
      uploading: false,
      uploaded: true,
      error: null,
    });
  };

  handleAssetUploadError = (error: Error) => {
    if (this.props.onError) {
      this.props.onError(error);
    }

    if (this.state.currentImageUrl) {
      URL.revokeObjectURL(this.state.currentImageUrl);
    }

    this.setState({
      uploading: false,
      uploaded: false,
      currentImageUrl: this.state.lastImageUrl,
      lastImageUrl: null,
      error,
    });
  };

  handleDocumentDragOver = (event: DragEvent) => {
    // Skip any events which are inside our drop target, so we're not resetting constantly
    if (
      this._dragArea &&
      this._dragArea.contains(event.target as HTMLDivElement)
    ) {
      return;
    }

    event.stopPropagation();
    event.preventDefault();

    // Make sure we're loading the image processing code now the user is dragging something around
    this.getLoadImage();

    this.setState(
      {
        dropAreaHover: false,
        documentHover: true,
      },
      this.setDragTimeout,
    );
  };

  setDragTimeout = () => {
    this.cancelDragTimeout();
    this.dragTimeout = setTimeout(this.handleDragCancelled, 500);
  };

  cancelDragTimeout = () => {
    if (this.dragTimeout) {
      clearTimeout(this.dragTimeout);
    }
  };

  handleDragCancelled = () => {
    this.setState({ documentHover: false, dropAreaHover: false });
  };

  handleDropAreaDragOver = (event: React.DragEvent) => {
    event.stopPropagation();
    event.preventDefault();

    // Make sure we're now loading (hopefully _have loaded_!) the image
    // processing code now that the user is dragging over the drop area
    this.getLoadImage();

    this.setState({ dropAreaHover: true }, this.setDragTimeout);
  };

  handleDropAreaDrop = (event: React.DragEvent) => {
    event.stopPropagation();
    event.preventDefault();

    this.startUpload(event.dataTransfer && event.dataTransfer.files);
  };

  handleIconInputChange = () => {
    // If we don't have a ref to _iconInput, something's gone weird;
    // re-render and abort this event
    if (!this._iconInput) {
      this.setState({ allowFileInput: false });
      return;
    }

    this.startUpload(this._iconInput && this._iconInput.files);
  };

  getLoadImage = () => {
    // If we don't have our import promise stored, create it
    if (!this._loadImage) {
      this._loadImage = import("blueimp-load-image");
    }

    // Finally, return the promise
    return this._loadImage;
  };

  withLoadImage = (callback: any) => {
    // Call back with the default loadImage
    // function, once the import promise resolves
    this.getLoadImage().then((module) => callback(module.default));
  };

  startUpload = (files?: FileList | null) => {
    if (this.props.onChange) {
      this.props.onChange();
    }

    this.setState(
      {
        allowFileInput: false,
        documentHover: false,
        dropAreaHover: false,
        uploading: true,
        uploaded: false,
        error: null,
      },
      () => {
        const imageFiles = Array.prototype.filter.call(
          files,
          (file) => file.type.indexOf("image/") === 0,
        );

        if (imageFiles.length === 1) {
          const imageFile = imageFiles[0];

          // If the image is an svg (and thus can't be resized!),
          // or is a gif, don't bother resizing
          if (
            ["image/svg", "image/svg+xml", "image/gif"].includes(imageFile.type)
          ) {
            this.processUpload(imageFile);
            return;
          }

          this.withLoadImage((loadImage: any) =>
            loadImage(
              imageFile,
              (processed: HTMLImageElement | HTMLCanvasElement | Event) => {
                if (processed instanceof HTMLCanvasElement) {
                  // @ts-expect-error - TS2345 - Argument of type '(blob: Blob) => void' is not assignable to parameter of type 'BlobCallback'.
                  processed.toBlob((blob: Blob) => {
                    this.processUpload(blob);
                  }, imageFile.type);
                } else {
                  // If loadImage gives us an <img/> (means the browser couldn't
                  // do canvas), or otherwise couldn't resize it,
                  alert(
                    "Unable to upload your image: your browser doesn't appear to support HTML5 Canvas!",
                  );
                }
              },
              {
                // NOTE: Minimum heights and widths are merely so
                // the library doesn't try to upscale the images.
                minHeight: 1,
                minWidth: 1,
                // This asks the resize library to respect EXIF orientation
                // information in JPEGs, so we don't end up with sideways avatars
                orientation: true,
                // Finally, this is the size we will downscale larger images to.
                //
                // 500px was chosen to balance byte size and utility because we'd
                // like the ability to display larger images in the future, but
                // don't currently have tech to resize for each display size, so
                // this avoids us displaying a 1024px image in 25px img tags!
                maxHeight: 500,
                maxWidth: 500,
              },
            ),
          );
        } else if (imageFiles.length > 1) {
          this.setError(new Error("Only one image can be uploaded."));
        } else {
          this.setError(new Error("You can only upload images."));
        }
      },
    );
  };

  processUpload = (imageFile: File | Blob) => {
    if (imageFile.size > 2097152) {
      this.setError(
        new Error(
          "Sorry, that image is too large. Please try one smaller than 2 megabytes.",
        ),
      );
      return;
    }

    const processedUrl = URL.createObjectURL(imageFile);

    if (this.state.lastImageUrl) {
      URL.revokeObjectURL(this.state.lastImageUrl);
    }

    this.setState({
      currentImageUrl: processedUrl,
      lastImageUrl: this.state.currentImageUrl,
    });

    this.assetUploader.uploadFromArray([imageFile]);
  };

  setError = (error: Error) => {
    this.setState(
      {
        uploading: false,
        error,
      },
      () => {
        if (this.props.onError) {
          this.props.onError(error);
        }
      },
    );
  };

  handleUploadClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();

    // Start loading the image processing code while the user browses for a file
    this.getLoadImage();

    this.setState({ allowFileInput: true }, () => {
      if (this._iconInput) {
        this._iconInput.click();
      }
    });
  };

  render() {
    const { documentHover, dropAreaHover } = this.state;

    return (
      <DropArea
        className={classNames("flex items-center border border-transparent", {
          "border-transparent": !(documentHover && dropAreaHover),
          "border-gray": documentHover && !dropAreaHover,
          "border-lime": dropAreaHover,
        })}
        ref={this.dragAreaRef}
        rounded={this.props.rounded}
        onDragOver={this.handleDropAreaDragOver}
        onDrop={this.handleDropAreaDrop}
      >
        <input
          type="file"
          accept="image/*"
          className="display-none"
          disabled={!this.state.allowFileInput}
          ref={this.iconInputRef}
          onChange={this.handleIconInputChange}
        />
        <PreviewButton
          className={classNames(
            "flex-none bg-silver border-transparent relative p0 mr2",
            {
              circle: !this.props.rounded,
              "rounded-relative": this.props.rounded,
            },
          )}
          onClick={this.handleUploadClick}
        >
          <PreviewButtonLabel
            className={classNames(
              "flex items-center justify-center absolute p1 white",
              {
                circle: !this.props.rounded,
                "rounded-relative": this.props.rounded,
              },
            )}
          >
            Edit
          </PreviewButtonLabel>
          <img
            src={this.state.currentImageUrl || this.props.imageUrl}
            height="100%"
            width="100%"
            className="w-full h-full"
            alt="Current Organization Icon"
          />
        </PreviewButton>
        {this.renderOutput()}
      </DropArea>
    );
  }

  renderOutput() {
    const { documentHover, dropAreaHover, uploading, uploaded, error } =
      this.state;

    let message = (
      <span>
        <a
          href="#"
          onClick={this.handleUploadClick}
          className="lime hover-lime text-decoration-none hover-underline"
        >
          Choose an image
        </a>
        {" to upload."}
        <br />
        Images should be square, and at least 500px wide.
      </span>
    );

    if (dropAreaHover) {
      // @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'Element'.
      message = "Thatʼs it! Right here.";
    } else if (documentHover) {
      // @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'Element'.
      message = "Drop your new icon here.";
    } else if (uploading) {
      message = (
        <>
          <Spinner className="mr1" />
          Uploading&hellip;
        </>
      );
    } else if (uploaded) {
      // @ts-expect-error - TS2322 - Type 'null' is not assignable to type 'Element'.
      message = null;
    } else if (error) {
      // @ts-expect-error - TS2339 - Property 'message' does not exist on type 'never'.
      message = error.message;
    }

    return (
      <small className="flex flex-stretch items-center dark-gray m0 line-height-3 h5 regular">
        {message}
      </small>
    );
  }
}

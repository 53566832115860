import { PureComponent } from "react";
import UserStore from "app/stores/UserStore";
import BranchStore, { Branch, Project } from "app/stores/BranchStore";

import BranchComponent from "./Branch";

type Props = {
  store: BranchStore;
};

type State = {
  branches: Array<Branch>;
  project: Project;
};

export default class BranchList extends PureComponent<Props, State> {
  state = {
    branches: this.props.store.all(),
    project: this.props.store.getProject(),
  };

  componentDidMount() {
    UserStore.addChangeListener(this.handleStoreChange);
    this.props.store.on("change", this.handleStoreChange);
  }

  componentWillUnmount() {
    UserStore.removeChangeListener(this.handleStoreChange);
    this.props.store.off("change", this.handleStoreChange);
  }

  handleStoreChange = () => {
    this.setState({
      branches: this.props.store.all(),
    });
  };

  render() {
    return (
      <div>
        {this.state.branches.map((branch) => (
          <BranchComponent
            key={branch.name}
            project={this.state.project}
            branch={branch}
            store={this.props.store}
          />
        ))}
      </div>
    );
  }
}

import { createFragmentContainer, graphql } from "react-relay";

import { Section, SectionHeading } from "./shared";

type ActorTypeProps = {
  name?: string;
  type?: string;
  uuid?: string;
  node?: {
    name: string;
    avatar?: {
      url?: string;
    };
  };
};
type Props = {
  auditEvent: {
    actor?: ActorTypeProps;
  };
};

const ActorType = ({ actor: { type } }: { actor: ActorTypeProps }) => {
  if (!type) {
    return null;
  }

  return [
    <dt className="mt1 dark-gray" key="type-title">
      Actor Type
    </dt>,
    <dd className="ml0 monospace" key="type-definition">
      {type}
    </dd>,
  ];
};

const ActorName = ({ actor: { name, node } }: { actor: ActorTypeProps }) => {
  if (!!name || !node) {
    return null;
  }
  const renderedName = (node && node.name) || name;

  return [
    <dt className="mt1 dark-gray" key="name-title">
      Actor Name
    </dt>,
    <dd className="ml0" key="name-definition">
      {renderedName}
    </dd>,
  ];
};

const ActorUUID = ({ actor: { uuid } }: { actor: ActorTypeProps }) => {
  if (!uuid) {
    return null;
  }

  return [
    <dt className="mt1 dark-gray" key="uuid-title">
      Actor UUID
    </dt>,
    <dd className="ml0" key="uuid-definition">
      {uuid}
    </dd>,
  ];
};

const AuditLogActorSection = ({ auditEvent: { actor } }: Props) => {
  if (!actor) {
    return null;
  }

  return (
    <Section data-testid="audit-actor">
      <SectionHeading className="m0 mb2">Actor</SectionHeading>
      <dl className="m0">
        {/* @ts-expect-error - TS2786 - 'ActorType' cannot be used as a JSX component. */}
        <ActorType actor={actor} />
        {/* @ts-expect-error - TS2786 - 'ActorName' cannot be used as a JSX component. */}
        <ActorName actor={actor} />
        {/* @ts-expect-error - TS2786 - 'ActorUUID' cannot be used as a JSX component. */}
        <ActorUUID actor={actor} />
      </dl>
    </Section>
  );
};

export default createFragmentContainer(AuditLogActorSection, {
  auditEvent: graphql`
    fragment actorSection_auditEvent on AuditEvent {
      actor {
        name
        type
        uuid
        node {
          ... on User {
            name
            avatar {
              url
            }
          }
          ... on Agent {
            name
          }
        }
      }
    }
  `,
});

/**
 * @generated SignedSource<<0223bfe68d673dbe86ba343d6e199c0f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BuildBlockedStates = "FAILED" | "PASSED" | "RUNNING" | "%future added value";
export type BuildStates = "BLOCKED" | "CANCELED" | "CANCELING" | "CREATING" | "FAILED" | "FAILING" | "NOT_RUN" | "PASSED" | "RUNNING" | "SCHEDULED" | "SKIPPED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type Status_pipeline$data = {
  readonly firstBuild: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly blockedState: BuildBlockedStates | null;
        readonly state: BuildStates;
        readonly url: string;
      } | null;
    } | null> | null;
  } | null;
  readonly id: string;
  readonly " $fragmentType": "Status_pipeline";
};
export type Status_pipeline$key = {
  readonly " $data"?: Status_pipeline$data;
  readonly " $fragmentSpreads": FragmentRefs<"Status_pipeline">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Status_pipeline",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": "firstBuild",
      "args": [
        {
          "kind": "Literal",
          "name": "branch",
          "value": "%default"
        },
        {
          "kind": "Literal",
          "name": "first",
          "value": 1
        }
      ],
      "concreteType": "BuildConnection",
      "kind": "LinkedField",
      "name": "builds",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BuildEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Build",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "state",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "blockedState",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "url",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "builds(branch:\"%default\",first:1)"
    }
  ],
  "type": "Pipeline",
  "abstractKey": null
};

(node as any).hash = "ef87fcebb3747467bcaf02e49d4de5f9";

export default node;

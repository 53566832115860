import Icon from "app/components/shared/Icon";

type Props = {
  buttonRef: React.RefObject<HTMLButtonElement>;
  onResolveTest: () => Promise<void>;
};

const ResolveButton = ({ buttonRef, onResolveTest }: Props) => (
  <button
    ref={buttonRef}
    className="btn btn-default w-full mt-2 flex justify-center items-center"
    onClick={onResolveTest}
  >
    <Icon
      icon="heroicons/outline/check"
      style={{ width: "15px", height: "15px" }}
      className="mr-1"
    />{" "}
    Mark flaky as resolved
  </button>
);

export default ResolveButton;

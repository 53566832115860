/* eslint-disable react/jsx-no-bind */
import { useEffect, useState } from "react";

import { track } from "app/lib/segmentAnalytics";
import RemoteButtonComponent from "app/components/shared/RemoteButtonComponent";
import Spinner from "app/components/shared/Spinner";
import BuildShowStore, { Job } from "app/stores/BuildShowStore";
import BuildsStore from "app/stores/BuildsStore";
import { twMerge } from "tailwind-merge";

interface Props {
  build: {
    path: string;
    retryFailedJobsPath?: string;
    permissions: {
      retry: {
        allowed: boolean;
      };
    };
    canRetryFailedJobs: boolean;
    jobs?: Job[];
  };
  store: BuildShowStore | BuildsStore;
  className?: string;
}

export default function RetryFailedJobsButton({
  build,
  store,
  className,
}: Props) {
  // We set isLoading when the request starts, but we don't unset it once the
  // request finishes. We want to keep the spinner going until the build starts
  // running again, at which point this will be unrendered.
  const [isLoading, setIsLoading] = useState(false);

  // When new jobs start, clear the loading state
  useEffect(() => {
    setIsLoading(false);
  }, [build.jobs?.length]);

  function handleBeforeStart() {
    setIsLoading(true);
    track("Retry Failed Jobs Clicked", {
      text: "Retry failed jobs",
      source: "Rebuild Dropdown",
    });
  }

  function handleSuccess(_event: Event, response: any) {
    if (response) {
      store.loadAndEmit(response);
    }
  }

  function handleError() {
    window.location.assign(build.path || "");
  }

  if (!build.permissions.retry.allowed || !build.canRetryFailedJobs) {
    return null;
  }

  return (
    <RemoteButtonComponent
      url={build.retryFailedJobsPath}
      method="post"
      loading={isLoading}
      loadingText="Retrying jobs&hellip;"
      loadingIndicator={<Spinner />}
      className={twMerge("btn btn-default btn-flex gap1", className)}
      onBeforeStart={handleBeforeStart}
      onSuccess={handleSuccess}
      onError={handleError}
    >
      Retry failed jobs
    </RemoteButtonComponent>
  );
}

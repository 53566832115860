import { useEffect, useState } from "react";
import { track } from "app/lib/segmentAnalytics";
import classnames from "classnames";
import styled from "styled-components";

import Button from "app/components/shared/Button";
import Dialog from "app/components/shared/Dialog";

import linuxLogo from "app/images/compute/machine-type-linux.png";
import macosLogo from "app/images/compute/machine-type-macos.png";
import windowsLogo from "app/images/compute/machine-type-windows.png";
import checkboxIndicator from "app/images/checkbox-indicator.svg";
import checkboxIndicatorChecked from "app/images/checkbox-indicator-checked.svg";

const FocusableLabel = styled.label`
  &:focus-within {
    border-color: var(--purple-600);
  }
`;

const MACHINE_TYPES = {
  linux: {
    value: "linux",
    label: "Linux",
    logo: linuxLogo,
  },
  macos: {
    value: "macos",
    label: "MacOS",
    logo: macosLogo,
  },
  windows: {
    value: "windows",
    label: "Windows",
    logo: windowsLogo,
  },
};

const BANNER_NAME = "compute expression of interest";

function ExpressionOfInterestForm({ organizationUuid, dismissNoticeUrl }) {
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  const [selectedStates, setSelectedStates] = useState({
    linux: false,
    macos: false,
    windows: false,
  });

  useEffect(() => {
    track("Promotional Banner Viewed", {
      banner_name: BANNER_NAME,
      organization_uuid: organizationUuid,
    });
  }, []);

  useEffect(() => {
    setSelectedStates({
      linux: selectAllChecked,
      macos: selectAllChecked,
      windows: selectAllChecked,
    });
  }, [selectAllChecked, setSelectedStates]);

  function handleSubmit(event) {
    event.preventDefault();

    const selectedMachineTypes = Object.keys(selectedStates).filter(
      (key) => selectedStates[key],
    );

    track("Compute Expression of Interest Submitted", {
      organization_uuid: organizationUuid,
      machine_types: selectedMachineTypes.toString(),
    }).then(() => {
      setHasSubmitted(true);
    });
  }

  function handleMachineTypeSelectionChange(event) {
    const { value, checked } = event.target;
    setSelectedStates((prev) => ({ ...prev, [value]: checked }));
  }

  function handleToggleSelectAll() {
    setSelectAllChecked((prev) => !prev);
  }

  function handleCloseDialog() {
    setIsModalOpen(false);

    if (hasSubmitted && dismissNoticeUrl) {
      // The user's joined the waitlist so they don't need to see the notice anymore
      fetch(dismissNoticeUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": window._csrf.token,
        },
        body: JSON.stringify({}),
      }).then(() => {
        window.location.reload();
      });
    }
  }

  function handleOpenDialog() {
    setIsModalOpen(true);
    track("Promotional Banner CTA Clicked", {
      banner_name: BANNER_NAME,
      organization_uuid: organizationUuid,
    });
  }

  return (
    <>
      <Button
        className="btn cursor-pointer border border-purple-300 rounded-1.5 unstyled-button semi-bold purple-600 px2 py1 bg-transparent"
        onClick={handleOpenDialog}
      >
        Join waitlist
      </Button>
      <Dialog
        onRequestClose={handleCloseDialog}
        isOpen={isModalOpen}
        width={500}
      >
        <div className="center p4">
          {hasSubmitted ? (
            <h3 className="bold">You&apos;re on the waitlist!</h3>
          ) : (
            <>
              <h3 className="bold">Select your machine type</h3>
              <div className="flex flex-column gap3 items-center">
                <p className="normal m0">
                  ⚡️ Unlock maximum velocity with the new hosted agents. Select
                  your preferred machine type and join our waitlist
                </p>
                <form
                  onSubmit={handleSubmit}
                  className="flex flex-column gap3 items-center w-full"
                >
                  <div className="w-full grid grid-cols-3 gap2">
                    {Object.values(MACHINE_TYPES).map((machineType) => (
                      <MachineTypeInputCard
                        key={machineType.label}
                        value={machineType.value}
                        label={machineType.label}
                        checked={selectedStates[machineType.value]}
                        onChange={handleMachineTypeSelectionChange}
                        logo={machineType.logo}
                      />
                    ))}
                  </div>
                  <FocusableLabel
                    data-testid="select-all"
                    className="flex gap1 px4 py2 cursor-pointer border rounded-1.5 border-gray-400"
                  >
                    <input
                      type="checkbox"
                      className="hide"
                      onChange={handleToggleSelectAll}
                      checked={selectAllChecked}
                    />
                    <img
                      src={
                        selectAllChecked
                          ? checkboxIndicatorChecked
                          : checkboxIndicator
                      }
                      width={20}
                      height={20}
                    />
                    <p className="m0 semi-bold">Select all</p>
                  </FocusableLabel>
                  <button
                    className="btn btn-onboarding"
                    style={{ width: "60%" }}
                    onClick={handleSubmit}
                    disabled={Object.values(selectedStates).every(
                      (state) => !state,
                    )}
                  >
                    Join waitlist
                  </button>
                </form>
              </div>
            </>
          )}
        </div>
      </Dialog>
    </>
  );
}

function MachineTypeInputCard({ value, label, logo, checked, onChange }) {
  return (
    <FocusableLabel
      className={classnames(
        "w-full flex flex-column gap2 items-center p5 border border-gray-400 rounded-1.5 cursor-pointer",
        { "bg-purple-100 border-purple-600": checked },
      )}
    >
      <input
        id={`checkbox-card-${value}`}
        type="checkbox"
        name="machineType"
        value={value}
        checked={checked}
        onChange={onChange}
        className="hide"
      />
      <div>
        <img src={logo} width={40} height={40} alt={label} />
        <p id={`checkbox-card-${value}-label`} className="m0 semi-bold">
          {label}
        </p>
      </div>
      <img
        src={checked ? checkboxIndicatorChecked : checkboxIndicator}
        width={20}
        height={20}
      />
    </FocusableLabel>
  );
}

export default ExpressionOfInterestForm;

import { createHistory, useQueries } from "history";
import { PureComponent, createContext } from "react";

export const LocationHistoryContext = createContext({ history: null });

export default function withHistory(WrappedComponent) {
  return class HistoryWrapperComponent extends PureComponent {
    static WrappedComponent = WrappedComponent;
    static displayName = `withHistory(${
      WrappedComponent.displayName || WrappedComponent.name || "Component"
    })`;

    history = useQueries(createHistory)();
    handleHistoryChange = this.handleHistoryChange.bind(this);
    state = {
      location: this.history.getCurrentLocation(),
    };

    componentDidMount() {
      this.unlisten = this.history.listen(this.handleHistoryChange);
    }

    componentWillUnmount() {
      this.unlisten && this.unlisten();
    }

    handleHistoryChange(location) {
      this.setState({ location });
    }

    render() {
      return (
        <LocationHistoryContext.Provider
          value={{
            history: this.history,
          }}
        >
          <WrappedComponent location={this.state.location} {...this.props} />
        </LocationHistoryContext.Provider>
      );
    }
  };
}

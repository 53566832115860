/**
 * @generated SignedSource<<b96d48eda0946d087d78eba02e1c6ea3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Agents_organization$data = {
  readonly agents?: {
    readonly count: number;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly metaData: ReadonlyArray<string> | null;
        readonly name: string;
        readonly " $fragmentSpreads": FragmentRefs<"AgentRow_agent">;
      } | null;
    } | null> | null;
    readonly " $fragmentSpreads": FragmentRefs<"ShowMoreFooter_connection">;
  } | null;
  readonly allAgents?: {
    readonly count: number;
  } | null;
  readonly id: string;
  readonly slug: string;
  readonly uuid: string;
  readonly " $fragmentType": "Agents_organization";
};
export type Agents_organization$key = {
  readonly " $data"?: Agents_organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"Agents_organization">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "count",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "clusterQueue"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "clustered"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "isMounted"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isRunningJob"
    },
    {
      "defaultValue": 100,
      "kind": "LocalArgument",
      "name": "pageSize"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "search"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "Agents_organization",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "uuid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "condition": "isMounted",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": "allAgents",
          "args": null,
          "concreteType": "AgentConnection",
          "kind": "LinkedField",
          "name": "agents",
          "plural": false,
          "selections": [
            (v1/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "clusterQueue",
              "variableName": "clusterQueue"
            },
            {
              "kind": "Variable",
              "name": "clustered",
              "variableName": "clustered"
            },
            {
              "kind": "Variable",
              "name": "first",
              "variableName": "pageSize"
            },
            {
              "kind": "Variable",
              "name": "isRunningJob",
              "variableName": "isRunningJob"
            },
            {
              "kind": "Variable",
              "name": "search",
              "variableName": "search"
            }
          ],
          "concreteType": "AgentConnection",
          "kind": "LinkedField",
          "name": "agents",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ShowMoreFooter_connection"
            },
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "AgentEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Agent",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "metaData",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "AgentRow_agent"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "6b22d2085bed0e2d6a1a16cd47d347dd";

export default node;

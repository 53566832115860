/* global jQuery */

import FormManager from "./FormManager";
import LegacyCreditCard, {
  CreditCardOptions,
  CardScheme,
  CardErrorFieldNames,
} from "./legacy-credit-card";

type CreditCardManagerOptions = CreditCardOptions & {
  el: JQuery;
  validatePath: string;
  cardTypesSelector: string;
  cardSchemes: Array<CardScheme>;
};

export default class CreditCardManager {
  options: CreditCardManagerOptions;
  form: FormManager;

  constructor(options: CreditCardManagerOptions) {
    this.options = options;

    this.form = new FormManager({
      el: this.options.el,
      loading: "Processing…",
      url: this.options.validatePath,
      validate: this._onValidate.bind(this),
    });
  }

  ready() {
    // Prettiy the card number and the cvc using jQuery.payment

    this.form.$input("credit-card-number").payment("formatCardNumber");
    this.form.$input("credit-card-cvc").payment("formatCardCVC");

    // When the card number changes, we'll update the card images
    const _onNumberChange = this._onNumberChange.bind(this);
    return this.form
      .$input("credit-card-number")
      .on("keyup", _onNumberChange)
      .on("change", _onNumberChange);
  }

  changeCardType(type?: string | null) {
    const $cardTypes = jQuery(this.options.cardTypesSelector);
    const notMatchedClassName = "credit-card-types__type--not-matched";

    // If nothing matches we show all the card icons, and soon as something matches
    // we show only it and we hide the others
    if (type) {
      $cardTypes.addClass(notMatchedClassName);
      return $cardTypes
        .filter(`[data-type=${type}]`)
        .removeClass(notMatchedClassName);
    }

    return $cardTypes.removeClass(notMatchedClassName);
  }

  _onValidate(successCallback: any, errorCallback: any) {
    const creditCardNumber = this.form.val("credit-card-number")?.toString();
    const creditCardCvc = this.form.val("credit-card-cvc")?.toString();

    // Contruct a hash of our credit card values
    const cardValues = {
      number: creditCardNumber,
      cvc: creditCardCvc,
      name: this.form.val("name")?.toString(),
      expiry_month: this.form.val("expiry_month")?.toString(),
      expiry_year: this.form.val("expiry_year")?.toString(),
      address_postcode: this.form.val("address_postcode")?.toString(),
      address_country: this.form.val("address_country")?.toString(),
    } as const;

    // Create a credit card object
    const creditCard = new LegacyCreditCard(this.options, cardValues);

    // Save the credit card details to either Pin or Stripe
    return creditCard.save({
      success: (provider, payload) => {
        // Add the providers data payload
        for (const key in payload) {
          const value = payload[key];
          this.form.addHiddenInput({ name: `provider[${key}]`, value });
        }

        // Add the providers id's as a field
        this.form.addHiddenInput({ name: "provider[id]", value: provider });

        return successCallback();
      },
      error: (provider, errors) => {
        // Add the errors to the form
        // @ts-expect-error - TS2345 - Argument of type '(field: CardErrorFieldNames) => void' is not assignable to parameter of type '(value: string, index: number, array: string[]) => void'.
        Object.keys(errors).forEach((field: CardErrorFieldNames) => {
          const messages = errors[field];

          // Handle name mismatches for number and cvc
          const formField =
            field === "number"
              ? "credit-card-number"
              : field === "cvc"
                ? "credit-card-cvc"
                : field;

          // @ts-expect-error - TS2345 - Argument of type 'string[] | undefined' is not assignable to parameter of type 'string[]'.
          this.form.addError(formField, messages);
        });

        return errorCallback();
      },
    });
  }

  _onNumberChange(event: JQueryEventObject) {
    const number = jQuery(event.target).val()?.toString();
    const cardScheme = LegacyCreditCard.findScheme(
      this.options.cardSchemes,
      number || "",
    );

    return this.changeCardType(cardScheme && cardScheme.id);
  }
}

import { PureComponent } from "react";
import { createFragmentContainer, graphql } from "react-relay";

import Tag from "app/components/shared/Tag";

type Props = {
  tag: any;
  onClick: (tag: string) => void;
};

class PipelineTag extends PureComponent<Props> {
  handleClick = () => {
    this.props.onClick(this.props.tag.label);
  };

  render() {
    return (
      <Tag
        onClick={this.handleClick}
        title={`Filter by "${this.props.tag.label}"`}
        text={this.props.tag.label}
      />
    );
  }
}

export default createFragmentContainer(PipelineTag, {
  tag: graphql`
    fragment Tag_tag on PipelineTag {
      label
    }
  `,
});

/* eslint-disable react/no-unused-prop-types */
import { memo } from "react";
import { Step, State, Outcome } from "app/lib/pipeline/Step";
import Icon from "app/components/shared/Icon";
import classNames from "classnames";
import { twMerge } from "tailwind-merge";

type Props = Pick<Step, "state" | "outcome" | "missingDependencies"> & {
  waitingOnConcurrencyGroup?: boolean;
  className?: string;
};

const iconForState = ({
  state,
  outcome,
  missingDependencies = [],
  waitingOnConcurrencyGroup = false,
}: Props) => {
  if (waitingOnConcurrencyGroup) {
    return "alert";
  }

  if (missingDependencies.length) {
    return "alert";
  }

  switch (state) {
    case State.Ignored:
      return "dash";
    case State.Running:
      return "progress";
    case State.WaitingForDependencies:
    case State.Ready:
      return "clock";
    case State.Failing:
      return "cross";
    case State.Finished:
      switch (outcome) {
        case Outcome.Passed:
          return "check";
        case Outcome.SoftFailed:
          return "alert";
        case Outcome.HardFailed:
          return "cross";
        case Outcome.Errored:
          return "dash-diagonal";
        case Outcome.Neutral:
        default:
          return null;
      }
    default:
      return null;
  }
};

const labelForState = ({
  state,
  outcome,
  waitingOnConcurrencyGroup = false,
}: Props) => {
  if (waitingOnConcurrencyGroup) {
    return "Waiting on concurrency group";
  }

  switch (state) {
    case State.Ignored:
      return "Ignored";
    case State.Running:
      return "Running";
    case State.Ready:
      return "Scheduled";
    case State.WaitingForDependencies:
      return "Waiting";
    case State.Failing:
      return "Failing";
    case State.Finished:
      switch (outcome) {
        case Outcome.Passed:
          return "Passed";
        case Outcome.SoftFailed:
          return "Soft failed";
        case Outcome.HardFailed:
          return "Failed";
        case Outcome.Errored:
          return "Errored";
        case Outcome.Neutral:
        default:
          return null;
      }
    default:
      return null;
  }
};

export const StateLabel = memo((props: Props) => {
  const label = labelForState(props);
  if (!label) {
    return null;
  }

  return <span className={props.className}>{label}</span>;
});

export const StateIcon = memo((props: Props) => {
  const icon = iconForState(props);
  const label = labelForState(props);

  if (!icon) {
    return null;
  }

  const variant = "tiny";
  const fullIcon = `${icon}-${variant}`;

  return (
    <Icon
      icon={fullIcon}
      aria-label={label}
      style={{ fill: "none" }}
      className={twMerge(
        "h-4 w-4",
        classNames({ "animate-spin": props.state === State.Running }),
        props.className,
      )}
    />
  );
});

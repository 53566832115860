/**
 * @generated SignedSource<<315380e72f4eec8074606d23497244b1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JobEventChanged_event$data = {
  readonly timestamp: any;
  readonly " $fragmentType": "JobEventChanged_event";
};
export type JobEventChanged_event$key = {
  readonly " $data"?: JobEventChanged_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"JobEventChanged_event">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "JobEventChanged_event",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timestamp",
      "storageKey": null
    }
  ],
  "type": "JobEvent",
  "abstractKey": "__isJobEvent"
};

(node as any).hash = "e7cd6ea73ff29739a99c0f17d7a01daa";

export default node;

/**
 * @generated SignedSource<<e6844f95bb62ec304df4ec4b16cb79b1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ShowMoreFooter_connection$data = {
  readonly pageInfo: {
    readonly hasNextPage: boolean;
  } | null;
  readonly " $fragmentType": "ShowMoreFooter_connection";
};
export type ShowMoreFooter_connection$key = {
  readonly " $data"?: ShowMoreFooter_connection$data;
  readonly " $fragmentSpreads": FragmentRefs<"ShowMoreFooter_connection">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ShowMoreFooter_connection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PageInfo",
      "kind": "LinkedField",
      "name": "pageInfo",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasNextPage",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Connection",
  "abstractKey": "__isConnection"
};

(node as any).hash = "8023885da685f32c87207a39ff7adf4b";

export default node;

import React, { useState } from "react";
import classNames from "classnames";

import Button from "app/components/shared/Button";
import FormYAMLEditorField from "app/components/shared/FormYAMLEditorField";
import PipelineEditorSidebar from "app/components/shared/PipelineEditorSidebar";
import PipelineTemplateFormSelect from "app/components/shared/PipelineTemplateFormSelect";

const MemoizedFormYAMLEditorField = React.memo(FormYAMLEditorField);

type PipelineTemplate = {
  uuid?: string;
  name: string;
  description?: string | null;
  configuration: string;
};

export type Props = {
  currentConfiguration: string;
  currentPipelineTemplateUuid: string | null;
  pipelineTemplates: Array<PipelineTemplate>;
  pipelineTemplatesRequired: boolean;
  organizationUuid: string;
  errors: { [key: string]: string };
};

const DEFAULT_CONFIGURATION = `
# Enter your pipeline's YAML step configuration:

# This default command changes the pipeline of a running build by uploading a configuration file.
# https://buildkite.com/docs/agent/v3/cli-pipeline
# For more information on different step types, toggle open the step guides

steps:
  - label: ":pipeline:"
    command: "buildkite-agent pipeline upload"
`.trim();

const NewPipelineStepSettingsForm = (props: Props) => {
  const hasTemplates = props.pipelineTemplates.length > 0;
  const noTemplate: PipelineTemplate = {
    name: "Do not use a pipeline template",
    configuration: props.currentConfiguration || DEFAULT_CONFIGURATION,
  };

  // Allow users to assign a custom configuration only if pipeline templates
  // are not required on an organization
  const pipelineTemplates = props.pipelineTemplatesRequired
    ? props.pipelineTemplates
    : [noTemplate, ...props.pipelineTemplates];

  const [selectedTemplate, setSelectedTemplate] = useState(
    props.pipelineTemplates.find(
      (template) => template.uuid === props.currentPipelineTemplateUuid,
    ) || noTemplate,
  );

  const [showGuide, setShowGuide] = useState(false);
  const isEditable = !selectedTemplate.uuid && !props.pipelineTemplatesRequired;
  const showEditor = !props.pipelineTemplatesRequired || selectedTemplate.uuid;

  function toggleGuide() {
    setShowGuide(!showGuide);
  }

  function handleSelectedTemplateChange(template: PipelineTemplate) {
    setSelectedTemplate(template);
  }

  return (
    <>
      {hasTemplates && (
        <div className="mb2">
          <PipelineTemplateFormSelect
            name="project[pipeline_template_uuid]"
            selected={selectedTemplate}
            items={pipelineTemplates}
            onChange={handleSelectedTemplateChange}
            hasError={!!props.errors["pipeline_template_uuid"]}
          />
        </div>
      )}

      {showEditor && (
        <div
          className="flex flex-row border border-gray rounded"
          style={{ height: "500px" }}
        >
          <div className="flex flex-column flex-auto">
            <div
              className={classNames("px1 py2 flex-auto overflow-scroll", {
                "bg-slate-100": !isEditable,
              })}
            >
              <MemoizedFormYAMLEditorField
                name="project[steps][yaml]"
                value={selectedTemplate?.configuration}
                lineWrapping={true}
                readOnly={!isEditable}
              />
            </div>

            {isEditable && (
              <div className="flex justify-end px3 py2 border-top border-gray">
                <Button type="button" theme="default" onClick={toggleGuide}>
                  {showGuide ? "Hide Guide" : "Show Guide"}
                </Button>
              </div>
            )}
          </div>

          {showGuide && isEditable && (
            <div
              className="flex-none border-left border-gray overflow-scroll"
              style={{ width: "320px" }}
            >
              <PipelineEditorSidebar
                trackingContext={{ organizationUuid: props.organizationUuid }}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default NewPipelineStepSettingsForm;

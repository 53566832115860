/**
 * A simple utility for reading root level CSS custom properties
 *
 * @param {string} property the name of the CSS custom property
 * @returns the corresponding value for the given CSS custom property
 */
function getCssValue(property: string): string {
  const rootElement: Element | null = document.documentElement;

  // This is just to keep flow happy. Technically `document.documentElement` is nullable
  // but this should never be the case for us.
  if (rootElement instanceof HTMLElement) {
    return getComputedStyle(rootElement).getPropertyValue(property);
  }
  throw new Error(
    `No root element was found when attempting to retrieve CSS custom property: ${property}`,
  );
}

export { getCssValue };

import CodeBlock from "app/components/docs/shared/code-block";
import Link from "app/components/docs/shared/link";

import "highlight.js/styles/atom-one-light.css";

type Props = {
  token?: string;
  className?: string;
  style?: any;
};

LinuxQuickStart.title = "Linux/Other";
LinuxQuickStart.slug = "linux";
LinuxQuickStart.emoji = ":linux:";

function LinuxQuickStart(props: Props) {
  const { className, style } = props;

  return (
    <div className={className} style={style}>
      <p>
        Run the following script (
        <Link href="https://raw.githubusercontent.com/buildkite/agent/master/install.sh">
          view the source
        </Link>
        ), which will download and install the correct binary for your system
        and architecture:
      </p>
      <CodeBlock language="bash">
        TOKEN=
        <span className="hljs-string">
          &quot;{props.token || "INSERT-YOUR-AGENT-TOKEN-HERE"}&quot;
        </span>{" "}
        bash -c{" "}
        <span className="hljs-string">
          &quot;`curl -sL
          https://raw.githubusercontent.com/buildkite/agent/master/install.sh`&quot;
        </span>
        <br />
      </CodeBlock>

      <p>Then, start the agent:</p>
      <CodeBlock language="bash">
        ~/.buildkite-agent/bin/buildkite-agent start
        <br />
      </CodeBlock>

      <p className="rounded bg-silver p2">
        See the <Link href="/docs/agent/linux">Linux agent docs</Link> for more
        details.
      </p>
    </div>
  );
}

export default LinuxQuickStart;

/**
 * @generated SignedSource<<ed63053f4976527f41e0a0185aedbf24>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ConsoleSnippetQuery$variables = {
  hasSnippet: boolean;
  snippet: string;
};
export type ConsoleSnippetQuery$data = {
  readonly graphQLSnippet?: {
    readonly operationName: string | null;
    readonly query: string;
    readonly url: string;
    readonly " $fragmentSpreads": FragmentRefs<"Console_graphQLSnippet">;
  } | null;
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"Console_viewer">;
  } | null;
};
export type ConsoleSnippetQuery = {
  response: ConsoleSnippetQuery$data;
  variables: ConsoleSnippetQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "hasSnippet"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "snippet"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "uuid",
    "variableName": "snippet"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "query",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "operationName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ConsoleSnippetQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Console_viewer"
          }
        ],
        "storageKey": null
      },
      {
        "condition": "hasSnippet",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "GraphQLSnippet",
            "kind": "LinkedField",
            "name": "graphQLSnippet",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "Console_graphQLSnippet"
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ConsoleSnippetQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 100
              }
            ],
            "concreteType": "OrganizationConnection",
            "kind": "LinkedField",
            "name": "organizations",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OrganizationEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Organization",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "slug",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrganizationPermissions",
                        "kind": "LinkedField",
                        "name": "permissions",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Permission",
                            "kind": "LinkedField",
                            "name": "pipelineView",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "allowed",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "code",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "organizations(first:100)"
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      },
      {
        "condition": "hasSnippet",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "GraphQLSnippet",
            "kind": "LinkedField",
            "name": "graphQLSnippet",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "6c38bd79d18ad2e1cc75dad216c1aa0c",
    "id": null,
    "metadata": {},
    "name": "ConsoleSnippetQuery",
    "operationKind": "query",
    "text": "query ConsoleSnippetQuery(\n  $hasSnippet: Boolean!\n  $snippet: String!\n) {\n  viewer {\n    ...Console_viewer\n    id\n  }\n  graphQLSnippet(uuid: $snippet) @include(if: $hasSnippet) {\n    query\n    operationName\n    url\n    ...Console_graphQLSnippet\n    id\n  }\n}\n\nfragment Console_graphQLSnippet on GraphQLSnippet {\n  query\n  operationName\n  url\n}\n\nfragment Console_viewer on Viewer {\n  organizations(first: 100) {\n    edges {\n      node {\n        id\n        name\n        slug\n        permissions {\n          pipelineView {\n            allowed\n            code\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "504d514e3839118b679365ba66e78f43";

export default node;

/**
 * @generated SignedSource<<3ae8f09dad3505d2fb5704d77358d717>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AgentStopInput = {
  clientMutationId?: string | null;
  graceful?: boolean | null;
  id: string;
};
export type AgentStopMutation$variables = {
  input: AgentStopInput;
};
export type AgentStopMutation$data = {
  readonly agentStop: {
    readonly agent: {
      readonly connectionState: string;
      readonly disconnectedAt: any | null;
      readonly stoppedAt: any | null;
      readonly stoppedBy: {
        readonly name: string;
      } | null;
    };
  } | null;
};
export type AgentStopMutation = {
  response: AgentStopMutation$data;
  variables: AgentStopMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "connectionState",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "disconnectedAt",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stoppedAt",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AgentStopMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AgentStopPayload",
        "kind": "LinkedField",
        "name": "agentStop",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Agent",
            "kind": "LinkedField",
            "name": "agent",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "stoppedBy",
                "plural": false,
                "selections": [
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AgentStopMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AgentStopPayload",
        "kind": "LinkedField",
        "name": "agentStop",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Agent",
            "kind": "LinkedField",
            "name": "agent",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "stoppedBy",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ad34ff54e67d73330d4c0592f7e5fd01",
    "id": null,
    "metadata": {},
    "name": "AgentStopMutation",
    "operationKind": "mutation",
    "text": "mutation AgentStopMutation(\n  $input: AgentStopInput!\n) {\n  agentStop(input: $input) {\n    agent {\n      connectionState\n      disconnectedAt\n      stoppedAt\n      stoppedBy {\n        name\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8baddec1a73bfc29a7ca9432e9c805b5";

export default node;

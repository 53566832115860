/**
 * @generated SignedSource<<c998ce39b888066c59e6af73d5c6428f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AgentBadge_agent$data = {
  readonly clusterQueue: {
    readonly cluster: {
      readonly uuid: string;
    } | null;
    readonly id: string;
    readonly uuid: string;
  } | null;
  readonly name: string;
  readonly organization: {
    readonly slug: string;
  } | null;
  readonly uuid: string;
  readonly version: string | null;
  readonly versionHasKnownIssues: boolean;
  readonly " $fragmentType": "AgentBadge_agent";
};
export type AgentBadge_agent$key = {
  readonly " $data"?: AgentBadge_agent$data;
  readonly " $fragmentSpreads": FragmentRefs<"AgentBadge_agent">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AgentBadge_agent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "version",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "versionHasKnownIssues",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ClusterQueue",
      "kind": "LinkedField",
      "name": "clusterQueue",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Cluster",
          "kind": "LinkedField",
          "name": "cluster",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Agent",
  "abstractKey": null
};
})();

(node as any).hash = "edac9e00d8ff958efa9ff8b94bf03696";

export default node;

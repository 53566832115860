import { PureComponent } from "react";
import Badge from "app/components/shared/Badge";
import Panel from "app/components/shared/Panel";
import RevealButton from "app/components/shared/RevealButton";

type Props = {
  agentToken: {
    id: string;
    token: string;
    description: string;
    public: boolean;
  };
  showDescription?: boolean;
};

export default class AgentTokenItem extends PureComponent<Props> {
  render() {
    return (
      <Panel.Row key={this.props.agentToken.id}>
        {this.renderDescription()}
        <RevealButton caption="Reveal Agent Token">
          <code className="red monospace" style={{ wordWrap: "break-word" }}>
            {this.props.agentToken.token}
          </code>
        </RevealButton>
      </Panel.Row>
    );
  }

  renderDescription() {
    if (this.props.showDescription) {
      return (
        <small className="dark-gray mb1 block">
          {this.props.agentToken.description}
          {this.renderPublicBadge()}
        </small>
      );
    }
  }

  renderPublicBadge() {
    if (this.props.agentToken.public) {
      return (
        <Badge
          outline={true}
          className="regular very-dark-gray"
          // @ts-expect-error - TS2769 - No overload matches this call.
          title="Agents registered with this token will be visible to everyone, including people outside this organization"
        >
          Public
        </Badge>
      );
    }
  }
}

import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import useFetch from "use-http";

import Emojify from "app/components/shared/Emojify";

type Props = {
  logUrl: string;
  job: {
    id: string;
    name: string;
  };
};

type LogDataType = string;

const LogViewerHeader = styled.h1`
  font-size: 24px;
  margin: 0 0 1rem 2rem;
`;

const LogViewerMeta = styled.span`
  font-size: 12px;
  margin: 2rem;
  color: gray;
`;

const StyledLogViewer = styled.pre`
  background: black;

  margin: 2rem;
  border-radius: 1rem;
  padding: 1rem;

  color: white;
  overflow-wrap: break-word;
  word-break: break-all;
  text-wrap: wrap;
`;

const LogViewerError = styled.div`
  margin: 2rem;
`;

const LogViewer = ({ logUrl, job }: Props) => {
  const { get, response, loading, error } = useFetch(logUrl);

  const [logData, setLogData] = useState<LogDataType | null>(null);
  useEffect(() => {
    loadLog();
  }, [logUrl]);

  const loadLog = useCallback(async () => {
    const data = await get();
    if (response.ok) {
      setLogData(data);
    }
  }, [get, setLogData]);

  if (error) {
    return (
      <div>
        <LogViewerHeader>
          Raw job log for <Emojify text={job.name} />
        </LogViewerHeader>
        <LogViewerMeta>Job ID: {job.id}</LogViewerMeta>
        <LogViewerError>There was an error loading the log</LogViewerError>
      </div>
    );
  }

  return (
    <div>
      <LogViewerHeader>
        Raw job log for <Emojify text={job.name} />
      </LogViewerHeader>
      <LogViewerMeta>Job ID: {job.id}</LogViewerMeta>
      <StyledLogViewer>{(!loading && logData) || "Loading..."}</StyledLogViewer>
    </div>
  );
};

export default LogViewer;

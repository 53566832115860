import { createFragmentContainer, graphql } from "react-relay";
import {
  AttributeTable,
  AttributeTableRow,
} from "app/components/shared/AttributeTable";
import AgentBadge from "app/components/AgentBadge";
import JobEvent from "../JobEvent";

type Props = {
  event: any;
  previousTimestamp: string | null | undefined;
};

function JobEventCanceled({ event, previousTimestamp }: Props) {
  const {
    actor: { node: agent },
  } = event;

  // Refine types here — this sucks a bit because we’re using unions and interfaces and junk for
  // all these types :(
  if (!agent || !agent.name || !agent.uuid || !agent.organization) {
    return null;
  }

  return (
    <JobEvent timestamp={event.timestamp} previousTimestamp={previousTimestamp}>
      <JobEvent.Label>
        <AgentBadge agent={agent} /> Received Cancelation Signal and Stopped
        Running Job
      </JobEvent.Label>
      <JobEvent.Content>
        <AttributeTable>
          <AttributeTableRow title="Exit Status">
            <code className="dark-gray monospace">{event.exitStatus}</code>
          </AttributeTableRow>
        </AttributeTable>
      </JobEvent.Content>
    </JobEvent>
  );
}

export default createFragmentContainer(JobEventCanceled, {
  event: graphql`
    fragment JobEventCanceled_event on JobEvent {
      ... on JobEvent {
        timestamp

        ... on JobEventCanceled {
          exitStatus
        }

        actor {
          node {
            ...AgentBadge_agent
            ... on Agent {
              name
              uuid
              organization {
                slug
              }
            }
          }
        }
      }
    }
  `,
});

import * as React from "react";

type Props = {
  href: string;
  children: React.ReactNode;
};

export default function Link(props: Props) {
  return (
    <a
      {...props}
      className="lime hover-lime text-decoration-none hover-underline"
    />
  );
}

import classNames from "classnames";
import debounce from "lodash/debounce";
import throttle from "lodash/throttle";
import { hour } from "metrick/duration";
import * as React from "react";
import { QueryRenderer, createRefetchContainer, graphql } from "react-relay";
import { CSSTransition } from "react-transition-group";

import cable from "app/lib/cable";
import Environment from "app/lib/relay/environment";

import Badge from "app/components/shared/Badge";
import Button from "app/components/shared/Button";
import Dropdown from "app/components/shared/Dropdown";
import Icon from "app/components/shared/Icon";
import Spinner from "app/components/shared/Spinner";

import { HighlightableNavigationButton } from "..";
import DropdownButton from "./../dropdown-button";
import Build from "./build";

type RelayVariables = {
  includeBuilds: boolean;
  includeBuildCounts: boolean;
};

type ViewerPartial = {
  user: {
    builds?: {
      edges: [
        {
          node: {
            id: string;
          };
        },
      ];
    };
    scheduledBuilds?: {
      count: number;
    };
    runningBuilds?: {
      count: number;
    };
  };
};

type Props = {
  viewer?: ViewerPartial;
  relay: {
    refetch(
      variables: RelayVariables | null | undefined,
      renderVariables: any,
      callback: (error?: Error | null | undefined) => void | null | undefined,
      options: any,
    ): void;
  };
};

type State = {
  isDropdownVisible: boolean;
  scheduledBuildsCount: number;
  runningBuildsCount: number;
  includeBuilds: boolean;
  includeBuildCounts: boolean;
};

const CACHED_STATE_KEY = "CachedState:MyBuilds:";

// This component caches the current builds count between page refreshes so
// that we don't animate in the value once it loads via relay. Because of this
// we do some munging of props into state. Push updates tell Relay to refetch
// the GraphQL query which updates the props, and we turn that into derived
// state for rendering.  We also look at the state and cache it on component
// update. Sorry.
class MyBuilds extends React.PureComponent<Props, State> {
  buildCountReloadInProgress = false;
  buildListReloadInProgress = false;
  subscription: any;
  handleFreeze: any = null;

  constructor(props: any) {
    super(props);

    const cachedState = MyBuilds.getCachedState();

    const viewer = props.viewer || {};

    // We initialize the state with the counts from props if possible (these will only be populated
    // if the Relay store has cached data before this component was rendered). If that is
    // unavailable we use the cached state from the previous page load (and fallback to 0 if neither
    // is available):
    const initialState = {
      isDropdownVisible: false,
      scheduledBuildsCount:
        viewer && viewer.user && viewer.user.scheduledBuilds
          ? viewer.user.scheduledBuilds.count
          : cachedState?.scheduledBuildsCount || 0,
      runningBuildsCount:
        viewer && viewer.user && viewer.user.runningBuilds
          ? viewer.user.runningBuilds.count
          : cachedState?.runningBuildsCount || 0,
      includeBuilds: false,
      includeBuildCounts: false,
    } as const;

    this.state = initialState;
  }

  static getCachedState() {
    const serializedState = localStorage[CACHED_STATE_KEY];

    if (!serializedState) {
      return {};
    }

    const { state, expiresAt } = JSON.parse(serializedState);

    if (!state || (expiresAt && expiresAt < Date.now())) {
      return {};
    }

    return state;
  }

  static setCachedState(state = {}) {
    localStorage[CACHED_STATE_KEY] = JSON.stringify({
      state,
      expiresAt: Date.now() + hour.bind(1),
    });
  }

  componentDidMount() {
    this.subscription = cable.subscriptions.create(
      { channel: "CurrentUserChannel" },
      {
        component: this,

        connected() {
          this.component.handleWebsocketConnected();
        },

        received({ event }) {
          if (event === "builds:changed") {
            this.component.reloadBuildCounts();
          }
        },
      },
    );

    window.addEventListener("storage", this.handleStorageEvent);
  }

  componentDidUpdate(prevProps: Props, _prevState: State) {
    const scheduledBuildsCount =
      this.props.viewer?.user?.scheduledBuilds?.count;
    const runningBuildsCount = this.props.viewer?.user?.runningBuilds?.count;

    const prevScheduledBuildsCount =
      prevProps.viewer?.user?.scheduledBuilds?.count;
    const prevRunningBuildsCount = prevProps.viewer?.user?.runningBuilds?.count;

    if (
      scheduledBuildsCount !== prevScheduledBuildsCount ||
      runningBuildsCount !== prevRunningBuildsCount
    ) {
      // Relay has updated the counts, we will push this to local storage so that all open tabs can
      // update their rendered count:
      MyBuilds.setCachedState({ scheduledBuildsCount, runningBuildsCount });
      // The storage update is not broadcast to the current tab, so we must pull the data from the
      // cache manually:
      this.refreshBuildCountsFromCache();
    }
  }

  componentWillUnmount() {
    this.reloadBuildCounts.cancel();
    this.subscription.unsubscribe();
    window.removeEventListener("storage", this.handleStorageEvent);
  }

  render() {
    return (
      <HighlightableNavigationButton
        as={Dropdown}
        width={320}
        className="flex flex-none"
        active={this.state.isDropdownVisible}
        onToggle={this.handleDropdownToggle}
      >
        <DropdownButton
          data-testid="my-builds-button"
          className={classNames("flex-none py0", {
            purple: this.state.isDropdownVisible,
          })}
          onMouseEnter={this.handleButtonMouseEnter}
          onMouseLeave={this.handleButtonMouseLeave}
        >
          {"My Builds "}
          <div className="xs-hide">
            <CSSTransition
              in={this.getBuildsCount() > 0}
              classNames="transition-appear-pop"
              timeout={{
                enter: 200,
                exit: 200,
              }}
            >
              {this.renderBadge()}
            </CSSTransition>
          </div>
          <Icon
            icon="down-triangle"
            className="flex-none"
            style={{
              width: 7,
              height: 7,
              marginLeft: ".5em",
            }}
          />
        </DropdownButton>

        {this.renderDropdown()}
      </HighlightableNavigationButton>
    );
  }

  handleStorageEvent = (event: any) => {
    if (event.key === CACHED_STATE_KEY) {
      this.refreshBuildCountsFromCache();
    }
  };

  refreshBuildCountsFromCache = () => {
    const cachedState = MyBuilds.getCachedState();
    this.setState({
      scheduledBuildsCount: cachedState.scheduledBuildsCount || 0,
      runningBuildsCount: cachedState.runningBuildsCount || 0,
    });
  };

  getBuildsCount() {
    return this.state.runningBuildsCount + this.state.scheduledBuildsCount;
  }

  renderBadge() {
    const buildsCount = this.getBuildsCount();

    // Render nothing (an empty span) if we've not got a number to show
    if (!buildsCount) {
      return <span />;
    }

    return (
      <Badge
        outline={true}
        className={classNames("color-inherit semi-bold", {
          "badge--purple": this.state.isDropdownVisible,
        })}
      >
        {buildsCount}
      </Badge>
    );
  }

  renderDropdown() {
    // If `builds` here is null, that means that Relay hasn't fetched the data
    // for it yet. It will become an Array once it's loaded.
    if (
      !this.props.viewer ||
      !this.props.viewer.user ||
      !this.props.viewer.user.builds
    ) {
      return this.renderSpinner();
    }

    // Once we've got an array of builds, we either show what we have, or the
    // setup instructions.
    if (this.props.viewer.user.builds.edges.length > 0) {
      return this.renderBuilds();
    }

    return this.renderSetupInstructions();
  }

  renderBuilds() {
    if (
      !(
        this.props.viewer &&
        this.props.viewer.user &&
        this.props.viewer.user.builds
      )
    ) {
      return;
    }

    return (
      <div>
        <div className="px3 py2">
          {this.props.viewer.user.builds.edges.map((edge) => (
            <Build key={edge.node.id} build={edge.node} />
          ))}
        </div>
        <div className="pb2 px3">
          <Button
            href="/builds"
            className="center h4 bold"
            style={{ width: "100%" }}
          >
            More Builds
          </Button>
        </div>
      </div>
    );
  }

  renderSetupInstructions() {
    return (
      <div className="px3 py2">
        <div className="mb2">
          To have your builds appear here, make sure that your commit email
          address (e.g.&nbsp;
          <code className="code" style={{ padding: ".1em .3em" }}>
            git config user.email
          </code>
          ) is added and verified in your list of personal email addresses.
        </div>
        <Button
          href="/user/emails"
          className="center h4 bold"
          style={{ width: "100%" }}
        >
          Update Email Addresses
        </Button>
      </div>
    );
  }

  renderSpinner() {
    return (
      <div className="px3 py2 center">
        <Spinner />
      </div>
    );
  }

  refetchQueryVariables() {
    return {
      includeBuilds: this.state.includeBuilds,
      includeBuildCounts: this.state.includeBuildCounts,
    };
  }

  reload(options = {}, callback = null) {
    this.props.relay.refetch(
      this.refetchQueryVariables(),
      null,
      // @ts-expect-error - TS2345 - Argument of type 'null' is not assignable to parameter of type '(error?: Error | null | undefined) => void | null | undefined'.
      callback,
      options,
    );
  }

  reloadBuildCounts = debounce(
    () => {
      if (this.buildCountReloadInProgress) {
        // Defer this next count reload until later using the debounce, so that the currently in
        // progress one has a chance to finish (this is NOT recursion thanks to the fact that we're
        // using the debounce function):
        this.reloadBuildCounts();
      } else {
        this.buildCountReloadInProgress = true;

        if (this.state.isDropdownVisible) {
          // The dropdown is visible so we need to do a full refresh of the query so that the list
          // of recent builds is updated as well as the build counts.
          //
          // In this case we do not use the `throttledListReload` method because we know we want the
          // reload to happen immediately (and these reloads are debounced and protected against
          // multiple parallel requests thanks to `reloadBuildCounts` - so the utility of
          // `throttledListReload` is not required here):
          // @ts-expect-error - TS2345 - Argument of type '() => void' is not assignable to parameter of type 'null | undefined'.
          this.reload({ force: true }, () => {
            // Clear the reload state ready for the next one:
            this.buildCountReloadInProgress = false;
          });
        } else {
          // The dropdown isn't visible so we can avoid extra load on the server by utilizing a lock
          // to ensure that only one tab is requesting the build counts at a time:
          this.buildCountReloadWithLock(() => {
            // Clear the reload state ready for the next one:
            this.buildCountReloadInProgress = false;
          });
        }
      }
    },
    1000,
    { maxWait: 4000 },
  );

  // When the user hovers over or clicks the "My Builds" button we want to refresh the list of
  // builds to ensure it is up to date for rendering in the dropdown. We need to do this
  // pre-emptively because we do not automatically refresh the list of builds when `builds:changed`
  // events are received unless the dropdown is already open.
  //
  // To avoid the user triggering too many of these forced build list refreshes we wrap it in a
  // throttle function to restrict the number of requests made to at most one every 4 seconds:
  throttledListReload = throttle(
    () => {
      if (this.buildListReloadInProgress) {
        // Skip this reload as we don't want to start another request while one is still in progress
        return;
      }

      this.buildListReloadInProgress = true;
      // @ts-expect-error - TS2345 - Argument of type '() => void' is not assignable to parameter of type 'null | undefined'.
      this.reload({ force: true }, () => {
        // Clear the reload state ready for the next one:
        this.buildListReloadInProgress = false;
      });
    },
    4000,
    { trailing: false }, // Only invoke on the leading edge of the timeout
  );

  buildCountReloadWithLock(callback: () => void) {
    // We only support refreshing build counts in browsers that support the Web Locks API (this
    // covers all modern browsers):
    if (!window.navigator.locks) {
      return;
    }

    // We'll use the Locks API to ensure that only one tab is requesting the build counts at a time:
    window.navigator.locks.request(
      "MyBuilds-count-reload",
      { ifAvailable: true },
      (lock) => {
        if (lock === null) {
          // Lock wasn't granted, another tab is refreshing the counts so we can skip this refresh
          // and await a storage update from the other tab:
          callback();
          return;
        }

        return new Promise(
          (
            resolve: (result: Promise<undefined> | undefined) => void,
            reject: (error?: any) => void,
          ) => {
            // We can release the lock by either resolving or rejecting this promise. We want to
            // release the lock early if the browser freezes/unloads this tab (to prevent active tabs
            // from being locked out), so we'll listen for the `freeze` event and release the lock
            // when that occurs:

            let lockReleased = false;

            if ("onfreeze" in document) {
              // We only ever want to have a single 'freeze' event listener for this component so we'll
              // keep track of its reference on the component instance.
              if (this.handleFreeze) {
                document.removeEventListener("freeze", this.handleFreeze);
              }

              this.handleFreeze = () => {
                if (!lockReleased) {
                  lockReleased = true;
                  reject();
                }
              };

              document.addEventListener("freeze", this.handleFreeze);
            }

            // @ts-expect-error - TS2345 - Argument of type '() => void' is not assignable to parameter of type 'null | undefined'.
            this.reload({ force: true }, () => {
              if (!lockReleased) {
                // The lock wasn't already released by a `freeze` event, so we'll do so now by
                // resolving the promise:
                // @ts-expect-error - TS2794 - Expected 1 arguments, but got 0. Did you forget to include 'void' in your type argument to 'Promise'?
                resolve();
              }

              if (this.handleFreeze) {
                document.removeEventListener("freeze", this.handleFreeze);
                this.handleFreeze = null;
              }

              // Let the component know that the request has been completed:
              callback();
            });
          },
        );
      },
    );
  }

  handleDropdownToggle = (visible: any) => {
    if (visible) {
      // If `includeBuilds` hasn't been set to `true` yet when the dropdown
      // is opened, this will trigger a load of the builds as the dropdown opens.
      this.setState({ includeBuilds: true }, () => {
        // Force a reload of the build list to ensure it is up to date:
        this.throttledListReload();
      });
    } else {
      // Stop including builds in any refreshes that happen while the dropdown is closed (i.e.
      // loading the build counts):
      this.setState({ includeBuilds: false });
    }

    this.setState({ isDropdownVisible: visible });
  };

  // We only go and fetch the build counts once the websocket is connected.
  handleWebsocketConnected = () => {
    this.setState({ includeBuildCounts: true }, () => {
      this.reload();
    });
  };

  // If the user is hovering over the "My Builds" button,
  // be sneaky and start loading the build data in the background.
  handleButtonMouseEnter = () => {
    this.setState({ includeBuilds: true }, () => {
      // Force data to reload in case the build list has changed since the last time the dropdown
      // was open:
      this.throttledListReload();
    });
  };

  // If the user has stopped hovering over "My Builds", be similarly
  // sneaky and prepare to stop requesting that extra data.
  handleButtonMouseLeave = () => {
    // Stop including builds in any refreshes that happen while the dropdown is closed (i.e.
    // loading the build counts):
    if (!this.state.isDropdownVisible) {
      this.setState({ includeBuilds: false });
    }
  };
}

const MyBuildsRefetchContainer = createRefetchContainer(
  MyBuilds,
  {
    viewer: graphql`
      fragment MyBuilds_viewer on Viewer
      @argumentDefinitions(
        includeBuildCounts: { type: "Boolean", defaultValue: false }
        includeBuilds: { type: "Boolean", defaultValue: false }
      ) {
        user {
          id
          builds(first: 5) @include(if: $includeBuilds) {
            edges {
              node {
                id
                ...build_build
              }
            }
          }
          runningBuilds: builds(state: [RUNNING, FAILING])
            @include(if: $includeBuildCounts) {
            count
          }
          scheduledBuilds: builds(state: SCHEDULED)
            @include(if: $includeBuildCounts) {
            count
          }
        }
      }
    `,
  },
  graphql`
    query MyBuildsRefetchQuery(
      $includeBuilds: Boolean!
      $includeBuildCounts: Boolean!
    ) {
      viewer {
        ...MyBuilds_viewer
          @arguments(
            includeBuilds: $includeBuilds
            includeBuildCounts: $includeBuildCounts
          )
      }
    }
  `,
);

/*
  My Builds uses the viewer data to gather build information. The viewer object is also used by
  other components in the navigation tree. This seems to be causing some internal issues with
  the relay cache.

  Since the build data is lazy loaded, we'll create a new Renderer just for MyBuilds.
*/
export default function MyBuildsRenderer() {
  const environment = Environment.get();
  const query = graphql`
    query MyBuildsQuery {
      viewer {
        ...MyBuilds_viewer
      }
    }
  `;

  function renderQuery({ props }: { props: any }) {
    if (!props) {
      return null;
    }

    return <MyBuildsRefetchContainer {...props} />;
  }

  return (
    <QueryRenderer
      fetchPolicy="store-and-network"
      environment={environment}
      query={query}
      render={renderQuery}
    />
  );
}

import usePagination, {
  PaginationProps,
} from "app/components/analytics/shared/hooks/usePagination";
import ErrorState from "app/components/analytics/shared/ErrorState";
import EmptyState from "app/components/shared/EmptyState";
import Pagination from "app/components/shared/Pagination";
import { LoadingPanels } from "app/components/analytics/shared/LoadingStates";
import Tooltip from "app/components/analytics/shared/Tooltips/BasicTooltip";
import Row, { ExecutionRow } from "../Row";

type Props = {
  endpoint: string;
  tooltip: string;
};

const TestTable = ({ endpoint, tooltip }: Props) => {
  const { currentData, pageCount, handleOnChange, loading, error } =
    usePagination({ endpoint });

  return (
    <TestTableData
      currentData={currentData}
      pageCount={pageCount}
      handleOnChange={handleOnChange}
      loading={loading}
      error={error}
      tooltip={tooltip}
    />
  );
};

type TestTableDataPaginationProps = Omit<PaginationProps, "currentData"> & {
  currentData: Array<ExecutionRow>;
  tooltip: string;
};

export const TestTableData = ({
  currentData,
  loading,
  error,
  pageCount,
  handleOnChange,
  tooltip,
}: TestTableDataPaginationProps) => {
  if (error) {
    return (
      <ErrorState
        emoji="🕳️"
        heading="There was an error loading the failed executions"
        subheading="Try refreshing the page; if the problem persists"
        urlText="let us know"
        url="mailto:support@buildkite.com"
      />
    );
  }

  if (!loading && currentData.length === 0) {
    return <EmptyState emoji="🤖" heading="No failed test executions found" />;
  }

  return (
    <section>
      {loading ? (
        <LoadingPanels rows={10} columns={1} />
      ) : (
        <ul className="ta-panel">
          <li
            aria-hidden={true}
            className="ta-panel--heading grid-cols-[3rem_auto_6rem]"
          >
            <div>Result</div>
            <div className="main-section">Description</div>
            <div className="flex items-center justify-end">
              Duration
              <Tooltip classNames="ml-1" name="Duration">
                <p className="font-semibold m-0 charcoal-800">Duration</p>
                <p className="m-0 mt-1 charcoal-800">{tooltip}</p>
              </Tooltip>
            </div>
          </li>
          {currentData.map((execution) => (
            <Row key={execution.uuid} execution={execution} />
          ))}
        </ul>
      )}
      <Pagination pageCount={pageCount} onPageChange={handleOnChange} />
    </section>
  );
};

export default TestTable;

import { useState, useRef, useEffect } from "react";
import { hashHistory } from "react-router";

function parseStepNumber() {
  return parseInt(window.location.hash.match(/\d+/)?.[0] || "", 10);
}

/**
 * This hook is used to keep track of the current step in the URL hash.
 */
export function useLocationStep(): [
  number,
  number,
  (step: number) => void,
  (step: number) => void,
] {
  const [[step, direction], setStep] = useState([parseStepNumber(), 1]);
  const prevStep = useRef(step);

  useEffect(() => {
    return hashHistory.listen(() => {
      const nextStep = parseStepNumber();
      setStep([nextStep, nextStep > prevStep.current ? 1 : -1]);
      prevStep.current = nextStep;
    });
  }, []);

  return [
    step,
    direction,
    function push(step: number) {
      hashHistory.push(`/${step}`);
    },
    function replace(step: number) {
      hashHistory.replace(`/${step}`);
    },
  ];
}

import * as Turbo from "@hotwired/turbo";
import { useCallback } from "react";

type Props = {
  label?: string;
  url: string;
  classes: string;
  children: React.ReactNode;
};

export default function ExecutionDrawerButton({
  label,
  url,
  classes,
  children,
}: Props) {
  const openExecutionDrawer = useCallback(() => {
    Turbo.visit(url, { frame: "execution_drawer_frame" });
  }, []);

  return (
    <button
      aria-label={label}
      onClick={openExecutionDrawer}
      // @ts-expect-error: TypeScript < 5.2 does not support Popover API
      // eslint-disable-next-line
      popovertarget="execution-drawer"
      // eslint-disable-next-line
      popovertargetaction="show"
      className={classes}
    >
      {children}
    </button>
  );
}

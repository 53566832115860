import EventEmitter from "eventemitter3";

export default class Store extends EventEmitter {
  all() {
    throw "not implemented";
  }

  // @ts-expect-error - TS2416 - Property 'on' in type 'Store' is not assignable to the same property in base type 'EventEmitter<string | symbol>'.
  on(events: string, callback: any): unknown {
    return events.split(" ").map((event) => super.on(event, callback));
  }

  // @ts-expect-error - TS2416 - Property 'off' in type 'Store' is not assignable to the same property in base type 'EventEmitter<string | symbol>'.
  off(events: string, callback: any): unknown {
    return events.split(" ").map((event) => super.off(event, callback));
  }
}

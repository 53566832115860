import { PureComponent } from "react";
import { minute } from "metrick/duration";

import FriendlyTime from "app/components/shared/FriendlyTime";

import { DateLike } from "app/lib/date";
import { buildStatus } from "app/lib/builds";

type Props = {
  build: any;
  updateFrequency: number;
};

type State = {
  timeValue: DateLike;
  prefix: string;
};

export default class BuildStatusDescription extends PureComponent<
  Props,
  State
> {
  // @ts-expect-error - TS2564 - Property '_interval' has no initializer and is not definitely assigned in the constructor.
  _interval: number;

  static defaultProps = {
    updateFrequency: minute.bind(1),
  };

  constructor(props: Props) {
    super(props);

    this.state = buildStatus(this.props.build);
  }

  updateBuildInfo(build: any) {
    this.setState(buildStatus(build));
  }

  componentDidMount() {
    this.maybeSetInterval(this.props.updateFrequency);
  }

  maybeSetInterval(updateFrequency: number) {
    if (updateFrequency > 0) {
      this._interval = setInterval(
        () => this.updateBuildInfo(this.props.build),
        updateFrequency,
      );
    }
  }

  maybeClearInterval() {
    if (this._interval) {
      clearInterval(this._interval);
    }
  }

  componentWillUnmount() {
    this.maybeClearInterval();
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const { build, updateFrequency } = nextProps;

    if (updateFrequency !== this.props.updateFrequency) {
      this.maybeClearInterval();
      this.maybeSetInterval(updateFrequency);
    }

    this.updateBuildInfo(build);
  }

  render() {
    return (
      <span>
        {this.state.prefix}{" "}
        <FriendlyTime value={this.state.timeValue} capitalized={false} />
      </span>
    );
  }
}

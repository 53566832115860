import { useState, useEffect, useCallback } from "react";
import useFetch from "use-http";

import Indicator from "app/components/shared/Indicator";

type Props = {
  job: {
    testAnalytics: {
      failedExecutionsUrl?: string;
    };
  };
};

const TestAnalyticsIndicator = ({
  job: {
    testAnalytics: { failedExecutionsUrl },
  },
}: Props) => {
  if (!failedExecutionsUrl) {
    return null;
  }

  const [failureCount, setFailureCount] = useState(0);
  const { get, response, error } = useFetch(failedExecutionsUrl);

  const getFailures = useCallback(async () => {
    const data = await get();
    if (response.ok && data.total_count) {
      setFailureCount(data.total_count);
    }
  }, [get, response]);

  useEffect(() => {
    getFailures();
  }, [getFailures]);

  if (!failureCount || error) {
    return null;
  }

  return <Indicator className="absolute top-0 right-1" />;
};

export default TestAnalyticsIndicator;

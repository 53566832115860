import * as React from "react";

import Emojify from "app/components/shared/Emojify";
import Icon from "app/components/shared/Icon";

import { titleForBrokenJob } from "./utils/job";
import { TriggerJob } from "./types/TriggerJob";
import { StepContentWrapper } from ".";
import PipelineStateIcon from "app/components/shared/PipelineStateIcon";

type Props = {
  build: {
    path: string;
  };
  job: TriggerJob;
  stepClassName: string;
};

/* eslint-disable react/require-optimization */
// TODO: Double check these can be optimised

export default class TriggerStep extends React.Component<Props> {
  render() {
    const { job, build, stepClassName } = this.props;

    if (this.props.job.state === "broken") {
      const title = titleForBrokenJob(job);

      return (
        <div key={job.id} className={stepClassName}>
          {this.renderJobName({ title })}
        </div>
      );
    }

    return (
      <a
        key={job.id}
        href={`${build.path}#job-${job.id}`}
        className={stepClassName}
        style={{ maxWidth: "15em" }}
      >
        {/* @ts-expect-error - TS2786 - 'StepContentWrapper' cannot be used as a JSX component. */}
        <StepContentWrapper shouldWrap={job.softFailed}>
          {job.softFailed ? (
            <span className="build-pipeline-job__icon">
              <PipelineStateIcon job={job} />
            </span>
          ) : null}
          {this.renderJobName()}
        </StepContentWrapper>
      </a>
    );
  }

  renderJobName(
    args?: {
      title: string | null | undefined;
    } | null,
  ) {
    if (this.props.job.name) {
      return (
        <span className="truncate" style={{ maxWidth: "12em" }}>
          {args && args.title ? (
            <Emojify text={this.props.job.name} title={args.title} />
          ) : (
            <Emojify text={this.props.job.name} />
          )}
        </span>
      );
    }

    let nameNode;
    let nameText;

    if (this.props.job.triggerProject) {
      nameNode = <Emojify text={this.props.job.triggerProject.name} />;
      nameText = `Trigger Build on ${this.props.job.triggerProject.name}`;
    } else if (this.props.job.triggerProjectSlug) {
      nameNode = (
        <code className="monospace">{this.props.job.triggerProjectSlug}</code>
      );
      nameText = `Trigger Build on ${this.props.job.triggerProjectSlug}`;
    } else {
      nameNode = nameText = "unknown pipeline";
    }

    return (
      <span className="truncate" style={{ maxWidth: "12em" }}>
        {this.props.job.retriedInJobUuid && (
          <Icon
            icon="retry"
            style={{ height: 12, width: 12, marginRight: 5 }}
            className="relative"
          />
        )}
        <span title={nameText}>
          {"Trigger Build on "}
          {nameNode}
        </span>
      </span>
    );
  }
}

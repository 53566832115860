import Link from "./link";
import Icon from "app/components/shared/Icon";

export default function Footer() {
  return (
    <footer
      className="container center"
      style={{ marginTop: 40, paddingTop: 30 }}
    >
      <div className="px2 border-top border-gray dark-gray">
        <div className="mt4 mb4 px2 small">
          <Link href="/home">Home</Link>
          <Link href="/changelog">Changelog</Link>
          <Link href="/docs">Docs</Link>
          <Link href="https://forum.buildkite.community/c/feature-requests">
            Feedback
          </Link>
          <Link href="/privacy-policy">Privacy</Link>
          <Link href="/terms-of-service">Terms</Link>
          <Link href="https://forum.buildkite.community/">Forum</Link>
          <Link href="https://buildkitestatus.com/">System Status</Link>
        </div>

        <div className="mt4 mb4">
          <a
            href="https://x.com/buildkite"
            className="btn hover-lime px1"
            title="X icon"
          >
            <Icon icon="custom/solid/x" />
          </a>
          <a
            href="https://github.com/buildkite"
            className="btn hover-lime px1"
            title="GitHub icon"
          >
            <Icon icon="github" />
          </a>
        </div>
      </div>
    </footer>
  );
}

/**
 * @generated SignedSource<<8a6a58f7a5ab400ec815b717d4c66956>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JobTitle_job$data = {
  readonly build: {
    readonly number: number;
    readonly pipeline: {
      readonly name: string;
    };
  } | null;
  readonly command: string | null;
  readonly label: string | null;
  readonly " $fragmentType": "JobTitle_job";
};
export type JobTitle_job$key = {
  readonly " $data"?: JobTitle_job$data;
  readonly " $fragmentSpreads": FragmentRefs<"JobTitle_job">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "JobTitle_job",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "command",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Build",
      "kind": "LinkedField",
      "name": "build",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "number",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Pipeline",
          "kind": "LinkedField",
          "name": "pipeline",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "JobTypeCommand",
  "abstractKey": null
};

(node as any).hash = "bb2a843c370f9aedfec40bc0e5d0a24d";

export default node;

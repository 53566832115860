import FriendlyTime from "app/components/shared/FriendlyTime";
import UserAvatar from "app/components/shared/UserAvatar";
import Emojify from "./Emojify";

export type PausedClusterQueue = {
  key: string;
  path: string;
  dispatchPausedAt: string;
  dispatchPausedBy: {
    name: string;
    avatar: {
      url: string;
    };
  };
  dispatchPausedNote?: string | null;
};

function PausedQueueNote({ note }: { note?: string | null }) {
  if (note == null || note.length === 0) {
    return null;
  }

  return (
    <div className="mt2 mb5">
      <p className="bg-white m0 px5 py4 depth rounded-1.5 inline-block charcoal-800">
        <Emojify text={note} />
      </p>
    </div>
  );
}

function PausedQueueDetails({ queue }: { queue: PausedClusterQueue }) {
  return (
    <div className="charcoal-500 border-gray-400" role="listitem">
      <div className="flex gap2">
        <a className="color-inherit underline" href={queue.path}>
          {queue.key}
        </a>

        <span className="parsnip-400">&bull;</span>

        <span className="flex items-center gap1">
          <UserAvatar user={queue.dispatchPausedBy} size="MICRO" />
          {queue.dispatchPausedBy.name}
        </span>

        <span className="parsnip-400">&bull;</span>

        <FriendlyTime value={queue.dispatchPausedAt} />
      </div>

      <PausedQueueNote note={queue.dispatchPausedNote} />
    </div>
  );
}

export default function PausedClusterQueuesNotice({
  pausedQueues,
}: {
  pausedQueues: Array<PausedClusterQueue>;
}) {
  if (!pausedQueues || pausedQueues.length === 0) {
    return null;
  }

  return (
    <div className="antialiased p6 pb0 mb2 border bg-parsnip-100 border-parsnip-400 rounded-1.5">
      <h2 className="charcoal-900 m0 mb3" style={{ fontSize: 16 }}>
        {pausedQueues.length > 1
          ? `${pausedQueues.length} Queues Paused`
          : "Queue Paused"}
      </h2>

      <div className="divide-y mb6" role="list">
        {pausedQueues.map((queue) => (
          <PausedQueueDetails key={queue.key} queue={queue} />
        ))}
      </div>
    </div>
  );
}

import { useState, useCallback, useEffect } from "react";
import styled from "styled-components";

import RunTable from "app/components/analytics/execution/RunTable";
import TabControl, { TabButton } from "app/components/analytics/shared/Tabs";

type Props = {
  failedTestsEndpoint: string;
  slowestTestsEndpoint: string;
  tooltip: string;
};

const TABS = {
  SLOWEST_TAB: "slowest",
  FAILED_TAB: "failed",
};

const StyledTabControl = styled(TabControl)`
  margin-top: 40px;
  justify-content: center;
`;

const TableWrapper = styled.div`
  margin-top: 30px;
  margin-bottom: 50px;
`;

TableWrapper.displayName = "TableWrapper";

const Tabs = ({
  failedTestsEndpoint,
  slowestTestsEndpoint,
  tooltip,
}: Props) => {
  let anchor = window.location.hash.replace("#", "");

  if (Object.values(TABS).indexOf(anchor) === -1) {
    anchor = TABS.SLOWEST_TAB;
  }

  const [activeTab, setActiveTab] = useState(anchor);

  const handleSlowestTabClick = useCallback(() => {
    setActiveTab(TABS.SLOWEST_TAB);
  }, []);

  const handleFailedTabClick = useCallback(() => {
    setActiveTab(TABS.FAILED_TAB);
  }, []);

  return (
    <>
      <StyledTabControl>
        <TabButton
          active={activeTab === TABS.SLOWEST_TAB}
          href={`#${TABS.SLOWEST_TAB}`}
          onClick={handleSlowestTabClick}
          icon="heroicons/outline/clock"
        >
          Slowest tests
        </TabButton>

        <TabButton
          active={activeTab === TABS.FAILED_TAB}
          href={`#${TABS.FAILED_TAB}`}
          onClick={handleFailedTabClick}
          icon="cross-clipboard"
        >
          Failed tests
        </TabButton>
      </StyledTabControl>

      <TableWrapper className="TableWrapper">
        {activeTab === TABS.SLOWEST_TAB && (
          <RunTable
            endpoint={slowestTestsEndpoint}
            emptyState="slowest"
            tooltip={tooltip}
          />
        )}
        {activeTab === TABS.FAILED_TAB && (
          <RunTable
            endpoint={failedTestsEndpoint}
            emptyState="failed"
            tooltip={tooltip}
          />
        )}
      </TableWrapper>
    </>
  );
};

export default Tabs;

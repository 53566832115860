import Logger from "app/lib/Logger";
import EventEmitter from "eventemitter3";

// Ensure Websocket handler is initialised whenever LegacyDispatcher has been
// This is done because Websocket is an intermediary between the CableStore
// and LegacyDispatcher, and rarely called itself. This means that without
// being explicitly imported somewhere, it wouldn't pass events!
//
// It makes more sense for it to be initialised here, as LegacyDispatcher will
// always be initialised by its consumers, and thus things dependent upon
// the Websocket subsystem. In the future I believe Websocket should become a
// part of the LegacyDispatcher or the system that succeeds it.
import "./Websocket";

class LegacyDispatcher extends EventEmitter {
  on(events: any, callback: any) {
    return events.split(" ").map((evt) => super.on(evt, callback));
  }

  off(events: any, callback: any) {
    return events.split(" ").map((evt) => super.off(evt, callback));
  }

  emit(event: any, a1: any, a2: any, a3: any, a4: any, a5: any) {
    // @ts-expect-error - TS2339 - Property 'debug' does not exist on type 'LegacyDispatcher'.
    if (this.debug) {
      Logger.info("[Legacy Dispatcher]", event, a1, a2, a3, a4, a5);
    }
    return super.emit(event, a1, a2, a3, a4, a5);
  }
}

export default new LegacyDispatcher();

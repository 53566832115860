import { BaseStep, Type } from ".";
import {
  WaitStep as WaitStepSchema,
  StringWaitStep as StringWaitStepSchema,
} from "../schema";

export interface WaitStep extends BaseStep {
  type: Type.Wait;
}

export function isStringWaitStep(step: any): step is StringWaitStepSchema {
  return step === "wait";
}

export function isWaitStep(step: any): step is WaitStepSchema {
  return step.type === "wait" || step.wait !== undefined;
}

export function parseWaitStep(config: WaitStepSchema): WaitStep {
  return {
    uuid: "",
    dependencies: [],
    type: Type.Wait,
  };
}

export function parseStringWaitStep(config: StringWaitStepSchema): WaitStep {
  return {
    uuid: "",
    dependencies: [],
    type: Type.Wait,
  };
}

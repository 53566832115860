import { CommandStep } from "./schema";

export type Plugin = null | { [k: string]: unknown };
export type Plugins = [string, Plugin][];

export function normalizePlugins(plugins: CommandStep["plugins"]): Plugins {
  if (!plugins) {
    return [];
  }

  // Skip rendering non-array plugins.
  if (typeof plugins === "object" && !Array.isArray(plugins)) {
    return Object.entries(plugins) as Plugins;
  }

  return plugins.flatMap((plugin) => {
    if (typeof plugin === "string") {
      return [[plugin, {}]];
    }
    return Object.entries(plugin) as Plugins;
  });
}

interface DockerPlugin {
  image: string;
}

// Fetch buildkite-docker-plugin attributes from plugins
export const getDockerPlugin = (plugins: Plugins): DockerPlugin | null => {
  const plugin = plugins.find(([plugin]) => {
    return plugin.startsWith("docker#") || plugin === "docker";
  });

  if (!plugin) {
    return null;
  }

  return plugin[1] as unknown as DockerPlugin;
};

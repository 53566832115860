/**
 * @generated SignedSource<<58ca8046d7bb674b1371b76e5dd9e136>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type JobEventActorType = "AGENT" | "DISPATCH" | "SYSTEM" | "USER" | "%future added value";
export type JobEventSignalReason = "AGENT_REFUSED" | "AGENT_STOP" | "CANCEL" | "PROCESS_RUN_ERROR" | "SIGNATURE_REJECTED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type JobLogExitInformation_job$data = {
  readonly events?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly actor: {
          readonly type: JobEventActorType;
        };
        readonly exitStatus?: any;
        readonly signal?: string | null;
        readonly signalReason?: JobEventSignalReason | null;
      };
    } | null> | null;
  };
  readonly exitStatus?: string | null;
  readonly softFailed?: boolean;
  readonly uuid?: string;
  readonly " $fragmentType": "JobLogExitInformation_job";
};
export type JobLogExitInformation_job$key = {
  readonly " $data"?: JobLogExitInformation_job$data;
  readonly " $fragmentSpreads": FragmentRefs<"JobLogExitInformation_job">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "exitStatus",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "signal",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "signalReason",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "JobLogExitInformation_job",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "uuid",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "softFailed",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 60
            }
          ],
          "concreteType": "JobEventConnection",
          "kind": "LinkedField",
          "name": "events",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "JobEventEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "JobEventActor",
                      "kind": "LinkedField",
                      "name": "actor",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "type",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": (v1/*: any*/),
                      "type": "JobEventFinished",
                      "abstractKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": (v1/*: any*/),
                      "type": "JobEventCanceled",
                      "abstractKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": (v1/*: any*/),
                      "type": "JobEventTimedOut",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "events(first:60)"
        }
      ],
      "type": "JobTypeCommand",
      "abstractKey": null
    }
  ],
  "type": "Job",
  "abstractKey": "__isJob"
};
})();

(node as any).hash = "bc792afe34a85be4c1417f422be49b4d";

export default node;

/**
 * @generated SignedSource<<0b4be50e0a2fc778c310b2f10277fbc7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SuiteAccessLevels = "MANAGE_AND_READ" | "READ_ONLY" | "%future added value";
export type TeamSuiteCreateInput = {
  accessLevel?: SuiteAccessLevels | null;
  clientMutationId?: string | null;
  suiteID: string;
  teamID: string;
};
export type SuitesChooserMutation$variables = {
  input: TeamSuiteCreateInput;
};
export type SuitesChooserMutation$data = {
  readonly teamSuiteCreate: {
    readonly clientMutationId: string | null;
    readonly team: {
      readonly id: string;
      readonly suites: {
        readonly count: number;
      } | null;
    } | null;
  } | null;
};
export type SuitesChooserMutation = {
  response: SuitesChooserMutation$data;
  variables: SuitesChooserMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "TeamSuiteCreatePayload",
    "kind": "LinkedField",
    "name": "teamSuiteCreate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Team",
        "kind": "LinkedField",
        "name": "team",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TeamSuiteConnection",
            "kind": "LinkedField",
            "name": "suites",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "count",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SuitesChooserMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SuitesChooserMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9da32e6f4395d22dbb35393f55056c31",
    "id": null,
    "metadata": {},
    "name": "SuitesChooserMutation",
    "operationKind": "mutation",
    "text": "mutation SuitesChooserMutation(\n  $input: TeamSuiteCreateInput!\n) {\n  teamSuiteCreate(input: $input) {\n    clientMutationId\n    team {\n      id\n      suites {\n        count\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9ade0d46df12a5bc49d1a90a68390efd";

export default node;

import Icon from "app/components/shared/Icon";
import { Step } from "app/lib/pipeline";

import { StepLink } from "./StepLink";

export function WaitStepListItem({ step }: { step: Step }) {
  return (
    <StepLink uuid={step.uuid} className="justify-center py-1.5">
      <Icon icon="heroicons/outline/chevron-down" className="h-4 w-4" />
    </StepLink>
  );
}

import Select from "app/components/shared/Select";

const PlansSelect = ({ parentId, ...props }) => {
  const onOptionSelect = () => {
    // Dispatch a change event to parent element as a few files
    // are listening to the change event.
    //
    // Running it after 50ms so react can update the state before
    // other JS reads the data from select element
    setTimeout(() => {
      document.getElementById(parentId)?.dispatchEvent(new Event("change"));
    }, 50);
  };

  return <Select onChange={onOptionSelect} {...props} />;
};

export default PlansSelect;

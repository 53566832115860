import React from "react";
import { CSSTransition } from "react-transition-group";
import Icon from "app/components/shared/Icon";
import styled from "styled-components";

type DialogTheme = "light" | "dark";

const Backdrop = styled.div<{ theme: DialogTheme }>`
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0.9;
  position: absolute;

  ${(props) => {
    switch (props.theme) {
      case "light":
        return `background: var(--base-0);`;
      case "dark":
        return `background: var(--charcoal-800);`;
    }
  }}
`;

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
`;

const Box = styled.div`
  margin: auto;
  max-width: 100%;
  background: var(--base-0);
  position: relative;

  label {
    overflow-wrap: break-word;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: -15px;
  right: -15px;
  width: 30px;
  height: 30px;
  background: var(--base-0);

  &:focus {
    outline: none;
  }
`;

const Container = styled.div`
  width: 100vw;
  max-width: 100%;
  height: 100%;
  padding: 25px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  position: relative;

  &:after {
    display: block;
    flex: 0 0 auto;
    height: 40px;
    content: "";
  }
`;

interface Props {
  children: React.ReactNode;
  closeable: boolean;
  isOpen?: boolean;
  width?: number | string;
  dialogStyle?: React.CSSProperties;
  onRequestClose?: () => void;
  onHide?: () => void;
  theme?: DialogTheme;
  onClose?: () => void;
}

interface State {
  rendered: boolean;
  visible: boolean;
  firstElement: HTMLElement | null;
  lastElement: HTMLElement | null;
}

// eslint-disable-next-line react/require-optimization
class Dialog extends React.Component<Props, State> {
  static defaultProps = {
    closeable: true,
    isOpen: false,
    width: 500,
    dialogStyle: {},
    theme: "light",
  };

  dialogRef: React.RefObject<HTMLDivElement> = React.createRef();

  constructor(initialProps: Props) {
    super(initialProps);

    let rendered = false;
    let visible = false;

    if (initialProps.isOpen) {
      rendered = visible = true;
    }
    this.state = {
      rendered,
      visible,
      firstElement: null,
      lastElement: null,
    };
  }

  componentDidMount() {
    document.documentElement.addEventListener(
      "keydown",
      this.handleDocumentKeyDown,
      false,
    );
  }

  componentWillUnmount() {
    document.documentElement.removeEventListener(
      "keydown",
      this.handleDocumentKeyDown,
    );
    document.body.classList.remove("overflow-hidden");
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    // Opening the dialog
    if (!this.props.isOpen && nextProps.isOpen) {
      this.setState({ rendered: true }, () => {
        this.setState({ visible: true });
        this.updateFocus();
      });
    }

    // Closing the dialog
    if (this.props.isOpen && !nextProps.isOpen) {
      this.setState({ visible: false }, () => {
        // Give the animation some time to finish, then remove the dialog from
        // the DOM
        setTimeout(() => {
          this.setState(
            { rendered: false, firstElement: null, lastElement: null },
            () => {
              this.props.onHide && this.props.onHide();
            },
          );
          this.props.onClose && this.props.onClose();
        }, 150);
      });
    }
  }

  componentDidUpdate() {
    // This locks the body's scrolling whenever the dialog is _rendered_
    const action = this.state.rendered ? "add" : "remove";
    document.body.classList[action]("overflow-hidden");
  }

  /* this.props.children often contains a field with an autofocus: true attribute, 
  but this is overwritten by the existing focus on the button used to open the dialog.
  This function is necessary to ensure the focus shifts to the first interactive element
  inside the dialog.*/
  updateFocus = () => {
    // Find the ref to the currently open dialog
    const dialogComponent = this.dialogRef.current;

    // Find the first clickable element inside the dialog.
    // This may be a text field or the submit button
    if (dialogComponent) {
      const elements = dialogComponent.querySelectorAll(
        'select, textarea, input:not([type="hidden"]), button',
      );

      if (elements.length > 0) {
        this.setState({
          firstElement: elements[0] as HTMLElement,
          lastElement: elements[elements.length - 1] as HTMLElement,
        });

        (elements[0] as HTMLElement).focus();
      }
    }
  };

  maybeClose = (event: Event) => {
    event.preventDefault();
    if (typeof this.props.onRequestClose === "function") {
      this.props.onRequestClose();
    }
  };

  handleCloseClick = (event: React.MouseEvent) => {
    this.maybeClose(event.nativeEvent);
  };

  handleDocumentKeyDown = (event: KeyboardEvent) => {
    // Disallow closing via escape if dialog is not closeable
    if (!this.props.closeable) {
      return;
    }

    // Close the dialog on hitting the escape key
    if (this.state.visible && event.key === "Escape") {
      this.maybeClose(event);
    }

    // traps focus within the dialog component
    if (this.state.visible && event.key === "Tab") {
      const { firstElement, lastElement } = this.state;

      // If the first element is focused and the user tries to
      // tab backwards, focus the last element in the dialog
      if (event.shiftKey && document.activeElement === firstElement) {
        event.preventDefault();
        lastElement?.focus();
        // otherwise when the user is on the last element, move the focus to the first
      } else if (!event.shiftKey && document.activeElement === lastElement) {
        event.preventDefault();
        firstElement?.focus();
      }
    }
  };

  renderBackdrop() {
    if (!this.state.visible) {
      return;
    }

    return <Backdrop theme={this.props.theme} />;
  }

  renderCloseButton() {
    if (!this.props.closeable) {
      return;
    }

    return (
      <CloseButton
        className="circle shadow-subtle bold flex items-center cursor-pointer border border-white p0 hover-lime focus-lime no-title-tooltip"
        onClick={this.handleCloseClick}
        aria-label="Close dialog"
        title="Close dialog"
      >
        <Icon className="mx-auto" icon="close" title="Close" />
      </CloseButton>
    );
  }

  renderDialog() {
    const dialogStyle = {
      ...this.props.dialogStyle,
      width: this.props.width,
    };

    return (
      <Container
        className="flex flex-column"
        role="dialog"
        ref={this.dialogRef}
      >
        <Box className="rounded-3 shadow-subtle" style={dialogStyle}>
          {this.props.children}
          {this.renderCloseButton()}
        </Box>
      </Container>
    );
  }

  render() {
    if (!this.state.rendered) {
      return null;
    }

    return (
      <Wrapper>
        {this.renderBackdrop()}
        <CSSTransition
          in={this.state.visible}
          classNames="transition-slide-up"
          timeout={{
            enter: 150,
            exit: 300,
          }}
        >
          {this.renderDialog()}
        </CSSTransition>
      </Wrapper>
    );
  }
}

export default Dialog;

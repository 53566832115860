import styled from "styled-components";
import { StyledUnderline } from "app/components/analytics/shared/styles";
import Icon from "app/components/shared/Icon";
import Dropdown from "app/components/shared/Dropdown";
import { EnvironmentVariables } from "../type";

export const OPTIMAL_ENV_VARIABLES: (keyof EnvironmentVariables)[] = [
  "commit_sha",
  "branch",
  "url",
  "message",
];

const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 25px;

  ol {
    margin: 0;
    padding: 0;
  }

  li {
    margin-bottom: 20px;
  }

  // we are using css counters here the design has semi-bold numbered list items
  // but list-style-type doesn't let you style them
  counter-reset: env-counter;

  // this selector means we only apply the counter when there are two list items
  ol:has(li:nth-last-child(n + 2)) span:first-child:before {
    content: counter(env-counter, decimal) ".";
    counter-increment: env-counter;
    margin-right: 5px;
    font-weight: 500;
  }

  ol:has(li:nth-last-child(n + 2)) li > span:not(:first-child) {
    padding-left: 17px;
  }
`;

const MissingEnvVariables = ({
  commit_sha,
  branch,
  url,
  message,
}: EnvironmentVariables) => {
  return (
    <Dropdown width={400}>
      <div className="flex items-center">
        <Icon
          icon="exclamation-circle"
          style={{ fill: "none", width: 24, marginRight: 5 }}
        />
        <StyledUnderline className="cursor-pointer" role="button">
          Missing env variables
        </StyledUnderline>
      </div>
      <DropdownContainer>
        <ol>
          {!commit_sha && (
            <li className="flex flex-column">
              <span className="semi-bold">
                Flaky tests aren&apos;t being detected
              </span>
              <span>
                To enable flaky test detection, add{" "}
                <code className="ta-code">commit_sha</code> to your environment
                variables.
              </span>
            </li>
          )}
          {(!branch || !url || !message) && (
            <li className="flex flex-column">
              <span className="semi-bold">
                Test data in the UI could be richer
              </span>
              <span>
                To make your test data richer, consider adding the following env
                vars: {!branch && <code className="ta-code mr1">branch</code>}
                {!url && <code className="ta-code mr1">url</code>}
                {!message && <code className="ta-code mr1">message</code>}
              </span>
            </li>
          )}
        </ol>
        <a
          className="btn btn-default center"
          href="/docs/test-analytics/ci-environments"
        >
          More env variables in Docs
        </a>
      </DropdownContainer>
    </Dropdown>
  );
};

export default MissingEnvVariables;

/**
 * @generated SignedSource<<106a483025bdd95589a714d7bcff9fb0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type TeamPrivacy = "SECRET" | "VISIBLE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type Labels_team$data = {
  readonly privacy: TeamPrivacy;
  readonly " $fragmentType": "Labels_team";
};
export type Labels_team$key = {
  readonly " $data"?: Labels_team$data;
  readonly " $fragmentSpreads": FragmentRefs<"Labels_team">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Labels_team",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "privacy",
      "storageKey": null
    }
  ],
  "type": "Team",
  "abstractKey": null
};

(node as any).hash = "db8b92afd8e943337472913f9cbbb241";

export default node;

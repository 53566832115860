/* global jQuery */

import async from "async";

import CableStore from "app/stores/CableStore";

import Logger from "app/lib/Logger";
import LegacyDispatcher from "app/lib/legacyDispatcher";

// Takes websocket events (which are just event names and urls) - downloads the
// payload at the URL, then triggeres a dispatcher event with the event name and
// the contents of the URL.
//
// We don't send the payload through the cable because by the time it gets to the
// client, it's usually out of date, and our payloads are much bigger than
// Pusher (our previous websocket provider) would allow.

// NOTE: Websocket is generally initialsied by the legacyDispatcher. Please see
// the notes in legacyDispatcher.js about Websocket and its delicate position
// as an intermediary for push events.

class Websocket {
  queue: {
    push: (data: unknown, callback: (result: unknown) => unknown) => unknown;
  };

  constructor() {
    this.queue = async.queue((payload, callback) => {
      Logger.info(`[Ajax] GET ${payload.url}`);

      return jQuery.ajax({
        url: payload.url,
        data: payload.urlData,
        headers: {
          "X-Buildkite-Frontend-Version": BUILDKITE_FRONTEND_VERSION,
        } as {
          [key: string]: string;
        },
        success(result: any) {
          return callback(result);
        },
      });
    });

    // Payload for `websocket:event` has: {event,id,url}
    CableStore.on("websocket:event", (payload) => {
      Logger.info("[Buildkite.Websocket] websocket:event:", payload);

      // @ts-expect-error - TS2554 - Expected 6 arguments, but got 2.
      LegacyDispatcher.emit(
        payload.subevent || payload.event,
        Object.assign(
          {
            fetcher: (urlData) => {
              const newPayload = Object.assign({ urlData }, payload);

              return new Promise((fulfill: (result: unknown) => void) =>
                this.queue.push(newPayload, (result) => fulfill(result)),
              );
            },
          },
          payload,
        ),
      );
    });
  }

  onConnect(callback: () => unknown) {
    CableStore.on("online", () => callback());
  }
}

export default new Websocket();

import * as React from "react";

import Duration from "app/components/shared/Duration";
import classNames from "classnames";

type Props = {
  label: string;
  from: string;
  to?: string | null;
  variant?: "normal" | "highlight";
};

export default function WaitTime({
  label,
  from,
  to,
  variant = "normal",
}: Props) {
  return (
    <div
      className={classNames("job-stats", {
        "!text-orange-800 bg-orange-500/[0.18] px-1 rounded":
          variant === "highlight",
      })}
    >
      <span>
        {`${label} `}
        <Duration.Short from={from} to={to || undefined} />
      </span>
    </div>
  );
}

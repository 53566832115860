import Environment from "app/lib/relay/environment";
import { useState } from "react";
import {
  QueryRenderer,
  commitMutation,
  createRefetchContainer,
  graphql,
} from "react-relay";

import Panel from "app/components/shared/Panel";
import SectionLoader from "app/components/shared/SectionLoader";
import ShowMoreFooter from "app/components/shared/ShowMoreFooter";
import Chooser from "./SuitesChooser";
import Row from "./row";

const PAGE_SIZE = 10;

const SuitesPage = ({ team, relay, className }) => {
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);

  const reloadTable = (props) => {
    setLoading(true);
    relay.refetch(
      {
        teamID: team.id,
        pageSize,
        ...props,
      },
      null,
      () => {
        setLoading(false);
      },
      { force: true },
    );
  };

  const handleTeamSuiteChoose = () => {
    reloadTable();
  };

  const handleShowMoreSuites = () => {
    setLoading(true);
    const newPageSize = pageSize + PAGE_SIZE;
    setPageSize(newPageSize);

    reloadTable({ pageSize: newPageSize });
  };

  const handleTeamSuiteRemove = (teamSuite, force, callback) => {
    const environment = Environment.get();
    const input = { id: teamSuite.id, force };

    commitMutation(environment, {
      mutation: graphql`
        mutation SuitesTeamDeleteMutation($input: TeamSuiteDeleteInput!) {
          teamSuiteDelete(input: $input) {
            deletedTeamSuiteID
            team {
              id
              suites {
                count
              }
            }
          }
        }
      `,
      variables: { input },
      onCompleted: () => {
        callback(null);
        reloadTable();
      },
      onError: (error) => callback(error),
    });
  };

  const handleAccessLevelChange = (teamSuite, accessLevel, callback) => {
    const environment = Environment.get();
    const input = { id: teamSuite.id, accessLevel };

    commitMutation(environment, {
      mutation: graphql`
        mutation SuitesTeamAccessLevelChangeMutation(
          $input: TeamSuiteUpdateInput!
        ) {
          teamSuiteUpdate(input: $input) {
            teamSuite {
              accessLevel
            }
          }
        }
      `,
      variables: { input },
      onCompleted: () => {
        callback(null);
      },
      onError: (error) => callback(error),
    });
  };

  const renderSuites = () => {
    if (team.suites.edges.length > 0) {
      return team.suites.edges.map(({ node }) => (
        <Row
          key={node.id}
          teamSuite={node}
          onRemoveClick={handleTeamSuiteRemove}
          onAccessLevelChange={handleAccessLevelChange}
        />
      ));
    }

    return (
      <Panel.Section className="dark-gray">
        There are no test suites assigned to this team
      </Panel.Section>
    );
  };

  return (
    <div>
      <Chooser
        team={team}
        onChoose={handleTeamSuiteChoose}
        disabled={loading}
      />

      <Panel className={className}>
        {renderSuites()}
        <ShowMoreFooter
          connection={team.suites}
          label="suites"
          loading={loading}
          onShowMore={handleShowMoreSuites}
        />
      </Panel>
    </div>
  );
};

const TeamsSuitesPageContainer = createRefetchContainer(
  SuitesPage,
  {
    team: graphql`
      fragment Suites_team on Team
      @argumentDefinitions(pageSize: { type: "Int", defaultValue: 10 }) {
        ...SuitesChooser_team
        suites(first: $pageSize, order: NAME) {
          ...ShowMoreFooter_connection
          count
          edges {
            node {
              id
              accessLevel
              suite {
                id
                name
                slug
                url
              }
              permissions {
                teamSuiteUpdate {
                  allowed
                }
                teamSuiteDelete {
                  allowed
                }
              }
            }
          }
        }
      }
    `,
  },
  graphql`
    query Suites_organizationQuery($team: ID!, $pageSize: Int!) {
      team(slug: $team) {
        ...Suites_team @arguments(pageSize: $pageSize)
      }
    }
  `,
);

const TEAMS_SUITES_QUERY = graphql`
  query Suites_Query($team: ID!) {
    team(slug: $team) {
      ...Suites_team
    }
  }
`;

const SuitesPageRenderer = ({ team }) => (
  <QueryRenderer
    fetchPolicy="store-and-network"
    environment={Environment.get()}
    query={TEAMS_SUITES_QUERY}
    variables={{ team }}
    render={({ props, error }) => {
      if (error || !props) {
        return <SectionLoader />;
      }
      return <TeamsSuitesPageContainer {...props} />;
    }}
  />
);

export default SuitesPageRenderer;

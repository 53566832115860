import { Job } from "app/components/build/Header/pipeline/types/Job";
import { CommandJob } from "app/components/build/Header/pipeline/types/CommandJob";

import {
  JOB_STATES,
  FINISHED_STATES,
  RUNNING_STATES,
} from "app/constants/JobStates";
import { TriggerJob } from "app/components/build/Header/pipeline/types/TriggerJob";

export function isJobRunning(job: { state: string }) {
  return Object.prototype.hasOwnProperty.call(
    RUNNING_STATES,
    job.state.toUpperCase(),
  );
}

export function isJobFinished(job: { state: string }) {
  return Object.prototype.hasOwnProperty.call(
    FINISHED_STATES,
    job.state.toUpperCase(),
  );
}

export function hasJobFailed(job: Job) {
  switch (job.type) {
    case "script":
    case "trigger":
      return !job.passed;
    case "manual":
    case "waiter":
      return false;
  }
}

export function hasJobPassed(job: Job) {
  switch (job.type) {
    case "script":
    case "trigger":
      return job.passed;
    case "manual":
      return true;
    case "waiter":
      return job.state.toUpperCase() === JOB_STATES.FINISHED;
  }
}

// TODO: Remove state uppercasing so we have consistent job state types
export function jobTime(
  job: Pick<
    CommandJob | TriggerJob,
    "startedAt" | "finishedAt" | "canceledAt" | "timedOutAt"
  > & { state: string },
) {
  const { state, startedAt, finishedAt, canceledAt, timedOutAt } = job;
  const time: Record<string, string | null | undefined> = {};

  // job never stared, so no duration to show
  if (!startedAt) {
    return time;
  }

  time.from = startedAt;

  switch (state.toUpperCase()) {
    case JOB_STATES.FINISHED:
      time.to = finishedAt;
      break;

    case JOB_STATES.CANCELED:
    case JOB_STATES.CANCELING:
      time.to = canceledAt;
      break;

    case JOB_STATES.TIMED_OUT:
    case JOB_STATES.TIMING_OUT:
      time.to = timedOutAt;
      break;
  }

  return time;
}

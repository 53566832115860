import { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export default class NavigationButton extends PureComponent {
  static displayName = "Navigation.NavigationButton";

  static propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
    href: PropTypes.string,
    children: PropTypes.node,
    onClick: PropTypes.func,
  };

  render() {
    const props = {
      style: this.props.style,
      className: classNames(
        "btn black flex items-center flex-none semi-bold hover-purple focus-purple",
        this.props.className,
      ),
      onClick: this.props.onClick,
    };

    return (
      <a href={this.props.href} {...props}>
        {this.props.children}
      </a>
    );
  }
}

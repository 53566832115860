import { useMemo, useState } from "react";
import classNames from "classnames";
import { buildClientSchema, IntrospectionQuery } from "graphql";

import Button from "app/components/shared/Button";
import Editor from "./Editor";

type Props = {
  name: string;
  value: string;
  error: boolean;
  schema: IntrospectionQuery;
};

export default function (props: Props) {
  const [query, setQuery] = useState(props.value);

  const schema = useMemo(() => buildClientSchema(props.schema), [props.schema]);

  async function handlePrettify(event) {
    event.preventDefault();

    const formattedQuery = await import("app/lib/prettier").then((modules) =>
      modules.prettifyGraphqlQuery(query),
    );

    setQuery(formattedQuery);
  }

  return (
    <>
      <p className="mt-0 mb-1">
        For more detailed examples of common tasks, checkout the{" "}
        <a
          href="https://buildkite.com/docs/apis/graphql/graphql-cookbook"
          target="_blank"
          className="text-purple-600 hover:text-purple-600 hover:underline"
          rel="noreferrer"
        >
          GraphQL API cookbook
        </a>
        .
      </p>

      <div
        className={classNames("relative p-1 border rounded-md", {
          "border-[#D0A9A7]": props.error,
          "border-[#ccc] focus-within:border-[#888]": !props.error,
        })}
      >
        <div className="overflow-x-auto">
          <Editor
            name={props.name}
            schema={schema}
            value={query}
            onChange={setQuery}
          />
        </div>

        <Button
          onClick={handlePrettify}
          className="z-10 absolute right-2 bottom-2 cursor-pointer"
          theme="small"
          type="button"
        >
          Prettier
        </Button>
      </div>
    </>
  );
}

// Keeps track of data

import string from "app/lib/string";

export default class Database {
  // Keys should always be camelCased. This goes through the object
  // and recursivly camelCases keys.
  static parse(data) {
    const dataType = typeof data;

    if (Array.isArray(data)) {
      return data.map((datum) => Database.parse(datum));
    } else if (data && (dataType === "object" || dataType === "function")) {
      const parsed = {};

      for (const key of Object.keys(data)) {
        const value = data[key];
        parsed[string.camelCase(key)] = Database.parse(value);
      }

      return parsed;
    }

    return data;
  }

  constructor(seed) {
    this.database = {};
    this.load(seed);
  }

  // Loads data into the database
  load(data) {
    // If we're loading an array of records, laod each one individually
    // and and then return the results.
    if (Array.isArray(data)) {
      return data.map((record) => this._load(record));
    }

    return this._load(data);
  }

  // Updates data in the database
  update(data) {
    if (this.find(data.id)) {
      return this.load(data);
    }

    return false;
  }

  // Return all records
  all() {
    return Object.values(this.database);
  }

  // Find a record based on it's id.
  find(id) {
    return this.database[this._normalizeKey(id)];
  }

  // Returns true if the id exists in the database
  exists(id) {
    return !!this.database[this._normalizeKey(id)];
  }

  // Adds the data to the database using the id for a index.
  _load(data) {
    return (this.database[this._normalizeKey(data.id)] = Database.parse(data));
  }

  _normalizeKey(id) {
    return String(id);
  }
}

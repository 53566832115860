import Icon from "app/components/shared/Icon";
import { twMerge } from "tailwind-merge";

interface Props {
  branchName?: string;
  branchPath?: string;
  className?: string;
}

export function Branch({ branchName, branchPath, className }: Props) {
  return (
    <a
      className={twMerge(
        "color-inherit hover-color-inherit font-mono flex items-center",
        className,
      )}
      href={branchPath}
    >
      <Icon
        icon="custom/outline/14x/branch"
        style={{ height: 14, width: 14 }}
      />
      {branchName}
    </a>
  );
}

import { useLayoutEffect, useRef, useState, FC, CSSProperties } from "react";
import { motion } from "framer-motion";

interface TransitionProps {
  style?: CSSProperties;
}

/**
 * A framer-motion transition with support for variable height children.
 */
const SlideDownTransition: FC<TransitionProps> = (props) => {
  const [height, setHeight] = useState(0);
  const elementRef = useRef<HTMLDivElement>(null);

  const calculateHeight = () => {
    setHeight(elementRef.current?.offsetHeight || 0);
  };

  useLayoutEffect(() => {
    calculateHeight();
  }, []);

  const item = <div ref={elementRef}>{props.children}</div>;

  // We need the element height to smoothly animate the slide down transition.
  //
  // If the height isn't available, render the item without the transition
  // to calculate the height for the next "proper" render via useLayoutEffect.
  if (!height) {
    return item;
  }

  return (
    <motion.div
      style={props.style}
      initial={{ marginTop: -height, opacity: 0, zIndex: 0 }}
      animate={{ marginTop: 0, opacity: 1, zIndex: props.style?.zIndex || 1 }}
      exit={{ marginTop: -height, opacity: 0, zIndex: 0 }}
      transition={{
        opacity: { duration: 0.1 },
        marginTop: { type: "spring", damping: 25, stiffness: 280 },
      }}
    >
      {item}
    </motion.div>
  );
};

export default SlideDownTransition;

/**
 * @generated SignedSource<<92e510b4f8dff1ec4ed2396e7d2987c0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type contextSection_auditEvent$data = {
  readonly context: {
    readonly __typename: "AuditAPIContext";
    readonly requestApiAccessTokenUuid: string | null;
    readonly requestIpAddress: string | null;
    readonly requestUserAgent: string | null;
  } | {
    readonly __typename: "AuditAgentAPIContext";
    readonly agentUuid: string | null;
    readonly authenticationType: string | null;
    readonly connectionState: string | null;
    readonly organizationUuid: string | null;
    readonly requestIpAddress: string | null;
    readonly sessionIpAddress: string | null;
  } | {
    readonly __typename: "AuditWebContext";
    readonly requestIpAddress: string | null;
    readonly requestUserAgent: string | null;
    readonly sessionCreatedAt: any | null;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
  readonly " $fragmentType": "contextSection_auditEvent";
};
export type contextSection_auditEvent$key = {
  readonly " $data"?: contextSection_auditEvent$data;
  readonly " $fragmentSpreads": FragmentRefs<"contextSection_auditEvent">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "requestIpAddress",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "requestUserAgent",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "contextSection_auditEvent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "context",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sessionCreatedAt",
              "storageKey": null
            }
          ],
          "type": "AuditWebContext",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "requestApiAccessTokenUuid",
              "storageKey": null
            }
          ],
          "type": "AuditAPIContext",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "agentUuid",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "organizationUuid",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sessionIpAddress",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "connectionState",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "authenticationType",
              "storageKey": null
            },
            (v0/*: any*/)
          ],
          "type": "AuditAgentAPIContext",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AuditEvent",
  "abstractKey": null
};
})();

(node as any).hash = "7490182199f9d6e4d6de42801d2d1ea2";

export default node;

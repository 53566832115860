/**
 * @generated SignedSource<<f6c89333f025b10c186f8a0c3dae71e2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Tag_tag$data = {
  readonly label: string;
  readonly " $fragmentType": "Tag_tag";
};
export type Tag_tag$key = {
  readonly " $data"?: Tag_tag$data;
  readonly " $fragmentSpreads": FragmentRefs<"Tag_tag">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Tag_tag",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    }
  ],
  "type": "PipelineTag",
  "abstractKey": null
};

(node as any).hash = "bc8a9a38b5cf8cb381d0ad2c9fb8acc9";

export default node;

import { useState } from "react";
import { twMerge } from "tailwind-merge";

import copySVG from "./CopySVG";
import tickSVG from "./TickSVG";

type Props = {
  element: string;
  className?: string;
};

const CopyTextButton = ({ element, className }: Props) => {
  const [copied, setCopied] = useState(false);

  const copy = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    const el = document.querySelector(element);
    if (!el) {
      throw new Error("Element not found");
    }

    event.persist();

    // @ts-expect-error - TS2339 - Property 'innerText' does not exist on type 'Element'.
    navigator.clipboard.writeText(el.innerText || "");
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
      // @ts-expect-error - TS2339 - Property 'blur' does not exist on type 'EventTarget'.
      event.target.blur();
    }, 2000);
  };

  return (
    <button
      className={twMerge("copy-btn", className)}
      aria-label={copied ? "Copied" : "Copy"}
      onClick={copy}
    >
      {copied ? tickSVG : copySVG}
    </button>
  );
};

export default CopyTextButton;

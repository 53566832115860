/**
 * @generated SignedSource<<71aec263b6b5ca50f5c68485ba56df72>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AgentUpgradeBanner_agent$data = {
  readonly version: string | null;
  readonly " $fragmentType": "AgentUpgradeBanner_agent";
};
export type AgentUpgradeBanner_agent$key = {
  readonly " $data"?: AgentUpgradeBanner_agent$data;
  readonly " $fragmentSpreads": FragmentRefs<"AgentUpgradeBanner_agent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AgentUpgradeBanner_agent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "version",
      "storageKey": null
    }
  ],
  "type": "Agent",
  "abstractKey": null
};

(node as any).hash = "adbf2dd8ed81adc48896bc4e1d3e23ad";

export default node;

import React from "react";
import styled from "styled-components";

interface Props {
  title: string;
  divider?: boolean;
  image?: React.ReactNode;
  imageColor?: string;
  backgroundColor?: string;
  imageShape?: "circle" | "square";
}

const Container = styled.div`
  --gap: 0.8em;

  display: grid;
  column-gap: var(--gap);
  grid-template-rows: auto 1fr;
  grid-template-columns: auto 1fr;
  grid-template-areas:
    ". divider"
    "image title"
    "image content";

  font-size: 1em;
`;

const Image = styled.div`
  grid-area: image;
  border-radius: 4px;
  height: 40px;
  width: 40px;
  align-items: center;
  justify-content: center;
  display: flex;

  ${({ imageShape }: Props) =>
    imageShape === "circle" && `border-radius: 999px;`}

  ${({ imageColor }: Props) => imageColor && `color: ${imageColor};`}
  ${({ backgroundColor }: Props) =>
    backgroundColor && `background-color: ${backgroundColor};`}
`;

const Title = styled.div`
  grid-area: title;
  color: var(--charcoal-700);
  font-weight: 500;
`;

const Content = styled.div`
  grid-area: content;
  color: var(--charcoal-300);
`;

const Divider = styled.hr`
  grid-area: divider;
  margin: 0;
  height: 0;
  margin-top: var(--gap);
  padding-bottom: var(--gap);
`;

const FeedItem: React.FC<Props> = (props) => (
  <Container>
    {props.divider && <Divider />}
    <Image {...props}>{props.image}</Image>
    <Title>{props.title}</Title>
    <Content>{props.children}</Content>
  </Container>
);

export default FeedItem;

import logoSrc from "app/images/logo-full-dark-backgrounds.svg";
import markSrc from "app/images/mark.svg";
import {
  Organization,
  PublicNavigationBottomMenu,
  PublicNavigationTopMenu,
} from "../shared/PublicNavigationMenu";

const AnonymousNavigation = ({
  organization,
}: {
  organization: Organization | null;
}) => {
  return (
    <nav
      className="border-bottom border-gray bg-silver"
      style={{ marginBottom: 25 }}
      data-tag={true}
    >
      <div className="nav-container">
        <div className="flex flex-stretch" style={{ height: 45 }}>
          <div className="flex flex-auto">
            <a
              href="/"
              className="btn black hover-lime focus-lime flex items-center flex-none px3 hover-faded-children"
              style={{ paddingLeft: 0 }}
            >
              <img
                style={{ marginBottom: -3 }}
                alt="Buildkite"
                className="xs-hide"
                src={logoSrc}
                width="102"
                height="19"
              />
              <img
                alt="Buildkite"
                className="sm-hide md-hide lg-hide"
                src={markSrc}
                width="40"
                height="40"
              />
            </a>
            {organization && (
              <PublicNavigationTopMenu
                organization={{
                  name: organization.name,
                  iconUrl: organization.iconUrl,
                  slug: organization.slug,
                  isPublic: organization.isPublic,
                }}
                borderLeft="1px solid lightgrey"
              />
            )}
          </div>

          <div className="flex">
            <Anchor href="/login" title="Log In" />
            <Anchor href="/signup" title="Sign Up" />
            <Anchor href="/" title="Learn More…" style={{ paddingRight: 0 }} />
          </div>
        </div>
      </div>
      {organization && (
        <PublicNavigationBottomMenu
          organization={{
            name: organization.name,
            iconUrl: organization.iconUrl,
            slug: organization.slug,
            isPublic: organization.isPublic,
          }}
        />
      )}
    </nav>
  );
};

type Anchorprops = {
  title: string;
  href: string;
  style?: object;
};

const Anchor = ({ title, href, style }: Anchorprops) => {
  return (
    <a
      className="btn black hover-lime focus-lime flex items-center flex-none"
      title={title}
      href={href}
      style={style}
    >
      {title}
    </a>
  );
};

export default AnonymousNavigation;

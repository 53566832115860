import styled from "styled-components";

import Emojify from "app/components/shared/Emojify";

type Props = {
  heading: string;
  subheading?: string;
  emoji?: string;
} & (
  | { url?: undefined; urlText?: undefined }
  | { url: string; urlText: string }
);

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  background: var(--slate-100);
  background-size: cover;
  border-radius: 8px;
  padding: 50px;
`;

const StateWrapper = styled.div`
  text-align: center;
  max-width: 400px;

  h2 {
    margin: 20px 0 0;
    color: var(--charcoal-700);
    font-weight: 500;
  }

  p {
    margin: 10px 0 0;
    color: var(--charcoal-700);
    font-weight: 400;
    white-space: pre-wrap;
  }
`;

const ErrorState = ({ heading, subheading, emoji, url, urlText }: Props) => (
  <Wrapper>
    <StateWrapper>
      {emoji && (
        <Emojify text={emoji} style={{ fontSize: 45 }} title={heading} />
      )}

      <h3 className="text-center text-lg">{heading}</h3>

      {subheading && (
        <p>
          {subheading}{" "}
          {url && (
            <a
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              className="bold color-inherit"
            >
              {urlText}.
            </a>
          )}
        </p>
      )}
    </StateWrapper>
  </Wrapper>
);

export default ErrorState;

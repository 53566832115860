import FeedItem from "./FeedItem";
import Card from "./Card";
import { Role } from "../form";
import SlideDownTransition from "./SlideDownTransition";
import { AnimatePresence } from "framer-motion";
import Icon from "app/components/shared/Icon";

type Quote = {
  author: string;
  quote: string;
};

// A mapping of roles to quotes
const mapping: { [key in Role]: Quote } = {
  [Role.Platformer]: {
    author: "Senior DevOps Manager, eCommerce marketplace",
    quote:
      "Buildkite empowers teams to easily set up their own workflows and write their own plugins.",
  },
  [Role.Architect]: {
    author: "Tech Leader, 2,000+ engineers",
    quote:
      "Buildkite helped us improve our infrastructure metrics and now we’re running hundreds of deploys every day.",
  },
  [Role.Creator]: {
    author: "Fullstack Engineer, Devtool SaaS company",
    quote:
      "Buildkite provides a simple, clean UI for all our pipelines in addition to having great documentation and an easy to use API.",
  },
  [Role.Consumer]: {
    author: "Application Developer, Scaleup SaaS company",
    quote:
      "Buildkite has a great user experience. The UI is really easy to use and helps me quickly identify and fix errors.",
  },
  [Role.TestChampion]: {
    author: "Senior QA Engineer, Enterprise SaaS company",
    quote:
      "Test Analytics has significantly increased our reliability. It helps us view how tests are running across the teams and saves us a lot of time with triaging.",
  },
  [Role.Other]: {
    author: "Application Developer, Scaleup SaaS company",
    quote:
      "Buildkite has a great user experience. The UI is really easy to use and helps me quickly identify and fix errors.",
  },
};

export default function Quote({ role }: { role: string }) {
  if (!Object.values<string>(Role).includes(role)) {
    role = Role.Other;
  }
  const { author, quote } = mapping[role as Role];

  const icon = <Icon icon="quote" />;

  return (
    <Card title="Built for people like you">
      <AnimatePresence initial={false}>
        <SlideDownTransition key={role}>
          <FeedItem backgroundColor="#ffedf9" title={author} image={icon}>
            {quote}
          </FeedItem>
        </SlideDownTransition>
      </AnimatePresence>
    </Card>
  );
}

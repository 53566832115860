import { Build } from "app/stores/BuildShowStore";
import { BuildStates } from "app/constants/BuildStates";

interface Theme {
  primaryColor: string;
  secondaryColor: string;
  tertiaryColor: string;
}

const FAILED: Theme = {
  primaryColor: "var(--red-500)",
  secondaryColor: "var(--red-300)",
  tertiaryColor: "var(--red-100)",
};

const PASSED: Theme = {
  primaryColor: "var(--green-500)",
  secondaryColor: "var(--green-400)",
  tertiaryColor: "var(--green-100)",
};

const RUNNING: Theme = {
  primaryColor: "var(--orange-500)",
  secondaryColor: "var(--orange-500)",
  tertiaryColor: "var(--orange-100)",
};

const PENDING: Theme = {
  primaryColor: "var(--slate-500)",
  secondaryColor: "var(--slate-300)",
  tertiaryColor: "var(--slate-100)",
};

const BUILD_COLORS = new Map<BuildStates, Theme>([
  ["creating", RUNNING],
  ["started", RUNNING],
  ["running", RUNNING],
  ["passed", PASSED],
  ["failed", FAILED],
  ["failing", FAILED],
  ["soft_failed", FAILED],
]);

export const getBuildColor = (build: {
  state: BuildStates;
  blockedState?: string;
}) => {
  if (build.state === "blocked" && build.blockedState) {
    return BUILD_COLORS.get(build.blockedState as BuildStates) || PASSED;
  }
  return BUILD_COLORS.get(build.state) || PENDING;
};

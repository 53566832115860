/**
 * @generated SignedSource<<1ff439a594bb4a0422c21698f6c4a127>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type OrganizationMemberSSOModeEnum = "OPTIONAL" | "REQUIRED" | "%future added value";
export type TeamMemberRole = "MAINTAINER" | "MEMBER" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type row_teamMember$data = {
  readonly id: string;
  readonly organizationMember: {
    readonly security?: {
      readonly twoFactorEnabled: boolean;
    };
    readonly sso?: {
      readonly mode: OrganizationMemberSSOModeEnum | null;
    };
    readonly uuid: string;
  } | null;
  readonly permissions: {
    readonly teamMemberDelete: {
      readonly allowed: boolean;
    } | null;
    readonly teamMemberUpdate: {
      readonly allowed: boolean;
    } | null;
  };
  readonly role: TeamMemberRole;
  readonly team: {
    readonly organization: {
      readonly slug: string;
    } | null;
  } | null;
  readonly user: {
    readonly avatar: {
      readonly url: string;
    };
    readonly bot: boolean;
    readonly email: string;
    readonly name: string;
  } | null;
  readonly " $fragmentType": "row_teamMember";
};
export type row_teamMember$key = {
  readonly " $data"?: row_teamMember$data;
  readonly " $fragmentSpreads": FragmentRefs<"row_teamMember">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "allowed",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "includeSensitiveInfo"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "row_teamMember",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Avatar",
          "kind": "LinkedField",
          "name": "avatar",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "url",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bot",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "role",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Team",
      "kind": "LinkedField",
      "name": "team",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "slug",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TeamMemberPermissions",
      "kind": "LinkedField",
      "name": "permissions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Permission",
          "kind": "LinkedField",
          "name": "teamMemberUpdate",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Permission",
          "kind": "LinkedField",
          "name": "teamMemberDelete",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrganizationMember",
      "kind": "LinkedField",
      "name": "organizationMember",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "uuid",
          "storageKey": null
        },
        {
          "condition": "includeSensitiveInfo",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "OrganizationMemberSSO",
              "kind": "LinkedField",
              "name": "sso",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "mode",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "OrganizationMemberSecurity",
              "kind": "LinkedField",
              "name": "security",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "twoFactorEnabled",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TeamMember",
  "abstractKey": null
};
})();

(node as any).hash = "88e30df468d20c2f1244ec20fbce97d5";

export default node;

import { CreateBuildDialog } from "app/components/pipeline/CreateBuildDialog";
import React, { useEffect, useState } from "react";

// NOTE: this component can be deleted when `HOTWIRE_NEW_BUILD_DIALOG` is cleaned up
export function CreateBuildDialogButton({
  className,
  ...props
}: { className?: string } & Exclude<
  React.ComponentProps<typeof CreateBuildDialog>,
  "isOpen" | "onRequestClose"
>) {
  const [open, setOpen] = useState(false);

  function handleOpen() {
    setOpen(true);

    history.pushState(null, "", "#new");
  }

  function handleClose() {
    setOpen(false);

    history.pushState(null, "", location.pathname + location.search);
  }

  function handleHashChange() {
    setOpen(window.location.hash.startsWith("#new"));
  }

  useEffect(() => {
    handleHashChange();

    window.addEventListener("hashchange", handleHashChange);

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, [window.location.hash]);

  return (
    <>
      <button onClick={handleOpen} className={className} data-testid="newBuild">
        New Build
      </button>

      <CreateBuildDialog
        {...props}
        isOpen={open}
        onRequestClose={handleClose}
      />
    </>
  );
}

import * as React from "react";
import { createFragmentContainer, graphql } from "react-relay";
import YAML from "js-yaml";

import {
  AttributeTable,
  AttributeTableRow,
} from "app/components/shared/AttributeTable";
import Emojify from "app/components/shared/Emojify";
import FriendlyTime from "app/components/shared/FriendlyTime";
import Icon from "app/components/shared/Icon";

import AgentStore from "app/stores/AgentStore";

import JobEvent from "../JobEvent";

type Props = {
  job: any;
  previousTimestamp: string | null | undefined;
};

function TableValue({ children }: { children: React.ReactNode }) {
  return <code className="block dark-gray monospace">{children}</code>;
}

function TableEmptyValue() {
  return <TableValue>-</TableValue>;
}

function MatrixConfiguration({ matrix }: { matrix: string }) {
  const matrixConfig = JSON.parse(matrix);
  const matrixKeys = Object.keys(matrixConfig);

  let value = matrixConfig;

  if (matrixKeys.length === 1 && matrixKeys[0] === "") {
    value = matrixConfig[matrixKeys[0]];
  }

  return (
    <pre style={{ fontFamily: "inherit" }}>
      <code style={{ fontFamily: "inherit" }}>{YAML.dump(value)}</code>
    </pre>
  );
}

function agentQueryRulesWithoutQueue(queries: any) {
  return queries.filter((query) => !query.includes("queue="));
}

function clusterUrl(cluster: any) {
  return `/organizations/${cluster.organization.slug}/clusters/${cluster.uuid}`;
}

function clusterQueueUrl(clusterQueue: any) {
  const cluster = clusterQueue.cluster;

  return `/organizations/${cluster.organization.slug}/clusters/${cluster.uuid}/queues/${clusterQueue.uuid}`;
}

function JobEventCreated({ job, previousTimestamp }: Props) {
  const normalizedQuery = AgentStore.normalizedQuery(job.agentQueryRules);
  const agentQueryRules = job.cluster
    ? agentQueryRulesWithoutQueue(normalizedQuery)
    : normalizedQuery;

  const truncatedDependenciesCount =
    job.step?.dependencies.count - job.step?.dependencies.edges.length;

  return (
    <JobEvent timestamp={job.createdAt} previousTimestamp={previousTimestamp}>
      <JobEvent.Label>Job Created</JobEvent.Label>
      <JobEvent.Content>
        <AttributeTable>
          {job.cluster ? (
            <AttributeTableRow title="Cluster">
              <TableValue>
                <a href={clusterUrl(job.cluster)}>
                  <Emojify text={job.cluster.name} />
                </a>
              </TableValue>
            </AttributeTableRow>
          ) : null}
          {job.cluster ? (
            <>
              <AttributeTableRow title="Queue">
                <TableValue>
                  <a href={clusterQueueUrl(job.clusterQueue)}>
                    {job.clusterQueue.key}
                  </a>
                  {job.clusterQueue.dispatchPaused ? (
                    <span data-testid="queue-paused-icon">
                      <Icon
                        icon="paused-large"
                        className="black ml1"
                        style={{ width: 20, height: 20 }}
                      />
                    </span>
                  ) : null}
                </TableValue>
              </AttributeTableRow>
              <>
                {job.clusterQueue.dispatchPaused ? (
                  <tr>
                    <th />
                    <td className="pb1 align-top">
                      <span className="dark-gray">Queue paused </span>
                      <FriendlyTime
                        clickable={true}
                        value={job.clusterQueue.dispatchPausedAt}
                        className="dark-gray"
                      />
                      <span className="dark-gray">
                        {" "}
                        by {job.clusterQueue.dispatchPausedBy.name}
                      </span>
                    </td>
                  </tr>
                ) : null}
                {job.clusterQueue.dispatchPaused &&
                job.clusterQueue.dispatchPausedNote ? (
                  <AttributeTableRow title="">
                    <span className="dark-gray">{`"${job.clusterQueue.dispatchPausedNote}"`}</span>
                  </AttributeTableRow>
                ) : null}
              </>
            </>
          ) : null}
          {job.priority?.number !== 0 ? (
            <AttributeTableRow title="Priority">
              <TableValue>{job.priority.number}</TableValue>
            </AttributeTableRow>
          ) : null}
          <AttributeTableRow title="Agent Targeting Rules">
            {agentQueryRules.length === 0 ? (
              <TableEmptyValue />
            ) : (
              agentQueryRules.map((query) => (
                <TableValue key={query}>{query}</TableValue>
              ))
            )}
          </AttributeTableRow>
          {job.concurrency ? (
            <>
              <AttributeTableRow title="Concurrency Group">
                <TableValue>{job.concurrency.group}</TableValue>
              </AttributeTableRow>
              <AttributeTableRow title="Concurrency Limit">
                <TableValue>{job.concurrency.limit}</TableValue>
              </AttributeTableRow>
            </>
          ) : null}
          {job.matrix ? (
            <AttributeTableRow title="Matrix Configuration">
              <TableValue>
                <MatrixConfiguration matrix={job.matrix} />
              </TableValue>
            </AttributeTableRow>
          ) : null}
          {job.dependencies && (
            <AttributeTableRow title="Dependencies">
              {job.dependencies.map((dep) => (
                <div key="dep">{dep}</div>
              ))}
            </AttributeTableRow>
          )}
          {job.step?.key ? (
            <AttributeTableRow title="Step Key">
              {/* @ts-expect-error - TS2322 - Type '{ children: any; title: any; }' is not assignable to type 'IntrinsicAttributes & { children: ReactNode; }'. */}
              <TableValue title={job.step.uuid}>{job.step.key}</TableValue>
            </AttributeTableRow>
          ) : null}
          {job.step?.dependencies.edges.length ? (
            <AttributeTableRow title="Step Dependencies">
              {job.step.dependencies.edges.map(({ node }) => (
                <div key={node.uuid}>
                  <TableValue>{node.key}</TableValue>
                </div>
              ))}

              {truncatedDependenciesCount > 0 && (
                <p className="mt-1 mb-0">
                  {truncatedDependenciesCount} more dependencies aren&apos;t
                  displayed.
                  <br />
                  Use the{" "}
                  <a
                    href="https://buildkite.com/docs/apis/graphql-api"
                    target="_blank"
                    rel="noreferrer"
                  >
                    GraphQL API ↗
                  </a>{" "}
                  to view all dependencies for this step.
                </p>
              )}
            </AttributeTableRow>
          ) : null}
          {job.step?.conditional ? (
            <AttributeTableRow title="Step Conditional">
              <TableValue>{job.step.conditional}</TableValue>
            </AttributeTableRow>
          ) : null}
        </AttributeTable>
      </JobEvent.Content>
    </JobEvent>
  );
}

export default createFragmentContainer(JobEventCreated, {
  job: graphql`
    fragment JobEventCreated_job on JobTypeCommand {
      ... on JobTypeCommand {
        uuid
        createdAt
        priority {
          number
        }
        agentQueryRules
        cluster {
          name
          uuid
          organization {
            slug
          }
        }
        clusterQueue {
          key
          uuid
          dispatchPaused
          dispatchPausedAt
          dispatchPausedBy {
            name
          }
          dispatchPausedNote
          cluster {
            name
            uuid
            organization {
              slug
            }
          }
        }
        concurrency {
          group
          limit
        }
        matrix
        step {
          uuid
          key
          conditional
          dependencies(first: 30) {
            count
            edges {
              node {
                uuid
                key
              }
            }
          }
        }
      }
    }
  `,
});

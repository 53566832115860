/* eslint-disable react/display-name */

import { useState } from "react";

import Button from "app/components/shared/Button";
import Emojify from "app/components/shared/Emojify";
import Panel from "app/components/shared/Panel";

import permissions from "app/lib/permissions";
import FlashesStore from "app/stores/FlashesStore";

import AccessLevel from "./access-level";

const Row = ({ teamSuite, onRemoveClick, onAccessLevelChange }) => {
  const [savingNewAccessLevel, setSavingNewAccessLevel] = useState(null);
  const [removing, setRemoving] = useState(null);

  const suite = teamSuite.suite;

  const handleAccessLevelChange = (accessLevel) => {
    setSavingNewAccessLevel(accessLevel);

    onAccessLevelChange(teamSuite, accessLevel, (error) => {
      setSavingNewAccessLevel(null);

      if (error) {
        FlashesStore.flash(FlashesStore.ERROR, error);
      }
    });
  };

  const handleSuiteRemove = (evt) => {
    if (
      confirm(
        "Remove the suite from this team?\nNote: All flaky test assignments for this team and suite will be permanently deleted.",
      )
    ) {
      evt.preventDefault();

      performSuiteRemove(false);
    }
  };

  const performSuiteRemove = (force) => {
    setRemoving(true);

    onRemoveClick(teamSuite, force, (error) => {
      setRemoving(false);

      if (error) {
        if (
          !force &&
          error.source &&
          error.source.type === "must_force_error"
        ) {
          if (
            confirm(
              error.source.errors[0].message +
                "\n\nAre you sure you want to remove this suite from this team?",
            )
          ) {
            performSuiteRemove(true);
          }
        } else {
          FlashesStore.flash(FlashesStore.ERROR, error);
        }
      }
    });
  };

  const Actions = () =>
    permissions(teamSuite.permissions).collect(
      {
        always: true,
        render: (idx) => (
          <AccessLevel
            key={idx}
            teamSuite={teamSuite}
            onAccessLevelChange={handleAccessLevelChange}
            saving={savingNewAccessLevel}
          />
        ),
      },
      {
        allowed: "teamSuiteDelete",
        render: (idx) => (
          <Button
            key={idx}
            loading={removing ? "Removing…" : false}
            className="ml3"
            onClick={handleSuiteRemove}
          >
            Remove
          </Button>
        ),
      },
    );

  return (
    <Panel.Row>
      <div className="flex">
        <a
          className="truncate semi-bold black hover-lime hover-color-inherit-parent text-decoration-none"
          href={suite.url}
          title={suite.name}
        >
          <Emojify text={suite.name} />
        </a>
      </div>
      <Panel.RowActions className="ml2">
        <Actions />
      </Panel.RowActions>
    </Panel.Row>
  );
};

export default Row;

import { createFragmentContainer, graphql } from "react-relay";

import { Section, SectionHeading } from "./shared";

type Props = {
  auditEvent: {
    subject: {
      name?: string;
      type?: string;
      uuid?: string;
      node?: {
        name?: string;
      };
    };
  };
};

const SubjectType = ({ type }: { type: string | null | undefined }) => [
  <dt className="mt1 dark-gray" key="type-title">
    Subject Type
  </dt>,
  <dd className="ml0 monospace" key="type-definition">
    {type}
  </dd>,
];

const SubjectName = ({
  name,
  node,
}: {
  name: string | null | undefined;
  node: any | null | undefined;
}) => {
  const renderedName = (node && node.name) || name;

  if (!renderedName) {
    return null;
  }

  return [
    <dt className="mt1 dark-gray" key="name-title">
      Subject Name
    </dt>,
    <dd className="ml0" key="name-definition">
      {renderedName}
    </dd>,
  ];
};

const SubjectUuid = ({ uuid }: { uuid: string | null | undefined }) =>
  uuid
    ? [
        <dt className="mt1 dark-gray" key="uuid-title">
          Subject UUID
        </dt>,
        <dd className="ml0 monospace" key="uuid-definition">
          {uuid}
        </dd>,
      ]
    : null;

const AuditLogSubjectSection = ({ auditEvent: { subject } }: Props) => (
  <Section>
    <SectionHeading className="m0 mb2">Subject</SectionHeading>
    <dl className="m0">
      {/* @ts-expect-error - TS2786 - 'SubjectType' cannot be used as a JSX component. */}
      <SubjectType type={subject.type} />
      {/* @ts-expect-error - TS2786 - 'SubjectName' cannot be used as a JSX component. */}
      <SubjectName name={subject.name} node={subject.node} />
      {/* @ts-expect-error - TS2786 - 'SubjectUuid' cannot be used as a JSX component. */}
      <SubjectUuid uuid={subject.uuid} />
    </dl>
  </Section>
);

export default createFragmentContainer(AuditLogSubjectSection, {
  auditEvent: graphql`
    fragment subjectSection_auditEvent on AuditEvent {
      subject {
        name
        type
        uuid
        node {
          ... on Organization {
            name
          }
          ... on Pipeline {
            name
          }
          ... on Registry {
            name
          }
          ... on Team {
            name
          }
        }
      }
    }
  `,
});

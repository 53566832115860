import { useBuild } from "app/components/Playground/BuildContext";
import { LoadingPanels } from "app/components/analytics/shared/LoadingStates";

/**
 * A container for the non-react flaky tests view.
 */

export default function TestDigestPage() {
  const { build } = useBuild();

  if (!build) {
    throw new Error("Missing build context");
  }

  return (
    <turbo-frame
      class="w-full"
      id="test_digest"
      src={`${build.path}/test_digest_tab`}
    >
      <LoadingPanels rows={10} columns={1} />
    </turbo-frame>
  );
}

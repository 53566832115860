import { Fragment } from "react";
import {
  GraphQLSchema,
  GraphQLType,
  GraphQLField,
  GraphQLEnumValue,
  GraphQLObjectType,
  GraphQLNonNull,
  GraphQLList,
  GraphQLEnumType,
  GraphQLUnionType,
  GraphQLInterfaceType,
  buildClientSchema,
} from "graphql";

import GraphqlExplorerLayout from "../../Layout";
import Scalar from "../Scalar";

type Props = {
  name: string;
  schema: any;
};

const GraphQLExplorerDocumentationType = ({ name, schema }: Props) => {
  const typename = name;
  const clientSchema: GraphQLSchema = buildClientSchema(schema);

  const type = clientSchema.getType(typename);

  if (!type) {
    return (
      <div>
        <h2>Not Found</h2>
        <p>No type with that name could be found.</p>
      </div>
    );
  }

  let objectType = "type";

  if (type instanceof GraphQLEnumType) {
    objectType = "enum";
  } else if (type instanceof GraphQLUnionType) {
    objectType = "union type";
  } else if (type instanceof GraphQLInterfaceType) {
    objectType = "interface";
  }

  return (
    <div className="cm-s-graphql">
      <h2>
        {objectType[0].toUpperCase() + objectType.slice(1)}:{" "}
        <Scalar name={typename} className="monospace" />
      </h2>
      <p>{type.description || "No description available."}</p>
      {renderFields(type)}
      {renderValues(type)}
      {renderTypes(type)}
      {renderInterfaces(type)}
      {renderPossibleTypes(type, clientSchema)}
    </div>
  );
};

function renderFields(type: GraphQLType) {
  if (
    !(type instanceof GraphQLInterfaceType) &&
    !(type instanceof GraphQLObjectType)
  ) {
    return null;
  }

  // NOTE: This is a weird type cast, but Object.values makes it tricky.
  const fields: Array<GraphQLField<any, any>> = Object.values(
    type.getFields() as any,
  ) as any;

  return (
    <>
      {/* @ts-expect-error - TS2322 - Type '{ children: string; name: string; }' is not assignable to type 'DetailedHTMLProps<HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>'. */}
      <h3 name="fields">Fields</h3>
      {fields.map(renderField)}
    </>
  );
}

const renderField = (field: GraphQLField<any, any>) => {
  let nonNull = false;
  let array = false;
  let nonNullItems = false;
  let type = field.type;

  if (type instanceof GraphQLNonNull) {
    nonNull = true;
    type = type.ofType;
  }

  if (type instanceof GraphQLList) {
    array = true;
    type = type.ofType;
  }

  if (type instanceof GraphQLNonNull) {
    nonNullItems = true;
    type = type.ofType;
  }

  if (type instanceof GraphQLList) {
    throw new TypeError(
      "Type was still a List after processing. This shouldn't be possible.",
    );
  }

  return (
    <Fragment key={field.name}>
      <h4 id={`fields-${field.name}`} className="monospace">
        <span className="cm-property">{field.name}</span>
        {renderFieldArguments(field)}
        <span className="cm-punctuation">: {array && "["}</span>
        {/* @ts-expect-error - TS2339 - Property 'name' does not exist on type 'GraphQLScalarType | GraphQLInterfaceType | GraphQLUnionType | GraphQLEnumType | GraphQLObjectType<any, any> | GraphQLNonNull<...>'. */}
        <Scalar name={type.name} />
        <span className="cm-punctuation">
          {nonNullItems && "!"}
          {array && "]"}
          {nonNull && "!"}
        </span>
      </h4>
      {field.isDeprecated && (
        <p>This field is deprecated. {field.deprecationReason}</p>
      )}
      <p>{field.description || "No description available."}</p>
    </Fragment>
  );
};

function renderFieldArguments(field: GraphQLField<any, any>) {
  if (!field.args || field.args.length < 1) {
    return null;
  }

  const renderedArguments = field.args.map((argument, index, list) => {
    let nonNull = false;
    let array = false;
    let nonNullItems = false;
    let type = argument.type;

    if (type instanceof GraphQLNonNull) {
      nonNull = true;
      type = type.ofType;
    }

    if (type instanceof GraphQLList) {
      array = true;
      type = type.ofType;
    }

    if (type instanceof GraphQLNonNull) {
      nonNullItems = true;
      type = type.ofType;
    }

    if (type instanceof GraphQLList) {
      throw new TypeError(
        "Type was still a List after processing. This shouldn't be possible.",
      );
    }

    return (
      <Fragment key={argument.name}>
        <span className="cm-attribute">{argument.name}</span>
        <span className="cm-punctuation">: {array && "["}</span>
        {/* @ts-expect-error - TS2339 - Property 'name' does not exist on type 'GraphQLScalarType | GraphQLEnumType | GraphQLInputObjectType | GraphQLNonNull<GraphQLScalarType | GraphQLEnumType | GraphQLInputObjectType | GraphQLList<...>>'. */}
        <Scalar name={type.name} />
        <span className="cm-punctuation">
          {nonNullItems && "!"}
          {array && "]"}
          {nonNull && "!"}
          {index < list.length - 1 && ", "}
        </span>
      </Fragment>
    );
  });

  return (
    <>
      <span className="cm-punctuation">(</span>
      {renderedArguments}
      <span className="cm-punctuation">)</span>
    </>
  );
}

const renderValues = (type: GraphQLType) => {
  if (!(type instanceof GraphQLEnumType)) {
    return null;
  }

  return (
    <>
      <h3>Possible Values</h3>
      {type.getValues().map(renderValue)}
    </>
  );
};

function renderValue(value: GraphQLEnumValue) {
  return (
    <Fragment key={value.name}>
      <h4 id={`values-${value.name}`}>
        <span className="cm-string-2 monospace">{value.name}</span>
      </h4>
      {value.isDeprecated && (
        <p>This value is deprecated. {value.deprecationReason}</p>
      )}
      <p>{value.description || "No description available."}</p>
    </Fragment>
  );
}

function renderTypes(type: GraphQLType) {
  if (!(type instanceof GraphQLUnionType)) {
    return null;
  }

  return (
    <>
      <h3 id="types">Possible Types</h3>
      {type.getTypes().map(renderType)}
    </>
  );
}

function renderType(type: GraphQLObjectType) {
  return (
    <Fragment key={type.name}>
      <h4 id={`types-${type.name}`}>
        <Scalar name={type.name} className="monospace" />
      </h4>
      <p>{type.description || "No description available."}</p>
    </Fragment>
  );
}

function renderInterfaces(type: GraphQLType) {
  if (!(type instanceof GraphQLObjectType)) {
    return null;
  }

  const interfaces = type.getInterfaces();

  if (interfaces.length < 1) {
    return null;
  }

  return (
    <>
      <h3 id="interfaces">Interfaces</h3>
      {interfaces.map(renderInterface)}
    </>
  );
}

function renderInterface(iface: GraphQLInterfaceType) {
  return (
    <Fragment key={iface.name}>
      <h5>
        <Scalar name={iface.name} className="monospace" />
      </h5>
      <p>{iface.description || "No description available."}</p>
    </Fragment>
  );
}

function renderPossibleTypes(type: GraphQLType, schema: GraphQLSchema) {
  if (!(type instanceof GraphQLUnionType)) {
    return;
  }

  const implementors = schema.getPossibleTypes(type);

  if (!implementors || implementors.length < 1) {
    return null;
  }

  return (
    <>
      <h3 id="implemented-by">Implemented By</h3>
      <span className="monospace">
        {implementors.map((implementor, index, implementors) => (
          <Fragment key={implementor.name}>
            <Scalar name={implementor.name} />
            {index < implementors.length - 2 && (
              <span className="cm-punctuation">{", "}</span>
            )}
            {index === implementors.length - 2 && " and "}
          </Fragment>
        ))}
      </span>
    </>
  );
}
const Page = (props: Props) => (
  <GraphqlExplorerLayout>
    <GraphQLExplorerDocumentationType {...props} />
  </GraphqlExplorerLayout>
);

export default Page;

/* global jQuery */

import ButtonManager from "app/lib/ButtonManager";

type FormManagerOptions = {
  el: JQuery;
  submit?: (event: JQueryEventObject) => true | unknown;
  validate: (
    successCallback: () => unknown,
    errorCallback: () => unknown,
  ) => unknown | null | undefined;
  loading: string | null | undefined;
  url: string;
};

export default class FormManager {
  options: FormManagerOptions;
  submitButtonManager: ButtonManager;
  $form: JQuery;
  // @ts-expect-error - TS2564 - Property '_submit' has no initializer and is not definitely assigned in the constructor.
  _submit: boolean;

  constructor(options: FormManagerOptions) {
    // Find the nearest form
    this.options = options;
    this.$form = jQuery(this.options.el).closest("form");

    // Attach our submit event handler
    this.$form.on("submit", this._onSubmit.bind(this));

    // Setup a button manager for the submit button
    this.submitButtonManager = new ButtonManager(
      this.$form.find("button[type=submit]"),
    );
  }

  // Finds an input based on it's ID, of the value passed is containted
  // within a [name] in the input name, for example:
  //
  //   <input name="foo[bar]" />
  //
  // Would be returned by:
  //
  //   $input('bar')
  $input(id: string) {
    const regex = new RegExp(`\\[${id}\\]`);

    return this.$form.find("input, select").filter(function (idx, el) {
      const $el = jQuery(el);

      // Check the name and the id of the input to see if it contains the id
      // we're looking for.
      return $el.attr("id") === id || regex.test($el.attr("name") || "");
    });
  }

  // Returns the value of an input field
  val(id: string) {
    return this.$input(id).val();
  }

  // Removes all field errors
  clearErrors() {
    this.$form.find(".error-block").remove();
    return this.$form.find(".has-error").removeClass("has-error");
  }

  // Toggles an error message for a field. If the error is blank, then any error
  // message will be removed.
  addError(field: string, errors: Array<string>) {
    // Filter out falsy values using the Boolean constructor
    // (this is what Underscore's compact method used to do)
    const compactedErrors = errors.filter(Boolean);

    const $cardField = this.$input(field);
    const $formGroup = $cardField.parents(".form-group");

    if (compactedErrors.length >= 1) {
      const messages = errors.join(", ");

      // Either update ot create the error element
      const $existingError = $formGroup.find(".error-block");
      if ($existingError.length > 0) {
        $existingError.html(messages);
      } else {
        const $error = jQuery("<p class='error-block help-block'></p>").html(
          messages,
        );
        $cardField.after($error);
      }

      // Toggle the has-error class on the form-group
      return $formGroup.addClass("has-error");
    }

    // Remove the error field
    $formGroup.find(".error-block").remove();

    // Reset the has-error class
    return $formGroup.removeClass("has-error");
  }

  // Disables the submit button and replaces it with a message
  loading(message: false | string) {
    if (message === false) {
      return this.submitButtonManager.reset();
    }

    return this.submitButtonManager.loading(message);
  }

  // Add's a hidden field to the form
  addHiddenInput(options: { name: string; value: string }) {
    const $input = jQuery("<input type='hidden'/>");

    $input.attr("name", options.name);
    $input.val(options.value);

    return this.$form.append($input);
  }

  // Validates the form using a remote endpoint
  validate(options: { success: () => unknown }) {
    // Convert the form's inputs to an array
    const data = this.$form.serializeArray();

    // Overide the _method field with POST. In some forms, this may be PATCH,
    // but because our validation endpoint is POST only, we need to force it to
    // be so.
    for (const field of data) {
      if (field.name === "_method") {
        field.value = "post";
      }
    }

    // Make the validation request
    return jQuery.ajax({
      url: this.options.url,
      method: "POST",
      headers: {
        "X-Buildkite-Frontend-Version": BUILDKITE_FRONTEND_VERSION,
      } as {
        [key: string]: string;
      },
      data,
      success: (response) => {
        const errors = response["errors"];

        // Clear all the errors first
        this.clearErrors();

        // Yay, no errors!
        if (Object.keys(errors).length === 0) {
          // Call the validation callback, and pass in a callback that finishes
          // the form submit.
          if (this.options.validate) {
            const successCallback = () => options.success();
            const errorCallback = () => this.submitButtonManager.reset();

            return this.options.validate(successCallback, errorCallback);
          }

          return options.success();
        }

        // Show the errors for the fields
        for (const field in errors) {
          const messages = errors[field];
          this.addError(field, messages);
        }

        // Reset the button's loading state
        return this.submitButtonManager.reset();
      },
      error: (error) => {
        // TODO: Do something with the error
        void error; // this just makes Flow stop bugging us

        // Reset the button's loading state
        return this.submitButtonManager.reset();
      },
    });
  }

  _onSubmit(event: JQueryEventObject) {
    if (this._submit) {
      return true;
    }

    // Don't allow the the form to be submitted while we've validating
    event.preventDefault();
    this._submit = false;

    // Change the label on the submit button
    this.submitButtonManager.loading(this.options.loading || "Please wait…");

    // Validate the form
    return this.validate({
      success: () => {
        // If the submit callback doesn't explictly return true, then don't allow
        // the form to submit.
        if (this.options.submit && this.options.submit(event) !== true) {
          return event.preventDefault();
        }

        this._submit = true;
        return this.$form.submit();
      },
    });
  }
}

import { RowTest } from ".";

type Props = {
  test: RowTest;
};

const Row = ({ test }: Props) => (
  <li className="row-link__container">
    <div className="ta-panel-row grid-cols-[auto_6rem_8rem]">
      <div className="main-section">
        <a
          href={test.url}
          className="block text-decoration-none color-inherit row-link"
        >
          <span className="semi-bold block">
            {test.scope} {test.name}
          </span>
        </a>
        <span className="mt1 small dark-gray block">{test.location}</span>
      </div>
      <div className="right-align">
        {test.reliability}
        <span className="hide"> reliable</span>
      </div>
      <div className="right-align">
        {test.average_duration}
        <span className="hide"> average duration</span>
      </div>
    </div>
  </li>
);

export default Row;

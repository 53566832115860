import { useMemo } from "react";

const BuildTriggerLabel = ({ source }: { source: string }) => {
  const label = useMemo(() => {
    switch (source) {
      case "webhook":
        return "Triggered from Webhook";
      case "ui":
        return "Triggered from Web";
      case "api":
        return "Triggered from API";
      case "trigger_job":
        return "Triggered from Pipeline";
      case "schedule":
        return "Triggered from Pipeline Schedule";
      default:
        return "Triggered from Unknown";
    }
  }, [source]);

  return <span>{label}</span>;
};

export default BuildTriggerLabel;

/**
 * @generated SignedSource<<2b7872f3b83e59e56fa52414d58a9fe3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AgentStateIcon_agent$data = {
  readonly connectionState: string;
  readonly isRunningJob: boolean;
  readonly " $fragmentType": "AgentStateIcon_agent";
};
export type AgentStateIcon_agent$key = {
  readonly " $data"?: AgentStateIcon_agent$data;
  readonly " $fragmentSpreads": FragmentRefs<"AgentStateIcon_agent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AgentStateIcon_agent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "connectionState",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isRunningJob",
      "storageKey": null
    }
  ],
  "type": "Agent",
  "abstractKey": null
};

(node as any).hash = "bbcc320759c68aaf34c7202b54b326dd";

export default node;

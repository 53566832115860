import { PureComponent } from "react";
import Badge from "app/components/shared/Badge";

type Props = {
  public: boolean;
};

export default class PipelineStatus extends PureComponent<Props> {
  render() {
    if (this.props.public) {
      return (
        <div data-testid="pipeline__status">
          <Badge
            className="very-dark-gray"
            outline={true}
            // @ts-expect-error - TS2769 - No overload matches this call.
            title="Public Pipeline"
          >
            Public
          </Badge>
        </div>
      );
    }

    return null;
  }
}

import { createFragmentContainer, graphql } from "react-relay";
import { UserAvatarRelay as UserAvatar } from "app/components/shared/UserAvatar";
import JobEvent from "../JobEvent";

type Props = {
  event: any;
  previousTimestamp: string | null | undefined;
};

function JobEventCancelation({
  event: { timestamp, actor },
  previousTimestamp,
}: Props) {
  if (!actor || !actor.node) {
    return null;
  }

  return (
    <JobEvent timestamp={timestamp} previousTimestamp={previousTimestamp}>
      <JobEvent.Label>
        <UserAvatar
          className="mr1"
          size="MICRO"
          user={actor.node}
          suppressAltText={true}
        />
        {actor.node.name} Marked Job For Cancelation
      </JobEvent.Label>
    </JobEvent>
  );
}

export default createFragmentContainer(JobEventCancelation, {
  event: graphql`
    fragment JobEventCancelation_event on JobEvent {
      ... on JobEvent {
        timestamp

        actor {
          node {
            ... on User {
              name
              ...UserAvatar_user
            }
          }
        }
      }
    }
  `,
});

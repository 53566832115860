import * as React from "react";
import classNames from "classnames";

type Props = {
  children: React.ReactNode;
  className?: string;
};

export default class Header extends React.PureComponent<Props> {
  render() {
    return (
      <h2
        className={classNames(
          "h4 m0 bg-silver py2 px3 semi-bold line-height-4",
          this.props.className,
        )}
      >
        {this.props.children}
      </h2>
    );
  }
}

/**
 * @generated SignedSource<<63c3f97a51a9411fff2ced9305a18b6d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Registries_organizationQuery$variables = {
  pageSize: number;
  team: string;
};
export type Registries_organizationQuery$data = {
  readonly team: {
    readonly " $fragmentSpreads": FragmentRefs<"Registries_team">;
  } | null;
};
export type Registries_organizationQuery = {
  response: Registries_organizationQuery$data;
  variables: Registries_organizationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pageSize"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "team"
},
v2 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "team"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v5 = {
  "kind": "Literal",
  "name": "order",
  "value": "NAME"
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "allowed",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "Registries_organizationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Team",
        "kind": "LinkedField",
        "name": "team",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "pageSize",
                "variableName": "pageSize"
              }
            ],
            "kind": "FragmentSpread",
            "name": "Registries_team"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "Registries_organizationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Team",
        "kind": "LinkedField",
        "name": "team",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 10
                  },
                  (v5/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "search",
                    "value": ""
                  }
                ],
                "concreteType": "RegistryConnection",
                "kind": "LinkedField",
                "name": "registries",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RegistryEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Registry",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v6/*: any*/),
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "registries(first:10,order:\"NAME\",search:\"\")"
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TeamPermissions",
            "kind": "LinkedField",
            "name": "permissions",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Permission",
                "kind": "LinkedField",
                "name": "teamRegistryCreate",
                "plural": false,
                "selections": (v7/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "first",
                "variableName": "pageSize"
              },
              (v5/*: any*/)
            ],
            "concreteType": "TeamRegistryConnection",
            "kind": "LinkedField",
            "name": "registries",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "Connection",
                "abstractKey": "__isConnection"
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "count",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TeamRegistryEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TeamRegistry",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "accessLevel",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Registry",
                        "kind": "LinkedField",
                        "name": "registry",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v6/*: any*/),
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "url",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TeamRegistryPermissions",
                        "kind": "LinkedField",
                        "name": "permissions",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Permission",
                            "kind": "LinkedField",
                            "name": "teamRegistryUpdate",
                            "plural": false,
                            "selections": (v7/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Permission",
                            "kind": "LinkedField",
                            "name": "teamRegistryDelete",
                            "plural": false,
                            "selections": (v7/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1050fae79f7e9a24c7c132ebcd5431c2",
    "id": null,
    "metadata": {},
    "name": "Registries_organizationQuery",
    "operationKind": "query",
    "text": "query Registries_organizationQuery(\n  $team: ID!\n  $pageSize: Int!\n) {\n  team(slug: $team) {\n    ...Registries_team_RlXb8\n    id\n  }\n}\n\nfragment RegistriesChooser_team on Team {\n  id\n  slug\n  organization {\n    registries(search: \"\", first: 10, order: NAME) {\n      edges {\n        node {\n          id\n          name\n          slug\n        }\n      }\n    }\n    id\n  }\n  permissions {\n    teamRegistryCreate {\n      allowed\n    }\n  }\n}\n\nfragment Registries_team_RlXb8 on Team {\n  ...RegistriesChooser_team\n  registries(first: $pageSize, order: NAME) {\n    ...ShowMoreFooter_connection\n    count\n    edges {\n      node {\n        id\n        accessLevel\n        registry {\n          id\n          name\n          slug\n          url\n        }\n        permissions {\n          teamRegistryUpdate {\n            allowed\n          }\n          teamRegistryDelete {\n            allowed\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment ShowMoreFooter_connection on Connection {\n  __isConnection: __typename\n  pageInfo {\n    hasNextPage\n  }\n}\n"
  }
};
})();

(node as any).hash = "28a02af62d36cec41240ba48ea86478c";

export default node;

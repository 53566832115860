import Tooltip from "../../shared/Tooltips/BasicTooltip";
import { isEmpty } from "lodash";
import { FlakyTest } from "../../shared/type";
import FlakyTestRow, { FlakyRowProps } from "../FlakyTestRow";
import FlakyEmptyState from "./FlakyEmptyState";
import SortingButton from "./SortingButton";

type Props = FlakyRowProps & {
  flakyTests: FlakyTest[];
  tooltips: {
    [K in "flaky_instance" | "first_occurrence_at"]: string;
  };
  query?: string;
  suiteHasRecentRuns: boolean;
  suiteHasPreviousInstances: boolean;
  sortBy: SortBy;
  order: order;
  period?: flakyDatePeriod;
};

type SortBy = "instance" | "latest_occurrence";
type order = "ASC" | "DESC";
export type flakyDatePeriod =
  | "1hour"
  | "4hours"
  | "1day"
  | "7days"
  | "14days"
  | "28days";

export const ResultButtons: Record<SortBy, string> = {
  instance: "Instances",
  latest_occurrence: "Latest occurrence",
};

const FlakyTestTable = ({
  query,
  suiteHasRecentRuns,
  suiteHasPreviousInstances,
  currentAccountMember,
  isAdmin,
  flakyTestAssignmentFeatureAvailable,
  manageTeamsUrl,
  billingUrl,
  teams,
  flakyTests,
  refreshAssignments,
  isFlaky,
  tooltips,
  sortBy,
  order,
  period,
}: Props) => {
  if (isEmpty(flakyTests)) {
    return (
      <FlakyEmptyState
        query={query}
        period={period}
        suiteHasRecentRuns={suiteHasRecentRuns}
        suiteHasPreviousInstances={suiteHasPreviousInstances}
      />
    );
  }

  return (
    <section>
      <ul className="ta-panel">
        <li
          aria-hidden={true}
          className="ta-panel--heading grid-cols-[auto_7rem_8rem_8rem] lg:grid-cols-[auto_7rem_14rem_17rem]"
        >
          <div className="main-section">Description</div>
          <div className="flex items-center justify-center">
            {currentAccountMember ? (
              <SortingButton
                currentOrder={order}
                currentSort={sortBy}
                buttonType="instance"
              />
            ) : (
              ResultButtons["instance"]
            )}

            <Tooltip classNames="ml-1" name="Flaky instances">
              <p className="font-semibold m-0 charcoal-800">Flaky instances</p>
              <p className="m-0 mt-1 charcoal-800">{tooltips.flaky_instance}</p>
            </Tooltip>
          </div>

          <div>
            First occurrence
            <Tooltip classNames="ml-1" name="First occurrence">
              <p className="font-semibold m-0 charcoal-800">First occurrence</p>
              <p className="m-0 mt-1 charcoal-800">
                {tooltips.first_occurrence_at}
              </p>
            </Tooltip>
          </div>

          <div>
            {currentAccountMember ? (
              <SortingButton
                currentOrder={order}
                currentSort={sortBy}
                buttonType="latest_occurrence"
              />
            ) : (
              ResultButtons["latest_occurrence"]
            )}
          </div>
        </li>

        {flakyTests.map((flakyTest) => (
          <FlakyTestRow
            key={flakyTest.test_id}
            flakyTest={flakyTest}
            currentAccountMember={currentAccountMember}
            teams={teams}
            isAdmin={isAdmin}
            flakyTestAssignmentFeatureAvailable={
              flakyTestAssignmentFeatureAvailable
            }
            manageTeamsUrl={manageTeamsUrl}
            billingUrl={billingUrl}
            refreshAssignments={refreshAssignments}
            isFlaky={isFlaky}
          />
        ))}
      </ul>
    </section>
  );
};

export default FlakyTestTable;

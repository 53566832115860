export enum NodeType {
  Root = "root",
  Block = "block",
  Wait = "wait",
  Command = "command",
  Unknown = "unknown",
  Trigger = "trigger",

  // ReactFlow has some reserved type names that we need to avoid.
  Group = "group-step",
  Input = "input-step",
}

import { useState, useRef, useLayoutEffect } from "react";
import styled from "styled-components";
import classNames from "classnames";
import useCollapse from "react-collapsed";

import Icon from "app/components/shared/Icon";
import Colors from "app/constants/analytics/Colors";
import Badge from "app/components/shared/Badge";

type SpanProps =
  | {
      section: "sql";
      detail: { query: string };
      formattedDuration: string;
    }
  | {
      section: "http";
      detail: { method: string; url: string; lib: string };
      formattedDuration: string;
    }
  | {
      section: string;
      detail: any;
      formattedDuration: string;
    };

const EllipsisButton = styled.button`
  font-family: inherit;
  font-size: inherit;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  margin-left: 75px;
  height: 16px;
  width: 28px;
  border-radius: 3px;
  border: 1px solid transparent;
  background: ${Colors.PALE_GRAY};
  vertical-align: middle;
  -webkit-appearance: none;
  color: inherit;

  &:focus,
  &:hover {
    background: ${Colors.LIGHT_GRAY};
  }
`;

const LINE_HEIGHT = 25;

const SpanBadge = ({ type }: { type: string }) => {
  const badgeStyle = {
    fontFamily:
      "SF Pro Text, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, sans-serif",
    fontStyle: "normal",
    fontSize: "11px",
    letterSpacing: "0.1em",
    textAlign: "center",
    textTransform: "uppercase",
    flex: "0 0 60px",
    width: "100%",
    marginRight: "10px",
  };

  const computedStyle = () => {
    switch (type) {
      case "http":
        return {
          backgroundColor: Colors.PALE_PURPLE,
          color: Colors.DARK_PURPLE,
        };
      case "sql":
        return { backgroundColor: Colors.PALE_AQUA, color: Colors.DARK_AQUA };
      case "sleep":
        return { backgroundColor: Colors.PALE_PINK, color: Colors.DARK_PINK };
      case "other":
        return { backgroundColor: Colors.PALE_BLUE, color: Colors.DARK_BLUE };
    }
  };

  return (
    <Badge className="bold" style={{ ...computedStyle(), ...badgeStyle }}>
      {type.toUpperCase()}
    </Badge>
  );
};

const HttpSpan = ({ spanContentRef, formattedDuration, formatDetail }) => (
  <div className="flex items-baseline">
    <SpanBadge type="http" />
    <div>
      <pre className="span-content flex-1 break-all" ref={spanContentRef}>
        {formatDetail}
      </pre>
    </div>
    <span className="duration">{formattedDuration}</span>
  </div>
);

const Span = ({ detail, formattedDuration, section }: SpanProps) => {
  const formatDetail = () => {
    switch (section) {
      case "sql":
        return detail.query;
      case "http":
        return `${detail.method} ${detail.url} ${detail.lib}`;
      case "sleep":
        return "";
      default:
        return JSON.stringify(detail);
    }
  };
  const calcExpandable = (element: HTMLElement | null) => {
    return element ? element.offsetHeight > LINE_HEIGHT : false;
  };
  const [isExpandable, setExpandable] = useState(false);
  const spanContentRef = useRef<HTMLPreElement | null>(null);
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
    collapsedHeight: LINE_HEIGHT,
    hasDisabledAnimation: true,
  });

  useLayoutEffect(() => {
    setExpandable(calcExpandable(spanContentRef.current));
  }, []);

  return (
    <div className="span-container">
      <div className="circle" />
      {section === "http" ? (
        <HttpSpan
          spanContentRef={spanContentRef}
          formattedDuration={formattedDuration}
          formatDetail={formatDetail()}
        />
      ) : (
        <>
          <div className={classNames({ pb1: isExpanded })}>
            <div className="flex items-baseline">
              <SpanBadge type={section} />
              <div {...getCollapseProps()}>
                <pre className="span-content" ref={spanContentRef}>
                  {formatDetail()}
                </pre>
              </div>
              <span className="duration">{formattedDuration}</span>
            </div>
          </div>
          {isExpandable && (
            <EllipsisButton
              {...getToggleProps()}
              className="bold"
              aria-label={isExpanded ? "Hide full span" : "Show full span"}
            >
              <Icon icon="ellipsis" style={{ width: 15, height: 12 }} />
            </EllipsisButton>
          )}
        </>
      )}
    </div>
  );
};

export default Span;

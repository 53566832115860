/**
 * @generated SignedSource<<0e04e031d05638f4c445374c18db3cdf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ConferenceAnnouncementNotice_viewer$data = {
  readonly notice: {
    readonly dismissedAt: any | null;
    readonly id: string;
  } | null;
  readonly " $fragmentType": "ConferenceAnnouncementNotice_viewer";
};
export type ConferenceAnnouncementNotice_viewer$key = {
  readonly " $data"?: ConferenceAnnouncementNotice_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"ConferenceAnnouncementNotice_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ConferenceAnnouncementNotice_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "namespace",
          "value": "EVENT"
        },
        {
          "kind": "Literal",
          "name": "scope",
          "value": "unblockconf-2022-schedule"
        }
      ],
      "concreteType": "Notice",
      "kind": "LinkedField",
      "name": "notice",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dismissedAt",
          "storageKey": null
        }
      ],
      "storageKey": "notice(namespace:\"EVENT\",scope:\"unblockconf-2022-schedule\")"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "290c753ceb60b94d75a9f6f84d7f74e5";

export default node;

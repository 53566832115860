import { Component } from "react";
import PropTypes from "prop-types";

import Button from "./Button";

class RevealButton extends Component {
  static propTypes = {
    caption: PropTypes.node,
    children: PropTypes.node,
  };

  static defaultProps = {
    caption: "Reveal",
  };

  state = {
    revealed: false,
  };

  handleRevealClick = (evt) => {
    evt.preventDefault();

    this.setState({ revealed: true }, () => {
      let range, selection;
      if (this._contentElement) {
        range = document.createRange();
        range.selectNodeContents(this._contentElement);

        selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
      }
    });
  };

  render() {
    if (this.state.revealed) {
      return (
        <div
          className="rounded border border-gray p1"
          ref={(element) => (this._contentElement = element)}
        >
          {this.props.children}
        </div>
      );
    }

    return (
      <Button onClick={this.handleRevealClick}>{this.props.caption}</Button>
    );
  }
}

export default RevealButton;

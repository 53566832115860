import type { ReactNode, ComponentType } from "react";
import styled from "styled-components";
import classNames from "classnames";
import TabControl from "app/components/shared/TabControl";
import Icon from "app/components/shared/Icon";

export const StyledTabControl = styled(TabControl)`
  border-bottom: 0;
  margin-bottom: 30px;
`;

export const TabButton = ({
  active,
  href,
  icon,
  iconSize,
  onClick,
  children,
}: {
  active: boolean;
  href: string;
  icon?: string;
  iconSize?: string;
  onClick: () => void;
  children: ReactNode;
}) => (
  <li>
    <a
      className={classNames("btn ta-btn-tab", {
        "ta-btn-tab--active": active,
      })}
      href={href}
      onClick={onClick}
    >
      {icon !== undefined && (
        <Icon
          icon={icon}
          style={{
            fill: "none",
            marginRight: "7px",
            width: iconSize !== undefined ? iconSize : "24px",
            height: iconSize !== undefined ? iconSize : "24px",
          }}
        />
      )}
      {children}
    </a>
  </li>
);

export default StyledTabControl;

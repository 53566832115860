class Favicon {
  update(href) {
    const icon = document.querySelector("link[rel='shortcut icon']");

    if (icon && icon.getAttribute("href") !== href) {
      // Firefox doesn't recognize just changing the href, so we need to
      // remove and add it back again.
      const link = document.createElement("link");
      link.type = "image/x-icon";
      link.rel = icon.getAttribute("rel");
      link.href = href;

      icon.remove();
      document.head.appendChild(link);
    }
  }
}

export default new Favicon();

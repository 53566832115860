/**
 * @generated SignedSource<<b4330c7c9ea8572c75732acc2ac3edfc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TOTPActivateInput = {
  clientMutationId?: string | null;
  id: string;
  token: string;
};
export type TwoFactorConfigureActivateMutation$variables = {
  input: TOTPActivateInput;
};
export type TwoFactorConfigureActivateMutation$data = {
  readonly totpActivate: {
    readonly viewer: {
      readonly " $fragmentSpreads": FragmentRefs<"TwoFactor_viewer">;
    };
  } | null;
};
export type TwoFactorConfigureActivateMutation = {
  response: TwoFactorConfigureActivateMutation$data;
  variables: TwoFactorConfigureActivateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TwoFactorConfigureActivateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "TOTPActivatePayload",
        "kind": "LinkedField",
        "name": "totpActivate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Viewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "TwoFactor_viewer"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TwoFactorConfigureActivateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "TOTPActivatePayload",
        "kind": "LinkedField",
        "name": "totpActivate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Viewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "TOTP",
                "kind": "LinkedField",
                "name": "totp",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RecoveryCodeBatch",
                    "kind": "LinkedField",
                    "name": "recoveryCodes",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "RecoveryCode",
                        "kind": "LinkedField",
                        "name": "codes",
                        "plural": true,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "consumed",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 50
                  }
                ],
                "concreteType": "OrganizationConnection",
                "kind": "LinkedField",
                "name": "organizations",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrganizationEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Organization",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "slug",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "membersRequireTwoFactorAuthentication",
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "organizations(first:50)"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ViewerPermissions",
                "kind": "LinkedField",
                "name": "permissions",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Permission",
                    "kind": "LinkedField",
                    "name": "totpConfigure",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "allowed",
                        "storageKey": null
                      },
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "message",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f26529ea70fd6ae395536a31fed62fef",
    "id": null,
    "metadata": {},
    "name": "TwoFactorConfigureActivateMutation",
    "operationKind": "mutation",
    "text": "mutation TwoFactorConfigureActivateMutation(\n  $input: TOTPActivateInput!\n) {\n  totpActivate(input: $input) {\n    viewer {\n      ...TwoFactor_viewer\n      id\n    }\n  }\n}\n\nfragment RecoveryCodeList_recoveryCodes on RecoveryCodeBatch {\n  codes {\n    code\n    consumed\n  }\n}\n\nfragment RecoveryCodes_totp on TOTP {\n  id\n  recoveryCodes {\n    id\n    ...RecoveryCodeList_recoveryCodes\n    codes {\n      code\n      consumed\n    }\n  }\n}\n\nfragment TwoFactorConfigureRecoveryCodes_recoveryCodes on RecoveryCodeBatch {\n  ...RecoveryCodeList_recoveryCodes\n  codes {\n    code\n    consumed\n  }\n}\n\nfragment TwoFactorConfigure_viewer on Viewer {\n  id\n  totp {\n    id\n    recoveryCodes {\n      ...TwoFactorConfigureRecoveryCodes_recoveryCodes\n      id\n    }\n  }\n}\n\nfragment TwoFactorDelete_viewer on Viewer {\n  totp {\n    id\n  }\n  organizations(first: 50) {\n    edges {\n      node {\n        name\n        slug\n        membersRequireTwoFactorAuthentication\n        id\n      }\n    }\n  }\n}\n\nfragment TwoFactor_viewer on Viewer {\n  ...TwoFactorConfigure_viewer\n  ...TwoFactorDelete_viewer\n  permissions {\n    totpConfigure {\n      allowed\n      code\n      message\n    }\n  }\n  totp {\n    ...RecoveryCodes_totp\n    id\n    recoveryCodes {\n      ...RecoveryCodeList_recoveryCodes\n      codes {\n        code\n        consumed\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4760f5be1fd319ef95967a165b0ec477";

export default node;

import FriendlyTime from "app/components/shared/FriendlyTime";
import ExecutionResult from "../../shared/ResultIcons/ExecutionResult";
import FailureReason from "../../shared/FailureReason";
import BuildNumber from "../../shared/Metadata/BuildNumber";
import { StyledUnderline } from "../../shared/styles";
import { Execution } from "../../shared/type";
import ExecutionDrawerButton from "../../execution/Row/ExecutionDrawerButton";
import Icon from "app/components/shared/Icon";

export type FlakyExecution = Omit<
  Execution,
  "duration" | "humanDuration" | "uuid"
>;

const ExecutionsList = ({
  executions,
}: {
  executions: Array<FlakyExecution>;
}) => (
  <div className="py-5 px-8">
    <ul className="flex flex-col gap-2 list-none">
      {executions.map((execution) => (
        <li key={execution.id} className="flex flex-col gap-2 charcoal-800">
          <div className="flex gap-2">
            <span className="shrink-0">
              <ExecutionResult.Small result={execution.result.toUpperCase()} />
            </span>

            <div className="flex flex-wrap gap-2 text-sm">
              <span>
                {execution.turboFrameSrc ? (
                  <ExecutionDrawerButton
                    url={execution.turboFrameSrc}
                    classes="color-inherit hover-faded underline"
                  >
                    View execution
                  </ExecutionDrawerButton>
                ) : (
                  <a className="color-inherit hover-faded" href={execution.url}>
                    View execution
                  </a>
                )}
              </span>
              <span className="before:content-['·'] before:text-charcoal-300 before:pr-2">
                <StyledUnderline
                  as={FriendlyTime}
                  value={execution.createdAt}
                  className="hover-faded"
                />
              </span>
              {(execution.buildNumber || execution.buildUrl) && (
                <span className="before:content-['·'] before:text-charcoal-300 before:pr-2">
                  <BuildNumber
                    number={execution.buildNumber}
                    url={execution.buildUrl}
                  />
                </span>
              )}
              {execution.branch && (
                <span className="before:content-['·'] before:text-charcoal-300 before:pr-2">
                  <Icon
                    icon="custom/outline/14x/branch"
                    style={{ height: 14, width: 14 }}
                  />
                  {execution.branch}
                </span>
              )}
            </div>
          </div>

          {execution.failureReason != null && (
            <div className="pl-6">
              <FailureReason
                failureReason={execution.failureReason}
                endpoint={execution.failureExpandedUrl}
                failureExpanded={execution.failureExpanded}
              />
            </div>
          )}
        </li>
      ))}
    </ul>
  </div>
);

export default ExecutionsList;

import * as React from "react";

import { commitMutation, createFragmentContainer, graphql } from "react-relay";

import Button from "app/components/shared/Button";
import Dialog from "app/components/shared/Dialog";
import FormTextField from "app/components/shared/FormTextField";
import Environment from "app/lib/relay/environment";

type Props = {
  isOpen: boolean | null | undefined;
  onRequestClose: any;
  pipeline: any;
};

type State = {
  saving: boolean;
};

class BranchSettingsDialog extends React.PureComponent<Props, State> {
  state = {
    saving: false,
  };

  defaultBranchTextField: FormTextField;

  componentDidMount() {
    // Focus the input box if the dialog started life opened.
    if (this.props.isOpen && this.defaultBranchTextField) {
      this.defaultBranchTextField.focus();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (!prevProps.isOpen && this.props.isOpen) {
      // Focus the input box if the dialog was just opened. This is a total
      // hack since when this component transitions from `isOpen (false =>
      // true)` the defaultBranchTextField doesn't actually exist yet.
      //
      // So we'll do this hacky thing here, let this component render, then
      // focus the text field if we've got it.
      setTimeout(() => {
        if (this.defaultBranchTextField) {
          this.defaultBranchTextField.focus();
        }
      }, 0);
    }
  }

  render() {
    return (
      <Dialog
        // @ts-expect-error - TS2322 - Type 'boolean | null | undefined' is not assignable to type 'boolean | undefined'.
        isOpen={this.props.isOpen}
        onRequestClose={this.props.onRequestClose}
        width={450}
      >
        <div
          className="p4 border-bottom border-gray semi-bold line-height-1"
          style={{ paddingBottom: 18 }}
        >
          {this.props.pipeline.organization.name} / {this.props.pipeline.name}
        </div>
        <div className="p4">
          <h1 className="m0 p0 h2 semi-bold mb1">Branch Settings</h1>
          <p className="dark-gray m0 p0 mb2">
            The default branch is used to generate the pipeline build graph and
            metrics, and to prefill the branch when new builds are created or
            triggered.
          </p>

          <form onSubmit={this.handleSubmit}>
            <FormTextField
              name="defaultBranch"
              label="Default Branch"
              placeholder="All Builds"
              help="Leave blank if your pipeline has no default branch."
              defaultValue={this.props.pipeline.defaultBranch}
              ref={(tf) => (this.defaultBranchTextField = tf)}
            />

            <Button
              type="submit"
              className="col-12 mt2"
              onClick={this.handleSubmit}
              // @ts-expect-error - TS2322 - Type 'false | "Saving Branch Settings..."' is not assignable to type 'boolean | undefined'.
              loading={this.state.saving ? "Saving Branch Settings..." : false}
            >
              Save Branch Settings
            </Button>
          </form>
        </div>
      </Dialog>
    );
  }

  handleSubmit = (event: any) => {
    event.preventDefault();

    if (this.defaultBranchTextField) {
      this.setState({ saving: true });

      const environment = Environment.get();
      const { pipeline } = this.props;
      const input = {
        id: pipeline.id,
        defaultBranch: this.defaultBranchTextField.value,
      } as const;

      commitMutation(environment, {
        mutation: graphql`
          mutation BranchSettingsDialogPipelineUpdateMutation(
            $input: PipelineUpdateInput!
          ) {
            pipelineUpdate(input: $input) {
              pipeline {
                defaultBranch
                ...Metrics_pipeline
              }
            }
          }
        `,
        variables: { input },
        onCompleted: this.handleMutationComplete,
        onError: this.handleMutationError,
      });
    }
  };

  handleMutationComplete = () => {
    this.setState({ saving: false });

    this.props.onRequestClose();
  };

  handleMutationError = (error: any) => {
    if (error) {
      alert(error);
    }

    this.setState({ saving: false });
  };
}

export default createFragmentContainer(BranchSettingsDialog, {
  pipeline: graphql`
    fragment BranchSettingsDialog_pipeline on Pipeline {
      id
      name
      defaultBranch
      organization {
        name
      }
    }
  `,
});

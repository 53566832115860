import styled from "styled-components";
import Card from "./Card";

export const CardContainer = styled.div`
  justify-content: flex-start;

  @media (max-width: 486px) {
    flex: 1 1 auto;
    flex-direction: column;
    align-items: stretch;
  }

  @media (max-width: 680px) {
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
  }

  @media (max-width: 1200px) {
    justify-content: start;
  }

  > div {
    margin-right: 15px;

    :last-child {
      @media (max-width: 1200px) {
        margin-right: 0;
      }
    }

    @media (max-width: 486px) {
      margin-right: 0;
    }

    @media (min-width: 500px) {
      margin-bottom: 15px;
    }

    @media (max-width: 500px) {
      margin-left: 0px;
      display: flex;
      flex: 1 1 auto;
      margin-bottom: 15px;
      align-items: end;
      justify-content: space-between;
      width: auto;
    }
  }
`;

export type Metrics = Array<{
  label: string;
  value: string;
  description?: string;
}>;

const MetricCards = ({ metrics }: { metrics: Metrics }) => (
  <div className="flex flex-column flex-auto">
    <CardContainer className="flex">
      {metrics.map((metric) => (
        <Card {...metric} key={metric.label} />
      ))}
    </CardContainer>
  </div>
);

export default MetricCards;

/**
 * @generated SignedSource<<cd157285eaebdafd1dc5cd7797da57d0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JobEventStarted_event$data = {
  readonly job: {
    readonly agent: {
      readonly " $fragmentSpreads": FragmentRefs<"AgentBadge_agent">;
    } | null;
  };
  readonly timestamp: any;
  readonly " $fragmentType": "JobEventStarted_event";
};
export type JobEventStarted_event$key = {
  readonly " $data"?: JobEventStarted_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"JobEventStarted_event">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "JobEventStarted_event",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timestamp",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "JobTypeCommand",
      "kind": "LinkedField",
      "name": "job",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Agent",
          "kind": "LinkedField",
          "name": "agent",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "AgentBadge_agent"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "JobEvent",
  "abstractKey": "__isJobEvent"
};

(node as any).hash = "4b73ec2b52cadd220479016234ec6e80";

export default node;

import { PureComponent } from "react";

type Props = {
  branch: string;
  commit: string;
};

export default class GitCommitNotFoundWarning extends PureComponent<Props> {
  render() {
    return (
      <div className="alert alert-warning mb2" role="alert">
        Git checkout failed as commit{" "}
        <span className="code small">{this.props.commit}</span> could not be
        found in branch <span className="semi-bold">{this.props.branch}</span>.
      </div>
    );
  }
}

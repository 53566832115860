import { useState } from "react";
import { createFragmentContainer, graphql } from "react-relay";

import FriendlyTime from "app/components/shared/FriendlyTime";
import Icon from "app/components/shared/Icon";
import RevealableDownChevron from "app/components/shared/Icon/RevealableDownChevron";
import Panel from "app/components/shared/Panel";
import UserAvatar from "app/components/shared/UserAvatar";

import AuditLogDrawer from "./Drawer";

import { indefiniteArticleFor } from "app/lib/words";

type AuditEvent = {
  auditEvent: {
    uuid: string;
    type: string;
    occurredAt: string;
    actor?: {
      type?: string;
      name?: string;
      node?: {
        name?: string;
        avatar?: {
          url?: string;
        };
      };
    };
    subject: {
      type: string;
      name?: string;
      node?: {
        name?: string;
        team?: {
          name?: string;
        };
        user?: {
          name?: string;
        };
        pipeline?: {
          name?: string;
        };
        registry?: {
          name?: string;
        };
        suite?: {
          name?: string;
        };
      };
    };
  };
};

const ActorName = ({ auditEvent }: AuditEvent) => {
  if (!auditEvent.actor) {
    // We don't add buildkite staff as actor to USER_IMPERSONATED event for privacy reasons
    // "No Actor impersonated user" can sound alarming to the organization
    // so we'll just say actor was "Buildkite staff"
    if (auditEvent.type === "USER_IMPERSONATED") {
      return <div className="dark-gray">Buildkite staff</div>;
    }
    return <div className="dark-gray">No Actor</div>;
  }

  const actorName =
    auditEvent.actor.name ||
    (auditEvent.actor.node && auditEvent.actor.node.name);

  return <div className="semi-bold">{actorName}</div>;
};

const ActorAvatar = ({ auditEvent }: AuditEvent) => {
  // Don't render a broken avatar image if there's no actor
  if (!auditEvent.actor) {
    return null;
  }

  if (auditEvent.actor && auditEvent.actor.node) {
    if (auditEvent.actor.type === "AGENT") {
      return (
        <div
          style={{ width: 39, height: 39 }}
          className="circle border border-gray flex items-center justify-center"
        >
          <Icon
            icon="custom/outline/14x/agent"
            style={{ width: "14px", height: "14px" }}
          />
        </div>
      );
    }
    return (
      <UserAvatar
        style={{ width: 39, height: 39 }}
        // $FlowExpectError: we know this is a user because we early exited if actor was an agent above^.
        // @ts-expect-error - TS2769 - No overload matches this call.
        user={auditEvent.actor.node}
        suppressAltText={true}
      />
    );
  }

  return (
    <div
      style={{ width: 39, height: 39 }}
      className="circle border border-gray flex items-center justify-center"
    >
      <span className="dark-gray">?</span>
    </div>
  );
};

const renderEventObject = ({
  type,
  name,
  node,
}: {
  type: string;
  name: any;
  node: any;
}) => {
  // "ORGANIZATION_INVITATION" => "organzation invitation"
  // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
  const friendlyType = type && type.split("_").pop().toLowerCase();

  // Check if we can still see the node and its current name, fall back to
  // the name recorded at the time of the event if present, or just an
  // indefinite type name
  if (node && node.name) {
    return `${friendlyType} “${node.name}”`;
  } else if (name) {
    return `${friendlyType} “${name}”`;
  } else if (type === "USER") {
    // "an user" feels weird
    return "a user";
  } else if (type === "ORGANIZATION_IMPERSONATION_REQUEST") {
    return "an impersonation request";
  }
  return `${indefiniteArticleFor(friendlyType)} ${friendlyType}`;
};

const EventSentence = ({ auditEvent: { type, subject } }) => {
  // Take a guess at the verb. Usually the event type is subject type + event
  // verb, so strip off the subject type if it matches or just take the last
  // word, then sentence case the verb.

  let eventVerb;

  if (type.indexOf(`${subject.type}_`) === 0) {
    // "ORGANIZATION_TEAMS_ENABLED" with an "ORGANIZATION" subject => "TEAMS ENABLED"
    eventVerb = type.slice(subject.type.length + 1).replace("_", " ");
  } else {
    // "PIPELINE_CREATED" => "CREATED"
    eventVerb = type.split("_").pop();
  }

  eventVerb =
    eventVerb.charAt(0).toUpperCase() + eventVerb.slice(1).toLowerCase();

  const renderedSubject = renderEventObject(subject);

  if (type === "ORGANIZATION_CREATED") {
    // Welcome!
    return `Created ${renderedSubject} 🎉`;
  } else if (type === "ORGANIZATION_TEAMS_ENABLED") {
    return `Enabled teams for ${renderedSubject}`;
  } else if (type === "ORGANIZATION_TEAMS_DISABLED") {
    return `Disabled teams for ${renderedSubject}`;
  } else if (type === "SUBSCRIPTION_PLAN_CHANGED") {
    return `Changed billing plan`;
  } else if (type === "SUBSCRIPTION_PLAN_CHANGE_SCHEDULED") {
    return `Scheduled a billing plan change`;
  } else if (type === "SUBSCRIPTION_PLAN_CANCELED") {
    return `Canceled billing plan`;
  } else if (type === "SUBSCRIPTION_PLAN_CANCELATION_SCHEDULED") {
    return `Scheduled a billing plan cancelation`;
  } else if (type === "SUBSCRIPTION_SCHEDULED_PLAN_CHANGE_CANCELED") {
    return `Canceled a scheduled billing plan change`;
  } else if (type === "API_ACCESS_TOKEN_ORGANIZATION_ACCESS_REVOKED") {
    const renderedUser = renderEventObject({
      type: "",
      node: "",
      name: subject.name,
    });
    return `Access revoked for token belonging to ${renderedUser}`;
  } else if (subject.type === "TEAM_MEMBER") {
    const renderedTeam = renderEventObject({
      type: "TEAM",
      node: subject.node && subject.node.team,
      name: null,
    });
    const renderedUser = renderEventObject({
      type: "USER",
      node: subject.node && subject.node.user,
      name: null,
    });

    if (type === "TEAM_MEMBER_CREATED") {
      return `Added ${renderedUser} to ${renderedTeam}`;
    } else if (type === "TEAM_MEMBER_DELETED") {
      return `Removed ${renderedUser} from ${renderedTeam}`;
    }

    return `${eventVerb} ${renderedUser} in ${renderedTeam}`;
  } else if (subject.type === "SCM_SERVICE") {
    return `${eventVerb} a repository provider`;
  } else if (subject.type === "TEAM_PIPELINE") {
    const renderedTeam = renderEventObject({
      type: "TEAM",
      node: subject.node && subject.node.team,
      name: null,
    });
    const renderedPipeline = renderEventObject({
      type: "PIPELINE",
      node: subject.node && subject.node.pipeline,
      name: null,
    });

    if (type === "TEAM_PIPELINE_CREATED") {
      return `Added ${renderedPipeline} to ${renderedTeam}`;
    } else if (type === "TEAM_PIPELINE_DELETED") {
      return `Removed ${renderedPipeline} from ${renderedTeam}`;
    }

    return `${eventVerb} ${renderedPipeline} in ${renderedTeam}`;
  } else if (subject.type === "TEAM_REGISTRY") {
    const renderedTeam = renderEventObject({
      type: "TEAM",
      node: subject.node && subject.node.team,
      name: null,
    });
    const renderedRegistry = renderEventObject({
      type: "REGISTRY",
      node: subject.node && subject.node.registry,
      name: null,
    });

    if (type === "TEAM_REGISTRY_CREATED") {
      return `Added ${renderedRegistry} to ${renderedTeam}`;
    } else if (type === "TEAM_REGISTRY_DELETED") {
      return `Removed ${renderedRegistry} from ${renderedTeam}`;
    }

    return `${eventVerb} ${renderedRegistry} in ${renderedTeam}`;
  } else if (subject.type === "TEAM_SUITE") {
    const renderedTeam = renderEventObject({
      type: "TEAM",
      node: subject.node && subject.node.team,
      name: null,
    });
    const renderedSuite = renderEventObject({
      type: "SUITE",
      node: subject.node && subject.node.suite,
      name: null,
    });

    if (type === "TEAM_SUITE_CREATED") {
      return `Added ${renderedSuite} to ${renderedTeam}`;
    } else if (type === "TEAM_SUITE_DELETED") {
      return `Removed ${renderedSuite} from ${renderedTeam}`;
    }

    return `${eventVerb} ${renderedSuite} in ${renderedTeam}`;
  } else if (type === "ORGANIZATION_BUILD_EXPORT_UPDATED") {
    return `Updated build export`;
  }

  return `${eventVerb} ${renderedSubject}`;
};

type Props = {
  auditEvent: {
    uuid: string;
    type: string;
    occurredAt: string;
    actor?: {
      type?: string;
      name?: string;
      node?: {
        name?: string;
        avatar?: {
          url?: string;
        };
      };
    };
    subject: {
      type: string;
      name?: string;
      node?: {
        name?: string;
        team?: {
          name?: string;
        };
        user?: {
          name?: string;
        };
        pipeline?: {
          name?: string;
        };
        registry?: {
          name?: string;
        };
        suite?: {
          name?: string;
        };
      };
    };
  };
};

const AuditLogRow = ({ auditEvent }: Props) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const handleHeaderClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Panel.Row>
      <div>
        <div
          className="flex items-center cursor-pointer hover-bg-silver mxn3 py2 px3"
          style={{
            marginTop: -10,
            // this is a hack to give the expandable section
            // a top border, without it taking up any space
            boxShadow: "0 1px 0 var(--gray-500)",
          }}
          onClick={handleHeaderClick}
        >
          <div className="flex-auto flex items-center">
            <div className="flex-none self-start icon-mr">
              <ActorAvatar auditEvent={auditEvent} />
            </div>
            <div className="flex-auto md-flex lg-flex items-center">
              <h2 className="flex-auto line-height-3 font-size-1 h4 regular m0">
                <ActorName auditEvent={auditEvent} />
                {/* @ts-expect-error - TS2786 - 'EventSentence' cannot be used as a JSX component. */}
                <EventSentence auditEvent={auditEvent} />
              </h2>
              <FriendlyTime
                clickable={false}
                className="flex-none dark-gray"
                value={auditEvent.occurredAt}
              />
            </div>
          </div>
          <div className="flex-none ml2">
            <RevealableDownChevron
              className="dark-gray"
              revealed={isExpanded}
            />
          </div>
        </div>
        <div
          className="mxn3 overflow-hidden"
          style={{
            transition: "max-height 400ms",
            marginBottom: -10,
            maxHeight: isExpanded ? "80vh" : 0,
            overflowY: "auto",
            // @ts-expect-error - TS2322 - Type '{ transition: "max-height 400ms"; marginBottom: number; maxHeight: string | number; overflowY: "auto"; overflowScrolling: string; WebkitOverflowScrolling: "touch"; }' is not assignable to type 'Properties<string | number, string & {}>'.
            overflowScrolling: "touch",
            WebkitOverflowScrolling: "touch",
          }}
        >
          <AuditLogDrawer auditEvent={auditEvent} hasExpanded={isExpanded} />
        </div>
      </div>
    </Panel.Row>
  );
};

export default createFragmentContainer(AuditLogRow, {
  auditEvent: graphql`
    fragment row_auditEvent on AuditEvent {
      ...Drawer_auditEvent
      uuid
      type
      occurredAt
      actor {
        type
        name
        node {
          ... on User {
            name
            avatar {
              url
            }
          }
          ... on Agent {
            name
          }
        }
      }
      subject {
        type
        name
        node {
          ... on Organization {
            name
          }
          ... on Pipeline {
            name
          }
          ... on Team {
            name
          }
          ... on TeamMember {
            team {
              name
            }
            user {
              name
            }
          }
          ... on TeamPipeline {
            team {
              name
            }
            pipeline {
              name
            }
          }
          ... on TeamRegistry {
            team {
              name
            }
            registry {
              name
            }
          }
          ... on TeamSuite {
            team {
              name
            }
            suite {
              name
            }
          }
          ... on Secret {
            uuid
            organization {
              name
            }
          }
        }
      }
      context {
        __typename
      }
    }
  `,
});

import * as React from "react";
import { createRefetchContainer, graphql } from "react-relay";

import DocumentTitle from "react-document-title";
import { seconds } from "metrick/duration";

import PageWithContainer from "app/components/shared/PageWithContainer";
import Panel from "app/components/shared/Panel";
import ShowMoreFooter from "app/components/shared/ShowMoreFooter";

import AgentJobRow from "./AgentJobRow";

const PAGE_SIZE = 25;

type Props = {
  agent: {
    id: string;
    uuid: string;
    name: string;
    jobs: {
      count: number;
      edges: Array<{
        node: {
          uuid: string;
          state: string;
          passed: boolean;
          startedAt?: string;
        };
      }>;
    };
    organization: {
      slug: string;
      name: string;
    };
  };
  relay: any;
};

type State = {
  loading: boolean;
  pageSize: number;
};

class AgentJobsList extends React.PureComponent<Props, State> {
  state = {
    loading: false,
    pageSize: PAGE_SIZE,
  };

  // @ts-expect-error - TS2564 - Property '_agentRefreshTimeout' has no initializer and is not definitely assigned in the constructor.
  _agentRefreshTimeout: number;

  componentDidMount() {
    // Only bother setting up the delayed load and refresher if we've got an
    // actual agent to play with.
    if (this.props.agent && this.props.agent.uuid) {
      // This will cause a full refresh of the data every 3 seconds. This seems
      // very low, but chances are people aren't really looking at this page
      // for long periods of time.
      this.startTimeout();
    }
  }

  componentWillUnmount() {
    clearTimeout(this._agentRefreshTimeout);
  }

  startTimeout = () => {
    this._agentRefreshTimeout = setTimeout(
      this.fetchUpdatedData,
      seconds.bind(3),
    );
  };

  refetchVariables = () => {
    return {
      agentId: this.props.agent.id,
      pageSize: this.state.pageSize,
    };
  };

  fetchUpdatedData = () => {
    this.props.relay.refetch(
      this.refetchVariables(),
      null,
      () => {
        this.startTimeout();
      },
      { force: true },
    );
  };

  render() {
    return (
      <DocumentTitle
        title={`${this.props.agent.name} / Jobs · ${this.props.agent.organization.name}`}
      >
        <PageWithContainer>
          <Panel>
            <Panel.Header className="p3">
              <a
                href={`/organizations/${this.props.agent.organization.slug}/agents/${this.props.agent.uuid}`}
                className="black text-decoration-none hover-underline"
              >
                {this.props.agent.name}
              </a>
              / Jobs
            </Panel.Header>
            {this.renderJobList()}
          </Panel>
          <ShowMoreFooter
            connection={this.props.agent.jobs}
            loading={this.state.loading}
            onShowMore={this.handleShowMoreJobs}
            className="pt4"
          />
        </PageWithContainer>
      </DocumentTitle>
    );
  }

  renderJobList() {
    const jobs = this.props.agent.jobs.edges;

    if (jobs.length < 1) {
      return (
        <Panel.Section className="dark-gray">
          This agent has not run any jobs
        </Panel.Section>
      );
    }

    return jobs.map((edge) => (
      <AgentJobRow key={edge.node.uuid} job={edge.node} jobType="command" />
    ));
  }

  handleShowMoreJobs = () => {
    this.setState(
      (prevState) => ({
        loading: true,
        pageSize: prevState.pageSize + PAGE_SIZE,
      }),
      () => {
        this.props.relay.refetch(this.refetchVariables(), null, () => {
          this.setState({ loading: false });
        });
      },
    );
  };
}

export default createRefetchContainer(
  AgentJobsList,
  {
    // Hardcode the defaultValue because we can't interpolate the PAGE_SIZE const into the graphql string
    agent: graphql`
      fragment AgentJobsList_agent on Agent
      @argumentDefinitions(pageSize: { type: "Int", defaultValue: 25 }) {
        id
        uuid
        name
        organization {
          name
          slug
        }
        jobs(first: $pageSize) {
          ...ShowMoreFooter_connection
          edges {
            node {
              ... on JobTypeCommand {
                uuid
                state
                passed
              }
              ...AgentJobRow_job
            }
          }
          count
        }
      }
    `,
  },
  graphql`
    query AgentJobsListRefetchQuery($agentId: ID!, $pageSize: Int!) {
      agent: node(id: $agentId) {
        ...AgentJobsList_agent @arguments(pageSize: $pageSize)
      }
    }
  `,
);

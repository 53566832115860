import classNames from "classnames";

import { getCssValue } from "app/lib/cssValues";
import Dropdown from "app/components/shared/Dropdown";

import getLabelColor from "./utils/getLabelColor";
import getStepClassName from "./utils/getStepClassName";
import JobGroup from "./utils/jobGroup";
import {
  StepContentWrapper,
  StepIcon,
  StepName,
  Step,
  StepProps,
} from "./Step";

function getWidthForParallelJobCount(parallelGroupTotal: number) {
  return 60 + (parallelGroupTotal.toString(10).length - 1) * 6;
}

function idealItemsPerRow(itemCount: number) {
  // If we're less than 6, just use one row
  if (itemCount < 6) {
    return itemCount;
  }

  // If we've got less than 16 items, just use two rows
  if (itemCount < 16) {
    return Math.ceil(itemCount / 2);
  }

  // If we've got less than 32 items, just use four rows
  if (itemCount < 32) {
    return Math.ceil(itemCount / 4);
  }

  // Otherwise, we compute something which looks decent
  if (itemCount < 128) {
    return Math.ceil(itemCount / Math.ceil(itemCount / 10));
  }

  return Math.ceil(itemCount / Math.ceil(itemCount / 20));
}

export default function StepGroup({
  job: group,
  build,
  buildStore,
  stepClassName,
  className,
}: StepProps<JobGroup> & { className?: string }) {
  let desiredWidth = 450;
  const widthForEachJob = getWidthForParallelJobCount(group.total || 0);

  if (group.parallel) {
    const itemsPerRow = idealItemsPerRow(group.total || 0);
    desiredWidth = widthForEachJob * itemsPerRow + 10 + 5 * itemsPerRow - 1;
  }

  const groupFailed =
    ["finished", "timed_out"].includes(group.state) && !group.passed;

  // This is pretty awful, but to consider a group hard failed we need to make sure that it
  // contains at least one job that is not a block/wait step, has not passed, and is not soft
  // failed or canceled. My kingdom for real group steps from the backend 🙏
  const groupHardFailed =
    groupFailed &&
    group.jobs.some((job) => {
      const softFailed = job.type === "script" && job.softFailed;
      const canceled = job.state === "canceled";
      const isBlockStep = job.type === "manual";
      const isWaitStep = job.type === "waiter";
      return (
        !isWaitStep && !isBlockStep && !job.passed && !softFailed && !canceled
      );
    });

  return (
    <Dropdown key={group.id} width={desiredWidth}>
      <button
        className={classNames(
          "right flex items-center cursor-pointer",
          stepClassName,
        )}
      >
        <StepContentWrapper shouldWrap={!groupHardFailed}>
          <span className="flex items-center">
            <StepIcon job={group} />
          </span>

          <StepContentWrapper shouldWrap={groupHardFailed}>
            <span
              className="truncate"
              style={{
                maxWidth: "12em",
              }}
            >
              <StepName job={group} useLabelForText={true} />
            </span>
            <span
              className="rounded white semi-bold small relative tabular-numerals px1"
              style={{
                color: "currentColor",
                border: `1px solid ${getLabelColor(group)}`,
                backgroundColor: getCssValue("--base-0"),
                lineHeight: 1.75,
              }}
            >
              {group.finishedCount}/{group.total || group.jobs.length}
            </span>
          </StepContentWrapper>
        </StepContentWrapper>
      </button>

      <div
        className={classNames("tabular-numerals my3", {
          mx1: group.parallel,
          "build-pipeline-job-popup--parallel": group.parallel,
          mx2: !group.parallel,
          mx4: !group.parallel,
          flex: !group.parallel,
          "flex-wrap": !group.parallel,
        })}
        data-testid="job-group"
        style={{
          gridTemplateColumns: group.parallel
            ? `repeat(auto-fill, minmax(${Math.round(widthForEachJob)}px, 1fr))`
            : undefined,
          gap: "5px",
        }}
      >
        {group.jobs.map((job) => (
          <Step
            className={className}
            key={job.id}
            job={job}
            group={group}
            build={build}
            buildStore={buildStore}
          />
        ))}
      </div>
    </Dropdown>
  );
}

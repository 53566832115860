/**
 * @generated SignedSource<<b7bfc8056f972f45490995ace22b5512>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SuitesChooser_team$data = {
  readonly id: string;
  readonly organization: {
    readonly suites: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly name: string;
          readonly slug: string;
        } | null;
      } | null> | null;
    } | null;
  } | null;
  readonly permissions: {
    readonly teamSuiteCreate: {
      readonly allowed: boolean;
    } | null;
  };
  readonly slug: string;
  readonly " $fragmentType": "SuitesChooser_team";
};
export type SuitesChooser_team$key = {
  readonly " $data"?: SuitesChooser_team$data;
  readonly " $fragmentSpreads": FragmentRefs<"SuitesChooser_team">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "suiteAddSearch"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "teamSelector"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SuitesChooser_team",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 10
            },
            {
              "kind": "Literal",
              "name": "order",
              "value": "NAME"
            },
            {
              "kind": "Variable",
              "name": "search",
              "variableName": "suiteAddSearch"
            },
            {
              "kind": "Variable",
              "name": "team",
              "variableName": "teamSelector"
            }
          ],
          "concreteType": "SuiteConnection",
          "kind": "LinkedField",
          "name": "suites",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SuiteEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Suite",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    },
                    (v1/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TeamPermissions",
      "kind": "LinkedField",
      "name": "permissions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Permission",
          "kind": "LinkedField",
          "name": "teamSuiteCreate",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "allowed",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Team",
  "abstractKey": null
};
})();

(node as any).hash = "ecb61fc4c76b6ecefdb52695e0b46eed";

export default node;

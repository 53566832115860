import * as React from "react";

import AgentStore from "app/stores/AgentStore";

import QueryRulesResult from "./Result";

type Props = {
  accountID: string;
  name: string;
  value: Array<string> | null | undefined;
  onChange: (queryRules: Array<string>) => unknown;
};

type State = {
  agents:
    | Array<{
        id: string;
        name: string;
        matched: boolean;
        metaData: Array<string> | null | undefined;
      }>
    | null
    | undefined;
  counts: {
    total: number;
    matched: number;
  };
  error: string | null | undefined;
};

const getStateFromStore = (
  accountID: string,
  query: Array<string> | null | undefined,
): State =>
  // @ts-expect-error - roperty 'agents' is optional in type '{ error: string; agents?: undefined; counts?: undefined; query?: undefined; }' but required in type 'State'
  AgentStore.getAllForAccountWithQuery(accountID, query || []) || {
    agents: null,
    counts: { total: 0, matched: 0 },
    error: null,
  };

export default class AgentQueryRules extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = getStateFromStore(this.props.accountID, this.props.value);
  }

  componentDidMount() {
    AgentStore.addChangeListener(this.handleStoreChange);
  }

  componentWillUnmount() {
    AgentStore.removeChangeListener(this.handleStoreChange);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (this.props.value !== nextProps.value) {
      this.setState(getStateFromStore(this.props.accountID, nextProps.value));
    }
  }

  renderAgents() {
    if (this.state.agents != null) {
      if (this.state.agents.length >= 1) {
        const nodes = this.state.agents.map((agent) => (
          <QueryRulesResult
            key={agent.id}
            onMetaDataClick={this.handleMetaDataClick}
            {...agent}
          />
        ));

        const message =
          this.state.counts.total === 0
            ? "No agents connected"
            : this.state.counts.total === this.state.counts.matched
              ? `Matches all ${this.state.counts.total} connected agents`
              : `Matches ${this.state.counts.matched} out of ${this.state.counts.total} connected agents`;

        return (
          <div>
            <div className="AgentQueryRulesComponent__AgentContainer_Agents scrollbar">
              {nodes}
            </div>
            <div className="AgentQueryRulesComponent__AgentContainer__Info">
              {message}
            </div>
          </div>
        );
      }

      return (
        <div className="AgentQueryRulesComponent__AgentContainer__Message text-muted">
          No connected agents
        </div>
      );
    } else if (this.state.error) {
      return (
        <div className="AgentQueryRulesComponent__AgentContainer__Message">
          {this.state.error}
        </div>
      );
    }

    return (
      <div className="AgentQueryRulesComponent__AgentContainer__Message">
        Loading…
      </div>
    );
  }

  render() {
    return (
      <div>
        <div className="AgentQueryRulesComponent">
          <div className="AgentQueryRulesComponent__TextareaContainer">
            <textarea
              className="input AgentQueryRulesComponent__TextareaContainer__Textarea"
              name={this.props.name}
              value={(this.props.value || []).join("\n")}
              // @ts-expect-error - event handler mismatch
              onChange={this.handleRulesChange}
              placeholder="queue=default"
            />
          </div>
          <div className="AgentQueryRulesComponent__AgentContainer">
            {this.renderAgents()}
          </div>
        </div>
      </div>
    );
  }

  handleRulesChange = (evt: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (!(evt.target instanceof HTMLTextAreaElement)) {
      return;
    }

    return this.props.onChange(evt.target.value.split("\n"));
  };

  handleMetaDataClick = (metaData: string) => {
    // If the current value is empty, just use the entire meta data
    let newValue;
    if (!this.props.value || this.props.value.join("") === "") {
      newValue = [metaData];
    } else {
      // Duplicate the current value and append the meta data value
      // @ts-expect-error - TS2769 - No overload matches this call.
      newValue = [].concat(this.props.value);
      newValue.push(metaData);
    }

    return this.props.onChange(newValue);
  };

  handleStoreChange = () => {
    this.setState(getStateFromStore(this.props.accountID, this.props.value));
  };
}

/* eslint-disable react/jsx-no-bind */

import * as React from "react";
import { QueryRenderer, graphql } from "react-relay";

import Bugsnag from "app/lib/Bugsnag";
import Environment from "app/lib/relay/environment";

import SectionLoader from "app/components/shared/SectionLoader";

import JobActivityTimelineList from "./JobActivityTimelineList";
import "./styles.css";

type Props = {
  job: {
    id: string;
  };
};

type QueryRendererData = {
  error: Error | null | undefined;
  props: any;
};

export default class JobActivityTimeline extends React.PureComponent<Props> {
  environment = Environment.get();

  static displayName = "JobActivityTimeline";

  static query = graphql`
    query JobActivityTimelineQuery($id: ID!) {
      job(uuid: $id) {
        ...JobActivityTimelineList_job
      }
    }
  `;

  render() {
    return (
      <QueryRenderer
        fetchPolicy="store-and-network"
        cacheConfig={{ force: true }}
        environment={this.environment}
        query={JobActivityTimeline.query}
        variables={{ id: this.props.job.id }}
        render={({ error, props }: QueryRendererData) => {
          if (error) {
            Bugsnag.notify(error);
            return null; // TODO: Handle this!
          }

          if (!props) {
            return <SectionLoader />;
          }

          return <JobActivityTimelineList {...props} />;
        }}
      />
    );
  }
}

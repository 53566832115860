/**
 * @generated SignedSource<<474d3572ef3c569a908b588c97dc925f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BuildBlockedStates = "FAILED" | "PASSED" | "RUNNING" | "%future added value";
export type BuildStates = "BLOCKED" | "CANCELED" | "CANCELING" | "CREATING" | "FAILED" | "FAILING" | "NOT_RUN" | "PASSED" | "RUNNING" | "SCHEDULED" | "SKIPPED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type build_build$data = {
  readonly blockedState: BuildBlockedStates | null;
  readonly canceledAt: any | null;
  readonly commit: string;
  readonly createdAt: any | null;
  readonly finishedAt: any | null;
  readonly id: string;
  readonly message: string | null;
  readonly pipeline: {
    readonly name: string;
  };
  readonly state: BuildStates;
  readonly url: string;
  readonly uuid: string;
  readonly " $fragmentType": "build_build";
};
export type build_build$key = {
  readonly " $data"?: build_build$data;
  readonly " $fragmentSpreads": FragmentRefs<"build_build">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "build_build",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "uuid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "blockedState",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canceledAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "finishedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "commit",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Pipeline",
      "kind": "LinkedField",
      "name": "pipeline",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Build",
  "abstractKey": null
};

(node as any).hash = "3e89c7e2364e0ed8843c2d20144380a3";

export default node;

import { Outcome, State, Step } from "app/lib/pipeline/Step";
import { isTerminalBuildState } from "app/constants/BuildStates";
import { Build } from "../BuildContext";

type Props = Pick<Step, "state" | "outcome" | "missingDependencies"> & {
  waitingOnConcurrencyGroup?: boolean;
};

export const textColorForStep = ({
  state,
  outcome,
  missingDependencies = [],
  waitingOnConcurrencyGroup = false,
}: Props) => {
  if (waitingOnConcurrencyGroup) {
    return "text-orange-500";
  }

  if (missingDependencies.length) {
    return "text-red-500";
  }

  switch (state) {
    case State.Finished:
      switch (outcome) {
        case Outcome.Neutral:
        case Outcome.Passed:
          return "text-lime-500";
        case Outcome.HardFailed:
        case Outcome.SoftFailed:
          return "text-red-500";
        default:
          return "text-slate-500";
      }
    case State.Failing:
      return "text-red-500";
    case State.Running:
      return "text-orange-500";
    case State.Ready:
    case State.WaitingForDependencies:
    default:
      return "text-charcoal-700";
  }
};

export const bgColorForStep = (
  step: Pick<Step, "state" | "outcome" | "missingDependencies">,
  build?: Pick<Build, "state"> | null,
) => {
  const { missingDependencies = [] } = step;
  if (missingDependencies.length) {
    if (build?.state && isTerminalBuildState(build.state)) {
      return "bg-red-100 border-red-500";
    }
    return "bg-white border-slate-500";
  }

  switch (step.state) {
    case State.Failing:
      return "bg-red-100 border-red-500";
    case State.Finished:
      switch (step.outcome) {
        case Outcome.Neutral:
        case Outcome.Passed:
          return "bg-green-100 border-lime-500";
        case Outcome.HardFailed:
        case Outcome.SoftFailed:
          return "bg-red-100 border-red-500";
        default:
          return "bg-white border-slate-500";
      }
    case State.Running:
      return "bg-orange-100 border-orange-500";
    default:
      return "bg-white border-slate-500";
  }
};

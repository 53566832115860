import styled from "styled-components";
import Emojify from "app/components/shared/Emojify";

type Props = {
  emoji: string;
  label: string;
  note: string;
  onClick: () => void;
  selected: boolean;
};

const RowButton = styled.button<{ selected: boolean }>`
  background-color: transparent;
  width: 100%;
  align-items: center;
  font-family: inherit;
  font-size: 14px;

  ${(props) =>
    props.selected &&
    `
    background-color: var(--purple-100);
    color: var(--purple-600);
  `}

  .emoji {
    width: 20px;
    height: 20px;
  }
`;

const Row = ({ emoji, note, label, onClick, selected }: Props) => {
  return (
    <li>
      <RowButton
        className={`border-none rounded p2 bold flex cursor-pointer ${
          selected ? "" : "hover-bg-silver"
        }`}
        onClick={onClick}
        selected={selected}
      >
        <Emojify text={emoji} className="mr3" />
        {label}{" "}
        <span className={`ml1 ${selected ? "purple" : "dark-gray"} regular`}>
          {note}
        </span>
      </RowButton>
    </li>
  );
};

export default Row;

/**
 * @generated SignedSource<<67aeac406fd160782b1022d087fd6a75>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JobEventCanceled_event$data = {
  readonly actor: {
    readonly node: {
      readonly name?: string;
      readonly organization?: {
        readonly slug: string;
      } | null;
      readonly uuid?: string;
      readonly " $fragmentSpreads": FragmentRefs<"AgentBadge_agent">;
    } | null;
  };
  readonly exitStatus?: any;
  readonly timestamp: any;
  readonly " $fragmentType": "JobEventCanceled_event";
};
export type JobEventCanceled_event$key = {
  readonly " $data"?: JobEventCanceled_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"JobEventCanceled_event">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "JobEventCanceled_event",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timestamp",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "exitStatus",
          "storageKey": null
        }
      ],
      "type": "JobEventCanceled",
      "abstractKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "JobEventActor",
      "kind": "LinkedField",
      "name": "actor",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "AgentBadge_agent"
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "uuid",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Organization",
                  "kind": "LinkedField",
                  "name": "organization",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "slug",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "Agent",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "JobEvent",
  "abstractKey": "__isJobEvent"
};

(node as any).hash = "131569304bc324c673cbc9c4e365aef7";

export default node;

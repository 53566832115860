import { createFragmentContainer, graphql } from "react-relay";
import JobEvent from "../JobEvent";

type Props = {
  event: any;
  previousTimestamp: string | null | undefined;
};

function JobEventAgentDisconnected({ event, previousTimestamp }: Props) {
  return (
    <JobEvent timestamp={event.timestamp} previousTimestamp={previousTimestamp}>
      <JobEvent.Label>Agent Disconnected</JobEvent.Label>
      <JobEvent.Description>
        The agent disconnected and so the job was re-scheduled.
      </JobEvent.Description>
    </JobEvent>
  );
}

export default createFragmentContainer(JobEventAgentDisconnected, {
  event: graphql`
    fragment JobEventAgentDisconnected_event on JobEvent {
      ... on JobEvent {
        timestamp
      }
    }
  `,
});

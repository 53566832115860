/**
 * @generated SignedSource<<0ed421f082656687e831ed935d2f7856>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type TeamMemberDeleteInput = {
  clientMutationId?: string | null;
  id: string;
};
export type MembersTeamDeleteMutation$variables = {
  input: TeamMemberDeleteInput;
};
export type MembersTeamDeleteMutation$data = {
  readonly teamMemberDelete: {
    readonly deletedTeamMemberID: string;
    readonly team: {
      readonly members: {
        readonly count: number;
      } | null;
    } | null;
  } | null;
};
export type MembersTeamDeleteMutation = {
  response: MembersTeamDeleteMutation$data;
  variables: MembersTeamDeleteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedTeamMemberID",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "TeamMemberConnection",
  "kind": "LinkedField",
  "name": "members",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "count",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MembersTeamDeleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "TeamMemberDeletePayload",
        "kind": "LinkedField",
        "name": "teamMemberDelete",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Team",
            "kind": "LinkedField",
            "name": "team",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MembersTeamDeleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "TeamMemberDeletePayload",
        "kind": "LinkedField",
        "name": "teamMemberDelete",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Team",
            "kind": "LinkedField",
            "name": "team",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b9be7d72a295f15f01b7d480b9e67dbf",
    "id": null,
    "metadata": {},
    "name": "MembersTeamDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation MembersTeamDeleteMutation(\n  $input: TeamMemberDeleteInput!\n) {\n  teamMemberDelete(input: $input) {\n    deletedTeamMemberID\n    team {\n      members {\n        count\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d3df3036aa5fb0343c0d804d3dc88fc7";

export default node;

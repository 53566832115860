import { useMemo } from "react";
import RelayModernPreloader from "./RelayModernPreloader";

export default function usePreloadedRelayQuery(
  enabled: boolean,
  relayConfig: {
    query: any;
    variables?: any;
    environment: any;
  },
  dependencies?: ReadonlyArray<unknown> | null,
) {
  // NOTE: it's a little weird using `useMemo` here, but we need to preload the query
  // once only, and as early as possible (useEffect is too late) to prevent the loading
  // spinner from showing up,
  useMemo(() => {
    if (enabled) {
      RelayModernPreloader.preload(relayConfig);
    }
    // @ts-expect-error - TS2345 - Argument of type 'readonly unknown[] | null | undefined' is not assignable to parameter of type 'DependencyList | undefined'.
  }, dependencies);
}

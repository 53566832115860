import Dropdown from "app/components/shared/Dropdown";
import Icon from "app/components/shared/Icon";

type Props = {
  name?: string;
  children: React.ReactNode;
  classNames?: string;
};

const Tooltip = ({ name, children, classNames }: Props) => (
  <Dropdown className={`ta-tooltip group ${classNames || ""}`} width={300}>
    <button
      aria-label={name || "Learn more"}
      className="unstyled-button no-title-tooltip slate-500 hover:text-slate-800 transition-colors group-[.Dropdown-showing]:text-slate-800"
    >
      <Icon
        icon="heroicons/outline/information-circle"
        style={{ width: "20px" }}
      />
    </button>

    <div className="p4 text-xs">{children}</div>
  </Dropdown>
);

export default Tooltip;

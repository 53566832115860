import Environment from "app/lib/relay/environment";
import { QueryRenderer, graphql } from "react-relay";

import usePreloadedRelayQuery from "app/lib/usePreloadedRelayQuery";
import AnnotationsList from "./AnnotationsList";

type AnnotationsListRendererProps = {
  params: {
    buildSlug: string;
  };
  organization: string;
  pipeline: string;
  number: number;
  filterIssues: boolean;
  hasFailedJobs: boolean;
};

export default function AnnotationsListRenderer({
  params,
  ...annotationsProps
}: AnnotationsListRendererProps) {
  const environment = Environment.get();
  const query = graphql`
    query AnnotationsListRendererQuery(
      $buildSlug: ID!
      $order: AnnotationOrder!
    ) {
      build(slug: $buildSlug) {
        ...AnnotationsList_build @arguments(order: $order)
      }
    }
  `;

  const annotationsOrder = window.Features.PriorityOrderAnnotations
    ? "PRIORITY_RECENTLY_CREATED"
    : "RECENTLY_CREATED";

  const variables = {
    buildSlug: params.buildSlug,
    order: annotationsOrder,
  } as const;

  usePreloadedRelayQuery(
    true,
    {
      environment,
      query,
      variables: variables,
    },
    [params.buildSlug, annotationsOrder],
  );

  function renderQuery({ props }: { props: any }) {
    if (!props) {
      return null;
    }

    return (
      <AnnotationsList
        buildSlug={params.buildSlug}
        {...props}
        {...annotationsProps}
      />
    );
  }

  return (
    <QueryRenderer
      fetchPolicy="store-and-network"
      cacheConfig={{ force: true }}
      environment={environment}
      query={query}
      variables={variables}
      render={renderQuery}
    />
  );
}

import React from "react";
import styled from "styled-components";

const CopyButton = styled.button.attrs({
  type: "button",
  className: "btn btn-depth btn-small bg-white",
})`
  height: 32px;
  width: 32px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before,
  &:after {
    content: "";
    height: 9px;
    width: 9px;
    position: absolute;
    top: 12px;
    right: -3px;
    border-radius: 0.5px;
    transform: rotate(-45deg);
  }

  &:before {
    z-index: -1;
    /* This is the same as --depth-btn but our CSS vars don't work in Styled Components */
    box-shadow:
      inset 0px 1px 0px 0px #fff,
      0px 0px 0px 1px rgba(0, 0, 0, 0.15),
      0px 1px 0px 0px rgba(0, 0, 0, 0.09),
      0px 2px 2px 0px rgba(0, 0, 0, 0.04),
      0px 3px 3px 0px rgba(0, 0, 0, 0.02),
      0px 4px 4px 0px rgba(0, 0, 0, 0.01);
  }

  &:after {
    background-color: white;
  }
`;

export default function CopyPermalink({
  onCopy,
  ...props
}: {
  onCopy: React.MouseEventHandler<HTMLButtonElement>;
}) {
  const [copied, setCopied] = React.useState(false);

  const handleClick = React.useCallback(
    (event) => {
      onCopy(event);

      // Unrender the button for a frame so that the line number is un-hovered
      setCopied(true);
      window.requestAnimationFrame(() => setCopied(false));
    },
    [onCopy],
  );

  if (copied) {
    return null;
  }

  return (
    <CopyButton title="Copy link to this line" onClick={handleClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
      >
        <path
          fill="currentColor"
          d="m7.775 3.275 1.25-1.25a3.5 3.5 0 1 1 4.95 4.95l-2.5 2.5a3.5 3.5 0 0 1-4.95 0 .751.751 0 0 1 1.06-1.06 1.998 1.998 0 0 0 2.83 0l2.5-2.5a2.002 2.002 0 0 0-2.83-2.83l-1.25 1.25a.751.751 0 0 1-1.06-1.06Zm-4.69 9.64a1.998 1.998 0 0 0 2.83 0l1.25-1.25a.751.751 0 0 1 1.261.535.751.751 0 0 1-.201.525l-1.25 1.25a3.5 3.5 0 0 1-4.95-4.95l2.5-2.5a3.5 3.5 0 0 1 4.95 0 .751.751 0 0 1-1.06 1.06 1.998 1.998 0 0 0-2.83 0l-2.5 2.5a1.997 1.997 0 0 0 0 2.83Z"
        />
      </svg>
    </CopyButton>
  );
}

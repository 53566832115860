import moment from "moment";

const formatDate = (date: string) => moment(date).format("DD MMM YYYY");

export interface UsagePeriod {
  start_date: string;
  end_date: string;
}

export interface DatePickerProps {
  usagePeriod: UsagePeriod;
  recentSubscriptionsPeriod: Array<UsagePeriod>;
}

export const formatDatePeriod = (period: UsagePeriod) =>
  `${formatDate(period.start_date)} - ${formatDate(period.end_date)}`;

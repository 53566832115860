import Button from "app/components/shared/Button";
import { useCallback, useState } from "react";
import RemoteButtonComponent from "app/components/shared/RemoteButtonComponent";
import Dialog from "app/components/shared/Dialog";
import BuildShowStore, { Build } from "app/stores/BuildShowStore";
import BuildsStore from "app/stores/BuildsStore";
import { twMerge } from "tailwind-merge";

interface Props {
  build: Build;
  store: BuildShowStore | BuildsStore;
  className?: string;
}

export function CancelButton({ build, store, className }: Props) {
  const [cancelJobDialogOpen, setCancelJobDialogOpen] = useState(false);

  const handleCancelJobDialogOpen = useCallback(() => {
    setCancelJobDialogOpen(true);
  }, []);

  const handleCancelJobDialogClose = useCallback(() => {
    setCancelJobDialogOpen(false);
  }, []);

  if (
    build.state === "creating" ||
    build.state === "scheduled" ||
    build.state === "started" ||
    build.state === "blocked" ||
    build.state === "failing"
  ) {
    if (build.permissions.cancel.allowed) {
      // Success returns a fresh copy of the build with the new state.
      //
      // Error usually returns a message which is handled by the onAjaxError
      // handler above, but we might also have an odd build state or
      // something so also tell the store to reload itself.
      //
      // The redirect to the build page feels odd, but I'm preserving the
      // current behaviour for the moment. It could use a `store.reload()`
      // here instead to refresh the current state, in the case that a build
      // is now "Canceling" or something.
      return (
        <>
          <Button
            className={twMerge(
              "btn btn-danger build-cancel-button center",
              className,
            )}
            onClick={handleCancelJobDialogOpen}
          >
            <span>Cancel</span>
          </Button>

          <Dialog
            isOpen={cancelJobDialogOpen}
            onRequestClose={handleCancelJobDialogClose}
            width={400}
          >
            <div className="px4 pb4">
              <h2 className="h2">Are you sure?</h2>
              <p className="mb3">
                Canceling the build will stop all running and pending jobs from
                running. Continue?
              </p>
              <RemoteButtonComponent
                url={build.cancelPath}
                method="post"
                loadingText="Canceling…"
                className="btn btn-danger block center"
                onSuccess={(_event, response) =>
                  response && store.loadAndEmit(response)
                }
                onError={(_event, _response) =>
                  window.location.assign(build.path)
                }
              >
                <span>Yes, Cancel Build</span>
              </RemoteButtonComponent>
            </div>
          </Dialog>
        </>
      );
    } else if (build.permissions.cancel.reason !== "anonymous") {
      return (
        <a
          href="#"
          aria-label={build.permissions.cancel.message}
          className="btn btn-danger build-cancel-button center mx1"
          rel="nofollow"
        >
          <span>Cancel</span>
        </a>
      );
    }
  }

  return null;
}

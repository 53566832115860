export function repositoryProviderIcon(providerName?: string | null): string {
  const icon = frontendIcon(providerName);

  if (icon === "git") {
    return "custom/outline/provider-git";
  }

  return `custom/solid/provider-${icon}`;
}

function frontendIcon(providerName?: string | null): string {
  switch (providerName) {
    case "RepositoryProviderBitbucket":
      return "bitbucket";
    case "RepositoryProviderGithub":
      return "github";
    case "RepositoryProviderGithubEnterprise":
      return "github";
    case "RepositoryProviderGitlab":
      return "gitlab";
    case "RepositoryProviderGitlabCommunity":
      return "gitlab";
    default:
      return "git";
  }
}

import { GraphQLNonNull, buildClientSchema } from "graphql";

import Comment from "./Comment";
import RootField from "./RootField";
import Argument from "./Argument";
import Scalar from "./Scalar";
import GraphqlExplorerLayout from "../Layout";

import Spinner from "app/components/shared/Spinner";

type FieldsProps = {
  root: string;
  fields: any;
};

const Fields = ({ root, fields }: FieldsProps) => {
  const fieldEntries = Object.entries(fields);
  const totalFieldsCount = fieldEntries.length;

  return (
    <div className="CodeMirror cm-s-graphql">
      <div>
        <span className="cm-keyword">{root}</span>
        <span className="cm-punctuation"> {`{`}</span>
      </div>

      {fieldEntries.map(([name, field]: [any, any], fieldIndex: number) => {
        const argumentNodes = field.args.map((arg, argumentIndex) => {
          // Only include the ", " separator between each argument (not after the
          // last one).
          let separator;
          if (
            argumentIndex + 1 !== field.args.length &&
            field.args.length > 1
          ) {
            separator = <span className="cm-punctuation">, </span>;
          }

          // Unwrap GraphQLNonNull types so we don't link to them with the "!" in there
          let nonNull = false;
          let type = arg.type;

          if (type instanceof GraphQLNonNull) {
            nonNull = true;
            type = type.ofType;
          }

          return (
            <span key={arg.name}>
              <span className="cm-attribute">{arg.name}</span>
              <span className="cm-punctuation">:</span>{" "}
              <Scalar name={type.name} />
              {nonNull && <span className="cm-punctuation">!</span>}
              {separator}
            </span>
          );
        });

        // Insert an empty line between each field (except the last one).
        let fieldSeparatorNode;
        if (fieldIndex + 1 !== totalFieldsCount && totalFieldsCount > 1) {
          fieldSeparatorNode = <div>&nbsp;</div>;
        }

        return (
          <div key={name} className="ml3" style={{ fontSize: 12 }}>
            <Comment text={field.description} />
            <div>
              <a
                className="cm-property underline-dotted text-decoration-none"
                href={`/user/graphql/documentation/${root}/${name}`}
              >
                {name}
              </a>
              <span className="cm-punctuation">(</span>
              {argumentNodes}
              <span className="cm-punctuation">)</span>
            </div>
            {fieldSeparatorNode}
          </div>
        );
      })}
      <div>
        <span className="cm-punctuation"> {`}`}</span>
      </div>
    </div>
  );
};

const GraphQLExplorerDocumentation = ({ schema }: { schema: any }) => {
  const clientSchema: any = buildClientSchema(schema);
  return (
    <GraphqlExplorerLayout>
      {clientSchema ? (
        <div>
          <h2 className="text-2xl">Queries</h2>

          <p>
            <strong>GraphQL Queries</strong> are a way to fetch data. The
            Buildkite GraphQL API offers 2 types of top-level query fields:
            “finders” and{" "}
            <RootField name="query.viewer" className="cm-s-graphql monospace" />
            .
          </p>

          <p>
            The{" "}
            <RootField name="query.viewer" className="cm-s-graphql monospace" />{" "}
            field represents the current user using the GraphQL API (this will
            be you). This is the field you’ll use when you want to write queries
            like{" "}
            <em>
              “retrieve all the organizations the current user has access to”
            </em>
            {" or "}
            <em>“get the current users name and email address”</em>.
          </p>

          <p>
            The other fields such as{" "}
            <RootField name="query.agent" className="cm-s-graphql monospace" />{" "}
            and{" "}
            <RootField name="query.job" className="cm-s-graphql monospace" />{" "}
            are “finder” fields and can be used to find objects in the GraphQL
            API.
          </p>

          <p>
            <RootField name="query.node" className="cm-s-graphql monospace" />{" "}
            is a special type of “finder” field that allows you to lookup any
            record using only its GraphQL{" "}
            <Scalar name="ID" className="cm-s-graphql monospace" />. It doesn’t
            matter if the{" "}
            <Scalar name="ID" className="cm-s-graphql monospace" /> you have is
            for a build, job or user, you can pass its{" "}
            <Scalar name="ID" className="cm-s-graphql monospace" /> to this
            field, and it will return the associated record if it exists. This
            field is based on the{" "}
            <a
              href="https://relay.dev/docs/guides/graphql-server-specification/#object-identification"
              className="lime hover-lime text-decoration-none hover-underline"
            >
              Relay Object Identification Specification
            </a>
            .
          </p>

          <p>
            Below is the full list of fields we support as GraphQL queries. You
            can click through a field to learn more about how it’s used and what
            it returns.
          </p>

          <div
            className="border border-gray rounded px2 py3"
            style={{ borderLeftWidth: 4 }}
          >
            <Fields
              root="query"
              fields={clientSchema.getQueryType().getFields()}
            />
          </div>

          <h2 className="text-2xl">Mutations</h2>

          <p>
            <strong>GraphQL Mutations</strong> are GraphQL’s write operations.
            Mutations have only 1 argument called <Argument name="input" />, and
            it’s required for all mutations. The <Argument name="input" />{" "}
            argument is where you’ll supply the data that the mutation uses.
          </p>

          <p>
            Buildkite GraphQL Mutations follow the{" "}
            <a
              href="https://facebook.github.io/relay/graphql/mutations.html"
              className="lime hover-lime text-decoration-none hover-underline"
            >
              Relay Input Object Mutations Specification
            </a>
            .
          </p>

          <p>
            Below is the full list of mutations we support as GraphQL queries.
            You can click through a field to learn more about how it’s used and
            what it returns.
          </p>

          <div
            className="border border-gray rounded px2 py3"
            style={{ borderLeftWidth: 4 }}
          >
            <Fields
              root="mutation"
              fields={clientSchema.getMutationType().getFields()}
            />
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center">
          <Spinner />
        </div>
      )}
    </GraphqlExplorerLayout>
  );
};

export default GraphQLExplorerDocumentation;

type Props = {
  job: {
    packages: {
      indexUrl: string;
    };
  };
};

function Skeleton() {
  return (
    <div className="flex items-center border-gray py-3 px-4 mt-0 bg-gray-100">
      <div className="flex flex-row gap-2 w-full">
        <span className="inline-flex items-center gap-2 semi-bold flex-grow py-3">
          <div className="rounded aspect-square bg-gray-400 h-4 w-4 animate-pulse" />
          <div className="rounded bg-gray-400 w-40 h-2 animate-pulse" />
        </span>
      </div>
    </div>
  );
}

export default function Packages({ job }: Props) {
  return (
    <div className="p-4">
      <turbo-frame id="job_packages" src={job.packages.indexUrl}>
        <div className="flex flex-col gap-2">
          <Skeleton />
        </div>
      </turbo-frame>
    </div>
  );
}

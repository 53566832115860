import EmptyState from "app/components/shared/EmptyState";
import { flakyDatePeriod } from ".";

const FlakyEmptyState = ({
  query,
  suiteHasRecentRuns,
  suiteHasPreviousInstances,
  period,
}: {
  query?: string;
  suiteHasRecentRuns: boolean;
  suiteHasPreviousInstances: boolean;
  period?: flakyDatePeriod;
}) => {
  if (query) {
    return (
      <EmptyState emoji="🕳️" heading={`No tests match the query "${query}"`} />
    );
  }

  const emptyStateText = (period) => {
    switch (period) {
      case "1hour":
        return "hour";
      case "4hours":
        return "4 hours";
      case "1day":
        return "day";
      case "7days":
        return "7 days";
      case "14days":
        return "14 days";
      case "28days":
        return "28 days";
      default:
        return "28 days";
    }
  };

  if (suiteHasRecentRuns) {
    return (
      <EmptyState
        emoji="🪴"
        heading={
          suiteHasPreviousInstances
            ? `No flaky instances found in the past ${emptyStateText(period)}`
            : "No flaky instances found"
        }
        subheading={
          suiteHasPreviousInstances ? undefined : "It must be your day."
        }
      />
    );
  }

  return (
    <EmptyState
      emoji="🤖"
      heading={`No tests have been run in the past ${emptyStateText(period)}`}
      subheading="We haven’t received any test executions. Create a run and check back soon."
    />
  );
};

export default FlakyEmptyState;

import Pagination from "app/components/shared/Pagination";
import usePagination, {
  PaginationProps,
} from "app/components/analytics/shared/hooks/usePagination";
import ErrorState from "app/components/analytics/shared/ErrorState";
import EmptyState from "app/components/shared/EmptyState";
import { LoadingPanels } from "app/components/analytics/shared/LoadingStates";
import { Run } from "app/components/analytics/shared/type";
import Row from "./Row";
import Tooltip from "../../shared/Tooltips/BasicTooltip";

type RowRun = {
  run: Omit<Run, "duration">;
  url: string;
};

type PaginateProps = {
  indexUrl: string;
  paginate: true;
  endpoint: string;
  tooltip: string;
};

type NonPaginateProps = {
  indexUrl: string;
  paginate: false;
  runs: Run[];
  tooltip: string;
};

const Table = (props: PaginateProps | NonPaginateProps) => {
  const { currentData, pageCount, handleOnChange, loading, error } =
    usePagination(
      props.paginate ? { endpoint: props.endpoint } : { data: props.runs },
    );

  return (
    <TableData
      currentData={currentData}
      pageCount={pageCount}
      handleOnChange={handleOnChange}
      loading={loading}
      error={error}
      indexUrl={props.indexUrl}
      tooltip={props.tooltip}
    />
  );
};

export const TableData = ({
  currentData,
  loading,
  error,
  pageCount,
  handleOnChange,
  indexUrl,
  tooltip,
}: PaginationProps & {
  indexUrl: string;
  tooltip: string;
}) => {
  if (error) {
    return (
      <ErrorState
        emoji="🕳️"
        heading="There was an error loading the runs"
        subheading="Try refreshing the page; if the problem persists"
        urlText="let us know"
        url="mailto:support@buildkite.com"
      />
    );
  }

  if (!loading && currentData.length === 0) {
    return (
      <EmptyState
        emoji="🤖"
        heading="No tests have been run"
        subheading="We haven’t received any test executions. Create a run and check back soon."
      />
    );
  }

  return (
    <section>
      {loading ? (
        <LoadingPanels rows={10} columns={1} />
      ) : (
        <ul className="ta-panel">
          <li
            aria-hidden={true}
            className="ta-panel--heading grid-cols-[3rem_auto_6rem]"
          >
            <div>Result</div>
            <div className="main-section">Description</div>
            <div className="flex items-center justify-end">
              Duration
              <Tooltip classNames="ml-1" name="Run duration">
                <p className="font-semibold m-0 charcoal-800">Run duration</p>
                <p className="m-0 mt-1 charcoal-800">{tooltip}</p>
              </Tooltip>
            </div>
          </li>
          {currentData.map((run: RowRun) => (
            <Row {...run} indexUrl={indexUrl} key={run.run.id} />
          ))}
        </ul>
      )}
      <Pagination pageCount={pageCount} onPageChange={handleOnChange} />
    </section>
  );
};

export default Table;

import * as React from "react";

import Badge from "app/components/shared/Badge";
import Icon from "app/components/shared/Icon";
import Spinner from "app/components/shared/Spinner";

import FormInputHelp from "./FormInputHelp";
import FormInputLabel from "./FormInputLabel";

import * as Select from "@radix-ui/react-select";

// Should mirror the `SCM::Service::ProviderType` list
// in `/app/models/scm/service.rb`

enum SCMProviderType {
  Git = "git",
  GithubApp = "github_app",
  GithubCodeAccessApp = "github_code_access_app",
}

enum RepositoryURLType {
  SSH = "ssh_url",
  HTTPS = "clone_url",
}

type SCMServiceInfo = {
  uuid: string;
  repositories_url: string;
  provider_type: SCMProviderType;
  account_login: string;
  has_code_access: boolean;
};

type RepositoryInfo = {
  full_name: string;
  description: string;
  private: boolean;
  fork: boolean;
  language: string | null | undefined;
  html_url: string;
  ssh_url: string;
  clone_url: string;
  default_branch: string;
};

type ElementAttributes = {
  id?: string;
  name?: string;
  value?: string;
  required?: boolean;
  // The auto focus attributes are a bit weird. This type is used for typing attributes
  // from both HTML elements and React DOM elements, which have different casings, so we need to
  // support both for cross-compatibility
  autoFocus?: boolean; // React DOM casing
  autofocus?: boolean; // HTML casing
};

type PropsFromFormElements = {
  label: string | null | undefined;
  hint?: React.ReactNode;
  repositoryFieldAttrs: ElementAttributes;
};

type Props = {
  connectAccountURL: string;
  services: Array<SCMServiceInfo> | null | undefined;
  defaultServiceLogin: string | null | undefined;
  defaultRepository: RepositoryInfo | null | undefined;
  allowWebhooksCheckbox: boolean | null | undefined;
} & PropsFromFormElements;

type RepositoriesData = Array<RepositoryInfo>;

type State = {
  showFilterField: boolean;
  selectedServiceUuid?: string | null;
  repositories: RepositoriesData | null | undefined;
  selectedRepository?: RepositoryInfo | null;
  selectedRepositoryURLType: RepositoryURLType;
  suggestionsVisible: boolean;
  search: string | null | undefined;
  loading: boolean;
  loadingFailed: boolean;
};

const IGNORED_HTML_ATTRIBUTES = ["class", "type", "style"];

const getAttributeListFromElement = (
  element?: HTMLElement | null,
): ElementAttributes | null | undefined => {
  if (!element) {
    return;
  }

  // Filter the element's attributes, removing those we want to ignore
  const copyAttributeList = Array.from(element.attributes).filter(
    (attribute) => IGNORED_HTML_ATTRIBUTES.indexOf(attribute.name) === -1,
  );

  // Transform the list of attributes into an ElementAttributes object
  return copyAttributeList.reduce<Partial<ElementAttributes>>(
    (propsObject, { name, value }) => {
      propsObject[name] = value;
      return propsObject;
    },
    {},
  );
};

const SCM_PROVIDER_TYPE_ICON_MAP: Record<SCMProviderType, string> = {
  [SCMProviderType.Git]: "git",
  [SCMProviderType.GithubApp]: "github",
  [SCMProviderType.GithubCodeAccessApp]: "github",
};

const NO_ACCOUNT = "none";

const AccountIcon = ({ type }: { type?: SCMProviderType }) => {
  if (!type) {
    return null;
  }

  return (
    <Icon
      icon={SCM_PROVIDER_TYPE_ICON_MAP[type]}
      className="w-4 h-4 block"
      style={{ color: "#555555" }}
    />
  );
};

const AccountSelect = (props: {
  accounts?: SCMServiceInfo[] | null;
  selected?: SCMServiceInfo | null;
  onChange: (uuid: string | null) => void;
  connectAccountURL: string;
}) => {
  const { accounts, selected, onChange } = props;

  if (!accounts) {
    return null;
  }

  const handleChange = React.useCallback(
    (value: string) => {
      onChange(value === NO_ACCOUNT ? null : value);
    },
    [onChange],
  );

  return (
    <Select.Root
      onValueChange={handleChange}
      value={selected ? selected.uuid : NO_ACCOUNT}
      defaultValue={NO_ACCOUNT}
    >
      <Select.Trigger
        className="bg-white border rounded flex items-center py-[0.33em] px-[.86em] pr-[.33em] shadow-input border-[#ccc]"
        data-testid="account-select"
      >
        <Select.Value placeholder="Select account">
          <span className="flex gap-2 items-center">
            <span>
              {selected?.provider_type ? (
                <AccountIcon type={selected?.provider_type} />
              ) : (
                <Icon icon="git" className="w-4 h-4" />
              )}
            </span>
            <span>{selected?.account_login || "Any account"}</span>
          </span>
        </Select.Value>
        <Select.Icon>
          <Icon icon="custom/outline/chevron-down" />
        </Select.Icon>
      </Select.Trigger>

      <Select.Portal>
        <Select.Content>
          <Select.ScrollUpButton />
          <Select.Viewport className="bg-white rounded shadow-depth-150">
            <Select.Group className="p-1">
              <Select.Item
                value={NO_ACCOUNT}
                className="px-2 py-1 flex items-center gap-2 hover:text-purple-600 hover:bg-purple-100 hover:outline-none rounded cursor-pointer aria-[selected=true]:bg-purple-100 aria-[selected=true]:text-purple-600"
              >
                <AccountIcon type={SCMProviderType.Git} />
                <span className="flex-1">
                  <Select.ItemText>Any account</Select.ItemText>
                </span>
                <Select.ItemIndicator>
                  <Icon
                    aria-hidden="true"
                    icon="heroicons/16/solid/check"
                    className="w-4 h-4 block"
                  />
                </Select.ItemIndicator>
              </Select.Item>

              {accounts.map((service) => (
                <Select.Item
                  key={service.uuid}
                  value={service.uuid}
                  className="px-2 py-1 flex items-center gap-2 hover:text-purple-600 hover:bg-purple-100 hover:outline-none rounded cursor-pointer aria-[selected=true]:bg-purple-100 aria-[selected=true]:text-purple-600"
                >
                  <AccountIcon type={service.provider_type} />
                  <span className="flex-1">
                    <Select.ItemText>
                      {service.account_login}
                      {service.has_code_access && " (with code access)"}
                    </Select.ItemText>
                  </span>
                  <Select.ItemIndicator>
                    <Icon
                      aria-hidden="true"
                      icon="heroicons/16/solid/check"
                      className="w-4 h-4 block"
                    />
                  </Select.ItemIndicator>
                </Select.Item>
              ))}
            </Select.Group>

            {props.connectAccountURL && (
              <>
                <Select.Separator className="border-b" />

                <Select.Group className="p-1">
                  <button
                    className="px-2 py-1 flex w-full items-center gap-2 hover:text-purple-600 hover:bg-purple-100 rounded"
                    // eslint-disable-next-line react/jsx-no-bind
                    onClick={() =>
                      (window.location.href = props.connectAccountURL)
                    }
                  >
                    <Icon
                      icon="heroicons/16/solid/plus"
                      className="w-4 h-4 block"
                      aria-hidden="true"
                    />
                    <span>Add account</span>
                  </button>
                </Select.Group>
              </>
            )}
          </Select.Viewport>
          <Select.ScrollDownButton />
          <Select.Arrow />
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  );
};

export default class ServiceRepositorySelector extends React.PureComponent<
  Props,
  State
> {
  static getDerivedPropsFromFormBuilderElements(
    element: HTMLElement,
  ): PropsFromFormElements {
    // Helper method to automatically clone the props from form elements
    // created using a form_builder component.
    //
    // `element` should be an HTML element which houses a repository URL field.
    //
    // This throws TypeErrors if the element doesn't contain the required label
    // and hint fields, as well as at least the repository field.

    const labelElement =
      element.querySelector<HTMLElement>(".FormField__Label");
    if (!labelElement) {
      throw new TypeError(
        "[ServiceRepositorySelector] Could not get props for: 'FormField__Label' element is missing",
      );
    }

    const hintElement = element.querySelector<HTMLElement>(".FormField__Hint");
    if (!hintElement) {
      throw new TypeError(
        "[ServiceRepositorySelector] Could not get props for: 'FormField__Hint' element is missing",
      );
    }

    const repositoryFieldAttrs = getAttributeListFromElement(
      element.querySelector<HTMLInputElement>('input[name*="[repository]"]'),
    );
    if (!repositoryFieldAttrs) {
      throw new TypeError(
        "[ServiceRepositorySelector] Could not get 'repository' field props",
      );
    }
    // This is a bit of a hack to handle the fact that the form_builder generates HTML
    // elements with HTML attribute name casing, but React expects camelCased props.
    if (repositoryFieldAttrs?.autofocus !== undefined) {
      repositoryFieldAttrs.autoFocus = repositoryFieldAttrs.autofocus;
      delete repositoryFieldAttrs.autofocus;
    }

    return {
      label: labelElement.innerText,
      hint: hintElement.innerText,
      repositoryFieldAttrs,
    };
  }

  repositorySearchTimeout: number | null | undefined;
  selectorInputRef = React.createRef<HTMLInputElement>();
  selectorWrapperRef = React.createRef<HTMLDivElement>();

  state: State = {
    showFilterField: true,
    selectedServiceUuid: undefined,
    repositories: null,
    selectedRepository: undefined,
    selectedRepositoryURLType: RepositoryURLType.SSH,
    suggestionsVisible: false,
    search: null,
    loading: false,
    loadingFailed: false,
  };

  static getDerivedStateFromProps(props: Props, state: State) {
    // If we don't have a list of services, we won't even show the selector.
    // Calling code should probably instead not mount the component,
    // but otherwise this is a safe fallback.
    if (!props.services) {
      return null;
    }

    // If the selectedServiceUuid already exists, we don't want to re-set it
    if (state.selectedServiceUuid !== undefined) {
      return null;
    }

    let selectedService: SCMServiceInfo | undefined;

    // Select the first account if there's only one to select from.
    if (props.services.length === 1) {
      selectedService = props.services[0];
    } else {
      // Okay, if we have services, find the one that matches our provided
      // account_login, if there is one, so we can show the dropdown with it.
      selectedService = props.services.find(
        (service) => service.account_login === props.defaultServiceLogin,
      );
    }

    // If we've been given a defaultRepository, set our repo to that
    const selectedRepository = props.defaultRepository;

    const initialRepositoryURL = props.repositoryFieldAttrs.value;

    // Work out the selected repository URL type based on the initial value.
    // This returns a RepositoryURLType enum key.
    const selectedRepositoryURLTypeKey =
      (selectedRepository &&
        initialRepositoryURL &&
        Object.keys(RepositoryURLType).find((type: string) => {
          // We haven't validated whether the URL ends in .git, so we may end up loading
          // up URLs that don't end in .git, so we'll strip that off for comparison here
          return (
            selectedRepository[RepositoryURLType[type]].replace(
              /\.git$/,
              "",
            ) === initialRepositoryURL.replace(/\.git$/, "")
          );
        })) ||
      "SSH";

    return {
      selectedServiceUuid: selectedService && selectedService.uuid,
      selectedRepository,
      selectedRepositoryURLType:
        RepositoryURLType[selectedRepositoryURLTypeKey],
      // Hide the filter field if we've got a default repository
      showFilterField: !selectedRepository,
    };
  }

  get selectedService() {
    if (!this.state.selectedServiceUuid) {
      return null;
    }

    if (!this.props.services) {
      return null;
    }

    return this.props.services.find(
      (service) => service.uuid === this.state.selectedServiceUuid,
    );
  }

  componentDidMount() {
    // Fetch the repositories, but only if we're showing the filter field
    if (this.state.showFilterField) {
      this.fetchRepositories();
    }
  }

  handleGlobalFocus = (event: Event) => {
    // If suggestions already aren't visible, do nothing
    // This state shouldn't be possible, but just in case!
    if (!this.state.suggestionsVisible) {
      return;
    }

    const currentRef = this.selectorWrapperRef.current;
    const target = event.target;

    // If suggestions _are_ visible, we lack a currently-rendered wrapper, the
    // target is a Node, not an HTML Element, or the new focused element is not
    // within said wrapper, hide the suggestions list as it's no longer needed.
    if (
      !currentRef ||
      !(target instanceof HTMLElement) ||
      !currentRef.contains(target)
    ) {
      this.setState({ suggestionsVisible: false });
    }
  };

  handleWindowKeydown = (event: KeyboardEvent) => {
    event = event || window.event;

    // If the user hit escape, give the focus back to the input element
    if (event.key === "Escape") {
      event.preventDefault();
      this.selectorInputRef.current && this.selectorInputRef.current.focus();
      this.setState({ suggestionsVisible: false });
      return;
    }

    const activeElement = document.activeElement;
    const linkClass = `${this.constructor.name}-link`;

    if (
      activeElement &&
      activeElement.tagName === "INPUT" &&
      (event.key === "ArrowDown" || event.key === "Enter")
    ) {
      event.preventDefault();
      const firstLink =
        this.selectorWrapperRef.current &&
        this.selectorWrapperRef.current.querySelector<HTMLElement>(linkClass);

      if (firstLink) {
        firstLink.focus();
      }
    }

    if (
      activeElement &&
      activeElement.classList.contains(linkClass) &&
      event.key === "ArrowDown"
    ) {
      event.preventDefault();
      // Walk up the DOM one element, as we're inside list items, then to another item
      const currentListItem = activeElement.parentElement;
      const nextElementSibling =
        currentListItem && currentListItem.nextElementSibling;
      const nextLink =
        nextElementSibling && nextElementSibling.firstElementChild;
      if (nextLink && nextLink.classList.contains(linkClass)) {
        // get around Flow's typing, which asserts that nextElementSibling returns an object of type Element, not HTMLElement
        const htmlLink = nextLink as HTMLElement;
        htmlLink.focus();
      }
    }

    if (
      activeElement &&
      activeElement.classList.contains(linkClass) &&
      event.key === "ArrowUp"
    ) {
      event.preventDefault();
      // Walk up the DOM one element, as we're inside list items, then to another item
      const currentListItem = activeElement.parentElement;
      const previousElementSibling =
        currentListItem && currentListItem.previousElementSibling;
      const prevLink =
        previousElementSibling && previousElementSibling.firstElementChild;
      if (prevLink && prevLink.classList.contains(linkClass)) {
        // get around Flow's typing, which asserts that previousElementSibling returns an object of type Element, not HTMLElement
        const htmlLink = prevLink as HTMLElement;
        htmlLink.focus();
      } else {
        // If you press up and there is no link above, give focus to the input
        this.selectorInputRef.current && this.selectorInputRef.current.focus();
      }
    }
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    // Check if whether we should display suggestions has changed,
    // and handle listeners for the dropdown appropriately
    if (
      this.shouldDisplaySuggestionsForState(prevState) !==
      this.shouldDisplaySuggestions
    ) {
      if (this.shouldDisplaySuggestions) {
        // Handle keydown events to navigate the dropdown
        window.addEventListener("keydown", this.handleWindowKeydown);

        // Listen for blur events (e.g. clicking outside the dropdown, tabbing away etc.)
        document.addEventListener("mousedown", this.handleGlobalFocus);
        document.addEventListener("focus", this.handleGlobalFocus, true);
      } else {
        // Stop listening for blur events
        document.removeEventListener("focus", this.handleGlobalFocus, true);
        document.removeEventListener("mousedown", this.handleGlobalFocus);
        window.removeEventListener("keydown", this.handleWindowKeydown);
      }
    }

    // If we just showed the filter field, and the repository list isn't ready
    if (
      this.state.showFilterField &&
      !prevState.showFilterField &&
      !this.state.repositories
    ) {
      // Immediately clear the timeout to fetch repositories
      if (this.repositorySearchTimeout) {
        clearTimeout(this.repositorySearchTimeout);
      }

      // And immediately go update the repository list
      this.fetchRepositories();
      return;
    }

    if (this.state.search !== prevState.search) {
      // If a timeout is already present,
      // clear it since the user is still typing
      if (this.repositorySearchTimeout) {
        clearTimeout(this.repositorySearchTimeout);
      }

      // Instead of doing a search on each keypress,
      // do it a few ms after they've stopped typing
      this.repositorySearchTimeout = window.setTimeout(() => {
        this.fetchRepositories();
        delete this.repositorySearchTimeout;
      }, 250);
    }
  }

  fetchRepositories = () => {
    if (window.Features.SkipNewPipelinesRepositoriesAutocomplete) {
      return;
    }

    const service = this.selectedService;

    if (!service) {
      return;
    }

    let url = service.repositories_url;
    const searchString = this.state.search;

    if (searchString) {
      url += "?search=" + encodeURIComponent(searchString);
    }

    this.setState({ loading: true, loadingFailed: false }, () => {
      fetch(url, {
        headers: { "X-Buildkite-Frontend-Version": BUILDKITE_FRONTEND_VERSION },
      })
        .then((response) => response.json())
        .then((repositories: RepositoriesData) => {
          // Multiple requests may be in flight at a time and we only care about the
          // response for the latest request.
          // This comparison checks the latest search string and selected service against
          // those that were used to make this request before updating state with the
          // request's response
          if (
            searchString === this.state.search &&
            service.repositories_url === this.selectedService?.repositories_url
          ) {
            this.setState({
              repositories,
              suggestionsVisible: true,
              loading: false,
            });
          }
        })
        .catch(() => {
          if (
            searchString === this.state.search &&
            service.repositories_url === this.selectedService?.repositories_url
          ) {
            this.setState({
              repositories: null,
              loading: false,
              loadingFailed: true,
            });
          }
        });
    });
  };

  renderRepository(repository: RepositoryInfo) {
    return (
      <>
        <h5 className="my1">
          {repository.private && (
            <>
              <Icon icon="eye-strikethrough" title="Private" />{" "}
            </>
          )}
          {repository.full_name}
          {repository.language && (
            <Badge className="mx1" outline={true} bold={true}>
              {repository.language}
            </Badge>
          )}
          {repository.fork && (
            <>
              {" "}
              <Icon icon="code" title="Fork" />
            </>
          )}
        </h5>
        <p className="truncate my1" title={repository.description}>
          {repository.description || "No description"}
        </p>
      </>
    );
  }

  handleRepositorySelect = (event: React.MouseEvent<HTMLAnchorElement>) => {
    const currentTarget = event.currentTarget as HTMLAnchorElement;

    // Weird, that shouldn't happen!
    if (!currentTarget.dataset.repositoryIndex) {
      return;
    }

    const selectedRepositoryIndex = parseInt(
      currentTarget.dataset.repositoryIndex,
      10,
    );

    if (
      selectedRepositoryIndex === null ||
      selectedRepositoryIndex === undefined
    ) {
      return;
    }

    const selectedRepository =
      this.state.repositories &&
      this.state.repositories[selectedRepositoryIndex];

    if (!selectedRepository) {
      return;
    }

    event.preventDefault();

    this.setState({
      selectedRepository,
      showFilterField: false,
      suggestionsVisible: false,
    });
  };

  renderRepositories(): React.ReactNode {
    if (!this.state.repositories) {
      return <Spinner />;
    }

    return this.state.repositories.map((repository, index) => (
      <li key={repository.html_url}>
        <a
          href="#"
          className={`${this.constructor.name}-link block text-decoration-none focus-bg-lime black focus-white px2 py1`}
          style={{
            outline: "none",
          }}
          data-repository-index={index}
          onClick={this.handleRepositorySelect}
        >
          {this.renderRepository(repository)}
        </a>
      </li>
    ));
  }

  handleServiceChange = (uuid: string | null) => {
    if (!uuid) {
      this.setState({
        repositories: null,
        selectedServiceUuid: null,
      });
    } else {
      this.setState({ selectedServiceUuid: uuid }, () => {
        this.fetchRepositories();
      });
    }
  };

  get shouldDisplaySuggestions() {
    return this.shouldDisplaySuggestionsForState(this.state);
  }

  shouldDisplaySuggestionsForState(state: State) {
    return (
      state.repositories &&
      state.repositories.length > 0 &&
      state.suggestionsVisible
    );
  }

  renderRepositorySuggestions() {
    const style = {
      display: this.shouldDisplaySuggestions ? "block" : "none",
      marginTop: 3,
      zIndex: 999,
      width: "100%",
      lineHeight: 1.4,
      maxHeight: 320,
    } as const;

    return (
      <div
        className="bg-white border border-silver rounded overflow-auto shadow absolute"
        style={style}
      >
        <ul className="list-reset m0 p0">{this.renderRepositories()}</ul>
      </div>
    );
  }

  handleRepositoryURLTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const selectedRepositoryURLType = event.target.value as RepositoryURLType;

    if (!Object.values(RepositoryURLType).includes(selectedRepositoryURLType)) {
      throw new Error(
        `Unexpected repository URL type: ${selectedRepositoryURLType}`,
      );
    }

    this.setState({ selectedRepositoryURLType });
  };

  handleRepositoryFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    this.setState({ search: event.target.value });
  };

  handleRepositoryFieldFocus = () => {
    // Always mark the suggestions as visible when focused;
    // the dropdown actually relies on the shouldDisplaySuggestions
    // computed value, which means this can account for focus events
    // received while data was still loading.
    this.setState({ suggestionsVisible: true });
  };

  handleChangeRepositoryClick = (
    event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>,
  ) => {
    event.preventDefault();
    this.setState({ showFilterField: true, selectedRepository: null });
  };

  render() {
    const {
      repositoryFieldAttrs: {
        value: repositoryFieldValue,
        ...repositoryFieldAttrs
      },
    } = this.props;
    let { label, hint } = this.props;

    if (this.props.services) {
      if (label) {
        // If we're on a "Repository URL" field, chop that part off for clarity
        const URLSuffix = " URL";
        const URLIndex = label.indexOf(URLSuffix);
        if (URLIndex !== -1 && URLIndex === label.length - URLSuffix.length) {
          label = label.slice(0, URLIndex);
        }
      }

      if (hint) {
        // Add some info to the hint if we've got a service selected,
        // as it adds some magic to the field
        if (
          this.selectedService &&
          this.state.showFilterField &&
          !window.Features.SkipNewPipelinesRepositoriesAutocomplete
        ) {
          hint = `${hint} Paste a checkout URL, or type to find a repository in the selected account.`;

          // And if loading failed, show a message about that;
          // it's kinda long but we don't have much of a better place
          if (this.state.loadingFailed) {
            hint = `${hint} Failed to load list of repositories. Try again later.`;
          }
        }

        if (!this.state.showFilterField) {
          hint = (
            <span>
              {hint}
              {" Need to "}
              <a
                href="#"
                className="lime hover-lime text-decoration-none hover-underline"
                onClick={this.handleChangeRepositoryClick}
              >
                choose another repository or URL
              </a>
              {/*
               */}
              ?
            </span>
          );
        }
      }
    }

    const content = this.state.showFilterField ? (
      <div className="flex flex-wrap items-center gap-2">
        <AccountSelect
          onChange={this.handleServiceChange}
          accounts={this.props.services}
          selected={this.selectedService}
          connectAccountURL={this.props.connectAccountURL}
        />
        <div
          className="flex-auto relative py1"
          style={{
            flexShrink: 1,
            flexGrow: 6,
            flexBasis: 250,
          }}
          ref={this.selectorWrapperRef}
        >
          <div className="flex items-center">
            <input
              type="text"
              className="input"
              ref={this.selectorInputRef}
              {...repositoryFieldAttrs}
              defaultValue={
                this.state.selectedRepository
                  ? this.state.selectedRepository[
                      this.state.selectedRepositoryURLType
                    ]
                  : repositoryFieldValue
              }
              id={repositoryFieldAttrs.id}
              autoComplete={this.props.services ? "off" : "url"}
              onChange={this.handleRepositoryFieldChange}
              onFocus={this.handleRepositoryFieldFocus}
            />
            {this.state.loading && (
              <span className="absolute right-2 pointer-events-none">
                <Spinner color={false} />
              </span>
            )}
          </div>
          {this.renderRepositorySuggestions()}
        </div>
      </div>
    ) : (
      <>
        <div className="flex items-center py2 px3 mb3 border border-gray">
          {this.selectedService && (
            <Icon
              icon={
                SCM_PROVIDER_TYPE_ICON_MAP[this.selectedService.provider_type]
              }
              className="flex-none my1 mr3"
            />
          )}
          <div className="flex-auto">
            {this.state.selectedRepository &&
              this.renderRepository(this.state.selectedRepository)}
          </div>
          <button
            className="unstyled-button hover-lime hover-bg-white"
            onClick={this.handleChangeRepositoryClick}
            title="Change Repository"
          >
            <Icon
              icon="close"
              style={{
                width: 30,
                height: 30,
              }}
            />
          </button>
        </div>
        <div className="flex items-center flex-wrap">
          <label className="flex-none inline-block py1 mr1">
            {"Checkout using: "}
          </label>
          {Object.values(RepositoryURLType).map((typeIdentifier) => (
            <label
              key={typeIdentifier}
              className="relative flex items-center py1 pl4 mr1"
            >
              <input
                // The important flags on these classes are to override legacy styles in
                // the `legacy-vendor-stylesheet` that have element level specificity
                className="absolute !-ml-5 radio !mt-0"
                type="radio"
                checked={
                  this.state.selectedRepositoryURLType === typeIdentifier
                }
                value={typeIdentifier}
                onChange={this.handleRepositoryURLTypeChange}
              />
              {typeIdentifier === RepositoryURLType.SSH && "SSH"}
              {typeIdentifier === RepositoryURLType.HTTPS && "HTTPS"}
            </label>
          ))}
          <input
            type="text"
            className="input flex-auto"
            style={{ flexBasis: 250 }}
            disabled={true}
            value={
              this.state.selectedRepository
                ? this.state.selectedRepository[
                    this.state.selectedRepositoryURLType
                  ]
                : undefined
            }
          />
        </div>
        <input
          type="hidden"
          {...repositoryFieldAttrs}
          value={
            this.state.selectedRepository
              ? this.state.selectedRepository[
                  this.state.selectedRepositoryURLType
                ]
              : undefined
          }
          id={repositoryFieldAttrs.id}
        />
      </>
    );

    let webhooks;
    if (
      this.props.allowWebhooksCheckbox &&
      ((window.Features.SkipNewPipelinesRepositoriesAutocomplete &&
        this.state.selectedServiceUuid) ||
        !this.state.showFilterField)
    ) {
      webhooks = (
        <>
          <hr
            className="p0 bg-gray"
            style={{
              border: "none",
              height: "1px",
              marginLeft: "-15px",
              marginRight: "-15px",
            }}
          />

          <div className="form-group">
            <input
              name="auto_create_webhooks"
              id="auto_create_webhooks"
              type="checkbox"
              value="true"
              defaultChecked={true}
            />
            <label
              className="FormField__Label pl1"
              htmlFor="auto_create_webhooks"
            >
              Auto-create webhooks
            </label>
            <p className="FormField__Hint">
              Webhooks are used to trigger builds in Buildkite. When selected,
              we&apos;ll attempt to create them automatically.
            </p>
          </div>
        </>
      );
    }

    return (
      <>
        <FormInputLabel
          label={label}
          required={!!repositoryFieldAttrs.required}
          htmlFor={repositoryFieldAttrs.id}
        />
        {content}
        <FormInputHelp>{hint}</FormInputHelp>
        {webhooks}
      </>
    );
  }
}

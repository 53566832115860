import Section from "./Section";

const exampleQuery = (slug: string) => `query {
  organization(slug: "${slug}") {
    auditEvents(first: 500) {
      edges {
        node {
          type
          occurredAt
          actor {
            name
          }
          subject {
            name
            type
          }
          data
        }
      }
    }
  }
}`;

const linkClassName = "text-decoration-none lime hover-lime hover-underline";

const AuditLogExport = ({ slug, name }: { slug: string; name: string }) => (
  <Section name={name} slug={slug}>
    <div>
      <p>
        Your organizationʼs audit log can be queried and exported using the{" "}
        <a href="/docs/graphql-api" className={linkClassName}>
          GraphQL API
        </a>
        .
      </p>
      <p>For example:</p>
      <pre className="code">{exampleQuery(slug)}</pre>
      <p>
        See the{" "}
        <a href="/docs/graphql-api" className={linkClassName}>
          GraphQL Documentation
        </a>{" "}
        for more information.
      </p>
    </div>
  </Section>
);

export default AuditLogExport;

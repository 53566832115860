import { useEffect, useState } from "react";

export type LoadMoreProps = {
  collection: Array<any>;
  handleClickLoadMore: () => Promise<void>;
  hasNextPage: boolean;
  loading: boolean;
  error?: boolean;
};

type Props = {
  data?: Array<any>;
  url?: string | undefined | null;
};

const useLoadMore = ({ data = [], url }: Props) => {
  const [collection, setCollection] = useState(data);
  const [nextPageUrl, setNextPageUrl] = useState(url);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const hasNextPage = !!nextPageUrl;

  const handleClickLoadMore = async () => {
    if (!nextPageUrl) {
      return;
    } // guard clause for typescript

    setLoading(true);

    const response = await fetch(nextPageUrl, { method: "GET" });
    if (response.ok) {
      const newCollection = await response.json();
      const link = await response.headers.get("link");

      setNextPageUrl(link);
      setCollection((collection) => [...collection, ...newCollection]);
    } else {
      setError(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    // If no data is rendered before the component loads
    // trigger the first fetch
    if (data.length === 0 && !!url) {
      const fetchFirstPage = async () => await handleClickLoadMore();
      fetchFirstPage();
    } else {
      setLoading(false);
    }
  }, []);

  // `data` could be set after the first render when we asynchronously load it
  useEffect(() => {
    if (data.length > 0) {
      setCollection(data);
    }
  }, [data]);

  return {
    collection,
    handleClickLoadMore,
    hasNextPage,
    loading,
    error,
  };
};

export default useLoadMore;

import Code from "app/components/docs/shared/code";
import CodeBlock from "app/components/docs/shared/code-block";
import Link from "app/components/docs/shared/link";

type Props = {
  token?: string;
  className?: string;
  style?: any;
};

WindowsQuickStart.title = "Windows";

function WindowsQuickStart(props: Props) {
  const { className, style } = props;

  return (
    <div className={className} style={style}>
      <ol className="list-disc list-inside pl-5 my-4">
        <li>
          Download the latest Windows release from{" "}
          <Link href="https://github.com/buildkite/agent/releases">
            Buildkite Agent releases on GitHub
          </Link>
        </li>
        <li>
          Extract the files to a directory of your choice (we recommend{" "}
          <Code>C:\buildkite-agent</Code>)
        </li>
        <li>
          Edit <Code>buildkite-agent.cfg</Code> and add your{" "}
          <Link href="/docs/agent/tokens">agent token</Link>
        </li>
        <li>
          Run <Code>buildkite-agent start</Code> from a command prompt
        </li>
      </ol>

      <p>When asked, enter your agent token:</p>
      <CodeBlock language="markdown">
        {props.token || "INSERT-YOUR-AGENT-TOKEN-HERE"}
        <br />
      </CodeBlock>

      <p className="rounded bg-silver p2">
        See the <Link href="/docs/agent/windows">Windows agent docs</Link> for
        more details.
      </p>
    </div>
  );
}

export default WindowsQuickStart;

/**
 * @generated SignedSource<<eee84755ee1a28e028165fc8b8d225f1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AgentConnectedDialogRefetchQuery$variables = {
  isMounted: boolean;
  slug: string;
};
export type AgentConnectedDialogRefetchQuery$data = {
  readonly organization: {
    readonly " $fragmentSpreads": FragmentRefs<"AgentConnectedDialog_organization">;
  } | null;
};
export type AgentConnectedDialogRefetchQuery = {
  response: AgentConnectedDialogRefetchQuery$data;
  variables: AgentConnectedDialogRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isMounted"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "slug"
},
v2 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AgentConnectedDialogRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "isMounted",
                "variableName": "isMounted"
              }
            ],
            "kind": "FragmentSpread",
            "name": "AgentConnectedDialog_organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AgentConnectedDialogRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "slug",
            "storageKey": null
          },
          {
            "condition": "isMounted",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "last",
                    "value": 1
                  }
                ],
                "concreteType": "AgentConnection",
                "kind": "LinkedField",
                "name": "agents",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "count",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AgentEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Agent",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "agents(last:1)"
              }
            ]
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7c5a5926e179f311c8004059624d908e",
    "id": null,
    "metadata": {},
    "name": "AgentConnectedDialogRefetchQuery",
    "operationKind": "query",
    "text": "query AgentConnectedDialogRefetchQuery(\n  $slug: ID!\n  $isMounted: Boolean!\n) {\n  organization(slug: $slug) {\n    ...AgentConnectedDialog_organization_1H60p0\n    id\n  }\n}\n\nfragment AgentConnectedDialog_organization_1H60p0 on Organization {\n  slug\n  agents(last: 1) @include(if: $isMounted) {\n    count\n    edges {\n      node {\n        name\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6bd05bbe123787ed14731faeb21f74a2";

export default node;

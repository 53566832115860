import { useCallback, useState } from "react";
import cn from "classnames";
import Icon from "app/components/shared/Icon";

interface Props {
  if?: string;
}

export default function Conditional(props: Props) {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const handleExpand = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  const conditional = props.if;
  if (!conditional) {
    return null;
  }

  return (
    <small
      className={cn(
        "bg-gray-200",
        ["inline-flex", "gap-1", "items-center"],
        "text-charcoal-300",
        "mb-1",
        ["px-2", "py-1"],
        "rounded",
        isExpanded ? "nowrap" : "truncate",
      )}
    >
      <code className={cn(isExpanded ? "nowrap" : ["truncate", "text-clip"])}>
        <span className="font-bold">if </span>
        <span>{conditional}</span>
      </code>
      {/* TODO: reference max-w-base-node's 45ch in Tailwind config */}
      {conditional.length > 45 && (
        <button
          aria-hidden={true}
          className={cn(
            ["bg-gray-500", "hover:bg-gray-600"],
            "group",
            "px-1",
            "rounded",
            ["transition-colors", "duration-200"],
          )}
          onClick={handleExpand}
        >
          <Icon
            className={cn(
              "block",
              ["h-4", "w-4"],
              ["text-charcoal-300", "group-hover:text-charcoal-700"],
              ["transition-colors", "duration-200"],
            )}
            icon="heroicons/16/solid/ellipsis-horizontal"
          />
        </button>
      )}
    </small>
  );
}

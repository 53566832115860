import { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";

type Props = {
  name: string;
  value: string;
};

export default function SecretDisclosure({ name, value }: Props) {
  const [isDisclosed, setIsDisclosed] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  function handleMouseOver() {
    setIsDisclosed(true);
  }

  function handleMouseLeave(event: any) {
    setIsDisclosed(false);

    event.currentTarget.blur();
  }

  function handleClick(event: any) {
    event.currentTarget.select();
  }

  function handleCopy() {
    setIsCopied(true);

    setTimeout(() => setIsCopied(false), 2000);
  }

  return (
    <div className="flex mt1">
      <input
        className="input code letter-spacing-1 dark-gray"
        name={name}
        value={value}
        readOnly={true}
        type={isDisclosed ? "text" : "password"}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
      />

      <CopyToClipboard text={value} onCopy={handleCopy}>
        {/* @ts-expect-error - TS2322 - Type '{ children: string; className: string; "aria-label": string | null; }' is not assignable to type 'DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>'. */}
        <button
          className="ml2 btn btn-default"
          aria-label={isCopied ? "Copied" : null}
        >
          Copy to Clipboard
        </button>
      </CopyToClipboard>
    </div>
  );
}

import styled from "styled-components";
import Button from "app/components/shared/Button";

const RainbowButton = styled(Button)`
  @keyframes glowing {
    0% {
      background-position: 0 0;
    }

    50% {
      background-position: 400% 0;
    }

    100% {
      background-position: 0 0;
    }
  }

  position: relative;
  transform-style: preserve-3d;

  &:before {
    animation: glowing 20s linear infinite;
    background: linear-gradient(
      45deg,
      var(--red-500),
      var(--orange-700),
      var(--yellow-500),
      var(--green-500),
      var(--aqua-600),
      var(--blue-600),
      var(--purple-600),
      var(--pink-400),
      var(--red-500)
    );
    background-size: 400%;
    border-radius: inherit;
    content: "";
    filter: blur(4px);
    height: calc(100% + 4px);
    left: -2px;
    outline: var(--base-0) 1px solid;
    opacity: 0.85;
    position: absolute;
    top: -2px;
    transform: translate3d(0px, 0px, -1px);
    transition: var(--transition-speed-normal);
    width: calc(100% + 4px);
  }

  &:hover::before {
    opacity: 0.85;
  }

  &:not([disabled]):hover:active {
    background-color: rgba(75, 25, 213, 0.5);
  }
`;

export default RainbowButton;

import Icon from "app/components/shared/Icon";
import { ResultButtons } from ".";

const SortingButton = ({ buttonType, currentSort, currentOrder }) => {
  const buttonActive = currentSort === buttonType;
  const params = new URLSearchParams(window.location.search);
  const newOrder = currentOrder === "ASC" ? "DESC" : "ASC";

  params.set("sort_by", buttonType);
  params.set("order", buttonActive ? newOrder : "DESC");

  return (
    <a
      href={`${window.location.pathname}?${params.toString()}`}
      className="charcoal-300 text-decoration-none no-title-tooltip"
      aria-label={`Sort by ${ResultButtons[buttonType]}`}
      style={{
        fontWeight: buttonActive ? "bold" : "normal",
        lineHeight: 2,
      }}
    >
      {ResultButtons[buttonType]}
      {currentSort === buttonType && (
        <span>
          <Icon
            icon="heroicons/outline/chevron-down"
            style={{
              marginLeft: "4px",
              width: "10px",
              display: "inline-block",
              rotate: currentOrder === "DESC" ? "0deg" : "180deg",
            }}
          />
        </span>
      )}
    </a>
  );
};

export default SortingButton;

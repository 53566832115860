import Node from "./Node.svg";
import Created from "./Created.svg";
import Spinner from "app/components/shared/Spinner";

const ACTOR_FIGURES = {
  NODE: Node,
  CREATED: Created,
} as const;

type Props = {
  type: keyof typeof ACTOR_FIGURES | "PENDING";
};

const STYLE = { width: 20, height: 20 } as const;

/* eslint-disable react/display-name */
export default function JobEventActorFigure(props: Props) {
  return (
    <figure className="mr2 flex-column">
      {props.type === "PENDING" ? (
        <div className="flex items-center justify-center" style={STYLE}>
          <Spinner color={false} size={16} />
        </div>
      ) : (
        <img style={STYLE} src={ACTOR_FIGURES[props.type]} />
      )}
    </figure>
  );
}

/**
 * @generated SignedSource<<580c5bbec1921227ed670a01b61ebe11>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Metrics_pipeline$data = {
  readonly metrics: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly label: string;
        readonly " $fragmentSpreads": FragmentRefs<"Metric_metric">;
      } | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "Metrics_pipeline";
};
export type Metrics_pipeline$key = {
  readonly " $data"?: Metrics_pipeline$data;
  readonly " $fragmentSpreads": FragmentRefs<"Metrics_pipeline">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Metrics_pipeline",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 6
        }
      ],
      "concreteType": "PipelineMetricConnection",
      "kind": "LinkedField",
      "name": "metrics",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PipelineMetricEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PipelineMetric",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "label",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "Metric_metric"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "metrics(first:6)"
    }
  ],
  "type": "Pipeline",
  "abstractKey": null
};

(node as any).hash = "671b5cf743fc3592b40912b4721de9e0";

export default node;

import * as React from "react";
import PlainBugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { vsprintf } from "sprintf-js";

const REACT_ERROR_DECODER_REGEX = /https:\/\/[^\s]+\/error-decoder\.html[^\s]+/;

const PROTOCOL_IGNORE_LIST = [
  "chrome-extension:",
  "moz-extension:",
  "safari-extension:",
];

const REACT_ERROR_CODES_URL =
  "https://raw.githubusercontent.com/facebook/react/master/scripts/error-codes/codes.json";

// Cached promise for fetching the React error code mapping data
let reactErrorMapRequest;
const getReactErrorMap = () =>
  (reactErrorMapRequest =
    reactErrorMapRequest ||
    fetch(REACT_ERROR_CODES_URL).then((response) =>
      response.ok
        ? response.json()
        : Promise.reject(
            new Error(
              `Fetching ${response.url}: ${response.status} ${response.statusText}`,
            ),
          ),
    ));

const Bugsnag = PlainBugsnag.start({
  // @ts-expect-error - TS2339 - Property '_bugsnag' does not exist on type 'Window & typeof globalThis'.
  ...window._bugsnag,

  plugins: [new BugsnagPluginReact()],

  onError(report: any) {
    // We don't want to hear about any errors from saved copies of pages
    if (window.location.protocol === "file:") {
      return false;
    }

    let returnValue = true;

    report.errors.forEach((error) => {
      if (error.errorMessage.indexOf("Minified React error") === 0) {
        // If we're seeing a minified React error, let's make a best-effort
        // attempt to go fetch the React error mappings, and update the error
        // with the actual error message
        const urlMatches = error.errorMessage.match(REACT_ERROR_DECODER_REGEX);

        if (urlMatches && urlMatches.length > 0) {
          // This is the most likely bit to fall over, `searchParams` is
          // a relatively modern API, but the good news is Bugsnag has
          // this callback wrapped in a try/catch, so we're good!
          const urlQuery = new URL(urlMatches[0]).searchParams;

          const invariant = urlQuery.get("invariant");
          const args = urlQuery.getAll("args[]");

          // once we've got the error number, and the supplied sprintf arguments,
          // go grab the error mapping (noting this can fail, and if so it'll be
          // gracefully handled in Bugsnag!)
          returnValue = getReactErrorMap().then((reactErrorMap) => {
            // once we've got a mapping, add the original error message to the
            // metadata so we can look it up just in case this messes up,
            report.addMetadata("react", {
              originalErrorMessage: error.errorMessage,
            });

            // use the formatting instructions React provided to format the error,
            // @ts-expect-error - TS2538 - Type 'null' cannot be used as an index type.
            error.errorMessage = vsprintf(reactErrorMap[invariant], args);

            // and finally, we're done, and happy!
            return true;
          });
        }
      } else if (
        error.stacktrace.some(({ file }) => {
          let protocol;

          try {
            protocol = new URL(file).protocol;
          } catch (error: any) {
            // Don't block "Global code" or other non-URL stuff
            return false;
          }

          return PROTOCOL_IGNORE_LIST.indexOf(protocol) !== -1;
        })
      ) {
        returnValue = false;
      }
    });

    return returnValue;
  },
});

export default Bugsnag;

// Bind the Bugsnag React plugin to our React instance
export const ErrorBoundary =
  // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
  Bugsnag.getPlugin("react").createErrorBoundary(React);

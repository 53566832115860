import * as React from "react";
import copy from "copy-to-clipboard";

import { DateLike } from "app/lib/date";
import LegacyDispatcher from "app/lib/legacyDispatcher";
import JobLogLineStore from "app/stores/JobLogLineStore";
import TestTooltip from "app/components/analytics/shared/Tooltips/TestTooltip";
import { track } from "app/lib/segmentAnalytics";
import CopyPermalink from "./CopyPermalink";
import classNames from "classnames";
import {
  LogLine,
  LogLineContent,
  LogLineNumber,
  LogTimestamp,
} from "app/components/shared/Log";

type Props = {
  id: string;
  number: number;
  line: string;
  startedAt: DateLike | null | undefined;
  timestampsOn: boolean | null | undefined;
  timestampsInUTC: boolean | null | undefined;
  onTimestampClick: (
    arg1: React.MouseEvent<HTMLElement>,
  ) => void | null | undefined;
};

// When changing to Typescript, this can be imported from 'TestTooltip'
type TestProps = {
  uuid: string;
  activity: {
    activities: Array<{
      createdAt: string;
      duration: number;
      url: string;
      humanDuration: string;
      id: string;
      result: string;
    }>;
    limit: number;
  };
  reliability: string;
  failedCount: number;
  p50Duration: number;
  p95Duration: number;
  url: string;
};

type State = {
  highlighted: boolean;
  showCopyPermalink: boolean;
  test?: TestProps;
  testLoading: boolean;
  showTooltip: boolean;
};

const getStateFromStores = (id: string) => ({
  highlighted: JobLogLineStore.isHighlighted(id),
});

export default class JobLogOutputLine extends React.PureComponent<
  Props,
  State
> {
  wrapperNode: HTMLTableRowElement | null | undefined;
  tooltipWrapperNode: HTMLSpanElement | null | undefined;

  constructor(props: Props) {
    super(props);

    this.state = {
      ...getStateFromStores(props.id),
      showCopyPermalink: false,
      test: undefined,
      testLoading: false,
      showTooltip: false,
    };
  }

  componentDidMount() {
    JobLogLineStore.addChangeListener(
      this.props.id,
      this.handleLineStoreChange,
    );
    this.emitHighlighted();
  }

  componentWillUnmount() {
    JobLogLineStore.removeChangeListener(
      this.props.id,
      this.handleLineStoreChange,
    );
  }

  componentDidUpdate() {
    this.emitHighlighted();
  }

  // Notify the stores that a highlighted line has been rendered. This gives the
  // stores an opportunity to scroll to this line if they need to.
  emitHighlighted() {
    if (this.state.highlighted) {
      // @ts-expect-error - TS2554 - Expected 6 arguments, but got 2.
      return LegacyDispatcher.emit("job_log_line:highlighted", {
        lineId: this.props.id,
        el: this.wrapperNode,
      });
    }
  }

  handleLineStoreChange = () => {
    return this.setState(getStateFromStores(this.props.id));
  };

  handleClick = (evt: React.MouseEvent<HTMLTableCellElement>) => {
    evt.preventDefault();

    this.setState({ showCopyPermalink: true });

    // @ts-expect-error - TS2554 - Expected 6 arguments, but got 2.
    return LegacyDispatcher.emit("job_log_line:highlight", {
      lineId: this.props.id,
    });
  };

  handleCopyPermalink = (evt: React.MouseEvent<HTMLButtonElement>) => {
    // Prevent the line from being unhighlighted
    evt.stopPropagation();

    const url = new URL(window.location.href);
    url.hash = this.props.id;
    copy(url.toString());

    this.setState({ showCopyPermalink: false });
  };

  handleTimestampClick = (evt: React.MouseEvent<HTMLElement>) => {
    evt.preventDefault();

    if (this.props.onTimestampClick) {
      this.props.onTimestampClick(evt);
    }
  };

  handleTooltipLineClick = async (evt: React.MouseEvent<HTMLElement>) => {
    const { target } = evt;

    if (!(target instanceof HTMLAnchorElement) || !target.href) {
      return;
    }

    const testAnalyticsUrlRegex =
      /\/organizations\/([^/]+)\/analytics\/suites\/([^/]+)\/tests\/([^/]+)/;
    const testAnalyticsMatch = target.href.match(testAnalyticsUrlRegex);

    if (testAnalyticsMatch) {
      evt.preventDefault();
      const organizationSlug = testAnalyticsMatch[1];
      this.setState({ showTooltip: true, testLoading: true });
      document.documentElement &&
        document.documentElement.addEventListener(
          "click",
          this.handleDocumentClick,
        );

      if (this.state.test === undefined) {
        const response = await fetch(`${target.href}/tooltip_metrics`);

        if (response.status === 200) {
          const data = await response.json();
          this.setState({ test: data });
        }
      }

      this.setState({ testLoading: false });
      _trackTestTooltipClicked({
        testId: this.state.test?.uuid,
        organizationSlug: organizationSlug,
        // @ts-expect-error - TS2322 - Type 'string | null' is not assignable to type 'string'.
        textContent: target.textContent,
      });
    }
  };

  handleDocumentClick = (evt: MouseEvent) => {
    if (!(evt.target instanceof HTMLElement)) {
      return;
    }

    if (
      this.tooltipWrapperNode &&
      !this.tooltipWrapperNode.contains(evt.target)
    ) {
      this.setState({ showTooltip: false });
      document.documentElement &&
        document.documentElement.removeEventListener(
          "click",
          this.handleDocumentClick,
        );
    }
  };

  renderCopyPermalink = () => {
    return (
      <div
        className={classNames("JobLogOutputComponent__CopyPermalink", {
          "JobLogOutputComponent__CopyPermalink--Visible":
            this.state.highlighted && this.state.showCopyPermalink,
        })}
      >
        <CopyPermalink onCopy={this.handleCopyPermalink} />
      </div>
    );
  };

  render() {
    return (
      <LogLine
        ref={(wrapperNode) => (this.wrapperNode = wrapperNode)}
        highlighted={this.state.highlighted}
      >
        <LogLineNumber number={this.props.number} onClick={this.handleClick}>
          {this.renderCopyPermalink()}
        </LogLineNumber>

        {this.props.timestampsOn && (
          <LogTimestamp
            time={this.props.startedAt}
            utc={this.props.timestampsInUTC}
            onClick={this.handleTimestampClick}
          />
        )}

        <LogLineContent colSpan={2}>
          <span
            ref={(tooltipWrapperNode) =>
              (this.tooltipWrapperNode = tooltipWrapperNode)
            }
            style={{ position: "relative" }}
          >
            <span
              dangerouslySetInnerHTML={{ __html: this.props.line }}
              onClick={this.handleTooltipLineClick}
            />
            {this.state.showTooltip && (
              <TestTooltip
                // @ts-expect-error - TS2322 - Type 'TestProps | undefined' is not assignable to type 'Test | undefined'.
                test={this.state.test}
                loading={this.state.testLoading}
                // @ts-expect-error - TS2322 - Type 'HTMLSpanElement | null | undefined' is not assignable to type 'HTMLElement'.
                wrapperNode={this.tooltipWrapperNode}
              />
            )}
          </span>
        </LogLineContent>
      </LogLine>
    );
  }
}

type TrackingProps = {
  testId?: string;
  organizationSlug: string;
  textContent: string;
};

function _trackTestTooltipClicked({
  testId,
  organizationSlug,
  textContent,
}: TrackingProps) {
  track("TA Test Tooltip Opened", {
    source: "Job/Log/Output/Line",
    tooltip_title: textContent,
    organization_slug: organizationSlug,
    // @ts-expect-error - TS2322 - Type 'string | undefined' is not assignable to type 'string | number | boolean'.
    test_id: testId,
  });
}

/**
 * @generated SignedSource<<f13eb66c9c24fc1119df3b90ab666ffc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SuitesChooserQuery$variables = {
  suiteAddSearch?: string | null;
  team: string;
  teamSelector?: any | null;
};
export type SuitesChooserQuery$data = {
  readonly team: {
    readonly " $fragmentSpreads": FragmentRefs<"SuitesChooser_team">;
  } | null;
};
export type SuitesChooserQuery = {
  response: SuitesChooserQuery$data;
  variables: SuitesChooserQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "suiteAddSearch"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "team"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "teamSelector"
},
v3 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "team"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SuitesChooserQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Team",
        "kind": "LinkedField",
        "name": "team",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "suiteAddSearch",
                "variableName": "suiteAddSearch"
              },
              {
                "kind": "Variable",
                "name": "teamSelector",
                "variableName": "teamSelector"
              }
            ],
            "kind": "FragmentSpread",
            "name": "SuitesChooser_team"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "SuitesChooserQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Team",
        "kind": "LinkedField",
        "name": "team",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 10
                  },
                  {
                    "kind": "Literal",
                    "name": "order",
                    "value": "NAME"
                  },
                  {
                    "kind": "Variable",
                    "name": "search",
                    "variableName": "suiteAddSearch"
                  },
                  {
                    "kind": "Variable",
                    "name": "team",
                    "variableName": "teamSelector"
                  }
                ],
                "concreteType": "SuiteConnection",
                "kind": "LinkedField",
                "name": "suites",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SuiteEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Suite",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TeamPermissions",
            "kind": "LinkedField",
            "name": "permissions",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Permission",
                "kind": "LinkedField",
                "name": "teamSuiteCreate",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "allowed",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f539218f14de3f2c0cb88720f0b5beb8",
    "id": null,
    "metadata": {},
    "name": "SuitesChooserQuery",
    "operationKind": "query",
    "text": "query SuitesChooserQuery(\n  $team: ID!\n  $suiteAddSearch: String\n  $teamSelector: TeamSelector\n) {\n  team(slug: $team) {\n    ...SuitesChooser_team_2L1mX0\n    id\n  }\n}\n\nfragment SuitesChooser_team_2L1mX0 on Team {\n  id\n  slug\n  organization {\n    suites(search: $suiteAddSearch, first: 10, order: NAME, team: $teamSelector) {\n      edges {\n        node {\n          id\n          name\n          slug\n        }\n      }\n    }\n    id\n  }\n  permissions {\n    teamSuiteCreate {\n      allowed\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7f46f7307ccd87a6fd66ee8967a74c31";

export default node;

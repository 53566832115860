/**
 * @generated SignedSource<<d77b15ebc1c0e77177e25e56693a5f19>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type buildQuery$variables = {
  uuid: string;
};
export type buildQuery$data = {
  readonly build: {
    readonly " $fragmentSpreads": FragmentRefs<"build_build">;
  } | null;
};
export type buildQuery = {
  response: buildQuery$data;
  variables: buildQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "uuid"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "uuid",
    "variableName": "uuid"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "buildQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Build",
        "kind": "LinkedField",
        "name": "build",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "build_build"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "buildQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Build",
        "kind": "LinkedField",
        "name": "build",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "uuid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "state",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "blockedState",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "canceledAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "finishedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "url",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "commit",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Pipeline",
            "kind": "LinkedField",
            "name": "pipeline",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cc72a0276ec97cd78088a288ac03cbb3",
    "id": null,
    "metadata": {},
    "name": "buildQuery",
    "operationKind": "query",
    "text": "query buildQuery(\n  $uuid: ID!\n) {\n  build(uuid: $uuid) {\n    ...build_build\n    id\n  }\n}\n\nfragment build_build on Build {\n  id\n  uuid\n  message\n  state\n  blockedState\n  createdAt\n  canceledAt\n  finishedAt\n  url\n  commit\n  pipeline {\n    name\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "1401db0ce5020d19fb84b37902f54298";

export default node;

import Dropdown from "app/components/shared/Dropdown";
import Icon from "app/components/shared/Icon";
import UserAvatar from "app/components/shared/UserAvatar";
import classNames from "classnames";
import { useRef, useState } from "react";
import DropdownButton from "./dropdown-button";
import NavigationButton from "./navigation-button";

interface Props {
  viewer: {
    user: {
      uuid: string;
      name: string;
      avatar: {
        url: string;
      };
    };
  };
  organization?: {
    slug: string;
  } | null;
  warning?: boolean;
}

const UserDropdown = ({ viewer, organization, warning }: Props) => {
  const [showingUserDropdown, setShowingUserDropdown] = useState(false);
  const userDropdown = useRef<Dropdown>(null);
  const logoutFormNode = useRef<HTMLFormElement>(null);

  const handleGraphQLExplorerClick = () => {
    // Clicking the "GraphQL Explorer" link from a `frontend` page (not an
    // old-school Rails rendered page) just changes what we render in the
    // viewport of the page. This means by default, the user dropdown doesn't
    // dissapear, but the page changes. This is a hack to hide it when you
    // click on the link.
    if (userDropdown.current) {
      userDropdown.current.setShowing(false);
    }
    setShowingUserDropdown(false);
  };

  const handleLogoutClick = (evt: Event) => {
    evt.preventDefault();

    if (logoutFormNode.current) {
      logoutFormNode.current.submit();
    }
  };

  const renderUserMenuLabel = () => {
    if (!viewer) {
      return (
        <span className="flex items-center xs-hide sm-flex flex-auto">
          Unknown User
        </span>
      );
    }

    // Capture `user` here to avoid Flow type refinement invalidation issues when using user in
    // both sides of the ternery below
    const user = viewer.user;

    return (
      <div className="circle" data-current-user-name={user.name}>
        <UserAvatar
          user={user}
          className={classNames("flex-none flex items-center")}
          style={{ width: 32, height: 32 }}
          suppressAltText={true}
        />
      </div>
    );
  };

  return (
    <Dropdown
      width={180}
      className="flex"
      style={{ flex: "0 1 auto", minWidth: 55 }}
      ref={userDropdown}
      onToggle={setShowingUserDropdown}
    >
      <DropdownButton
        className={classNames("py0 flex-auto", {
          purple: showingUserDropdown,
        })}
        style={{ paddingRight: 0 }}
      >
        {renderUserMenuLabel()}
        <span className="flex items-center flex-none">
          <Icon
            icon="down-triangle"
            style={{ width: 7, height: 7, marginLeft: ".5em" }}
          />
        </span>
      </DropdownButton>

      <NavigationButton href="/user/settings">
        Personal Settings
      </NavigationButton>
      <NavigationButton
        href="/user/graphql/console"
        onClick={handleGraphQLExplorerClick}
      >
        GraphQL Explorer
      </NavigationButton>

      <div className="md-hide lg-hide">
        <NavigationButton className="md-hide lg-hide" href="/docs">
          Documentation
        </NavigationButton>
      </div>

      <form
        action={warning ? "/admin/logout_to_admin" : "/logout"}
        method="post"
        ref={logoutFormNode}
      >
        <input type="hidden" name="_method" value="delete" />
        <input type="hidden" name="slug" value={organization?.slug} />
        <input
          type="hidden"
          name={window._csrf.param}
          value={window._csrf.token}
        />
        <NavigationButton href="#" onClick={handleLogoutClick}>
          {warning ? "Back to Admin" : "Logout"}
        </NavigationButton>
      </form>
    </Dropdown>
  );
};

export default UserDropdown;

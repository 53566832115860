import Button from "./Button";

/**
 * A generic error view that can be used as a fallback component for a Bugsnag
 * error boundary.
 *
 * Example:
 *   <ErrorBoundary FallbackComponent={ErrorView}>
 *     <MyComponent />
 *   </ErrorBoundary>
 */
interface ErrorViewProps {
  clearError?: () => void;
  className?: string;
}

export function ErrorView({ clearError, className }: ErrorViewProps) {
  return (
    <div className={className}>
      <h1>Buildkite had a problem</h1>
      <p>
        If the problem persists, email&nbsp;
        <a href="mailto:support@buildkite.com">support@buildkite.com</a>{" "}
        and&nbsp;we’ll help you out.
      </p>
      {clearError && (
        <Button theme="small" onClick={clearError}>
          Try again
        </Button>
      )}
    </div>
  );
}

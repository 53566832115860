import { twMerge } from "tailwind-merge";

interface Props {
  children: React.ReactNode;
  command?: string;
  status: React.ReactNode;
  className?: string;
}

export const Label = ({ children, command, className, status }: Props) => {
  return (
    <>
      <div
        className={twMerge(
          "flex-auto flex items-center text-charcoal-700 py-1 text-left",
          className,
        )}
      >
        <div className="truncate w-full font-medium space-x-2">
          {children}

          {command && (
            <span className="font-mono font-normal text-charcoal-300 text-xs">
              {command}
            </span>
          )}
        </div>
      </div>

      <div className="self-stretch flex items-center justify-center shrink-0">
        {status}
      </div>
    </>
  );
};

import CodeBlock from "app/components/docs/shared/code-block";
import Link from "app/components/docs/shared/link";

import "highlight.js/styles/atom-one-light.css";

type Props = {
  token?: string;
  className?: string;
  style?: any;
};

DockerQuickStart.title = "Docker";

function DockerQuickStart(props: Props) {
  const { className, style } = props;

  return (
    <div className={className} style={style}>
      <p>
        Start an agent with the{" "}
        <Link href="https://hub.docker.com/r/buildkite/agent/">
          official image
        </Link>{" "}
        based on Alpine Linux:
      </p>
      <CodeBlock language="bash">
        docker run -e BUILDKITE_AGENT_TOKEN=
        <span className="hljs-string">
          &quot;{props.token || "INSERT-YOUR-AGENT-TOKEN-HERE"}&quot;
        </span>{" "}
        buildkite/agent
        <br />
      </CodeBlock>

      <p>A much larger Ubuntu-based image is also available:</p>
      <CodeBlock language="bash">
        docker run -e BUILDKITE_AGENT_TOKEN=
        <span className="hljs-string">
          &quot;{props.token || "INSERT-YOUR-AGENT-TOKEN-HERE"}&quot;
        </span>{" "}
        buildkite/agent:ubuntu
        <br />
      </CodeBlock>

      <p>
        Alternatively all our installers are capable of running your builds
        within isolated Docker containers. Install the relevant agent for your
        system.
      </p>

      <p className="rounded bg-silver p2">
        See the <Link href="/docs/agent/docker">Docker docs</Link> for more
        details.
      </p>
    </div>
  );
}

export default DockerQuickStart;

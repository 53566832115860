import React from "react";
import Loadable, { LoadingProps } from "react-loadable";
import { default as CodeMirror, CodeMirrorOptions } from "codemirror";

import Spinner from "app/components/shared/Spinner";

const CODEMIRROR_BUFFER = 8;
const CODEMIRROR_LINE_HEIGHT = 20;

const CODEMIRROR_CONFIG: CodeMirrorOptions = {
  lineNumbers: true,
  tabSize: 2,
  indentUnit: 2,
  indentWithTabs: false,
  mode: "yaml",
  keyMap: "sublime",
  theme: "yaml",
  autoCloseBrackets: true,
  matchBrackets: true,
  showCursorWhenSelecting: true,
  viewportMargin: Infinity,
  extraKeys: {
    "Ctrl-Left": "goSubwordLeft",
    "Ctrl-Right": "goSubwordRight",
    "Alt-Left": "goGroupLeft",
    "Alt-Right": "goGroupRight",
    Tab: (cm) => {
      if (cm.somethingSelected()) {
        cm.execCommand("indentMore");
      } else {
        return cm.replaceSelection(
          Array(cm.getOption("indentUnit") + 1).join(" "),
        );
      }
    },
  },
};

type SharedProps = {
  name?: string;
  value?: string;
  autofocus?: boolean;
  readOnly?: boolean;
  lineWrapping?: boolean;
};

type Props = SharedProps & {
  CodeMirror: typeof CodeMirror;
};

class FormYAMLEditorField extends React.PureComponent<Props> {
  input: HTMLTextAreaElement | null | undefined;
  editor: CodeMirror;

  componentDidMount() {
    const { CodeMirror } = this.props;

    if (this.input) {
      this.editor = CodeMirror.fromTextArea(this.input, this.codeMirrorConfig);

      if (this.props.autofocus && !this.props.readOnly) {
        this.editor.focus();
        const lineInfo = this.editor.lineInfo(this.editor.lastLine());
        this.editor.setCursor(lineInfo.line, lineInfo.text.length);
      }
    }
  }

  componentWillUnmount() {
    if (this.editor) {
      this.editor.toTextArea();
      delete this.editor;
    }
  }

  render() {
    return (
      <div>
        <textarea
          name={this.props.name}
          defaultValue={this.props.value}
          ref={(input) => (this.input = input)}
        />
      </div>
    );
  }

  get codeMirrorConfig() {
    const cursorHeight = this.props.readOnly ? 0 : 1;
    return Object.assign(
      {
        readOnly: this.props.readOnly,
        cursorHeight: cursorHeight,
        lineWrapping: this.props.lineWrapping,
      } as CodeMirrorOptions,
      CODEMIRROR_CONFIG,
    );
  }
}

const FormYAMLEditorFieldLoader = (props: SharedProps) => {
  // Here's a dynamic loader for editor that does some magical stuff. It tries
  // to attempt the size of the editor before we load it, this way the page
  // doesn't change in size after we load in Codemirror.
  const ApproximateHeightLoader = (
    loader: LoadingProps & {
      value?: string;
    },
  ) => {
    let contents;
    if (loader.error) {
      contents = (
        <span className="red">
          There was an error loading the editor. Please reload the page.
        </span>
      );
    } else if (loader.pastDelay) {
      contents = (
        <span>
          <Spinner /> Loading Editor…
        </span>
      );
    } else {
      contents = null;
    }

    const lines = props.value ? props.value.split("\n").length : 0;
    const height = CODEMIRROR_BUFFER + lines * CODEMIRROR_LINE_HEIGHT;

    return (
      <div
        className="flex items-center justify-center"
        style={{ height: height }}
      >
        {contents}
      </div>
    );
  };

  // This loads Codemirror and all of its addons.
  const LoadableCodeMirror = Loadable.Map({
    loader: {
      CodeMirror: () =>
        import("./codemirror").then(
          (module) =>
            // HACK: Add a "zero" delay after the module has
            // loaded, to allow their styles to take effect
            new Promise((resolve: (result: Promise<never>) => void) => {
              setTimeout(() => resolve(module.default), 0);
            }),
        ),
    },

    loading() {
      return <ApproximateHeightLoader />;
    },

    render(loaded: any, props: any) {
      return (
        <FormYAMLEditorField
          CodeMirror={loaded.CodeMirror}
          name={props.name}
          value={props.value}
          autofocus={props.autofocus}
          readOnly={props.readOnly}
          lineWrapping={props.lineWrapping}
        />
      );
    },
  });

  return <LoadableCodeMirror {...props} />;
};

export default FormYAMLEditorFieldLoader;

/* eslint-disable react/require-optimization */
import { createFragmentContainer, graphql } from "react-relay";
import styled from "styled-components";

import { getCssValue } from "app/lib/cssValues";

import BuildState from "app/components/icons/BuildState";
import BuildStates from "app/constants/BuildStates";

const GREEN = getCssValue("--green-200");
const RED = getCssValue("--red-300");
const ORANGE = getCssValue("--orange-100");
const SLATE = getCssValue("--slate-100");

const STATE_COLORS = {
  [BuildStates.RUNNING]: ORANGE,
  [BuildStates.PASSED]: GREEN,
  [BuildStates.FAILING]: RED,
  [BuildStates.FAILED]: RED,
  [BuildStates.CREATING]: ORANGE,
  [BuildStates.BLOCKED]: {
    [BuildStates.PASSED]: GREEN,
    [BuildStates.RUNNING]: ORANGE,
    [BuildStates.FAILED]: RED,
  },
} as const;

const StatusBlock = styled.div<{ color?: string }>`
  width: 44px;
  height: 60px;
  background-color: ${(props) => props.color || SLATE};
`;

function getStatusBlockColor(state: string, blockedState?: string | null) {
  if (state === BuildStates.BLOCKED) {
    return blockedState
      ? STATE_COLORS[BuildStates.BLOCKED][blockedState]
      : GREEN;
  }

  return STATE_COLORS[state] || SLATE;
}

type Props = {
  pipeline: any;
};

function Status(props: Props) {
  const builds = props.pipeline.firstBuild?.edges || [];
  const mostRecentBuild = builds[0]?.node;

  if (!mostRecentBuild) {
    return (
      <StatusBlock className="rounded flex items-center justify-center">
        <BuildState.Regular state={null} />
      </StatusBlock>
    );
  }

  const { state, blockedState } = mostRecentBuild;

  return (
    <StatusBlock
      className="rounded flex items-center justify-center"
      color={getStatusBlockColor(state, blockedState)}
    >
      <BuildState.Regular state={state} blockedState={blockedState} />
    </StatusBlock>
  );
}

export default createFragmentContainer(Status, {
  pipeline: graphql`
    fragment Status_pipeline on Pipeline {
      id
      firstBuild: builds(first: 1, branch: "%default") {
        edges {
          node {
            state
            blockedState
            url
          }
        }
      }
    }
  `,
});

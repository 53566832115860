import { forwardRef, useCallback } from "react";
import { twMerge } from "tailwind-merge";

interface Props {
  onResize: (x: number, y: number) => void;
  direction: "horizontal" | "vertical";
  className?: string;
}

/**
 * Resize handle for the sidebar / drawer / etc.
 */
export const ResizeHandle = forwardRef<HTMLDivElement, Props>(
  ({ onResize, direction, className }, ref) => {
    const handleDrag = useCallback(() => {
      const onMouseMove = (event: MouseEvent) => {
        onResize(event.clientX, event.clientY);
      };

      const onMouseUp = () => {
        document.removeEventListener("mousemove", onMouseMove);
        document.removeEventListener("mouseup", onMouseUp);
      };
      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
    }, []);

    return (
      <div
        className={twMerge(
          "select-none z-50 overflow-clip before:bg-transparent before:delay-75 before:transition-colors active:before:bg-purple-600 hover:before:bg-purple-600",
          direction === "horizontal" &&
            "cursor-col-resize w-[7px] absolute top-0 bottom-0 -translate-x-1/2 before:absolute before:top-0 before:bottom-0 before:w-[3px] before:left-[2px]",
          direction === "vertical" &&
            "cursor-row-resize h-[7px] absolute left-0 right-0 -translate-y-1/2 before:absolute before:left-0 before:right-0 before:h-[3px] before:top-[2px]",
          className,
        )}
        onMouseDown={handleDrag}
        ref={ref}
      />
    );
  },
);

import { useBuild } from "app/components/Playground/BuildContext";

/**
 * A container for the non-react waterfall view.
 */
export default function WaterfallTurboPage() {
  const { build } = useBuild();

  if (!build) {
    throw new Error("Missing build context");
  }

  return (
    <turbo-frame
      class="w-full p-1"
      id="waterfall"
      src={`${build.path}/waterfall_tab`}
    />
  );
}

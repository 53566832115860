import * as React from "react";
import classNames from "classnames";
import styled from "styled-components";

import IntroWithButton from "./intro-with-button";
import Row from "./row";
import RowActions from "./row-actions";
import RowLink from "./row-link";
import RowAccordion from "./row-accordion";
import Header from "./header";

const Separator = styled.hr.attrs({
  className: "p0 m0 bg-gray",
})`
  border: none;
  height: 1px;

  &:last-child {
    display: none;
  }
`;

type Props = {
  children: React.ReactNode;
  className?: string;
  separator?: boolean;
};

class Panel extends React.PureComponent<Props> {
  static Header: any = Header;
  static IntroWithButton: any = IntroWithButton;
  static Row: any = Row;
  static RowActions: any = RowActions;
  static RowLink: any = RowLink;
  static RowAccordion: any = RowAccordion;
  static Footer: any;
  static Section: any;
  static Separator: any = Separator;
  static defaultProps = {
    separator: true,
  };

  render() {
    const children = React.Children.toArray(this.props.children);

    const nodes = [];

    // Insert a separator between each section
    let key = 0;
    for (let index = 0, length = children.length; index < length; index++) {
      if (index === 1) {
        // Insert one separator only for first panel header
        // @ts-expect-error - TS2345 - Argument of type 'Element' is not assignable to parameter of type 'never'.
        nodes.push(<Separator key="header separator" />);
      }

      if (index > 1 && this.props.separator === true) {
        // @ts-expect-error - TS2345 - Argument of type 'Element' is not assignable to parameter of type 'never'.
        nodes.push(<Separator key={(key += 1)} />);
      }

      // @ts-expect-error - TS2345 - Argument of type 'ReactChild | ReactFragment | ReactPortal' is not assignable to parameter of type 'never'.
      nodes.push(children[index]);
    }

    return (
      <section
        className={classNames(
          "border border-gray rounded",
          this.props.className,
        )}
      >
        {nodes}
      </section>
    );
  }
}

const SIMPLE_COMPONENTS = {
  Footer: "py2 px3",
  Section: "m3",
} as const;

type WrapperProps = {
  children: React.ReactNode;
  className?: string;
};

Object.keys(SIMPLE_COMPONENTS).forEach((componentName) => {
  const defaultStyle = SIMPLE_COMPONENTS[componentName];

  const Component = (props: WrapperProps) => (
    <div className={classNames(defaultStyle, props.className)}>
      {props.children}
    </div>
  );

  Component.displayName = `Panel.${componentName}`;

  // NOTE: We have to cast Panel to Object so we can assign
  //       in this way. We should probably do this a better way.
  //       see <https://github.com/facebook/flow/issues/1323>
  (Panel as any)[componentName] = Component;
});

export default Panel;

import * as React from "react";
import { getCssValue } from "app/lib/cssValues";

import * as Turbo from "@hotwired/turbo";

import Emojify from "app/components/shared/Emojify";
import RemoteButtonComponent from "app/components/shared/RemoteButtonComponent";
import BlockFieldsModal from "app/components/job/BlockFieldsModal";
import PipelineStateIcon from "app/components/shared/PipelineStateIcon";
import Icon from "app/components/shared/Icon";

import Database from "app/lib/Database";

import { titleForBrokenJob } from "./utils/job";
import { InputJob } from "./types/InputJob";

type Props = {
  job: InputJob;
  buildStore?: {
    loadAndEmit: (arg1?: any) => void;
    reload: () => void;
  };
  stepClassName: string;
};

type State = {
  fieldsModalActivated: boolean;
};

// eslint-disable-next-line react/require-optimization
export default class ManualStep extends React.Component<Props, State> {
  fieldsModal: BlockFieldsModal | null | undefined;

  state = {
    fieldsModalActivated: false,
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      prevState.fieldsModalActivated !== this.state.fieldsModalActivated &&
      this.fieldsModal
    ) {
      this.fieldsModal.show();
    }
  }

  render() {
    const fieldsModal = this.props.buildStore &&
      this.state.fieldsModalActivated && (
        <BlockFieldsModal
          ref={(fieldsModal) => (this.fieldsModal = fieldsModal)}
          buildStore={this.props.buildStore}
          job={this.props.job}
        />
      );

    return (
      <div
        key={this.props.job.id}
        className="inline-block build-pipeline-job__wrapper"
        style={{ maxWidth: "15em" }}
      >
        {fieldsModal}
        {this.renderStep()}
      </div>
    );
  }

  stepArrow() {
    return (
      <span
        style={{
          display: "flex",
          alignItems: "center",
          padding: "0 3px 0 5px",
          color: getCssValue("--base-0"),
        }}
      >
        <Icon icon="arrow-right" style={{ height: "14px", width: "14px" }} />
      </span>
    );
  }

  renderStep() {
    const { job, stepClassName } = this.props;

    if (job.state === "broken") {
      const title = titleForBrokenJob(job);

      return (
        <div className={stepClassName}>{this.stepContent({ job, title })}</div>
      );
    } else if (!job.permissions.activate.allowed) {
      return (
        <div
          aria-label={job.permissions.activate.message}
          className={stepClassName}
        >
          {this.stepContent({ job })}
        </div>
      );
    } else if (job.unblockedAt) {
      return <div className={stepClassName}>{this.stepContent({ job })}</div>;
    } else if (
      job.state === "skipped" ||
      job.state === "canceled" ||
      job.state === "blocked_failed"
    ) {
      return <div className={stepClassName}>{this.stepContent({ job })}</div>;
    }

    if (this.props.buildStore === undefined) {
      return (
        <a href={this.props.job.path} className={stepClassName}>
          {this.stepContent({ job })}
          {this.stepArrow()}
        </a>
      );
    }

    if (job.fields) {
      return (
        <a
          href="#"
          onClick={this.handleContinueButtonClick}
          className={stepClassName}
        >
          {this.stepContent({ job })}
          {this.stepArrow()}
        </a>
      );
    }

    return (
      <RemoteButtonComponent
        url={job.unblockPath}
        method="post"
        confirmText={job.prompt || "Unblock this step?"}
        className={stepClassName}
        onSuccess={(_event, response) =>
          response &&
          response.build &&
          this.props.buildStore?.loadAndEmit(Database.parse(response.build))
        }
        onError={(_event, _response) => this.props.buildStore?.reload()}
      >
        {this.stepContent({ job })}
        {this.stepArrow()}
      </RemoteButtonComponent>
    );
  }

  handleContinueButtonClick = (evt: React.MouseEvent<HTMLAnchorElement>) => {
    if (window.Features.HotwireNewBuildDialog) {
      Turbo.visit(this.props.job.unblockDialogPath, {
        frame: "unblock_dialog",
      });
    } else {
      evt.preventDefault();

      if (this.state.fieldsModalActivated && this.fieldsModal) {
        return this.fieldsModal.show();
      }

      this.setState({ fieldsModalActivated: true });
    }
  };

  stepContent({
    job,
    title,
  }: {
    job: InputJob;
    title?: string | null | undefined;
  }) {
    return (
      <div className="build-pipeline-job-content">
        <span className="build-pipeline-job__icon">
          <PipelineStateIcon job={job} />
        </span>
        <span className="truncate" style={{ maxWidth: "10em" }}>
          <Emojify
            text={job.name?.length ? job.name : "Continue"}
            // @ts-expect-error - TS2322 - Type 'string | null | undefined' is not assignable to type 'string | undefined'.
            title={title}
          />
        </span>
      </div>
    );
  }
}

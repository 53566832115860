/**
 * @generated SignedSource<<ede3cb847cb0b5cd2343903af7f883ea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JobEventExpired_event$data = {
  readonly timestamp: any;
  readonly " $fragmentType": "JobEventExpired_event";
};
export type JobEventExpired_event$key = {
  readonly " $data"?: JobEventExpired_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"JobEventExpired_event">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "JobEventExpired_event",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timestamp",
      "storageKey": null
    }
  ],
  "type": "JobEvent",
  "abstractKey": "__isJobEvent"
};

(node as any).hash = "4f467e69cf9a43ac02b5debb91a96ec5";

export default node;

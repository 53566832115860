import styled from "styled-components";

import ExecutionResult from "app/components/analytics/shared/ResultIcons/ExecutionResult";
import FailureReason from "app/components/analytics/shared/FailureReason";
import { Execution, Test } from "app/components/analytics/shared/type";

type RowTest = Omit<Test, "averageDuration" | "reliability">;
type RowExecution = Omit<Execution, "id" | "duration" | "uuid">;

export type Props = {
  test: RowTest & {
    execution: RowExecution;
  };
};

export const FailureRow = styled.div`
  width: 100%;
  display: block;
  grid-column: 2 / -1;
  overflow: hidden;
`;

FailureRow.displayName = "FailureRow";

const Row = ({ test }: Props) => {
  const failed =
    test.execution.result === "failed" && test.execution.failureReason !== null;
  return (
    <li className="row-link__container">
      <div className="ta-panel-row grid-cols-[3rem_auto_6rem]">
        <div data-testid="icon">
          <ExecutionResult.Regular
            result={test.execution.result.toUpperCase()}
          />
        </div>
        <div className="main-section">
          <a
            href={test.execution.url}
            className="block text-decoration-none color-inherit row-link"
            data-testid="name"
          >
            <span className="semi-bold block">
              {test.scope} {test.name}
            </span>
          </a>
          <span className="mt1 small dark-gray block" data-testid="location">
            {test.location}
          </span>
        </div>
        <div className="right-align">
          {test.execution.humanDuration}{" "}
          <span className="hide"> execution duration</span>
        </div>
        {failed && (
          <FailureRow>
            <FailureReason
              failureReason={test.execution.failureReason}
              endpoint={test.execution.failureExpandedUrl}
            />
          </FailureRow>
        )}
      </div>
    </li>
  );
};

export default Row;

/**
 * @generated SignedSource<<281ea6adbb2369444bccc7b2bc561ae8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AgentsCount_organization$data = {
  readonly agents: {
    readonly count: number;
  } | null;
  readonly id: string;
  readonly uuid: string;
  readonly " $fragmentType": "AgentsCount_organization";
};
export type AgentsCount_organization$key = {
  readonly " $data"?: AgentsCount_organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"AgentsCount_organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AgentsCount_organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "uuid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AgentConnection",
      "kind": "LinkedField",
      "name": "agents",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "count",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "7762e5e7b3495dd73db2c76497751a3d";

export default node;

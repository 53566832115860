/* global jQuery */

export default class ButtonManager {
  $el: JQuery;
  // @ts-expect-error - TS2564 - Property '_previous' has no initializer and is not definitely assigned in the constructor.
  _previous: string;

  constructor(el: JQuery) {
    this.$el = jQuery(el);
    this.$el.addClass("button-manager");
  }

  loading(message: string) {
    this._previous = this.$el.html();

    // Figure out what label to show
    const label = message || this._previous;

    this.$el.addClass("button-manager-loading").attr("disabled", "disable");

    this.$el.html(
      label +
        ' <i class="button-manager-loading-spinner fa fa-spinner fa-spin"></i>',
    );
  }

  reset() {
    this.$el
      .prop("disabled", false)
      .removeClass("button-manager-loading")
      .html(this._previous);
  }
}

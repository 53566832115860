import * as React from "react";
import styled from "styled-components";

const NoticeContainer = styled.div<{ color: string }>`
  border-color: ${(props) => props.color};
  overflow: hidden;
`;

const NoticeBorderBar = styled.div<{ color: string }>`
  background-color: ${(props) => props.color};
  flex: 0 0 3px;
`;

const NoticeHeading = styled.h3`
  font-size: 16px;
`;

type NoticeProps = {
  title?: string;
  noticeType: "notice" | "success" | "warning" | "error" | "purple";
  children: React.ReactNode;
};

function Notice({ title, noticeType, children }: NoticeProps) {
  const colorMap = {
    notice: "var(--lime-500)",
    success: "var(--green-500)",
    warning: "var(--orange-500)",
    error: "var(--red-600)",
    purple: "var(--purple-600)",
  } as const;

  return (
    <NoticeContainer
      className="flex border rounded"
      color={colorMap[noticeType]}
    >
      <NoticeBorderBar color={colorMap[noticeType]} />
      <div className="flex flex-column gap2 p3">
        {Boolean(title) && title !== "" ? (
          <NoticeHeading className="m0">{title}</NoticeHeading>
        ) : null}
        {children}
      </div>
    </NoticeContainer>
  );
}

export default Notice;

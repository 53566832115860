/**
 * @generated SignedSource<<631a9b9d55bb4849f21f3dbde7a30fbe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JobLink_job$data = {
  readonly url: string;
  readonly " $fragmentSpreads": FragmentRefs<"JobTitle_job">;
  readonly " $fragmentType": "JobLink_job";
};
export type JobLink_job$key = {
  readonly " $data"?: JobLink_job$data;
  readonly " $fragmentSpreads": FragmentRefs<"JobLink_job">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "JobLink_job",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "JobTitle_job"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "type": "JobTypeCommand",
  "abstractKey": null
};

(node as any).hash = "29baceaa72246eea727d093319db78bc";

export default node;

/**
 * @generated SignedSource<<6e64af1dc398094a3b3297c19caea7ef>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JobEventLimited_event$data = {
  readonly job: {
    readonly concurrency: {
      readonly group: string;
      readonly limit: number;
    } | null;
  };
  readonly timestamp: any;
  readonly " $fragmentType": "JobEventLimited_event";
};
export type JobEventLimited_event$key = {
  readonly " $data"?: JobEventLimited_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"JobEventLimited_event">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "JobEventLimited_event",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timestamp",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "JobTypeCommand",
      "kind": "LinkedField",
      "name": "job",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "JobConcurrency",
          "kind": "LinkedField",
          "name": "concurrency",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "group",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "limit",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "JobEvent",
  "abstractKey": "__isJobEvent"
};

(node as any).hash = "161b0372d78cec25341af33c18d527f9";

export default node;

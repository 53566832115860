import Environment from "app/lib/relay/environment";
import usePreloadedRelayQuery from "app/lib/usePreloadedRelayQuery";
import { QueryRenderer, graphql } from "react-relay";

import HeaderIndex from ".";

type PipelineHeaderRendererProps = {
  params: {
    buildSlug: string | null | undefined;
    pipelineSlug: string;
  };
  isCurrentOrganizationMember: boolean;
  isCurrentOrganizationAdmin: boolean;
  buildState?: string;
  branchName?: string;
  buildURL: string;
  legacySteps: boolean;
  pipelineTemplates:
    | Array<{
        uuid: string;
        name: string;
        description: string | null | undefined;
      }>
    | null
    | undefined;
};

export default function PipelineHeaderRenderer({
  params,
  ...rest
}: PipelineHeaderRendererProps) {
  const environment = Environment.get();
  const rendererQueryVariables = {
    queryBuild: !!params.buildSlug,
    ...params,
  } as const;
  const query = graphql`
    query PipelineHeaderRendererQuery(
      $buildSlug: ID
      $pipelineSlug: ID!
      $queryBuild: Boolean!
    ) {
      build(slug: $buildSlug) @include(if: $queryBuild) {
        ...Header_build
      }
      pipeline(slug: $pipelineSlug) {
        ...Header_pipeline
      }
    }
  `;

  usePreloadedRelayQuery(
    true,
    {
      environment,
      query,
      variables: rendererQueryVariables,
    },
    [],
  );

  function renderQuery({ props }: { props: any }) {
    if (!props) {
      return null;
    }

    return <HeaderIndex {...props} {...rest} />;
  }

  return (
    <QueryRenderer
      fetchPolicy="store-and-network"
      environment={environment}
      query={query}
      variables={{ queryBuild: !!params.buildSlug, ...params }}
      render={renderQuery}
    />
  );
}

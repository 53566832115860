import { commitMutation, graphql } from "react-relay";
import Environment from "app/lib/relay/environment";

const AgentStop = graphql`
  mutation AgentStopMutation($input: AgentStopInput!) {
    agentStop(input: $input) {
      agent {
        connectionState
        disconnectedAt
        # eslint-disable-next-line @graphql-eslint/no-deprecated
        stoppedAt
        # eslint-disable-next-line @graphql-eslint/no-deprecated
        stoppedBy {
          name
        }
      }
    }
  }
`;

const agentStopMutation = ({ id, graceful, onCompleted, onError }) => {
  const environment = Environment.get();
  const config = {
    mutation: AgentStop,
    variables: {
      input: {
        id,
        graceful,
      },
    },
    onCompleted,
    onError,
  };

  return commitMutation(environment, config);
};

export default agentStopMutation;

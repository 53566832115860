/**
 * @generated SignedSource<<52fc0716efd08bd1eeb91966edb2584d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateBuildDialog_build$data = {
  readonly branch: string;
  readonly message: string | null;
  readonly " $fragmentType": "CreateBuildDialog_build";
};
export type CreateBuildDialog_build$key = {
  readonly " $data"?: CreateBuildDialog_build$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreateBuildDialog_build">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreateBuildDialog_build",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "branch",
      "storageKey": null
    }
  ],
  "type": "Build",
  "abstractKey": null
};

(node as any).hash = "d7f5f28d9d369905cb1c16c93b91490f";

export default node;

import { graphql, QueryRenderer } from "react-relay";
import Environment from "app/lib/relay/environment";
import Page from "./Page";

const PIPELINE_TEAMS_QUERY = graphql`
  query PipelineTeams_Query($pipelineSlug: ID!) {
    pipeline(slug: $pipelineSlug) {
      ...Page_pipeline
    }
  }
`;

const Index = ({ pipelineSlug }: { pipelineSlug: string }) => (
  <QueryRenderer
    fetchPolicy="store-and-network"
    cacheConfig={{ force: true }}
    environment={Environment.get()}
    query={PIPELINE_TEAMS_QUERY}
    variables={{ pipelineSlug: pipelineSlug }}
    render={({ props, error }) => {
      if (error || !props) {
        return null;
      }
      return <Page {...props} />;
    }}
  />
);

export default Index;

/* eslint-disable @typescript-eslint/no-unused-vars */
import { BaseStep, Type } from ".";
import {
  BlockStep as BlockStepSchema,
  StringBlockStep as StringBlockStepSchema,
} from "../schema";

export interface BlockStep extends BaseStep {
  type: Type.Block;
  label: string;
}

export function isStringBlockStep(step: any): step is StringBlockStepSchema {
  return step === "block";
}

export function isBlockStep(step: any): step is BlockStepSchema {
  return step.type === "block" || step.block !== undefined;
}

export function parseBlockStep(config: BlockStepSchema): BlockStep {
  return {
    uuid: "",
    dependencies: [],
    type: Type.Block,
    label: config.label || config.block || "",
  };
}

export function parseStringBlockStep(config: StringBlockStepSchema): BlockStep {
  return {
    uuid: "",
    dependencies: [],
    type: Type.Block,
    label: "",
  };
}

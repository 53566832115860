import { createFragmentContainer, graphql } from "react-relay";

import FriendlyTime from "app/components/shared/FriendlyTime";

import { Section, SectionHeading } from "./shared";

const ContextName = ({ context }) => {
  const str = context.__typename.replace(/^Audit|Context$/g, "");

  if (str.startsWith("Agent")) {
    return `${str.replace("Agent", "Agent ")} Context`;
  }

  return `${str} Context`;
};

const RequestApiAccessKeyTokenUuid = ({ requestApiAccessTokenUuid }) => {
  if (requestApiAccessTokenUuid === undefined) {
    return null;
  }

  return [
    <dt className="mt1 dark-gray" key="requestApiAccessTokenUuid-title">
      Request API Access Token UUID
    </dt>,
    <dd className="ml0" key="requestApiAccessTokenUuid-definition">
      {requestApiAccessTokenUuid}
    </dd>,
  ];
};

const RequestIpAddress = ({ requestIpAddress }) => {
  if (requestIpAddress === undefined) {
    return null;
  }

  return [
    <dt className="mt1 dark-gray" key="requestIpAddress-title">
      Request IP Address
    </dt>,
    <dd className="ml0" key="requestIpAddress-definition">
      {requestIpAddress}
    </dd>,
  ];
};

const AgentUuid = ({ agentUuid }) => {
  if (agentUuid === undefined) {
    return null;
  }

  return [
    <dt className="mt1 dark-gray" key="agentUuid-title">
      Agent UUID
    </dt>,
    <dd className="ml0" key="agentUuid-definition">
      {agentUuid}
    </dd>,
  ];
};

const OrganizationUuid = ({ organizationUuid }) => {
  if (organizationUuid === undefined) {
    return null;
  }

  return [
    <dt className="mt1 dark-gray" key="organizationUuid-title">
      Agent Organization UUID
    </dt>,
    <dd className="ml0" key="organizationUuid-definition">
      {organizationUuid}
    </dd>,
  ];
};

const SessionIpAddress = ({ sessionIpAddress }) => {
  if (sessionIpAddress === undefined) {
    return null;
  }

  return [
    <dt className="mt1 dark-gray" key="ipAddress-title">
      Agent Session IP Address
    </dt>,
    <dd className="ml0" key="ipAddress-definition">
      {sessionIpAddress}
    </dd>,
  ];
};

const ConnectionState = ({ connectionState }) => {
  if (connectionState === undefined) {
    return null;
  }

  return [
    <dt className="mt1 dark-gray" key="connectionState-title">
      Agent Connection State
    </dt>,
    <dd className="ml0" key="connectionState-definition">
      {connectionState}
    </dd>,
  ];
};

const AuthenticationType = ({ authenticationType }) => {
  if (authenticationType === undefined) {
    return null;
  }

  return [
    <dt className="mt1 dark-gray" key="authenticationType-title">
      Agent Authentication Type
    </dt>,
    <dd className="ml0" key="authenticationType-definition">
      {authenticationType}
    </dd>,
  ];
};

const SessionCreatedAt = ({ sessionCreatedAt }) => {
  if (sessionCreatedAt === undefined) {
    return null;
  }

  return [
    <dt className="mt1 dark-gray" key="sessionCreatedAt-title">
      Session Started
    </dt>,
    <dd className="ml0" key="sessionCreatedAt-definition">
      <FriendlyTime value={sessionCreatedAt} />
    </dd>,
  ];
};

const RequestUserAgent = ({ requestUserAgent }) => {
  if (requestUserAgent === undefined) {
    return null;
  }

  return [
    <dt className="mt1 dark-gray" key="requestUserAgent-title">
      Request User Agent
    </dt>,
    <dd className="ml0" key="requestUserAgent-definition">
      {requestUserAgent}
    </dd>,
  ];
};

type Props = {
  auditEvent: {
    context:
      | {
          __typename: string;
          requestIpAddress?: string;
          requestUserAgent?: string;
          sessionCreatedAt?: string;
          requestApiAccessTokenUuid?: string;
          agentUuid?: string;
          organizationUuid?: string;
          sessionIpAddress?: string;
          connectionState?: string;
          authenticationType?: string;
        }
      | null
      | undefined;
  };
};

const AuditLogContextSection = ({ auditEvent }: Props) => {
  const { context } = auditEvent;
  if (!context) {
    return null;
  }

  return (
    <Section data-testid="audit-api-context">
      <SectionHeading className="m0 mb2">
        {/* @ts-expect-error - TS2786 - 'ContextName' cannot be used as a JSX component. */}
        <ContextName context={context} />
      </SectionHeading>
      <dl className="m0">
        {/* @ts-expect-error - TS2786 - 'RequestApiAccessKeyTokenUuid' cannot be used as a JSX component. */}
        <RequestApiAccessKeyTokenUuid
          requestApiAccessTokenUuid={context.requestApiAccessTokenUuid}
        />
        {/* @ts-expect-error - TS2786 - 'RequestIpAddress' cannot be used as a JSX component. */}
        <RequestIpAddress requestIpAddress={context.requestIpAddress} />
        {/* @ts-expect-error - TS2786 - 'RequestUserAgent' cannot be used as a JSX component. */}
        <RequestUserAgent requestUserAgent={context.requestUserAgent} />
        {/* @ts-expect-error - TS2786 - 'SessionCreatedAt' cannot be used as a JSX component. */}
        <SessionCreatedAt sessionCreatedAt={context.sessionCreatedAt} />
        {/* @ts-expect-error - TS2786 - 'AgentUuid' cannot be used as a JSX component. */}
        <AgentUuid agentUuid={context.agentUuid} />
        {/* @ts-expect-error - TS2786 - 'OrganizationUuid' cannot be used as a JSX component. */}
        <OrganizationUuid organizationUuid={context.organizationUuid} />
        {/* @ts-expect-error - TS2786 - 'SessionIpAddress' cannot be used as a JSX component. */}
        <SessionIpAddress sessionIpAddress={context.sessionIpAddress} />
        {/* @ts-expect-error - TS2786 - 'ConnectionState' cannot be used as a JSX component. */}
        <ConnectionState connectionState={context.connectionState} />
        {/* @ts-expect-error - TS2786 - 'AuthenticationType' cannot be used as a JSX component. */}
        <AuthenticationType authenticationType={context.authenticationType} />
      </dl>
    </Section>
  );
};

export default createFragmentContainer(AuditLogContextSection, {
  auditEvent: graphql`
    fragment contextSection_auditEvent on AuditEvent {
      context {
        __typename
        ... on AuditWebContext {
          requestIpAddress
          requestUserAgent
          sessionCreatedAt
        }
        ... on AuditAPIContext {
          requestIpAddress
          requestUserAgent
          requestApiAccessTokenUuid
        }
        ... on AuditAgentAPIContext {
          agentUuid
          organizationUuid
          sessionIpAddress
          connectionState
          authenticationType
          requestIpAddress
        }
      }
    }
  `,
});

import { useEffect, useRef } from "react";
import { Spinner, SpinnerOptions } from "spin.js";

const DEFAULT_OPTS: SpinnerOptions = {
  lines: 7, // The number of lines to draw
  length: 0, // The length of each line
  width: 2, // The line thickness
  radius: 2, // The radius of the inner circle
  scale: 1, // Scales overall size of the spinner
  corners: 1, // Corner roundness (0..1)
  speed: 2, // Rounds per second
  rotate: 0, // The rotation offset
  animation: "spinner-line-fade-more", // The CSS animation name for the lines
  direction: 1, // 1: clockwise, -1: counterclockwise
  color: "#ffffff", // CSS color or array of colors
  fadeColor: "transparent", // CSS color or array of colors
  shadow: false, // Whether or not to add shadows
  zIndex: 0, // The z-index (defaults to 2e9)
} as const;

const LegacySpinner = () => {
  const spinnerElementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const spinner = new Spinner(DEFAULT_OPTS).spin(
      spinnerElementRef.current || undefined,
    );
    return () => {
      spinner.stop();
    };
  }, []);

  return <div className="SpinnerComponent" ref={spinnerElementRef} />;
};

export default LegacySpinner;

/**
 * @generated SignedSource<<ee3bb8a8c4fd6098d9d6b987d087db90>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type TOTPDeleteInput = {
  clientMutationId?: string | null;
  id: string;
};
export type TwoFactorDeleteMutation$variables = {
  input: TOTPDeleteInput;
};
export type TwoFactorDeleteMutation$data = {
  readonly totpDelete: {
    readonly clientMutationId: string | null;
    readonly viewer: {
      readonly totp: {
        readonly id: string;
      } | null;
    };
  } | null;
};
export type TwoFactorDeleteMutation = {
  response: TwoFactorDeleteMutation$data;
  variables: TwoFactorDeleteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientMutationId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "TOTP",
  "kind": "LinkedField",
  "name": "totp",
  "plural": false,
  "selections": [
    (v3/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TwoFactorDeleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "TOTPDeletePayload",
        "kind": "LinkedField",
        "name": "totpDelete",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Viewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TwoFactorDeleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "TOTPDeletePayload",
        "kind": "LinkedField",
        "name": "totpDelete",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Viewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f496535b55ac652d0d79e071d76f1361",
    "id": null,
    "metadata": {},
    "name": "TwoFactorDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation TwoFactorDeleteMutation(\n  $input: TOTPDeleteInput!\n) {\n  totpDelete(input: $input) {\n    clientMutationId\n    viewer {\n      totp {\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e3963934a5b6600be7f8c78942207c51";

export default node;

/**
 * @generated SignedSource<<4b140c6f79279aebcc73787d513cbd9e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PipelineAccessLevels = "BUILD_AND_READ" | "MANAGE_BUILD_AND_READ" | "READ_ONLY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type TeamRow_teamPipeline$data = {
  readonly accessLevel: PipelineAccessLevels;
  readonly id: string;
  readonly permissions: {
    readonly teamPipelineDelete: {
      readonly allowed: boolean;
    } | null;
    readonly teamPipelineUpdate: {
      readonly allowed: boolean;
    } | null;
  };
  readonly pipeline: {
    readonly id: string;
  } | null;
  readonly team: {
    readonly description: string | null;
    readonly id: string;
    readonly members: {
      readonly count: number;
    } | null;
    readonly name: string;
    readonly pipelines: {
      readonly count: number;
    } | null;
    readonly slug: string;
  } | null;
  readonly " $fragmentType": "TeamRow_teamPipeline";
};
export type TeamRow_teamPipeline$key = {
  readonly " $data"?: TeamRow_teamPipeline$data;
  readonly " $fragmentSpreads": FragmentRefs<"TeamRow_teamPipeline">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "count",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "allowed",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TeamRow_teamPipeline",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accessLevel",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Pipeline",
      "kind": "LinkedField",
      "name": "pipeline",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Team",
      "kind": "LinkedField",
      "name": "team",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TeamMemberConnection",
          "kind": "LinkedField",
          "name": "members",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TeamPipelineConnection",
          "kind": "LinkedField",
          "name": "pipelines",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TeamPipelinePermissions",
      "kind": "LinkedField",
      "name": "permissions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Permission",
          "kind": "LinkedField",
          "name": "teamPipelineUpdate",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Permission",
          "kind": "LinkedField",
          "name": "teamPipelineDelete",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TeamPipeline",
  "abstractKey": null
};
})();

(node as any).hash = "4f6c9163889188c52a6c7c89ddda144f";

export default node;

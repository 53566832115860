import CodeBlock from "app/components/docs/shared/code-block";
import Link from "app/components/docs/shared/link";

import "highlight.js/styles/atom-one-light.css";

type Props = {
  token?: string;
  className?: string;
  style?: any;
};

MacOSQuickStart.title = "macOS";
MacOSQuickStart.emoji = ":finder:";

function MacOSQuickStart(props: Props) {
  const { className, style } = props;

  return (
    <div className={className} style={style}>
      <p>
        If you have <Link href="http://brew.sh/">Homebrew</Link> installed you
        can use our{" "}
        <Link href="https://github.com/buildkite/homebrew-buildkite">
          Buildkite formula repository
        </Link>{" "}
        to install the agent:
      </p>

      <CodeBlock language="bash">
        brew install buildkite/buildkite/buildkite-agent
        <br />
      </CodeBlock>

      <p>Then configure your agent token:</p>
      <CodeBlock language="bash">
        sed -i <span className="hljs-string">&apos;&apos;</span>{" "}
        <span className="hljs-string">
          &quot;s/xxx/{props.token || "INSERT-YOUR-AGENT-TOKEN-HERE"}/g&quot;
        </span>{" "}
        <span className="hljs-string">
          &quot;<span className="hljs-variable">$(brew --prefix)</span>&quot;
        </span>
        /etc/buildkite-agent/buildkite-agent.cfg
        <br />
      </CodeBlock>

      <p>
        If you don&apos;t use Homebrew you should follow the{" "}
        <Link href="/docs/agent/linux">Linux</Link> install instructions.
      </p>

      <p className="rounded bg-silver p2">
        See the <Link href="/docs/agent/osx">macOS agent docs</Link> for more
        details.
      </p>
    </div>
  );
}

export default MacOSQuickStart;

import Code from "app/components/docs/shared/code";
import CodeBlock from "app/components/docs/shared/code-block";
import Link from "app/components/docs/shared/link";

import "highlight.js/styles/atom-one-light.css";

type Props = {
  token?: string;
  className?: string;
  style?: any;
};

RedhatOrCentOSQuickStart.title = "RH/CentOS";
RedhatOrCentOSQuickStart.slug = "redhat";
RedhatOrCentOSQuickStart.emoji = ":redhat:";

function RedhatOrCentOSQuickStart(props: Props) {
  const { className, style } = props;

  return (
    <div className={className} style={style}>
      <p>
        The Buildkite Agent can be installed on Red Hat, CentOS and Amazon Linux
        using our yum repository.
      </p>
      <p>
        Firstly, add our yum repository for your architecture (run{" "}
        <Code>uname -m</Code> to find your system’s arch).
      </p>
      <p>For 64-bit (x86_64):</p>
      <CodeBlock language="bash">
        sudo sh -c{" "}
        <span className="hljs-string">
          &apos;echo -e &quot;[buildkite-agent]\nname = Buildkite Pty
          Ltd\nbaseurl =
          https://yum.buildkite.com/buildkite-agent/stable/x86_64/\nenabled=1\ngpgcheck=0\npriority=1&quot;
          &gt; /etc/yum.repos.d/buildkite-agent.repo&apos;
        </span>
        <br />
      </CodeBlock>

      <p>For 32-bit (i386):</p>
      <CodeBlock language="bash">
        sudo sh -c{" "}
        <span className="hljs-string">
          &apos;echo -e &quot;[buildkite-agent]\nname = Buildkite Pty
          Ltd\nbaseurl =
          https://yum.buildkite.com/buildkite-agent/stable/i386/\nenabled=1\ngpgcheck=0\npriority=1&quot;
          &gt; /etc/yum.repos.d/buildkite-agent.repo&apos;
        </span>
        <br />
      </CodeBlock>

      <p>Then, install the agent:</p>
      <CodeBlock language="bash">
        sudo yum -y install buildkite-agent
        <br />
      </CodeBlock>

      <p>Configure your agent token:</p>
      <CodeBlock language="bash">
        sudo sed -i{" "}
        <span className="hljs-string">
          &quot;s/xxx/{props.token || "INSERT-YOUR-AGENT-TOKEN-HERE"}/g&quot;
        </span>{" "}
        /etc/buildkite-agent/buildkite-agent.cfg
        <br />
      </CodeBlock>

      <p>And then start the agent, and tail the logs:</p>
      <CodeBlock language="bash">
        <span className="hljs-comment"># For distributions with systemctl</span>
        <br />
        {/*
         */}
        sudo systemctl <span className="hljs-built_in">enable</span>{" "}
        buildkite-agent &amp;&amp; sudo systemctl start buildkite-agent
        <br />
        {/*
         */}
        sudo tail -f /var/<span className="hljs-built_in">log</span>/messages
        <br />
        <br />
        <span className="hljs-comment">
          # For distributions without systemctl (such as Amazon Linux)
        </span>
        <br />
        {/*
         */}
        sudo service buildkite-agent start
        <br />
        {/*
         */}
        sudo tail -f /var/<span className="hljs-built_in">log</span>
        /buildkite-agent.log
        <br />
      </CodeBlock>

      <p className="rounded bg-silver p2">
        See the <Link href="/docs/agent/redhat">Red Hat/CentOS agent docs</Link>{" "}
        for more details.
      </p>
    </div>
  );
}

export default RedhatOrCentOSQuickStart;

import Badge from "app/components/shared/Badge";
import TeamPrivacyConstants from "app/constants/TeamPrivacyConstants";
import { PureComponent } from "react";
import { createFragmentContainer, graphql } from "react-relay";

type Props = {
  team: any;
};

class TeamLabels extends PureComponent<Props> {
  render() {
    if (this.props.team.privacy === TeamPrivacyConstants.SECRET) {
      return (
        <div className="flex ml1">
          <Badge outline={true} className="regular">
            Secret
          </Badge>
        </div>
      );
    }
    return null;
  }
}

export default createFragmentContainer(TeamLabels, {
  team: graphql`
    fragment Labels_team on Team {
      privacy
    }
  `,
});

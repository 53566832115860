import ReactSelect, { Props } from "react-select";

const Select = (props: Props) => {
  const defaultStyles = {
    control: (provided) => ({
      ...provided,
      cursor: "pointer",
      borderColor: "var(--gray-600)",
      boxShadow: "none",
      "&:hover, &:focus": {
        borderColor: "var(--gray-800)",
      },
    }),
    groupHeading: (provided) => ({
      ...provided,
      backgroundColor: "var(--gray-100)",
      color: "var(--charcoal-800)",
      textTransform: "normal",
      fontSize: "0.8rem",
      padding: "0.5rem",
      fontWeight: "600",
    }),
    group: (provided) => ({
      ...provided,
      padding: 0,
      margin: 0,
    }),
    option: (provided) => ({
      ...provided,
      cursor: "pointer",
    }),
  };

  return <ReactSelect styles={defaultStyles} {...props} />;
};

export default Select;

import Button from "app/components/shared/Button";
import Dropdown from "app/components/shared/Dropdown";
import Icon from "app/components/shared/Icon";
import { Build } from "app/stores/BuildShowStore";
import { twMerge } from "tailwind-merge";

interface Props {
  build: Build;
  className?: string;
}

export function RebuildButton({ build, className }: Props) {
  if (
    !build.project.archived &&
    (build.state === "canceling" || build.finishedAt)
  ) {
    if (build.permissions.rebuild.allowed && build.project.allowRebuilds) {
      return (
        <div>
          <a
            className={twMerge(
              "btn btn-default build-rebuild-button btn-grouped btn-flex btn-grouped--first center",
              className,
            )}
            data-method="post"
            href={build.rebuildPath}
            rel="nofollow"
          >
            <Icon
              icon="custom/outline/16x/arrows-refresh"
              style={{ width: 16, height: 16 }}
              className="mr1 charcoal-700"
            />

            <span>Rebuild</span>
          </a>

          <Dropdown width={200} className="inline-block">
            <Button
              className={twMerge(
                "btn-grouped btn-flex btn-grouped--last",
                className,
              )}
              title="Rebuild options"
            >
              <Icon icon="down-triangle" style={{ width: 7, height: 7 }} />
            </Button>

            <a
              data-method="post"
              href={build.rebuildPath}
              rel="nofollow"
              className="btn block hover-lime"
            >
              Rebuild this commit
            </a>
            <a
              data-method="post"
              href={build.rebuildBranchPath}
              rel="nofollow"
              className="btn block hover-lime"
            >
              Rebuild this branch
            </a>

            {Features.HotwireNewBuildDialog ? (
              <a
                href={build.newPath}
                className="btn block hover-lime"
                data-turbo={true}
                data-turbo-frame="modal_dialog"
              >
                Custom rebuild&hellip;
              </a>
            ) : (
              <a href="#new" className="btn block hover-lime">
                Custom rebuild&hellip;
              </a>
            )}
          </Dropdown>
        </div>
      );
    } else if (build.permissions.rebuild.reason !== "anonymous") {
      return (
        <span
          className={twMerge(
            "btn btn-default build-rebuild-button center mx1 btn--disabled",
            className,
          )}
          aria-label={build.permissions.rebuild.message}
        >
          <i className="fa fa-refresh mr1" />
          <span>Rebuild</span>
        </span>
      );
    }
  }

  return null;
}

import { CommandJob } from "app/components/build/Header/pipeline/types/CommandJob";
import Emojify from "app/components/shared/Emojify";
import PipelineStateIcon from "app/components/shared/PipelineStateIcon/PipelineStateIcon";
import { twMerge } from "tailwind-merge";
import { Label } from "./Label";

// TODO: Does this exist elsewhere?
const colorForJob = (job: CommandJob) => {
  switch (job.state) {
    case "finished":
      if (job.passed) {
        return "text-green-500";
      }
      return "text-red-500";
    case "running":
      return "text-yellow-500";
    default:
      return "text-charcoal-700";
  }
};

export const JobLabel = ({ job }: { job: CommandJob }) => {
  const name = job.name || job.command || "";

  let count: string | null = null;
  if (job.parallelGroupIndex !== null && job.parallelGroupIndex !== undefined) {
    count = `${job.parallelGroupIndex + 1}/${job.parallelGroupTotal}`;
  }

  return (
    <Label
      command={job.command || undefined}
      status={
        <>
          {job.retriedInJobUuid && (
            <span className="rounded-[3px] px-0.5 border border-current text-xs bg-white">
              Retried
            </span>
          )}

          <div
            className={twMerge(
              "self-stretch flex items-center justify-center w-7",
              colorForJob(job),
            )}
          >
            <PipelineStateIcon
              job={job}
              className="w-4 h-4"
              style={{ width: undefined, height: undefined }}
            />
          </div>
        </>
      }
    >
      <div className="inline-block space-x-1">
        {count && (
          <span className="rounded-[3px] px-0.5 border border-current text-xs bg-white inline-block">
            {count}
          </span>
        )}

        {name && <Emojify text={name} />}
      </div>
    </Label>
  );
};

import classNames from "classnames";
import { useParams } from "react-router-dom";
import { useMostRecentJob } from "app/components/Playground/BuildContext";
import { twMerge } from "tailwind-merge";
import { shortCommit } from "app/lib/commits";
import { TriggerStep } from "app/lib/pipeline";
import { TriggerJob } from "app/components/build/Header/pipeline/types/TriggerJob";
import { StateIcon } from "app/components/Playground/Node/BaseNode/StateIcon";
import { textColorForStep } from "app/components/Playground/Node/colors";
import Emojify from "app/components/shared/Emojify";

import { Label } from "./Label";
import { StepLink } from "./StepLink";

export function TriggerStepListItem({ step }: { step: TriggerStep }) {
  const { stepId } = useParams();
  const job = useMostRecentJob<TriggerJob>(step.uuid);

  let label;
  let command;

  if (job) {
    const commit = shortCommit(job.triggerCommit, job.triggerCommitShortLength);
    const pipeline = job.triggerProject?.name || step.triggerPipelineSlug;
    label = step.label || "Trigger build";
    command = `${job.triggerBranch}\x2F${commit} on ${pipeline}`;
  } else {
    label = step.label || "Trigger step";
    command = null;
  }

  return (
    <StepLink
      uuid={step.uuid}
      className={twMerge(
        "rounded-md hover:bg-gray-200 pl-2",
        classNames({ "bg-gray-200": step.uuid === stepId }),
      )}
    >
      <Label
        command={command}
        status={
          <StateIcon
            className={twMerge("w-7", textColorForStep(step))}
            {...step}
          />
        }
      >
        <Emojify text={label} />
      </Label>
    </StepLink>
  );
}

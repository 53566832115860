import { shortCommit } from "app/lib/commits";
import { twMerge } from "tailwind-merge";

interface Props {
  commitId?: string;
  commitShortLength?: number;
  commitUrl?: string;
  project: {
    provider: {
      frontendIcon?: string;
      url?: string;
      id?: string;
    };
  };
  className?: string;
}

export function Commit({
  commitId,
  commitShortLength,
  commitUrl,
  project,
  className,
}: Props) {
  if (!commitId) {
    return null;
  }

  if (commitUrl) {
    const providerIconClass = `build-header__provider-icon fa fa-${project.provider.frontendIcon}`;

    return (
      <span
        title={commitId}
        className={twMerge("flex items-center font-mono", className)}
      >
        <a href={commitUrl} className="color-inherit hover-color-inherit">
          <span className={providerIconClass} />
          {shortCommit(commitId, commitShortLength)}
        </a>
      </span>
    );
  }

  return (
    <span title={commitId} className={twMerge("font-mono", className)}>
      {shortCommit(commitId, commitShortLength)}
    </span>
  );
}

import Chooser from "app/components/shared/Chooser";
import Dropdown from "app/components/shared/Dropdown";
import PermissionDescription from "app/components/shared/PermissionDescription";
import PermissionSelectOptionDescriptions from "app/components/shared/PermissionSelectOptionDescriptions";

const READ_ONLY = "READ_ONLY";
const READ_AND_WRITE = "READ_AND_WRITE";
const READ_WRITE_AND_ADMIN = "READ_WRITE_AND_ADMIN";

const getLabel = (value) => {
  switch (value) {
    case READ_ONLY:
      return "Read Only";
    case READ_AND_WRITE:
      return "Read & Write";
    case READ_WRITE_AND_ADMIN:
      return "Full Access";
  }
};

const DropdownWithChooser = ({ saving, selected, onAccessLevelChange }) => (
  <Dropdown width={270}>
    <div className="underline-dotted cursor-pointer inline-block regular">
      {getLabel(selected)}
    </div>

    <Chooser selected={selected} onSelect={onAccessLevelChange}>
      <Chooser.SelectOption
        value={READ_WRITE_AND_ADMIN}
        saving={saving === READ_WRITE_AND_ADMIN}
        selected={selected === READ_WRITE_AND_ADMIN}
        label={getLabel(READ_WRITE_AND_ADMIN)}
        description={
          <PermissionSelectOptionDescriptions>
            <PermissionDescription
              allowed={true}
              permission="view and download packages"
            />
            <PermissionDescription
              allowed={true}
              permission="publish packages"
            />
            <PermissionDescription
              allowed={true}
              permission="delete packages"
            />
            <PermissionDescription
              allowed={true}
              permission="create, edit and delete registries"
            />
          </PermissionSelectOptionDescriptions>
        }
      />
      <Chooser.SelectOption
        value={READ_AND_WRITE}
        label={getLabel(READ_AND_WRITE)}
        saving={saving === READ_AND_WRITE}
        selected={selected === READ_AND_WRITE}
        description={
          <PermissionSelectOptionDescriptions>
            <PermissionDescription
              allowed={true}
              permission="view and download packages"
            />
            <PermissionDescription
              allowed={true}
              permission="publish packages"
            />
            <PermissionDescription
              allowed={false}
              permission="delete packages"
            />
            <PermissionDescription
              allowed={false}
              permission="create, edit and delete registries"
            />
          </PermissionSelectOptionDescriptions>
        }
      />
      <Chooser.SelectOption
        value={READ_ONLY}
        label={getLabel(READ_ONLY)}
        saving={saving === READ_ONLY}
        selected={selected === READ_ONLY}
        description={
          <PermissionSelectOptionDescriptions>
            <PermissionDescription
              allowed={true}
              permission="view and download packages"
            />
            <PermissionDescription
              allowed={false}
              permission="publish packages"
            />
            <PermissionDescription
              allowed={false}
              permission="delete packages"
            />
            <PermissionDescription
              allowed={false}
              permission="create, edit and delete registries"
            />
          </PermissionSelectOptionDescriptions>
        }
      />
    </Chooser>
  </Dropdown>
);

const AccessLevel = ({ saving, teamRegistry, onAccessLevelChange }) => {
  if (teamRegistry.permissions.teamRegistryUpdate.allowed) {
    return (
      <DropdownWithChooser
        saving={saving}
        selected={teamRegistry.accessLevel}
        onAccessLevelChange={onAccessLevelChange}
      />
    );
  }
  return (
    <span className="dark-gray">{getLabel(teamRegistry.accessLevel)}</span>
  );
};

export default AccessLevel;

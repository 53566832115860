import styled from "styled-components";
import classNames from "classnames";
import Emojify from "./Emojify";
import Icon from "./Icon";

type Props = {
  emoji: string | null | undefined;
  color: string | null | undefined;
  size: number;
  fallbackIcon: string;
  fallbackColor: string;
  className?: string;
};

const Base = styled.div<{ color: string; size: number }>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  font-size: ${(props) => props.size - 24}px;
  background-color: ${(props) => props.color};
  border-radius: 4px;
`;

// Render an avatar using specified emoji and color. Will fallback to an icon if no emoji is specified.
export default function EmojiAvatar({
  emoji,
  color,
  size,
  fallbackIcon,
  className,
  fallbackColor,
}: Props) {
  return (
    <Base
      color={color ?? fallbackColor}
      size={size}
      className={classNames(
        "flex items-center justify-center flex-none",
        className,
      )}
      data-testid="emoji-avatar-base"
    >
      {emoji ? (
        <Emojify text={emoji} className="line-height-1" />
      ) : (
        // @ts-expect-error - TS2322 - Type '{ icon: string; size: number; }' is not assignable to type 'IntrinsicAttributes & Props'.
        <Icon icon={fallbackIcon} size={size - 16} />
      )}
    </Base>
  );
}

EmojiAvatar.defaultProps = {
  size: 40,
  fallbackColor: "#F5F5F5",
  fallbackIcon: "custom/outline/pipeline",
};

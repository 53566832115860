import * as React from "react";

import Popover from ".";
import calculateViewportOffsets from "./calculate-viewport-offsets";

type Props = {
  children: React.ReactNode;
  className?: string;
  nibOffsetX: number;
  position: "relative" | "absolute";
  style?: any;
  width: number;
  id?: string;
};

type State = {
  offsetX: number;
  offsetY: number;
  showing: boolean;
  width: number;
};

export default class AnchoredPopover extends React.PureComponent<Props, State> {
  static defaultProps = {
    nibOffsetX: 0,
    position: "relative",
    style: {},
    width: 250,
  };

  wrapperNode: HTMLSpanElement | null | undefined;
  _resizeDebounceTimeout: number | null | undefined;

  constructor(props: Props) {
    super(props);

    this.state = {
      offsetX: 0,
      offsetY: 45,
      showing: false,
      width: 250,
    };

    if (props.id) {
      // @ts-expect-error - TS2540 - Cannot assign to 'showing' because it is a read-only property.
      this.state.showing = window[`popover-${props.id}-showing`] ?? false;
    }
  }

  handleWindowResize = () => {
    // when hidden, we wait for the resize to be finished!
    // to do so, we clear timeouts on each event until we get
    // a good delay between them.
    const optimizeForHidden = !this.state.showing;

    // when hidden, we wait 2.5 times as long between
    // recalculations, which usually means a user is
    // done resizing by the time we do recalculate
    const debounceTimeout = optimizeForHidden ? 250 : 100;

    if (optimizeForHidden && this._resizeDebounceTimeout) {
      clearTimeout(this._resizeDebounceTimeout);
      delete this._resizeDebounceTimeout;
    }

    if (!this._resizeDebounceTimeout) {
      this._resizeDebounceTimeout = setTimeout(
        this.handleDebouncedWindowResize,
        debounceTimeout,
      );
    }
  };

  handleDebouncedWindowResize = () => {
    if (this._resizeDebounceTimeout) {
      clearTimeout(this._resizeDebounceTimeout);
      delete this._resizeDebounceTimeout;
    }

    this.calculateViewportOffsets();
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowResize, false);
    this.calculateViewportOffsets();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowResize);

    // Just in case...
    if (this._resizeDebounceTimeout) {
      clearTimeout(this._resizeDebounceTimeout);
      delete this._resizeDebounceTimeout;
    }
  }

  calculateViewportOffsets = () => {
    if (this.wrapperNode) {
      this.setState(
        calculateViewportOffsets(this.props.width, this.wrapperNode),
      );
    }
  };

  handleMouseOver = (event: any) => {
    // NOTE: We have to cast `event.target` to a Node to use with `contains`
    //       see <https://github.com/facebook/flow/issues/4645>
    const target: Node = event.target;

    if (this.wrapperNode && this.wrapperNode.contains(target)) {
      this.setState({ showing: true });

      if (this.props.id) {
        window[`popover-${this.props.id}-showing`] = true;
      }
    }
  };

  handleMouseOut = (event: any) => {
    // NOTE: We have to cast `event.target` to a Node to use with `contains`
    //       see <https://github.com/facebook/flow/issues/4645>
    const target: Node = event.target;

    if (this.wrapperNode && this.wrapperNode.contains(target)) {
      this.setState({ showing: false });

      if (this.props.id) {
        window[`popover-${this.props.id}-showing`] = false;
      }
    }
  };

  renderPopover(children: React.ReactNode) {
    if (!this.state.showing) {
      return;
    }

    const { width, offsetX, offsetY } = this.state;
    const { nibOffsetX } = this.props;

    return (
      <Popover
        offsetX={offsetX}
        offsetY={offsetY}
        nibOffsetX={nibOffsetX}
        width={width}
      >
        {children}
      </Popover>
    );
  }

  render() {
    const { className, position, style } = this.props;

    const [firstChild, ...children] = React.Children.toArray(
      this.props.children,
    );

    const wrapperStyle = Object.assign({ position }, style);

    return (
      <span
        data-testid="anchored-popover"
        ref={(wrapperNode) => (this.wrapperNode = wrapperNode)}
        className={className}
        style={wrapperStyle}
        onMouseOver={this.handleMouseOver}
        onMouseOut={this.handleMouseOut}
        onFocus={this.handleMouseOver}
        onBlur={this.handleMouseOut}
      >
        {firstChild}
        {this.renderPopover(children)}
      </span>
    );
  }
}

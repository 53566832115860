/**
 * @generated SignedSource<<303c426807c3df031600edfc751812d7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MembersChooserQuery$variables = {
  memberAddSearch?: string | null;
  team: string;
  teamSelector?: any | null;
};
export type MembersChooserQuery$data = {
  readonly team: {
    readonly " $fragmentSpreads": FragmentRefs<"MembersChooser_team">;
  } | null;
};
export type MembersChooserQuery = {
  response: MembersChooserQuery$data;
  variables: MembersChooserQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "memberAddSearch"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "team"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "teamSelector"
},
v3 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "team"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "MembersChooserQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Team",
        "kind": "LinkedField",
        "name": "team",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "memberAddSearch",
                "variableName": "memberAddSearch"
              },
              {
                "kind": "Variable",
                "name": "teamSelector",
                "variableName": "teamSelector"
              }
            ],
            "kind": "FragmentSpread",
            "name": "MembersChooser_team"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "MembersChooserQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Team",
        "kind": "LinkedField",
        "name": "team",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "slug",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 10
                  },
                  {
                    "kind": "Literal",
                    "name": "order",
                    "value": "RELEVANCE"
                  },
                  {
                    "kind": "Variable",
                    "name": "search",
                    "variableName": "memberAddSearch"
                  },
                  {
                    "kind": "Variable",
                    "name": "team",
                    "variableName": "teamSelector"
                  }
                ],
                "concreteType": "OrganizationMemberConnection",
                "kind": "LinkedField",
                "name": "members",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrganizationMemberEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrganizationMember",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "email",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Avatar",
                                "kind": "LinkedField",
                                "name": "avatar",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "url",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TeamPermissions",
            "kind": "LinkedField",
            "name": "permissions",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Permission",
                "kind": "LinkedField",
                "name": "teamMemberCreate",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "allowed",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "297b9660fdcc599417c18cac1a21d24b",
    "id": null,
    "metadata": {},
    "name": "MembersChooserQuery",
    "operationKind": "query",
    "text": "query MembersChooserQuery(\n  $team: ID!\n  $memberAddSearch: String\n  $teamSelector: TeamSelector\n) {\n  team(slug: $team) {\n    ...MembersChooser_team_3GK8va\n    id\n  }\n}\n\nfragment MembersChooser_team_3GK8va on Team {\n  id\n  slug\n  organization {\n    members(search: $memberAddSearch, first: 10, order: RELEVANCE, team: $teamSelector) {\n      edges {\n        node {\n          user {\n            id\n            ...User_user\n          }\n          id\n        }\n      }\n    }\n    id\n  }\n  permissions {\n    teamMemberCreate {\n      allowed\n    }\n  }\n}\n\nfragment User_user on User {\n  name\n  email\n  avatar {\n    url\n  }\n}\n"
  }
};
})();

(node as any).hash = "caac4e6808914382ffd51ec0e257942d";

export default node;

/**
 * @generated SignedSource<<0e861d82414da352b9031fa7f6c6fdfd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Navigation_organization$data = {
  readonly iconUrl: string | null;
  readonly id: string;
  readonly name: string;
  readonly permissions: {
    readonly agentView: {
      readonly allowed: boolean;
    } | null;
    readonly notificationServiceUpdate: {
      readonly allowed: boolean;
    } | null;
    readonly organizationBillingUpdate: {
      readonly allowed: boolean;
    } | null;
    readonly organizationInvitationCreate: {
      readonly allowed: boolean;
    } | null;
    readonly organizationMemberView: {
      readonly allowed: boolean;
      readonly code: string | null;
    } | null;
    readonly organizationUpdate: {
      readonly allowed: boolean;
    } | null;
    readonly pipelineView: {
      readonly allowed: boolean;
      readonly code: string | null;
    } | null;
    readonly suiteView: {
      readonly allowed: boolean;
    } | null;
    readonly teamView: {
      readonly allowed: boolean;
    } | null;
  };
  readonly public: boolean;
  readonly slug: string;
  readonly " $fragmentSpreads": FragmentRefs<"AgentsCount_organization">;
  readonly " $fragmentType": "Navigation_organization";
};
export type Navigation_organization$key = {
  readonly " $data"?: Navigation_organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"Navigation_organization">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "allowed",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "code",
    "storageKey": null
  }
],
v2 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Navigation_organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AgentsCount_organization"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "iconUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "public",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrganizationPermissions",
      "kind": "LinkedField",
      "name": "permissions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Permission",
          "kind": "LinkedField",
          "name": "pipelineView",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Permission",
          "kind": "LinkedField",
          "name": "suiteView",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Permission",
          "kind": "LinkedField",
          "name": "agentView",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Permission",
          "kind": "LinkedField",
          "name": "organizationMemberView",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Permission",
          "kind": "LinkedField",
          "name": "organizationUpdate",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Permission",
          "kind": "LinkedField",
          "name": "organizationInvitationCreate",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Permission",
          "kind": "LinkedField",
          "name": "notificationServiceUpdate",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Permission",
          "kind": "LinkedField",
          "name": "organizationBillingUpdate",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Permission",
          "kind": "LinkedField",
          "name": "teamView",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "a07f0c5971bfe2fc533ab9c2cd2aca89";

export default node;

import { FC, useState } from "react";
import styled, { css } from "styled-components";
import kebabCase from "lodash/kebabCase";

import Icon from "app/components/shared/Icon";

interface SectionProps {
  title: string;
  expandedByDefault?: boolean;
  showActiveIndicator?: boolean;
}

interface StyledSectionProps {
  expanded: boolean;
}

const SectionContainer = styled.div`
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  padding: 0 16px;
`;

const SectionButton = styled.button`
  all: unset;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-weight: 600;
  width: 100%;
  height: 50px;
  padding-right: 5px;
`;

const SectionContent = styled.div<StyledSectionProps>`
  max-height: 0;
  visibility: hidden;
  overflow: hidden;
  transition:
    visibility 0.3s,
    max-height 0.3s ease-in-out;

  ${(props) =>
    props.expanded &&
    css`
      visibility: visible;
      max-height: 500px;
    `}
`;

const Section: FC<SectionProps> = ({
  title,
  children,
  expandedByDefault = false,
  showActiveIndicator = false,
}) => {
  const [expanded, setExpanded] = useState(expandedByDefault);

  function toggleSection() {
    setExpanded(!expanded);
  }

  const kebabCasedTitle = kebabCase(title);

  return (
    <SectionContainer>
      <SectionButton
        className="hover-purple"
        onClick={toggleSection}
        aria-controls={`${kebabCasedTitle}-content`}
        aria-expanded={expanded}
      >
        {title}
        <div className="flex gap2 items-center">
          {showActiveIndicator ? (
            <div
              className="ml1 inline-block circle"
              data-testid={`${kebabCasedTitle}-section-indicator`}
              style={{
                width: 6,
                height: 6,
                bottom: 0,
                right: 0,
                borderWidth: 2,
                backgroundColor: "var(--blue-500)",
              }}
            />
          ) : null}
          <Icon
            icon={expanded ? "up-triangle" : "down-triangle"}
            className="flex-none pointer-events-none"
            style={{ width: 7, height: 7 }}
          />
        </div>
      </SectionButton>
      <SectionContent
        expanded={expanded}
        id={`${kebabCasedTitle}-content`}
        aria-hidden={!expanded}
      >
        {children}
      </SectionContent>
    </SectionContainer>
  );
};

export default Section;

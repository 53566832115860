import { QueryRenderer, graphql } from "react-relay";

import Environment from "app/lib/relay/environment";
import usePreloadedRelayQuery from "app/lib/usePreloadedRelayQuery";

import Navigation from ".";

type Props = {
  params: {
    includeOrganization: boolean;
    organizationSlug: string | null | undefined;
  };
  activeNav: string | null | undefined;
  staff: boolean;
  organizationShard?: string;
  suggestedDocumentation: string | Array<any>;
};

const parseDocumentation = (documentation: string | Array<any>) => {
  try {
    if (typeof documentation === "object") {
      return documentation;
    }

    return JSON.parse(documentation);
  } catch (error: any) {
    return [];
  }
};

export default function NavigationRenderer({
  params,
  suggestedDocumentation,
  ...navigationProps
}: Props) {
  const environment = Environment.get();
  const query = graphql`
    query NavigationRendererQuery(
      $includeOrganization: Boolean!
      $organizationSlug: ID
    ) {
      organization(slug: $organizationSlug) @include(if: $includeOrganization) {
        ...Navigation_organization
      }
      viewer {
        ...Navigation_viewer
      }
    }
  `;

  usePreloadedRelayQuery(
    true,
    {
      environment,
      query,
      variables: { ...params },
    },
    [],
  );

  function renderQuery({ props }: { props: any }) {
    if (!props) {
      return null;
    }

    return (
      <Navigation
        {...navigationProps}
        {...props}
        // Documentations are passed in as a stringified JSON object or empty array
        suggestedDocumentation={parseDocumentation(suggestedDocumentation)}
      />
    );
  }

  return (
    <QueryRenderer
      fetchPolicy="store-and-network"
      environment={environment}
      query={query}
      variables={params}
      render={renderQuery}
    />
  );
}

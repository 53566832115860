import RunResults from "app/constants/analytics/RunResults";
import ResultIcon from "app/components/icons/analytics/ResultIcon";

const SIZES = ["XSmall", "Small", "Regular"];

const iconMap = {
  [RunResults.FAILED]: "FAILED",
  [RunResults.PASSED]: "PASSED",
  [RunResults.PENDING]: "PENDING",
  [RunResults.STALE]: "UNDEFINED",
} as const;

const RunResult: Record<string, any> = {};

SIZES.forEach((size) => {
  const component = (props: { result: string }) => {
    const icon = iconMap[props.result] || "UNDEFINED";
    // @ts-expect-error - TS2322 - Type 'string' is not assignable to type '"XSmall" | "Small" | "Regular"'.
    return <ResultIcon icon={icon} size={size} result={props.result} />;
  };

  component.displayName = `RunResult.${size}`;

  RunResult[size] = component;
});

export default RunResult;

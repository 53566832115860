import Environment from "app/lib/relay/environment";
import { useState } from "react";
import {
  QueryRenderer,
  commitMutation,
  createRefetchContainer,
  graphql,
} from "react-relay";

import Panel from "app/components/shared/Panel";
import SectionLoader from "app/components/shared/SectionLoader";
import ShowMoreFooter from "app/components/shared/ShowMoreFooter";
import Chooser from "./RegistriesChooser";
import Row from "./row";

const PAGE_SIZE = 10;

const RegistriesPage = ({ team, relay, className }) => {
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);

  const reloadTable = (props) => {
    setLoading(true);
    relay.refetch(
      {
        teamID: team.id,
        pageSize,
        ...props,
      },
      null,
      () => {
        setLoading(false);
      },
      { force: true },
    );
  };

  const handleTeamRegistryChoose = () => {
    reloadTable();
  };

  const handleShowMoreRegistries = () => {
    setLoading(true);
    const newPageSize = pageSize + PAGE_SIZE;
    setPageSize(newPageSize);

    reloadTable({ pageSize: newPageSize });
  };

  const handleTeamRegistryRemove = (teamRegistry, force, callback) => {
    const environment = Environment.get();
    const input = { id: teamRegistry.id, force };

    commitMutation(environment, {
      mutation: graphql`
        mutation RegistriesTeamDeleteMutation(
          $input: TeamRegistryDeleteInput!
        ) {
          teamRegistryDelete(input: $input) {
            deletedTeamRegistryID
            team {
              id
              registries {
                count
              }
            }
          }
        }
      `,
      variables: { input },
      onCompleted: () => {
        callback(null);
        reloadTable();
      },
      onError: (error) => callback(error),
    });
  };

  const handleAccessLevelChange = (teamRegistry, accessLevel, callback) => {
    const environment = Environment.get();
    const input = { id: teamRegistry.id, accessLevel };

    commitMutation(environment, {
      mutation: graphql`
        mutation RegistriesTeamAccessLevelChangeMutation(
          $input: TeamRegistryUpdateInput!
        ) {
          teamRegistryUpdate(input: $input) {
            teamRegistry {
              accessLevel
            }
          }
        }
      `,
      variables: { input },
      onCompleted: () => {
        callback(null);
      },
      onError: (error) => callback(error),
    });
  };

  const renderRegistries = () => {
    if (team.registries.edges.length > 0) {
      return team.registries.edges.map(({ node }) => (
        <Row
          key={node.id}
          teamRegistry={node}
          onRemoveClick={handleTeamRegistryRemove}
          onAccessLevelChange={handleAccessLevelChange}
        />
      ));
    }

    return (
      <Panel.Section className="dark-gray">
        There are no registries assigned to this team
      </Panel.Section>
    );
  };

  return (
    <div>
      <Chooser
        team={team}
        onChoose={handleTeamRegistryChoose}
        disabled={loading}
      />

      <Panel className={className}>
        {renderRegistries()}
        <ShowMoreFooter
          connection={team.registries}
          label="registries"
          loading={loading}
          onShowMore={handleShowMoreRegistries}
        />
      </Panel>
    </div>
  );
};

const TeamsRegistriesPageContainer = createRefetchContainer(
  RegistriesPage,
  {
    team: graphql`
      fragment Registries_team on Team
      @argumentDefinitions(pageSize: { type: "Int", defaultValue: 10 }) {
        ...RegistriesChooser_team
        registries(first: $pageSize, order: NAME) {
          ...ShowMoreFooter_connection
          count
          edges {
            node {
              id
              accessLevel
              registry {
                id
                name
                slug
                url
              }
              permissions {
                teamRegistryUpdate {
                  allowed
                }
                teamRegistryDelete {
                  allowed
                }
              }
            }
          }
        }
      }
    `,
  },
  graphql`
    query Registries_organizationQuery($team: ID!, $pageSize: Int!) {
      team(slug: $team) {
        ...Registries_team @arguments(pageSize: $pageSize)
      }
    }
  `,
);

const TEAMS_REGISTRIES_QUERY = graphql`
  query Registries_Query($team: ID!) {
    team(slug: $team) {
      ...Registries_team
    }
  }
`;

const RegistriesPageRenderer = ({ team }) => (
  <QueryRenderer
    fetchPolicy="store-and-network"
    environment={Environment.get()}
    query={TEAMS_REGISTRIES_QUERY}
    variables={{ team }}
    render={({ props, error }) => {
      if (error || !props) {
        return <SectionLoader />;
      }
      return <TeamsRegistriesPageContainer {...props} />;
    }}
  />
);

export default RegistriesPageRenderer;

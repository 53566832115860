import Chart from "app/components/analytics/shared/Chart/";
import { DateFilter } from "app/components/analytics/shared/DateFilters";
import { Metrics } from "app/components/analytics/shared/type";

type MiniChartMetrics = Pick<Metrics, "metric" | "chartType">;

type Props = MiniChartMetrics & {
  filter: DateFilter;
  branch: string | null | undefined;
  endpoint: string;
};

const chartOptions = (metric: Props["metric"]) => {
  const options = {
    data: {
      datasets: [
        {
          label: metric,
          borderRadius: 2,
        },
      ],
    },
    options: {
      scales: {
        xIndex: {
          grid: {
            display: false,
          },
          ticks: {
            display: false,
          },
        },
        yIndex: {
          beginAtZero: true,
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            display: false,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      layout: { padding: 1 },
      events: [], // disable all hover/click events for mini chart
    },
  };
  return options;
};

const MiniChart = ({ filter, branch, metric, endpoint, chartType }: Props) => (
  <Chart
    height={50}
    width={200}
    filter={filter}
    branch={branch}
    metric={metric}
    endpoint={endpoint}
    options={chartOptions(metric)}
    chartType={chartType}
  />
);

export default MiniChart;

import { GraphQLError } from "graphql/error";
const { QueryResponseCache } = require("relay-runtime");

// The cache is a simple in-memory cache
// copied from https://relay.dev/docs/v10.1.3/network-layer/
// This works across all modern relay versions until v10

const oneMinute = 60 * 1000;
const cache = new QueryResponseCache({ size: 250, ttl: oneMinute });

const makeFetch = (operation, variables, cacheConfig) => {
  const queryID = operation.text;
  const isMutation = operation.operationKind === "mutation";
  const isQuery = operation.operationKind === "query";
  const forceFetch = cacheConfig && cacheConfig.force;

  // Try to get data from cache on queries
  const fromCache = cache.get(queryID, variables);
  if (isQuery && fromCache !== null && !forceFetch) {
    return fromCache;
  }

  return fetch(window._graphql.url, {
    method: "POST",
    headers: {
      "X-Buildkite-Frontend-Version": BUILDKITE_FRONTEND_VERSION,
      ...window._graphql.headers,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    credentials: "same-origin",
    body: JSON.stringify({
      query: operation.text,
      variables,
    }),
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      // Update cache on queries
      if (isQuery && json) {
        cache.set(queryID, variables, json);
      }
      // Clear cache on mutations
      if (isMutation) {
        cache.clear();
      }

      if (json && json.errors && json.errors.length) {
        throw new GraphQLError("The query failed", null, json);
      }
      return json;
    });
};

export default makeFetch;

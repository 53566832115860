import * as React from "react";
import classNames from "classnames";

type Props = {
  className?: string;
  errors: Array<React.ReactNode> | null | undefined;
};

function FormInputErrors({ className, errors, ...props }: Props) {
  if (!errors || errors.length < 1) {
    return null;
  }

  return (
    <p className={classNames("FormField__Error", className)} {...props}>
      {errors.reduce<Array<any>>((acc, item, index) => {
        const separator = index > 0 ? [", "] : [];
        return acc.concat(separator).concat([item]);
      }, [])}
    </p>
  );
}

FormInputErrors.defaultProps = {
  errors: [],
};

export default FormInputErrors;

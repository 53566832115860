import cn from "classnames";
import Emojify from "app/components/shared/Emojify";
import { twMerge } from "tailwind-merge";

const emojiPattern = /^(:\w+:\s?)+/;

// Parse a step label into the _leading_ emoji and the remaining text (which may contain more emojis).
export function parseLabel(label: string): {
  emojis: string | null;
  text: string;
} {
  const match = label.match(emojiPattern);
  const emojis = match ? match[0] : null;
  const text = emojis ? label.replace(emojis, "").trimStart() : label;

  return { emojis, text };
}

export default function Label({
  label,
  className = undefined,
}: {
  label: string;
  className?: string;
}) {
  const { text, emojis } = parseLabel(label);

  return (
    <header
      role="heading"
      className={twMerge("flex items-center gap-2", className)}
    >
      {emojis && (
        <Emojify
          text={emojis}
          title=""
          className="flex shrink-0 items-center text-xl"
        />
      )}
      {text && (
        <Emojify
          text={text}
          title=""
          className={cn(
            "whitespace-nowrap overflow-hidden overflow-ellipsis block",
            ["font-medium", "text-base", "text-slate-900"],
          )}
        />
      )}
    </header>
  );
}

/**
 * @generated SignedSource<<521f2244c0c8f909b98e46dd6c07f38e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JobEventAgentStopped_event$data = {
  readonly timestamp: any;
  readonly " $fragmentType": "JobEventAgentStopped_event";
};
export type JobEventAgentStopped_event$key = {
  readonly " $data"?: JobEventAgentStopped_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"JobEventAgentStopped_event">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "JobEventAgentStopped_event",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timestamp",
      "storageKey": null
    }
  ],
  "type": "JobEvent",
  "abstractKey": "__isJobEvent"
};

(node as any).hash = "f1694c8bb4dff266146ba7e6c75af9c0";

export default node;

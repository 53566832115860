/**
 * @generated SignedSource<<b2b7191204bbdfc031579209b46cf0ce>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type JobEventActorType = "AGENT" | "DISPATCH" | "SYSTEM" | "USER" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type JobEventRetryFailed_event$data = {
  readonly actor?: {
    readonly node: {
      readonly name?: string;
      readonly uuid?: string;
      readonly " $fragmentSpreads": FragmentRefs<"UserAvatar_user">;
    } | null;
    readonly type: JobEventActorType;
  };
  readonly retryFailedReason?: string;
  readonly timestamp?: any;
  readonly " $fragmentType": "JobEventRetryFailed_event";
};
export type JobEventRetryFailed_event$key = {
  readonly " $data"?: JobEventRetryFailed_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"JobEventRetryFailed_event">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "JobEventRetryFailed_event",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "timestamp",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "JobEventActor",
          "kind": "LinkedField",
          "name": "actor",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "uuid",
                      "storageKey": null
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "UserAvatar_user"
                    }
                  ],
                  "type": "User",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "retryFailedReason",
              "storageKey": null
            }
          ],
          "type": "JobEventRetryFailed",
          "abstractKey": null
        }
      ],
      "type": "JobEvent",
      "abstractKey": "__isJobEvent"
    }
  ],
  "type": "JobEventRetryFailed",
  "abstractKey": null
};

(node as any).hash = "1b7f6fd9d934ebda85cf68459fd4d81c";

export default node;

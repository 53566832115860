import { RelayProp, createFragmentContainer, graphql } from "react-relay";

import Emojify from "./Emojify";

type Props = {
  job: any;
  relay: RelayProp;
};

const JobTitle = ({ job, relay: _relay, ...props }: Props) => (
  <span {...props}>
    <Emojify text={job.label || job.command} />
    <span className="dark-gray"> in </span>
    <Emojify
      text={
        job.build ? job.build.pipeline.name : "A pipeline owned by another team"
      }
    />
    {` `}
    <span className="monospace dark-gray">
      #{job.build ? job.build.number : "???"}
    </span>
  </span>
);

export default createFragmentContainer(JobTitle, {
  job: graphql`
    fragment JobTitle_job on JobTypeCommand {
      label
      command
      build {
        number
        pipeline {
          name
        }
      }
    }
  `,
});

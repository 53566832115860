import { useState, useCallback } from "react";
import copy from "copy-to-clipboard";

function useCopyToClipboard() {
  const [copied, setCopied] = useState(false);

  const handleCopy = useCallback((textToCopy: string) => {
    copy(textToCopy);
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 1500);
  }, []);

  return { copied, handleCopy };
}

export default useCopyToClipboard;

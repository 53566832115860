/**
 * @generated SignedSource<<2971ea720924bf0efc9895d7e1c91450>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type RegistryAccessLevels = "READ_AND_WRITE" | "READ_ONLY" | "READ_WRITE_AND_ADMIN" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type Registries_team$data = {
  readonly registries: {
    readonly count: number;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly accessLevel: RegistryAccessLevels;
        readonly id: string;
        readonly permissions: {
          readonly teamRegistryDelete: {
            readonly allowed: boolean;
          } | null;
          readonly teamRegistryUpdate: {
            readonly allowed: boolean;
          } | null;
        };
        readonly registry: {
          readonly id: string;
          readonly name: string;
          readonly slug: string;
          readonly url: string;
        } | null;
      } | null;
    } | null> | null;
    readonly " $fragmentSpreads": FragmentRefs<"ShowMoreFooter_connection">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"RegistriesChooser_team">;
  readonly " $fragmentType": "Registries_team";
};
export type Registries_team$key = {
  readonly " $data"?: Registries_team$data;
  readonly " $fragmentSpreads": FragmentRefs<"Registries_team">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "allowed",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": 10,
      "kind": "LocalArgument",
      "name": "pageSize"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "Registries_team",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RegistriesChooser_team"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "pageSize"
        },
        {
          "kind": "Literal",
          "name": "order",
          "value": "NAME"
        }
      ],
      "concreteType": "TeamRegistryConnection",
      "kind": "LinkedField",
      "name": "registries",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ShowMoreFooter_connection"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "count",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TeamRegistryEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TeamRegistry",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "accessLevel",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Registry",
                  "kind": "LinkedField",
                  "name": "registry",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "slug",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "url",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "TeamRegistryPermissions",
                  "kind": "LinkedField",
                  "name": "permissions",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Permission",
                      "kind": "LinkedField",
                      "name": "teamRegistryUpdate",
                      "plural": false,
                      "selections": (v1/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Permission",
                      "kind": "LinkedField",
                      "name": "teamRegistryDelete",
                      "plural": false,
                      "selections": (v1/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Team",
  "abstractKey": null
};
})();

(node as any).hash = "7f93b7a3371ead90bdb7130aaf8a843b";

export default node;

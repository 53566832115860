import * as ActionCable from "app/vendor/actioncable";

const cable = ActionCable.createConsumer();

if (process.env.NODE_ENV === "development") {
  ActionCable.logger.enabled = true;
}

// TODO: add a react context which can propagate the consumer instead of using
// this global module, once we know every usage of the consumer is within a
// react tree capable of using a context. Ideally switch to use-action-cable
// hooks eventually.

export default cable;

import labelled from "./labelledFormComponent";

const textFieldDefaultProps = { type: "text" };

const FormTextField = labelled("input");
FormTextField.defaultProps = {
  ...FormTextField.defaultProps,
  ...textFieldDefaultProps,
};

export default FormTextField;

/**
 * @generated SignedSource<<8b33ee77269f2682a1549cc4c86ae0c3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type TeamMemberRole = "MAINTAINER" | "MEMBER" | "%future added value";
export type TeamMemberCreateInput = {
  clientMutationId?: string | null;
  role?: TeamMemberRole | null;
  teamID: string;
  userID: string;
};
export type MembersChooserMutation$variables = {
  input: TeamMemberCreateInput;
};
export type MembersChooserMutation$data = {
  readonly teamMemberCreate: {
    readonly clientMutationId: string | null;
    readonly team: {
      readonly id: string;
      readonly members: {
        readonly count: number;
      } | null;
    } | null;
  } | null;
};
export type MembersChooserMutation = {
  response: MembersChooserMutation$data;
  variables: MembersChooserMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "TeamMemberCreatePayload",
    "kind": "LinkedField",
    "name": "teamMemberCreate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Team",
        "kind": "LinkedField",
        "name": "team",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TeamMemberConnection",
            "kind": "LinkedField",
            "name": "members",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "count",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MembersChooserMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MembersChooserMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "813aada5b49713bd97986bf00a42a330",
    "id": null,
    "metadata": {},
    "name": "MembersChooserMutation",
    "operationKind": "mutation",
    "text": "mutation MembersChooserMutation(\n  $input: TeamMemberCreateInput!\n) {\n  teamMemberCreate(input: $input) {\n    clientMutationId\n    team {\n      id\n      members {\n        count\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "de1bfd576cc679c4e4c855ee5c455882";

export default node;

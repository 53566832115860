import { browserHistory } from "react-router";
import { QueryRenderer, graphql } from "react-relay";

import Environment from "app/lib/relay/environment";
import usePreloadedRelayQuery from "app/lib/usePreloadedRelayQuery";

import SectionLoader from "app/components/shared/SectionLoader";

import Agents from "./Agents";

type Props = {
  clusterQueueId?: string;
  hostedQueue?: boolean;
  organization: {
    slug: string;
  };
  unclusteredOnly?: boolean;
};

export default function AgentsRenderer({
  organization,
  ...agentsProps
}: Props) {
  const environment = Environment.get();
  const query = graphql`
    query AgentsRendererQuery($organizationSlug: ID!) {
      organization(slug: $organizationSlug) {
        ...Agents_organization
      }
    }
  `;

  usePreloadedRelayQuery(
    true,
    {
      environment,
      query,
      variables: { organizationSlug: organization.slug },
    },
    [organization],
  );

  function renderQuery({ props }: { props: Props }) {
    if (!props) {
      return <SectionLoader />;
    }

    return (
      <Agents
        location={browserHistory.getCurrentLocation()}
        router={browserHistory}
        {...agentsProps}
        {...props}
      />
    );
  }

  return (
    <QueryRenderer
      fetchPolicy="store-and-network"
      cacheConfig={{ force: true }}
      environment={environment}
      query={query}
      variables={{ organizationSlug: organization.slug }}
      render={renderQuery}
    />
  );
}

/**
 * @generated SignedSource<<1965831f78ee0fb227379c2147965340>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PipelineAccessLevels = "BUILD_AND_READ" | "MANAGE_BUILD_AND_READ" | "READ_ONLY" | "%future added value";
export type TeamPipelineCreateInput = {
  accessLevel?: PipelineAccessLevels | null;
  clientMutationId?: string | null;
  pipelineID: string;
  teamID: string;
};
export type TeamChooserTeamPipelineCreateMutation$variables = {
  input: TeamPipelineCreateInput;
};
export type TeamChooserTeamPipelineCreateMutation$data = {
  readonly teamPipelineCreate: {
    readonly teamPipelineEdge: {
      readonly node: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"TeamRow_teamPipeline">;
      } | null;
    } | null;
  } | null;
};
export type TeamChooserTeamPipelineCreateMutation = {
  response: TeamChooserTeamPipelineCreateMutation$data;
  variables: TeamChooserTeamPipelineCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "count",
    "storageKey": null
  }
],
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "allowed",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TeamChooserTeamPipelineCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "TeamPipelineCreatePayload",
        "kind": "LinkedField",
        "name": "teamPipelineCreate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TeamPipelineEdge",
            "kind": "LinkedField",
            "name": "teamPipelineEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TeamPipeline",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "TeamRow_teamPipeline"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TeamChooserTeamPipelineCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "TeamPipelineCreatePayload",
        "kind": "LinkedField",
        "name": "teamPipelineCreate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TeamPipelineEdge",
            "kind": "LinkedField",
            "name": "teamPipelineEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TeamPipeline",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "accessLevel",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Pipeline",
                    "kind": "LinkedField",
                    "name": "pipeline",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Team",
                    "kind": "LinkedField",
                    "name": "team",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "description",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "slug",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TeamMemberConnection",
                        "kind": "LinkedField",
                        "name": "members",
                        "plural": false,
                        "selections": (v3/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TeamPipelineConnection",
                        "kind": "LinkedField",
                        "name": "pipelines",
                        "plural": false,
                        "selections": (v3/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TeamPipelinePermissions",
                    "kind": "LinkedField",
                    "name": "permissions",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Permission",
                        "kind": "LinkedField",
                        "name": "teamPipelineUpdate",
                        "plural": false,
                        "selections": (v4/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Permission",
                        "kind": "LinkedField",
                        "name": "teamPipelineDelete",
                        "plural": false,
                        "selections": (v4/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "88b48fd69ba809e5ae0ad36badf23cbf",
    "id": null,
    "metadata": {},
    "name": "TeamChooserTeamPipelineCreateMutation",
    "operationKind": "mutation",
    "text": "mutation TeamChooserTeamPipelineCreateMutation(\n  $input: TeamPipelineCreateInput!\n) {\n  teamPipelineCreate(input: $input) {\n    teamPipelineEdge {\n      node {\n        id\n        ...TeamRow_teamPipeline\n      }\n    }\n  }\n}\n\nfragment TeamRow_teamPipeline on TeamPipeline {\n  id\n  accessLevel\n  pipeline {\n    id\n  }\n  team {\n    id\n    name\n    description\n    slug\n    members {\n      count\n    }\n    pipelines {\n      count\n    }\n  }\n  permissions {\n    teamPipelineUpdate {\n      allowed\n    }\n    teamPipelineDelete {\n      allowed\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ae84974bd4eab94c23286bce8173dc57";

export default node;

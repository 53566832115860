/**
 * @generated SignedSource<<8393e6e1ceb312c49503a9cc0c996d39>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TeamChooser_Query$variables = {
  includeSearchResults?: boolean | null;
  pipelineSelector?: any | null;
  pipelineSlug?: string | null;
  teamAddSearch?: string | null;
};
export type TeamChooser_Query$data = {
  readonly pipeline: {
    readonly " $fragmentSpreads": FragmentRefs<"TeamChooser_pipeline">;
  } | null;
};
export type TeamChooser_Query = {
  response: TeamChooser_Query$data;
  variables: TeamChooser_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": true,
  "kind": "LocalArgument",
  "name": "includeSearchResults"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pipelineSelector"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pipelineSlug"
},
v3 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "teamAddSearch"
},
v4 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "pipelineSlug"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TeamChooser_Query",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Pipeline",
        "kind": "LinkedField",
        "name": "pipeline",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "includeSearchResults",
                "variableName": "includeSearchResults"
              },
              {
                "kind": "Variable",
                "name": "pipelineSelector",
                "variableName": "pipelineSelector"
              },
              {
                "kind": "Variable",
                "name": "teamAddSearch",
                "variableName": "teamAddSearch"
              }
            ],
            "kind": "FragmentSpread",
            "name": "TeamChooser_pipeline"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "TeamChooser_Query",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Pipeline",
        "kind": "LinkedField",
        "name": "pipeline",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "archived",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "condition": "includeSearchResults",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "first",
                        "value": 10
                      },
                      {
                        "kind": "Literal",
                        "name": "order",
                        "value": "RELEVANCE"
                      },
                      {
                        "kind": "Variable",
                        "name": "pipeline",
                        "variableName": "pipelineSelector"
                      },
                      {
                        "kind": "Variable",
                        "name": "search",
                        "variableName": "teamAddSearch"
                      }
                    ],
                    "concreteType": "TeamConnection",
                    "kind": "LinkedField",
                    "name": "teams",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TeamEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Team",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "TeamPermissions",
                                "kind": "LinkedField",
                                "name": "permissions",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Permission",
                                    "kind": "LinkedField",
                                    "name": "teamPipelineCreate",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "allowed",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "description",
                                "storageKey": null
                              },
                              (v6/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "privacy",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ]
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5b4675484ae3a32c0fecdb8218b8b1fb",
    "id": null,
    "metadata": {},
    "name": "TeamChooser_Query",
    "operationKind": "query",
    "text": "query TeamChooser_Query(\n  $pipelineSlug: ID\n  $pipelineSelector: PipelineSelector\n  $teamAddSearch: String = \"\"\n  $includeSearchResults: Boolean = true\n) {\n  pipeline(slug: $pipelineSlug) {\n    ...TeamChooser_pipeline_3SLauY\n    id\n  }\n}\n\nfragment Labels_team on Team {\n  privacy\n}\n\nfragment TeamChooser_pipeline_3SLauY on Pipeline {\n  id\n  slug\n  archived\n  organization {\n    slug\n    teams(search: $teamAddSearch, first: 10, order: RELEVANCE, pipeline: $pipelineSelector) @include(if: $includeSearchResults) {\n      edges {\n        node {\n          id\n          permissions {\n            teamPipelineCreate {\n              allowed\n            }\n          }\n          ...TeamSuggestion_team\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment TeamSuggestion_team on Team {\n  name\n  description\n  slug\n  ...Labels_team\n}\n"
  }
};
})();

(node as any).hash = "34f4221aeaa3a1257e1141b9147f1d29";

export default node;

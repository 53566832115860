import * as React from "react";
import classNames from "classnames";
import algoliasearch from "algoliasearch/lite";
import {
  InstantSearch,
  SearchBox,
  connectHits,
  connectStateResults,
  SearchState,
  SearchResults,
  TDoc,
  THit,
} from "react-instantsearch-dom";
import styled from "styled-components";

import Button from "app/components/shared/Button";
import Icon from "app/components/shared/Icon";
import Dropdown from "app/components/shared/Dropdown";
import DropdownButton from "./dropdown-button";
import { HighlightableNavigationButton } from ".";

// @ts-expect-error - TS2307 - Cannot find module 'app/images/objects/community-chat.jpg' or its corresponding type declarations.
import communityChatImage from "app/images/objects/community-chat.jpg";

import "instantsearch.css/themes/reset.css";
import "./help-dropdown.css";

const SmallHeader = styled.h4`
  font-size: 10px;
  color: #6c6c6c;
  text-transform: uppercase;
  margin: 10px 0;
`;

const DividingLine = styled.div`
  height: 1px
  width: calc(100%+30px);
  background-color: #ddd;
  margin: 15px -15px 15px -15px;
`;

type SuggestedDoc = {
  title: string;
  url: string;
};

type Props = {
  suggestedDocumentation: Array<SuggestedDoc>;
};

type State = {
  isDropdownVisible: boolean;
};

const searchClient = algoliasearch(
  "1IDL1HEBJ6",
  "3d6f41b2f8d0107f8a122b78f10a3aef",
);

type SearchProps = {
  searchState: SearchState;
  searchResults?: SearchResults<TDoc>;
  children: any;
  suggestedDocumentation: Array<SuggestedDoc>;
};

const StateResults = (props: SearchProps) => {
  const hasQuery = props.searchState && props.searchState.query;
  const hasResults = props.searchResults && props.searchResults.nbHits !== 0;

  if (hasQuery && hasResults) {
    return <div>{props.children}</div>;
  } else if (hasQuery && !hasResults) {
    return (
      <div className="center my3 black">
        No results found for {props.searchState.query}
      </div>
    );
  }

  if (props.suggestedDocumentation.length < 1) {
    return null;
  }

  return (
    <div className="py1">
      <SmallHeader>Suggested Documentation</SmallHeader>
      {props.suggestedDocumentation.map(({ title, url }) => (
        <a
          key={url}
          className="black bold line-height-4 text-decoration-none flex flex-none mt1 docs-suggestion-link docs-link hover-lime"
          style={{ fontSize: "14px" }}
          href={url}
        >
          <Icon icon="document" className="flex-none" />
          <span className="flex-none ml1" style={{ lineHeight: "24px" }}>
            {title}
          </span>
        </a>
      ))}
    </div>
  );
};

const CustomResults = connectStateResults(StateResults);

type HitProps = {
  hits: Array<THit>;
};

const Hits = (props: HitProps) => (
  <div style={{ margin: "10px 0" }}>
    {props.hits.map((hit, index) => {
      // const category = hit.hierarchy.lvl0;
      const pageTitle = hit.hierarchy.lvl1;
      const pageSection = hit.hierarchy.lvl2;
      if (index < 10) {
        return (
          <a
            key={hit.objectID}
            className="block black hover-dark-gray text-decoration-none docs-search-link docs-link"
            style={{ fontSize: "14px", fontWeight: "600", padding: "5px 0" }}
            href={hit.url}
          >
            <span dangerouslySetInnerHTML={{ __html: pageTitle }} />
            {pageSection ? (
              <span
                style={{
                  fontSize: "10px",
                  opacity: "0.6",
                  textTransform: "uppercase",
                }}
                dangerouslySetInnerHTML={{ __html: pageSection }}
              />
            ) : null}
          </a>
        );
      }
    })}
  </div>
);

const CustomHits = connectHits(Hits);

class HelpDropdown extends React.PureComponent<Props, State> {
  static displayName = "Navigation.HelpDropdown";

  state: State = {
    isDropdownVisible: false,
  };

  handleDropdownToggle = (visible: boolean) => {
    this.setState({ isDropdownVisible: visible });

    if (visible) {
      window.addEventListener("keydown", this.checkKey);

      // Setting focus manually, rather than using autofocus prop on the element, because autofocus breaks the presenting transition
      const searchBox = document.getElementsByClassName(
        "ais-SearchBox-input",
      )[0];
      // @ts-expect-error - TS2339 - Property 'focus' does not exist on type 'Element'.
      searchBox.focus();
    }

    if (!visible) {
      window.removeEventListener("keydown", this.checkKey);
    }
  };

  render() {
    return (
      <HighlightableNavigationButton
        as={Dropdown}
        width={320}
        className="flex flex-none"
        active={this.state.isDropdownVisible}
        onToggle={this.handleDropdownToggle}
      >
        <DropdownButton
          className={classNames("flex-none py0", {
            purple: this.state.isDropdownVisible,
          })}
        >
          {"Help "}
          <Icon
            icon="down-triangle"
            className="flex-none"
            style={{
              width: 7,
              height: 7,
              marginLeft: ".5em",
            }}
          />
        </DropdownButton>

        {this.renderContent()}
      </HighlightableNavigationButton>
    );
  }

  renderContent() {
    return (
      <div className="px3 py2">
        <InstantSearch indexName="prod_docs" searchClient={searchClient}>
          <SearchBox
            submit={null}
            reset={null}
            translations={{ placeholder: "Search Documentation" }}
          />
          <CustomResults
            suggestedDocumentation={this.props.suggestedDocumentation}
          >
            <CustomHits />
          </CustomResults>
        </InstantSearch>

        <Button
          className="block h4 bold center mt2"
          href="https://buildkite.com/docs"
        >
          Browse the Documentation
        </Button>

        <DividingLine />

        <img
          src={communityChatImage}
          alt="Support image"
          className="flex mx-auto"
          style={{ maxWidth: "175px" }}
        />

        <div className="mx-auto mt1 semi-bold line-height-4">
          Have a problem, or can’t find what you need?
          <br />
          {/*
           */}
          Send us an email and we’ll help you out.
        </div>

        <div className="mt2">
          <Button
            className="block h4 bold center"
            href="mailto:support@buildkite.com"
          >
            Email support@buildkite.com
          </Button>
        </div>
      </div>
    );
  }

  checkKey(event: KeyboardEvent) {
    event = event || window.event;
    const activeElement = document.activeElement;

    if (
      activeElement &&
      activeElement.tagName === "INPUT" &&
      (event.key === "ArrowDown" || event.key === "Enter")
    ) {
      event.preventDefault();
      const firstLink = document.getElementsByClassName("docs-link")[0];
      if (firstLink) {
        // @ts-expect-error - TS2339 - Property 'focus' does not exist on type 'Element'.
        firstLink.focus();
      }
    }

    if (
      activeElement &&
      activeElement.classList.contains("docs-link") &&
      event.key === "ArrowDown"
    ) {
      event.preventDefault();
      const nextLink = activeElement.nextElementSibling;
      if (nextLink && nextLink.classList.contains("docs-link")) {
        // get around Flow's typing, which asserts that nextElementSibling returns an object of type Element, not HTMLElement
        const htmlLink = nextLink as HTMLElement;
        htmlLink.focus();
      }
    }

    if (
      activeElement &&
      activeElement.classList.contains("docs-link") &&
      event.key === "ArrowUp"
    ) {
      event.preventDefault();
      const prevLink = activeElement.previousElementSibling;
      if (prevLink && prevLink.classList.contains("docs-link")) {
        // get around Flow's typing, which asserts that previousElementSibling returns an object of type Element, not HTMLElement
        const htmlLink = prevLink as HTMLElement;
        htmlLink.focus();
      } else {
        // If you press up and there is no link above, give focus to the searchbox
        const searchBox = document.getElementsByClassName(
          "ais-SearchBox-input",
        )[0];
        // @ts-expect-error - TS2339 - Property 'focus' does not exist on type 'Element'.
        searchBox.focus();
      }
    }
  }
}

export default HelpDropdown;

import map from "lodash/map";
import { second, seconds } from "metrick/duration";
import React from "react";
import { createRefetchContainer, graphql } from "react-relay";
import throttle from "lodash/throttle";

import cable from "app/lib/cable";
import { formatNumber } from "app/lib/number";

import Dropdown from "app/components/shared/Dropdown";
import Panel from "app/components/shared/Panel";
import SearchField from "app/components/shared/SearchField";
import ShowMoreFooter from "app/components/shared/ShowMoreFooter";
import Spinner from "app/components/shared/Spinner";

import AgentRow from "./AgentRow";

const PAGE_SIZE = 100;
const FILTERS = {
  NONE: "none",
  BUSY: "isRunningJob",
} as const;
const FILTER_LABELS = {
  [FILTERS.NONE]: "All Agents",
  [FILTERS.BUSY]: "Busy Agents",
} as const;

type Props = {
  unclusteredOnly: boolean;
  clusterQueueId?: string;
  hostedQueue?: boolean;
  organization: {
    id: string;
    uuid: string;
    allAgents:
      | {
          count: number;
        }
      | null
      | undefined;
    agents:
      | {
          count: number;
          edges: Array<any>;
        }
      | null
      | undefined;
  };
  location: {
    query: {
      [key: string]: string;
    };
    pathname: string;
  };
  router: {
    replace: any;
  };
  relay: any;
};

type State = {
  loading: boolean;
  searchingRemotely: boolean;
  searchingRemotelyIsSlow: boolean;
  localSearchQuery: Array<string> | null | undefined;
  remoteSearchQuery: string | null | undefined;
  defaultQuery: string | null | undefined;
  selectedFilter: (typeof FILTERS)[keyof typeof FILTERS];
  pageSize: number;
};

class Agents extends React.PureComponent<Props, State> {
  agentFilterDropdown: Dropdown | null | undefined;
  // @ts-expect-error - TS2564 - Property 'agentListRefreshTimeout' has no initializer and is not definitely assigned in the constructor.
  agentListRefreshTimeout: number;
  // @ts-expect-error - TS2564 - Property 'remoteSearchIsSlowTimeout' has no initializer and is not definitely assigned in the constructor.
  remoteSearchIsSlowTimeout: number;
  subscription: any;

  static defaultProps = {
    clusterQueueId: null,
  };

  // For some reason we need to explicitly type this so the assignment of
  // defaultQuery in the constructor doesn't give us a Flow error. Weird.
  state: State = {
    loading: false,
    searchingRemotely: false,
    searchingRemotelyIsSlow: false,
    localSearchQuery: null,
    remoteSearchQuery: null,
    defaultQuery: null,
    selectedFilter: FILTERS.NONE,
    pageSize: PAGE_SIZE,
  };

  refetchVariables() {
    return {
      isMounted: true,
      organizationID: this.props.organization.id,
      clusterQueue: this.props.clusterQueueId,
      clustered: !this.props.unclusteredOnly,
      search: this.state.remoteSearchQuery,
      pageSize: this.state.pageSize,
      // Note this defaults to `null`
      isRunningJob: this.state.selectedFilter === FILTERS.BUSY || null,
    };
  }

  constructor(initialProps: any) {
    super(initialProps);

    this.state.defaultQuery = this.props.location.query.q || null;
    this.state.remoteSearchQuery = this.state.defaultQuery;
  }

  componentDidMount() {
    // We've marked the data requirements for this component with `@include(if:
    // $isMounted)` which allows us to defer the actual loading of the data
    // until we're ready. This keeps the page speedy to load and we can load
    // the Agents in after this component has mounted.
    //
    // We use `refetch` with `force` because we want to re-grab the entire
    // agent list when we come to/from the Agents page via
    // back/forward button in the browser.
    this.props.relay.refetch(
      this.refetchVariables(),
      null,
      () => {
        // Reload the component on reconnect, or if the agent count changes
        this.subscription = cable.subscriptions.create(
          {
            channel: "Pipelines::OrganizationChannel",
            uuid: this.props.organization.uuid,
          },
          {
            component: this,

            received({ event, agents }) {
              if (event === "agents:changed") {
                if (agents !== undefined && agents.count !== undefined) {
                  this.component.setState({ agentCount: agents.count });
                } else {
                  this.component.reload();
                }
              }
            },
          },
        );

        this.startTimeout();
      },
      { force: true },
    );
  }

  componentWillUnmount() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    clearTimeout(this.agentListRefreshTimeout);
  }

  // We refresh the data under 2 circumstances. The refresh happens on what
  // ever happens first.
  //
  // 1. We receive an `organization_stats:change` push event
  // 2. 10 seconds have passed
  startTimeout = () => {
    this.agentListRefreshTimeout = setTimeout(this.reload, seconds.bind(10));
  };

  // Throttle the `reload` function so we don't ever reload the entire list
  // more than once every 3 seconds
  reload = throttle(() => {
    this.props.relay.refetch(
      this.refetchVariables(),
      null,
      () => {
        // Start a timeout that will cause the data to be refreshed again
        // in a few seconds time
        this.startTimeout();
      },
      { force: true },
    );
  }, 3000);

  render() {
    // grab (potentially) filtered agent list here, as we need it in several places
    const agents = this.getRelevantAgents();

    return (
      <Panel>
        {this.renderSearchAndFilter()}
        {this.renderSearchAndFilterInfo(agents)}
        {this.renderAgentList(agents)}

        <ShowMoreFooter
          connection={this.props.organization.agents}
          label="agents"
          loading={this.state.loading}
          searching={!!this.state.localSearchQuery}
          onShowMore={this.handleShowMoreAgents}
        />
      </Panel>
    );
  }

  renderSearchAndFilter = () => {
    return (
      <Panel.Row>
        <div className="flex">
          <SearchField
            id="agents-filter"
            className="flex-auto"
            onChange={this.handleSearch}
            searching={this.state.searchingRemotelyIsSlow}
            defaultValue={this.state.defaultQuery}
          />
          <Dropdown
            width={250}
            className="flex items-center ml3"
            ref={(agentFilterDropdown) =>
              (this.agentFilterDropdown = agentFilterDropdown)
            }
          >
            <div
              className="underline-dotted cursor-pointer inline-block regular dark-gray truncate"
              style={{ maxWidth: 200 }}
              data-testid="agents-filter-options"
            >
              {FILTER_LABELS[this.state.selectedFilter]}
            </div>

            {map(FILTERS, (filter) => (
              <button
                key={filter}
                className="btn btn-block left-align hover-bg-silver"
                onClick={this.agentFilterHandler(filter)}
              >
                {FILTER_LABELS[filter]}
              </button>
            ))}
          </Dropdown>

          <label className="hide" htmlFor="agents-filter">
            Search agents
          </label>
        </div>
      </Panel.Row>
    );
  };

  getRelevantAgents() {
    const {
      organization: { agents: organizationAgents },
    } = this.props;

    if (!organizationAgents) {
      return null;
    }

    const { edges: agents } = organizationAgents;
    const { localSearchQuery } = this.state;

    if (!localSearchQuery) {
      return agents;
    }

    // Parse the search. We only want to do this once, outside of the agent
    // loop, in case they have lots of agents
    const queries = localSearchQuery.map((string) => {
      const [key, value] = string.split("=");

      return {
        string: string.toLowerCase(),
        metaDataKey: key,
        metaDataValue: value, // may be undefined
      };
    });

    const matchesName = (
      agent: any,
      query: {
        metaDataKey: string;
        metaDataValue: string;
        string: string;
      },
    ) => {
      const lowercaseAgentName = agent.name.toLowerCase();
      const searchName = query.string.toLowerCase();

      return lowercaseAgentName.indexOf(searchName) !== -1;
    };

    const matchesMetaData = (
      agent: any,
      query: {
        metaDataKey: string;
        metaDataValue: string;
        string: string;
      },
    ) => {
      return agent.metaData.some((metaDataKeyValue) => {
        const [key, value] = metaDataKeyValue.split("=");

        // Simple string match
        //
        // 'mo' matches 'moo=true'
        // 'tru' matches 'moo=true'
        // 'bark' does not match 'moo=true'
        if (
          !query.metaDataValue &&
          (key.indexOf(query.metaDataKey) !== -1 ||
            value.toLowerCase().indexOf(query.metaDataKey.toLowerCase()) !== -1)
        ) {
          return true;
        }

        // Wildcard matching
        //
        // 'moo=*' matches 'moo=true'
        // 'moo=*' doesn't match 'bark=true'
        if (query.metaDataKey === key && query.metaDataValue === "*") {
          return true;
        }

        // Key=Value matching
        //
        // 'moo=tr' matches 'moo=true'
        // 'moo=true' matches 'moo=true'
        // 'moo=rue' doesn't match 'moo=true'
        // 'moo=false' doesn't match 'moo=true'
        if (
          query.metaDataKey === key &&
          value.toLowerCase().indexOf(query.metaDataValue.toLowerCase()) === 0
        ) {
          return true;
        }
      });
    };

    const filtered_agents = agents.filter(({ node: agent }) => {
      return queries.every((query) => {
        return matchesName(agent, query) || matchesMetaData(agent, query);
      });
    });

    return filtered_agents;
  }

  renderSearchAndFilterInfo(relevantAgents: null | Array<any>) {
    const {
      organization: { agents },
    } = this.props;
    const { localSearchQuery, remoteSearchQuery, selectedFilter } = this.state;

    if (
      (localSearchQuery && relevantAgents) ||
      (remoteSearchQuery && agents) ||
      (selectedFilter !== FILTERS.NONE && agents)
    ) {
      return (
        <div className="bg-silver semi-bold py2 px3">
          <small className="dark-gray">
            {formatNumber(
              localSearchQuery
                ? relevantAgents
                  ? relevantAgents.length
                  : 0
                : agents
                  ? agents.count
                  : 0,
            )}{" "}
            matching agents
          </small>
        </div>
      );
    }
  }

  renderAgentEmptyState() {
    const { hostedQueue, location } = this.props;

    if (hostedQueue) {
      return (
        <Panel.Section className="dark-gray">
          Agents will be connected when the job is running.
        </Panel.Section>
      );
    }

    return (
      <Panel.Section className="dark-gray">
        No agents connected, check{" "}
        <a
          className="purple text-decoration-none hover-underline"
          title="Link to agent guides"
          href={`${location.pathname}/guides`}
        >
          Guides
        </a>{" "}
        for help in connecting your agents.
      </Panel.Section>
    );
  }

  renderAgentList(relevantAgents: null | Array<any>) {
    const { localSearchQuery, remoteSearchQuery, selectedFilter } = this.state;

    const isContextFiltered = !!(
      remoteSearchQuery ||
      localSearchQuery ||
      selectedFilter !== FILTERS.NONE
    );

    if (!relevantAgents) {
      return (
        <Panel.Section className="center">
          <Spinner />
        </Panel.Section>
      );
    } else if (relevantAgents.length > 0) {
      return relevantAgents.map(({ node: agent }) => (
        <AgentRow key={agent.id} agent={agent} />
      ));
    } else if (!isContextFiltered) {
      return this.renderAgentEmptyState();
    }
  }

  updateUrlQParam(query: any) {
    const { router, location } = this.props;

    const urlSearchParams = new URLSearchParams(location.query);
    query.length
      ? urlSearchParams.set("q", query)
      : urlSearchParams.delete("q");

    router.replace(`${location.pathname}?${urlSearchParams.toString()}`);
  }

  handleSearch = (query: any) => {
    const { organization } = this.props;

    const haveCachedAllAgents =
      !!organization.agents &&
      !!organization.allAgents &&
      organization.agents.count === organization.allAgents.count &&
      organization.allAgents.count <= PAGE_SIZE;

    return haveCachedAllAgents
      ? this.handleLocalSearch(query)
      : this.handleRemoteSearch(query);
  };

  agentFilterHandler =
    (selectedFilter: (typeof FILTERS)[keyof typeof FILTERS]) => () => {
      this.agentFilterDropdown && this.agentFilterDropdown.setShowing(false);

      this.setState(
        { searchingRemotely: true, selectedFilter: selectedFilter },
        () => {
          this.props.relay.refetch(
            this.refetchVariables(),
            null,
            () => {
              this.setState({ searchingRemotely: false });
            },
            { force: true },
          );
        },
      );
    };

  handleLocalSearch = (query: any) => {
    // if there's a remote search active we make doubly sure to
    // reset it this shouldn't happen but stranger things have!
    const shouldResetSearch = !!this.state.remoteSearchQuery;

    // remove leading and trailing whitespace
    query = query.trim();

    const newState = Object.assign({}, this.state, {
      localSearchQuery: query ? query.split(/\s+/g) : null,
    });

    if (shouldResetSearch) {
      newState.searchingRemotely = true;
    }

    this.setState(newState);

    if (shouldResetSearch) {
      this.setState({ remoteSearchQuery: null });
      this.props.relay.refetch(this.refetchVariables(), null, () => {
        this.updateUrlQParam(query);
        this.setState({ searchingRemotely: false });
      });
    } else {
      this.updateUrlQParam(query);
    }
  };

  handleRemoteSearch = (query: any) => {
    this.setState({
      localSearchQuery: null,
      searchingRemotely: true,
      remoteSearchQuery: query,
    });

    if (this.remoteSearchIsSlowTimeout) {
      clearTimeout(this.remoteSearchIsSlowTimeout);
    }

    this.remoteSearchIsSlowTimeout = setTimeout(() => {
      this.setState({ searchingRemotelyIsSlow: true });
    }, second.bind(1));

    this.props.relay.refetch(
      this.refetchVariables(),
      null,
      () => {
        this.updateUrlQParam(query);
        if (this.remoteSearchIsSlowTimeout) {
          clearTimeout(this.remoteSearchIsSlowTimeout);
        }
        this.setState({
          searchingRemotely: false,
          searchingRemotelyIsSlow: false,
        });
      },
      { force: true },
    );
  };

  handleShowMoreAgents = () => {
    this.setState({ loading: true, pageSize: this.state.pageSize + PAGE_SIZE });

    this.props.relay.refetch(this.refetchVariables(), () => {
      this.setState({ loading: false });
    });
  };
}

export default createRefetchContainer(
  Agents,
  {
    organization: graphql`
      fragment Agents_organization on Organization
      @argumentDefinitions(
        isMounted: { type: "Boolean!", defaultValue: false }
        clustered: { type: "Boolean" }
        search: { type: "String" }
        clusterQueue: { type: "[ID!]" }
        isRunningJob: { type: "Boolean" }
        pageSize: { type: "Int!", defaultValue: 100 }
      ) {
        id
        uuid
        slug
        allAgents: agents @include(if: $isMounted) {
          count
        }
        agents(
          first: $pageSize
          search: $search
          clusterQueue: $clusterQueue
          isRunningJob: $isRunningJob
          clustered: $clustered
        ) @include(if: $isMounted) {
          ...ShowMoreFooter_connection
          count
          edges {
            node {
              id
              metaData
              name
              ...AgentRow_agent
            }
          }
        }
      }
    `,
  },
  graphql`
    query AgentsRefetchQuery(
      $organizationID: ID!
      $isMounted: Boolean!
      $clustered: Boolean
      $search: String
      $clusterQueue: [ID!]
      $isRunningJob: Boolean
      $pageSize: Int!
    ) {
      organization: node(id: $organizationID) {
        ...Agents_organization
          @arguments(
            isMounted: $isMounted
            clustered: $clustered
            search: $search
            clusterQueue: $clusterQueue
            isRunningJob: $isRunningJob
            pageSize: $pageSize
          )
      }
    }
  `,
);

/**
 * @generated SignedSource<<d7b546a2fef713707703eb78c36f95d0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AnnotationOrder = "PRIORITY_RECENTLY_CREATED" | "RECENTLY_CREATED" | "%future added value";
export type AnnotationsListRendererQuery$variables = {
  buildSlug: string;
  order: AnnotationOrder;
};
export type AnnotationsListRendererQuery$data = {
  readonly build: {
    readonly " $fragmentSpreads": FragmentRefs<"AnnotationsList_build">;
  } | null;
};
export type AnnotationsListRendererQuery = {
  response: AnnotationsListRendererQuery$data;
  variables: AnnotationsListRendererQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "buildSlug"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "order"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "buildSlug"
  }
],
v2 = {
  "kind": "Variable",
  "name": "order",
  "variableName": "order"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AnnotationsListRendererQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Build",
        "kind": "LinkedField",
        "name": "build",
        "plural": false,
        "selections": [
          {
            "args": [
              (v2/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "AnnotationsList_build"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AnnotationsListRendererQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Build",
        "kind": "LinkedField",
        "name": "build",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "uuid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 10
              },
              (v2/*: any*/),
              {
                "kind": "Literal",
                "name": "style",
                "value": []
              }
            ],
            "concreteType": "AnnotationConnection",
            "kind": "LinkedField",
            "name": "annotations",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AnnotationEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Annotation",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "context",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "style",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "createdAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "priority",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AnnotationBody",
                        "kind": "LinkedField",
                        "name": "body",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "html",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "Connection",
                "abstractKey": "__isConnection"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0924e66f3c816465d8000063bafd5c89",
    "id": null,
    "metadata": {},
    "name": "AnnotationsListRendererQuery",
    "operationKind": "query",
    "text": "query AnnotationsListRendererQuery(\n  $buildSlug: ID!\n  $order: AnnotationOrder!\n) {\n  build(slug: $buildSlug) {\n    ...AnnotationsList_build_17VYVu\n    id\n  }\n}\n\nfragment AnnotationsList_build_17VYVu on Build {\n  id\n  uuid\n  annotations(first: 10, style: [], order: $order) {\n    edges {\n      node {\n        id\n        context\n        style\n        createdAt\n        priority\n        body {\n          html\n        }\n      }\n      cursor\n    }\n    ...ShowMoreFooter_connection\n  }\n}\n\nfragment ShowMoreFooter_connection on Connection {\n  __isConnection: __typename\n  pageInfo {\n    hasNextPage\n  }\n}\n"
  }
};
})();

(node as any).hash = "204c0173cdb848080f0e52e57bd41a2f";

export default node;

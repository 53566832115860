/**
 * @generated SignedSource<<5f3b259a3d49ec4f847ff6c41f301a62>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TwoFactorConfigureRefetchQuery$variables = {};
export type TwoFactorConfigureRefetchQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"TwoFactorConfigure_viewer">;
  } | null;
};
export type TwoFactorConfigureRefetchQuery = {
  response: TwoFactorConfigureRefetchQuery$data;
  variables: TwoFactorConfigureRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "TwoFactorConfigureRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TwoFactorConfigure_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "TwoFactorConfigureRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "TOTP",
            "kind": "LinkedField",
            "name": "totp",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "RecoveryCodeBatch",
                "kind": "LinkedField",
                "name": "recoveryCodes",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RecoveryCode",
                    "kind": "LinkedField",
                    "name": "codes",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "code",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "consumed",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v0/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b030a0c2f3d4fc768604bf1069e3945b",
    "id": null,
    "metadata": {},
    "name": "TwoFactorConfigureRefetchQuery",
    "operationKind": "query",
    "text": "query TwoFactorConfigureRefetchQuery {\n  viewer {\n    ...TwoFactorConfigure_viewer\n    id\n  }\n}\n\nfragment RecoveryCodeList_recoveryCodes on RecoveryCodeBatch {\n  codes {\n    code\n    consumed\n  }\n}\n\nfragment TwoFactorConfigureRecoveryCodes_recoveryCodes on RecoveryCodeBatch {\n  ...RecoveryCodeList_recoveryCodes\n  codes {\n    code\n    consumed\n  }\n}\n\nfragment TwoFactorConfigure_viewer on Viewer {\n  id\n  totp {\n    id\n    recoveryCodes {\n      ...TwoFactorConfigureRecoveryCodes_recoveryCodes\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5207f788b563cf4490270aee9bbde8a5";

export default node;

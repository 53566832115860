import { useCallback, useEffect } from "react";
import Icon from "app/components/shared/Icon";
import Button from "app/components/shared/Button";
import Dropdown from "app/components/shared/Dropdown";
import UserSessionStore from "app/stores/UserSessionStore";
import Colors from "app/constants/analytics/Colors";

type Props = {
  branch_name?: string;
  default_branch?: string;
  suite_uuid: string;
};

const ALL_BRANCHES = "all branches";
const USER_SESSION_STORE_DEFAULT_BRANCH_KEY = "test-analytics-branch-filter";

const BranchFilter = ({ branch_name, default_branch, suite_uuid }: Props) => {
  const currentBranch =
    branch_name ||
    UserSessionStore.get(
      `${USER_SESSION_STORE_DEFAULT_BRANCH_KEY}:${suite_uuid}`,
    ) ||
    default_branch ||
    ALL_BRANCHES;

  useEffect(() => {
    const currentUrl = new URL(window.location.href);
    const branchInParams = currentUrl.searchParams.get("branch");

    if (currentBranch !== branchInParams) {
      currentUrl.searchParams.set("branch", currentBranch);
      // Turbo adds to history state for re-ification of past pages. We need to
      // pass this along when we add the branch param to ensure the back button
      // still works ok.
      window.history.replaceState(window.history.state, "", currentUrl);
    }
  }, [currentBranch]);

  const onChangeBranch = useCallback(
    (value) => {
      UserSessionStore.set(
        `${USER_SESSION_STORE_DEFAULT_BRANCH_KEY}:${suite_uuid}`,
        value,
      );

      const link = new URL(window.location.href);
      link.searchParams.set("branch", value);

      window.location.href = link.href;
    },
    [suite_uuid],
  );

  return (
    <Dropdown className="flex">
      <Button theme="small" className="font-medium">
        <Icon
          icon="custom/outline/14x/branch"
          style={{
            fontWeight: 400,
            fill: Colors.BASE_GRAY,
            width: 15,
            height: 15,
            marginRight: "2px",
          }}
        />

        {currentBranch === ALL_BRANCHES ? "All branches" : currentBranch}

        <Icon
          icon="down-triangle"
          style={{ width: 8, height: 8, marginLeft: "5px" }}
        />
      </Button>

      <div className="px-2 py-[3px] flex flex-column gap-2">
        {default_branch != null && (
          <DropdownButton
            onClick={onChangeBranch}
            selected={currentBranch !== ALL_BRANCHES}
            value={default_branch}
          />
        )}

        <div
          role="separator"
          aria-orientation="horizontal"
          className="-mx-2 h-px bg-gray-500"
        />

        <DropdownButton
          data-testid="allBranches"
          onClick={onChangeBranch}
          selected={currentBranch === ALL_BRANCHES}
          value={ALL_BRANCHES}
        />
      </div>
    </Dropdown>
  );
};

type DropdownButtonProps = {
  onClick: (string) => void;
  selected: boolean;
  value: string;
};

const DropdownButton = ({ onClick, selected, value }: DropdownButtonProps) => {
  const title = value === ALL_BRANCHES ? "All branches" : value;
  const handleButtonClick = useCallback(() => onClick(value), [value, onClick]);

  return (
    <button
      className="text-decoration-none flex p-2 text-sm text-charcoal-700 font-semibold rounded focus:no-underline focus:text-charcoal-700 focus:outline focus:outline-2 focus:outline-lime-500 hover:text-purple-600 data-[active=true]:bg-purple-100 data-[active=true]:text-purple-600 hover:no-underline"
      onClick={handleButtonClick}
      data-turbo="false"
      data-active={selected}
    >
      {title}
    </button>
  );
};

export default BranchFilter;

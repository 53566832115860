/* eslint-disable react/jsx-no-bind */
import { track } from "app/lib/segmentAnalytics";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useFetch } from "use-http";
import FlashesStore from "app/stores/FlashesStore";
import { createConsumer } from "app/vendor/actioncable";

const Box = styled.div`
  --system-font: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  border-radius: 16px;
  padding: 24px;
  margin-bottom: 32px;
  position: relative;
  display: grid;
  position: relative;
  grid-template-columns: 1fr;
  gap: 8px;
  overflow: hidden;
  background: conic-gradient(
    from 212deg at 55% 63%,
    rgba(255, 186, 17, 0.1) 0deg,
    rgba(241, 152, 211, 0.1) 90deg,
    rgba(161, 149, 239, 0.2) 180deg,
    rgba(106, 240, 219, 0.7) 270deg,
    rgba(255, 186, 17, 0.1) 360deg
  );
  @media (min-width: 992px) {
    padding: 32px;
    grid-template-columns: 1fr 480px;
    gap: 48px;
    background: conic-gradient(
      from 212deg at 71.58% 40%,
      rgba(255, 186, 17, 0.125) 0deg,
      rgba(241, 152, 211, 0.125) 90deg,
      rgba(161, 149, 239, 0.225) 180deg,
      rgba(106, 240, 219, 0.7) 270deg,
      rgba(255, 186, 17, 0.125) 360deg
    );
  }
`;

const CloseButton = styled.button`
  appearance: none;
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  transition: 0.2s ease background;
  font-family: var(--system-font);
  font-size: 12px;
  line-height: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  transition: opacity 0.2s ease-out;
  opacity: 0.6;
  &:hover {
    opacity: 1;
  }
  @media (max-width: 991px) {
    position: absolute;
    top: 12px;
    right: 12px;
  }
`;

const ScoreButton = styled.button`
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.7);
  box-shadow:
    0px 2px 4px 0px rgba(0, 0, 0, 0.18),
    0px 0px 0px 1px rgba(0, 0, 0, 0.08);
  display: inline-flex;
  padding: 12px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 40px;
  width: 100%;
  border: none;
  cursor: pointer;
  transition:
    box-shadow 0.2s ease-out,
    background 0.2s ease-out,
    transform 0.2s ease-out;
  font-family: var(--system-font);
  font-weight: 600;
  font-size: 14px;
  transform: translateY(0);

  &:hover {
    background: rgba(255, 255, 255, 0.8);
    box-shadow:
      0px 2px 4px 0px rgba(0, 0, 0, 0.18),
      0px 0px 0px 1px rgba(0, 0, 0, 0.3);
  }

  &:active {
    transform: translateY(1px);
    background: rgba(255, 255, 255, 0.5);
    box-shadow:
      0px 0 0 0px rgba(0, 0, 0, 0.1),
      0px 0px 0px 1px rgba(0, 0, 0, 0.15);
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 4px;
  max-width: 480px;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-inline: auto;
  flex-wrap: wrap;
  @media (min-width: 480px) {
    display: grid;
    grid-template-columns: repeat(11, 1fr);
  }
`;

const Header = styled.div`
  margin-bottom: 8px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  text-wrap: balance;
`;

const Emoji = styled.div`
  font-size: 24px;

  // A bit of a hack to align the emoji and text with a system banner if present
  flex: 0 0 31px;
  text-align: center;
`;

const Intro = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  @media (min-width: 992px) {
    flex-direction: row;
    gap: 16px;
    justify-content: start;
    text-align: left;
  }
`;

const Score = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
`;

const Key = styled.ol`
  margin: 0 auto;
  max-width: 480px;
  width: 100%;
  padding: 8px 0 0;
  display: flex;
  justify-content: center;
  gap: 24px;
  list-style: none;
  font-size: 12px;
  span {
    opacity: 0.6;
    padding-right: 4px;
    font-weight: medium;
  }
  @media (min-width: 480px) {
    justify-content: space-between;
    span {
      display: none;
    }
  }
`;

type Message = { event?: string };

interface Props {
  organization_slug?: string;
  organization_uuid?: string;
}

export default function NetPromoterScoreSurvey(props: Props) {
  const consumer = useRef(createConsumer());

  useEffect(() => {
    if (consumer.current) {
      consumer.current.subscriptions.create(
        {
          channel: "CurrentUserChannel",
        },
        {
          received: (res: Message) => {
            if (
              res?.event === "nps:dismissed" ||
              res?.event === "nps:submitted"
            ) {
              setOpen(false);
            }
          },
        },
      );
    }
    return () => {
      if (consumer.current) {
        consumer.current.disconnect();
      }
    };
  }, []);

  const { post } = useFetch({
    credentials: "same-origin",
    headers: {
      "X-CSRF-Token": window._csrf.token,
    },
  });

  const [open, setOpen] = useState(true);

  useEffect(() => {
    track("Net Promoter Score Survey Viewed", {
      organization_uuid: props.organization_uuid ?? "",
    });
  }, []);

  function handleClose() {
    const form = new FormData();
    form.append("account_id", props.organization_slug ?? "");
    post("/user/nps/dismiss", form);
    setOpen(false);
  }

  function submit(score: number) {
    const form = new FormData();
    form.append("score", String(score));
    form.append("account_id", props.organization_slug ?? "");

    post("/user/nps", form);
    setOpen(false);
    FlashesStore.flash(
      // @ts-expect-error - TS2339 - Property 'SUCCESS' does not exist on type 'FlashesStore'.
      FlashesStore.SUCCESS,
      "Thanks! Your feedback helps us improve Buildkite.",
    );
  }

  if (!open) {
    return null;
  }

  return (
    <div className="container" data-testid="nps">
      <Box>
        <Intro>
          <Emoji>👋</Emoji>
          <div>
            <Header>
              How likely are you to recommend Buildkite to a friend or
              colleague?
            </Header>
            <CloseButton onClick={handleClose}>Close</CloseButton>
          </div>
        </Intro>
        <Score>
          <ButtonsWrapper>
            <ScoreButton onClick={() => submit(0)}>0</ScoreButton>
            <ScoreButton onClick={() => submit(1)}>1</ScoreButton>
            <ScoreButton onClick={() => submit(2)}>2</ScoreButton>
            <ScoreButton onClick={() => submit(3)}>3</ScoreButton>
            <ScoreButton onClick={() => submit(4)}>4</ScoreButton>
            <ScoreButton onClick={() => submit(5)}>5</ScoreButton>
            <ScoreButton onClick={() => submit(6)}>6</ScoreButton>
            <ScoreButton onClick={() => submit(7)}>7</ScoreButton>
            <ScoreButton onClick={() => submit(8)}>8</ScoreButton>
            <ScoreButton onClick={() => submit(9)}>9</ScoreButton>
            <ScoreButton onClick={() => submit(10)}>10</ScoreButton>
          </ButtonsWrapper>
          <Key>
            <li>
              <span>0</span>Not likely
            </li>
            <li>
              <span>10</span>Highly likely
            </li>
          </Key>
        </Score>
      </Box>
    </div>
  );
}

import * as React from "react";
import styled from "styled-components";
import { getCssValue } from "app/lib/cssValues";
import classNames from "classnames";

const StyledToggleButton = styled.button`
  display: flex;
  align-items: center;
  height: 30px;
  border: 1px solid var(--gray-500);
  border-radius: 4px;
  background-color: var(--base-0);
  padding: 2px;

  transition: box-shadow 0.2s;
  &:focus-visible {
    outline: none;
    box-shadow:
      0 0 0 2px #fff,
      0 0 0 4px var(--lime-500);
  }
`;

const StyledToggleOption = styled.span`
  display: flex;
  align-items: center;
  padding: 4px 10px;
  border-radius: 2px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  height: 100%;
  color: ${getCssValue("--charcoal-700")};
  position: relative;

  &.selected {
    color: ${getCssValue("--purple-600")};
    background-color: ${getCssValue("--purple-100")};
  }
`;

type ToggleSwitchProps = {
  value: boolean;
  onChange: (isOn: boolean) => void;
  labelledBy: string;
  renderOffIndicator?: () => React.ReactElement;
  renderOnIndicator?: () => React.ReactElement;
  className?: string;
  style?: any;
};

/**
 * ToggleSwitch is a controlled, accessible button component for toggling between true and false
 * states (by default presented as "On" and "Off" indicators). A 'labelledBy' prop must be provided
 * - this is the id of the element that describes the purpose of the toggle switch. If you wish to
 * customise the On/Off indicators you can pass in render functions via the `renderOnIndicator` and
 * `renderOffIndicator` props.
 */
function ToggleSwitch({
  value: toggledOn,
  onChange,
  labelledBy,
  renderOffIndicator,
  renderOnIndicator,
  className,
  style,
}: ToggleSwitchProps) {
  function handleClick() {
    onChange(!toggledOn);
  }

  return (
    <div className="flex flex-auto items-center gap3">
      <StyledToggleButton
        role="switch"
        className={classNames("unstyled-button", className)}
        style={style}
        aria-checked={toggledOn}
        aria-labelledby={labelledBy}
        onClick={handleClick}
      >
        <StyledToggleOption className={!toggledOn ? "selected" : ""}>
          {renderOffIndicator ? renderOffIndicator() : "Off"}
        </StyledToggleOption>
        <StyledToggleOption className={toggledOn ? "selected" : ""}>
          {renderOnIndicator ? renderOnIndicator() : "On"}
        </StyledToggleOption>
      </StyledToggleButton>
    </div>
  );
}

export default ToggleSwitch;

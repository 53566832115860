import * as React from "react";
import { QueryRenderer, graphql } from "react-relay";

import Bugsnag from "app/lib/Bugsnag";
import Environment from "app/lib/relay/environment";

import Spinner from "app/components/shared/Spinner";

import JobLogExitInformation from "./JobLogExitInformation";

type Props = {
  job: {
    id: string;
  };
};

type QueryRendererData = {
  error: Error | null | undefined;
  props: any;
};

export default class JobLogExitInformationWrapper extends React.PureComponent<Props> {
  environment = Environment.get();
  static displayName = "JobLogExitInformationWrapper";

  static query = graphql`
    query ExitInformationWrapperQuery($id: ID!) {
      job(uuid: $id) {
        ...JobLogExitInformation_job
      }
    }
  `;

  renderQuery = ({ error, props }: QueryRendererData) => {
    const { job: _job, ...wrapperProps } = this.props;

    if (error) {
      Bugsnag.notify(error);
      return null; // TODO: Handle this!
    }

    if (!props) {
      return <Spinner />;
    }

    return <JobLogExitInformation {...wrapperProps} {...props} />;
  };

  render() {
    return (
      <QueryRenderer
        fetchPolicy="store-and-network"
        cacheConfig={{ force: true }}
        environment={this.environment}
        query={JobLogExitInformationWrapper.query}
        variables={{ id: this.props.job.id }}
        render={this.renderQuery}
      />
    );
  }
}

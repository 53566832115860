/**
 * @generated SignedSource<<5e0cd2e225ded6dbb1e72f8d6acad4c1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PipelineHeaderRendererQuery$variables = {
  buildSlug?: string | null;
  pipelineSlug: string;
  queryBuild: boolean;
};
export type PipelineHeaderRendererQuery$data = {
  readonly build?: {
    readonly " $fragmentSpreads": FragmentRefs<"Header_build">;
  } | null;
  readonly pipeline: {
    readonly " $fragmentSpreads": FragmentRefs<"Header_pipeline">;
  } | null;
};
export type PipelineHeaderRendererQuery = {
  response: PipelineHeaderRendererQuery$data;
  variables: PipelineHeaderRendererQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "buildSlug"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pipelineSlug"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "queryBuild"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "buildSlug"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "pipelineSlug"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v9 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "count",
    "storageKey": null
  }
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v11 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "allowed",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "code",
    "storageKey": null
  },
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PipelineHeaderRendererQuery",
    "selections": [
      {
        "condition": "queryBuild",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Build",
            "kind": "LinkedField",
            "name": "build",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "Header_build"
              }
            ],
            "storageKey": null
          }
        ]
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Pipeline",
        "kind": "LinkedField",
        "name": "pipeline",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Header_pipeline"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PipelineHeaderRendererQuery",
    "selections": [
      {
        "condition": "queryBuild",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Build",
            "kind": "LinkedField",
            "name": "build",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "branch",
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ]
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Pipeline",
        "kind": "LinkedField",
        "name": "pipeline",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "defaultBranch",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v4/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "iconUrl",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PipelineTemplate",
            "kind": "LinkedField",
            "name": "pipelineTemplate",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v6/*: any*/),
              (v8/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/),
          (v7/*: any*/),
          {
            "alias": "scheduledBuilds",
            "args": [
              {
                "kind": "Literal",
                "name": "state",
                "value": "SCHEDULED"
              }
            ],
            "concreteType": "BuildConnection",
            "kind": "LinkedField",
            "name": "builds",
            "plural": false,
            "selections": (v9/*: any*/),
            "storageKey": "builds(state:\"SCHEDULED\")"
          },
          {
            "alias": "runningBuilds",
            "args": [
              {
                "kind": "Literal",
                "name": "state",
                "value": [
                  "RUNNING",
                  "FAILING"
                ]
              }
            ],
            "concreteType": "BuildConnection",
            "kind": "LinkedField",
            "name": "builds",
            "plural": false,
            "selections": (v9/*: any*/),
            "storageKey": "builds(state:[\"RUNNING\",\"FAILING\"])"
          },
          (v6/*: any*/),
          (v8/*: any*/),
          (v10/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "visibility",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "archived",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "emoji",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "color",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Repository",
            "kind": "LinkedField",
            "name": "repository",
            "plural": false,
            "selections": [
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "provider",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v10/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PipelinePermissions",
            "kind": "LinkedField",
            "name": "permissions",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Permission",
                "kind": "LinkedField",
                "name": "pipelineUpdate",
                "plural": false,
                "selections": (v11/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Permission",
                "kind": "LinkedField",
                "name": "buildCreate",
                "plural": false,
                "selections": (v11/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "634454085b38ca5acd54c637d4a7c1ba",
    "id": null,
    "metadata": {},
    "name": "PipelineHeaderRendererQuery",
    "operationKind": "query",
    "text": "query PipelineHeaderRendererQuery(\n  $buildSlug: ID\n  $pipelineSlug: ID!\n  $queryBuild: Boolean!\n) {\n  build(slug: $buildSlug) @include(if: $queryBuild) {\n    ...Header_build\n    id\n  }\n  pipeline(slug: $pipelineSlug) {\n    ...Header_pipeline\n    id\n  }\n}\n\nfragment BuildsIndicator_pipeline on Pipeline {\n  id\n  uuid\n  scheduledBuilds: builds(state: SCHEDULED) {\n    count\n  }\n  runningBuilds: builds(state: [RUNNING, FAILING]) {\n    count\n  }\n}\n\nfragment CreateBuildDialog_build on Build {\n  message\n  branch\n}\n\nfragment CreateBuildDialog_pipeline on Pipeline {\n  slug\n  defaultBranch\n  organization {\n    slug\n    id\n  }\n  pipelineTemplate {\n    uuid\n    name\n    description\n    id\n  }\n}\n\nfragment Header_build on Build {\n  ...CreateBuildDialog_build\n}\n\nfragment Header_pipeline on Pipeline {\n  ...CreateBuildDialog_pipeline\n  ...BuildsIndicator_pipeline\n  id\n  name\n  description\n  url\n  visibility\n  archived\n  emoji\n  color\n  organization {\n    name\n    slug\n    iconUrl\n    id\n  }\n  repository {\n    url\n    provider {\n      __typename\n      url\n    }\n  }\n  permissions {\n    pipelineUpdate {\n      allowed\n      code\n      message\n    }\n    buildCreate {\n      allowed\n      code\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5842bee3ce7909d59385d7b6c004f888";

export default node;

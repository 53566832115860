import Icon from "app/components/shared/Icon";
import {
  HoverCard,
  HoverCardTrigger,
  HoverCardContent,
  HoverCardPortal,
} from "app/components/shared/HoverCard";

export default function Signature({
  algorithm,
  onNestedLinkTrigger,
}: {
  algorithm: string;
  onNestedLinkTrigger: (e: React.MouseEvent | React.KeyboardEvent) => void;
}) {
  const description = (
    <>
      This step was signed using the{" "}
      <span className="font-bold">{algorithm}</span> algorithm.
    </>
  );

  return (
    <HoverCard>
      <HoverCardTrigger className="job-stats flex flex-none items-center cursor-help">
        <Icon
          icon="custom/outline/14x/signature"
          className="flex-shrink-0 w-3.5 h-3.5"
        />
        {/* Description for screenreader */}
        <span className="sr-only">{description}</span>
      </HoverCardTrigger>

      <HoverCardPortal>
        <HoverCardContent
          size="md"
          className="text-center"
          onClick={onNestedLinkTrigger}
          onKeyDown={onNestedLinkTrigger}
        >
          {description}
        </HoverCardContent>
      </HoverCardPortal>
    </HoverCard>
  );
}

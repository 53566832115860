import { useState } from "react";
import { commitMutation, createRefetchContainer, graphql } from "react-relay";
import { ConnectionHandler } from "relay-runtime";

import Environment from "app/lib/relay/environment";

import AutocompleteDialog from "app/components/shared/Autocomplete/Dialog";
import Button from "app/components/shared/Button";

import FlashesStore from "app/stores/FlashesStore";
import TeamSuggestion from "./TeamSuggestion";

const renderSuggestions = ({ teamAddSearch, organizationTeams, loading }) => {
  if (!organizationTeams || loading) {
    return [<AutocompleteDialog.Loader key="loading" />];
  }

  // Filter team edges by permission to add them
  const relevantTeamEdges = organizationTeams.edges.filter(
    ({ node }) => node.permissions.teamPipelineCreate.allowed,
  );

  // Either render the suggestions, or show a "not found" error
  if (relevantTeamEdges.length > 0) {
    return relevantTeamEdges.map(({ node }) => {
      return [<TeamSuggestion key={node.id} team={node} />, node];
    });
  } else if (teamAddSearch !== "") {
    return [
      <AutocompleteDialog.ErrorMessage key="error">
        Could not find a team with name <em>{teamAddSearch}</em>
      </AutocompleteDialog.ErrorMessage>,
    ];
  }

  return [
    <AutocompleteDialog.ErrorMessage key="error">
      Could not find any more teams to add
    </AutocompleteDialog.ErrorMessage>,
  ];
};

type Props = {
  pipeline: {
    id: string;
    slug: string;
    archived: boolean;
    organization: {
      slug: string;
      teams?: {
        edges: Array<{
          node: {
            id: string;
            permissions: {
              teamPipelineCreate: {
                allowed: boolean;
              };
            };
          };
        }>;
      };
    };
  };
  relay: any;
};

const TeamChooser = ({ pipeline, relay }: Props) => {
  const defaultState = {
    loading: false,
    searching: false,
    removing: null,
    showingDialog: false,

    teamAddSearch: "",
  } as const;
  const [state, setState] = useState(defaultState);

  const handleDialogOpen = () => {
    // @ts-expect-error - TS2322 - Type 'true' is not assignable to type 'false'. | TS2322 - Type 'true' is not assignable to type 'false'.
    setState({ ...state, loading: true, showingDialog: true });

    relay.refetch(
      {
        includeSearchResults: true,
        pipelineSlug: `${pipeline.organization.slug}/${pipeline.slug}`,
        teamAddSearch: "",
        pipelineSelector: `!${pipeline.slug}`,
      },
      null,
      () => {
        // @ts-expect-error - TS2322 - Type 'true' is not assignable to type 'false'.
        setState({ ...state, loading: false, showingDialog: true });
      },
      { force: true },
    );
  };

  const handleDialogClose = () => {
    setState({ ...state, showingDialog: false, teamAddSearch: "" });
  };

  const handleTeamSearch = (teamAddSearch: any) => {
    // @ts-expect-error - TS2322 - Type 'true' is not assignable to type 'false'.
    setState({ ...state, searching: true });
    relay.refetch(
      {
        teamAddSearch,
        pipelineSelector: `!${pipeline.slug}`,
        includeSearchResults: true,
      },
      null,
      () => {
        setState({ ...state, searching: false });
      },
      { force: true },
    );
  };

  const handleTeamSelect = (team: any) => {
    setState({ ...state, showingDialog: false, teamAddSearch: "" });
    const environment = Environment.get();

    const updater = (store: any) => {
      // Manually perform optimistic update and insert an edge.
      const payload = store.getRootField("teamPipelineCreate");
      const newEdge = payload.getLinkedRecord("teamPipelineEdge");

      const PipelineProxy = store.get(pipeline.id);
      const conn = ConnectionHandler.getConnection(
        PipelineProxy,
        "Page_pipeline_teams",
      );
      ConnectionHandler.insertEdgeBefore(conn, newEdge);
    };

    commitMutation(environment, {
      mutation: graphql`
        mutation TeamChooserTeamPipelineCreateMutation(
          $input: TeamPipelineCreateInput!
        ) {
          teamPipelineCreate(input: $input) {
            teamPipelineEdge {
              node {
                id
                ...TeamRow_teamPipeline
              }
            }
          }
        }
      `,
      variables: {
        input: {
          teamID: team.id,
          pipelineID: pipeline.id,
        },
      },
      updater,
      onError: (error) => {
        // @ts-expect-error - TS2339 - Property 'ERROR' does not exist on type 'FlashesStore'.
        FlashesStore.flash(FlashesStore.ERROR, error);
      },
    });
  };

  return (
    <div>
      <Button
        onClick={handleDialogOpen}
        theme="primary"
        disabled={!!pipeline.archived}
      >
        Add to Team
      </Button>
      <AutocompleteDialog
        isOpen={state.showingDialog}
        onRequestClose={handleDialogClose}
        onSearch={handleTeamSearch}
        onSelect={handleTeamSelect}
        items={renderSuggestions({
          organizationTeams: pipeline.organization.teams,
          teamAddSearch: state.teamAddSearch,
          loading: state.loading,
        })}
        placeholder="Search all teams…"
        selectLabel="Add"
        popover={false}
      />
    </div>
  );
};

export default createRefetchContainer(
  TeamChooser,
  {
    pipeline: graphql`
      fragment TeamChooser_pipeline on Pipeline
      @argumentDefinitions(
        teamAddSearch: { type: "String", defaultValue: "" }
        pipelineSelector: { type: "PipelineSelector" }
        includeSearchResults: { type: "Boolean", defaultValue: false }
      ) {
        id
        slug
        archived
        organization {
          slug
          teams(
            search: $teamAddSearch
            first: 10
            order: RELEVANCE
            pipeline: $pipelineSelector
          ) @include(if: $includeSearchResults) {
            edges {
              node {
                id
                permissions {
                  teamPipelineCreate {
                    allowed
                  }
                }
                ...TeamSuggestion_team
              }
            }
          }
        }
      }
    `,
  },
  graphql`
    query TeamChooser_Query(
      $pipelineSlug: ID
      $pipelineSelector: PipelineSelector
      $teamAddSearch: String = ""
      $includeSearchResults: Boolean = true
    ) {
      pipeline(slug: $pipelineSlug) {
        ...TeamChooser_pipeline
          @arguments(
            teamAddSearch: $teamAddSearch
            pipelineSelector: $pipelineSelector
            includeSearchResults: $includeSearchResults
          )
      }
    }
  `,
);

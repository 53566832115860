import { track } from "app/lib/segmentAnalytics";

export const _trackTALinkClicked = ({
  linkTo,
  linkTitle = "",
  url,
  source,
}: {
  linkTo: string;
  linkTitle: string;
  url: string;
  source: string;
}) =>
  track("TA Link Clicked", {
    source,
    link_to: linkTo,
    link_title: linkTitle,
    url,
  });

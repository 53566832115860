import * as React from "react";

type Props = {
  href: string;
  children: React.ReactNode;
};

export default class Link extends React.PureComponent<Props> {
  static displayName = "Footer.Link";

  render() {
    const { children, ...props } = this.props;

    return (
      <a className="btn semi-bold hover-lime" {...props}>
        {children}
      </a>
    );
  }
}

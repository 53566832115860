import * as React from "react";
import { v4 as uuid } from "uuid";

import Code from "app/components/docs/shared/code";
import CopyTextButton from "app/components/shared/CopyTextButton";

type Props = {
  children: React.ReactNode;
  language?: string;
  showCopyButton?: boolean;
};

export default function CodeBlock({ showCopyButton, ...props }: Props) {
  const id = `code-block-${uuid()}`;

  return (
    <div className="relative">
      <pre
        id={id}
        className="border border-gray rounded bg-silver overflow-auto p2 monospace"
      >
        <Code {...props} />
      </pre>

      {showCopyButton && (
        <CopyTextButton
          element={`#${id}`}
          className="absolute w-8 h-8 top-2.5 right-2.5 border border-gray rounded flex items-center justify-center"
        />
      )}
    </div>
  );
}

import Button from "app/components/shared/Button";
import { useBuild } from "app/components/Playground/BuildContext";
import { TriggerJob } from "app/components/build/Header/pipeline/types/TriggerJob";
import TriggerJobSummary from "app/components/build/Show/components/TriggerJobSummary";

export const TriggerJobDetails = ({ step }) => {
  const { build, store } = useBuild();
  if (!build || !store) {
    return null;
  }

  const jobs = build.jobs.filter((job) => job.stepUuid === step.uuid);
  const latestJob = jobs[jobs.length - 1] as TriggerJob;

  return (
    <div className="job-list-pipeline">
      <TriggerJobSummary
        job={latestJob}
        isRetry={jobs.length > 1}
        build={build}
      />

      {latestJob.triggeredBuild &&
        latestJob.triggeredBuild.streamName &&
        !latestJob.triggeredBuild.destroyedAt && (
          <>
            <div className="-my-[10px] p-3 bg-slate-100/70 border-gray rounded-b shadow-inner">
              <turbo-cable-stream-source
                signed-stream-name={latestJob.triggeredBuild.streamName}
                channel="Turbo::StreamsChannel"
              />
              <div id={latestJob.triggeredBuild.streamTargetDomId}>
                <turbo-frame
                  id={latestJob.triggeredBuild.stepsStreamDomId}
                  src={latestJob.triggeredBuild.stepsUrl}
                >
                  <div>Loading steps...</div>
                </turbo-frame>
              </div>
            </div>

            <Button
              href={latestJob.triggeredBuild.canvasUrl}
              theme="small"
              target="_blank"
              className="mt-5"
            >
              <span className="after:content-['→'] after:ml-1">
                Go to build
              </span>
            </Button>
          </>
        )}
    </div>
  );
};

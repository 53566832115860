import { useEffect, useMemo, useRef } from "react";

type Props = {
  following: boolean;
  children: React.ReactNode;
};

/**
 * ScrollFollower is a component that will scroll to the bottom of its children
 * whenever the children change. This is useful for logs or other content that
 * should always be visible.
 */
export default function ScrollFollower(props: Props) {
  const ref = useRef<HTMLDivElement | null>(null);

  const resizeObserver = useMemo(() => {
    return new ResizeObserver(() => {
      requestAnimationFrame(() => {
        ref.current?.scrollIntoView({ block: "end" });
      });
    });
  }, []);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    if (props.following) {
      resizeObserver.observe(ref.current);
    } else {
      resizeObserver.disconnect();
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [props.following]);

  return <div ref={ref}>{props.children}</div>;
}

import { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import FormInputHelp from "./FormInputHelp";
import FormInputErrors from "./FormInputErrors";
import Badge from "app/components/shared/Badge";

class FormRadioGroup extends Component {
  static propTypes = {
    options: PropTypes.arrayOf(
      PropTypes.shape({
        className: PropTypes.string,
        label: PropTypes.node.isRequired,
        help: PropTypes.node,
        value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
          .isRequired,
      }),
    ).isRequired,
    value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    label: PropTypes.node,
    name: PropTypes.string,
    required: PropTypes.bool,
    help: PropTypes.node,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    errors: PropTypes.array,
  };

  render() {
    return (
      <div className={classNames("mb2", this.props.className)}>
        {this._renderLabel()}
        {this.props.help && <FormInputHelp>{this.props.help}</FormInputHelp>}
        {this._renderInputs()}
        <FormInputErrors errors={this.props.errors} />
      </div>
    );
  }

  _hasErrors() {
    return this.props.errors && this.props.errors.length > 0;
  }

  _renderLabel() {
    if (this.props.label) {
      return (
        <label className={classNames("block bold", { red: this._hasErrors() })}>
          {this.props.label}
          {this.props.required && (
            <span className="dark-gray h6 semi-bold"> — Required</span>
          )}
        </label>
      );
    }
  }

  _renderInputs() {
    // If we use onChange event handlers, radio boxes need to be "managed" inputs.
    // `onChange` never fires for subsequent changes if you use `defaultChecked`.
    return this.props.options.map((option, index) => (
      <div key={index} className="mt1">
        <label className="mt1 inline-block pl4">
          <div className="flex">
            <input
              className={classNames(
                "absolute radio",
                { "is-error": this._hasErrors() },
                option.className,
              )}
              style={{ marginLeft: "-20px" }}
              name={this.props.name}
              type="radio"
              checked={
                this.props.onChange
                  ? this.props.value === option.value
                  : undefined
              }
              defaultChecked={
                this.props.onChange
                  ? undefined
                  : this.props.value === option.value
              }
              disabled={this.props.disabled}
              value={option.value}
              onChange={this.props.onChange}
            />
            <span className="bold block" style={{ marginBottom: -5 }}>
              {" "}
              {option.label}
            </span>
            {option.badge && <Badge outline={true}>{option.badge}</Badge>}
          </div>
          <FormInputHelp>{option.help}</FormInputHelp>
        </label>
      </div>
    ));
  }
}

export default FormRadioGroup;

const TickSVG = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="Icon of a green tick"
    role="img"
  >
    <path
      d="M2.99999 7.49999L5.5 10L10.5 5"
      stroke="#00BE13"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default TickSVG;

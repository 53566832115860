/**
 * @generated SignedSource<<c5a93884d001a8f6eaa86546aaf3ec12>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PipelineVisibility = "PRIVATE" | "PUBLIC" | "%future added value";
export type PipelineUpdateInput = {
  allowRebuilds?: boolean | null;
  archived?: boolean | null;
  branchConfiguration?: string | null;
  cancelIntermediateBuilds?: boolean | null;
  cancelIntermediateBuildsBranchFilter?: string | null;
  clientMutationId?: string | null;
  clusterId?: string | null;
  color?: string | null;
  defaultBranch?: string | null;
  defaultTimeoutInMinutes?: number | null;
  description?: string | null;
  emoji?: string | null;
  id: string;
  maximumTimeoutInMinutes?: number | null;
  name?: string | null;
  nextBuildNumber?: number | null;
  pipelineTemplateId?: string | null;
  repository?: PipelineRepositoryInput | null;
  skipIntermediateBuilds?: boolean | null;
  skipIntermediateBuildsBranchFilter?: string | null;
  steps?: PipelineStepsInput | null;
  tags?: ReadonlyArray<PipelineTagInput> | null;
  visibility?: PipelineVisibility | null;
};
export type PipelineRepositoryInput = {
  url: string;
};
export type PipelineStepsInput = {
  yaml: string;
};
export type PipelineTagInput = {
  label: string;
};
export type BranchSettingsDialogPipelineUpdateMutation$variables = {
  input: PipelineUpdateInput;
};
export type BranchSettingsDialogPipelineUpdateMutation$data = {
  readonly pipelineUpdate: {
    readonly pipeline: {
      readonly defaultBranch: string | null;
      readonly " $fragmentSpreads": FragmentRefs<"Metrics_pipeline">;
    };
  } | null;
};
export type BranchSettingsDialogPipelineUpdateMutation = {
  response: BranchSettingsDialogPipelineUpdateMutation$data;
  variables: BranchSettingsDialogPipelineUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "defaultBranch",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BranchSettingsDialogPipelineUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PipelineUpdatePayload",
        "kind": "LinkedField",
        "name": "pipelineUpdate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Pipeline",
            "kind": "LinkedField",
            "name": "pipeline",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "Metrics_pipeline"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BranchSettingsDialogPipelineUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PipelineUpdatePayload",
        "kind": "LinkedField",
        "name": "pipelineUpdate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Pipeline",
            "kind": "LinkedField",
            "name": "pipeline",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 6
                  }
                ],
                "concreteType": "PipelineMetricConnection",
                "kind": "LinkedField",
                "name": "metrics",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PipelineMetricEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PipelineMetric",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "label",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "value",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "url",
                            "storageKey": null
                          },
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "metrics(first:6)"
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ed9cabadf9e0ffdceb8aa9869f2208b1",
    "id": null,
    "metadata": {},
    "name": "BranchSettingsDialogPipelineUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation BranchSettingsDialogPipelineUpdateMutation(\n  $input: PipelineUpdateInput!\n) {\n  pipelineUpdate(input: $input) {\n    pipeline {\n      defaultBranch\n      ...Metrics_pipeline\n      id\n    }\n  }\n}\n\nfragment Metric_metric on PipelineMetric {\n  label\n  value\n  url\n}\n\nfragment Metrics_pipeline on Pipeline {\n  metrics(first: 6) {\n    edges {\n      node {\n        label\n        ...Metric_metric\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "69841a610b87ec003e17f35fae8c8a05";

export default node;

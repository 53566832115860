/**
 * @generated SignedSource<<df547facabea668ecc9ebf98d1bbabcd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AgentsRefetchQuery$variables = {
  clusterQueue?: ReadonlyArray<string> | null;
  clustered?: boolean | null;
  isMounted: boolean;
  isRunningJob?: boolean | null;
  organizationID: string;
  pageSize: number;
  search?: string | null;
};
export type AgentsRefetchQuery$data = {
  readonly organization: {
    readonly " $fragmentSpreads": FragmentRefs<"Agents_organization">;
  } | null;
};
export type AgentsRefetchQuery = {
  response: AgentsRefetchQuery$data;
  variables: AgentsRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "clusterQueue"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "clustered"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isMounted"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isRunningJob"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationID"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pageSize"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v7 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationID"
  }
],
v8 = {
  "kind": "Variable",
  "name": "clusterQueue",
  "variableName": "clusterQueue"
},
v9 = {
  "kind": "Variable",
  "name": "clustered",
  "variableName": "clustered"
},
v10 = {
  "kind": "Variable",
  "name": "isRunningJob",
  "variableName": "isRunningJob"
},
v11 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "count",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AgentsRefetchQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v7/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": [
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "kind": "Variable",
                "name": "isMounted",
                "variableName": "isMounted"
              },
              (v10/*: any*/),
              {
                "kind": "Variable",
                "name": "pageSize",
                "variableName": "pageSize"
              },
              (v11/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "Agents_organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v6/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Operation",
    "name": "AgentsRefetchQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v7/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v12/*: any*/),
          (v13/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v14/*: any*/),
              (v15/*: any*/),
              {
                "condition": "isMounted",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "alias": "allAgents",
                    "args": null,
                    "concreteType": "AgentConnection",
                    "kind": "LinkedField",
                    "name": "agents",
                    "plural": false,
                    "selections": [
                      (v16/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      (v8/*: any*/),
                      (v9/*: any*/),
                      {
                        "kind": "Variable",
                        "name": "first",
                        "variableName": "pageSize"
                      },
                      (v10/*: any*/),
                      (v11/*: any*/)
                    ],
                    "concreteType": "AgentConnection",
                    "kind": "LinkedField",
                    "name": "agents",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "kind": "LinkedField",
                            "name": "pageInfo",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "hasNextPage",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "Connection",
                        "abstractKey": "__isConnection"
                      },
                      (v16/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AgentEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Agent",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v13/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "metaData",
                                "storageKey": null
                              },
                              (v17/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "connectionState",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "isRunningJob",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "hostname",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Organization",
                                "kind": "LinkedField",
                                "name": "organization",
                                "plural": false,
                                "selections": [
                                  (v15/*: any*/),
                                  (v13/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ClusterQueue",
                                "kind": "LinkedField",
                                "name": "clusterQueue",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "key",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Cluster",
                                    "kind": "LinkedField",
                                    "name": "cluster",
                                    "plural": false,
                                    "selections": [
                                      (v14/*: any*/),
                                      (v17/*: any*/),
                                      (v13/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v14/*: any*/),
                                  (v13/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "public",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "job",
                                "plural": false,
                                "selections": [
                                  (v12/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "state",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "label",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "command",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Build",
                                        "kind": "LinkedField",
                                        "name": "build",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "number",
                                            "storageKey": null
                                          },
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Pipeline",
                                            "kind": "LinkedField",
                                            "name": "pipeline",
                                            "plural": false,
                                            "selections": [
                                              (v17/*: any*/),
                                              (v13/*: any*/)
                                            ],
                                            "storageKey": null
                                          },
                                          (v13/*: any*/)
                                        ],
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "url",
                                        "storageKey": null
                                      }
                                    ],
                                    "type": "JobTypeCommand",
                                    "abstractKey": null
                                  },
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v13/*: any*/)
                                    ],
                                    "type": "Node",
                                    "abstractKey": "__isNode"
                                  }
                                ],
                                "storageKey": null
                              },
                              (v14/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "version",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "versionHasKnownIssues",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ]
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2b512186ee378e37b97bdf28e1e4eaa3",
    "id": null,
    "metadata": {},
    "name": "AgentsRefetchQuery",
    "operationKind": "query",
    "text": "query AgentsRefetchQuery(\n  $organizationID: ID!\n  $isMounted: Boolean!\n  $clustered: Boolean\n  $search: String\n  $clusterQueue: [ID!]\n  $isRunningJob: Boolean\n  $pageSize: Int!\n) {\n  organization: node(id: $organizationID) {\n    __typename\n    ...Agents_organization_tYOb1\n    id\n  }\n}\n\nfragment AgentRow_agent on Agent {\n  ...AgentStateIcon_agent\n  id\n  hostname\n  metaData\n  name\n  organization {\n    slug\n    id\n  }\n  clusterQueue {\n    key\n    cluster {\n      uuid\n      name\n      id\n    }\n    uuid\n    id\n  }\n  public\n  isRunningJob\n  job {\n    __typename\n    ... on JobTypeCommand {\n      state\n    }\n    ...JobLink_job\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n  uuid\n  version\n  versionHasKnownIssues\n}\n\nfragment AgentStateIcon_agent on Agent {\n  connectionState\n  isRunningJob\n}\n\nfragment Agents_organization_tYOb1 on Organization {\n  id\n  uuid\n  slug\n  allAgents: agents @include(if: $isMounted) {\n    count\n  }\n  agents(first: $pageSize, search: $search, clusterQueue: $clusterQueue, isRunningJob: $isRunningJob, clustered: $clustered) @include(if: $isMounted) {\n    ...ShowMoreFooter_connection\n    count\n    edges {\n      node {\n        id\n        metaData\n        name\n        ...AgentRow_agent\n      }\n    }\n  }\n}\n\nfragment JobLink_job on JobTypeCommand {\n  ...JobTitle_job\n  url\n}\n\nfragment JobTitle_job on JobTypeCommand {\n  label\n  command\n  build {\n    number\n    pipeline {\n      name\n      id\n    }\n    id\n  }\n}\n\nfragment ShowMoreFooter_connection on Connection {\n  __isConnection: __typename\n  pageInfo {\n    hasNextPage\n  }\n}\n"
  }
};
})();

(node as any).hash = "9287f12d1e0109a549c1c05f0da45c78";

export default node;

import Logger from "app/lib/Logger";

export default class IconLoader {
  static types = {
    raw: "raw",
    component: "component",
    heroicon: "heroicon",
  };

  static load(loader: keyof typeof IconLoader.types, name: string) {
    const path =
      loader === "heroicon"
        ? // If the icon name starts with a number, use the the name as the path,
          // otherwise prepend the path with the default size (24px)
          name.match(/\d+\/.+/)
          ? `./${name}.svg`
          : `./24/${name}.svg`
        : `./${name}.svg`;
    const context = CONTEXTS[loader];

    try {
      switch (loader) {
        case this.types.raw:
          return context(path);
        case this.types.component:
        case this.types.heroicon:
          return context(path).default;
      }
    } catch (err: any) {
      if (loader === "heroicon") {
        Logger.error(
          `[Icon] No heroicon found for "${name}", make sure it is declared in heroicons.json`,
          err,
        );
      } else {
        Logger.error(`[Icon] No icon found for "${name}"`, err);
      }
    }

    return CONTEXTS[IconLoader.types.component]("./placeholder.svg").default;
  }
}

const CONTEXTS = {
  [IconLoader.types.raw]: require.context("./raw/", false, /\.svg$/),
  [IconLoader.types.component]: require.context("./component/", true, /\.svg$/),
  [IconLoader.types.heroicon]: require.context(
    "./../../../../node_modules/heroicons/",
    true,
    /\.svg$/,
  ),
} as const;

import * as React from "react";
import { createRefetchContainer, graphql } from "react-relay";
import cable from "app/lib/cable";
import JobEventActorFigure from "./JobEventActorFigure";
import JobEventCreated from "./JobEventCreated";
import JobEventLimited from "./JobEventLimited";
import JobEventFinished from "./JobEventFinished";
import JobEventRetried from "./JobEventRetried";
import JobEventScheduled from "./JobEventScheduled";
import JobEventAssigned from "./JobEventAssigned";
import JobEventAssignedExpired from "./JobEventAssignedExpired";
import JobEventCanceled from "./JobEventCanceled";
import JobEventCancelation from "./JobEventCancelation";
import JobEventAccepted from "./JobEventAccepted";
import JobEventAcceptedExpired from "./JobEventAcceptedExpired";
import JobEventStarted from "./JobEventStarted";
import JobEventTimedOut from "./JobEventTimedOut";
import JobEventChanged from "./JobEventChanged";
import JobEventBuildStepUploadCreated from "./JobEventBuildStepUploadCreated";
import JobEventExpired from "./JobEventExpired";
import JobEventAgentStopped from "./JobEventAgentStopped";
import JobEventAgentDisconnected from "./JobEventAgentDisconnected";
import JobEventAgentLost from "./JobEventAgentLost";
import JobEventJobLogSizeExceeded from "./JobEventJobLogSizeExceeded";
import JobEventRetryFailed from "./JobEventRetryFailed";

// This is pretty gnarly but will just extract the nested event node type from the generated types,
// which is pretty handy as we pass it around a bunch here...
type EventNode = ReturnType<
  <T>(arg1: {
    readonly events?: {
      readonly edges:
        | ReadonlyArray<
            | {
                readonly node: T;
              }
            | null
            | undefined
          >
        | null
        | undefined;
    };
  }) => T
>;
// “Fake” events that are UI only
type VirtualEventNode = {
  type: "CREATED" | "PENDING";
};

type Props = {
  relay: any;
  job: any;
};

// Create a constant that contains all the possible events types, type it by the possible
// values from the enum.
export const EVENT_TYPES: {
  [key: string]: string;
} = {
  ACCEPTED_EXPIRED: "ACCEPTED_EXPIRED",
  ACCEPTED: "ACCEPTED",
  AGENT_DISCONNECTED: "AGENT_DISCONNECTED",
  AGENT_LOST: "AGENT_LOST",
  AGENT_STOPPED: "AGENT_STOPPED",
  ASSIGNED_EXPIRED: "ASSIGNED_EXPIRED",
  ASSIGNED: "ASSIGNED",
  BUILD_STEP_UPLOAD_CREATED: "BUILD_STEP_UPLOAD_CREATED",
  CANCELATION: "CANCELATION",
  CANCELED: "CANCELED",
  CHANGED: "CHANGED",
  EXPIRED: "EXPIRED",
  FINISHED: "FINISHED",
  LIMITED: "LIMITED",
  LOG_SIZE_LIMIT_EXCEEDED: "LOG_SIZE_LIMIT_EXCEEDED",
  NOTIFICATION: "NOTIFICATION",
  RETRIED: "RETRIED",
  RETRY_FAILED: "RETRY_FAILED",
  SCHEDULED: "SCHEDULED",
  STARTED: "STARTED",
  TIMED_OUT: "TIMED_OUT",
  UNBLOCKED: "UNBLOCKED",
};

export const UI_EVENT_TYPES = {
  // Special case types that only exist in the UI
  CREATED: "CREATED",
  PENDING: "PENDING",
} as const;

const RENDERABLE_EVENT_TYPES = [
  EVENT_TYPES.ACCEPTED_EXPIRED,
  EVENT_TYPES.ACCEPTED,
  EVENT_TYPES.AGENT_DISCONNECTED,
  EVENT_TYPES.AGENT_LOST,
  EVENT_TYPES.AGENT_STOPPED,
  EVENT_TYPES.ASSIGNED_EXPIRED,
  EVENT_TYPES.ASSIGNED,
  EVENT_TYPES.BUILD_STEP_UPLOAD_CREATED,
  EVENT_TYPES.CANCELATION,
  EVENT_TYPES.CANCELED,
  EVENT_TYPES.EXPIRED,
  EVENT_TYPES.FINISHED,
  EVENT_TYPES.LIMITED,
  EVENT_TYPES.LOG_SIZE_LIMIT_EXCEEDED,
  EVENT_TYPES.RETRIED,
  EVENT_TYPES.RETRY_FAILED,
  EVENT_TYPES.SCHEDULED,
  EVENT_TYPES.STARTED,
  EVENT_TYPES.TIMED_OUT,
];

function EventContent(props: { event: EventNode }): React.ReactElement | null {
  // See Job::Event::Type in app/models/job/event.rb
  // @ts-expect-error - TS2571 - Object is of type 'unknown'.
  switch (props.event?.type) {
    case EVENT_TYPES.LIMITED:
      return <JobEventLimited {...props} />;
    case EVENT_TYPES.SCHEDULED:
      return <JobEventScheduled {...props} />;
    case EVENT_TYPES.ASSIGNED:
      return <JobEventAssigned {...props} />;
    case EVENT_TYPES.ASSIGNED_EXPIRED:
      return <JobEventAssignedExpired {...props} />;
    case EVENT_TYPES.ACCEPTED:
      return <JobEventAccepted {...props} />;
    case EVENT_TYPES.ACCEPTED_EXPIRED:
      return <JobEventAcceptedExpired {...props} />;
    case EVENT_TYPES.STARTED:
      return <JobEventStarted {...props} />;
    case EVENT_TYPES.FINISHED:
      return <JobEventFinished {...props} />;
    case EVENT_TYPES.RETRIED:
      return <JobEventRetried {...props} />;
    case EVENT_TYPES.RETRY_FAILED:
      return <JobEventRetryFailed {...props} />;
    case EVENT_TYPES.CANCELATION:
      return <JobEventCancelation {...props} />;
    case EVENT_TYPES.CANCELED:
      return <JobEventCanceled {...props} />;
    case EVENT_TYPES.TIMED_OUT:
      return <JobEventTimedOut {...props} />;
    case EVENT_TYPES.CHANGED:
      return <JobEventChanged {...props} />;
    case EVENT_TYPES.BUILD_STEP_UPLOAD_CREATED:
      return <JobEventBuildStepUploadCreated {...props} />;
    case EVENT_TYPES.EXPIRED:
      return <JobEventExpired {...props} />;
    case EVENT_TYPES.AGENT_STOPPED:
      return <JobEventAgentStopped {...props} />;
    case EVENT_TYPES.AGENT_DISCONNECTED:
      return <JobEventAgentDisconnected {...props} />;
    case EVENT_TYPES.AGENT_LOST:
      return <JobEventAgentLost {...props} />;
    case EVENT_TYPES.LOG_SIZE_LIMIT_EXCEEDED:
      return <JobEventJobLogSizeExceeded {...props} />;

    // Not implemented yet...
    case EVENT_TYPES.NOTIFICATION:
    case EVENT_TYPES.UNBLOCKED:
      return null;

    // Fall through to an empty render. In case something weird happens, lol?
    default:
      return null;
  }
}

function EventBadge(props: {
  event: EventNode | VirtualEventNode;
}): React.ReactElement {
  // @ts-expect-error - TS2571 - Object is of type 'unknown'.
  switch (props.event?.type) {
    case UI_EVENT_TYPES.CREATED:
      return <JobEventActorFigure type={UI_EVENT_TYPES.CREATED} />;
    case UI_EVENT_TYPES.PENDING:
      return <JobEventActorFigure type={UI_EVENT_TYPES.PENDING} />;
    default:
      return <JobEventActorFigure type="NODE" />;
  }
}

function hasReachedTerminalState(state?: string | null): boolean {
  switch (state) {
    case "ACCEPTED":
    case "ASSIGNED":
    case "CANCELING":
    case "LIMITED":
    case "LIMITING":
    case "RUNNING":
    case "SCHEDULED":
    case "TIMING_OUT":
    case "WAITING":
      return false;
    default:
      return true;
  }
}

function JobActivityTimelineList({ job, relay }: Props) {
  React.useEffect(() => {
    const subscription = cable.subscriptions.create(
      { channel: "JobEventsChannel", uuid: job.uuid },
      {
        received: (data: any) => {
          if (data.changed) {
            relay.refetch(null, null, null, { force: true });
          }
        },
      },
    );

    return () => {
      subscription.unsubscribe();
    };
  }, [job ? job.uuid : null]);

  return (
    <div
      className="JobActivityTimeline pt2 pb2"
      data-testid="JobActivityTimelineComponent"
    >
      <ol
        className="JobActivityTimeline__list"
        data-testid="JobActivityTimelineComponentList"
      >
        {/*
        Render a virtual event that represents the creation event for the job. We don’t
        actually have an event in the DB for this, we just fake it using the createdAt of
        the Job itself. Fake it til’ ya make it!
        */}
        <li
          key="CREATED"
          className="JobActivityTimeline__list__item"
          data-testid="JobActivityTimelineComponentListItem"
        >
          <div className="flex">
            <EventBadge event={{ type: UI_EVENT_TYPES.CREATED }} />
            <div className="JobActivityTimeline__list__item__content flex-auto">
              <JobEventCreated job={job} />
            </div>
          </div>
        </li>

        {/* If we have some jobs... */}
        {job && job.events && job.events.edges
          ? // Render out our job records.!
            job.events.edges.map((edge, index) => {
              // Guard against weird nulls here.
              if (!edge || !edge.node) {
                return null;
              }

              const { node } = edge;

              // Is this event on that we support rendering on the client? If not then we’ll
              // just skip over it for now.
              if (!RENDERABLE_EVENT_TYPES.includes(node.type)) {
                return null;
              }

              // Fetch the previous event timestamp (if it exists) so that we can render
              // nice incremental time diffs between each edge.
              const previousEdge =
                job.events && job.events.edges && job.events.edges[index - 1];
              const previousTimestamp =
                previousEdge && previousEdge.node
                  ? previousEdge.node.timestamp
                  : job.createdAt;

              return (
                <li
                  key={node.uuid}
                  className="JobActivityTimeline__list__item"
                  data-testid="JobActivityTimelineComponentListItem"
                >
                  <div className="flex">
                    <EventBadge event={node} />
                    <div className="JobActivityTimeline__list__item__content flex-auto">
                      <EventContent
                        event={node}
                        // @ts-expect-error - TS2322 - Type '{ event: any; previousTimestamp: any; }' is not assignable to type 'IntrinsicAttributes & { event: unknown; }'.
                        previousTimestamp={previousTimestamp}
                      />
                    </div>
                  </div>
                </li>
              );
            })
          : null}

        {/*
        Unless the job has reached a terminal state we want to render an indeterminate progress
        spinner/indicator here to communicate that buildkite expects that things are still happening!
        */}
        {job && !hasReachedTerminalState(job.state) ? (
          <li key="WAITING" className="JobActivityTimeline__list__item">
            <div className="flex">
              <EventBadge event={{ type: UI_EVENT_TYPES.PENDING }} />
              <div className="JobActivityTimeline__list__item__content flex-auto dark-gray" />
            </div>
          </li>
        ) : null}
      </ol>
    </div>
  );
}

export default createRefetchContainer(
  JobActivityTimelineList,
  {
    job: graphql`
      fragment JobActivityTimelineList_job on Job {
        ... on JobTypeCommand {
          uuid
          state
          createdAt
          ...JobEventCreated_job

          events(first: 30) {
            edges {
              node {
                uuid
                ... on JobEvent {
                  timestamp
                  type

                  ...JobEventLimited_event
                  ...JobEventFinished_event
                  ...JobEventRetried_event
                  ...JobEventScheduled_event
                  ...JobEventAssigned_event
                  ...JobEventAssignedExpired_event
                  ...JobEventCanceled_event
                  ...JobEventCancelation_event
                  ...JobEventAccepted_event
                  ...JobEventAcceptedExpired_event
                  ...JobEventStarted_event
                  ...JobEventTimedOut_event
                  ...JobEventChanged_event
                  ...JobEventBuildStepUploadCreated_event
                  ...JobEventExpired_event
                  ...JobEventAgentStopped_event
                  ...JobEventAgentDisconnected_event
                  ...JobEventAgentLost_event
                  ...JobEventJobLogSizeExceeded_event
                  ...JobEventRetryFailed_event
                }
              }
            }
          }
        }
      }
    `,
  },
  graphql`
    query JobActivityTimelineListRefetchQuery($id: ID!) {
      job(uuid: $id) {
        ...JobActivityTimelineList_job
      }
    }
  `,
);

import * as React from "react";

type Props = {
  children: React.ReactNode;
};

const Row = (props: Props) => {
  const children = React.Children.toArray(props.children);

  // Filter out the actions from the rest of the children
  const nodes: Array<never> = [];
  let actions;
  children.forEach((child) => {
    // @ts-expect-error - TS2339 - Property 'type' does not exist on type 'ReactChild | ReactFragment | ReactPortal'. | TS2339 - Property 'type' does not exist on type 'ReactChild | ReactFragment | ReactPortal'.
    if (child.type && child.type.displayName === "Panel.RowActions") {
      actions = child;
    } else {
      // @ts-expect-error - TS2345 - Argument of type 'ReactChild | ReactFragment | ReactPortal' is not assignable to parameter of type 'never'.
      nodes.push(child);
    }
  });

  return (
    <div className="py2 px3 flex items-center">
      <div className="flex-auto">{nodes}</div>
      {actions}
    </div>
  );
};

Row.displayName = "Panel.Row";

export default Row;

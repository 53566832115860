import CreateBuildDialog from "app/components/pipeline/CreateBuildDialog";
import Button from "app/components/shared/Button";
import Dropdown from "app/components/shared/Dropdown";
import EmojiAvatar from "app/components/shared/EmojiAvatar";
import Emojify from "app/components/shared/Emojify";
import Icon from "app/components/shared/Icon";
import React from "react";
import styled from "styled-components";

import BuildsIndicator from "./BuildsIndicator";

import permissions from "app/lib/permissions";
import { repositoryProviderIcon } from "app/lib/repositories";
import withHistory, { LocationHistoryContext } from "app/lib/withHistory";
import { createFragmentContainer, graphql } from "react-relay";
import Badge from "app/components/shared/Badge";

const HeaderContent = styled.header`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
`;

type Props = {
  pipeline: {
    id: string;
    name: string;
    description: string | null | undefined;
    url: string;
    visibility: string;
    archived: boolean;
    emoji: string | null | undefined;
    color: string | null | undefined;
    organization: {
      slug: string;
      name: string;
      iconUrl: string | null | undefined;
    };
    repository: {
      url: string;
      provider: {
        __typename: string;
        url: string | null | undefined;
      };
    };
    permissions: {
      pipelineUpdate: {
        allowed: boolean;
        code: string | null | undefined;
        message: string | null | undefined;
      };
      buildCreate: {
        allowed: boolean;
        code: string | null | undefined;
        message: string | null | undefined;
      };
    };
  };
  build?: any;
  isCurrentOrganizationMember: boolean;
  isCurrentOrganizationAdmin: boolean;
  buildState?: string;
  branchName?: string;
  buildURL: string;
  legacySteps: boolean;
  location: {
    hash: string;
    pathname: string;
    search: string;
  };
  pipelineTemplates:
    | Array<{
        uuid: string;
        name: string;
        description: string | null | undefined;
      }>
    | null
    | undefined;
};

type State = {
  showingActionsDropdown: boolean;
  showingCreateBuildDialog: boolean;
};

class Header extends React.PureComponent<Props, State> {
  state = {
    showingActionsDropdown: false,
    showingCreateBuildDialog: false,
  };

  actionsDropdown: Dropdown | null | undefined;

  static contextType = LocationHistoryContext;

  UNSAFE_componentWillMount() {
    if (this.props.location.hash.split("?").shift() === "#new") {
      this.setState({
        showingCreateBuildDialog: true,
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    this.setState({
      showingCreateBuildDialog:
        nextProps.location.hash.split("?").shift() === "#new",
    });
  }

  render() {
    if (!this.props.pipeline) {
      return null;
    }

    const actions = this.getAvailableActions();

    return (
      <div data-testid="PipelineHeader">
        <HeaderContent className="mb4">
          <div className="flex items-center gap2">
            <EmojiAvatar
              emoji={this.props.pipeline.emoji}
              color={this.props.pipeline.color}
            />

            <div className="flex flex-column gap0.5 min-width-0">
              <div className="flex items-center gap0.5">
                <a
                  className="h3 line-height-3 black m0 semi-bold text-decoration-none truncate"
                  data-testid="PipelineUrl"
                  href={this.props.pipeline.url}
                >
                  <Emojify text={this.props.pipeline.name} />
                </a>

                {this.props.pipeline.visibility === "PUBLIC" && (
                  <Badge outline={true} className="very-dark-grey">
                    Public
                  </Badge>
                )}
              </div>

              {this.props.pipeline.description && (
                <Emojify
                  className="line-height-3 dark-gray m0 truncate"
                  text={this.props.pipeline.description}
                />
              )}
            </div>

            <div className="flex items-center gap2 ml-auto">
              {this.renderProviderBadge()}
              {this.renderDropdownForActions(actions)}
              {this.renderButtonsForActions(actions)}
            </div>
          </div>
        </HeaderContent>

        <CreateBuildDialog
          isOpen={this.state.showingCreateBuildDialog}
          onRequestClose={this.handleCreateBuildDialogClose}
          pipeline={this.props.pipeline}
          build={this.props.build}
          pipelineTemplates={
            this.props.isCurrentOrganizationAdmin
              ? this.props.pipelineTemplates
              : []
          }
        />
      </div>
    );
  }

  renderProviderBadge() {
    const uri = this.props.pipeline.repository.provider.url;

    if (!uri) {
      return null;
    }

    return (
      <a
        href={uri}
        className="flex flex-none items-center black line-height-0 text-decoration-none"
      >
        <Icon
          title={uri}
          icon={repositoryProviderIcon(
            this.props.pipeline.repository.provider.__typename,
          )}
        />
      </a>
    );
  }

  getAvailableActions() {
    if (!this.props.pipeline) {
      return [];
    }

    // NOTE: We "render" props here so we can show near-identical
    // action lists in the dropdown and header. `children` is assumed
    // to be any renderable thing. Have at it!
    return permissions(this.props.pipeline.permissions).collect(
      {
        allowed: "pipelineUpdate",
        render: () => ({
          key: "pipelineSettings",
          href: `${this.props.pipeline.url}/settings/pipeline`,
          children: "Settings",
        }),
      },
      {
        allowed: "buildCreate",
        render: () => ({
          key: "newBuild",
          onClick: this.handleBuildCreateClick,
          children: "New Build",
          theme: "primary",
          disabled: !!this.props.pipeline.archived,
        }),
      },
    );
  }

  renderDropdownForActions(actions) {
    const content = actions.map(({ key, ...action }) => (
      <Button
        key={key}
        className="btn black hover-lime focus-lime flex items-center flex-none semi-bold"
        {...action}
        // @ts-expect-error - TS2322 - Type 'null' is not assignable to type '"small" | "default" | "primary" | "danger" | "onboarding" | undefined'.
        theme={null}
      />
    ));

    return (
      <Dropdown
        className="md-hide lg-hide"
        width={200}
        ref={(actionsDropdown) => (this.actionsDropdown = actionsDropdown)}
        onToggle={this.handleActionsDropdownToggle}
      >
        <Button iconOnly={true}>
          <Icon
            icon="down-triangle"
            style={{ width: 7, height: 7 }}
            className="flex-none"
          />
          <span className="offscreen">Pipeline Actions</span>
        </Button>
        <a
          className="btn black hover-lime focus-lime flex items-center flex-none semi-bold"
          href={`${this.props.pipeline.url}/builds`}
        >
          <BuildsIndicator pipeline={this.props.pipeline} />
        </a>
        {content}
      </Dropdown>
    );
  }

  renderButtonsForActions(actions) {
    const content = actions.map(({ key, ...action }) => (
      <Button
        key={key}
        data-testid={key}
        className="flex items-center"
        {...action}
      />
    ));

    return <div className="flex xs-hide sm-hide gap1">{content}</div>;
  }

  renderDescription() {
    const { pipeline } = this.props;

    if (pipeline.description) {
      return <Emojify text={pipeline.description} />;
    }

    // Hide passwords and limit to 8 dots in repository URLs
    return pipeline.repository.url.replace(
      /:([^/@]{1,8})[^/@]*@/,
      (match, password) => `:${password.replace(/./g, "•")}@`,
    );
  }

  handleActionsDropdownToggle = (visible: any) => {
    this.setState({ showingActionsDropdown: visible });
  };

  handleBuildCreateClick = () => {
    if (this.state.showingActionsDropdown && this.actionsDropdown) {
      this.actionsDropdown.setShowing(false);
    }

    this.setState({ showingCreateBuildDialog: true });

    this.context.history.push(
      this.props.location.pathname + this.props.location.search + "#new",
    );
  };

  handleCreateBuildDialogClose = () => {
    this.setState({ showingCreateBuildDialog: false });

    this.context.history.push(
      this.props.location.pathname + this.props.location.search,
    );
  };
}

export default createFragmentContainer(withHistory(Header), {
  build: graphql`
    fragment Header_build on Build {
      ...CreateBuildDialog_build
    }
  `,
  pipeline: graphql`
    fragment Header_pipeline on Pipeline {
      ...CreateBuildDialog_pipeline
      ...BuildsIndicator_pipeline
      id
      name
      description
      url
      visibility
      archived
      emoji
      color
      organization {
        name
        slug
        iconUrl
      }
      repository {
        url
        provider {
          __typename
          url
        }
      }
      permissions {
        pipelineUpdate {
          allowed
          code
          message
        }
        buildCreate {
          allowed
          code
          message
        }
      }
    }
  `,
});

/**
 * @generated SignedSource<<b632a4ce4c41237c5d3d24852c8cc998>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TwoFactor_viewer$data = {
  readonly permissions: {
    readonly totpConfigure: {
      readonly allowed: boolean;
      readonly code: string | null;
      readonly message: string | null;
    };
  };
  readonly totp: {
    readonly id: string;
    readonly recoveryCodes: {
      readonly codes: ReadonlyArray<{
        readonly code: string;
        readonly consumed: boolean;
      }>;
      readonly " $fragmentSpreads": FragmentRefs<"RecoveryCodeList_recoveryCodes">;
    };
    readonly " $fragmentSpreads": FragmentRefs<"RecoveryCodes_totp">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"TwoFactorConfigure_viewer" | "TwoFactorDelete_viewer">;
  readonly " $fragmentType": "TwoFactor_viewer";
};
export type TwoFactor_viewer$key = {
  readonly " $data"?: TwoFactor_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"TwoFactor_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TwoFactor_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TwoFactorConfigure_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TwoFactorDelete_viewer"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ViewerPermissions",
      "kind": "LinkedField",
      "name": "permissions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Permission",
          "kind": "LinkedField",
          "name": "totpConfigure",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "allowed",
              "storageKey": null
            },
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "message",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TOTP",
      "kind": "LinkedField",
      "name": "totp",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "RecoveryCodes_totp"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "RecoveryCodeBatch",
          "kind": "LinkedField",
          "name": "recoveryCodes",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RecoveryCodeList_recoveryCodes"
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "RecoveryCode",
              "kind": "LinkedField",
              "name": "codes",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "consumed",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "e3c804b3372143e71e1440058361bc58";

export default node;

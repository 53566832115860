import SupportBanner from "../SupportBanner";

const ContentCypress = () => (
  <>
    <p>
      Before you start, make sure Cypress runs with access to{" "}
      <a
        className="purple"
        href="https://buildkite.com/docs/test-analytics/ci-environments"
      >
        CI environment variables
      </a>
      .
    </p>
    <p>Create a new branch:</p>

    <pre className="code">git checkout -b install-buildkite-test-analytics</pre>

    <p>
      Add the{" "}
      <a
        className="purple"
        href="https://www.npmjs.com/package/buildkite-test-collector"
      >
        buildkite-test-collector package
      </a>{" "}
      to your dev dependencies:
    </p>

    <pre className="code">
      {[
        "# If you use npm:",
        "npm install --save-dev buildkite-test-collector",
        "",
        "# If you use Yarn:",
        "yarn add --dev buildkite-test-collector",
      ].join("\n")}
    </pre>

    <p>
      Update your{" "}
      <a
        className="purple"
        href="https://docs.cypress.io/guides/references/configuration"
      >
        Cypress configuration
      </a>
    </p>
    <pre className="code">
      {[
        "// cypress.config.js",
        "",
        "// Send results to Test Analytics",
        'reporter: "buildkite-test-collector/cypress/reporter"',
      ].join("\n")}
    </pre>

    <p>
      Add the <code className="inline-block">BUILDKITE_ANALYTICS_TOKEN</code>{" "}
      secret to your CI environment, push your changes to a branch, and open a
      pull request 🎉
    </p>
    <pre className="code">
      {[
        "git add .",
        'git commit -m "Install and set up Buildkite Test Analytics"',
        "git push",
      ].join("\n")}
    </pre>

    <p>
      <em>Note:</em> To pass in the API token using a custom environment
      variable, add the `reporterOptions` option to your Cypress configuration:
    </p>
    <pre className="code">
      {[
        "// cypress.config.js",
        "",
        "// Send results to Test Analytics",
        'reporter: "buildkite-test-collector/cypress/reporter"',
        "reporterOptions: {",
        "  token_name: process.env.CUSTOM_ENV_VARIABLE",
        "}",
      ].join("\n")}
    </pre>

    <p>
      After completing these steps, you&apos;ll see the analytics of test
      executions on all branches that include this code in the Test Analytics
      dashboard.
    </p>

    <SupportBanner />
  </>
);

export default ContentCypress;

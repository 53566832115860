import * as React from "react";
import classNames from "classnames";
import { createFragmentContainer, graphql } from "react-relay";

const SIZES = {
  MICRO: 16,
  SMALL: 20,
  REGULAR: 26,
  LARGE: 32,
} as const;

export type JobPresenterUser = {
  name: string;
  avatarUrl: string;
};

export type User = {
  name: string | null | undefined;
  avatar: Readonly<{
    url: string;
  }>;
};

type Props = {
  user: Readonly<User>;
  size?: keyof typeof SIZES;
  className?: string;
  style?: any;
  suppressAltText?: boolean;
};

export default class UserAvatar extends React.PureComponent<Props> {
  // I'm so sorry, please delete this method as soon as you can
  static convertUserFromJobPresenter({
    name,
    avatarUrl,
  }: JobPresenterUser): User {
    return {
      name,
      avatar: {
        url: avatarUrl,
      },
    };
  }

  render() {
    const size = this.props.size ? SIZES[this.props.size] : null;
    const style = {
      ...this.props.style,
      height: size || this.props.style?.height,
      width: size || this.props.style?.width,
    } as const;

    return (
      <img
        src={this.props.user.avatar.url}
        className={classNames("circle", this.props.className)}
        // @ts-expect-error - TS2322 - Type 'string | null | undefined' is not assignable to type 'string | undefined'.
        alt={this.altText}
        // @ts-expect-error - TS2322 - Type 'string | null | undefined' is not assignable to type 'string | undefined'.
        title={this.altText}
        style={style}
      />
    );
  }

  get altText() {
    if (this.props.suppressAltText) {
      return "";
    }

    return this.props.user.name;
  }
}

export const UserAvatarRelay = createFragmentContainer(UserAvatar, {
  user: graphql`
    fragment UserAvatar_user on User {
      name
      avatar {
        url
      }
    }
  `,
});

import styled from "styled-components";
import Emojify from "app/components/shared/Emojify";
import CopyTextButton from "app/components/analytics/shared/CopyTextButton";
import { _trackTALinkClicked } from "app/components/analytics/shared/segmentEvents";

export type MetadataCardType = {
  run_name: string;
  run_url: string;
  test_name: string;
  test_url: string;
  location: string | null | undefined;
};

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--charcoal-700);
  font-family:
    SF Pro Text,
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Helvetica Neue,
    Helvetica,
    sans-serif;
  font-weight: 400;
  padding: 0 20px;
  border-radius: 3px;
  background: var(--base-0);

  div {
    border-bottom: 1px solid #e0e0e0;
  }

  div:last-child {
    border: 0;
  }
`;

const StyledRow = styled.div`
  display: grid;
  grid-template-columns: 100px auto auto;
  align-items: center;
  word-break: break-all;
  padding: 15px 0px;
  width: 100%;

  &.button-container-padding {
    padding: 10px 0px;
    .btn-default {
      padding: 5px 7px;
    }
  }
`;

const MetadataCard = ({
  run_url,
  run_name,
  test_name,
  test_url,
  location,
}: MetadataCardType) => (
  <HeaderContainer>
    <StyledRow>
      <span className="mr4 bold">Run</span>
      <span className="truncate">
        <a
          href={run_url}
          title={run_name}
          className="lime"
          onClick={() =>
            _trackTALinkClicked({
              linkTo: "run#show",
              linkTitle: run_name,
              url: run_url,
              source: "MetadataCard link",
            })
          }
        >
          <Emojify text={run_name} />
        </a>
      </span>
    </StyledRow>

    <StyledRow>
      <span className="mr4 bold">Test</span>
      <span className="truncate">
        <a
          href={test_url}
          title={test_name}
          className="lime"
          onClick={() =>
            _trackTALinkClicked({
              linkTo: "test#show",
              linkTitle: test_name,
              url: test_url,
              source: "MetadataCard link",
            })
          }
        >
          {test_name}
        </a>
      </span>
    </StyledRow>

    {location && (
      <StyledRow className="button-container-padding">
        <span className="mr4 bold">Location</span>
        <CopyTextButton text={location} className="monospace charcoal-700" />
      </StyledRow>
    )}
  </HeaderContainer>
);

export default MetadataCard;

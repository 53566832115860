import { createContext } from "react";

export type Organization = {
  uuid: string;
  slug: string;
  name: string;
};

interface Context {
  organization: Organization | null;
  setOrganization: (organization: Organization) => void;
}

export const OrganizationContext = createContext<Context>({
  organization: null,
  setOrganization: () => {
    return;
  },
});

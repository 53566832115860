/* eslint-disable react/display-name */

import { useState } from "react";

import Button from "app/components/shared/Button";
import Emojify from "app/components/shared/Emojify";
import Panel from "app/components/shared/Panel";
import permissions from "app/lib/permissions";
import FlashesStore from "app/stores/FlashesStore";
import AccessLevel from "./access-level";

const Row = ({ onAccessLevelChange, teamPipeline, onRemoveClick }) => {
  const [savingNewAccessLevel, setSavingNewAccessLevel] = useState(null);
  const [removing, setRemoving] = useState(null);

  const pipeline = teamPipeline.pipeline;

  const handleAccessLevelChange = (accessLevel) => {
    setSavingNewAccessLevel(accessLevel);

    onAccessLevelChange(teamPipeline, accessLevel, (error) => {
      setSavingNewAccessLevel(null);
      if (error) {
        FlashesStore.flash(FlashesStore.ERROR, error);
      }
    });
  };

  const handlePipelineRemove = (evt) => {
    if (confirm("Remove the pipeline from this team?")) {
      evt.preventDefault();

      performPipelineRemove(false);
    }
  };

  const performPipelineRemove = (force) => {
    setRemoving(true);

    onRemoveClick(teamPipeline, force, (error) => {
      setRemoving(false);

      if (error) {
        if (
          !force &&
          error.source &&
          error.source.type === "must_force_error"
        ) {
          if (
            confirm(
              error.source.errors[0].message +
                "\n\nAre you sure you want to remove this pipeline from this team?",
            )
          ) {
            performPipelineRemove(true);
          }
        } else {
          FlashesStore.flash(FlashesStore.ERROR, error);
        }
      }
    });
  };

  const Actions = () =>
    permissions(teamPipeline.permissions).collect(
      {
        always: true,
        render: (idx) => (
          <AccessLevel
            key={idx}
            teamPipeline={teamPipeline}
            onAccessLevelChange={handleAccessLevelChange}
            saving={savingNewAccessLevel}
          />
        ),
      },
      {
        allowed: "teamPipelineDelete",
        render: (idx) => (
          <Button
            key={idx}
            loading={removing ? "Removing…" : false}
            className="ml3"
            onClick={handlePipelineRemove}
          >
            Remove
          </Button>
        ),
      },
    );

  return (
    <Panel.Row>
      <div className="flex">
        <a
          className="truncate semi-bold black hover-lime hover-color-inherit-parent text-decoration-none"
          href={pipeline.url}
          title={pipeline.name}
        >
          <Emojify text={pipeline.name} />
          <br />
          <small
            className="truncate dark-gray block hover-color-inherit"
            title={pipeline.repository.url}
          >
            {pipeline.repository.url}
          </small>
        </a>
      </div>
      <Panel.RowActions className="ml2">
        <Actions />
      </Panel.RowActions>
    </Panel.Row>
  );
};

export default Row;

/**
 * @generated SignedSource<<8b09943e385044b2266999dfe0e7d83b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type PipelineAccessLevels = "BUILD_AND_READ" | "MANAGE_BUILD_AND_READ" | "READ_ONLY" | "%future added value";
export type TeamPipelineCreateInput = {
  accessLevel?: PipelineAccessLevels | null;
  clientMutationId?: string | null;
  pipelineID: string;
  teamID: string;
};
export type PipelinesChooserMutation$variables = {
  input: TeamPipelineCreateInput;
};
export type PipelinesChooserMutation$data = {
  readonly teamPipelineCreate: {
    readonly clientMutationId: string | null;
    readonly team: {
      readonly id: string;
      readonly pipelines: {
        readonly count: number;
      } | null;
    } | null;
  } | null;
};
export type PipelinesChooserMutation = {
  response: PipelinesChooserMutation$data;
  variables: PipelinesChooserMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "TeamPipelineCreatePayload",
    "kind": "LinkedField",
    "name": "teamPipelineCreate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Team",
        "kind": "LinkedField",
        "name": "team",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TeamPipelineConnection",
            "kind": "LinkedField",
            "name": "pipelines",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "count",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PipelinesChooserMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PipelinesChooserMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b8140f313207e55c8a9253aa6e459f55",
    "id": null,
    "metadata": {},
    "name": "PipelinesChooserMutation",
    "operationKind": "mutation",
    "text": "mutation PipelinesChooserMutation(\n  $input: TeamPipelineCreateInput!\n) {\n  teamPipelineCreate(input: $input) {\n    clientMutationId\n    team {\n      id\n      pipelines {\n        count\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "be8c905f7e5edc88e0c731e0e5810453";

export default node;

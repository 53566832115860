import { useState, useCallback } from "react";
import styled from "styled-components";

import Table from "app/components/analytics/span/Table";
import Timeline from "app/components/analytics/execution/Timeline";
import TabControl, { TabButton } from "app/components/analytics/shared/Tabs";

type Props = {
  slowest_sql_endpoint: string;
  slowest_http_endpoint: string;
  timeline_endpoint: string;
};

const TABS: { [key: string]: string } = {
  SQL_TAB: "sql-queries",
  HTTP_TAB: "http-requests",
  TIMELINE_TAB: "span-timeline",
};

const StyledTabControl = styled(TabControl)`
  margin-top: 40px;
  justify-content: center;
`;

const TableWrapper = styled.div`
  margin-top: 30px;
  margin-bottom: 50px;
`;

const Tabs = (props: Props) => {
  let anchor = window.location.hash.replace("#", "");

  if (Object.values(TABS).indexOf(anchor) === -1) {
    anchor = TABS.SQL_TAB;
  }

  const [activeTab, setActiveTab] = useState(anchor);
  const handleSQLTabClick = useCallback(() => setActiveTab(TABS.SQL_TAB), []);
  const handleHTTPTabClick = useCallback(() => setActiveTab(TABS.HTTP_TAB), []);
  const handleTimelineTabClick = useCallback(
    () => setActiveTab(TABS.TIMELINE_TAB),
    [],
  );

  return (
    <>
      <StyledTabControl>
        <TabButton
          active={activeTab === TABS.SQL_TAB}
          href={`#${TABS.SQL_TAB}`}
          onClick={handleSQLTabClick}
          icon="heroicons/outline/clock"
        >
          Slowest SQL queries
        </TabButton>

        <TabButton
          active={activeTab === TABS.HTTP_TAB}
          href={`#${TABS.HTTP_TAB}`}
          onClick={handleHTTPTabClick}
          icon="heroicons/outline/clock"
        >
          Slowest HTTP requests
        </TabButton>

        <TabButton
          active={activeTab === TABS.TIMELINE_TAB}
          href={`#${TABS.TIMELINE_TAB}`}
          onClick={handleTimelineTabClick}
          icon="heroicons/outline/clock"
        >
          Span timeline
        </TabButton>
      </StyledTabControl>

      <TableWrapper>
        {activeTab === TABS.SQL_TAB && (
          <Table url={props.slowest_sql_endpoint} />
        )}
        {activeTab === TABS.HTTP_TAB && (
          <Table url={props.slowest_http_endpoint} />
        )}
        {activeTab === TABS.TIMELINE_TAB && (
          <Timeline url={props.timeline_endpoint} backToTopButton={true} />
        )}
      </TableWrapper>
    </>
  );
};

export default Tabs;

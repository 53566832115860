import classNames from "classnames";
import "intersection-observer";
import React from "react";
import { InView } from "react-intersection-observer";

import { minute } from "metrick/duration";

import { getDateString, getRelativeDateString, DateLike } from "app/lib/date";

import UserSessionStore, {
  UserSessionEvent,
} from "app/stores/UserSessionStore";

type Props = {
  value: DateLike;
  updateFrequency: number;
  capitalized: boolean;
  seconds: boolean;
  fractionalSeconds: boolean;
  tabularNumerals: boolean;
  className?: string;
  clickable: boolean;
  hasPrecedingWord?: boolean; // handles dates that occur today or yesterday should not be capitalized
};

type State = {
  text: string;
  utc: boolean;
};

const USER_SESSION_STORE_TIMESTAMP_UTC_KEY = "timestamps-utc";

/**
 * Renders a friendly, human readable, self-updating representation of a timestamp,
 * as supplied via `value` and stored in `text`.
 */
export default class FriendlyTime extends React.PureComponent<Props, State> {
  static defaultProps = {
    updateFrequency: minute.bind(1),
    capitalized: true,
    seconds: false,
    fractionalSeconds: false,
    tabularNumerals: false,
    clickable: true,
    hasPrecedingWord: true,
  };

  // @ts-expect-error - TS2564 - Property '_timeout' has no initializer and is not definitely assigned in the constructor.
  _timeout: number;

  constructor(props: Props) {
    super(props);

    this.state = {
      text: "",
      utc:
        UserSessionStore.get(USER_SESSION_STORE_TIMESTAMP_UTC_KEY) === "true",
    };

    // NOTE: This is separate because it relies on the value of `this.state.utc`!
    // @ts-expect-error - TS2540 - Cannot assign to 'text' because it is a read-only property.
    this.state.text = this.dateString;
  }

  get dateString() {
    const { value, capitalized, seconds, fractionalSeconds } = this.props;

    return this.state.utc
      ? getDateString(value, {
          withSeconds: seconds,
          withFractionalSeconds: fractionalSeconds,
          utc: true,
        })
      : getRelativeDateString(value, {
          capitalized,
          seconds,
          fractionalSeconds,
        });
  }

  uncapitalizeDate() {
    const isRelativeDateString =
      this.dateString.includes("Yesterday") ||
      this.dateString.includes("Today");
    if (isRelativeDateString && this.props.hasPrecedingWord) {
      return this.dateString[0].toLowerCase() + this.dateString.slice(1);
    }
    return this.dateString;
  }

  tick() {
    const text = this.uncapitalizeDate();
    this.setState({ text }, this.maybeScheduleTick);
  }

  componentDidMount() {
    this.maybeScheduleTick();
    UserSessionStore.on("change", this.handleSessionDataChange);
  }

  maybeScheduleTick() {
    const { updateFrequency } = this.props;

    if (typeof updateFrequency == "number" && updateFrequency > 0) {
      this._timeout = setTimeout(() => {
        this.tick();
      }, updateFrequency);
    }
  }

  maybeCancelTick() {
    if (this._timeout) {
      clearTimeout(this._timeout);
    }
  }

  componentWillUnmount() {
    this.maybeCancelTick();
    UserSessionStore.off("change", this.handleSessionDataChange);
  }

  componentDidUpdate({ updateFrequency }: Props, { utc }: State) {
    if (utc !== this.state.utc) {
      this.maybeCancelTick();
      this.tick();
    } else if (updateFrequency !== this.props.updateFrequency) {
      this.maybeCancelTick();
      this.maybeScheduleTick();
    }
  }

  handleVisibilityChange = (visible: boolean) => {
    this.maybeCancelTick();

    if (visible) {
      this.tick();
    }
  };

  handleSessionDataChange = ({ key, newValue }: UserSessionEvent) => {
    if (key === USER_SESSION_STORE_TIMESTAMP_UTC_KEY) {
      this.setState({ utc: newValue === "true" });
    }
  };

  handleTimestampClick = (event: React.MouseEvent<HTMLTimeElement>) => {
    event.preventDefault();

    const utc = !this.state.utc;
    this.setState({ utc });

    // Store it so next time we default to that choice
    UserSessionStore.set(USER_SESSION_STORE_TIMESTAMP_UTC_KEY, utc.toString());
  };

  render() {
    const className = classNames(this.props.className, {
      // Show a pointer cursor if the component accepts clicks
      "cursor-pointer": this.props.clickable,
    });

    let title = getDateString(this.props.value, {
      withSeconds: true,
      utc: this.state.utc,
    });

    if (this.props.clickable) {
      // Append to the tooltip if the component accepts clicks
      title += "\n(Click to toggle between local and UTC)";
    }
    return (
      <InView
        as="time"
        className={className}
        // @ts-expect-error - TS2322 - Type 'DateLike' is not assignable to type 'string | undefined'.
        dateTime={this.props.value}
        title={title}
        onChange={this.handleVisibilityChange}
        onClick={this.props.clickable ? this.handleTimestampClick : undefined}
      >
        {this.props.tabularNumerals ? (
          <span className="tabular-numerals">{this.state.text}</span>
        ) : (
          this.state.text
        )}
      </InView>
    );
  }
}

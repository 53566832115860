import Database from "app/lib/Database";
import LegacyDispatcher from "app/lib/legacyDispatcher";
import BaseStore from "app/stores/BaseStore";

export type StepID = string | number;

export type StepReorderPosition = "before" | "after";

export type StepPartial = {
  name?: string;
  command?: string;
  branchConfiguration?: string;
  artifactPaths?: string;
  agentQueryRules?: Array<string>;
  env?: string;
  destroyed?: boolean;
  selected?: boolean;
  persisted?: boolean;
  order?: number;
  parallelJobs?: string;
  concurrency?: string;
  timeoutInMinutes?: string;
  type: "script" | "manual" | "waiter" | "trigger";
  triggerProjectSlug?: string;
  triggerAsync?: boolean;
  triggerMessage?: string;
  triggerCommit?: string;
  triggerBranch?: string;
};

export type Step = StepPartial & {
  id: StepID;
};

export type Agent = {
  id: string;
  name: string;
  version: string | null | undefined;
};

type Project = {
  steps: Array<Step>;
};

let STEP_IDS = 0;

export default class ProjectPipelineStore extends BaseStore {
  project: Project;

  constructor(project: Project) {
    // Call super on the parent store
    super();

    // Parse the project object
    this.project = Database.parse(project);

    // If cloning a project, some steps will come in with no ID's, so we'll use
    // the standard id generation gear that we use in #newStep to come up with
    // an ID
    for (const step of this.project.steps) {
      if (!step.id) {
        step.id = this.generateStepId();
      }
    }

    // Load the steps into a database
    // @ts-expect-error - TS2339 - Property 'database' does not exist on type 'ProjectPipelineStore'.
    this.database = new Database(this.project.steps);

    LegacyDispatcher.on("project_step:new", (data) => {
      this.newStep(data.attributes);
      this.emit("change");
    });

    LegacyDispatcher.on("project_step:select", (data) => {
      this.selectStep(data.step);
      this.emit("change");
    });

    LegacyDispatcher.on("project_step:clone", (data) => {
      this.copyStep(data.step);
      this.emit("change");
    });

    LegacyDispatcher.on("project_step:destroy", (data) => {
      this.destroyStep(data.step);
      this.emit("change");
    });

    LegacyDispatcher.on("project_step:change", (data) => {
      this.changeStep(data.step, data.attributes);
      this.emit("change");
    });

    LegacyDispatcher.on("project_step:sort", (data) => {
      this.reorderSteps(data.stepId, data.targetId, data.position);
      this.emit("change");
    });
  }

  orderedSteps() {
    // @ts-expect-error - TS2339 - Property 'database' does not exist on type 'ProjectPipelineStore'.
    return this.database
      .all()
      .sort((stepA, stepB) => stepA.order - stepB.order);
  }

  orderedStepsNotDestroyed() {
    return this.orderedSteps().filter((step) => !step.destroyed);
  }

  newStep(attributes: StepPartial) {
    const attrs = Object.assign({}, attributes);

    // Come up with a fake id
    // @ts-expect-error - TS2339 - Property 'id' does not exist on type 'StepPartial'.
    attrs.id = this.generateStepId();

    // Default to any agent
    if (attrs.agentQueryRules == null) {
      attrs.agentQueryRules = [""];
    }

    const notDestroyedSteps = this.orderedStepsNotDestroyed();

    const lastStep = notDestroyedSteps[notDestroyedSteps.length - 1];
    attrs.order = lastStep ? lastStep.order + 1 : 0;

    // @ts-expect-error - TS2339 - Property 'database' does not exist on type 'ProjectPipelineStore'.
    const step = this.database.load(attrs);

    return this.selectStep(step);
  }

  copyStep(attributes: Step) {
    const attrs = Object.assign({}, attributes);

    attrs.persisted = false;

    return this.newStep(attrs);
  }

  selectStep(step: Step) {
    if (step === this.selectedStep()) {
      return this.deselect();
    }

    this.deselect();

    if (step) {
      return (step.selected = true);
    }
  }

  destroyStep(step: Step) {
    const steps = this.orderedStepsNotDestroyed();
    const nextStep = steps[steps.indexOf(step) + 1];

    step.destroyed = true;

    if (nextStep) {
      return this.selectStep(nextStep);
    }

    const notDestroyedSteps = this.orderedStepsNotDestroyed();

    return this.selectStep(notDestroyedSteps[notDestroyedSteps.length - 1]);
  }

  deselect() {
    if (this.selectedStep()) {
      return (this.selectedStep().selected = false);
    }
  }

  changeStep(step: Step, attributes: Step) {
    const result: Array<any> = [];
    for (const key in attributes) {
      const value = attributes[key];
      result.push((step[key] = value));
    }
    return result;
  }

  reorderSteps(
    stepId: StepID,
    targetId: StepID,
    position?: StepReorderPosition | null,
  ) {
    // @ts-expect-error - TS2339 - Property 'database' does not exist on type 'ProjectPipelineStore'.
    const step = this.database.find(stepId);
    // @ts-expect-error - TS2339 - Property 'database' does not exist on type 'ProjectPipelineStore'.
    const target = this.database.find(targetId);

    if (position === "after") {
      return this.insertStepAfterStep(step, target);
    }

    return this.insertStepBeforeStep(step, target);
  }

  insertStepBeforeStep(step: Step, beforeStep: Step) {
    const steps = this.orderedSteps().filter(
      (orderedStep) => orderedStep !== step,
    );
    const index = steps.indexOf(beforeStep);
    steps.splice(index, 0, step);
    this.rebuildStepOrder(steps);
  }

  insertStepAfterStep(step: Step, afterStep: Step) {
    const steps = this.orderedSteps().filter(
      (orderedStep) => orderedStep !== step,
    );
    const index = steps.indexOf(afterStep);
    steps.splice(index + 1, 0, step);
    this.rebuildStepOrder(steps);
  }

  rebuildStepOrder(steps: Array<Step>) {
    steps.map((step, index) => (step.order = index));
  }

  generateStepId() {
    return `new-step-${STEP_IDS++}`;
  }

  selectedStep() {
    return this.orderedStepsNotDestroyed().find((step) => step.selected);
  }
}

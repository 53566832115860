import EventEmitter from "eventemitter3";
import "whatwg-fetch";

// UserSessionStore is exposed as a singleton which works with User::Session::Store
// remove and set are synced with the backend which you can access through User::Session::Store
// Note: If the user clears their local storage it will stay on the server and not replace local values. So using UserSessionStore.get in javascript land after clearing your local storage will retrieve nothing, but User::Session::Store in ruby land will still have the last saved value
// You can listen to change events so you can re-render on the fly:
// UserSessionStore.on('change', handleSessionDataChange);

// When adding something new to the User::Session::Store you need to add a validation regex otherwise it won't work!

export type UserSessionEvent = {
  key: string;
  newValue: string | null | undefined;
  oldValue: string | null | undefined;
};

const LOCALSTORAGE_PREFIX = "UserSessionStore:";

const keyWithPrefix = (key = "") => `${LOCALSTORAGE_PREFIX}${key}`;
const keySansPrefix = (prefixedKey: "$key" | "$value" | "length" | string) => {
  if (prefixedKey.indexOf(LOCALSTORAGE_PREFIX) !== 0) {
    throw new Error("keySansPrefix called with invalid key");
  }
  return prefixedKey.slice(LOCALSTORAGE_PREFIX.length);
};

const HTTP = (method: string, url: string, body: string) =>
  fetch(url, {
    method,
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": window._csrf.token,
      "X-Buildkite-Frontend-Version": BUILDKITE_FRONTEND_VERSION,
    },
    body,
  });

class UserSessionStore extends EventEmitter {
  constructor() {
    super();

    // NOTE: Storage events are *only* sent to tabs/windows which did
    // not initiate the change! This tripped me up for half an hour. 🙄
    window.addEventListener("storage", this.handleStorageEvent, false);
  }

  // This takes a "real" storage event, detects if it's one of ours,
  // and if so, emits a message with a consistent façade
  handleStorageEvent = ({
    key: prefixedKey,
    oldValue,
    newValue,
  }: StorageEvent) => {
    if (prefixedKey && prefixedKey.indexOf(keyWithPrefix()) === 0) {
      this.emitMessage(keySansPrefix(prefixedKey), oldValue, newValue);
    }
  };

  get(key: string) {
    return localStorage.getItem(keyWithPrefix(key));
  }

  set(key: string, value: string) {
    const prefixedKey = keyWithPrefix(key);

    this.emitMessage(key, localStorage.getItem(prefixedKey), value);

    localStorage.setItem(prefixedKey, value);
    HTTP("POST", "/_session/store", JSON.stringify({ [key]: value }));
  }

  remove(key: string) {
    const prefixedKey = keyWithPrefix(key);

    this.emitMessage(key, localStorage.getItem(prefixedKey), null);

    localStorage.removeItem(prefixedKey);
    HTTP("DELETE", "/_session/store", JSON.stringify([key]));
  }

  clear() {
    // clear UserSessionStore-managed localStorage items
    Object.keys(localStorage)
      .filter((key) => key.indexOf(LOCALSTORAGE_PREFIX) === 0)
      .forEach((key) => {
        localStorage.removeItem(key);

        // We need an event per item, as we're mirroring the
        // StorageEvent API and it doesn't handle multi-item
        // events
        // @ts-expect-error - TS2339 - Property 'sendVirtualEvent' does not exist on type 'UserSessionStore'.
        this.sendVirtualEvent(
          keySansPrefix(key),
          localStorage.getItem(key),
          null,
        );
      });
  }

  emitMessage(key: string, oldValue?: string | null, newValue?: string | null) {
    // We detect if the value has meaningfully changed
    // as sometimes we get multiple events for the same
    // change. This smooths it out.
    if (oldValue === newValue) {
      return;
    }

    // This should be the only place `emit` is called
    // by UserSessionStore!
    this.emit("change", {
      key,
      oldValue,
      newValue,
    } as UserSessionEvent);
  }
}

export default new UserSessionStore();

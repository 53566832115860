import * as React from "react";
import classNames from "classnames";

import defaultAvatar from "app/images/avatar_default.png";

type Organization = {
  readonly name: string | null | undefined;
  readonly iconUrl: string | null | undefined;
};

type Props = {
  readonly organization: Organization;
  className?: string;
  style?: any;
  suppressAltText?: boolean;
};

export default class OrganizationAvatar extends React.PureComponent<Props> {
  render() {
    return (
      <img
        src={this.props.organization.iconUrl || defaultAvatar}
        className={classNames("rounded-relative", this.props.className)}
        // @ts-expect-error - TS2322 - Type 'string | null | undefined' is not assignable to type 'string | undefined'.
        alt={this.altText}
        // @ts-expect-error - TS2322 - Type 'string | null | undefined' is not assignable to type 'string | undefined'.
        title={this.altText}
        style={this.props.style}
      />
    );
  }

  get altText() {
    // return "";
    if (this.props.suppressAltText) {
      return "";
    }

    return this.props.organization.name;
  }
}

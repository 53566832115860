import { Component } from "react";
import { createFragmentContainer, graphql } from "react-relay";
import Metric from "./Metric";

type Props = {
  pipeline: any;
};

class Metrics extends Component<Props> {
  get metricsEdges() {
    if (this.props.pipeline.metrics && this.props.pipeline.metrics.edges) {
      return this.props.pipeline.metrics.edges;
    }
    return [];
  }

  render() {
    return (
      <div className="flex items-center">
        {this.metricsEdges.map((metricsEdge) =>
          metricsEdge && metricsEdge.node ? (
            <Metric key={metricsEdge.node.label} metric={metricsEdge.node} />
          ) : null,
        )}
      </div>
    );
  }
}

export default createFragmentContainer(Metrics, {
  pipeline: graphql`
    fragment Metrics_pipeline on Pipeline {
      metrics(first: 6) {
        edges {
          node {
            label
            ...Metric_metric
          }
        }
      }
    }
  `,
});

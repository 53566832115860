import { Link } from "react-router-dom";
import { ReactNode, useMemo } from "react";
import { Build } from "app/components/Playground/BuildContext";
import { twMerge } from "tailwind-merge";

type Props = Pick<Build, "annotationCountsByStyle" | "path">;

export const AnnotationsLink = ({ annotationCountsByStyle, path }: Props) => {
  const annotationsCount = useMemo(() => {
    const errors = annotationCountsByStyle.error || 0;
    const warnings = annotationCountsByStyle.warning || 0;
    const totalCount = Object.values(annotationCountsByStyle).reduce(
      (acc, count) => acc + count,
      0,
    );

    return {
      errors,
      warnings,
      others: totalCount - warnings - errors,
      totalCount,
    };
  }, [annotationCountsByStyle]);

  if (annotationsCount.totalCount === 0) {
    return null;
  }

  return (
    <div>
      <Link
        to={`${path}/summary/annotations`}
        className={twMerge(
          "inline box-decoration-clone text-current py-1.5 px-2 hover:text-current focus:text-current active:text-current hover:no-underline focus:no-underline",
          annotationsCount.others > 0 && "bg-blue-100",
          annotationsCount.warnings > 0 && "bg-orange-100",
          annotationsCount.errors > 0 && "bg-red-100",
        )}
      >
        <AnnotationsLabel {...annotationsCount} />
      </Link>
    </div>
  );
};

// Summarize the number of annotations in a build.
//
// e.g.
//   Build has 1 error, 2 warning, and 3 other annotations
//   Build has 1 error and 2 warning annotations
//   Build has 1 error annotation
//   Build has 10 annotations
//
export function AnnotationsLabel({ errors = 0, warnings = 0, others = 0 }) {
  const parts: ReactNode[] = [];
  if (errors > 0) {
    parts.push(
      <span className="underline decoration-dotted">
        {errors} {errors === 1 ? "error" : "errors"}
      </span>,
    );
  }

  if (warnings > 0) {
    parts.push(
      <span className="underline decoration-dotted">
        {warnings} {warnings === 1 ? "warning" : "warnings"}
      </span>,
    );
  }

  if (others > 0) {
    if (parts.length === 0) {
      parts.push(
        <span className="underline decoration-dotted">
          {others} {others === 1 ? "annotation" : "annotations"}
        </span>,
      );
    } else {
      parts.push(
        <>
          <span className="underline decoration-dotted">{others} other</span>
          {others === 1 ? " annotation" : " annotations"}
        </>,
      );
    }
  }

  const counts: ReactNode = parts.reduce((acc, part, index) => {
    if (index === 0) {
      return part;
    }

    if (parts.length === 2 && parts.length - 1) {
      return (
        <>
          {acc} and {part}
        </>
      );
    }

    if (index === parts.length - 1) {
      return (
        <>
          {acc}, and {part}
        </>
      );
    }

    return (
      <>
        {acc}, {part}
      </>
    );
  }, null);

  return <span data-testid="annotations-label">Build has {counts}</span>;
}

/**
 * @generated SignedSource<<549eca97221092b0f7e6bf4609983df2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type JobEventActorType = "AGENT" | "DISPATCH" | "SYSTEM" | "USER" | "%future added value";
export type JobEventSignalReason = "AGENT_REFUSED" | "AGENT_STOP" | "CANCEL" | "PROCESS_RUN_ERROR" | "SIGNATURE_REJECTED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type JobEventFinished_event$data = {
  readonly actor: {
    readonly type: JobEventActorType;
  };
  readonly exitStatus?: any;
  readonly job: {
    readonly agent: {
      readonly heartbeatAt: any | null;
      readonly " $fragmentSpreads": FragmentRefs<"AgentBadge_agent">;
    } | null;
    readonly exitStatus: string | null;
    readonly softFailed: boolean;
  };
  readonly signal?: string | null;
  readonly signalReason?: JobEventSignalReason | null;
  readonly timestamp: any;
  readonly " $fragmentType": "JobEventFinished_event";
};
export type JobEventFinished_event$key = {
  readonly " $data"?: JobEventFinished_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"JobEventFinished_event">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "exitStatus",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "JobEventFinished_event",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timestamp",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "signal",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "signalReason",
          "storageKey": null
        }
      ],
      "type": "JobEventFinished",
      "abstractKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "JobEventActor",
      "kind": "LinkedField",
      "name": "actor",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "JobTypeCommand",
      "kind": "LinkedField",
      "name": "job",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "softFailed",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Agent",
          "kind": "LinkedField",
          "name": "agent",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "AgentBadge_agent"
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "heartbeatAt",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "JobEvent",
  "abstractKey": "__isJobEvent"
};
})();

(node as any).hash = "aae364289967f591e7371492f1a048e5";

export default node;

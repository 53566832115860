/**
 * @generated SignedSource<<697642b04677bf5c69eceea3d56bc9d3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type TOTPRecoveryCodesRegenerateInput = {
  clientMutationId?: string | null;
  totpId: string;
};
export type RecoveryCodesRegenerateMutation$variables = {
  input: TOTPRecoveryCodesRegenerateInput;
};
export type RecoveryCodesRegenerateMutation$data = {
  readonly totpRecoveryCodesRegenerate: {
    readonly totp: {
      readonly id: string;
      readonly recoveryCodes: {
        readonly codes: ReadonlyArray<{
          readonly code: string;
          readonly consumed: boolean;
        }>;
        readonly id: string;
      };
    };
  } | null;
};
export type RecoveryCodesRegenerateMutation = {
  response: RecoveryCodesRegenerateMutation$data;
  variables: RecoveryCodesRegenerateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "TOTPRecoveryCodesRegeneratePayload",
    "kind": "LinkedField",
    "name": "totpRecoveryCodesRegenerate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TOTP",
        "kind": "LinkedField",
        "name": "totp",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "RecoveryCodeBatch",
            "kind": "LinkedField",
            "name": "recoveryCodes",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "RecoveryCode",
                "kind": "LinkedField",
                "name": "codes",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "code",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "consumed",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RecoveryCodesRegenerateMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RecoveryCodesRegenerateMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "fc6e54b7d87e5f84d300f2fabb303fab",
    "id": null,
    "metadata": {},
    "name": "RecoveryCodesRegenerateMutation",
    "operationKind": "mutation",
    "text": "mutation RecoveryCodesRegenerateMutation(\n  $input: TOTPRecoveryCodesRegenerateInput!\n) {\n  totpRecoveryCodesRegenerate(input: $input) {\n    totp {\n      id\n      recoveryCodes {\n        id\n        codes {\n          code\n          consumed\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9092fee398f3adae3c100f404fdfd339";

export default node;

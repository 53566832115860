import styled, { StyledComponent } from "styled-components";

import Icon from "app/components/shared/Icon";

type Props = {
  style?: any;
  className?: string;
  icon?: string;
  title?: string;
  revealed?: boolean;
  strokeWidth?: number;
};

// @ts-expect-error - TS2322 - Type 'StyledComponent<(props: Props) => Element, any, { icon: "chevron-right"; revealed: boolean; size: number; strokeWidth: number; }, "size" | "strokeWidth" | "icon" | "revealed">' is not assignable to type 'StyledComponent<Props, never, (props: Props) => Element, never>'. | TS2344 - Type 'Props' does not satisfy the constraint 'keyof IntrinsicElements | ComponentType<any>'.
const RevealableDownChevron: StyledComponent<Props, never, typeof Icon> =
  styled(Icon).attrs({
    icon: "chevron-right",
    revealed: false,
    size: 14,
    strokeWidth: 2,
  })`
    transform: rotate(${(props) => (props.revealed ? -90 : 90)}deg);
    trasform-origin: center 0;
    transition: transform 150ms;
    will-change: transform;
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;

    polyline {
      stroke-width: ${(props) => props.strokeWidth}px;
    }
  `;

export default RevealableDownChevron;

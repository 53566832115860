export enum Product {
  Pipelines = "pipelines",
  TestAnalytics = "test_analytics",
  Packages = "packages",
}

export enum Goal {
  Security = "security",
  Performance = "performance",
  Flexibility = "flexibility",
  ReduceCosts = "reduce_costs",
  UnblockDevelopers = "unblock_developers",
  ImproveTestQuality = "improve_test_quality",
  NewTools = "new_tools",
  Other = "other",
}

export enum Vendor {
  Buildkite = "buildkite",
  CircleCI = "circle_ci",
  GithubActions = "github_actions",
  Harness = "harness",
  Jenkins = "jenkins",
  TeamCity = "team_city",
  GitlabCI = "gitlab",
  BitbucketPipelines = "bitbucket_pipelines",
  Semaphore = "semaphore",
  Other = "other",
}

export enum Role {
  Platformer = "platformer",
  Architect = "architect",
  Creator = "creator",
  Consumer = "consumer",
  TestChampion = "test_champion",
  Other = "other",
}

export interface Form {
  product: Product | null;
  goals: Goal[];
  otherGoals?: string;
  vendors: Vendor[];
  otherVendors?: string;
  role: Role | null;
  otherRole?: string;
}

/**
 * @generated SignedSource<<af1ebc3aa015365a555eeaaf54806cd8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Console_graphQLSnippet$data = {
  readonly operationName: string | null;
  readonly query: string;
  readonly url: string;
  readonly " $fragmentType": "Console_graphQLSnippet";
};
export type Console_graphQLSnippet$key = {
  readonly " $data"?: Console_graphQLSnippet$data;
  readonly " $fragmentSpreads": FragmentRefs<"Console_graphQLSnippet">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Console_graphQLSnippet",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "query",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "operationName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "type": "GraphQLSnippet",
  "abstractKey": null
};

(node as any).hash = "323e463a6de36870aa06fc0126344af5";

export default node;

/**
 * @generated SignedSource<<614882b6a0e3a132a7d89f20eca9f146>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BuildStates = "BLOCKED" | "CANCELED" | "CANCELING" | "CREATING" | "FAILED" | "FAILING" | "NOT_RUN" | "PASSED" | "RUNNING" | "SCHEDULED" | "SKIPPED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type Graph_pipeline$data = {
  readonly builds?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly canceledAt: any | null;
        readonly finishedAt: any | null;
        readonly id: string;
        readonly scheduledAt: any | null;
        readonly startedAt: any | null;
        readonly state: BuildStates;
        readonly url: string;
        readonly " $fragmentSpreads": FragmentRefs<"Bar_build">;
      } | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "Graph_pipeline";
};
export type Graph_pipeline$key = {
  readonly " $data"?: Graph_pipeline$data;
  readonly " $fragmentSpreads": FragmentRefs<"Graph_pipeline">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "includeGraphData"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "Graph_pipeline",
  "selections": [
    {
      "condition": "includeGraphData",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "branch",
              "value": "%default"
            },
            {
              "kind": "Literal",
              "name": "first",
              "value": 30
            },
            {
              "kind": "Literal",
              "name": "state",
              "value": [
                "SCHEDULED",
                "RUNNING",
                "PASSED",
                "FAILED",
                "FAILING",
                "CANCELED",
                "CANCELING",
                "BLOCKED"
              ]
            }
          ],
          "concreteType": "BuildConnection",
          "kind": "LinkedField",
          "name": "builds",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "BuildEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Build",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "id",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "state",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "url",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "startedAt",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "finishedAt",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "canceledAt",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "scheduledAt",
                      "storageKey": null
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "Bar_build"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "builds(branch:\"%default\",first:30,state:[\"SCHEDULED\",\"RUNNING\",\"PASSED\",\"FAILED\",\"FAILING\",\"CANCELED\",\"CANCELING\",\"BLOCKED\"])"
        }
      ]
    }
  ],
  "type": "Pipeline",
  "abstractKey": null
};

(node as any).hash = "dec69775dbf755a1080778e268d2b342";

export default node;

/* global jQuery */
import EventEmitter from "eventemitter3";

const _info = {};

const CHANGE_EVENT = "change";

class JobInformationStore extends EventEmitter {
  emitChange() {
    return this.emit(CHANGE_EVENT);
  }

  addChangeListener(callback) {
    return this.addListener(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    return this.removeListener(CHANGE_EVENT, callback);
  }

  hasChangeListeners() {
    return this.getListeners(CHANGE_EVENT).length !== 0;
  }

  get(jobID, key) {
    const jobCache = _info[jobID];
    return jobCache != null ? jobCache[key] : undefined;
  }

  set(jobID, key, value) {
    const jobCache = _info[jobID] || (_info[jobID] = {});
    jobCache[key] = value;
    return this.emitChange();
  }

  delete(jobID, key = null) {
    if (key) {
      if (_info[jobID] != null) {
        _info[jobID][key] = null;
      }
    } else {
      _info[jobID] = null;
    }
    return this.emitChange();
  }

  refetch(jobID, key, url) {
    return jQuery.ajax({
      url,
      headers: { "X-Buildkite-Frontend-Version": BUILDKITE_FRONTEND_VERSION },
      success: (data) => {
        this.set(jobID, key, data);
        return this.emitChange();
      },
    });
  }
}

export default new JobInformationStore();

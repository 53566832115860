/* eslint-disable react/display-name */
import { useState } from "react";
import { createFragmentContainer, graphql } from "react-relay";

import Badge from "app/components/shared/Badge";
import Button from "app/components/shared/Button";
import Emojify from "app/components/shared/Emojify";
import Panel from "app/components/shared/Panel";
import UserAvatar from "app/components/shared/UserAvatar";

import permissions from "app/lib/permissions";
import FlashesStore from "app/stores/FlashesStore";

import OrganizationMemberSSOModeConstants, {
  OrganizationMemberSSOModes,
} from "app/constants/OrganizationMemberSSOModeConstants";

import Role from "./role";

const AVATAR_SIZE = 40;

type TeamMember = {
  user: any;
  role: string;
  permissions: {
    teamMemberUpdate:
      | {
          allowed: boolean;
        }
      | null
      | undefined;
    teamMemberDelete:
      | {
          allowed: boolean;
        }
      | null
      | undefined;
  };
  team: {
    organization: {
      slug: string;
    };
  };
  organizationMember: {
    uuid: string;
    security?: {
      twoFactorEnabled: boolean;
    };
    sso?: {
      mode: OrganizationMemberSSOModes;
    };
  };
};
type Props = {
  teamMember: TeamMember;
  onRemoveClick: (
    member: TeamMember,
    callback: (error?: string | null | undefined) => void,
  ) => void;
  onRoleChange: (
    member: TeamMember,
    role: string,
    callback: (error?: string | null | undefined) => void,
  ) => void;
};

const Row = ({ teamMember, onRemoveClick, onRoleChange }: Props) => {
  const [removing, setRemoving] = useState(false);
  const [savingNewRole, setSavingNewRole] = useState(null);

  const renderLabels = () => {
    const nodes: Array<React.ReactElement<React.ComponentProps<"div">>> = [];

    if (teamMember.user.bot) {
      nodes.push(
        <div key="bot" className="flex ml1">
          <Badge outline={true} className="regular">
            Bot <Emojify text=":robot_face:" />
          </Badge>
        </div>,
      );
    }

    if (
      teamMember.organizationMember.sso &&
      teamMember.organizationMember.sso.mode ===
        OrganizationMemberSSOModeConstants.OPTIONAL
    ) {
      nodes.push(
        <div key="sso" className="flex ml1">
          <Badge outline={true} className="regular">
            SSO Optional
          </Badge>
        </div>,
      );
    }

    if (
      teamMember.organizationMember.security &&
      teamMember.organizationMember.security.twoFactorEnabled
    ) {
      nodes.push(
        <div key="2fa" className="flex ml1">
          <Badge
            // @ts-expect-error - TS2769 - No overload matches this call.
            title="Two-Factor authentication is configured."
            outline={true}
            className="border-lime lime"
          >
            2FA
          </Badge>
        </div>,
      );
    }

    return nodes;
  };

  const renderActions = () =>
    permissions(teamMember.permissions).collect(
      {
        always: true,
        render: (idx) => (
          <Role
            key={idx}
            teamMember={teamMember}
            onRoleChange={handleRoleChange}
            savingNewRole={savingNewRole}
            teamMemberUpdatePermission={
              teamMember.permissions && teamMember.permissions.teamMemberUpdate
            }
          />
        ),
      },
      {
        allowed: "teamMemberDelete",
        render: (idx) => (
          <Button
            key={idx}
            // @ts-expect-error - TS2322 - Type 'false | "Removing…"' is not assignable to type 'boolean | undefined'.
            loading={removing ? "Removing…" : false}
            className="ml3"
            onClick={handleMemberRemove}
          >
            Remove
          </Button>
        ),
      },
    );

  const handleRoleChange = (role: any) => {
    setSavingNewRole(role);

    onRoleChange(teamMember, role, (error) => {
      setSavingNewRole(null);
      if (error) {
        // @ts-expect-error - TS2339 - Property 'ERROR' does not exist on type 'FlashesStore'.
        FlashesStore.flash(FlashesStore.ERROR, error);
      }
    });
  };

  const handleMemberRemove = (evt: any) => {
    if (confirm("Remove this user from the team?")) {
      evt.preventDefault();
      setRemoving(true);

      onRemoveClick(teamMember, (error) => {
        setRemoving(false);

        if (error) {
          // @ts-expect-error - TS2339 - Property 'ERROR' does not exist on type 'FlashesStore'.
          FlashesStore.flash(FlashesStore.ERROR, error);
        }
      });
    }
  };

  return (
    <Panel.Row>
      <div className="flex">
        <a
          className="truncate semi-bold black hover-lime hover-color-inherit-parent text-decoration-none"
          href={`/organizations/${teamMember.team.organization.slug}/users/${teamMember.organizationMember.uuid}`}
        >
          <div className="flex flex-stretch items-center">
            <div className="flex flex-none mr2">
              <UserAvatar
                user={teamMember.user}
                className="align-middle"
                style={{ width: AVATAR_SIZE, height: AVATAR_SIZE }}
                suppressAltText={true}
              />
            </div>
            <div className="flex-auto">
              <div className="m0 flex items-center semi-bold">
                {teamMember.user ? teamMember.user.name : ""}
                {renderLabels()}
              </div>
              {teamMember.user ? (
                <div className="h6 regular mt1">{teamMember.user.email}</div>
              ) : (
                ""
              )}
            </div>
          </div>
        </a>
      </div>
      <Panel.RowActions className="ml2">{renderActions()}</Panel.RowActions>
    </Panel.Row>
  );
};

export default createFragmentContainer(Row, {
  teamMember: graphql`
    fragment row_teamMember on TeamMember
    @argumentDefinitions(includeSensitiveInfo: { type: "Boolean!" }) {
      id
      user {
        name
        email
        avatar {
          url
        }
        bot
      }
      role
      team {
        organization {
          slug
        }
      }
      permissions {
        teamMemberUpdate {
          allowed
        }
        teamMemberDelete {
          allowed
        }
      }
      organizationMember {
        uuid
        sso @include(if: $includeSensitiveInfo) {
          mode
        }
        security @include(if: $includeSensitiveInfo) {
          twoFactorEnabled
        }
      }
    }
  `,
});

import React, { Suspense, useEffect } from "react";
import { useBuild } from "app/components/Playground/BuildContext";
import { isTerminalBuildState } from "app/constants/BuildStates";
import NotEnoughData from "../components/Waterfall/EmptyStates/NotEnoughData";
import UpgadeRequired from "../components/Waterfall/EmptyStates/UpgadeRequired";
import IncompatibleBuild from "../components/Waterfall/EmptyStates/IncompatibleBuild";
import {
  Build,
  WaterfallData,
  WaterfallRowType,
} from "app/stores/BuildShowStore";
import {
  Await,
  defer,
  LoaderFunctionArgs,
  useLoaderData,
  useRevalidator,
} from "react-router-dom";
import Skeleton from "../components/Waterfall/EmptyStates/Skeleton";
import classNames from "classnames";
import { DrawerOutlet } from "../components/DrawerOutlet";
import WaterfallRowLabel from "../components/Waterfall/WaterfallRowLabel";
import WaterfallRowChart from "../components/Waterfall/WaterfallRowChart";

export function loader({ params }: LoaderFunctionArgs) {
  const { org, pipeline, number } = params;
  const waterfallDataUrl = `/${org}/${pipeline}/builds/${number}/waterfall_tab.json`;

  const response = fetch(waterfallDataUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": window._csrf.token,
      "X-Buildkite-Frontend-Version": BUILDKITE_FRONTEND_VERSION,
    },
  }).then((res) => res.json());

  return defer({ waterfallData: response });
}

function MissingData({ build }: { build: Build }) {
  if (isTerminalBuildState(build.state)) {
    return <IncompatibleBuild />;
  }
  return <NotEnoughData />;
}

export type FlattenedWaterfallRow = Omit<WaterfallRowType, "children">;

// Flatten the data for easier access. Convert from a tree structure to a flat list
function flattenChartChildren(chartData: WaterfallData["chart_data"]) {
  return chartData.reduce((acc, row) => {
    const { children, ...rest } = row;
    acc.push(rest);
    if (children.length > 0) {
      acc.push(...flattenChartChildren(children));
    }
    return acc;
  }, [] as FlattenedWaterfallRow[]);
}

export default function WaterfallPage() {
  const data = useLoaderData() as { waterfallData: WaterfallData };
  const revalidator = useRevalidator();
  const { build, store } = useBuild();

  if (!store || !build) {
    throw new Error("Missing build context");
  }

  // Re-fetch the loader data when the build changes
  useEffect(() => {
    revalidator.revalidate();
  }, [build]);

  // Need to upgrade to see the waterfall
  if (!store.waterfallAvailable) {
    return <UpgadeRequired />;
  }

  return (
    <>
      <div className="flex-auto relative">
        <div
          className={classNames(
            "flex w-full h-full",
            Features.BuildSidebar ? "absolute overflow-y-auto" : "",
          )}
        >
          <Suspense fallback={<Skeleton />}>
            <Await resolve={data.waterfallData}>
              {(waterfallData: WaterfallData) => {
                return waterfallData.chart_data.length > 0 ? (
                  <div className="flex-1">
                    <section
                      className={classNames(
                        "relative w-full overflow-clip flex divide-x divide-gray-400",
                        Features.BuildSidebar
                          ? ""
                          : "border border-gray-400 rounded-lg",
                      )}
                    >
                      {/*
                    We seperate out rendering labels from rendering bars so that we can
                    make use of CSS divide-y to create borders between labels and child labels
                  */}
                      <div className="col-3 bg-silver m-0 p-0 divide-y divide-gray-400">
                        {waterfallData.chart_data.map((row) => {
                          const uniqueKey = [
                            "label",
                            row.step_uuid,
                            row.job_uuid,
                          ];
                          return (
                            <WaterfallRowLabel
                              key={uniqueKey.join("-")}
                              data={row}
                            />
                          );
                        })}
                      </div>
                      <div className="col-9 bg-white m-0 p-0 divide-y divide-gray-400">
                        {flattenChartChildren(waterfallData.chart_data).map(
                          (row) => {
                            const uniqueKey = [
                              "chart",
                              row.step_uuid,
                              row.job_uuid,
                            ];
                            return (
                              <WaterfallRowChart
                                key={uniqueKey.join("-")}
                                bar_container_padding={
                                  waterfallData.bar_container_padding
                                }
                                data={row}
                              />
                            );
                          },
                        )}
                      </div>
                    </section>
                  </div>
                ) : (
                  <MissingData build={build} />
                );
              }}
            </Await>
          </Suspense>
        </div>
      </div>
      <DrawerOutlet />
    </>
  );
}

import UserAvatar from "app/components/shared/UserAvatar";

interface Props {
  authorName?: string;
  authorAvatar?: string;
}

export function Avatar({ authorAvatar, authorName }: Props) {
  if (!authorName) {
    return null;
  }

  const user = {
    name: authorName,
    avatar: { url: authorAvatar || "" },
  };

  return (
    <div className="build-author-avatar" style={{ width: 32 + 8 }}>
      <UserAvatar user={user} style={{ width: 32, height: 32 }} />
    </div>
  );
}

import { useBuild } from "app/components/Playground/BuildContext";
import { Link, useParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { StepRouteParams } from "../../..";
import classNames from "classnames";

/**
 * A link to a step (with optional job ID).
 */
export const StepLink = ({
  uuid,
  className,
  children,
  onClick,
}: {
  children: React.ReactNode;
  uuid: string;
  className?: string;
  onClick?: () => void;
}) => {
  const { build } = useBuild();
  const { stepOrJobId } = useParams<StepRouteParams>();
  const path = `${build?.path}/canvas/${uuid}`;

  return (
    <Link
      onClick={onClick}
      key={uuid}
      to={path}
      className={twMerge(
        "flex flex-auto items-baseline text-charcoal-700 no-underline hover:no-underline active:no-underline focus:no-underline hover:bg-gray-200 rounded-md hover:text-inherit focus:text-inherit",
        classNames({
          "bg-gray-200": uuid === stepOrJobId,
        }),
        className,
      )}
    >
      {children}
    </Link>
  );
};

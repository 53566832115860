import { useFetch } from "use-http";

interface CreateOrganizationResponse {
  error?: string;
  organization: {
    uuid: string;
    name: string;
    slug: string;
  };
}

interface CreateOrganizationInput {
  name: string;
}

// Used to track where the user came from when they created an organization
function getSource() {
  return new URLSearchParams(window.location.search).get("source");
}

const useCreateOrganization = () => {
  const { post, ...fetch } = useFetch<CreateOrganizationResponse>({
    credentials: "same-origin",
    headers: {
      Accept: "application/json",
      "X-CSRF-Token": window._csrf.token,
    },
  });

  return {
    ...fetch,
    createOrganization: (input: CreateOrganizationInput) => {
      return post(`/organizations`, {
        ...input,
        source: getSource(),
      });
    },
  };
};

export default useCreateOrganization;

/**
 * @generated SignedSource<<f0caf125915cb18a3a3cc5dc51ff232f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type JobStates = "ACCEPTED" | "ASSIGNED" | "BLOCKED" | "BLOCKED_FAILED" | "BROKEN" | "CANCELED" | "CANCELING" | "EXPIRED" | "FINISHED" | "LIMITED" | "LIMITING" | "PENDING" | "RUNNING" | "SCHEDULED" | "SKIPPED" | "TIMED_OUT" | "TIMING_OUT" | "UNBLOCKED" | "UNBLOCKED_FAILED" | "WAITING" | "WAITING_FAILED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AgentRow_agent$data = {
  readonly clusterQueue: {
    readonly cluster: {
      readonly name: string;
      readonly uuid: string;
    } | null;
    readonly key: string;
    readonly uuid: string;
  } | null;
  readonly hostname: string | null;
  readonly id: string;
  readonly isRunningJob: boolean;
  readonly job: {
    readonly state?: JobStates;
    readonly " $fragmentSpreads": FragmentRefs<"JobLink_job">;
  } | null;
  readonly metaData: ReadonlyArray<string> | null;
  readonly name: string;
  readonly organization: {
    readonly slug: string;
  } | null;
  readonly public: boolean;
  readonly uuid: string;
  readonly version: string | null;
  readonly versionHasKnownIssues: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"AgentStateIcon_agent">;
  readonly " $fragmentType": "AgentRow_agent";
};
export type AgentRow_agent$key = {
  readonly " $data"?: AgentRow_agent$data;
  readonly " $fragmentSpreads": FragmentRefs<"AgentRow_agent">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AgentRow_agent",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AgentStateIcon_agent"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hostname",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "metaData",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ClusterQueue",
      "kind": "LinkedField",
      "name": "clusterQueue",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "key",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Cluster",
          "kind": "LinkedField",
          "name": "cluster",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "public",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isRunningJob",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "job",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "state",
              "storageKey": null
            }
          ],
          "type": "JobTypeCommand",
          "abstractKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "JobLink_job"
        }
      ],
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "version",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "versionHasKnownIssues",
      "storageKey": null
    }
  ],
  "type": "Agent",
  "abstractKey": null
};
})();

(node as any).hash = "8790b0ec952bf13e2832c363a23eb153";

export default node;

import window from "global/window";
import Bugsnag from "app/lib/Bugsnag";
import moment from "moment";
import "moment-duration-format";
import round from "lodash/round";

export type DateLike = moment.Moment | string | number | Date | Array<number>;

// Detect whether to use 12-hour time by querying the
// user's locale's time formatting preferences
const shouldUse12HourTime = (() => {
  if (window.Intl && window.Intl.DateTimeFormat) {
    try {
      const userLocaleFormatter = new window.Intl.DateTimeFormat(
        // For whatever reason, Chrome lies about the 'default' locale
        // (for instance, my computer, set to en-AU in Chrome's options,
        // will use en-GB, which has totally different time formatting),
        // so instead we feed in the user's specified language, if present.
        window.navigator.language || "default",
        {
          // We need to specify this to have the `hour12` property appear
          hour: "numeric",
        },
      );

      // Some browsers, like Android's, don't support the `resolvedOptions` method
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/DateTimeFormat/resolvedOptions#Browser_compatibility
      if (userLocaleFormatter.resolvedOptions) {
        return userLocaleFormatter.resolvedOptions().hour12;
      }
    } catch (err: any) {
      // Just in case, we're adding error catching here because not
      // doing so would mean a (completely) broken app, and I don’t
      // know what errors are possible here.
      //
      // It’s plausible that there are languages the browser can report
      // which aren’t supported by the Intl API, but none of the ones I
      // can come up with do.
      //
      // If errors come from here, we should see them,
      // and then correctly handle and address them!
      Bugsnag.notify(err);
    }
  }

  // Fall back to our original preferences for time formatting
  return true;
})();

// gets the clock time formatter
const getTimeFormatter = (
  withSeconds = false,
  withFractionalSeconds = false,
  twelveHourTime = shouldUse12HourTime,
) =>
  `${twelveHourTime ? "h" : "HH"}:mm${
    withSeconds || withFractionalSeconds
      ? `:ss${withFractionalSeconds ? ".SSS" : ""}`
      : ""
  }${twelveHourTime ? " A" : ""}`;

// gets the calendar date formatter
const getDateFormatter = (
  withSeconds = false,
  withFractionalSeconds = false,
  withYear = true,
  twelveHourTime = shouldUse12HourTime,
) =>
  `ddd Do MMM${withYear ? " YYYY" : ""} [at] ${getTimeFormatter(
    withSeconds,
    withFractionalSeconds,
    twelveHourTime,
  )}`;

// gets the computer date formtter
const getComputerDateFormatter = () => `YYYY-MM-DD HH:mm:ss`;

type RelativeDateOptions = {
  seconds?: boolean;
  fractionalSeconds?: boolean;
  inPast?: boolean;
  capitalized?: boolean;
};

// This is a bit of a hack...
//
// technically there's a *private* property which contains
// the configured CalendarFormats data, but it's not public:
// https://github.com/moment/moment/blob/a0f3c74374b638b769d9ab1ae54ba5266ef0976f/moment.js#L434
// We mess with the type information a bit here to enable
// "safely" using it despite being private.
interface MomentLocaleDataPrivate extends moment.Locale {
  _calendar: Record<moment.CalendarKey, string>;
}

// gets a friendly, relative date string, optionally with seconds,
// and optionally with relative date names in lowercase
//
// For example:
//   "Today at 12:03 PM"
//   "yesterday at 11:01 AM"
//   "Wed 13 Nov at 1:00 AM"
//   "Fri 1 Jan 2012 at 4:02 PM"
export function getRelativeDateString(
  time: DateLike,
  options: RelativeDateOptions = {},
) {
  const localeData = moment.localeData() as MomentLocaleDataPrivate;
  const formats = Object.assign({}, localeData._calendar);
  const timeFormat = getTimeFormatter(
    options.seconds,
    options.fractionalSeconds,
  );

  if (!options.inPast && typeof formats.lastWeek === "string") {
    formats.lastWeek = formats.lastWeek.replace("[Last] ", "");
    formats.nextWeek = `[Next] ${formats.lastWeek}`;
  }

  // @ts-expect-error - TS2322 - Type '(date: any) => string' is not assignable to type 'string'.
  formats.sameElse = function (date: any) {
    return getDateFormatter(
      options.seconds,
      options.fractionalSeconds,
      // @ts-expect-error - TS2349 - This expression is not callable.
      this.year() !== moment(date).year(),
    );
  };

  if (!options.capitalized) {
    Object.keys(formats).forEach((calendarString) => {
      if (typeof formats[calendarString] === "string") {
        formats[calendarString] = formats[calendarString]
          .replace(/\[[^\]]+\]/g, (replacement) => replacement.toLowerCase())
          .replace("LT", timeFormat);
      }
    });
  } else {
    Object.keys(formats).forEach((calendarString) => {
      if (typeof formats[calendarString] === "string") {
        formats[calendarString] = formats[calendarString].replace(
          "LT",
          timeFormat,
        );
      }
    });
  }

  return moment(time).calendar(moment(), formats);
}

// gets a version of the date suitable for viewing next to logs
export function getComputerDateString(date: DateLike) {
  return moment(date).format(getComputerDateFormatter());
}

export type DateOptions = {
  withSeconds?: boolean;
  withFractionalSeconds?: boolean;
  withYear?: boolean;
  utc?: boolean;
};

// gets an absolute date string, optionally with seconds
export function getDateString(
  date: DateLike,
  {
    withSeconds = false,
    withFractionalSeconds = false,
    withYear = true,
    utc = false,
  }: DateOptions = {},
) {
  // Turn off twelve-hour time explicitly if we're in UTC mode
  const twelveHourTime = utc ? false : undefined;

  const formatter = getDateFormatter(
    withSeconds,
    withFractionalSeconds,
    withYear,
    twelveHourTime,
  );

  date = moment(date);

  if (utc) {
    return date.utc().format(formatter) + " UTC";
  }

  return date.format(formatter);
}

/**
 * Calculates the duration between two datetimes.
 * @param {DateLike} from - the start of the duration
 * @param {DateLike} [to] - the end of the duration
 * @returns the resulting duration. Negative durations are clamped to zero.
 */
export function getDuration(
  from?: DateLike | null,
  to: DateLike | null = moment(),
) {
  if (!to || !from) {
    return moment.duration(0);
  }

  const duration = moment.duration(moment(to).diff(moment(from)));

  // Clamping negative durations to zero might be a bit weird, but it avoids accidentally
  // calculating a negative duration when the `from` value is higher precision than the `to` value
  // e.g. when `from` and `to` are for the same second in time, but `from` includes non-zero ms and `to` excludes ms (evaluating to zero ms)
  return duration.milliseconds() < 0 ? moment.duration(0) : duration;
}

const LONG_DURATION_STRING =
  "w [weeks], d [days], h [hours], m [minutes], s [seconds]";
const SHORT_DURATION_STRING = "w[w] d[d] h[h] m[m] s[s]";
const MICRO_DURATION_STRING = "w[w] d[d] h[h] m[m] s[s] S[ms]";

type DurationFormatOptions = {
  largest: number;
  trim: boolean | "both";
};

const DURATION_FORMATS: Record<string, [string, DurationFormatOptions]> = {
  full: [LONG_DURATION_STRING, { largest: 3, trim: false }],
  medium: [LONG_DURATION_STRING, { largest: 1, trim: false }],
  short: [SHORT_DURATION_STRING, { largest: 2, trim: false }],
  micro: [MICRO_DURATION_STRING, { largest: 1, trim: false }],
};

interface Duration extends moment.Duration {
  format: (template: string, options: DurationFormatOptions) => string;
}

export function getDurationString(
  seconds: number,
  format: keyof typeof DURATION_FORMATS = "full",
) {
  if (getDurationString.formats.indexOf(format) === -1) {
    throw new Error(`getDurationString: Unknown format \`${format}\`.`);
  }

  const [template, options] = DURATION_FORMATS[format];
  return (moment.duration(seconds, "seconds") as Duration).format(
    template,
    options,
  );
}

// This method is a JavaScript port of the Ruby Analytics::HumanDuration class

export function getMicrosecondDurationFormattedString(
  durationInMicroseconds: number,
) {
  const PRECISION = 2;

  // @ts-expect-error - TS2345 - Argument of type 'number' is not assignable to parameter of type 'string'.
  const microseconds = parseInt(durationInMicroseconds) || 0; // imitating behaviour of #to_i for null or NaN results
  const milliseconds = microseconds / 1000.0;
  const seconds = microseconds / 1000000.0;
  const minutes = Math.floor(seconds / 60.0);
  const hours = Math.floor(seconds / 3600.0);

  const isWholeUnit = (time: number) => time % 1 === 0;

  if (hours >= 1) {
    const remainderMinutes = minutes % (hours * 60);
    return round(remainderMinutes) === 0
      ? `${hours}h`
      : `${hours}h ${Math.floor(remainderMinutes)}m`;
  } else if (minutes >= 1) {
    const remainderSeconds = seconds % (minutes * 60);
    return round(remainderSeconds) === 0
      ? `${minutes}m`
      : `${minutes}m ${Math.floor(remainderSeconds)}s`;
  } else if (seconds >= 1) {
    const roundedSeconds = round(seconds, PRECISION);
    return isWholeUnit(roundedSeconds)
      ? `${Math.floor(roundedSeconds)}s`
      : `${roundedSeconds}s`;
  } else if (milliseconds >= 1) {
    const roundedMilliseconds = round(milliseconds, PRECISION);
    return isWholeUnit(roundedMilliseconds)
      ? `${Math.floor(roundedMilliseconds)}ms`
      : `${roundedMilliseconds}ms`;
  }
  return `${Math.floor(microseconds)}μs`;
}

getDurationString.formats = Object.keys(DURATION_FORMATS);

let cachedLocalTimezoneAbbr;

// Returns a version of the timezone like AEST, AWST, UTC, etc. If it can't
// accuratly figure it out, it'll just return null.
export function getLocalTimezoneAbbr() {
  if (cachedLocalTimezoneAbbr) {
    return cachedLocalTimezoneAbbr;
  }

  const dateString = new Date().toString();
  let abbr: RegExpMatchArray | string | null =
    // Works for the majority of modern browsers
    dateString.match(/\(([^)]+)\)$/) ||
    // IE outputs date strings in a different format:
    dateString.match(/([A-Z]+) [\d]{4}$/);

  if (abbr) {
    // Old Firefox uses the long timezone name (e.g., "Central
    // Daylight Time" instead of "CDT")
    abbr = abbr[1].match(/[A-Z]/g);
    if (abbr) {
      abbr = abbr.join("");
    }
  }

  return abbr;
}

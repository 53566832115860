/* global jQuery */

import classNames from "classnames";
import createReactClass from "create-react-class";
import PropTypes from "prop-types";

import { getRelativeDateString } from "app/lib/date";
import { track } from "app/lib/segmentAnalytics";
import BuildPageStore from "app/stores/BuildPageStore";
import Button from "app/components/shared/Button";
import Dialog from "app/components/shared/Dialog";
import Icon from "app/components/shared/Icon";
import JobActivityTimeline from "app/components/JobActivityTimeline";
import LegacyDispatcher from "app/lib/legacyDispatcher";
import parseEmoji from "app/lib/parseEmoji";
import PipelineStateIcon from "app/components/shared/PipelineStateIcon";
import RemoteButtonComponent from "app/components/shared/RemoteButtonComponent";
import UserAvatar from "app/components/shared/UserAvatar";

import Command from "./Command";
import JobArtifacts from "./Artifacts";
import JobArtifactsCount from "./ArtifactsCount";
import JobEnvironment from "./Environment";
import JobLog from "./Log";
import Agent from "./Agent";
import ClusterQueue from "./ClusterQueue";
import Signature from "./Signature";
import { MissingDependenciesNode } from "app/components/build/Show/components/MissingDependenciesNode";
import { MissingStepDependencies } from "app/components/job/Job/MissingStepDependencies";
import { JobDuration } from "app/components/job/Job/JobDuration";
import { LimitedByConcurrencyGroup } from "app/components/job/Job/LimitedByConcurrencyGroup";
import TestAnalytics from "./TestAnalytics";
import TestAnalyticsIndicator from "./TestAnalyticsIndicator";
import Packages from "./Packages";
import { isTerminalBuildState } from "app/constants/BuildStates";
import { twMerge } from "tailwind-merge";

export const elementIdFor = (jobId) => `job-${jobId}`;

export const TERMINAL_JOB_STATES = [
  "finished",
  "timed_out",
  "canceled",
  "expired",
];

/* eslint-disable react/prefer-es6-class */
// TODO: Move to a class

export default createReactClass({
  displayName: "Job",

  propTypes: {
    autoFollow: PropTypes.bool,
    className: PropTypes.string,
    build: PropTypes.object.isRequired,
    buildStore: PropTypes.shape({
      loadAndEmit: PropTypes.func.isRequired,
      reload: PropTypes.func.isRequired,
    }).isRequired,
    job: PropTypes.shape({
      id: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      passed: PropTypes.bool.isRequired,
      softFailed: PropTypes.bool.isRequired,
      command: PropTypes.string.isRequired,
      name: PropTypes.string,
      basePath: PropTypes.string.isRequired,
      agentName: PropTypes.string,
      parallelGroupTotal: PropTypes.number,
      parallelGroupIndex: PropTypes.number,
      matrix: PropTypes.string,
      cancelable: PropTypes.shape({
        allowed: PropTypes.bool.isRequired,
      }),
      retryable: PropTypes.shape({
        allowed: PropTypes.bool.isRequired,
        message: PropTypes.string,
      }).isRequired,
      retriedInJobUuid: PropTypes.string,
      retriedBy: PropTypes.shape({
        name: PropTypes.string.isRequired,
        avatarUrl: PropTypes.string.isRequired,
      }),
      step: PropTypes.shape({
        signature: PropTypes.shape({
          value: PropTypes.string,
          algorithm: PropTypes.string,
          signedFields: PropTypes.arrayOf(PropTypes.string),
        }),
      }),
      createdAt: PropTypes.string,
      startedAt: PropTypes.string,
      agentAssignedAt: PropTypes.string,
      agentAcceptedAt: PropTypes.string,
      retriedAt: PropTypes.string,
      runnableAt: PropTypes.string,
      canceledAt: PropTypes.string,
      finishedAt: PropTypes.string,
      timedOutAt: PropTypes.string,
      expiredAt: PropTypes.string,
      agentsPath: PropTypes.string,
      hostedAgentSshPath: PropTypes.string,
      permissions: PropTypes.shape({
        retry: PropTypes.shape({
          allowed: PropTypes.bool.isRequired,
          message: PropTypes.string,
          reason: PropTypes.string,
        }).isRequired,
        cancel: PropTypes.shape({
          allowed: PropTypes.bool.isRequired,
          message: PropTypes.string,
          reason: PropTypes.string,
        }).isRequired,
      }).isRequired,
      testAnalytics: PropTypes.shape({
        runUrl: PropTypes.string,
        failedExecutionsUrl: PropTypes.string,
      }).isRequired,
      packages: PropTypes.oneOfType([
        PropTypes.shape({
          indexUrl: PropTypes.string,
        }),
        PropTypes.instanceOf(null),
      ]),
      limitingJobs: PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.shape({
            uuid: PropTypes.string,
            webUrl: PropTypes.string,
            state: PropTypes.string,
            name: PropTypes.string,
            pipelineName: PropTypes.string,
            buildNumber: PropTypes.number,
            currentStateSince: PropTypes.string,
          }),
          PropTypes.instanceOf(null),
        ]),
      ),
      missingDependencies: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
  },

  tabs() {
    const { testAnalytics, packages } = this.props.job;
    const showTestsTab = testAnalytics.failedExecutionsUrl;

    const showPackagesTab =
      window.Features.PackagesPipelinesBuildJobTab && packages?.indexUrl;

    return [
      {
        id: "output",
        name: "Log",
        component: JobLog,
      },
      showTestsTab && {
        id: "tests",
        name: "Tests",
        new: true,
        indicator: TestAnalyticsIndicator,
        component: TestAnalytics,
      },
      showPackagesTab && {
        id: "packages",
        name: "Packages",
        new: true,
        component: Packages,
      },
      {
        id: "artifacts",
        name: "Artifacts",
        count: JobArtifactsCount,
        component: JobArtifacts,
      },
      {
        id: "timeline",
        name: "Timeline",
        component: JobActivityTimeline,
      },
      {
        id: "env",
        name: "Environment",
        component: JobEnvironment,
      },
    ].filter(Boolean);
  },

  notifyStoreOfRender() {
    if (this.state.expanded) {
      // Trigger the action in the next execution thread because there's a high
      // chance that this method was called as part of a dispatch and we don't
      // want to cause a loop
      setTimeout(
        () =>
          LegacyDispatcher.emit("job:expanded", {
            job: this.props.job,
            el: this.wrapperNode,
          }),
        0,
      );
    }
  },

  getInitialState() {
    return {
      tab: "output",
      expanded: this.suggestedExpandedState(),
      cancelJobDialogOpen: false,
    };
  },

  // Some magic to determine the default expanded state for the job.
  suggestedExpandedState() {
    return BuildPageStore.isJobExpanded(this.props.job);
  },

  componentDidMount() {
    BuildPageStore.addChangeListener(this.props.job, this._onStoreChange);
    this.notifyStoreOfRender();
  },

  componentWillUnmount() {
    BuildPageStore.removeChangeListener(this.props.job, this._onStoreChange);
  },

  componentDidUpdate() {
    this.notifyStoreOfRender();
  },

  _onStoreChange() {
    const expanded = BuildPageStore.isJobExpanded(this.props.job);

    // If we're changing from expanded to not exapnded, and the wrapping
    // element is outside the viewport (meaning the header is likely stickied
    // to the top of it), scroll to the top of the element so it stays in view
    // when it collapses. This is for Sticky Job Headers, but shouldn't change
    // much of note about the page's behaviour when that isn't enabled.
    if (
      this.state.expanded &&
      !expanded &&
      this.wrapperNode.offsetTop < window.scrollY
    ) {
      this.wrapperNode.scrollIntoView();
    }

    this.setState({ expanded });
  },

  scrollToAndExpandJob() {
    this.setState({ expanded: true }, () => {
      this.scrollToJob();
    });
  },

  scrollToJob() {
    const $anchor = jQuery(this.wrapperNode);
    jQuery(window).scrollTop($anchor.offset().top);
  },

  handleCancelJobClick() {
    this.setState({ cancelJobDialogOpen: true });
  },

  handleCancelJobDialogClose() {
    this.setState({ cancelJobDialogOpen: false });
  },

  handleTabClick(tab) {
    return (event) => {
      this.setState({ tab: tab.id });

      // If the browser viewport is below the top of the job component, scroll back to the top of the job component.
      if (this.wrapperNode.offsetTop < window.scrollY) {
        this.wrapperNode.scrollIntoView();
      }

      event.preventDefault();

      const startedAt = this.props.job.startedAt
        ? new Date(this.props.job.startedAt)
        : new Date();
      const timeSinceJobStarted = new Date() - startedAt;
      const daysSinceJobStarted = Math.floor(
        timeSinceJobStarted / 1000 / 60 / 60 / 24,
      );

      track("Job Tab Clicked", {
        tab_name: tab.name,
        job_id: this.props.job.id,
        job_state: this.props.job.state,
        days_since_job_started: daysSinceJobStarted,
      });
    };
  },

  handleClickHeader(evt) {
    if (Features.BuildSidebar) {
      return;
    }

    window.location.href =
      window.location.pathname +
      `#${this.state.expanded ? "_" : this.props.job.id}`;

    // return if the user did a middle-click, right-click, or used a modifier
    // key (like ctrl-click, meta-click, shift-click, etc.)
    if (
      (evt.button && evt.button !== 0) ||
      evt.altKey ||
      evt.ctrlKey ||
      evt.metaKey ||
      evt.shiftKey
    ) {
      return;
    }

    LegacyDispatcher.emit("job:toggle", { job: this.props.job });
  },

  handleClickRetriedJobLink(evt) {
    // return if the user did a middle-click, right-click, or used a modifier
    // key (like ctrl-click, meta-click, shift-click, etc.)
    if (
      evt.button !== 0 ||
      evt.altKey ||
      evt.ctrlKey ||
      evt.metaKey ||
      evt.shiftKey
    ) {
      return;
    }

    LegacyDispatcher.emit("job:retried-link-click", { job: this.props.job });
  },

  tabMenuNodes() {
    return this.tabs().map((tab) => {
      let className = "btn btn-tab btn-tab--compact btn-tab--monochrome";

      if (this.state.tab === tab.id) {
        className += " btn-tab--active";
      }

      if (tab.count) {
        className += " btn-tab--badged";
      }

      if (tab.indicator) {
        className += " relative";
      }

      return (
        <li key={tab.id} className="flex items-stretch">
          {this._renderTabLink(tab, className)}
        </li>
      );
    });
  },

  _renderTabLink(tab, className) {
    const TabCountComponent = tab.count;
    const TabIndicatorComponent = tab.indicator;

    return (
      <button className={className} onClick={this.handleTabClick(tab)}>
        {tab.name}
        {TabCountComponent && <TabCountComponent job={this.props.job} />}
        {tab.new && this._renderNewBadge()}
        {tab.indicator && <TabIndicatorComponent job={this.props.job} />}
      </button>
    );
  },

  _renderNewBadge() {
    return (
      <span className="badge badge--outline ml1 tabular-numerals">New</span>
    );
  },

  tabContentNodes() {
    return this.tabs().map((tab) => {
      let className = "tab-pane";

      if (this.state.tab === tab.id) {
        className += " active";
        const contents = (
          <tab.component
            build={this.props.build}
            job={this.props.job}
            autoFollow={this.props.autoFollow}
            parentElementId={this.elementId()}
          />
        );
        return (
          <div key={tab.id} className={className}>
            {contents}
          </div>
        );
      }
    });
  },

  groupInfoNode() {
    let text = null;

    if (this.props.job.matrix) {
      text = "Matrix";
    } else if (
      this.props.job.parallelGroupTotal &&
      this.props.job.parallelGroupTotal > 1
    ) {
      text = `${this.props.job.parallelGroupIndex + 1}/${
        this.props.job.parallelGroupTotal
      }`;
    }

    if (text) {
      return (
        <div
          className="flex-none flex items-center small relative tabular-numerals px1 job-group-count"
          style={{ lineHeight: 1.75 }}
        >
          {text}
        </div>
      );
    }
  },

  commandNode() {
    if (this.props.job.command) {
      return (
        <Command
          command={this.props.job.command}
          onNestedLinkTrigger={this.handleNestedLinkTrigger}
        />
      );
    }
  },

  jobNameNode() {
    if (this.props.job.name) {
      return (
        <span
          onClick={
            window.Features.BuildsHighlightJobName
              ? this.handleClickNode
              : undefined
          }
          className="truncate job-name"
          style={{ flex: "0 0 auto" }}
          data-testid="JobName"
          dangerouslySetInnerHTML={{ __html: parseEmoji(this.props.job.name) }}
        />
      );
    }
  },

  signatureNode() {
    if (this.props.job.step.signature) {
      return (
        <Signature
          algorithm={this.props.job.step.signature.algorithm}
          onNestedLinkTrigger={this.handleNestedLinkTrigger}
        />
      );
    }
  },

  elementId() {
    return elementIdFor(this.props.job.id);
  },

  renderTestAnalyticsButton() {
    const { runUrl } = this.props.job.testAnalytics;
    // These flags are only set for authenticated users with TA
    const runLinkEnabled = Features.Analytics;

    // runUrl returns as null when the run suite is not public, the job doesn't have an
    // associated run, or if the associated run didn't have the job id sent in the run env
    if (
      runUrl &&
      (runLinkEnabled || !Features.AnalyticsPublicSuiteEmergencyStop)
    ) {
      return (
        <Button
          href={runUrl}
          style={{ fontSize: 13, height: 34, lineHeight: "13px" }}
        >
          View in Test Engine
        </Button>
      );
    }
  },

  renderSSHButton() {
    const running = this.props.job.state === "running";
    const hostedAgentSSHPath = this.props.job.hostedAgentSshPath;

    if (running && hostedAgentSSHPath) {
      return (
        <Button
          href={hostedAgentSSHPath}
          style={{ fontSize: 13, height: 34, lineHeight: "13px" }}
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center"
        >
          <i
            className="fa fa-terminal white bg-black bg-rounded small rounded mr1"
            style={{ padding: "2px 4px" }}
          />{" "}
          Open Terminal{" "}
          <i
            className="fa fa-external-link ml1 dark-gray small"
            style={{ position: "relative", top: "1px" }}
          />
        </Button>
      );
    }
  },

  renderRetryJobButton() {
    // Before attempting to render the button, we'll see if we've already
    // retried this job, and if we have, render who did it and when.
    let retryNotAllowedMessage;
    if (this.props.job.retriedInJobUuid) {
      const linkContents = this.props.job.retriedBy ? (
        <>
          <UserAvatar
            user={{
              name: this.props.job.retriedBy.name,
              avatar: { url: this.props.job.retriedBy.avatarUrl },
            }}
            className="mx1"
            style={{
              width: 20,
              height: 20,
            }}
            suppressAltText={true}
          />
          <span>
            <span className="semi-bold">{this.props.job.retriedBy.name}</span>
            &nbsp;retried {getRelativeDateString(this.props.job.retriedAt)}
          </span>
        </>
      ) : (
        <>
          <Icon
            icon="retry"
            className="flex-none"
            style={{
              width: 14,
              height: 14,
              position: "relative",
              top: -1,
              marginLeft: 3,
              marginRight: 3,
            }}
          />
          Automatically retried{" "}
          {getRelativeDateString(this.props.job.retriedAt)}
        </>
      );

      return (
        <a
          href={
            window.location.pathname + `#${this.props.job.retriedInJobUuid}`
          }
          onClick={this.handleClickRetriedJobLink}
          className="text-decoration-underline black hover-black flex items-center block py1 xs-hide"
          style={{ fontSize: 13 }}
        >
          {linkContents}
        </a>
      );
    }

    // We never want to show the retry button if the current user is anonymous,
    // so we'll just bail out early here and return nothing.
    if (this.props.job.permissions.retry.reason === "anonymous") {
      return null;
    }

    // Don't show the retry button if it's not finished.
    if (!TERMINAL_JOB_STATES.includes(this.props.job.state)) {
      return null;
    }

    // Also don't show the retry button if the job has "passed" and there isn't
    // an error message (we may want to show the retry button for failed jobs if
    // the user has configured it to be allowed)
    if (this.props.job.passed && !this.props.job.retryable.allowed) {
      return null;
    }

    // First check if we're allowed to retry based on permissions. If we're
    // all good on that front, check to see if the job is eligible for
    // retries.
    if (!this.props.job.permissions.retry.allowed) {
      retryNotAllowedMessage = this.props.job.permissions.retry.message;
    } else if (!this.props.job.retryable.allowed) {
      retryNotAllowedMessage = this.props.job.retryable.message;
    }

    const retryButtonContent = (
      <>
        <Icon
          icon="retry"
          style={{
            width: 14,
            height: 14,
            position: "relative",
            top: -1,
            marginRight: 3,
          }}
        />
        Retry
      </>
    );

    if (retryNotAllowedMessage) {
      // Retries are disabled, let's render a disabled button with a tooltip for more info!
      return (
        <button
          disabled={true}
          className="btn btn-default"
          title={retryNotAllowedMessage}
          style={{ fontSize: 13, height: 34, lineHeight: "13px" }}
        >
          {retryButtonContent}
        </button>
      );
    }

    const options = {
      url: this.props.job.basePath + "/retry",
      method: "post",
      loadingText: "Retrying...",
      className: "btn btn-default",
      style: { fontSize: 13, height: 34, lineHeight: "13px" },
      onSuccess: (event, response) => {
        if (response && response.build) {
          this.props.buildStore.loadAndEmit(response.build);
        }
      },
      onError: (event, response) => {
        if (response && response.message) {
          alert(response.message);
        }
      },
    };

    // Retries are all good! Let's render the button.
    return (
      <RemoteButtonComponent {...options}>
        {retryButtonContent}
      </RemoteButtonComponent>
    );
  },

  renderCancelJobDialog() {
    const options = {
      url: this.props.job.basePath + "/cancel",
      method: "post",
      loadingText: "Canceling...",
      className: "btn btn-danger block center",
      onSuccess: (event, response) => {
        if (response && response.build) {
          this.handleCancelJobDialogClose();
          this.props.buildStore.loadAndEmit(response.build);
        }
      },
      onError: (event, response) => {
        if (response && response.message) {
          alert(response.message);
        }
      },
    };

    return (
      <Dialog
        isOpen={this.state.cancelJobDialogOpen}
        onRequestClose={this.handleCancelJobDialogClose}
        width={400}
      >
        <div className="px4 pb4">
          <h2 className="h2">Are you sure?</h2>
          <p className="mb3">
            Canceling this job may have an impact on the success of the overall
            build. Continue?
          </p>
          <RemoteButtonComponent {...options}>
            Yes, Cancel Job
          </RemoteButtonComponent>
        </div>
      </Dialog>
    );
  },

  renderCancelJobButton() {
    // First check if we're allowed to cancel based on permissions.
    // If not, we'll just bail out early here and return nothing.
    if (!this.props.job.permissions.cancel.allowed) {
      return null;
    }

    const options = {
      className: "btn btn-default",
      style: { fontSize: 13, height: 34, lineHeight: "13px" },
    };

    if (this.props.job.state === "canceling") {
      options.disabled = true;
      options.loading = "Canceling...";
    } else if (!this.props.job.cancelable.allowed) {
      // Don't render the cancel button if it can't be cancelled
      return null;
    }

    return (
      <Button {...options} onClick={this.handleCancelJobClick}>
        <i className="fa fa-times mr1" />
        Cancel
      </Button>
    );
  },

  renderMissingDependencies() {
    return (
      <MissingStepDependencies
        stepUuid={this.props.job.stepUuid}
        variant={
          isTerminalBuildState(this.props.build.state) ? "error" : "warning"
        }
      />
    );
  },

  renderMissingGroupDependencies() {
    return (
      <MissingStepDependencies
        stepUuid={this.props.job.groupUuid}
        group={true}
        variant={
          isTerminalBuildState(this.props.build.state) ? "error" : "warning"
        }
      />
    );
  },

  reloadBuildStore() {
    this.props.buildStore.reload();
  },

  renderLimitedBy() {
    if (this.props.job.state !== "limited" || !this.props.job.limitingJobs) {
      return null;
    }

    return (
      <div className="pt-4">
        <LimitedByConcurrencyGroup
          limitingJobs={this.props.job.limitingJobs}
          concurrencyGroup={this.props.job.concurrencyGroup}
          concurrency={this.props.job.concurrency}
          reload={this.reloadBuildStore}
        />
      </div>
    );
  },

  render() {
    const { className, job } = this.props;

    let state;
    if (TERMINAL_JOB_STATES.includes(job.state)) {
      switch (true) {
        case job.state === "canceled" && job.passed:
          state = "canceled-passed";
          break;
        case job.state === "canceled":
          state = "canceled";
          break;
        case job.passed:
          state = "passed";
          break;
        case job.softFailed:
          state = "soft_failed";
          break;
        default:
          state = "failed";
      }
    } else {
      state = job.state;
    }

    // If we're going to expand and we haven't rendered
    // the job content node before. This small hack lets us lazily
    // load the tabs.
    if (this.state.expanded && !this.lazyLoaded) {
      this.lazyLoaded = true;
    }

    // If it's been lazy loaded, we can start generating the content
    const jobContentNode = this.state.expanded ? (
      <div className="build-details-pipeline-job-body z1">
        {this.renderMissingDependencies()}
        {this.renderMissingGroupDependencies()}
        {this.renderLimitedBy()}

        <div className="build-details-pipeline-job-body__controls pt2 mb2 bg-white z2 border-bottom border-gray flex">
          <div className="build-details-pipeline-job-body__actions order-1 flex items-center">
            {this.renderSSHButton()}
            {this.renderTestAnalyticsButton()}
            {this.renderRetryJobButton()}
            {this.renderCancelJobButton()}
          </div>
          <ul
            className="monochrome flex flex-auto items-stretch list-style-none p0 m0 order-0"
            style={{ fontSize: 13, overflowX: "auto", overflowY: "hidden" }}
          >
            {this.tabMenuNodes()}
          </ul>
        </div>
        <div className="tab-content">{this.tabContentNodes()}</div>
      </div>
    ) : null;

    return (
      <>
        <div
          className={classNames(
            `build-details-pipeline-job relative build-details-pipeline-job-state-${state}`,
            { "build-details-pipeline-job-expanded": this.state.expanded },
            className,
          )}
          id={this.elementId()}
          ref={(wrapperNode) => (this.wrapperNode = wrapperNode)}
        >
          <div
            role="button"
            tabIndex="0"
            className={twMerge(
              classNames(
                "build-details-pipeline-job__header flex items-center antialiased px-3 py-3.5 gap-x-2.5 cursor-pointer color-inherit focus-color-inherit hover-color-inherit focus-bg-silver hover-bg-silver bg-white text-decoration-none z2",
                {
                  "cursor-default": Features.BuildSidebar,
                },
              ),
            )}
            onClick={this.handleClickHeader}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                this.handleClickHeader(event);
              }
            }}
            aria-pressed={this.state.expanded}
          >
            <div className="flex gap-x-1.5 flex-auto items-center">
              <span className="build-details-pipeline-icon">
                <PipelineStateIcon job={job} />
              </span>
              {this.groupInfoNode()}
              {this.jobNameNode()}
              {this.commandNode()}
            </div>

            {this.specialStateNode() ?? (
              <MissingDependenciesNode
                job={this.props.job}
                build={this.props.build}
              />
            )}

            <JobDuration
              job={job}
              build={this.props.build}
              className="job-stats"
              hostedConcurrency={this.props.job.hostedConcurrency}
            />
            {this.agentNode()}
            {this.clusterQueueNode()}
            {this.signatureNode()}
          </div>
          {jobContentNode}
        </div>
        {this.renderCancelJobDialog()}
      </>
    );
  },

  handleClickNode(event) {
    const selection = window.getSelection();
    const range = document.createRange();

    range.selectNodeContents(event.currentTarget);
    selection.removeAllRanges();
    selection.addRange(range);
  },

  agentNode() {
    const hostedQueue = this.props.job.hosted;
    const instanceShape = this.props.job.instanceShape;

    if (this.props.job.agentName) {
      return (
        <div className="flex-none xs-hide">
          <Agent
            agentName={this.props.job.agentName}
            agentPath={this.props.job.agentPath}
            queueName={this.props.job.clusterQueue?.key}
            agentsPath={this.props.job.agentsPath}
            onNestedLinkTrigger={this.handleNestedLinkTrigger}
            hosted={hostedQueue}
            instanceShape={instanceShape}
          />
        </div>
      );
    }
  },

  specialStateNode() {
    let text = null;

    if (this.props.job.state === "canceled") {
      text = "Canceled";
    } else if (this.props.job.state === "canceling") {
      text = "Canceling…";
    } else if (this.props.job.state === "timed_out") {
      text = "Timed Out";
    } else if (this.props.job.state === "timing_out") {
      text = "Timing Out…";
    } else if (this.props.job.state === "expired") {
      text = "Expired";
    }

    if (text) {
      return (
        <div className="job-stats flex-none flex items-center gap1 xs-hide">
          <Icon
            icon="heroicons/16/solid/exclamation-triangle"
            className="flex-shrink-0"
            style={{ width: "14px", height: "14px" }}
          />

          <span>{text}</span>
        </div>
      );
    }

    return null;
  },

  handleNestedLinkTrigger(event) {
    // The job header is a clickable container that expands the job details. We sometimes nest links
    // within this header, and when they're triggered we don't want the expanded state to change, so
    // we'll prevent event propagation here.
    if (
      event.type === "click" ||
      (event.type === "keydown" && event.key === "Enter")
    ) {
      event.stopPropagation();
    }
  },

  clusterQueueNode() {
    if (!this.props.job.agentsPath) {
      return null;
    }

    const queueName = this.props.job.clusterQueue?.key;
    const hasAgent = this.props.job.agentName;

    // Don't render once we have an agent
    if (hasAgent) {
      return null;
    }

    return (
      <div className="flex-none xs-hide">
        <ClusterQueue
          queueName={queueName}
          hasAgent={hasAgent}
          agentsPath={this.props.job.agentsPath}
          onNestedLinkTrigger={this.handleNestedLinkTrigger}
        />
      </div>
    );
  },
});

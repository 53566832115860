import * as React from "react";
import DocumentTitle from "react-document-title";
import classNames from "classnames";

import Icon from "app/components/shared/Icon";
import PageHeader from "app/components/shared/PageHeader";
import TabControl from "app/components/shared/TabControl";

type Props = {
  name: string;
  slug: string;
  children: React.ReactNode;
};

const ACTIVE_CLASS_NAME = "btn-tab--active";

const Tab = ({ active, ...props }: any) => (
  <a
    className={classNames("btn btn-tab cursor-pointer", {
      [ACTIVE_CLASS_NAME]: active,
    })}
    {...props}
  />
);

const AuditLogSection = ({ name, slug, children }: Props) => {
  const auditLogURI = `/organizations/${slug}/audit-log`;
  const auditLogExportURI = `/organizations/${slug}/audit-log/export`;
  const currentPath = window.location.pathname;

  return (
    <DocumentTitle title={`Audit Log · ${name}`}>
      <div>
        <PageHeader>
          <PageHeader.Icon>
            <Icon
              icon="auditLog"
              className="align-middle"
              style={{ width: 40, height: 40 }}
            />
          </PageHeader.Icon>
          <PageHeader.Title>Audit Log</PageHeader.Title>
          <PageHeader.Description>
            Event log of all organization activity
          </PageHeader.Description>
        </PageHeader>

        <TabControl>
          <Tab href={auditLogURI} active={currentPath === auditLogURI}>
            Events
          </Tab>
          <Tab
            href={`${auditLogExportURI}`}
            active={currentPath === auditLogExportURI}
          >
            Query & Export
          </Tab>
        </TabControl>
        {children}
      </div>
    </DocumentTitle>
  );
};

export default AuditLogSection;

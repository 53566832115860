import { Handle, NodeProps } from "reactflow";
import { type ReactNode } from "react";
import cn from "classnames";
import { twMerge } from "tailwind-merge";
import Conditional from "./Conditional";
import Label from "./Label";
import { Step, State, Outcome } from "app/lib/pipeline/Step";
import { StateIcon } from "./StateIcon";
import { bgColorForStep, textColorForStep } from "../colors";
import { useBuild, useMostRecentJob } from "../../BuildContext";

type StepProps = Pick<Step, "state" | "outcome" | "if"> & {
  emphasis?: Emphasis;
};

export enum Emphasis {
  Default,
  High,
  Low,
}

export default function BaseNode(
  props: {
    label?: string;
    icon?: ReactNode;
    children?: ReactNode;
    className?: string;
    hideStateIcon?: boolean;
    count?: ReactNode;
  } & NodeProps<StepProps>,
) {
  const { state, outcome, emphasis = Emphasis.Default } = props.data;
  const { build } = useBuild();

  const job = useMostRecentJob(props.id);
  const waitingOnConcurrencyGroup = job?.state === "limited";

  return (
    <>
      <Conditional if={props.data.if} />
      <div
        data-state={state}
        data-outcome={outcome}
        className={twMerge(
          "bg-white",
          "cursor-pointer",
          "rounded-xl",
          "p-3",
          "border-2",
          ["flex", "flex-col", "gap-5"],
          "transition-all duration-150",
          "max-w-[45ch]",
          cn({
            [textColorForStep({ ...props.data, waitingOnConcurrencyGroup })]:
              state,
            [bgColorForStep(props.data, build)]: state && build,
            "shadow-depth-200": !state,
            "opacity-40":
              state === State.WaitingForDependencies || state === State.Ignored,
            border: state === State.Finished && outcome === Outcome.SoftFailed,
            "ring-4 ring-offset-2 ring-[#086DD7]": props.selected,
          }),
          props.className,
          cn({
            "opacity-40 hover:opacity-100": emphasis === Emphasis.Low,
            "opacity-100 border-opacity-100": emphasis === Emphasis.High,
          }),
        )}
      >
        <div className="flex items-center gap-2">
          {props.icon}
          {props.label && (
            <Label
              label={props.label}
              className={cn("flex-auto", {
                "line-through": state === State.Ignored,
              })}
            />
          )}

          {!props.hideStateIcon && (
            <span className="flex items-center gap-1 ml-auto">
              {props.count}
              <StateIcon
                {...props.data}
                waitingOnConcurrencyGroup={waitingOnConcurrencyGroup}
              />
            </span>
          )}
        </div>
        {props.children}
      </div>

      {props.targetPosition && (
        <Handle
          type="target"
          position={props.targetPosition}
          isConnectable={props.isConnectable}
          className={cn({ "opacity-0": !props.isConnectable })}
        />
      )}
      {props.sourcePosition && (
        <Handle
          type="source"
          position={props.sourcePosition}
          isConnectable={props.isConnectable}
          className={cn({ "opacity-0": !props.isConnectable })}
        />
      )}
    </>
  );
}

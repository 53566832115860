import classNames from "classnames";
import { getCssValue } from "app/lib/cssValues";
import {
  ParallelGroupFooter,
  ParallelGroupHeader,
  StepContentWrapper,
  StepIcon,
  StepName,
  StepProps,
} from "./Step";
import getLabelColor from "./utils/getLabelColor";
import { isJobTerminated, titleForBrokenJob } from "./utils/job";
import { CommandJob } from "./types/CommandJob";

export default function BrokenScriptStep({
  job,
  stepClassName,
  groupedJob,
  showGroupHeader,
  showGroupFooter,
}: StepProps<CommandJob> & {
  groupedJob: boolean;
  showGroupHeader: boolean;
  showGroupFooter: boolean;
}) {
  const title = titleForBrokenJob(job);

  // TODO: icon for broken steps
  return (
    <>
      {showGroupHeader && <ParallelGroupHeader job={job} />}

      <div
        className={classNames(stepClassName, {
          "build-pipeline-job--grouped": groupedJob,
        })}
        style={groupedJob ? {} : { maxWidth: "15em" }}
      >
        {groupedJob ? (
          <StepContentWrapper>
            <div className="flex items-center">
              <StepIcon job={job} />
            </div>

            <div
              className="rounded white semi-bold bg-white small tabular-numerals px1 border"
              title={title ?? undefined}
              style={{
                color: getLabelColor(job),
                backgroundColor: getCssValue("--base-0"),
                borderColor: getLabelColor(job),
                lineHeight: 1.75,
              }}
            >
              {job.parallelGroupIndex && job.parallelGroupIndex + 1}
            </div>
          </StepContentWrapper>
        ) : (
          <StepContentWrapper
            shouldWrap={isJobTerminated(job) && !job.passed && !job.softFailed}
          >
            <StepName job={job} title={title ?? undefined} />
          </StepContentWrapper>
        )}
      </div>

      {showGroupFooter && <ParallelGroupFooter />}
    </>
  );
}

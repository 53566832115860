/**
 * @generated SignedSource<<678d17bd0d3aad0d7ae4ec29edf92e12>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Members_organizationQuery$variables = {
  pageSize: number;
  search?: string | null;
  team: string;
};
export type Members_organizationQuery$data = {
  readonly team: {
    readonly " $fragmentSpreads": FragmentRefs<"Members_team">;
  } | null;
};
export type Members_organizationQuery = {
  response: Members_organizationQuery$data;
  variables: Members_organizationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pageSize"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "team"
},
v3 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "team"
  }
],
v4 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Avatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "allowed",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "Members_organizationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Team",
        "kind": "LinkedField",
        "name": "team",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "pageSize",
                "variableName": "pageSize"
              },
              (v4/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "Members_team"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "Members_organizationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Team",
        "kind": "LinkedField",
        "name": "team",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 10
                  },
                  {
                    "kind": "Literal",
                    "name": "order",
                    "value": "RELEVANCE"
                  },
                  {
                    "kind": "Literal",
                    "name": "search",
                    "value": ""
                  }
                ],
                "concreteType": "OrganizationMemberConnection",
                "kind": "LinkedField",
                "name": "members",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrganizationMemberEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrganizationMember",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              (v7/*: any*/),
                              (v8/*: any*/),
                              (v9/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "members(first:10,order:\"RELEVANCE\",search:\"\")"
              },
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "OrganizationPermissions",
                "kind": "LinkedField",
                "name": "permissions",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Permission",
                    "kind": "LinkedField",
                    "name": "organizationMemberViewSensitive",
                    "plural": false,
                    "selections": (v10/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TeamPermissions",
            "kind": "LinkedField",
            "name": "permissions",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Permission",
                "kind": "LinkedField",
                "name": "teamMemberCreate",
                "plural": false,
                "selections": (v10/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "first",
                "variableName": "pageSize"
              },
              {
                "kind": "Literal",
                "name": "order",
                "value": "NAME"
              },
              (v4/*: any*/)
            ],
            "concreteType": "TeamMemberConnection",
            "kind": "LinkedField",
            "name": "members",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "Connection",
                "abstractKey": "__isConnection"
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "count",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TeamMemberEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TeamMember",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "bot",
                            "storageKey": null
                          },
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "role",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Team",
                        "kind": "LinkedField",
                        "name": "team",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Organization",
                            "kind": "LinkedField",
                            "name": "organization",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/),
                              (v5/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TeamMemberPermissions",
                        "kind": "LinkedField",
                        "name": "permissions",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Permission",
                            "kind": "LinkedField",
                            "name": "teamMemberUpdate",
                            "plural": false,
                            "selections": (v10/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Permission",
                            "kind": "LinkedField",
                            "name": "teamMemberDelete",
                            "plural": false,
                            "selections": (v10/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrganizationMember",
                        "kind": "LinkedField",
                        "name": "organizationMember",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "uuid",
                            "storageKey": null
                          },
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f28fe4484a395274e32ba862caf14369",
    "id": null,
    "metadata": {},
    "name": "Members_organizationQuery",
    "operationKind": "query",
    "text": "query Members_organizationQuery(\n  $team: ID!\n  $pageSize: Int!\n  $search: String\n) {\n  team(slug: $team) {\n    ...Members_team_1v4FbS\n    id\n  }\n}\n\nfragment MembersChooser_team on Team {\n  id\n  slug\n  organization {\n    members(search: \"\", first: 10, order: RELEVANCE) {\n      edges {\n        node {\n          user {\n            id\n            ...User_user\n          }\n          id\n        }\n      }\n    }\n    id\n  }\n  permissions {\n    teamMemberCreate {\n      allowed\n    }\n  }\n}\n\nfragment Members_team_1v4FbS on Team {\n  id\n  ...MembersChooser_team\n  members(first: $pageSize, search: $search, order: NAME) {\n    ...ShowMoreFooter_connection\n    count\n    edges {\n      node {\n        id\n        ...row_teamMember_1wu189\n      }\n    }\n  }\n  organization {\n    permissions {\n      organizationMemberViewSensitive {\n        allowed\n      }\n    }\n    id\n  }\n}\n\nfragment ShowMoreFooter_connection on Connection {\n  __isConnection: __typename\n  pageInfo {\n    hasNextPage\n  }\n}\n\nfragment User_user on User {\n  name\n  email\n  avatar {\n    url\n  }\n}\n\nfragment row_teamMember_1wu189 on TeamMember {\n  id\n  user {\n    name\n    email\n    avatar {\n      url\n    }\n    bot\n    id\n  }\n  role\n  team {\n    organization {\n      slug\n      id\n    }\n    id\n  }\n  permissions {\n    teamMemberUpdate {\n      allowed\n    }\n    teamMemberDelete {\n      allowed\n    }\n  }\n  organizationMember {\n    uuid\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "55c2a405e4d1d24e8889f4c0da6edb9c";

export default node;

import { useState } from "react";
import { createRefetchContainer, graphql } from "react-relay";

import Panel from "app/components/shared/Panel";
import ShowMoreFooter from "app/components/shared/ShowMoreFooter";
import Spinner from "app/components/shared/Spinner";
import AuditLogRow from "../row";

const PAGE_SIZE = 30;

type AuditEventType = {
  edges: Array<any>;
};
type Props = {
  organization: {
    name: string;
    slug: string;
    auditEvents?: AuditEventType;
  };
  relay: any;
};

const Events = ({
  auditEvents,
}: {
  auditEvents: AuditEventType | null | undefined;
}) => {
  if (!auditEvents) {
    return (
      <Panel.Section className="center">
        <Spinner />
      </Panel.Section>
    );
  }

  if (auditEvents.edges.length > 0) {
    return auditEvents.edges.map(({ node: auditEvent }) => (
      <AuditLogRow key={auditEvent.id} auditEvent={auditEvent} />
    ));
  }

  return (
    <Panel.Section>
      <div className="dark-gray">There are no audit events</div>
    </Panel.Section>
  );
};

type FooterProps = {
  auditEvents: AuditEventType | null | undefined;
  isLoading: boolean;
  onShowMore: () => void;
};

const Footer = ({ auditEvents, isLoading, onShowMore }: FooterProps) => (
  <ShowMoreFooter
    connection={auditEvents}
    loading={isLoading}
    onShowMore={onShowMore}
  />
);

const AuditLogList = (props: Props) => {
  const [pageSize, setPageSize] = useState<number>(PAGE_SIZE);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  if (!props.organization) {
    return null;
  }

  const onShowMore = () => {
    setIsLoading(true);
    setPageSize(pageSize + PAGE_SIZE);

    props.relay.refetch(
      (prevValues) => ({
        ...prevValues,
        organization: props.organization.slug,
        pageSize: pageSize + PAGE_SIZE,
      }),
      null,
      () => {
        setIsLoading(false);
      },
    );
  };

  return (
    <Panel>
      {/* @ts-expect-error - TS2786 - 'Events' cannot be used as a JSX component. */}
      <Events auditEvents={props.organization.auditEvents} />
      <Footer
        auditEvents={props.organization.auditEvents}
        isLoading={isLoading}
        onShowMore={onShowMore}
      />
    </Panel>
  );
};

export default createRefetchContainer(
  AuditLogList,
  {
    organization: graphql`
      fragment List_organization on Organization
      @argumentDefinitions(pageSize: { type: "Int", defaultValue: 30 }) {
        slug
        name
        auditEvents(first: $pageSize) {
          edges {
            node {
              id
              ...row_auditEvent
            }
          }
          ...ShowMoreFooter_connection
        }
      }
    `,
  },
  graphql`
    query List_organizationQuery($organization: ID!, $pageSize: Int!) {
      organization(slug: $organization) {
        ...List_organization @arguments(pageSize: $pageSize)
      }
    }
  `,
);

/* eslint-disable id-length */

import Icon from "app/components/shared/Icon";
import classNames from "classnames";
import React, { useCallback, useMemo, useState } from "react";
import { twMerge } from "tailwind-merge";
import { motion } from "framer-motion";
import { useHotKey } from "app/components/Playground/Controls/useHotKey";
import {
  DockPosition,
  useBuildPreferencesStore,
} from "../lib/useBuildPreferencesStore";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuTrigger,
} from "app/components/shared/DropdownMenu";
import { ResizeHandle } from "./ResizeHandle";

interface Props {
  children: React.ReactNode;
  onClose: () => void;
}

const getVariants = (dock: DockPosition) => {
  switch (dock) {
    case DockPosition.Center:
      return {
        initial: { scale: 0.95 },
        animate: { scale: 1, transition: { type: "spring", duration: 0.25 } },
        exit: { transition: { duration: 0 } },
      };
    case DockPosition.Bottom:
      return {
        initial: { y: "100%" },
        animate: { y: 0 },
        exit: { transition: { duration: 0 } },
      };
    case DockPosition.Right:
      return {
        initial: { x: "100% " },
        animate: { x: 0 },
        exit: { transition: { duration: 0 } },
      };
  }
};

/**
 * A collapsible drawer
 */
export const Drawer = ({ children, onClose }: Props) => {
  const [expanded, setExpanded] = useState(false);

  const height = useBuildPreferencesStore((state) => state.resizedDrawerHeight);
  const width = useBuildPreferencesStore((state) => state.resizedDrawerWidth);

  const setResizedDrawerHeight = useBuildPreferencesStore(
    (state) => state.setResizedDrawerHeight,
  );

  const setResizedDrawerWidth = useBuildPreferencesStore(
    (state) => state.setResizedDrawerWidth,
  );

  const dockPosition = useBuildPreferencesStore((state) => state.dockPosition);

  useHotKey("Escape", () => {
    if (expanded) {
      setExpanded(false);
    } else {
      onClose();
    }
  });

  const toggleExpand = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  const iconType = useMemo(() => {
    const iconName = expanded ? "minimize" : "maximize";
    return `custom/outline/${iconName}`;
  }, [expanded]);

  /**
   * Resize the drawer when dragging the resize handle
   *
   * Hook into the mouse down event to start dragging the resize handle
   * and update the drawer size based on the mouse position, storing the
   * new size in a preferences store for persistence across sessions.
   *
   * Sizes are stored as percentages to allow for responsive resizing.
   */
  const resize = useCallback(
    (x: number, y: number) => {
      // Prevent resizing when the drawer is expanded
      if (expanded) {
        return;
      }

      // If the drawer is docked to bottom, resize the height (as a percentage)
      if (dockPosition === DockPosition.Bottom) {
        let height = ((window.innerHeight - y) / window.innerHeight) * 100;

        if (height > 100) {
          height = 100;
        } else if (height < 5) {
          height = 5;
        }

        setResizedDrawerHeight(height);
      }

      // If the drawer is docked to right, resize the width (as a percentage)
      if (dockPosition === DockPosition.Right) {
        let width = ((window.innerWidth - x) / window.innerWidth) * 100;

        if (width > 100) {
          width = 100;
        } else if (width < 5) {
          width = 5;
        }

        setResizedDrawerWidth(width);
      }
    },
    [setResizedDrawerHeight, setResizedDrawerWidth, expanded, dockPosition],
  );

  const style: React.CSSProperties = {};
  if (dockPosition === DockPosition.Right) {
    style.width = expanded ? `100%` : `${width}%`;
  }

  if (dockPosition === DockPosition.Bottom) {
    style.height = expanded ? `100%` : `${height}%`;
  }

  return (
    <motion.div
      key={dockPosition}
      data-position={dockPosition}
      data-testid="drawer"
      style={style}
      initial="initial"
      animate="animate"
      exit="exit"
      variants={getVariants(dockPosition)}
      transition={{ type: "spring", duration: 0.3 }}
      className={twMerge(
        // Custom class to override the some job list item styles (consolidate once this is the source of truth)
        "drawer group",

        "bg-white z-50 fixed rounded-md",
        classNames({
          // Docked to the right
          "bottom-0 top-0 right-0": dockPosition === DockPosition.Right,
          "shadow-[-20px_0_25px_-5px_rgb(0_0_0_/_0.1)]":
            dockPosition === DockPosition.Right,
          "left-0": expanded && dockPosition === DockPosition.Right,

          // Docked to the center
          "border border-gray-400 shadow-[0_10px_25px_-5px_rgb(0_0_0_/_0.2)]":
            dockPosition === DockPosition.Center,
          // Make sure it overlaps other borders by using negative values
          "z-20 md:absolute -left-[1px] -right-[1px] -top-[1px] -bottom-[1px]":
            dockPosition === DockPosition.Center,

          // Docked to the bottom
          "bottom-0 left-0 right-0": dockPosition === DockPosition.Bottom,
          "shadow-[0_-20px_25px_-5px_rgb(0_0_0_/_0.1)]":
            dockPosition === DockPosition.Bottom,
          "top-0": expanded && dockPosition === DockPosition.Bottom,
        }),
      )}
    >
      <div>
        {dockPosition === DockPosition.Bottom && (
          <ResizeHandle onResize={resize} direction="vertical" />
        )}

        {dockPosition === DockPosition.Right && (
          <ResizeHandle onResize={resize} direction="horizontal" />
        )}

        <div className="absolute h-full w-full left-0 top-0 overflow-auto rounded-md">
          <div className="flex flex-col">
            <div className="flex gap-2 sticky top-0 z-10 bg-white py-2 px-4">
              {(dockPosition === DockPosition.Right ||
                dockPosition === DockPosition.Bottom) && (
                <DrawerButton
                  onClick={toggleExpand}
                  isActive={expanded}
                  data-testid="drawer-expand-button"
                >
                  <Icon className="h-4" icon={iconType} />
                </DrawerButton>
              )}

              <div className="flex gap-1 ml-auto">
                <DockerPreferenceDropdown />

                <DrawerButton
                  onClick={onClose}
                  data-testid="drawer-close-button"
                >
                  <Icon icon="heroicons/20/solid/x-mark" className="h-5" />
                </DrawerButton>
              </div>
            </div>
            <div className="px-4">{children}</div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

const DrawerButton = (
  props: React.ButtonHTMLAttributes<HTMLButtonElement> & { isActive?: boolean },
) => (
  <button
    {...props}
    className={classNames(
      "rounded-md px-1 h-8 flex items-center justify-center transition-colors duration-200 ease-in-out",
      {
        "bg-purple-100 text-purple-600 hover:bg-purple-200": props.isActive,
        "bg-gray-100 hover:bg-purple-100 hover:text-purple-600":
          !props.isActive,
      },
    )}
  />
);

/**
 * A dropdown to change the dock position of the drawer.
 *
 * Shows the current dock position and allows the user to change it.
 */
const DockerPreferenceDropdown = () => {
  const dockPosition = useBuildPreferencesStore((state) => state.dockPosition);
  const setDockPosition = useBuildPreferencesStore(
    (state) => state.setDockPosition,
  );

  const dockRight = useCallback(() => {
    setDockPosition(DockPosition.Right);
  }, [setDockPosition]);

  const dockBottom = useCallback(() => {
    setDockPosition(DockPosition.Bottom);
  }, [setDockPosition]);

  const dockCenter = useCallback(() => {
    setDockPosition(DockPosition.Center);
  }, [setDockPosition]);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <DrawerButton>
          {dockPosition === DockPosition.Bottom && (
            <Icon icon="custom/outline/panel-bottom" className="h-4" />
          )}
          {dockPosition === DockPosition.Right && (
            <Icon icon="custom/outline/panel-right" className="h-4" />
          )}

          {dockPosition === DockPosition.Center && (
            <Icon icon="custom/outline/panel" className="h-4" />
          )}
          <span className="w-4 -ml-1">
            <Icon icon="custom/outline/chevron-down" className="w-5 h-5" />
          </span>
        </DrawerButton>
      </DropdownMenuTrigger>
      <DropdownMenuPortal>
        <DropdownMenuContent className="min-w-0 p-0">
          <DropdownMenuItem onClick={dockCenter}>
            <Icon icon="custom/outline/panel" className="h-4" />
            Center
          </DropdownMenuItem>
          <DropdownMenuItem onClick={dockRight}>
            <Icon icon="custom/outline/panel-right" className="h-4" />
            Right
          </DropdownMenuItem>
          <DropdownMenuItem onClick={dockBottom}>
            <Icon icon="custom/outline/panel-bottom" className="h-4" />
            Bottom
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenuPortal>
    </DropdownMenu>
  );
};

import Code from "app/components/docs/shared/code";
import CodeBlock from "app/components/docs/shared/code-block";
import Link from "app/components/docs/shared/link";

import "highlight.js/styles/atom-one-light.css";

type Props = {
  token?: string;
  className?: string;
  style?: any;
};

FreeBSDQuickStart.title = "FreeBSD";

function FreeBSDQuickStart(props: Props) {
  const { className, style } = props;

  return (
    <div className={className} style={style}>
      <p>
        You first need to ensure that both <Code>git</Code> and{" "}
        <Code>bash</Code> are installed on your server:
      </p>
      <CodeBlock language="bash">
        sudo pkg install bash git
        <br />
        {/*
         */}
        sudo sh -c{" "}
        <span className="hljs-string">
          &apos;echo &quot;fdesc /dev/fd fdescfs rw 0 0&quot; &gt;&gt;
          /etc/fstab&apos;
        </span>
        <br />
        {/*
         */}
        sudo mount -a
        <br />
        <br />
        <span className="hljs-comment">
          # The agent requires a version of bash to be available at: /bin/bash
        </span>
        <br />
        {/*
         */}
        sudo ln -s /usr/<span className="hljs-built_in">local</span>/bin/bash
        /bin/bash
        <br />
        <br />
        <span className="hljs-comment">
          # If you want change your default shell to bash
        </span>
        <br />
        {/*
         */}
        sudo chsh -s /usr/<span className="hljs-built_in">local</span>/bin/bash
        `whoami`
        <br />
        <br />
        <span className="hljs-comment"># You now switch to bash</span>
        <br />
        {/*
         */}
        bash
        <br />
      </CodeBlock>

      <p>
        Once they have been installed, you can run the following script (
        <Link href="https://raw.githubusercontent.com/buildkite/agent/master/install.sh">
          view the source
        </Link>
        ), which will download and install the correct binary for your system
        and architecture:
      </p>
      <CodeBlock language="bash">
        TOKEN=
        <span className="hljs-string">
          &quot;{props.token || "INSERT-YOUR-AGENT-TOKEN-HERE"}&quot;
        </span>{" "}
        bash -c{" "}
        <span className="hljs-string">
          &quot;`curl -sL
          https://raw.githubusercontent.com/buildkite/agent/master/install.sh`&quot;
        </span>
        <br />
      </CodeBlock>

      <p>Then, start the agent:</p>
      <CodeBlock language="bash">
        ~/.buildkite-agent/bin/buildkite-agent start
        <br />
      </CodeBlock>

      <p className="rounded bg-silver p2">
        See the <Link href="/docs/agent/freebsd">FreeBSD agent docs</Link> for
        more details.
      </p>
    </div>
  );
}

export default FreeBSDQuickStart;

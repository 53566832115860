const CopySVG = (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="Icon for copy action"
    role="img"
  >
    <path
      d="M13.092 9.5V7.75C13.092 6.50736 12.0847 5.5 10.842 5.5H9.84204C9.42783 5.5 9.09204 5.16421 9.09204 4.75V3.75C9.09204 2.50736 8.08468 1.5 6.84204 1.5H5.59204M7.09204 1.5H3.84204C3.42783 1.5 3.09204 1.83579 3.09204 2.25V13.75C3.09204 14.1642 3.42783 14.5 3.84204 14.5H12.342C12.7563 14.5 13.092 14.1642 13.092 13.75V7.5C13.092 4.18629 10.4057 1.5 7.09204 1.5Z"
      stroke="currentColor"
      strokeOpacity="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CopySVG;

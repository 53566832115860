/**
 * @generated SignedSource<<d5531b7469005a38b8cf23878ed8b6cc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TeamRow_organization$data = {
  readonly slug: string;
  readonly " $fragmentType": "TeamRow_organization";
};
export type TeamRow_organization$key = {
  readonly " $data"?: TeamRow_organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"TeamRow_organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TeamRow_organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "6638444dea0579c711e57c5b326f3795";

export default node;

import * as React from "react";

import AgentQueryRules from "./AgentQueryRules";

import CollapsableArea from "app/components/shared/CollapsableArea";
import FormTextarea from "app/components/shared/FormTextarea";
import FormTextField from "app/components/shared/FormTextField";

import LegacyDispatcher from "app/lib/legacyDispatcher";

import { Step } from "app/stores/ProjectPipelineStore";

type Props = {
  disabled: boolean;
  accountID: string;
  index: string | number;
  step: Step;
};

type State = {
  confirmDestroy: boolean;
};

type HeadingAndFormNode = {
  headingNode: React.ReactNode;
  formNode: React.ReactNode;
};

/* eslint-disable react/require-optimization */
// TODO: Fix the architecture of these components so this one can be optimised.
// The problem is the `step` object is actually passed from the store, which is always
// the same object reference, even if mutated, so ShallowCompare functions do not work.

export default class PipelineForm extends React.Component<Props, State> {
  commandTextareaComponent: FormTextarea | null | undefined;
  envTextareaComponent: FormTextarea | null | undefined;
  doneInitialAutoresizeUpdate: boolean | null | undefined;

  state = {
    confirmDestroy: false,
  };

  componentDidUpdate() {
    // The autoresizer fails to automatically size the initial height of the
    // textareas because they're hidden, so we need to tell give it a nudge
    // See: http://www.jacklmoore.com/autosize/#faq-hidden
    if (this.props.step.selected && !this.doneInitialAutoresizeUpdate) {
      if (this.commandTextareaComponent) {
        this.commandTextareaComponent.updateAutoresize();
      }
      if (this.envTextareaComponent) {
        this.envTextareaComponent.updateAutoresize();
      }
      return (this.doneInitialAutoresizeUpdate = true);
    }
  }

  renderHeadingAndFormNode(): HeadingAndFormNode {
    switch (this.props.step.type) {
      case "script":
        return this._scriptStepForm();
      case "manual":
        return this._manualStepForm();
      case "waiter":
        return this._waiterStepForm();
    }

    throw new TypeError(
      "PipelineForm#renderHeadingAndFormNode recieved an invalid step type",
    );
  }

  render() {
    const { headingNode, formNode } = this.renderHeadingAndFormNode();

    // If the step is destroyed, then this should should be hidden
    const panelDisplay = this.props.step.destroyed
      ? "none"
      : // Toggle display based on whether it's selected or not
        this.props.step.selected
        ? "block"
        : "none";

    const destroyInputValue = this.props.step.destroyed ? "true" : "false";

    // If the id input is empty, rails will treat it like a new value
    const idInputValue = this.props.step.persisted ? this.props.step.id : "";

    return (
      <div
        className="ProjectPipelineFormComponent panel panel-default kinda-new-panel step-form"
        style={{ display: panelDisplay }}
      >
        <input
          name={this.inputNameFor("type")}
          type="hidden"
          value={this.props.step.type}
        />
        <input
          name={this.inputNameFor("id")}
          type="hidden"
          value={idInputValue}
        />
        <div className="panel-heading clearfix">
          <span className="name">{headingNode}</span>
          <div className="edit-dropdown pull-right">
            <button
              disabled={this.props.disabled}
              type="button"
              className="btn btn-default clone-button"
              onClick={this.handleCloneStepClick}
            >
              Clone
            </button>
            <button
              disabled={this.props.disabled}
              type="button"
              className="btn btn-default delete-button"
              onClick={this.handleRemoveStepClick}
            >
              Remove
            </button>
          </div>
        </div>
        <div className="panel-body">
          <fieldset disabled={this.props.disabled}>{formNode}</fieldset>
        </div>
        <input
          name={this.inputNameFor("order")}
          type="hidden"
          value={this.props.step.order}
        />
        <input
          name={this.inputNameFor("_destroy")}
          type="hidden"
          value={destroyInputValue}
        />
      </div>
    );
  }

  _manualStepForm(): HeadingAndFormNode {
    return {
      headingNode: "Wait and block pipeline",
      formNode: (
        <div>
          <p>
            {
              "Waits for all previous steps to pass before allowing someone to manually continue the pipeline. You can use this to control deployments, QA and other manual processes. If you define this block step in a "
            }
            <a href="/docs/pipelines/uploading-pipelines">pipeline.yml file</a>
            {
              ", you’re able to customize the prompt, including adding custom form fields. For more information, see the "
            }
            <a href="/docs/pipelines/block-step">block step documentation</a>
            {/*
             */}
            .
          </p>
          <hr />
          <div className="row">
            <div className="col-md-6">
              <FormTextField
                name={this.inputNameFor("name")}
                label="Label"
                value={this.props.step.name}
                help="Label to show in the build pipeline, e.g. “:shipit: Deploy to Prod”"
                placeholder="Continue"
                onChange={this.handleNameInputChange}
              />
              <FormTextField
                name={this.inputNameFor("branch_configuration")}
                label="Branch Filtering"
                defaultValue={this.props.step.branchConfiguration}
                help={
                  <span>
                    {
                      "List of branch conditions, each separated with a space. e.g. "
                    }
                    <code className="code">main</code>
                    {/*
                     */}
                    . The build won’t become blocked when it reaches this step
                    if the build’s branch does not match.
                  </span>
                }
                placeholder="All branches"
              />
            </div>
          </div>
        </div>
      ),
    };
  }

  _waiterStepForm(): HeadingAndFormNode {
    return {
      headingNode: "Wait for previous steps to pass",
      formNode: (
        <div>
          <p>
            Waits for previous steps to successfully pass before continuing the
            build.
          </p>
        </div>
      ),
    };
  }

  _scriptStepForm(): HeadingAndFormNode {
    return {
      headingNode: "Run script or command",
      formNode: (
        <div>
          <div className="row">
            <div className="col-md-6 ProjectPipelineFormComponent__col--main">
              {this._scriptNode()}
              <FormTextField
                name={this.inputNameFor("name")}
                label="Label"
                value={this.props.step.name}
                onChange={this.handleNameInputChange}
                help="Label to show in the build pipeline. e.g. “:llama: Tests”"
              />
            </div>
            <div className="col-md-6 ProjectPipelineFormComponent__col--options">
              {this._agentSelectorFieldNode()}
              <CollapsableArea
                label="Environment Variables"
                initialOpen={this.isValuePresent(this.props.step.env)}
                className="mb3"
              >
                <FormTextarea.Autosize
                  name={this.inputNameFor("env")}
                  defaultValue={this.props.step.env}
                  help={
                    <span>
                      {
                        "Environment variables to be set, each on a new line. e.g. "
                      }
                      <code className="code">FOO=bar</code>
                      {". "}
                      <a href="/docs/pipelines/secrets">Pipeline secrets</a>
                      {" should not be stored as environment variables."}
                    </span>
                  }
                  rows={2}
                  style={{ resize: "vertical" }}
                  className="monospace"
                  ref={(envTextareaComponent) =>
                    (this.envTextareaComponent = envTextareaComponent)
                  }
                />
              </CollapsableArea>
              <CollapsableArea
                label="Branch Filtering"
                initialOpen={this.isValuePresent(
                  this.props.step.branchConfiguration,
                )}
                className="mb3"
              >
                <FormTextField
                  name={this.inputNameFor("branch_configuration")}
                  defaultValue={this.props.step.branchConfiguration}
                  help={
                    <span>
                      {
                        "List of branch conditions, each separated with a space. e.g. "
                      }
                      <code className="code">main</code>
                      {/*
                       */}
                      . This step will be skipped if the build’s branch does not
                      match.
                    </span>
                  }
                  placeholder="All branches"
                />
              </CollapsableArea>
              <CollapsableArea
                label="Automatic Artifact Uploading"
                initialOpen={this.isValuePresent(this.props.step.artifactPaths)}
                className="mb3"
              >
                <FormTextField
                  name={this.inputNameFor("artifact_paths")}
                  label=""
                  defaultValue={this.props.step.artifactPaths}
                  help={
                    <span>
                      {
                        "File paths for the artifacts to be automatically uploaded after the step has run (e.g. "
                      }
                      <code className="code">tmp/**/*.png</code>
                      {/*
                       */}
                      ). Separate each path with a semi-colon.
                    </span>
                  }
                  placeholder="No artifacts"
                />
              </CollapsableArea>
              {this._timeoutField()}
              {this._parallelJobsField()}
              {this._concurrencyField()}
            </div>
          </div>
        </div>
      ),
    };
  }

  _scriptNode() {
    return (
      <FormTextarea.Autosize
        name={this.inputNameFor("command")}
        rows={2}
        label="Commands to run"
        value={this.props.step.command}
        help={this._scriptHelp()}
        onChange={this.handleCommandInputChange}
        style={{
          resize: "vertical",
        }}
        className="monospace"
        ref={(commandTextareaComponent) =>
          (this.commandTextareaComponent = commandTextareaComponent)
        }
      />
    );
  }

  _scriptHelp() {
    if (
      this.props.step.command &&
      this.props.step.command.indexOf("buildkite-agent pipeline upload") !== -1
    ) {
      return (
        <span>
          {
            "This pipeline upload job will run on a matching agent, and upload the step configuration from the pipeline’s source repository. Read more about uploading and generating pipelines in the "
          }
          <a href="/docs/guides/uploading-pipelines" target="_blank">
            Uploading Pipeline Guide
          </a>
          {/*
           */}
          .
        </span>
      );
    }

    return (
      <span>
        {
          "A list of executable scripts or shell commands to run, relative to the root of the repository, each separated with a new line, e.g. "
        }
        <code className="code">script/tests.sh</code>
        {" or "}
        <code className="code">make</code>
        {/*
         */}
        . This step will pass only if all the commands return an exit status of
        zero.
      </span>
    );
  }

  _agentSelectorFieldNode() {
    const agentSelectorNode = () => {
      return (
        <div>
          <AgentQueryRules
            name={this.inputNameFor("agent_query_rules")}
            accountID={this.props.accountID}
            value={this.props.step.agentQueryRules}
            onChange={this.handleAgentQueryRulesChange}
          />
          <p className="help-block">
            A list of query rules for selecting an agent, each on a new line.
            e.g. <code className="code">ruby=true</code>
          </p>
        </div>
      );
    };

    return (
      <CollapsableArea
        label="Agent Targeting Rules"
        initialOpen={this.isValuePresent(this.props.step.agentQueryRules)}
        className="mb3"
      >
        {agentSelectorNode()}
      </CollapsableArea>
    );
  }

  _parallelJobsField() {
    return (
      <CollapsableArea
        label="Parallelism"
        initialOpen={this.isValuePresent(this.props.step.parallelJobs)}
        className="mb3"
      >
        <FormTextField
          name={this.inputNameFor("parallel_jobs")}
          defaultValue={this.props.step.parallelJobs}
          placeholder="1"
          help={
            <span>
              {
                "Number of parallel jobs to create when a build is started. Each job will be assigned an increasing "
              }
              <code className="code">$BUILDKITE_PARALLEL_JOB</code>
              {" environment variable starting at 0, and "}
              <code className="code">$BUILDKITE_PARALLEL_JOB_COUNT</code>
              {" will be the total number."}
            </span>
          }
        />
      </CollapsableArea>
    );
  }

  _concurrencyField() {
    return (
      <CollapsableArea
        label="Global Concurrency Limit"
        initialOpen={this.isValuePresent(this.props.step.concurrency)}
        className="mb3"
      >
        <FormTextField
          name={this.inputNameFor("concurrency")}
          defaultValue={this.props.step.concurrency}
          placeholder="No limit"
          help="The maximum number of allowed concurrent jobs across all builds."
        />
      </CollapsableArea>
    );
  }

  _timeoutField() {
    return (
      <CollapsableArea
        label="Automatic Timeout"
        initialOpen={this.isValuePresent(this.props.step.timeoutInMinutes)}
        className="mb3"
      >
        <FormTextField
          name={this.inputNameFor("timeout_in_minutes")}
          defaultValue={this.props.step.timeoutInMinutes}
          placeholder="No timeout"
          help="Number of minutes the step can run for before being automatically canceled."
        />
      </CollapsableArea>
    );
  }

  inputNameFor(name: string) {
    return `project[steps_attributes][${this.props.index}][${name}]`;
  }

  isValuePresent(
    value: string | number | Array<string> | null | undefined,
  ): boolean {
    if (Array.isArray(value)) {
      return value.length > 0 && value.toString() !== "";
      // @ts-expect-error - TS2358 - The left-hand side of an 'instanceof' expression must be of type 'any', an object type or a type parameter.
    } else if (typeof value === "string" || value instanceof String) {
      return value !== "";
    }
    return value !== undefined && value !== null;
  }

  handleNameInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    // @ts-expect-error - TS2554 - Expected 6 arguments, but got 2.
    LegacyDispatcher.emit("project_step:change", {
      step: this.props.step,
      attributes: {
        name: evt.target.value,
      },
    });
  };

  handleCommandInputChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    // @ts-expect-error - TS2554 - Expected 6 arguments, but got 2.
    LegacyDispatcher.emit("project_step:change", {
      step: this.props.step,
      attributes: {
        command: evt.target.value,
      },
    });
  };

  handleAgentQueryRulesChange = (agentQueryRules: Array<string>) => {
    // @ts-expect-error - TS2554 - Expected 6 arguments, but got 2.
    LegacyDispatcher.emit("project_step:change", {
      step: this.props.step,
      attributes: {
        agentQueryRules,
      },
    });
  };

  handleCloneStepClick = (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault();

    // @ts-expect-error - TS2554 - Expected 6 arguments, but got 2.
    LegacyDispatcher.emit("project_step:clone", {
      step: this.props.step,
    });
  };

  handleRemoveStepClick = (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault();

    if (confirm("Remove step?")) {
      // @ts-expect-error - TS2554 - Expected 6 arguments, but got 2.
      LegacyDispatcher.emit("project_step:destroy", {
        step: this.props.step,
      });
    }
  };
}

import { useBuild } from "app/components/Playground/BuildContext";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuTrigger,
} from "app/components/shared/DropdownMenu";
import Icon from "app/components/shared/Icon";

export function PipelineSettings() {
  const { build } = useBuild();
  if (!build) {
    throw new Error("Missing build context");
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        title="Pipeline settings"
        className="flex self-stretch items-center px-2.5 py-2.5 hover:bg-gray-200 rounded-md"
      >
        <Icon icon="down-triangle" className="flex-none w-2 h-2" />
      </DropdownMenuTrigger>
      <DropdownMenuPortal>
        <DropdownMenuContent>
          <DropdownMenuItem>
            <DropdownLink href={`${build.project.url}/settings`}>
              Settings
            </DropdownLink>
          </DropdownMenuItem>
          <DropdownMenuItem>
            <DropdownLink href={`${build.project.url}/settings/steps`}>
              Edit steps
            </DropdownLink>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenuPortal>
    </DropdownMenu>
  );
}

const DropdownLink = ({ href, children }) => (
  <a
    href={href}
    className="text-inherit focus:text-inherit hover:text-inherit grow"
  >
    {children}
  </a>
);

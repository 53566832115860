import ExecutionResults from "app/constants/analytics/ExecutionResults";
import ResultIcon from "app/components/icons/analytics/ResultIcon";

const SIZES = ["XSmall", "Small", "Regular"];

const iconMap = {
  [ExecutionResults.FAILED]: "FAILED",
  [ExecutionResults.PASSED]: "PASSED",
  [ExecutionResults.PENDING]: "PENDING",
  [ExecutionResults.SKIPPED]: "UNDEFINED",
  [ExecutionResults.UNKNOWN]: "UNDEFINED",
} as const;

const ExecutionResult: Record<string, any> = {};

SIZES.forEach((size) => {
  const component = (props: { result: string }) => {
    const icon = iconMap[props.result] || "UNDEFINED";
    // @ts-expect-error - TS2322 - Type 'string' is not assignable to type '"XSmall" | "Small" | "Regular"'.
    return <ResultIcon icon={icon} size={size} result={props.result} />;
  };

  component.displayName = `ExecutionResult.${size}`;

  ExecutionResult[size] = component;
});

export default ExecutionResult;

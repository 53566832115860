/**
 * @generated SignedSource<<469870e4db799a417d20e780617be795>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JobEventAssignedExpired_event$data = {
  readonly timestamp: any;
  readonly " $fragmentType": "JobEventAssignedExpired_event";
};
export type JobEventAssignedExpired_event$key = {
  readonly " $data"?: JobEventAssignedExpired_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"JobEventAssignedExpired_event">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "JobEventAssignedExpired_event",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timestamp",
      "storageKey": null
    }
  ],
  "type": "JobEvent",
  "abstractKey": "__isJobEvent"
};

(node as any).hash = "9e56d8ded9646190ba5945774e4859ce";

export default node;

import * as HoverCard from "@radix-ui/react-hover-card";
import classNames from "classnames";
import { forwardRef } from "react";
import { bgColorForStep } from "./colors";
import { NodeProps } from "reactflow";
import { Step } from "app/lib/pipeline/Step";
import { useBuild } from "../BuildContext";

export { Trigger, Portal } from "@radix-ui/react-hover-card";

type Props = NodeProps<Pick<Step, "state" | "outcome">> & {
  children: React.ReactNode;
};

export const Root = ({
  children,
}: React.ComponentProps<typeof HoverCard.Root>) => (
  <HoverCard.Root openDelay={300} closeDelay={200}>
    {children}
  </HoverCard.Root>
);

export const Content = forwardRef<
  React.ElementRef<typeof HoverCard.Content>,
  Props & { noBorder?: boolean }
>(({ data, children, noBorder }, ref) => {
  const { build } = useBuild();

  return (
    <HoverCard.Content
      ref={ref}
      side="top"
      sideOffset={20}
      className={classNames(
        "w-96 z-10 bg-white rounded-2 p-3 depth-800",
        "data-[state=open]:animate-[slideUp_100ms_cubic-bezier(0.16,1,0.3,1)_forwards]",
        "data-[state=closed]:animate-[slideDown_50ms_linear_forwards]",
        noBorder ? "" : "border border-t-4",
        bgColorForStep(data, build),
      )}
    >
      {children}
    </HoverCard.Content>
  );
});

import classNames from "classnames";

type Props = {
  className?: string;
};

const Indicator = ({ className }: Props) => (
  <span
    data-testid="indicator"
    className={classNames(
      "w-2.5 h-2.5 inline-block rounded-full bg-red-600 border border-white -translate-x-1/2 translate-y-1/2 ring-red-300 ring-2 animate-scaleInCenter",
      className,
    )}
  />
);

export default Indicator;

import { createElement } from "react";
import reactRender from "app/lib/reactRenderer";

export default class ReactComponentElement extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    const Component = window.require(this.getAttribute("path"));
    const args = JSON.parse(this.getAttribute("args"));

    reactRender(createElement(Component, args), this);
  }
}

if (!window.customElements.get("react-component")) {
  window.ReactComponentElement = ReactComponentElement;
  window.customElements.define("react-component", ReactComponentElement);
}

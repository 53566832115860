/**
 * @generated SignedSource<<2d76f3b908095f52a03abbd7c55d740d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RecoveryCodes_totp$data = {
  readonly id: string;
  readonly recoveryCodes: {
    readonly codes: ReadonlyArray<{
      readonly code: string;
      readonly consumed: boolean;
    }>;
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"RecoveryCodeList_recoveryCodes">;
  };
  readonly " $fragmentType": "RecoveryCodes_totp";
};
export type RecoveryCodes_totp$key = {
  readonly " $data"?: RecoveryCodes_totp$data;
  readonly " $fragmentSpreads": FragmentRefs<"RecoveryCodes_totp">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RecoveryCodes_totp",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "RecoveryCodeBatch",
      "kind": "LinkedField",
      "name": "recoveryCodes",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "RecoveryCodeList_recoveryCodes"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "RecoveryCode",
          "kind": "LinkedField",
          "name": "codes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "code",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "consumed",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TOTP",
  "abstractKey": null
};
})();

(node as any).hash = "46ca7b7ef3e89409c15e8e85430dcb75";

export default node;

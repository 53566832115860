import { useRef, Ref } from "react";
import styled from "styled-components";
import useFetch from "use-http";
import Skeleton from "react-loading-skeleton";

import Icon from "app/components/shared/Icon";
import EmojiAvatar from "app/components/shared/EmojiAvatar";
import Emojify from "app/components/shared/Emojify";
import Badge from "app/components/shared/Badge";
import { Suite } from "app/components/analytics/shared/type";

type ListItemProps = {
  suite: Suite;
  path: string;
  currentAccountMember: boolean;
  onBookmarkClick: (suite: Suite, buttonRef: Ref<HTMLButtonElement>) => void;
};

export const NAVATAR_DIMENSIONS = "60px";

const Wrapper = styled.div<{ displayManagedTests: boolean }>`
  display: grid;
  grid-template-columns: ${NAVATAR_DIMENSIONS} minmax(0, 1fr) ${({
      displayManagedTests,
    }) => (displayManagedTests ? "120px 120px" : "120px")} 20px;
  align-items: center;
  gap: 24px;
  padding: 30px;

  @media (max-width: 900px) {
    gap: 15px;
    padding: 15px;
  }
`;

const Link = styled.a`
  font-size: 16px;
  line-height: 18px;
  position: static;
  display: block;

  &:before {
    content: "";
    position: absolute;
    z-index: 0;
    inset: 0;
  }
`;

const StyledBadge = styled(Badge)`
  text-transform: uppercase;
  font-weight: 500;
  margin: 5px 5px 0 0;

  &.new-suite {
    background: rgba(255, 220, 0, 0.5);
    color: #633712;
  }
`;

const EmojiNavatar = styled(EmojiAvatar)`
  width: ${NAVATAR_DIMENSIONS};
  height: ${NAVATAR_DIMENSIONS};
  font-size: 24px;

  .emoji {
    width: 24px;
    height: 24px;
  }
`;

const LanguageBadge = styled(Emojify)<{ $color?: string }>`
  background-color: white;
  border-radius: 100%;
  border: 1px solid ${(props) => props.$color ?? "#F5F5F5"};
  position: absolute;
  width: 30px;
  height: 30px;
  top: -10px;
  right: -10px;
  font-size: 14px;

  .emoji {
    width: 14px;
    height: 14px;
  }
`;

const BookmarkButton = styled.button`
  z-index: 2;
  position: relative;
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;

  svg {
    stroke: var(--gray-500);
    fill: var(--base-0) !important;
  }

  &.ta-favorite-suite svg,
  &:hover svg,
  &:disabled svg {
    stroke: var(--orange-500);
    fill: var(--yellow-500) !important;
  }

  &:disabled {
    opacity: 0.5;

    &:before,
    &:after {
      display: none;
    }
  }
`;
const Navatar = ({ suite }) => {
  if (suite.emoji) {
    return (
      <div className="relative">
        {suite.language && (
          <LanguageBadge
            text={`:${suite.language}:`}
            className="flex items-center justify-center"
            $color={suite.color}
          />
        )}
        <EmojiNavatar emoji={suite.emoji} color={suite.color} />
      </div>
    );
  }

  if (suite.language) {
    return <EmojiNavatar emoji={`:${suite.language}:`} color={suite.color} />;
  }

  return (
    // @ts-expect-error - TS2769 - No overload matches this call.
    <EmojiNavatar
      fallbackIcon="heroicons/outline/chart-bar-square"
      color={suite.color}
    />
  );
};

const ListItem = ({
  suite,
  path,
  currentAccountMember,
  onBookmarkClick,
}: ListItemProps) => {
  const {
    loading,
    error,
    data = 0,
  } = useFetch(`${suite.endpoint}/weekly_runs`, []);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const displayManagedTests = !!suite.weekly_managed_tests;

  return (
    <li className="pb2">
      <Wrapper
        className="ta-suite-card"
        displayManagedTests={displayManagedTests}
      >
        <div className="flex justify-center items-center">
          <Navatar suite={suite} />
        </div>

        <div>
          {suite.application_name && (
            <p className="m0 charcoal-300 truncate">
              <Emojify text={suite.application_name} />
            </p>
          )}

          <Link
            href={path}
            className="bold color-inherit text-decoration-none"
            title={suite.name}
          >
            <Emojify text={suite.name_truncated} />
          </Link>

          {suite.new_suite && (
            <StyledBadge className="charcoal-800 new-suite">new</StyledBadge>
          )}
          {suite.public && (
            <StyledBadge className="bg-blue-100 blue-600">public</StyledBadge>
          )}
        </div>
        {displayManagedTests && (
          <div>
            <p className="m0 charcoal-300">Managed tests</p>
            <span className="h1 m0 line-height-1 medium">
              {suite.weekly_managed_tests}
            </span>
            <span className="h6 regular m0 line-height-1 charcoal-300">
              /week
            </span>
          </div>
        )}
        <div>
          {suite.runs_exist && (
            <div>
              <p className="m0 charcoal-300">Runs</p>
              {loading && (
                <span data-testid="loading-skeleton">
                  <Skeleton />
                </span>
              )}
              {error && (
                <span>There was an error loading the weekly runs.</span>
              )}
              {!loading && !error && (
                <>
                  <span className="h1 m0 line-height-1 medium">{data}</span>
                  <span className="h6 regular m0 line-height-1 charcoal-300">
                    /week
                  </span>
                </>
              )}
            </div>
          )}
          {!suite.runs_exist &&
            currentAccountMember &&
            suite.can_edit_suite && (
              <a href={path} className="btn btn-default py1 px2 z1 relative">
                Continue setup
              </a>
            )}
        </div>

        <div>
          {currentAccountMember && (
            <BookmarkButton
              ref={buttonRef}
              onClick={() => onBookmarkClick(suite, buttonRef)}
              aria-label={suite.favorite ? "Remove bookmark" : "Bookmark"}
              className={suite.favorite ? "ta-favorite-suite" : ""}
            >
              {/* @ts-expect-error - TS2322 - Type '{ icon: string; size: number; }' is not assignable to type 'IntrinsicAttributes & Props'. */}
              <Icon icon="heroicons/outline/star" size={24} />
            </BookmarkButton>
          )}
        </div>
      </Wrapper>
    </li>
  );
};

export default ListItem;

import { PureComponent } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import JobInformationStore from "app/stores/JobInformationStore";

type Props = {
  job: {
    id: string;
    basePath: string;
  };
};

type State = {
  env: {
    [key: string]: string;
  };
  showExport: boolean;
  copied: boolean;
};

const getStateFromStore = (jobId: string) =>
  JobInformationStore.get(jobId, "environment");

export default class JobEnvironment extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      env: getStateFromStore(props.job.id),
      showExport: false,
      copied: false,
    };
  }

  _refetchStore() {
    return JobInformationStore.refetch(
      this.props.job.id,
      "environment",
      this.props.job.basePath + "/environment",
    );
  }

  componentDidMount() {
    JobInformationStore.addChangeListener(this.handleStoreChange);
    this._refetchStore();
  }

  componentWillUnmount() {
    JobInformationStore.removeChangeListener(this.handleStoreChange);
  }

  renderEnvNode() {
    if (this.state.env) {
      let deprecatedNode, value;
      const nonDeprecated: Array<string> = [];

      for (const key in this.state.env) {
        value = this.state.env[key];
        if (
          key.indexOf("BUILDKITE_PROJECT") === 0 ||
          key.indexOf("BUILDBOX_PROJECT") === 0 ||
          key === "BUILDKITE_STEP_IDENTIFIER"
        ) {
          // deprecated
        } else if (key.indexOf("BUILDBOX") === -1) {
          nonDeprecated.push(this.shellFriendlyEnvironmentString(key, value));
        }
      }

      const deprecated: Record<string, any> = {};
      let hasDeprecated = false;

      for (const key in this.state.env) {
        value = this.state.env[key];
        if (key.indexOf("BUILDBOX") === 0) {
          hasDeprecated = true;
          deprecated[key] = value;
        }
        if (key === "BUILDKITE_STEP_IDENTIFIER") {
          hasDeprecated = true;
          deprecated[key] = value;
        }
        if (
          key.indexOf("BUILDKITE_PROJECT") === 0 ||
          key.indexOf("BUILDBOX_PROJECT") === 0
        ) {
          hasDeprecated = true;
          deprecated[key] = value;
        }
      }

      if (hasDeprecated) {
        deprecatedNode = (
          <div>
            <h3 className="h4 mt2 mb1 pb0">Deprecated Environment Variables</h3>
            <p className="p0 m0 mb2">
              The following environment variables have been deprecated, and
              should no longer be used:
            </p>
            <pre className="code">
              <code>
                {Object.keys(deprecated).map(
                  (key) =>
                    this.shellFriendlyEnvironmentString(key, deprecated[key]) +
                    "\n",
                )}
              </code>
            </pre>
          </div>
        );
      }

      return (
        <div>
          <pre className="code">
            <code>{nonDeprecated.sort().map((line) => `${line}\n`)}</code>
          </pre>
          {deprecatedNode}
        </div>
      );
    }

    return <span>Loading…</span>;
  }

  render() {
    return (
      <div data-testid="JobEnvironmentComponent" className="relative pb-2">
        {this.renderExportButton()}
        {this.renderCopyButton()}
        {this.renderEnvNode()}
      </div>
    );
  }

  renderExportButton() {
    const label = this.state.showExport ? "Hide" : "Show";

    return (
      <button
        className="btn btn-outline border-gray hover-black bg-white absolute border regular rounded h5"
        style={{ top: "10px", right: "10px", fontSize: "13px" }}
        onClick={this.handleShowExportClick}
      >
        {label} <code className="monospace">export</code>
        {" Prefix"}
      </button>
    );
  }

  renderCopyButton() {
    const env: Array<string> = [];
    for (const key in this.state.env) {
      env.push(this.shellFriendlyEnvironmentString(key, this.state.env[key]));
    }

    return (
      <CopyToClipboard
        text={env.join("\n")}
        onCopy={this.handleCopyToClipboard}
      >
        <button
          className="btn btn-outline border-gray hover-black bg-white absolute border regular rounded h5"
          style={{ top: "10px", right: "170px", fontSize: "13px" }}
          disabled={this.state.copied}
        >
          {this.state.copied ? "Copied to Clipboard!" : "Copy to Clipboard"}
        </button>
      </CopyToClipboard>
    );
  }

  handleCopyToClipboard = (_text: string, result: string) => {
    if (result) {
      this.setState({ copied: true });
      setTimeout(() => {
        this.setState({ copied: false });
      }, 1000);
    } else {
      alert(
        "We couldnʼt put this in your clipboard for you, please copy it manually!",
      );
    }
  };

  handleShowExportClick = () => {
    this.setState({ showExport: !this.state.showExport });
  };

  handleStoreChange = () => {
    this.setState({ env: getStateFromStore(this.props.job.id) });
  };

  shellFriendlyEnvironmentString(key: string, value: string) {
    const valueWithEscapedDoubleQuotes = value.replace(/([\\"])/g, "\\$1");

    const envString = `${key}="${valueWithEscapedDoubleQuotes}"`;

    if (this.state.showExport) {
      return `export ${envString}`;
    }

    return envString;
  }
}

import React from "react";
import Button from "app/components/shared/Button";
import styled from "styled-components";
import Spinner from "app/components/shared/Spinner";
import Icon from "app/components/shared/Icon";
import SkipLink from "../SkipLink";

interface Props {
  step: number;
  of: number;
  title: string;
  onSubmit: () => void;
  disabled?: boolean;
  children?: React.ReactNode;
  loading?: boolean;
  completed?: boolean;
  /*
  Steps progress will only show if the user remains in the questionnaire flow after the product selection step.
  This is to help avoid the confusion of a user thinking they're missing steps if redirected on selecting Test Analytics or Packages.
  */
  displayProgress?: boolean;
}

export const Container = styled.div`
  width: 100%;
  padding-top: 3em;
  padding-bottom: 2em;
`;

export const Progress = styled.div`
  margin-bottom: 1em;
  font-size: 0.85em;
  color: var(--charcoal-300);
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
`;

export const Title = styled.h3`
  margin-top: 0;
  margin-bottom: 1em;
  font-size: 1.5em;
`;

export const Content = styled.div`
  margin-bottom: 1.35em;
`;

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 1em;
`;

const AnimatedIcon = styled(Icon)`
  @keyframes check {
    0% {
      transform: scale(0.2);
    }
    100% {
      transform: scale(1);
    }
  }

  color: var(--lime-500);
  animation: check 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
`;

export default function Step(props: Props) {
  /*
  Steps progress will only show if the user remains in the questionnaire flow after the product selection step.
  This is to help avoid the confusion of a user thinking they're missing steps if redirected on selecting Test Analytics or Packages.
  */

  return (
    <Container data-testid={`step-${props.step}`}>
      <form onSubmit={props.onSubmit}>
        <Header>
          <Title>{props.title}</Title>
          {props.displayProgress && (
            <Progress>
              {props.step}&nbsp;/&nbsp;{props.of}
            </Progress>
          )}
        </Header>
        <Content>{props.children}</Content>
        <ButtonGroup>
          <Button type="submit" theme="onboarding" disabled={props.disabled}>
            Continue
          </Button>
          {props.loading && <Spinner size="1.5em" color={false} />}
          {props.completed && <AnimatedIcon icon="check-large" />}
          {props.displayProgress && <SkipLink />}
        </ButtonGroup>
      </form>
    </Container>
  );
}

import { useContext, useEffect } from "react";

import Role from "./Role";
import Products from "./Products";
import Goals from "./Goals";
import Vendors from "./Vendors";
import Complete, { Product } from "./Complete";
import styled from "styled-components";
import { useLocationStep } from "./useLocationStep";
import { AnimatePresence } from "framer-motion";
import SlideIn from "./SlideIn";
import Hello from "./Hello";
import Organization from "./Organization";
import { OrganizationContext } from "../OrganizationContext";

const Container = styled.div`
  position: relative;
`;

const Box = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 100%;
  height: 100%;
`;

export const STEPS = [Organization, Products, Goals, Role, Vendors, Complete];

const match = (step: number) => {
  return STEPS[step - 1];
};

interface Props {
  product?: Product;
  name: string;
}

export default function Steps(props: Props) {
  const [step, direction, pushStep, replaceStep] = useLocationStep();
  const { organization } = useContext(OrganizationContext);

  useEffect(() => {
    // Everything after the first step requires an organization
    if (step > 1 && !organization) {
      return replaceStep(1);
    }

    // If the step is invalid, go to the first step
    if (!match(step)) {
      return replaceStep(1);
    }

    if (window.analytics) {
      window.analytics.page("Welcome", {
        name: "Welcome",
        step,
      });
    }
  }, [step]);

  if (step > 1 && !organization) {
    return null;
  }

  const Step = match(step);
  if (!Step) {
    return null;
  }

  return (
    <Container>
      <AnimatePresence initial={false} custom={direction}>
        {step < STEPS.length ? (
          <SlideIn direction={direction} key="form">
            <Box>
              <Hello name={props.name} />

              <Container>
                <AnimatePresence initial={false} custom={direction}>
                  <SlideIn direction={direction} key={step}>
                    <Step
                      step={step}
                      of={STEPS.length - 1}
                      displayProgress={step >= 3}
                      // eslint-disable-next-line react/jsx-no-bind
                      onSubmit={() => pushStep(step + 1)}
                    />
                  </SlideIn>
                </AnimatePresence>
              </Container>
            </Box>
          </SlideIn>
        ) : (
          <SlideIn direction={direction} key="complete">
            {/* Render the same element hidden to preserve the height/spacing of the page */}
            <div style={{ visibility: "hidden" }}>
              <Hello name={props.name} />
            </div>

            <Complete product={props.product} />
          </SlideIn>
        )}
      </AnimatePresence>
    </Container>
  );
}

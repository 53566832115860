import { QueryRenderer, graphql } from "react-relay";
import Environment from "app/lib/relay/environment";

import usePreloadedRelayQuery from "app/lib/usePreloadedRelayQuery";

import AgentsCount from "./AgentsCount";

type Props = {
  organizationID: string;
};

export default function AgentsCountRenderer({
  organizationID,
  ...rest
}: Props) {
  const environment = Environment.get();
  const query = graphql`
    query AgentsCountRendererQuery($organizationID: ID!) {
      organization: node(id: $organizationID) {
        ...AgentsCount_organization
      }
    }
  `;

  usePreloadedRelayQuery(
    true,
    {
      environment,
      query,
      variables: { organizationID: organizationID },
    },
    [organizationID],
  );

  function renderQuery({ props }: { props: any }) {
    return <AgentsCount {...props} {...rest} />;
  }

  return (
    <QueryRenderer
      fetchPolicy="store-and-network"
      cacheConfig={{ force: true }}
      environment={environment}
      query={query}
      variables={{ organizationID: organizationID }}
      render={renderQuery}
    />
  );
}

import React from "react";
import styled from "styled-components";

export const Title = styled.div`
  margin-bottom: 0.5em;
`;

const CardStyled = styled.div`
  background-color: var(--base-0);
  box-shadow:
    0px 3px 0px 1px rgba(0, 0, 0, 0.05),
    0px 0px 0px 1px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 1em;
  overflow: hidden;
`;

const Card: React.FC<{ title?: string }> = (props) => {
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {props.title && <Title>{props.title}</Title>}
      <CardStyled>{props.children}</CardStyled>
    </>
  );
};

export default Card;

import moment from "moment";

export default class TimeWithFormatElement extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    let date;
    if (this.hasAttribute("datetime")) {
      date = moment(this.getAttribute("datetime"));
    } else {
      date = moment();
    }

    this.textContent = date.format(this.getAttribute("format"));
  }
}

if (!window.customElements.get("time-with-format")) {
  window.TimeWithFormatElement = TimeWithFormatElement;
  window.customElements.define("time-with-format", TimeWithFormatElement);
}

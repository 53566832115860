import { useEffect, useRef, useCallback } from "react";
import { createConsumer } from "app/vendor/actioncable";
import EmptyState from "app/components/shared/EmptyState";

type Props = {
  cableUrl: string;
  runId: string;
  firstRun: boolean;
};

type Result = {
  uuid: string;
  result: string;
  duration: number;
};

type Message = { results: Result[] } | { event: string };

const handleRunFinished = (res: Message) => {
  if ("event" in res && res.event === "run.finished") {
    location.reload();
  }
};

const RunLoadingState = ({ cableUrl, runId, firstRun }: Props) => {
  const consumer = useRef(createConsumer(cableUrl));

  useEffect(() => {
    if (consumer.current) {
      consumer.current.subscriptions.create(
        {
          channel: "Analytics::RunChannel",
          uuid: runId,
        },
        {
          received: (res: Message) => {
            processMessage(res);
          },
        },
      );
    }
    return () => {
      if (consumer.current) {
        consumer.current.disconnect();
      }
    };
  }, []);

  const processMessage = useCallback(handleRunFinished, []);

  if (firstRun) {
    return (
      <EmptyState
        heading="Spilling the beans on quirky test behaviors"
        subheading="Your first run is being processed and analyzed. Check back here soon for updates and insights on your tests."
        emoji="🪴"
        variant="blue-gradient"
      />
    );
  }

  return (
    <EmptyState
      heading="Still processing data"
      subheading="We're processing this run — check back soon."
      emoji="🤖"
    />
  );
};

export default RunLoadingState;

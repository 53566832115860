import { formatNumber } from "app/lib/number";

export default class NumberWithFormatElement extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.textContent = formatNumber(this.getAttribute("number"));
  }
}

if (!window.customElements.get("number-with-format")) {
  window.NumberWithFormatElement = NumberWithFormatElement;
  window.customElements.define("number-with-format", NumberWithFormatElement);
}

/**
 * @generated SignedSource<<ba6d87d5b5a641b2d993080f059cc88e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type JobStates = "ACCEPTED" | "ASSIGNED" | "BLOCKED" | "BLOCKED_FAILED" | "BROKEN" | "CANCELED" | "CANCELING" | "EXPIRED" | "FINISHED" | "LIMITED" | "LIMITING" | "PENDING" | "RUNNING" | "SCHEDULED" | "SKIPPED" | "TIMED_OUT" | "TIMING_OUT" | "UNBLOCKED" | "UNBLOCKED_FAILED" | "WAITING" | "WAITING_FAILED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AgentJobsList_agent$data = {
  readonly id: string;
  readonly jobs: {
    readonly count: number;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly passed?: boolean;
        readonly state?: JobStates;
        readonly uuid?: string;
        readonly " $fragmentSpreads": FragmentRefs<"AgentJobRow_job">;
      } | null;
    } | null> | null;
    readonly " $fragmentSpreads": FragmentRefs<"ShowMoreFooter_connection">;
  } | null;
  readonly name: string;
  readonly organization: {
    readonly name: string;
    readonly slug: string;
  } | null;
  readonly uuid: string;
  readonly " $fragmentType": "AgentJobsList_agent";
};
export type AgentJobsList_agent$key = {
  readonly " $data"?: AgentJobsList_agent$data;
  readonly " $fragmentSpreads": FragmentRefs<"AgentJobsList_agent">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": 25,
      "kind": "LocalArgument",
      "name": "pageSize"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "AgentJobsList_agent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "pageSize"
        }
      ],
      "concreteType": "JobConnection",
      "kind": "LinkedField",
      "name": "jobs",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ShowMoreFooter_connection"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "JobEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "state",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "passed",
                      "storageKey": null
                    }
                  ],
                  "type": "JobTypeCommand",
                  "abstractKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "AgentJobRow_job"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "count",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Agent",
  "abstractKey": null
};
})();

(node as any).hash = "27ece849b1a944ebf65c72428080985d";

export default node;

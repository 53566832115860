import React from "react";

export default function NoBuildsMessage({
  state,
  creator,
  emptyContext = "There are",
}: {
  state?: string | string[];
  creator?: string;
  emptyContext?: string;
}) {
  const prefix = creator === "me" ? "You have" : emptyContext;

  if (state && state.length > 0) {
    // Running is a special case as it can be an array used to include "Failing" as a running state
    if (state === "running" || state?.includes("running")) {
      return <p>{prefix} no running builds</p>;
    }

    // Not Run is a special case because it doesn't read well when directly interpolated
    if (state === "not_run") {
      return <p>{prefix} no builds that haven&apos;t been run</p>;
    }

    // The rest of the build states interpolate nicely here
    return (
      <p>
        {prefix} no {state} builds
      </p>
    );
  }

  return <p>{prefix} no builds</p>;
}

/**
 * @generated SignedSource<<d18bc8b1adc3946c3c7f6f102ac06cbb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Pipeline_pipeline$data = {
  readonly color: string | null;
  readonly defaultBranch: string | null;
  readonly description: string | null;
  readonly emoji: string | null;
  readonly favorite: boolean;
  readonly id: string;
  readonly name: string;
  readonly organization: {
    readonly slug: string;
  };
  readonly permissions: {
    readonly pipelineFavorite: {
      readonly allowed: boolean;
    };
    readonly pipelineUpdate: {
      readonly allowed: boolean;
    };
  };
  readonly public: boolean;
  readonly slug: string;
  readonly tags: ReadonlyArray<{
    readonly label: string;
    readonly " $fragmentSpreads": FragmentRefs<"Tag_tag">;
  }>;
  readonly url: string;
  readonly uuid: string;
  readonly " $fragmentSpreads": FragmentRefs<"BranchSettingsDialog_pipeline" | "Graph_pipeline" | "Metrics_pipeline" | "Status_pipeline">;
  readonly " $fragmentType": "Pipeline_pipeline";
};
export type Pipeline_pipeline$key = {
  readonly " $data"?: Pipeline_pipeline$data;
  readonly " $fragmentSpreads": FragmentRefs<"Pipeline_pipeline">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "allowed",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "includeGraphData"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "Pipeline_pipeline",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Status_pipeline"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Metrics_pipeline"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BranchSettingsDialog_pipeline"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "includeGraphData",
          "variableName": "includeGraphData"
        }
      ],
      "kind": "FragmentSpread",
      "name": "Graph_pipeline"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "uuid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "public",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "defaultBranch",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "favorite",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "color",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "emoji",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PipelineTag",
      "kind": "LinkedField",
      "name": "tags",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Tag_tag"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PipelinePermissions",
      "kind": "LinkedField",
      "name": "permissions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Permission",
          "kind": "LinkedField",
          "name": "pipelineFavorite",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Permission",
          "kind": "LinkedField",
          "name": "pipelineUpdate",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Pipeline",
  "abstractKey": null
};
})();

(node as any).hash = "32671553f1aae407acb415a79e572ed1";

export default node;

import EmptyState from "app/components/shared/EmptyState";

export default function IncompatibleBuild() {
  return (
    <div className="w-full">
      <EmptyState
        emoji="ℹ️"
        heading="Incompatible build"
        subheading="The waterfall chart is not backward-compatible with all builds. You'll see it display on newer builds."
      />
    </div>
  );
}

import * as React from "react";
import { createRefetchContainer, graphql } from "react-relay";

import queryString from "query-string";
import Confetti from "react-confetti";
import { useWindowSize } from "react-use-size";

import Button from "app/components/shared/Button";
import Dialog from "app/components/shared/Dialog";
import Emojify from "app/components/shared/Emojify";

const FullScreenConfetti = () => {
  const { width, height } = useWindowSize();

  return <Confetti width={width} height={height} />;
};

type Props = {
  organization: {
    slug: string;
    agents: {
      count: number;
      edges: Array<{
        node: {
          name: string;
        };
      }>;
    };
  };
  relay: any;
};

type State = {
  isDialogOpen: boolean;
};

class AgentConnectedDialog extends React.PureComponent<Props, State> {
  _agentRefreshTimeout: number | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      isDialogOpen: false,
    };
  }

  componentDidMount() {
    this.props.relay.refetch(
      {
        isMounted: true,
        slug: this.props.organization.slug,
      },
      null,
      (error) => {
        if (!error) {
          this.startTimeout();
        }
      },
    );
  }

  componentWillUnmount() {
    if (this._agentRefreshTimeout) {
      clearTimeout(this._agentRefreshTimeout);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (
      nextProps.organization.agents &&
      nextProps.organization.agents.count >
        (this.props.organization?.agents?.count || 0)
    ) {
      this.setState({ isDialogOpen: true });
    }
  }

  startTimeout = () => {
    this._agentRefreshTimeout = setTimeout(this.fetchUpdatedData, 5000);
  };

  fetchUpdatedData = () => {
    this.props.relay.refetch(
      {
        isMounted: true,
        slug: this.props.organization.slug,
      },
      null,
      (error) => {
        if (!error) {
          this.startTimeout();
        }
      },
      { force: true },
    );
  };

  render() {
    const { organization } = this.props;
    const { isDialogOpen } = this.state;

    // This page doesn't have a list of agents, so if they haven ANY agents
    // connected then they shouldn't be looking here, with the exception of if
    // it's the first time connected agent with the nice dialog
    if (
      !isDialogOpen &&
      organization.agents &&
      organization.agents &&
      organization.agents.count
    ) {
      const url = new URL(window.location.href);
      url.searchParams.delete("setup");

      // @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'Location'.
      window.location = url.toString();
    }

    return (
      <>
        <Dialog
          isOpen={isDialogOpen}
          onRequestClose={this.handleFirstAgentDialogClose}
          width={350}
        >
          <div
            className="center p4"
            style={{ paddingTop: 45, paddingBottom: 50 }}
          >
            <p className="m0 mb2">
              <Emojify text=":raised_hands:" style={{ fontSize: 32 }} />
            </p>

            <p
              className="m0 h3 bold mb2"
              style={{ paddingLeft: "1.5em", paddingRight: "1.5em" }}
            >
              Your first agent is connected and ready to work!
            </p>
            {this.renderCallToAction()}
          </div>
        </Dialog>
        {isDialogOpen && <FullScreenConfetti />}
      </>
    );
  }

  renderCallToAction() {
    const query = queryString.parse(window.location.search);
    const pipelineSlug = query.return_to_pipeline;

    // If coming directly from a pipeline, push user towards triggering a build.
    if (query.return_to_pipeline) {
      return (
        <TriggerBuildButton
          message="First build"
          commit="HEAD"
          branch="main"
          organizationSlug={this.props.organization.slug}
          pipelineSlug={pipelineSlug}
        >
          Run your first build
        </TriggerBuildButton>
      );
    }

    if (query.return_to_build) {
      return (
        <Button
          href={`/${this.props.organization.slug}/${query.pipeline_slug}/builds/${query.return_to_build}`}
          className="mt2"
        >
          Back to the build
        </Button>
      );
    }

    return (
      <Button href={`/${this.props.organization.slug}`} className="mt2">
        Manage your pipelines
      </Button>
    );
  }

  handleFirstAgentDialogClose = () => {
    const query = queryString.parse(window.location.search);
    const pipelineSlug = query.return_to_pipeline;

    if (query.return_to_pipeline) {
      return (window.location.href = `/${this.props.organization.slug}/${pipelineSlug}`);
    }

    this.setState({ isDialogOpen: false });
  };
}

export default createRefetchContainer(
  AgentConnectedDialog,
  {
    organization: graphql`
      fragment AgentConnectedDialog_organization on Organization
      @argumentDefinitions(
        isMounted: { type: "Boolean!", defaultValue: false }
      ) {
        slug
        agents(last: 1) @include(if: $isMounted) {
          count
          edges {
            node {
              name
            }
          }
        }
      }
    `,
  },
  graphql`
    query AgentConnectedDialogRefetchQuery($slug: ID!, $isMounted: Boolean!) {
      organization(slug: $slug) {
        ...AgentConnectedDialog_organization @arguments(isMounted: $isMounted)
      }
    }
  `,
);

function TriggerBuildButton(props: {
  pipelineSlug: string;
  organizationSlug: string;
  branch: string;
  commit: string;
  message: string;
  children: React.ReactNode;
}) {
  return (
    <form
      action={`/organizations/${props.organizationSlug}/pipelines/${props.pipelineSlug}/builds`}
      method="POST"
    >
      <input type="hidden" name="utf8" value="✓" />
      <input
        type="hidden"
        name={window._csrf.param}
        value={window._csrf.token}
      />
      <input type="hidden" name="build[commit]" value={props.commit} />
      <input type="hidden" name="build[branch]" value={props.branch} />
      <input type="hidden" name="build[message]" value={props.message} />

      <Button>{props.children}</Button>
    </form>
  );
}

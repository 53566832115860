import { createFragmentContainer, graphql } from "react-relay";
import JobEvent from "../JobEvent";

type Props = {
  event: any;
  previousTimestamp: string | null | undefined;
};

function JobEventScheduled({ event, previousTimestamp }: Props) {
  return (
    <JobEvent timestamp={event.timestamp} previousTimestamp={previousTimestamp}>
      <JobEvent.Label>Dispatcher Scheduled Job</JobEvent.Label>
    </JobEvent>
  );
}

export default createFragmentContainer(JobEventScheduled, {
  event: graphql`
    fragment JobEventScheduled_event on JobEvent {
      ... on JobEvent {
        timestamp
      }
    }
  `,
});

declare let jQuery: JQueryStatic & {
  payment: {
    cardType: (number: string) => string;
  };
};

type PinPaymentsCardFieldName =
  | "name"
  | "number"
  | "expiry_month"
  | "expiry_year"
  | "cvc"
  | "address_postcode"
  | "address_country";
type PinPaymentsCardFields = Partial<Record<PinPaymentsCardFieldName, string>>;
type PinPaymentsSuccessResponse = {
  token: string;
  scheme: string;
  display_number: string;
};

type StripeCardFieldName = "name" | "number" | "exp_month" | "exp_year" | "cvc";
type StripeCardFields = Partial<Record<StripeCardFieldName, string>>;
type StripeSuccessResponse = {
  token: string;
  brand: string;
  last4: string;
};

export type CardErrorFieldNames =
  | PinPaymentsCardFieldName
  | StripeCardFieldName
  | "_";

type ErrorResponse = Partial<Record<CardErrorFieldNames, Array<string>>>;

declare let Stripe: {
  setPublishableKey: (key: string) => unknown;
  card: {
    createToken: (
      card: StripeCardFields,
      callback: (status: unknown, response: any) => unknown,
    ) => unknown;
  };
};

export type CardScheme = {
  id: string;
  provider: string;
  aliases: Array<string>;
};

export type CreditCardOptions = {
  provider?: string;
  cardSchemes: Array<CardScheme>;
  pin: {
    key: string;
    endpoint: string;
  };
  stripe: {
    key: string;
  };
};

type SuccessCallback = (
  provider: string,
  data: PinPaymentsSuccessResponse | StripeSuccessResponse,
) => unknown;
type ErrorCallback = (provider: string, errors: ErrorResponse) => unknown;

// The key is what Stripe expects, the value is the key that we have.
const PIN_TO_STRIPE_FIELD_MAP: Partial<
  Record<PinPaymentsCardFieldName, StripeCardFieldName>
> = {
  name: "name",
  number: "number",
  cvc: "cvc",
  expiry_month: "exp_month",
  expiry_year: "exp_year",
};

const STRIPE_TO_PIN_FIELD_MAP: Partial<
  Record<StripeCardFieldName, PinPaymentsCardFieldName>
> = Object.keys(PIN_TO_STRIPE_FIELD_MAP).reduce<Record<string, any>>(
  (acc, key) => {
    acc[PIN_TO_STRIPE_FIELD_MAP[key]] = key;
    return acc;
  },
  {},
);

export default class CreditCard {
  static FIELDS: Array<PinPaymentsCardFieldName> = [
    "name",
    "number",
    "expiry_month",
    "expiry_year",
    "cvc",
    "address_postcode",
    "address_country",
  ];

  // Given an array of card schemes and a card number,
  // returns the related card scheme.
  static findScheme = function (
    cardSchemes: Array<CardScheme>,
    cardNumber: string,
  ): CardScheme | null | undefined {
    const cardType = jQuery.payment.cardType(cardNumber);

    return cardSchemes.find(
      (scheme) =>
        scheme["id"] === cardType || scheme["aliases"].indexOf(cardType) !== -1,
    );
  };

  options: CreditCardOptions;
  fields: PinPaymentsCardFields;

  constructor(options: CreditCardOptions, fields: PinPaymentsCardFields) {
    this.options = options;
    this.fields = fields || {};
  }

  validate() {
    CreditCard.FIELDS.forEach((field) => {
      if (
        !(
          this.fields &&
          Object.prototype.hasOwnProperty.call(this.fields, field)
        )
      ) {
        this._error("Missing field: " + field.toString());
      }
    });
  }

  // Either use the provider passed as an option, or figure it out based on
  // the card details.
  provider() {
    if (this.options.provider) {
      return this.options.provider;
    }

    // Find the card scheme based on the card number
    const cardScheme = CreditCard.findScheme(
      this.options.cardSchemes,
      // @ts-expect-error - TS2345 - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
      this.fields.number,
    );

    // Return the provider information
    if (cardScheme) {
      return cardScheme.provider;
    }

    // Default to stripe if there isn't a provider. If the users enters a
    // credit card type that we don't support, then we need to send the card
    // somewhere, and Stripe will just reject any card type that it doesn't
    // support.
    return "stripe";
  }

  save(options: { success: SuccessCallback; error: ErrorCallback }) {
    this.validate();

    const provider = this.provider();

    if (provider === "pin_payments") {
      this._pin(
        this.options.pin.key,
        this.options.pin.endpoint,
        options.success,
        options.error,
      );
    } else if (provider === "stripe") {
      this._stripe(this.options.stripe.key, options.success, options.error);
    } else {
      this._error("Unknown provider: " + provider.toString());
    }
  }

  _pin(
    key: string,
    endpoint: string,
    successCallback: SuccessCallback,
    errorCallback: ErrorCallback,
  ) {
    jQuery.ajax({
      url: endpoint + "/1/cards.json?callback=?",
      dataType: "jsonp",
      data: Object.assign(
        { _method: "POST", publishable_api_key: key },
        this.fields,
      ),
      complete: (xhr, _status) => {
        const json = xhr.responseJSON;

        if (json && json["response"]) {
          const response = json["response"];

          // We only need these 3 parameters
          successCallback("pin_payments", {
            token: response["token"],
            scheme: response["scheme"],
            display_number: response["display_number"],
          });
        } else if (json && json["error"] === "invalid_resource") {
          // Construct a hash with the field name as the key, and an array
          // of error messages.
          const errors: Record<string, any> = {};
          json["messages"] &&
            json["messages"].forEach((message) => {
              const fieldName = message["param"];
              if (
                !(
                  errors &&
                  Object.prototype.hasOwnProperty.call(errors, fieldName)
                )
              ) {
                errors[fieldName] = [];
              }
              errors[fieldName].push(message["message"]);
            });

          errorCallback("pin_payments", errors);
        } else {
          this._error(
            "There was an error communicating with Pin Payments: #{url} (#{xhr.status})",
          );
        }
      },
    });
  }

  _stripe(
    key: string,
    successCallback: SuccessCallback,
    errorCallback: ErrorCallback,
  ) {
    Stripe.setPublishableKey(key);

    // Create our card details hash
    const cardDetails: Record<string, any> = {};

    Object.keys(this.fields).forEach((key) => {
      if (
        PIN_TO_STRIPE_FIELD_MAP &&
        Object.prototype.hasOwnProperty.call(PIN_TO_STRIPE_FIELD_MAP, key)
      ) {
        cardDetails[PIN_TO_STRIPE_FIELD_MAP[key]] = this.fields[key];
      }
    });

    // Create the stripe token
    Stripe.card.createToken(cardDetails, (status, response) => {
      const error = response["error"];

      if (error) {
        // Turn the Stripe param into the equiv local field name
        const fieldKey = STRIPE_TO_PIN_FIELD_MAP[error["param"]] || "_";

        // Construct the same error format that we provide from the Pin
        // function.
        const errors: ErrorResponse = {};

        errors[fieldKey] = [error["message"]];

        errorCallback("stripe", errors);
      } else {
        successCallback("stripe", {
          token: response["id"],
          brand: response["card"]["brand"],
          last4: response["card"]["last4"],
        });
      }
    });
  }

  _error(message?: string | null) {
    if (!message) {
      message = "An unexpected error occured. Please try again.";
    }

    if (window["console"]) {
      console.error(message); // eslint-disable-line no-console
    }

    alert(message);
  }
}

/**
 * @generated SignedSource<<23127458c740b5ca140358e8f24a5a2b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JobEventAgentDisconnected_event$data = {
  readonly timestamp: any;
  readonly " $fragmentType": "JobEventAgentDisconnected_event";
};
export type JobEventAgentDisconnected_event$key = {
  readonly " $data"?: JobEventAgentDisconnected_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"JobEventAgentDisconnected_event">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "JobEventAgentDisconnected_event",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timestamp",
      "storageKey": null
    }
  ],
  "type": "JobEvent",
  "abstractKey": "__isJobEvent"
};

(node as any).hash = "7b4d15a17b0f8191e66e4fd9cce00bfa";

export default node;

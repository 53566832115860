import { BaseStep, Type, Step } from ".";
import { CommandStep as CommandStepSchema } from "../schema";
import { Plugin, normalizePlugins } from "../plugins";

export interface CommandStep extends BaseStep {
  type: Type.Command;
  label: string;
  commandScript?: string;
  commandParallelism?: number;
  commandMatrixDimensions?: { [key: string]: string[] };
  plugins?: [string, Plugin][];
  artifactPaths?: string[];
}

export function isDefaultCommandStep(step: CommandStep): step is CommandStep {
  return !isParallelCommandStep(step) && !isMatrixCommandStep(step);
}

export function isParallelCommandStep(
  step: Step,
): step is CommandStep & { commandParallelism: number } {
  return (
    step.type === "command" &&
    Boolean(step.commandParallelism && step.commandParallelism > 1)
  );
}

export function isMatrixCommandStep(step: Step): step is CommandStep & {
  commandMatrixDimensions: { [key: string]: string[] };
} {
  return (
    step.type === "command" &&
    Array.isArray(step.commandMatrixDimensions) &&
    step.commandMatrixDimensions.length > 0
  );
}

const normalizeCommands = (config: CommandStepSchema): string => {
  if (typeof config.command === "string") {
    return config.command;
  } else if (typeof config.commands === "string") {
    return config.commands;
  } else if (Array.isArray(config.command)) {
    return config.command.join("\n");
  } else if (Array.isArray(config.commands)) {
    return config.commands.join("\n");
  }
  return "";
};

function normalizeArtifactPaths(
  paths: CommandStepSchema["artifact_paths"],
): string[] {
  if (!paths) {
    return [];
  }

  if (typeof paths === "string") {
    return [paths];
  }

  return paths;
}

export function isCommandStep(step: any): step is CommandStepSchema {
  return (
    step.type === "command" ||
    step.commands !== undefined ||
    step.command !== undefined ||
    step.plugins !== undefined
  );
}

export function parseCommandStep(config: CommandStepSchema): CommandStep {
  const step: CommandStep = {
    uuid: "",
    dependencies: [],
    type: Type.Command,
    label: config.label || "",
  };

  const commandScript = normalizeCommands(config);
  if (commandScript.length) {
    step.commandScript = commandScript;
  }

  const plugins = normalizePlugins(config.plugins);
  if (plugins.length) {
    step.plugins = plugins;
  }

  const artifactPaths = normalizeArtifactPaths(config.artifact_paths);
  if (artifactPaths.length) {
    step.artifactPaths = artifactPaths;
  }

  return step;
}

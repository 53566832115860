/**
 * @generated SignedSource<<1e84ef39ed99b7264889f4bba9d784d6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Pipelines_organization$data = {
  readonly allPipelines: {
    readonly count: number;
  } | null;
  readonly id: string;
  readonly pipelines: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly description: string | null;
        readonly favorite: boolean;
        readonly id: string;
        readonly name: string;
        readonly " $fragmentSpreads": FragmentRefs<"Pipeline_pipeline">;
      } | null;
    } | null> | null;
    readonly " $fragmentSpreads": FragmentRefs<"ShowMoreFooter_connection">;
  } | null;
  readonly slug: string;
  readonly " $fragmentSpreads": FragmentRefs<"Welcome_organization">;
  readonly " $fragmentType": "Pipelines_organization";
};
export type Pipelines_organization$key = {
  readonly " $data"?: Pipelines_organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"Pipelines_organization">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "kind": "Variable",
  "name": "team",
  "variableName": "teamSearch"
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "archivedFilter"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "includeGraphData"
    },
    {
      "defaultValue": 30,
      "kind": "LocalArgument",
      "name": "pageSize"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "pipelineFilter"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "tagsFilter"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "teamSearch"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "Pipelines_organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Welcome_organization"
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": "allPipelines",
      "args": [
        (v1/*: any*/)
      ],
      "concreteType": "PipelineConnection",
      "kind": "LinkedField",
      "name": "pipelines",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "count",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "archived",
          "variableName": "archivedFilter"
        },
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "pageSize"
        },
        {
          "kind": "Literal",
          "name": "order",
          "value": "NAME_WITH_FAVORITES_FIRST"
        },
        {
          "kind": "Variable",
          "name": "search",
          "variableName": "pipelineFilter"
        },
        {
          "kind": "Variable",
          "name": "tags",
          "variableName": "tagsFilter"
        },
        (v1/*: any*/)
      ],
      "concreteType": "PipelineConnection",
      "kind": "LinkedField",
      "name": "pipelines",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ShowMoreFooter_connection"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PipelineEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Pipeline",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "description",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "favorite",
                  "storageKey": null
                },
                {
                  "args": [
                    {
                      "kind": "Variable",
                      "name": "includeGraphData",
                      "variableName": "includeGraphData"
                    }
                  ],
                  "kind": "FragmentSpread",
                  "name": "Pipeline_pipeline"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "0733cb0336058c1e957c68bd2e10f9b8";

export default node;

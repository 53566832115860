import { Field } from "app/components/job/BlockFieldsModal";
import { JobStates } from "./jobStates";

export function isInputJobUnblocked(job: InputJob): job is UnblockedInputJob {
  return job.unblockedAt !== undefined && job.unblockedBy !== undefined;
}

export interface UnblockedInputJob extends InputJob {
  unblockedAt: string;
  unblockedBy: { name: string; avatarUrl: string };
}

export type InputJob = {
  type: "manual";
  id: string;
  unblockDialogPath: string;
  unblockPath: string;
  fields: Array<Field>;
  name: string | null | undefined;
  permissions: {
    activate: {
      allowed: boolean;
      message?: string;
    };
  };
  step?: {
    label: string | null | undefined;
  };
  prompt: string | null | undefined;
  state: JobStates;
  submit: string | null | undefined;
  tooltip?: string;
  groupUuid: string | null | undefined;
  groupLabel: string | null | undefined;
  groupIdentifier: string | null | undefined;
  stepUuid: string;
  triggerProject?: {
    name: string;
  };
  unblockedAt?: string;
  unblockedBy?: {
    name: string;
    avatarUrl: string;
  };
  softFailed?: boolean;
  path: string;
};

import * as React from "react";
import Environment from "app/lib/relay/environment";
import usePreloadedRelayQuery from "app/lib/usePreloadedRelayQuery";
import { QueryRenderer, graphql } from "react-relay";

import SectionLoader from "app/components/shared/SectionLoader";
import AgentConnectedDialog from "./AgentConnectedDialog";

type AgentConnectedDialogRendererProps = {
  organization: {
    slug: string;
  };
};

export default function AgentConnectedDialogRenderer({
  organization,
  ...rest
}: AgentConnectedDialogRendererProps) {
  const environment = Environment.get();

  const query = graphql`
    query AgentConnectedDialogRendererQuery($slug: ID!) {
      organization(slug: $slug) {
        ...AgentConnectedDialog_organization
      }
    }
  `;

  usePreloadedRelayQuery(
    true,
    {
      environment,
      query,
      variables: { slug: organization.slug },
    },
    [organization.slug],
  );

  function renderQuery({ props }: { props: any }) {
    if (!props) {
      return <SectionLoader />;
    }

    return <AgentConnectedDialog {...props} {...rest} />;
  }
  return (
    <QueryRenderer
      fetchPolicy="store-and-network"
      cacheConfig={{ force: true }}
      environment={environment}
      query={query}
      variables={{ slug: organization.slug }}
      render={renderQuery}
    />
  );
}

import EventEmitter from "eventemitter3";
import { DateLike } from "app/lib/date";
import { parse as parseJobLog } from "app/lib/job-log";

import JobLogGroupStore from "app/stores/JobLogGroupStore";

type LogResponse = {
  streaming: boolean;
  bytes: number;
  header_times: Array<unknown>;
  output: string;
  truncated: boolean;
  started_at_from_agent?: string;
  finished_at_from_agent?: string;
};

declare let jQuery: JQueryStatic;

export type JobPartial = {
  id: string;
  basePath: string;
  state: string;
  startedAt: DateLike | null | undefined;
};

type Group = {
  id: string;
  name: string | null | undefined;
  expanded: boolean;
  lines: Array<{
    id: string;
    number: number;
    line: string;
    startedAt: DateLike | null | undefined;
  }>;
  number: number;
  startedAt: DateLike | null | undefined;
  finished: boolean;
  finishedAt: DateLike | null | undefined;
  durationPercentage: number | null | undefined;
  bootstrap?: boolean;
};

export type Job = {
  id: string;
  expanded?: boolean;
  url: string;
  passed?: boolean;
  groups: Array<Group>;
  headerTimes: Array<unknown>;
  bytes: number;
  totalLines: number;
  streaming: boolean;
  loaded: boolean;
  sshKeyFailure?: boolean;
  httpsAuthFailure?: boolean;
  dockerRateLimited?: boolean;
  truncated: boolean;
  startedAtFromAgent?: string;
  finishedAtFromAgent?: string;
  gitCommitNotFoundInBranchFailure?: boolean;
  linesHaveTimestamps?: boolean;
  _timeout?: number | null | undefined;
};

const createLog = (job: JobPartial): Job => ({
  id: job.id,
  url: job.basePath + "/log",
  groups: [],
  headerTimes: [],
  bytes: 0,
  totalLines: 0,
  streaming: false,
  loaded: false,
  truncated: false,
});

class JobLogStore {
  eventEmitter: EventEmitter;
  state: {
    [key: string]: Job;
  };

  constructor() {
    this.eventEmitter = new EventEmitter();
    this.state = {};
  }

  addChangeListener(jobId: string, callback: any) {
    return this.eventEmitter.addListener(`${jobId}/change`, callback);
  }

  removeChangeListener(jobId: string, callback: any) {
    return this.eventEmitter.removeListener(`${jobId}/change`, callback);
  }

  delete(jobId: string) {
    delete this.state[jobId];
    return this.eventEmitter.emit(`${jobId}/change`);
  }

  get(jobId: string) {
    return this.state[jobId];
  }

  refresh(jobId: string) {
    const log = this.get(jobId);

    return jQuery.ajax({
      url: log.url,
      headers: {
        "X-Buildkite-Frontend-Version": BUILDKITE_FRONTEND_VERSION,
      } as {
        [key: string]: string;
      },
      success: (json: LogResponse) => {
        // Save some of log data
        log.streaming = json.streaming;
        log.bytes = json.bytes;
        log.headerTimes = json.header_times;
        log.sshKeyFailure = false;
        log.httpsAuthFailure = false;
        log.dockerRateLimited = false;
        log.truncated = json.truncated;
        log.startedAtFromAgent = json.started_at_from_agent;
        log.finishedAtFromAgent = json.finished_at_from_agent;

        // Parse the output if we have any
        if (json.output !== "") {
          const parsed = parseJobLog(log, json.output);
          log.totalLines = parsed.totalLines;
          log.groups = parsed.groups;
          log.sshKeyFailure = parsed.sshKeyFailure;
          log.httpsAuthFailure = parsed.httpsAuthFailure;
          log.dockerRateLimited = parsed.dockerRateLimited;
          log.gitCommitNotFoundInBranchFailure =
            parsed.gitCommitNotFoundInBranchFailure;
          log.linesHaveTimestamps = parsed.linesHaveTimestamps;
        }

        // If the parser identified any groups that should be automatically
        // expanded, set the default expands for them.
        for (const group of Array.from(log.groups)) {
          JobLogGroupStore.setDefault(group.id, group.expanded);
        }

        // Mark the log as loaded
        log.loaded = true;

        // Emit the change so the components can reload
        this.eventEmitter.emit(`${log.id}/change`);

        // Reload the log if it needs to be streamed to the client
        if (log.streaming) {
          // Don't set a timeout if one has already been set
          if (log._timeout) {
            return;
          }

          // Schedule a refresh of the log in 5 seconds
          return (log._timeout = setTimeout(() => {
            this.refresh(log.id);
            return (log._timeout = null);
          }, 5000));
        }
      },
    });
  }

  load(job: JobPartial) {
    // Find or create the log
    const { id } = job;

    // Create the log record if it doesn't exist.
    // The log data won't be loaded until refreshed, e.g. when JobLogOutput is mounted.
    if (!this.state[id]) {
      this.state[id] = createLog(job);
    }

    return this.state[id];
  }
}

export default new JobLogStore();

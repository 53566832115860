/* eslint-disable react/no-array-index-key */
import * as React from "react";
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import { twMerge } from "tailwind-merge";
import { Nib } from "app/components/shared/Nib";
import Icon from "app/components/shared/Icon";

const DropdownMenu = DropdownMenuPrimitive.Root;

const DropdownMenuPortal = DropdownMenuPrimitive.Portal;

const DropdownMenuTrigger = DropdownMenuPrimitive.Trigger;

const DropdownMenuArrow = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Arrow>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Arrow>
>(({ ...props }, ref) => (
  <DropdownMenuPrimitive.Arrow
    ref={ref}
    asChild={true}
    {...props}
    width={16}
    height={8}
  >
    <Nib />
  </DropdownMenuPrimitive.Arrow>
));
DropdownMenuArrow.displayName = DropdownMenuPrimitive.Arrow.displayName;

const DropdownMenuContent = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Content>
>(
  (
    { children, className, sideOffset = -3, collisionPadding = 10, ...props },
    ref,
  ) => (
    <DropdownMenuPrimitive.Content
      ref={ref}
      sideOffset={sideOffset}
      collisionPadding={collisionPadding}
      arrowPadding={4}
      className={twMerge(
        "z-50 min-w-[180px] shadow-depth-800 bg-white rounded p-2 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=open]:duration-100 data-[state=closed]:duration-100 data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
        className,
      )}
      {...props}
    >
      <DropdownMenuArrow />
      {children}
    </DropdownMenuPrimitive.Content>
  ),
);
DropdownMenuContent.displayName = DropdownMenuPrimitive.Content.displayName;

const DropdownMenuItem = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Item> & {
    active?: boolean;
  }
>(({ className, active, ...props }, ref) => (
  <DropdownMenuPrimitive.Item
    ref={ref}
    data-active={active}
    className={twMerge(
      "group relative flex cursor-pointer select-none items-center outline-none w-full p-2 text-sm text-charcoal-700 font-medium rounded transition-colors data-[highlighted]:text-purple-600 data-[highlighted]:no-underline data-[highlighted]:outline-none data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
      active && "bg-purple-100 text-purple-600",
      className,
    )}
    {...props}
  />
));
DropdownMenuItem.displayName = DropdownMenuPrimitive.Item.displayName;

const DropdownMenuCheckboxItem = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.CheckboxItem>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.CheckboxItem>
>(({ className, checked, children, ...props }, ref) => (
  <DropdownMenuPrimitive.CheckboxItem
    ref={ref}
    data-active={checked}
    className={twMerge(
      "group relative flex cursor-pointer select-none items-center outline-none w-full p-2 text-sm text-charcoal-700 font-semibold rounded transition-colors data-[highlighted]:text-purple-600 data-[highlighted]:no-underline data-[highlighted]:outline-none data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
      checked && "bg-purple-100 text-purple-600",
      className,
    )}
    {...props}
  >
    <div
      data-active={checked}
      className="flex items-center justify-center mr-2 h-[18px] w-[18px] border border-gray-400 rounded data-[active=true]:bg-purple-600"
    >
      {checked && (
        <Icon icon="heroicons/outline/check" className="h-3 w-3 text-white" />
      )}
    </div>

    {children}
  </DropdownMenuPrimitive.CheckboxItem>
));
DropdownMenuCheckboxItem.displayName =
  DropdownMenuPrimitive.CheckboxItem.displayName;

const DropdownMenuLabel = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Label>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Label>
>(({ className, ...props }, ref) => (
  <DropdownMenuPrimitive.Label
    ref={ref}
    className={twMerge(
      "p-2 flex items-center font-semibold text-xs uppercase tracking-wider text-charcoal-700",
      className,
    )}
    {...props}
  />
));
DropdownMenuLabel.displayName = DropdownMenuPrimitive.Label.displayName;

const DropdownMenuSeparator = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Separator>
>(({ className, ...props }, ref) => (
  <DropdownMenuPrimitive.Separator
    ref={ref}
    className={twMerge("-mx-2 my-2 h-px bg-gray-500", className)}
    {...props}
  />
));
DropdownMenuSeparator.displayName = DropdownMenuPrimitive.Separator.displayName;

export {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuCheckboxItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuPortal,
  DropdownMenuArrow,
};

type ComposedDropdownMenuProps = {
  trigger: string;
  contentProps?: React.ComponentPropsWithoutRef<typeof DropdownMenuContent>;
  triggerProps?: React.ComponentPropsWithoutRef<typeof DropdownMenuTrigger> & {
    as?: React.ElementType;
  };
  items: Array<
    | {
        component: "item";
        props: React.ComponentPropsWithoutRef<typeof DropdownMenuItem> & {
          href?: string;
          children: string;
        };
      }
    | {
        component: "label";
        props: React.ComponentPropsWithoutRef<typeof DropdownMenuLabel>;
      }
    | {
        component: "separator";
        props: React.ComponentPropsWithoutRef<typeof DropdownMenuSeparator>;
      }
  >;
};

export function ComposedDropdownMenu({
  trigger,
  items,
  contentProps,
  triggerProps,
  ...props
}: ComposedDropdownMenuProps) {
  const TriggerTag = triggerProps?.as ?? "button";

  return (
    <DropdownMenu {...props}>
      <DropdownMenuTrigger {...triggerProps} asChild={true}>
        <TriggerTag dangerouslySetInnerHTML={{ __html: trigger }} />
      </DropdownMenuTrigger>

      <DropdownMenuContent {...contentProps}>
        {items.map(({ component, props }, index) => {
          if (component === "item") {
            const ItemTag = props.href ? "a" : "button";

            return (
              <DropdownMenuItem key={index} {...props} asChild={true}>
                <ItemTag dangerouslySetInnerHTML={{ __html: props.children }} />
              </DropdownMenuItem>
            );
          } else if (component === "label") {
            return <DropdownMenuLabel key={index} {...props} />;
          } else if (component === "separator") {
            return <DropdownMenuSeparator key={index} {...props} />;
          }
        })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

type FlashType = "error" | "success" | "warning" | "info";

type StringMessage = string;
type GraphqlExceptionMessage = {
  message: StringMessage;
  source: {
    type: string;
    errors: Array<{
      message: StringMessage;
    }>;
  };
};
type Message = GraphqlExceptionMessage | StringMessage;

export type FlashItem = {
  id: number | "FLASH_CONN_ERROR";
  type: FlashType;
  message: StringMessage | React.ReactNode;
};

import EventEmitter from "eventemitter3";

class FlashesStore extends EventEmitter {
  preloaded: Array<FlashItem>;

  constructor() {
    super();
    this.preloaded = [];
    // @ts-expect-error - TS2339 - Property 'ERROR' does not exist on type 'FlashesStore'.
    this.ERROR = "error";
    // @ts-expect-error - TS2339 - Property 'SUCCESS' does not exist on type 'FlashesStore'.
    this.SUCCESS = "success";
    // @ts-expect-error - TS2339 - Property 'INFO' does not exist on type 'FlashesStore'.
    this.INFO = "info";
    // @ts-expect-error - TS2339 - Property 'WARNING' does not exist on type 'FlashesStore'.
    this.WARNING = "warning";
  }

  preload(flashes: Array<FlashItem>) {
    for (const flash of flashes) {
      // @ts-expect-error - TS2783 - 'id' is specified more than once, so this usage will be overwritten.
      this.preloaded.push({ id: new Date().valueOf(), ...flash });
    }
  }

  flash(type: FlashType, message: Message): void {
    let returnMessage: Message = message;

    // See if the message is actually a GraphQL exception
    if (typeof message !== "string" && message.message && message.source) {
      if (
        message.source.errors.length &&
        message.source.errors[0] &&
        message.source.errors[0].message
      ) {
        if (message.source.type === "unknown_error") {
          returnMessage =
            "Sorry, there’s been an unexpected error and our engineers have been notified";
        } else {
          returnMessage = message.source.errors[0].message;
        }
      } else {
        returnMessage = "An unknown error occured";
      }
    }

    this.emit("flash", {
      id: new Date().valueOf(),
      type: type,
      message: returnMessage,
    });
  }

  reset() {
    this.emit("reset");
  }
}

export default new FlashesStore();

import styled from "styled-components";
import FriendlyTime from "app/components/shared/FriendlyTime";
import { StyledUnderline } from "app/components/analytics/shared/styles";
import BuildNumber from "./BuildNumber";
import CopyTextButton from "../CopyTextButton";
import { TestPlanProps } from "../type";
import TestPlansDropdown from "./TestPlansDropdown";
import CommitSha from "./CommitSha";

type MetadataRowProps = {
  buildNumber: string | null | undefined;
  buildUrl: string | null | undefined;
  commitSha: string | null | undefined;
  branch: string | null | undefined;
  createdAt: string;
  tags?: Array<string>;
  indexUrl?: string;
  testPlans?: TestPlanProps[];
  repoUrl: string | undefined | null;
};

const MetaDataRow = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  color: var(--charcoal-700);
  margin-top: 4px;
  font-weight: 400;
`;

const MetaDataColumn = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;

  &:after {
    content: "·";
    color: var(--gray-700);
    padding: 0 8px;
  }

  &:last-child:after {
    content: "";
  }
`;

const MetadataRow = ({
  buildNumber,
  buildUrl,
  commitSha,
  branch,
  createdAt,
  tags,
  indexUrl,
  testPlans,
  repoUrl,
}: MetadataRowProps) => (
  <MetaDataRow className="flex-wrap">
    {(buildNumber || buildUrl) && (
      <MetaDataColumn data-testid="build-number">
        <BuildNumber number={buildNumber} url={buildUrl} />
      </MetaDataColumn>
    )}
    {commitSha && (
      <MetaDataColumn className="monospace" data-testid="commit-sha">
        <CommitSha commitSha={commitSha} repoUrl={repoUrl} />
      </MetaDataColumn>
    )}
    {branch && (
      <MetaDataColumn>
        <CopyTextButton text={branch} />
      </MetaDataColumn>
    )}

    {testPlans && testPlans.length > 0 && (
      <MetaDataColumn>
        <TestPlansDropdown testPlans={testPlans} />
      </MetaDataColumn>
    )}

    <MetaDataColumn className="clickable-time">
      <StyledUnderline as={FriendlyTime} value={createdAt} />
    </MetaDataColumn>

    {tags && tags.length > 0 && (
      <span data-testid="tags">
        {tags.map((tag) => (
          <a
            key={tag}
            href={`${indexUrl}?tag=${tag}`}
            className="badge bg-gray mr1"
          >
            {tag}
          </a>
        ))}
      </span>
    )}
  </MetaDataRow>
);

export default MetadataRow;

import agentWarning from "app/images/agentWarning.svg";
import { createFragmentContainer, graphql } from "react-relay";

type Props = {
  agent: any;
};

function AgentUpgradeBanner({ agent }: Props) {
  if (!agent.version) {
    return null;
  }

  return (
    <section
      className="border border-gray rounded very-dark-gray mb2"
      style={{ borderLeft: "3px solid #fbca44" }}
    >
      <div className="m2">
        <h4 className="h4 m0 p0 mb1 semi-bold">
          <img src={agentWarning} className="inline mr1" />
          <span>Agent Upgrade Required</span>
        </h4>
        <p className="dark-gray">
          buildkite-agent {agent.version} has known issues and should be
          upgraded to a later version. See the{" "}
          <a
            href={`https://github.com/buildkite/agent/releases/tag/v${agent.version}`}
            className="text-decoration-none lime text-decoration-none hover-underline"
          >
            release notes on GitHub
          </a>{" "}
          for more information.
        </p>
      </div>
    </section>
  );
}

export default createFragmentContainer(AgentUpgradeBanner, {
  agent: graphql`
    fragment AgentUpgradeBanner_agent on Agent {
      ... on Agent {
        version
      }
    }
  `,
});

/**
 * @generated SignedSource<<c7726e9259883546ebfab87718267a83>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JobEventScheduled_event$data = {
  readonly timestamp: any;
  readonly " $fragmentType": "JobEventScheduled_event";
};
export type JobEventScheduled_event$key = {
  readonly " $data"?: JobEventScheduled_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"JobEventScheduled_event">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "JobEventScheduled_event",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timestamp",
      "storageKey": null
    }
  ],
  "type": "JobEvent",
  "abstractKey": "__isJobEvent"
};

(node as any).hash = "c49f7ec9f9506d59cbc625e1e47476fa";

export default node;
